import EventEmitter from "eventemitter3";
import { extend } from "lodash";
import * as d3 from "d3-timer";

import { ValidationError, NotFoundError } from "../lib/errors";
import FriendlyFrank from "./core.js";
import PatternReader from "./pattern-reader.js";

/**
 * The material data.
 *
 * @typedef {Object} RFIDScannerMaterial
 *
 * @property {String} materialId - The material id.
 * @property {Number} packSize - Amount of materials with the same material id in the pack.
 * @property {Number[]} indexes - Indexes of material pack placed on scanner.
 * @property {Boolean} isComplete - `true` when all indexes of material pack on the scanner, false otherwise.
 * @property {String} alarm - State of material alarm: enabled, disabled or unknown.
 *   The unknown if the scanner software not supported alarm status or alarm state is different for materials in pack.
 *
 * @property {Object} [error] - The error of loading material detal.
 * @property {String} [error.code] - The error code.
 * @property {String} [error.message] - The error message.
 *
 * @property {Object} [detail] - The material detail.
 * @property {String} [detail.id] - The faust number of material.
 * @property {String} [detail.faustNumber] - Duplicate of id.
 * @property {String} [detail.author] - Author of material.
 * @property {String} [detail.title] - The title of material.
 * @property {String} [detail.description] - The description of material (like on back book cover).
 * @property {String} [detail.year] - The year of publication.
 * @property {String} [detail.type] - The type of material (book, cd, music and etc).
 */
/**
 * The interface of es-linux-apps rfid scanner.
 *
 * @class RFIDScanner
 * @augments EventEmitter3
 *
 * @param {Object} options - The connection options.
 * @param {String} options.uri - Connection uri (like: `ws://localhost:9211`).
 * @param {Number} [options.reopenTimeout=2000] - The reconnection interval in ms. Used if the connection is not established or lost.
 * @param {Number} [options.reopenAttempts=5] - Amonut of total reconnections in row.
 *
 * @returns {RFIDScanner} - instace of RFIDScanner class.
 */
export default class RFIDScanner extends EventEmitter {
  /**
   * Static method for get the material data from inventory by material id.
   *
   * @param {String} materialId - The material id.
   * @param {Object} inventory - Inventory of rfid scanner.
   *
   * @returns {RFIDScannerMaterial} The material data.
   */
  static packData(materialId, inventory) {
    if (!inventory) {
      return {
        materialId: materialId,
        packSize: 1,
        indexes: [1],
        isComplete: true,
        alarm: "unknown"
      };
    }

    let materials = Object.keys(inventory).map(uid => {
      return inventory[uid];
    }).filter(material => {
      return material && material.materialId === materialId;
    });

    if (materials.length === 0) {
      return null;
    }

    let materialOptions = {
      materialId: materialId,
      packSize: materials[0].packSize || 1,
      indexes: materials.map(material => {
        return material.numberInPack;
      }).sort().filter((numberInPack, index, arr) => {
        return arr.indexOf(numberInPack, index + 1) === -1;
      }),
      isComplete: false,
      alarm: "unknown"
    };

    materialOptions.isComplete =
      materialOptions.packSize === materialOptions.indexes.length
      || !materialOptions.packSize
      || materialOptions.packSize === 1;

    if (materialOptions.isComplete) {
      let alarmEnabled = materials.every(material => {
        material.afi = (material.afi || material.AFI || "").toLowerCase();
        return material.alarm === "enabled" || material.afi === "07";
      });

      let alarmDisabled = materials.every(material => {
        material.afi = (material.afi || material.AFI || "").toLowerCase();
        return material.alarm === "disabled" || material.afi === "c2";
      });

      if (alarmEnabled) {
        materialOptions.alarm = "enabled";
      } else if (alarmDisabled) {
        materialOptions.alarm = "disabled";
      }
    }

    return materialOptions;
  }

  constructor(options) {
    super();

    options = options || {};

    this.inventory = {};
    extend(this, options);

    if (options.type === "keyboard-emitter") {
      this.expireDelay = options.expireDelay || 5000;
      this.patternOptions = options.patternOptions || ({
        prefix: "[^0-9]{2}",
        body: "\\d+",
        postfix: "[^0-9]\n?"
      });

      this._initKeyboardReader(options);
    } else {
      this._initSocketScanner(options);
    }
  }

  /**
   * Check if the inventory is empty.
   *
   * @returns {Boolean} - `true` is empty.
   */
  isEmpty() {
    return Object.keys(this.inventory || {}).length === 0;
  }

  /**
   * Closes connection and destroys the FriendlyFrank\KeyboardReader instance.
   */
  destroy() {
    this._destroyed = true;
    /**
     * The inventory of RFID scanner.
     *
     * @typedef {Object} RFIDScannerInventory
     *
     * @property {RFIDScannerMaterial} * - The material data on the scanner.
     */
    this.inventory = {};

    if (this._friendlyFrank) {
      this._friendlyFrank.destroy();
    }
  }

  _initKeyboardReader() {
    this.patternReader = new PatternReader({
      pattern: new RegExp(
        this.patternOptions.prefix
        + this.patternOptions.body
        + this.patternOptions.postfix
        , "")
    });

    const prefixRegex = new RegExp("^" + this.patternOptions.prefix, "");
    const postfixRegex = new RegExp(this.patternOptions.postfix + "$", "");
    this.patternReader.on("match", (rawMaterialId) => {
      const materialId = rawMaterialId.replace(prefixRegex, "").replace(postfixRegex, "");

      if (!this.inventory[materialId]) {
        this._addMaterial(materialId);
        this.inventory[materialId].timer = d3.timeout(this._removeMaterial.bind(this, materialId), this.expireDelay);
      } else {
        this.inventory[materialId].timer.restart(this._removeMaterial.bind(this, materialId), this.expireDelay);
      }
    });
  }

  /**
   * Inialization of rfid scanner. Creates the instance of Friendly Frank.
   * @listens module:FriendlyFrank~action:added-books
   * @listens module:FriendlyFrank~action:removed-books
   *
   * @param {object} options - The connection options.
   * @param {String} options.uri - Connection uri (like: `ws://localhost:9211`). Required.
   * @param {Number} [options.reopenTimeout=2000] - The reconnection interval in ms. Used if the connection is not established or lost.
   * @param {Number} [options.reopenAttempts=5] - Amonut of total reconnections in row.
   */
  _initSocketScanner(options) {
    this._friendlyFrank = new FriendlyFrank(Object.assign({}, options, { autoConnect: false }));
    this._friendlyFrank.on("action:added-books", (message) => {
      (message.books || []).forEach(materialId => {
        this._addMaterial(materialId, message.inventory);
      });
    });
    this._friendlyFrank.on("action:removed-books", (message) => {
      (message.books || []).forEach(materialId => {
        this._removeMaterial(materialId, message.inventory);
      });
    });
    this._friendlyFrank.connect();
  }

  /**
   * Add material to the insatnce inventory by material id and scanner inventory.
   * @fires RFIDScanner#material-updated
   * @fires RFIDScanner#inventory-updated
   *
   * @param {String} materialId - The material id.
   * @param {Object} inventory - Inventory of rfid scanner.
   */
  _addMaterial(materialId, inventory) {
    if (this._destroyed) {
      return;
    }

    let materialOptions = RFIDScanner.packData(materialId, inventory);
    if (this.inventory[materialId]) {
      this.inventory[materialId] = Object.assign({
        detail: null,
        error: null
      }, this.inventory[materialId] || {}, materialOptions, {
        updated: Date.now()
      });

      /**
       * Material data update event.
       *
       * @event RFIDScanner#material-updated
       * @type {RFIDScannerMaterial}
       */
      this.emit("material-updated", materialOptions);

      /**
       * The inventory of rfid scanner update event.
       *
       * @event RFIDScanner#inventory-updated
       * @type {RFIDScannerInventory}
       */
      this.emit("inventory-updated", this.inventory);
      return;
    }

    materialOptions.created = Date.now();
    this.inventory[materialId] = materialOptions;
    this.inventory[materialId].loadingPromise = this._loadMaterialDetail(materialOptions);
    this.emit("material-added", materialOptions);
    this.emit("inventory-updated", this.inventory);
  }

  /**
   * Remove zero bytes and data after it from the material id.
   *
   * @param {String} materialId - Material id from the es-linux-app.
   * @returns {String} materialId - Fixed material id.
   */
  fixMaterialId(materialId) {
    return materialId.split("\x00")[0];
  }

  /**
   * Load the details to material.
   * @async
   *
   * @fires RFIDScanner#material-updated
   * @fires RFIDScanner#material-loaded
   * @fires RFIDScanner#inventory-updated
   *
   * @param {RFIDScannerMaterial} materialOptions - The material data.
   */
  async _loadMaterialDetail(materialOptions) {
    try {
      const faustNumber = await this.request.materialToFaust({
        materialId: this.fixMaterialId(materialOptions.materialId)
      });

      if (typeof faustNumber !== "string") {
        throw NotFoundError({ message: "Faust number not found." });
      }

      const [detailResponse, coverResponse] = await Promise.all([
        (async function() {
          const detail = await this.request.detailWithoutCache({
            faustNumber: faustNumber,
            withExternalResources: this.withExternalResources,
            withNoCover: true
          });

          this.inventory[materialOptions.materialId].detail = detail;
          this.emit("material-updated", this.inventory[materialOptions.materialId]);
          this.emit("inventory-updated", this.inventory);

          return detail;
        }).call(this),
        this.request.detailCover({ faustNumber: faustNumber })
      ]);

      if (coverResponse && coverResponse.cover) {
        detailResponse.cover = coverResponse.cover;
      }

      this.inventory[materialOptions.materialId].detail = detailResponse;
      this.emit("material-updated", this.inventory[materialOptions.materialId]);
      /**
       * The material loading event.
       *
       * @event RFIDScanner#inventory-loaded
       * @type {RFIDScannerMaterial}
       */
      this.emit("material-loaded", this.inventory[materialOptions.materialId]);
      this.emit("inventory-updated", this.inventory);
      
      return detailResponse;
    } catch (error) {
      console.error(`Material scanner, can't load material: ${ materialOptions.materialId }`, error);
      materialOptions.error = error;
      this.emit("material-error", materialOptions);
      this.emit("inventory-updated", this.inventory);

      throw error;
    }
  }

  /**
   * Removes the material from inventory.
   * @fires RFIDScanner#material-updated
   * @fires RFIDScanner#inventory-updated
   *
   * @param {String} materialId - The material id.
   * @param {Object} inventory - Inventory of rfid scanner.
   */
  _removeMaterial(materialId, inventory) {
    if (this._destroyed) {
      return;
    }

    if (inventory) {
      let materialOptions = RFIDScanner.packData(materialId, inventory);
      if (materialOptions) {
        this.inventory[materialId] = Object.assign({
          detail: null,
          error: null
        }, this.inventory[materialId] || {}, materialOptions, {
          updated: Date.now()
        });

        this.emit("material-updated", materialOptions);
        this.emit("inventory-updated", this.inventory);
        return;
      }
    }

    let material = this.inventory[materialId];
    delete this.inventory[materialId];
    this.emit("material-removed", material);
    this.emit("inventory-updated", this.inventory);
  }

  /**
   * Remove the all registered materials from instance inventory.
   */
  clearInventory() {
    Object.keys(this.inventory).map(materialId => this._removeMaterial(materialId));
  }

  /**
   * Set the alam for material or material pack.
   * @async
   *
   * @param {Object} options - Request options.
   * @param {String} [options.action] - The type of action: "on" or "off" for enable or disable alarm accordingly.
   * @param {String} [options.materialId] - The id of material to which the alarm should be changed.
   * @param {Boolean} [options.timeout] - Use the timeout, default true.
   *
   * @returns {Promise<Object>} The promise-based callback.
   */
  setAlarm(options) {
    options = options || {};

    let ids = options.materialId;
    if (!ids) {
      throw new ValidationError({ message: "option 'materialId' are required." });
    }

    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    return new Promise((resolve, reject) => {
      this._friendlyFrank.request({
        action: options.action,
        materialId: options.materialId,
        _withTimeout: options.timeout
      }, (error, data) => {
        if (error) {
          /* Attempt of alarm reverting. */
          if (error.code === "TIMEOUT_ERROR") {
            if (options.action === "on") {
              this.setAlarm({
                action: "off",
                materialId: ids,
                timeout: false
              });
            } else if (options.action === "off") {
              this.setAlarm({
                action: "on",
                materialId: ids,
                timeout: false
              });
            }
          }

          return reject(error);
        }

        resolve(data);
      });
    });
  }

  /**
   * Get the status of material.
   * @async
   *
   * @param {String} materialId - The material id.
   *
   * @returns {Promise<Object>} The promise-based callback.
   */
  getStatus(options) {
    options = options || {};

    let ids = options.materialId;
    if (!ids) {
      throw new ValidationError({ message: "option 'materialId' are required." });
    }

    if (!Array.isArray(ids)) {
      ids = [ids];
    }

    return new Promise((resolve, reject) => {
      this._friendlyFrank.request({
        action: "get-status",
        materialId: ids
      }, (error, data) => {
        if (error) {
          return reject(error);
        }

        Object.keys(this.inventory).forEach(materialId => {
          if (data[materialId]) {
            Object.assign(this.inventory[materialId], RFIDScanner.packData(materialId, data.inventory));
          }
        });

        resolve(data);
      });
    });
  }
}
