/*
 * List of the accented symbols.
 * The accented symbols in danish.
 */
const joinableChars = {
  "¨a": "ä",   "¨A": "Ä",
  "¨e": "ë",   "¨E": "Ë",
  "¨o": "ö",   "¨O": "Ö",
  "¨i": "ï",   "¨I": "Ï",
  "¨u": "ü",   "¨U": "Ü",
  "¨y": "ÿ",   "¨Y": "Ÿ",

  "`a": "á",   "`A": "Á",
  "`e": "é",   "`E": "É",
  "`o": "ó",   "`O": "Ó",
  "`i": "í",   "`I": "Í",
  "`u": "ú",   "`U": "Ú",
  "`y": "ý",   "`Y": "Ý",

  "^a": "â",   "^A": "Â",
  "^e": "ê",   "^E": "Ê",
  "^o": "ô",   "^O": "Ô",
  "^i": "î",   "^I": "Î",
  "^u": "û",   "^U": "Û",
  "^y": "ŷ",   "^Y": "Ŷ"
};

/**
 * The join existing accented symbols in a string.
 *
 * @param {String} string -  The string for join existing accented symbols.
 *
 * @returns {String} The string with joint existing accented symbols.
 */
export function joinAccentedSymbols(string) {
  Object.keys(joinableChars).forEach(function(accentedSymbols){
    var unicodeCharacter = joinableChars[accentedSymbols];
    string = string.replace(new RegExp(accentedSymbols.replace(/[^a-z0-9 ]/ig, function (ch) {
      return "\\" + ch;
    }), "g"), unicodeCharacter);
  });

  return string;
}

/**
 * The split existing accented symbols in a string.
 *
 * @param {String} string -  The string for split existing accented symbols.
 *
 * @returns {String} The string with splited existing accented symbols.
 */
export function splitAccentedSymbols(string) {
  Object.keys(joinableChars).forEach(function(accentedSymbols){
    var unicodeCharacter = joinableChars[accentedSymbols];
    string = string.replace(new RegExp(unicodeCharacter, "g"), accentedSymbols);
  });

  return string;
}
