import { castArray } from "lodash";
import isElementOf from "@/lib/utils/is-element-of.js";

export default {
  methods: {
    /**
     * Mutation event of selected reference.
     *
     * @event #mutation:[reference-name]
     * @type {MutationRecord[]}
     */
    /**
     * Callback of mutation observer. Emits the events based on changes excluding duplicates.
     * @fires #mutation:[reference-name]
     *
     * @param {MutationRecord} mutationRecords - The mutation records. See more https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver.
     */
    _mutationObserverCallback(mutationRecords) {
      let mutationsByGroup = {};

      mutationRecords.forEach(mutationRecord => {
        let refName = castArray(this.watchRefs).find(refName => {
          return this.$refs[refName] && isElementOf(mutationRecord.target, this.$refs[refName]);
        });

        if (refName) {
          mutationsByGroup[refName] = mutationsByGroup[refName] || [];
          mutationsByGroup[refName].push(mutationRecord);
        }
      });

      Object.keys(mutationsByGroup).forEach(refName => {
        this.$emit(`mutation:${ refName }`, mutationsByGroup[refName]);
      });
    },
    /**
     * Initialize the mutation observers for each existing reference from `this.watchRefs`.
     * The existing mutation observers will be destroyed before new initialization.
     */
    _initMutationObservers() {
      this._destroyMutationObservers();

      this._mutationObservers = castArray(this.watchRefs).filter(refName => {
        return !!this.$refs[refName];
      }).map(refName => {
        let observer = new MutationObserver(this._mutationObserverCallback);
        observer.observe(this.$refs[refName], {
          attributes: true,
          childList: true,
          subtree: true
        });

        return observer;
      });
    },
    /**
     * Destroy the mutation observer.
     */
    _destroyMutationObservers() {
      if (!this._mutationObservers) {
        return;
      }

      this._mutationObservers.forEach(observer => {
        observer.disconnect();
      });
      this._mutationObservers = null;
    }
  },
  updated() {
    this._initMutationObservers();
  },
  mounted() {
    this._initMutationObservers();
  },
  beforeDestroy() {
    this._destroyMutationObservers();
  }
};
