<template>
  <div class="easyscreen-tab">
    <div v-if="withBackground" class="easyscreen-tab--backgrounds">
      <div
        v-for="(element, elementIndex) in elements"
        :key="`background-element-${ elementIndex || 'index-' + elementIndex }`"
        :class="[
          'easyscreen-tab--background',
          { 'easyscreen-tab--background_active': elementIndex === activeElementIndex }
        ]"
        :style="{ backgroundImage: element.image ? `url('${ element.image }')` : null }"
      ></div>
    </div>
    <div class="easyscreen-tab--overlay"></div>
    <div class="easyscreen-tab--navigation">
      <slot name="navigation"></slot>
      <scrollable
        ref="navigationScrollable"
        max-height="100%"
      >
        <div
          ref="navigationElements"
          v-for="(element, elementIndex) in elements"
          :key="`navigation-element-${ elementIndex || 'index-' + elementIndex }`"
          :class="[
            'easyscreen-tab--navigation-element',
            { 'easyscreen-tab--navigation-element_active': elementIndex === activeElementIndex }
          ]"
          @click="() => { selectIndex(elementIndex) }"
        >
          <slot name="title" :element="element">
            {{ element.title }}
          </slot>
        </div>
      </scrollable>
    </div>
    <div class="easyscreen-tab--content">
      <div
        v-for="(element, elementIndex) in elements"
        :key="`content-element-${ elementIndex || 'index-' + elementIndex }`"
        :class="[
          'easyscreen-tab--content-element',
          { 'easyscreen-tab--content-element_active': elementIndex === activeElementIndex }
        ]"
        @click="$emit('expand-element', element, elementIndex)"
      >
        <slot name="content" :element="element">
          {{ element.content }}
        </slot>
      </div>
    </div>
  </div>
</template>

<style src="./tab.less" lang="less"></style>

<script>  
  import * as d3 from "d3-timer";
  import fitNumber from "@/lib/utils/fit-number.js";

  import Scrollable from "../scrollable/scrollable.vue";

  export default {
    name: "easyscreen-tab",
    props: {
      elements: Array,
      /* The amount of milliseconds when single element is active. */
      animationDuration: {
        type: Number,
        default: 5000
      },
      /* Flag for enable the autoamimation on standby. */
      autoAnimation: {
        type: Boolean,
        default: false
      },
      withBackground: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        activeElementIndex: 0
      };
    },
    methods: {
      /**
       * Select the element by index. First in case when the index bigger than elements amount and last if less than 0.
       *
       * @param {Number} index - The element index.
       */
      async selectIndex(index) {
        this.activeElementIndex = fitNumber(index, {
          lt: 0,
          value: this.elements.length - 1
        }, {
          gt: this.elements.length - 1,
          value: 0
        });

        await this.$nextTick();
        this.$refs.navigationScrollable.scrollIntoView(this.$refs.navigationElements[this.activeElementIndex], {
          aligment: "center",
          scrollIfNeeded: false
        });

        this.$emit("selected", this.elements[this.activeElementIndex], this.activeElementIndex);
      },
      /**
       * Select next element. Or first in case when the active element is last.
       */
      selectNext() {
        this.selectIndex(this.activeElementIndex + 1);
      },
      /**
       * Select previous element. Or last in case when the active element is first.
       */
      selectPrevious() {
        this.selectIndex(this.activeElementIndex - 1);
      },
      /**
       * Starts the auto animation (select next silde) with `this.animationDuration` interval.
       */
      _startAutoAnimation() {
        this._stopAutoAnimation();

        this._autoAnimationInterval = d3.interval(this.selectNext, this.animationDuration);
      },
      /**
       * Stops the auto animation.
       */
      _stopAutoAnimation() {
        if (this._autoAnimationInterval) {
          this._autoAnimationInterval.stop();
          this._autoAnimationInterval = null;
        }
      }
    },
    screenStandby() {
      if (this.autoAnimation) {
        this._startAutoAnimation();
      }
    },
    screenActive() {
      this._stopAutoAnimation();
    },
    components: {
      "scrollable": Scrollable
    }
  };
</script>
