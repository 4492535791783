var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'node-list-carousel-color-image-slide',
    _vm.formattedColor ? `node-list-carousel-color-image-slide_${ _vm.formattedColor }` : null,
    _vm.orientation
  ]},[_c('div',{staticClass:"node-list-carousel-color-image-slide--image-wrapper"},[_c('div',{staticClass:"node-list-carousel-color-image-slide--image",style:({
        backgroundImage: _vm.backgroundImage ? `url('${ _vm.backgroundImage }')` : null
      })})]),_c('div',{staticClass:"node-list-carousel-color-image-slide--content"},[_c('h1',{staticClass:"node-list-carousel-color-image-slide--title",style:({
        fontSize: this.title.length <= 20 ? 116 : null
      })},[(_vm.logo)?_c('img',{staticClass:"node-list-carousel-color-image-slide--logo node-list-carousel-color-image-slide--logo_in-text",attrs:{"src":_vm.logo}}):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"node-list-carousel-color-image-slide--teaser ck-editor",domProps:{"innerHTML":_vm._s(_vm.formattedTeaser)}}),(_vm.isEvent)?_c('div',{staticClass:"node-list-carousel-color-image-slide--meta"},[(_vm.date)?_c('div',{staticClass:"node-list-carousel-color-image-slide--date"},[_vm._v(" "+_vm._s(_vm.formattedDate)+" ")]):_vm._e(),(_vm.location)?_c('div',{staticClass:"node-list-carousel-color-image-slide--location"},[_vm._v(" "+_vm._s(_vm.location)+" ")]):_vm._e(),(_vm.price)?_c('div',{staticClass:"node-list-carousel-color-image-slide--price"},[_vm._v(" "+_vm._s(_vm._l10n("Price:"))+" "+_vm._s(_vm.price)+" ")]):_vm._e()]):_vm._e(),(_vm.link)?_c('div',{staticClass:"node-list-carousel-color-image-slide--link"},[_vm._v(" "+_vm._s(_vm.link)+" ")]):_vm._e(),(_vm.logo)?_c('img',{staticClass:"node-list-carousel-color-image-slide--logo",attrs:{"src":_vm.logo}}):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }