import l10n from "@/lib/localization/localization.js";
import stringifyStyles from "@/lib/utils/stringify-styles.js";

export default () => {
  return [
    [
      { lowerCase: "$", upperCase: "§", color: "light-gray" },
      { lowerCase: "1", upperCase: "!", color: "light-gray" },
      { lowerCase: "2", upperCase: '"', color: "light-gray" },
      { lowerCase: "3", upperCase: "#", color: "light-gray" },
      { lowerCase: "4", upperCase: "€", color: "light-gray" },
      { lowerCase: "5", upperCase: "%", color: "light-gray" },
      { lowerCase: "6", upperCase: "&", color: "light-gray" },
      { lowerCase: "7", upperCase: "/", color: "light-gray" },
      { lowerCase: "8", upperCase: "(", color: "light-gray" },
      { lowerCase: "9", upperCase: ")", color: "light-gray" },
      { lowerCase: "0", upperCase: "=", color: "light-gray" },
      { lowerCase: "+", upperCase: "?", color: "light-gray" },
      { label: "`", color: "light-gray" },
      {
        special: "backspace",
        icon: {
          className: "fa fa-long-arrow-left",
          placement: "right",
          style: {
            top: "2px",
            fontSize: "44px"
          }
        },
        style: {
          "width": 101
        },
        color: "light-gray"
      }
    ],
    [{
        special: "tab",
        label: "Tab",
        color: "light-gray",
        style: {
          width: 77,
          fontSize: "14px",
          textAlign: "left",
          paddingTop: "9px"
        },
        disabled: true
    },
      "q", "w", "e", "r", "t", "y", "u", "i", "o", "p", "å",
      { lowerCase: "¨", upperCase: "^", color: "light-gray" }
    ],
    [{
        special: "capslock",
        label: "Caps \nLock",
        color: "light-gray",
        style: {
          width: 92,
          fontSize: "14px",
          textAlign: "left"
        }
    },
      "a", "s", "d", "f", "g", "h", "j", "k", "l", "æ", "ø",
      {
        special: "enter",
        fn: function(position, override) {
          override = override || {};

          var label = l10n("Search");
          var style = {
            border: "none",
            transform: "scale(1.05)",
            "z-index": 1,
            top: position.top - 56 + (override.offsetTop || 0),
            left: position.left + 2 + (override.offsetleft || 0),
            width: 109,
            height: 101
          };

          if (override) {
            for (var key in override.style) {
              style[key] = override.style[key];
            }

            label = override.label || label;
          }

          style = stringifyStyles(style);
          /* eslint-disable */
          const path = override.path || `m 18.835654,785.36391 c -1.46095,0 -2.66989,1.03506 -2.9414,2.41407 l 0,49.97847 c 0,1.662 -1.338,3 -3,3 l -15.5742204,0 0,0.018 -22.0703106,0 c -1.662,0 -3,1.338 -3,3 l 0,37.4375 c 0,1.662 1.338,3 3,3 l 42.701171,0 c 0.28104,0.087 0.57456,0.1485 0.88476,0.1485 l 56.628912,0 c 1.662,0 3,-1.338 3,-3 l 0,-92.99612 c 0,-1.662 -1.338,-3 -3,-3 l -56.628912,0 z`;
          return `
          <svg
            class="keyboard-fullsize--key keyboard-fullsize--svg-key"
            style="${style}"
            width="109"
            height="101"
            viewBox="0 0 109 101"
          >
            <g transform="translate(0,-951.36216)">
              <g transform="translate(29.142855,166.99998)">
                <path
                  d="${ path }"
                  style="opacity:1;fill:${ override && override.fill ? override.fill : "#43a09d" };fill-opacity:1;stroke:#ffffff;stroke-width:1;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1"
                />
                <text><tspan
                  x="25.035723"
                  y="868.55865"
                  text-anchor="middle"
                  style="font-weight:bold;font-size:20px;fill:#b3b3b3"
                >${ label }</tspan></text>
                <text><tspan
                  x="24.642866"
                  y="868.07648"
                  text-anchor="middle"
                  style="font-weight:bold;font-size:20px;fill:#ffffff"
                >${ label }</tspan></text>
              </g>
            </g>
          </svg>`;
          /* eslint-enable */
        }
      }
    ],
    [{
        special: "lshift",
        label: "Shift",
        icon: {
          className: "far fa-arrow-alt-up",
          placement: "left",
          style: {
            fontSize: "24px",
            marginRight: "-4px",
            marginLeft: "-42px",
            top: "2px",
            marginTop: "2px"
          }
        },
        style: {
          fontSize: "14px",
          width: 118
        },
        color: "light-gray"
    },
      "z", "x", "c", "v", "b", "n", "m",
      { lowerCase: ",", upperCase: ";" },
      { lowerCase: ".", upperCase: ":" },
      { lowerCase: "-", upperCase: "_" },
      { label: "@", color: "light-gray" },
      {
        special: "rshift",
        label: "Shift",
        icon: {
          className: "far fa-arrow-alt-up",
          placement: "right",
          style: {
            fontSize: "24px",
            marginRight: "-4px",
            marginLeft: "4px"
          }
        },
        style: {
          fontSize: "14px",
          width: 86
        },
        color: "light-gray"
      }
    ],
    [
      { label: "/", color: "light-gray", style: { width: 63 } },
      { label: "<", color: "light-gray", style: { width: 63 } },
      { label: ">", color: "light-gray", style: { width: 63 } },
      { special: "space", label: " ", style: { width: 330 } },
      { label: "\"", color: "light-gray", style: { width: 63 } },
      { label: "*", color: "light-gray", style: { width: 63 } },
      { label: "◄", color: "light-gray", style: { width: 63 } },
      { label: "►", color: "light-gray", style: { width: 63 } }
    ]
  ];
};
