<template>
  <div
    :class="[
      'easyscreen-text-input',
      { 'easyscreen-text-input_has-error': !!error }
    ]"
    @click="focusTheHiddenInput"
  >
    <label class="easyscreen-text-input--label" v-if="label" :for="id">{{ label }}</label>
    <pre
      :class="[
        'easyscreen-text-input--field',
        'easyscreen-input',
        'easyscreen-text-input--input',
        { focus: focused },
        inputClass
      ]"
      ref="inputField"
      @click="(e) => _selectNearest({ x: e.clientX, y: e.clientY })"
    >
      <span class="easyscreen-text-input--default-position" ref="defaultPositionPointer"></span><span
        v-for="(letter, index) in (value || '').split('')"
        :key="`${ letter }-${ index }`"
      >{{ letter }}</span>
      <span
        v-if="cursor && cursorPosition > 0"
        :class="['easyscreen-text-input--cursor', cursorClass]"
        ref="cursor"
        :style="{ left: cursorPosition }"
        ></span>
    </pre>
    <div v-if="error" class="easyscreen-text-input--error">{{ error }}</div>
    <i
      :class="[
        'fal',
        'fa-times',
        'easyscreen-text-input--clean-button',
        {
          'easyscreen-text-input--clean-button_visible': !!value
        },
        clearButtonClass
      ]"
      @click="setCursor(0); $emit('input', '')"
    ></i>
    <input
      :id="id"
      class="easyscreen-text-input--hidden-input"
      type="text"
      :value="value"
      @input="(event) => $emit('input', event.target.value)"
      @submit="(event) => $emit('submit', event)"
      @keydown="_onNativeCursorChange"
      @keyup="_onNativeCursorChange"
      autocomplete="off"
      ref="hiddenInput"
      :readonly="readonly"
    />
  </div>
</template>

<style lang="less" src="./input.less"></style>
<script>
  import randomId from "../../../lib/utils/random-id.js";
  import clampNumber from "../../../lib/utils/clamp-number.js";
  import euclideanDistance from "../../../lib/utils/euclidean-distance.js";
  import orientationMixin from "../mixins/orientation.js";

  export default {
    name: "easyscreen-text-input",
    mixins: [orientationMixin],
    props: {
      /* The custom class of input field (<pre>) */
      inputClass: [String, Object, Array],
      /* The custom class of clear button (<i class="easyscreen-text-input--clean-button">) */
      clearButtonClass: [String, Object, Array],
      /* The custom class of input field (<span class="easyscreen-text-input--cursor">) */
      cursorClass: [String, Object, Array],
      /* Label of input field */
      label: {
        type: String,
        default: ""
      },
      /* Value of input */
      value: {
        type: String,
        default: ""
      },
      /* Sets the input focused when true. */
      focused: {
        type: Boolean,
        default: false
      },
      /* Sets the input readonly when true */
      readonly: {
        type: Boolean,
        default: false
      },
      /* The flag of coursor, display the cursor when true. */
      cursor: {
        type: Boolean,
        default: false
      },
      /* The error alert. */
      error: {
        type: String,
        default: ""
      }
    },
    data() {
      return {
        id: randomId(),
        cursorIndex: -1,
        cursorPosition: -1
      };
    },
    methods: {
      /**
       * Set the cursor position to position.
       * The selection range not supported by y this widget.
       *
       * @param {Number} index - The position index.
       */
      setCursor(index) {
        if (!this.cursor) {
          return;
        }

        const text = this._getTextNodes();
        index = clampNumber(index, 0, text.length - 1);
        const selectedElement = text[index];
        const containerBoundingRect = this.$refs.inputField.getBoundingClientRect();
        const selectedElementBoundingRect = selectedElement.getBoundingClientRect();
        const selectedElementRelativeBounding = {
          left: selectedElementBoundingRect.left - containerBoundingRect.left,
          right: selectedElementBoundingRect.right - containerBoundingRect.left
        };

        let cursorPosition = selectedElementRelativeBounding.left;
        const nextElement = text[index + 1];
        if (nextElement) {
          cursorPosition += selectedElementBoundingRect.width * 0.8;
        } else {
          cursorPosition += selectedElementBoundingRect.width;
        }

        this.cursorIndex = index;
        this.cursorPosition = this._applyParentsScale(this.$refs.inputField, { x: 0, y: cursorPosition }).y;
      },
      /**
       * Set the forcus for hidden input for track native keyboard events.
       */
      focusTheHiddenInput() {
        const hiddenInput = this.$refs.hiddenInput;
        if (hiddenInput) {
          hiddenInput.focus();
          let cursorIndex = hiddenInput.value.length;
          if (this.cursor) {
            cursorIndex = this.cursorIndex;
          }

          hiddenInput.selectionStart = hiddenInput.selectionEnd = cursorIndex;
        }
      },
      /**
       * Applied the container scale to number.
       *
       * @param {Number} number - The number (position) inside the input container.
       *
       * @returns {Number} The number (position) counting container scale.
       */
      _applyContanerWidthScale(el, number) {
        const scaleX = el.offsetWidth / el.getBoundingClientRect().width;

        return number / scaleX;
      },
      /**
       * Get the text nodes which presents the letters in emulated input view.
       *
       * @returns {HTMLElement[]} The list of span html elements with one symbol inside.
       */
      _getTextNodes() {
        return [].slice.call(this.$refs.inputField.children).filter(child => {
          return child !== this.$refs.cursor;
        });
      },
      /**
       * Set the cursor by screen coordinate.
       *
       * @param {EuclideanPoint} position - The screen coordinate of where cursor should be set.
       */
      _selectNearest (position) {
        if (!this.cursor) {
          return;
        }

        const text = this._getTextNodes();
        const nearest = text.reduce((nearest, node) => {
          const boundingRect = node.getBoundingClientRect();
          const center = {
            x: boundingRect.x + boundingRect.width / 2,
            y: boundingRect.y + boundingRect.height / 2
          };

          const distance = euclideanDistance(position, center);
          if (nearest.distance > distance) {
            nearest = { node, distance, center };
          }

          return nearest;
        }, { node: null, distance: Infinity });

        let indexToSelect = text.indexOf(nearest.node);
        if (nearest.center && nearest.center.x > position.x) {
          indexToSelect -= 1;
        }

        this.setCursor(indexToSelect);
      },
      /**
       * Listener of native input events for duplicate it in emulated input view.
       */
      _onNativeCursorChange() {
        if (!this.cursor) {
          return;
        }

        const hiddenInput = this.$refs.hiddenInput;
        if (hiddenInput) {
          this.setCursor(hiddenInput.selectionEnd);
        }
      }
    },
    mounted() {
      if (this.cursor) {
        this.setCursor(this.value.length);
      }
    }
  };
</script>
