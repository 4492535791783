<template>
  <div class="search-cover">
    <img
      class="search-cover--image-reference"
      ref="image"
      draggable="false"
      :src="$easyscreenRequest.lmsConnector.wrapCover(cover)"
      crossOrigin="annonimus"
    >

    <div
      :class="['search-cover--image', `search-cover_${ availability }`]"
      :style="{
        backgroundImage: `url(${ $easyscreenRequest.lmsConnector.wrapCover(cover) })`
      }"
    >
      <div class="search-cover--type">
        {{ type }}
      </div>
    </div>

    <div class="search-cover--meta">
      <div class="search-cover--title">
        {{ _cutText(title, maxTitleLength) }}
      </div>
      <div class="search-cover--author">
        {{ author }}
        <span v-if="year">({{ year }})</span>
      </div>
    </div>
  </div>
</template>

<style src="./search-cover.less" lang="less"></style>
<script>
  import cutText from "@/lib/utils/cut-text.js";
  import getDominantImageColor from "@/lib/utils/get-dominant-image-color.js";

  export default {
    name: "search-cover",
    props: {
      maxTitleLength: {
        type: Number,
        default: 38
      },
      /* The title of material. */
      title: String,
      /* The author of material. */
      author: String,
      /* The publication year of material. */
      year: String,
      /* The type of material (book, cd and etc). */
      type: String,
      /* Url of the cover, from material detail (wihtout the host). */
      cover: String,
      /* The availability of the material. Default: "unknown". */
      availability: {
        type: String,
        default: "unknown",
        validator: (_availability) => [
          "unknown",
          "available",
          "not-available",
          "available-online",
          "available-at-another-branch"
        ].includes(_availability)
      }
    },
    data() {
      return {
        dominantColor: null
      };
    },
    methods: {
      /**
       * Proxy for cutText. See the @/lib/utils/cut-text.js
       */
      _cutText: cutText
    },
    async mounted() {
      this.dominantColor = getDominantImageColor(this.$refs.image);
      this.dominantColor = await this.dominantColor;
    }
  };
</script>
