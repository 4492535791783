<template>
  <div
    :class="[
      'easyscreen-button-group',
      `easyscreen-button-group_${ gap }`,
      `easyscreen-button-group_${ gapSide }`
    ]"
  >
    <slot></slot>
  </div>
</template>

<style lang="less" src="./button-group.less" />
<script>
  export default {
    name: "easyscreen-button-group",
    props: {
      /*
       * The gap between buttons. Default "normal".
       * Possible values:
       * - "small"(8px)
       * - "normal"(16px)
       * - "medium"(24px)
       * - "big"(32px).
       */
      gap: {
        type: String,
        default: "normal",
        validator: (_gap) => ["small", "normal", "medium", "big", "auto"].includes(_gap)
      },
      /*
       * The side of element to from which the gap will counts. Default: "right".
       * Possible values:
       * - "left"
       * - "both" (half of gap number for each side.)
       * - "right"
       */
      gapSide: {
        type: String,
        default: "right",
        validator: (_gapType) => ["left", "both", "right"].includes(_gapType)
      }
    }
  };
</script>
