<template>
  <materials-scanner
    @screen-closed="() => $emit('closed')"
    @open-loan="() => $emit('open-loan')"
    @find-by-tag="(tag, callback) => $emit('find-by-tag', tag, callback)"
    ref="materialsScanner"
  />
</template>

<script>
  import MaterialsScanner from "../../../materials-scanner/materials-scanner.vue";

  export default {
    name: "easyscreen-menu-scan-screen",
    methods: {
      /**
       * Open the self check scan screen.
       *
       * @param {Function} callback - Callback, will be called when screen is opend.
       */
      show(callback) {
        this.$refs.materialsScanner.show("scan", callback);
      },
      /**
       * Close the self check scan screen.
       *
       * @param {Function} callback - Callback, will be called when screen is closed.
       */
      hide(callback) {
        this.$refs.materialsScanner.hide("scan", callback);
      }
    },
    components: {
      "materials-scanner": MaterialsScanner
    }
  };
</script>
