<template>
  <div>
    <failed-operations
      ref="failedOperationsTemplate"
      :failed-operations="failedOperations"
    />
  </div>
</template>

<script>
  import FailedOperations from "./failed-operations.vue";

  import { RequestError } from "@/lib/errors.js";

  export default {
    name: "screen-control--safety-mode",
    data() {
      return  {
        failedOperations: []
      };
    },
    methods: {
      async _performSavedOperations() {
        const operations = await this.$easyscreenRequest.friendlyFrank.operations();

        for (let operation of operations) {
          try {
            if (operation.action === "loan") {
              const loanResponse = await this.$easyscreenRequest.lmsConnector.loanMaterial({
                cpr: operation.cpr,
                pin: operation.pin,
                materialIds: operation.materials
              });

              const failedMaterials = operation.materials.filter(materialId => {
                return loanResponse[materialId] === undefined;
              });

              if (failedMaterials.length !== 0) {
                if (loanResponse.isUserBlocked) {
                  throw new RequestError({
                    message: `${ this.$l10n("Account is blocked.") }`
                  });
                }

                throw new RequestError({
                  message: `Loan rejected for material with id: '${ failedMaterials.join(", ") }'.`
                });
              }
            } else if (operation.action === "return") {
              const unloanResponse = await this.$easyscreenRequest.lmsConnector.unloanMaterial({
                materialId: operation.materials
              });

              const failedMaterials = operation.materials.filter(materialId => {
                return !unloanResponse.unloanResult.includes(materialId);
              });

              if (failedMaterials.length !== 0) {
                throw new RequestError({
                  message: `Returned material (${ failedMaterials.join(", ") }) not found in response.`
                });
              }
            }
          } catch (error) {
            this.failedOperations = this.failedOperations.concat([
              Object.assign(operation, { message: error.message })
            ]);
          }

          await this.$easyscreenRequest.friendlyFrank.deleteOperation({
            id: operation._id
          });
        }

        if (this.failedOperations.length !== 0) {
          await this.$nextTick();
          const publicData = await this.$easyscreenRequest.lmsConnector.getConsumerPublicData();

          if (publicData.email) {
            await this.$easyscreenRequest.lmsConnector.email({
              to: publicData.email,
              html: this.$refs.failedOperationsTemplate.toHTML(),
              subject: this.$l10n("Filed operations in safety mode")
            });
          }

          for (let failedOperation of this.failedOperations) {
            await this.$easyscreenRequest.friendlyFrank.deleteOperation({
              id: failedOperation._id
            });
          }
          this.failedOperations = [];
        }
      },
      _startSafetyModeChecking() {
        this._stopSafetyModeChecking();

        this._safetyModeCheckingHandler = (isLmsOnline) => {
          this.$store.dispatch("setSafetyMode", isLmsOnline === false);

          if (isLmsOnline === true) {
            this._performSavedOperations();
          }
        };

        this.$easyscreenScreenControl.startLmsPing();
        this.$easyscreenScreenControl.on("lms-status-changed", this._safetyModeCheckingHandler);
      },
      _stopSafetyModeChecking() {
        this.$easyscreenScreenControl.stopLmsPing();

        if (this._safetyModeCheckingHandler) {
          this.$easyscreenScreenControl.off("lms-status-changed", this._safetyModeCheckingHandler);
          this._safetyModeCheckingHandler = null;
        }
      }
    },
    async mounted() {
      if (this.$easyscreenConfig.get("enable.safetyMode")) {
        this._startSafetyModeChecking();
        if (await this.$easyscreenScreenControl.isLmsOnline()) {
          this._performSavedOperations();
        }
      }
    },
    beforeDestroy() {
      this._stopSafetyModeChecking();
    },
    components: {
      "failed-operations": FailedOperations
    }
  };
</script>
