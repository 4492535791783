var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"material-promotion-carousel-multimple-view"},[(_vm.title)?_c('h1',{staticClass:"material-promotion-carousel-multimple-view--title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_c('easyscreen-carousel',{ref:"carousel",staticClass:"material-promotion-carousel-multimple-view--carousel",attrs:{"type":"infinite","layout-rows":1,"layout-columns":_vm.columns,"step":100 / _vm.columns,"position-offset":-100 / _vm.columns * 0.5 + 50},on:{"before-position-change":(_, _followingSlideIndex) => {
      _vm.activeSlideIndex = _followingSlideIndex % _vm.elements.length;
      if (_vm.activeSlideIndex < 0) {
        _vm.activeSlideIndex = _vm.elements.length + _vm.activeSlideIndex;
      }
    }}},_vm._l((_vm.modifiedElements),function(element,elementIndex){return _c('div',{key:`element-${ element.id }`,class:[
        'material-promotion-carousel-multimple-view--element-wrapper',
        { 'material-promotion-carousel-multimple-view--element_active': _vm.activeSlideIndex === elementIndex }
      ]},[_c('div',{staticClass:"material-promotion-carousel-multimple-view--element"},[_c('img',{staticClass:"material-promotion-carousel-multimple-view--element-image",attrs:{"draggable":"false","src":_vm.$easyscreenRequest.lmsConnector.wrapCover(element.cover)}}),_c('div',{staticClass:"material-promotion-carousel-multimple-view--element-overlay"})])])}),0),_c('div',{staticClass:"material-promotion-carousel-multimple-view--element-info"},[(_vm.activeElement)?[_c('h2',{staticClass:"material-promotion-carousel-multimple-view--element-title"},[_vm._v(" "+_vm._s(_vm.activeElement.title)+" ")]),_c('div',{staticClass:"material-promotion-carousel-multimple-view--element-meta"},[(_vm.activeElement.author)?_c('span',[_vm._v(" "+_vm._s(_vm.activeElement.author)+" ")]):_vm._e(),(_vm.activeElement.year)?_c('span',[_vm._v(" ("+_vm._s(_vm.activeElement.year)+") ")]):_vm._e()]),(_vm.activeElement.description && _vm.withMetaInformation !== true)?_c('div',{staticClass:"material-promotion-carousel-multimple-view--element-teaser"},[_vm._v(" "+_vm._s(_vm._cutText(_vm.activeElement.description, _vm.maxTeaserLength))+" ")]):_vm._e(),(_vm.withMetaInformation === true)?_c('div',{class:[
          'material-promotion-carousel-multimple-view--element-teaser',
          'material-promotion-carousel-multimple-view--additional-element-meta'
        ]},[_c('meta-information-table',{attrs:{"standalone":true,"metaPair":_vm.activeElement.metaOnly}}),_c('meta-information-table',{attrs:{"standalone":true,"metaPair":_vm.activeElement.placementsOnly}})],1):_vm._e()]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }