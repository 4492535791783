import l10n from "@/lib/localization/localization.js";

export default {
  methods: {
    /**
     * Fetch material suggested lists (recommended, same author and etc).
     * @async
     *
     * @param {Object} options - The fetch options.
     * @param {String} options.id - The id\faustNumber of material.
     * @param {Boolean} [options.withCoversOnly=false] - List materials only with covers.
     * @param {Boolean} [options.recommendationsInSuggestedLists=false] - Show the recommendations at the suggested lists.
     *
     * @throws {wrapXMLHttpError} - The ajax request error.
     * @returns {Object} The material detail.
     */
    async _fetchMaterialSuggestedLists(options) {
      let handler = this.$easyscreenRequest.lmsConnector.lists;
      if (options.recommendationsInSuggestedLists) {
        handler = this.$easyscreenRequest.lmsConnector.extendedLists;
      }

      let lists = await handler({
        faustNumber: options.id,
        withCoversOnly: options.withCoversOnly
      });

      /* Remove target material from suggestions. */
      Object.keys(lists).map(listName => {
        /* List with other types do not includes requested material. The filtration by type there not needed.  */
        if (listName === "otherTypes") {
          return;
        }

        if (Array.isArray(lists[listName])) {
          let materialsOrder = [];
          let materialsMap = {
            [options.materialDetail.title]: {
              [(options.materialDetail.type || "").toLowerCase()]: options.materialDetail
            }
          };

          lists[listName].forEach(material => {
            const isTitleDuplicate = materialsMap[material.title];
            if (!isTitleDuplicate) {
              materialsOrder.push(material.title);
              materialsMap[material.title] = {};
            }

            materialsMap[material.title][(material.type || "").toLowerCase()] = material;
          });

          lists[listName] = materialsOrder.map(materialTitle => {
            return materialsMap[materialTitle]["bog"] // "book" for Danmark libraries
              || materialsMap[materialTitle]["bok"] // "book" for Norway libraries
              || materialsMap[materialTitle][Object.keys(materialsMap[materialTitle])[0]];
          }).filter(Boolean);
        }
      });

      /* Remove duplicates by material title. */

      return [{
        title: l10n("Recommended items"),
        id: "recommendation-materials",
        materials: lists.recommendations
      }, {
        title: l10n("Others who have borrowed"),
        id: "borrowed-materials",
        materials: lists.borrowed
      }, {
        title: l10n("In the same series"),
        id: "series-materials",
        materials: lists.series
      }, {
        title: l10n("By the same author"),
        id: "same-author-materials",
        materials: lists.sameAuthor
      }, {
        title: l10n("Other types of the same material"),
        id: "other-types-materials",
        materials: lists.otherTypes
      }].filter(list => Array.isArray(list.materials) && list.materials.length !== 0);
    }
  }
};
