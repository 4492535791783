<template>
  <div>
    <modal-fullscreen
      ref="modal"

      colorScheme="easyscreen-menu"

      @before-open="(event) => $emit('before-open', event)"
      @opened="(event) => $emit('opened', event)"
      @before-close="(event) => $emit('before-close', event)"
      @closed="(event) => $emit('closed', event)"
    >
      <template slot="header-left">
        <easyscreen-circle-button
          icon="/images/es-menu/home_icon.png"
          icon-type="image"

          @click.native="hide"
        />
      </template>
      <template slot="header-center">
        <h1 class="easyscreen-header_1">
          {{ _l10n("Return") }}
        </h1>
      </template>
      <template slot="content">
        <materials-scanner-sendmail
          v-if="stage === 'email-form'"
          :processed-materials-label="_l10n('Items returned')"
          :processed-materials="_precessedMaterials().length"
          :failed-materials="_failedMaterials().length"
          :default-email="defaultEmail"
          :with-email-autocomplete="true"
          @close="hide"
          @send="_emailReceipt"
        />
        <div style="display: none">
          <materials-scanner-return-email-template
            ref="emailTemplate"

            :materials="_materialsToEmailTemplate()"
          />
        </div>

        <materials-scanner-layout v-if="stage === 'return'">
          <template slot="header">
            {{ _l10n("Hand in materials") }}
          </template>
          <template slot="content-info">
            <div
              :class="[
                'material-scanner-instruction material-scanner-instruction_return text-center',
                { 'material-scanner-instruction_barcode': $easyscreenConfig.get('enable.selfcheckViaBarcode') },
                orientation
              ]"
            >
              <p class="es-mb-1 text-secondary">
                {{ _l10n('Place the materials on the scanner you want to return') }}
              </p>
              <p class="es-mb-1 text-disabled">
                {{ _l10n('Put them on the shelf when they are returned - Thank you!') }}
              </p>

              <img
                v-if="_isLandscape()"
                class="material-scanner-instruction--image"
                :src="landscapeInstructionImage"
              />
              <img
                v-if="_isPortrait()"
                class="material-scanner-instruction--image"
                :src="portraitInstructionImage"
              />
              <p class="material-scanner-instruction_step material-scanner-instruction_step1 text-bold text-secondary">
                {{ _l10n($easyscreenConfig.get('enable.selfcheckViaBarcode') ? 'Scan the material' : 'Place material on scanner') }}
              </p>
              <p class="material-scanner-instruction_step material-scanner-instruction_step2 text-bold text-secondary">
                {{ _l10n('Wait for checkmark') }}
              </p>
              <p class="material-scanner-instruction_step material-scanner-instruction_step3 text-bold text-secondary">
                {{ _l10n('Place according to message') }}
              </p>
            </div>
          </template>
          <template slot="content-materials">
            <materials-scanner-material
              v-for="material in inventory"
              :key="material.materialId"
              :material="Object.assign({}, material.detail, material)"
              :status="_getMaterialStatus(material)"
              :message="_getMaterialMessage(material)"
              :disabled="material.disabled"
            />
          </template>
          <template slot="footer-buttons">
            <easyscreen-button-group>
              <easyscreen-button
                color="secondary"
                modificator="fixed-width_small"
                @click.native="hide"
              >
                {{ _l10n("Close without receipt") }}
              </easyscreen-button>
              <easyscreen-button
                v-if="$easyscreenConfig.get('enable.print') && _printerIsOnline()"
                color="primary"
                modificator="fixed-width_small"
                @click.native="_printReceipt"
                :disabled="_precessedMaterials().length === 0 || _inProgressMaterials().length !== 0"
              >
                {{ _l10n("Print receipt") }}
              </easyscreen-button>
              <easyscreen-button
                color="primary"
                modificator="fixed-width_small"
                @click.native="stage='email-form'"
                :disabled="_precessedMaterials().length === 0 || _inProgressMaterials().length !== 0"
              >
                {{ _l10n("Email receipt") }}
              </easyscreen-button>
            </easyscreen-button-group>
          </template>
          <template slot="footer-counter">
            <div :class="[
              'materials-scanner-counter',
              orientation
            ]">
              <p>{{ _l10n('Items returned') }}: {{ _precessedMaterials().length }}</p>
              <p :class="{
                'text-disabled': _failedMaterials().length === 0,
                'text-danger': _failedMaterials().length !== 0
              }">{{ _l10n('Items failed') }}: {{ _failedMaterials().length }}</p>
            </div>
          </template>
        </materials-scanner-layout>
      </template>
    </modal-fullscreen>

    <modal-alert ref="alertModal" />
    <modal-alert
      ref="sortingBinModal"
      :click-to-close="false"
    >
      <template slot="header">{{ _l10n("Loan confirmation") }}</template>
      <template slot="content">
        <p class="es-mb-2">{{ _l10n('Place next materials to sorting bin:') }}</p>
        <p
          v-for="material in _getWaitingForBinMaterials()"
          :key="`${ material.materialId }_${ material.index }`"
        >
          <span class="es-ml-1">- {{ _get(material, "detail.title", _l10n("Untitled")) }}</span>
          <span class="text-cursive"> ({{ _l10n("Material number") }}: {{ material.materialId }})</span>
          <span class="text-cursive" v-if="material.packSize > 1">
            {{ _l10n("Part") }} {{ material.index }}
          </span>
        </p>
      </template>
    </modal-alert>
    <modal-confirm ref="confirmModal" />
    <modal-confirm
      ref="materialsConfirmationModal"
      @ok="() => _processMaterials( _getRequiredConfirmation() )"
      @cancel="() => _cancel( _getRequiredConfirmation() )"
    >
      <template slot="header">{{ _l10n("Action is required") }}</template>
      <template slot="content">
        <div>
          {{ _l10n("You are about to loan the following") }}
          {{ _l10nPlural(_getRequiredConfirmation(), "item") }}?:
          <template v-for="loanMaterial in processingInventory">
            <div v-if="loanMaterial.requiredConfirmation" :key="loanMaterial.materialId" class="text-cursive">
              - {{ _getMaterialTitle(loanMaterial.materialId) }}
            </div>
          </template>
        </div>
      </template>
    </modal-confirm>
  </div>
</template>

<style lang="less" src="../../core/mixins.less"></style>
<style lang="less" src="../parts/instruction.less"></style>
<style lang="less" src="../parts/counter.less"></style>
<script>
  import ModalFullscreen from "../../core/modal/fullscreen.vue";
  import ModalAlert from "../../core/modal/alert.vue";
  import ModalConfirm from "../../core/modal/confirm.vue";
  import EasyscreenButton from "../../core/button/button.vue";
  import EasyscreenButtonGroup from "../../core/button/button-group.vue";
  import EasyscreenCircleButton from "../../core/button/circle-button.vue";
  import MaterialsScannerLayout from "../parts/layout.vue";
  import MaterialsScannerMaterial from "../parts/material.vue";
  import MaterialsScannerSendmail from "../parts/sendmail.vue";
  import MaterialsScannerReturnEmailTemplate from "../email-templates/return.vue";

  import { mapState } from "vuex";
  import * as querystring from "querystring";
  import { get, isFunction, castArray } from "lodash";
  import { ProviderError, ValidationError, wrapXMLHttpError } from "../../../lib/errors.js";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../../core/mixins/orientation.js";
  import reEmitMixin from "../../core/mixins/re-emit.js";
  import commonAPIMixin from "./common-api-mixin.js";
  import barcodeSelfcheckSupportMinix from "./barcode-selfcheck-support-minix.js";

  export default {
    name: "materials-scanner-return",
    mixins: [reEmitMixin, orientationMixin, commonAPIMixin, barcodeSelfcheckSupportMinix],
    props: {
      withPrinter: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        stage: "",
        sortingBinWaiting: []
      };
    },
    computed: {
      ...mapState({
        safetyModeEnabled: state => state.safetyModeEnabled
      }),
      defaultEmail() {
        const urlOptions = querystring.parse(window.location.href.split("#")[1]);
        return urlOptions.defaultEmail;
      },
      /* Proxy for modal isShown variable. */
      isShown: {
        cache: false,
        get() {
          return this.$refs.modal && this.$refs.modal.isShown;
        }
      },
      landscapeInstructionImage: {
        get() {
          if (this.$easyscreenConfig.get("enable.selfcheckViaBarcode")) {
            return "/images/scanner/unloan/unloan-barcode-instruction-for-landscape.png";
          }

          return "/images/scanner/unloan/unloan-instruction-for-landscape.png";
        }
      },
      portraitInstructionImage: {
        get() {
          if (this.$easyscreenConfig.get("enable.selfcheckViaBarcode")) {
            return "/images/scanner/unloan/unloan-barcode-instruction-for-portrait.png";
          }

          return "/images/scanner/unloan/unloan-instruction-for-portrait.png";
        }
      }
    },
    methods: {
      /* Public .show method see in ./common-api-mixin.js */
      /**
       * Hide the return screen.
       *
       * @param {Function} callback - .
       */
      hide(callback) {
        this.$refs.modal.hide(() => {
          this.stage = "return";

          if (isFunction(callback)) {
            callback();
          }
        });
      },
      /**
       * The proxy method of `lodash.get`.
       */
      _get: get,
      /* Proxy for localization function. */
      _l10n: l10n,
      _l10nPlural(collection, single, _plural) {
        if (!_plural) {
          _plural = single + "s";
        }

        single = l10n(single);
        _plural = l10n(_plural);

        return collection.length === 1 ? single : _plural;
      },
      /**
       * Shows the return screen with confirmation popup if some materials on scanner.
       */
      _show() {
        this.stage = "return";
        this.$refs.modal.show(() => {
          this._showConfirmationModal();
        });
      },
      /**
       * Return the passed materials.
       * @async
       *
       * @param {String[]} materialIds - List of material ids.
       *
       * @returns {Promise} - The promise-based callback, will be called when each material will returned or failed.
       */
      _processMaterials(materialIds) {
        return Promise.allSettled(materialIds.map(async (materialId) => {
          const returnResult = await this._returnSingle(materialId);
          if (this.$easyscreenConfig.get("enable.sortingBin") && get(returnResult, "raw[0].sortBin") === "stay") {
            await this._waitForSortingBin(materialId);
          }

          return returnResult;
        }));
      },
      /**
       * Save the return options - used in safety mode when the actual loan not possible.
       * @async
       *
       * @param {String[]} materialIds - The list of material id.
       *
       * @returns {Promise} - The promise-based callback, will be called when material will saved or failed.
       */
      async _saveReturnOperation(options) {
        const materialIds = castArray(options.materialId).filter(Boolean).map(materialId => this.fixMaterialId(materialId));
        await this.$easyscreenRequest.friendlyFrank.saveOperation({
          action: "return",
          screenId: this.$easyscreenScreenId,
          materials: materialIds
        });

        let result = {
          unloanResult: materialIds,
          messages: {}
        };

        materialIds.forEach(materialId => {
          result.messages[materialId] = this.$l10n("Operation saved");
        });

        return result;
      },
      /**
       * Return single material.
       * @async
       *
       * @param {String} materialId - The material id.
       *
       * @returns {Promise} - The promise-based callback, will be called when material will returned or failed.
       */
      async _returnSingle(materialId) {
        const processingInfo = this.processingInventory[materialId];
        await this._waitMaterialForLoadingAndValidate(materialId);

        const cancel = () => {
          processingInfo.status = "CANCELED";
          processingInfo.message = l10n("Material was removed too early, please try to unloan it again.");
          this.$forceUpdate();
        };

        if (!this.$easyscreenConfig.get("enable.disableAlarmHandling") && !this.$easyscreenConfig.get("enable.selfcheckViaBarcode")) {
          try {
            await this.$friendlyFrankScanner.setAlarm({ action: "on", materialId: materialId });
            await this.$friendlyFrankScanner.getStatus({ materialId: materialId });
          } catch (error) {
            this.$easyscreenStatistic.unloanScannerFailed({ materialId: materialId });
            cancel();
            throw error;
          }

          const materialInventory = this.$friendlyFrankScanner.inventory[materialId];
          if (!materialInventory || materialInventory.alarm !== "enabled") {
            cancel();
            throw new ValidationError(Object.assign({ message: "Alarm not enabled." }));
          }
        }

        try {
          let response;
          if (this.safetyModeEnabled) {
            response = await this._saveReturnOperation({ materialId: materialId });
          } else {
            response = await this.$easyscreenRequest.lmsConnector.unloanMaterial({ materialId: this.fixMaterialId(materialId) });
          }

          if (response.unloanResult.indexOf(materialId) === -1) {
            throw new ProviderError({
              message: `Returned material (${ materialId }) not found in response.`,
              body: response.body
            });
          }

          this.$easyscreenStatistic.unloanScanner({ materialId: materialId });

          processingInfo.status = "ACCEPTED";
          // eslint-disable-next-line
          processingInfo.message = `${l10n("Returned")}<br/><span class="materials-scanner-material--sorting-bin-message">(${response.messages[materialId] || l10n("Place on shelf")})</span>`;
          this.$forceUpdate();

          return {
            message: response.messages[materialId],
            raw: response.body
          };
        } catch (error) {
          this.$easyscreenStatistic.unloanScannerFailed({ materialId: materialId });

          processingInfo.status = "REJECTED";
          processingInfo.message = `${ l10n("Return failed!") }<br/>${ l10n("Item is not unloaned") }`;
          this.$forceUpdate();

          throw wrapXMLHttpError(error);
        }
      },
      /**
       * Wait when selected materials will be fully placed to sorting bin.
       * @async
       * @listens materials-scanner-return~inside-bin:${materialId}_${materialIndex}
       *
       * @param {String} materialId - List of material ids.
       *
       * @returns {Promise} The promise-based callback, will be called when all selected material are placed to bin.
       */
      _waitForSortingBin(materialId) {
        if (this.sortingBinWaiting.length === 0) {
          this.$refs.sortingBinModal.show();
        }

        let isWaiting = this.sortingBinWaiting.find(d => d.materialId === materialId);
        if (!isWaiting) {
          let material = this.inventory.find(material => material.materialId === materialId);
          let waitingFor = [];

          for (let index = 1; index <= material.packSize; index++) {
            this.sortingBinWaiting.push({ materialId: materialId, index: index });
            waitingFor.push(new Promise((resolve) => {
              this.$once(`inside-bin:${ materialId }_${ index }`, resolve);
            }));
          }

          return Promise.allSettled(waitingFor);
        }

        return Promise.resolve();
      },
      /**
       * Get the materials which should be placed to sorting bin.
       *
       * @returns {CombinedProcessingMaterialInfo[]} The list of returned materials.
       */
      _getWaitingForBinMaterials() {
        return this.sortingBinWaiting.map(d => {
          return Object.assign({}, d, this._materialDataByMaterialId(d.materialId));
        });
      },
      /**
       * Handler of sorting bin inventory-updated event.
       * @fires materials-scanner-return#inside-bin:${materialId}_${materialIndex}
       *
       * @param {RFIDScannerInventory} inventory - The inventory of sorting bin.
       */
      _onSotringBinInventoryUpdate(inventory) {
        Object.keys(inventory).forEach(materialId => {
          let packData = inventory[materialId];
          packData.indexes.forEach(materialIndex => {
            /**
             * Emits the material placed to workign bin.
             *
             * @event materials-scanner-return#inside-bin:${materialId}_${materialIndex
             */
            this.$emit(`inside-bin:${ materialId }_${ materialIndex }`);
            this.sortingBinWaiting = this.sortingBinWaiting.filter(d => {
              return !(d.materialId === materialId && d.index === materialIndex);
            });

            if (this.sortingBinWaiting.length === 0) {
              this.$refs.sortingBinModal.hide();
            }
          });
        });
      }
    },
    mounted() {
      this.$friendlyFrankSortingBin.on("inventory-updated", this._onSotringBinInventoryUpdate);
    },
    beforeDestroy() {
      this.$friendlyFrankSortingBin.off("inventory-updated", this._onSotringBinInventoryUpdate);
    },
    components: {
      "modal-fullscreen": ModalFullscreen,
      "modal-alert": ModalAlert,
      "modal-confirm": ModalConfirm,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-button-group": EasyscreenButtonGroup,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "materials-scanner-layout": MaterialsScannerLayout,
      "materials-scanner-material": MaterialsScannerMaterial,
      "materials-scanner-sendmail": MaterialsScannerSendmail,
      "materials-scanner-return-email-template": MaterialsScannerReturnEmailTemplate
    }
  };
</script>
