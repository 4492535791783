<template>
  <div class="easyscreen-tear-off-calendar">
    <div class="easyscreen-tear-off-calendar--day-of-week">
      {{ dayOfWeek }}
    </div>
    <div class="easyscreen-tear-off-calendar--body">
      <div class="easyscreen-tear-off-calendar--day-of-month">
        {{ dayOfMonth }}
      </div>
      <div class="easyscreen-tear-off-calendar--month">
        {{ month }}
      </div>
      <div
        v-if="withTime"
        class="easyscreen-tear-off-calendar--time"
      >
        {{ time }}
      </div>
    </div>
  </div>
</template>

<style src="./tear-off-calendar.less" lang="less"></style>

<script>
  import moment from "moment";

  export default {
    name: "easyscreen-tear-off-calendar",
    props: {
      /* Date for display. */
      date: String,
      /* See format options here: https://momentjs.com/docs/#/displaying/ */
      dayOfWeekFormat: {
        type: String,
        default: "dddd"
      },
      dayOfMonthFormat: {
        type: String,
        default: "D"
      },
      monthFormat: {
        type: String,
        default: "MMM"
      },
      timeFormat: {
        type: String,
        default: "HH:mm"
      },
      /* Flag for use the time at calendar (used for cinema type of node list). */
      withTime: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      /**
       * Getter of day of week from `date` with format `this.dayOfWeekFormat`.
       */
      dayOfWeek() {
        return moment(this.date).format(this.dayOfWeekFormat);
      },
      /**
       * Getter of day of month from `date` with format `this.dayOfMonthFormat`.
       */
      dayOfMonth() {
        return moment(this.date).format(this.dayOfMonthFormat);
      },
      /**
       * Getter of month from `date` with format `this.monthFormat`.
       */
      month() {
        return moment(this.date).format(this.monthFormat);
      },
      /**
       * Getter of time from `date` with format `this.timeFormat`.
       */
      time() {
        return moment(this.date).format(this.timeFormat);
      }
    }
  };
</script>
