var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'materials-scanner-material',
    { 'materials-scanner-material_disabled': _vm.disabled }
  ]},[(_vm.status === 'LOADING')?_c('div',[_vm._m(0)]):(_vm.status === 'NOT_FOUND')?_c('div',{staticClass:"materials-scanner-material--placeholder text-upper"},[_vm._v(" "+_vm._s(_vm._l10n("No material found"))+" "),_c('br'),_c('span',{staticClass:"text-small"},[_vm._v("("+_vm._s(_vm._l10n("Material number"))+": "+_vm._s(_vm.material.materialId)+")")])]):(_vm.status === 'UNKNOWN')?_c('div',{staticClass:"materials-scanner-material--placeholder text-upper text-danger text-center"},[_vm._v(" "+_vm._s(_vm._l10n("Unknown material type"))+" "),_c('br'),_c('span',{staticClass:"text-small text-danger"},[_vm._v("("+_vm._s(_vm._l10n("Material number"))+": "+_vm._s(_vm.material.materialId)+")")])]):(_vm.material)?_c('div',{staticClass:"materials-scanner-material--content"},[_c('div',[_c('img',{staticClass:"materials-scanner-material--image",attrs:{"src":_vm.material.cover || '/images/defaultCover.jpg'}})]),_c('div',[_c('h4',{staticClass:"es-mb-2"},[_vm._v(_vm._s(_vm.material.title))]),_c('p',{staticClass:"es-mb-4"},[_vm._v(_vm._s(_vm.material.author))]),_c('p',[_vm._v(" "+_vm._s(_vm._l10n("Material number"))+": "+_vm._s(_vm.material.materialId)+" "),(!_vm.material.isComplete && !_vm.disabled)?_c('span',{staticClass:"text-primary text-cursive"},[_vm._v(" ("+_vm._s(_vm._l10n("materials in pack"))+": "),_vm._l((_vm.material.packSize),function(index){return _c('span',{key:index,class:{
              'text-warn': !_vm.material.indexes.includes(index),
              'text-primary': _vm.material.indexes.includes(index)
            }},[_vm._v(_vm._s(index)+_vm._s(index !== _vm.material.packSize ? ", " : ""))])}),_vm._v(") ")],2):_vm._e()])]),_c('div',[_c('div',{staticClass:"materials-scanner-material--status"},[_c('span',[_c('i',{class:['materials-scanner-material--icon', 'fa', {
            'fa-spin fa-spinner': _vm.status === 'IN_PROGRESS',
            'fa-check': _vm.status === 'ACCEPTED',
            'fa-times': ['CANCELED', 'REJECTED'].includes(_vm.status),
            'text-primary': _vm.status === 'ACCEPTED',
            'text-warn': _vm.status === 'CANCELED',
            'text-danger': _vm.status === 'REJECTED'
          }]})]),(_vm.message)?_c('p',{domProps:{"innerHTML":_vm._s(_vm.message)}}):_vm._e()])])]):_vm._e()])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"materials-scanner-material--placeholder"},[_c('i',{staticClass:"fa fa-spin fa-spinner"})])
}]

export { render, staticRenderFns }