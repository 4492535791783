<template>
  <div :class="['patron-profile--user-account', orientation]">
    <modal-layout>
      <template slot="header">
        {{ _l10n("User account - See profile information, edit favorite library and default reservation time") }}
      </template>
      <template slot="content">
        <form>
          <!-- User info: start -->
          <easyscreen-form-section
            :content-style="_isLandscape() ? {
              'grid-template-columns': provider !== 'bibliofil' ? '1fr 1fr 1fr 1fr' : '1fr 1fr',
              'grid-template-rows': 'repeat(1, 77px)'
            } : {}"
          >
            <easyscreen-form-group :title="_l10n('Full name')">
              <input
                class="easyscreen-input easyscreen-input_full-width"
                type="text"
                :value="data.name"
                disabled
                readonly
              />
            </easyscreen-form-group>

            <easyscreen-form-group :title="_l10n('Street and number')">
              <input
                class="easyscreen-input easyscreen-input_full-width"
                type="text"
                :value="data.address.street"
                disabled
                readonly
              />
            </easyscreen-form-group>

            <easyscreen-form-group :title="_l10n('Zip')">
              <input
                class="easyscreen-input easyscreen-input_full-width"
                type="text"
                :value="data.address.zip"
                disabled
                readonly
              />
            </easyscreen-form-group>

            <easyscreen-form-group :title="_l10n('City')">
              <input
                class="easyscreen-input easyscreen-input_full-width"
                type="text"
                :value="data.address.city"
                disabled
                readonly
              />
            </easyscreen-form-group>
          </easyscreen-form-section>
          <easyscreen-form-section
            v-if="withEmailEdit || withPhoneEdit || withServiceNotification || withPickupBranch || withMaximumWaitingTime"
            :content-style="_isLandscape() ? {
              'grid-template-columns': '1fr 1fr 1fr 1fr',
              'grid-template-rows': 'repeat(1, 77px)'
            } : {}"
          >
            <easyscreen-form-group v-if="withEmailEdit" :title="_l10n('Email')">
              <input
                class="easyscreen-input easyscreen-input_full-width"
                type="text"
                :value="email"
                readonly
                @click="() => _editForm({ type: 'text', field: 'email', label: _l10n('Email') })"
              >
            </easyscreen-form-group>

            <easyscreen-form-group v-if="withPhoneEdit" :title="_l10n('Phone')">
              <input
                class="easyscreen-input easyscreen-input_full-width"
                type="text"
                :value="phone"
                readonly
                @click="() => _editForm({ type: 'number', field: 'phone', label: _l10n('Phone') })"
              >
            </easyscreen-form-group>

            <easyscreen-form-group v-if="withServiceNotification" :title="_l10n('Sevice notification')">
              <easyscreen-select
                :label="_l10n('Sevice notification')"
                :options="[{
                  label: _l10n('Disabled'),
                  value: ''
                }, {
                  label: _l10n('Email only'),
                  value: 'email'
                }, {
                  label: _l10n('SMS'),
                  value: 'SMS'
                }, {
                  label: _l10n('Email + SMS'),
                  value: 'emailSMS'
                }]"
                :value="_getServiceNotificationValue()"
                ref="seviceNotificationSelect"
              />
            </easyscreen-form-group>

            <easyscreen-form-group v-if="withPickupBranch" :title="_l10n('Pick up reservation on')">
              <easyscreen-select
                :label="_l10n('Pick up reservation on')"
                :options="_branchesToSelect()"
                :value="data.preferredBranch"
                ref="pickupBranchSelect"
              />
            </easyscreen-form-group>

            <easyscreen-form-group v-if="withMaximumWaitingTime" :title="_l10n('Maximum waiting time for reservations')">
              <easyscreen-select
                :disabled="true"
                :label="_l10n('Maximum waiting time for reservations')"
                :options="[{
                  label: _l10n('30 days'),
                  value: 30
                }, {
                  label: _l10n('60 days'),
                  value: 60
                }, {
                  label: _l10n('90 days'),
                  value: 90
                }, {
                  label: _l10n('120 days'),
                  value: 120
                }, {
                  label: _l10n('180 days'),
                  value: 180
                }, {
                  label: _l10n('500 days'),
                  value: 500
                }, {
                  label: _l10n('720 days'),
                  value: 720
                }]"
                :value="data.defaultInterestPeriod"
                ref="interestPeriodSelect"
              />
            </easyscreen-form-group>
          </easyscreen-form-section>
          <!-- User info: end -->

          <!-- Reservation options: start -->
          <easyscreen-form-section
            v-if="withReservationsOnHold"
            :title="_isPortrait() ? _l10n('Reservations on hold') : ''"
            :content-style="_isLandscape() ? {
              'grid-template-columns': '1fr 1fr 1fr',
              'grid-template-rows': 'repeat(1, 77px)'
            } : {}"
          >
            <easyscreen-form-group
              :title="
                _isLandscape()
                  ? _l10n('Reservations on hold') + ' - ' + _l10n('From').toLowerCase()
                  : _l10n('From')
              "
            >
              <div class="easyscreen-grid easyscreen-grid_three-cols">
                <easyscreen-select
                  :label="_l10n('Reservations on hold') + ' - ' + _l10n('From').toLowerCase()"
                  :options="_getDays()"
                  :value="_convertDate(_get(data, 'onHold.from'), 'DD')"
                  ref="onHoldFromDay"
                />
                <easyscreen-select
                  :label="_l10n('Reservations on hold') + ' - ' + _l10n('From').toLowerCase()"
                  :options="_getMonths()"
                  :value="_convertDate(_get(data, 'onHold.from'), 'MM')"
                  ref="onHoldFromMonth"
                />
                <easyscreen-select
                  :label="_l10n('Reservations on hold') + ' - ' + _l10n('From').toLowerCase()"
                  :options="_getYears()"
                  :value="_convertDate(_get(data, 'onHold.from'), 'YYYY')"
                  ref="onHoldFromYear"
                />
              </div>
            </easyscreen-form-group>

            <easyscreen-form-group
              :title="
                _isLandscape()
                  ? _l10n('Reservations on hold') + ' - ' + _l10n('To').toLowerCase()
                  : _l10n('To')
              "
            >
              <div class="easyscreen-grid easyscreen-grid_three-cols">
                <easyscreen-select
                  :label="_l10n('Reservations on hold') + ' - ' + _l10n('to').toLowerCase()"
                  :options="_getDays()"
                  :value="_convertDate(_get(data, 'onHold.to'), 'DD')"
                  ref="onHoldToDay"
                />
                <easyscreen-select
                  :label="_l10n('Reservations on hold') + ' - ' + _l10n('to').toLowerCase()"
                  :options="_getMonths()"
                  :value="_convertDate(_get(data, 'onHold.to'), 'MM')"
                  ref="onHoldToMonth"
                />
                <easyscreen-select
                  :label="_l10n('Reservations on hold') + ' - ' + _l10n('to').toLowerCase()"
                  :options="_getYears()"
                  :value="_convertDate(_get(data, 'onHold.to'), 'YYYY')"
                  ref="onHoldToYear"
                />
              </div>
            </easyscreen-form-group>
          </easyscreen-form-section>
          <!-- Reservation options: end -->

          <!-- Pin: start -->
          <easyscreen-form-section
            v-if="withPinEdit"
            :content-style="_isLandscape() ? {
              'grid-template-columns': '1fr 1fr 1fr',
              'grid-template-rows': 'repeat(1, 77px)'
            } : {}"
          >
            <easyscreen-form-group :title="_l10n('Existing pin')">
              <easyscreen-password-input
                :readonly="true"
                :value="existingPin"
                @click="() => _editForm({ type: 'pin', field: 'existingPin', label: _l10n('Existing pin') })"
              />
            </easyscreen-form-group>

            <easyscreen-form-group :title="_l10n('New pin')">
              <easyscreen-password-input
                :readonly="true"
                :value="newPin"
                @click="() => _editForm({ type: 'pin', field: 'newPin', label: _l10n('New pin') })"
              />
            </easyscreen-form-group>

            <easyscreen-form-group :title="_l10n('Repeat new pin')">
              <easyscreen-password-input
                :readonly="true"
                :value="newPinRepeat"
                @click="() => _editForm({ type: 'pin', field: 'newPinRepeat', label: _l10n('Repeat new pin') })"
              />
            </easyscreen-form-group>
          </easyscreen-form-section>
          <!-- Pin: end -->
        </form>
      </template>
      <template slot="footer">
        <easyscreen-button
          v-if="hasEditableFields"
          color="primary"
          modificator="fixed-width"
          @click.native="_save"
        >
          {{ _l10n("Save") }}
        </easyscreen-button>
      </template>
    </modal-layout>

    <modal-edit-pin ref="modalPin" />
    <modal-edit-number ref="modalNumber" />
    <modal-edit-text ref="modalText" />
    <modal-alert ref="alert" />
    <loader ref="loader" />
  </div>
</template>

<style lang="less" src="../../../core/input/input.less"></style>
<style lang="less" src="../../../core/form/grid.less"></style>

<script>
  import ModalAlert from "../../../core/modal/alert.vue";
  import ModalLayout from "../../../core/modal/layout.vue";
  import ModalEditPin from "../../../core/modal/edit/pin.vue";
  import ModalEditNumber from "../../../core/modal/edit/number.vue";
  import ModalEditText from "../../../core/modal/edit/text.vue";
  import EasyscreenButton from "../../../core/button/button.vue";
  import EasyscreenFormSection from "../../../core/form/form-section.vue";
  import EasyscreenFormGroup from "../../../core/form/form-group.vue";
  import EasyscreenPasswordInput from "../../../core/input/password.vue";
  import EasyscreenSelect from "../../../core/input/select.vue";
  import Loader from "../../../core/loader/loader.vue";

  import { get, set } from "lodash";
  import capitalize from "capitalize";
  import moment from "moment";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../../../core/mixins/orientation.js";
  import convertDateMixin from "../../../core/mixins/convert-date.js";

  const holdDateFormat = "YYYY-MM-DD";

  export default {
    name: "patron-profile-user-account",
    mixins: [orientationMixin, convertDateMixin],
    props: {
      /* The name of info-data provider, used for partially disable features on screen. */
      provider: String,
      /* The profile data, see response on GET [lms]/[consumer-hash]/patron/info. */
      data: Object,
      /* The list of branches, see response on GET [lms]/[consumer-hash]/barnches. */
      branches: Object,
      /* The authentication user form data: cpr and pin. */
      formData: Object
    },
    data() {
      return {
        email: this.data.email,
        phone: this.data.phone,
        existingPin: "",
        newPin: "",
        newPinRepeat: ""
      };
    },
    computed: {
      withEmailEdit() {
        return this.provider !== "bibliofil";
      },
      withPhoneEdit() {
        return this.provider !== "bibliofil";
      },
      withServiceNotification() {
        return this.provider !== "ex_alma" && this.provider !== "bibliofil";
      },
      withPickupBranch() {
        return this.provider !== "ex_alma" && this.provider !== "bibliofil";
      },
      withMaximumWaitingTime() {
        return this.provider !== "ex_alma" && this.provider !== "bibliofil";
      },
      withReservationsOnHold() {
        return this.provider !== "ex_alma" && this.provider !== "bibliofil";
      },
      withPinEdit() {
        return this.provider !== "ex_alma" && this.provider !== "bibliofil";
      },
      hasEditableFields() {
        return [
          this.withEmailEdit,
          this.withPhoneEdit,
          this.withServiceNotification,
          this.withPickupBranch,
          this.withMaximumWaitingTime,
          this.withReservationsOnHold,
          this.withPinEdit
        ].some(field => field !== false);
      }
    },
    methods: {
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Shows the edit form for selected field.
       * @listens modal~ok
       * @listens modal~closed
       */
      _editForm({ type, field, label }) {
        let value = get(this, field);
        let modal = this.$refs[`modal${ capitalize(type) }`];

        if (!modal) {
          console.warn(`user-account.vue: Edit modal with type ${ type } not found.`);
          console.trace();
          return;
        }

        modal.show({ value, title: label });
        let _setValue = (event) => set(this, field, event.value);
        modal.$once("ok", _setValue);
        modal.$once("closed", () => {
          modal.$off("ok", _setValue);
        });
      },
      /**
       * Converts the branches to select options.
       *
       * @returns {Object[]} The list of branches for select.
       */
      _branchesToSelect() {
        return Object.keys(this.branches).sort((a, b) => {
          if (this.branches[a] > this.branches[b]) {
            return 1;
          }

          if (this.branches[a] < this.branches[b]) {
            return -1;
          }

          return 0;
        }).map((branchId) => {
          return { value: branchId, label: this.branches[branchId] };
        });
      },
      /**
       * Generates the list of years for select.
       *
       * @returns {Object[]] The list of years for select.
       */
      _getYears() {
        var currentYear = (new Date()).getFullYear();
        return [{ label: "YYYY", value: "" }].concat(new Array(4).fill(null).map((_, index) => currentYear + index));
      },
      /**
       * Generates the list of months for select.
       *
       * @returns {Object[]] The list of months for select.
       */
      _getMonths() {
        return [{ label: "MM", value: "" }].concat([
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ].map((month, index) => {
          return {
            label: capitalize(l10n(month)),
            value: (index + 1).toString().padStart(2, "0")
          };
        }));
      },
      /**
       * Generates the list of days for select.
       *
       * @returns {Object[]] The list of days for select.
       */
      _getDays() {
        return [{
          label: "DD", value: ""
        }].concat(
          new Array(31)
            .fill(null)
            .map(
              (_, index) => {
                return (index + 1)
                  .toString()
                  .padStart(2, "0");
              }
            )
        );
      },
      /**
       * Serialized the service notification into the string (this.data.receiveEmail and this.data.receiveSms).
       *
       * @returns {String} The service notification string.
       */
      _getServiceNotificationValue() {
        return [
          this.data.receiveEmail ? "email" : "",
          this.data.receiveSms ? "SMS" : ""
        ].join("");
      },
      /**
       * Parse the service notification string into object.
       *
       * @param {String} string - The service notification value, one of: "", "email", "SMS", "emailSMS".
       *
       * @returns {Object} serviceNotification - The parsed service notification value.
       * @returns {Boolean} serviceNotification.receiveEmail - email notificaiton flag.
       * @returns {Boolean} serviceNotification.receiveSms - sms notificaiton flag.
       */
      _parseServiceNotificationValue(string) {
        return {
          receiveEmail: string.indexOf("email") !== -1,
          receiveSms: string.indexOf("SMS") !== -1
        };
      },
      /**
       * Saves the form changes (using lms PUT /patron/info).
       */
      async _save() {
        let errors = [];
        let data = {};

        if (this.withEmailEdit) {
          data.email = this.email;
        }

        if (this.withPhoneEdit) {
          data.phone = this.phone;
        }

        if (this.withServiceNotification) {
          Object.assign(data, this._parseServiceNotificationValue(this.$refs.seviceNotificationSelect.selected.value));
        }

        if (this.withPickupBranch) {
          data.preferredBranch = this.$refs.pickupBranchSelect.selected.value;
        }

        if (this.withMaximumWaitingTime) {
          data.defaultInterestPeriod = this.$refs.interestPeriodSelect.selected.value;
        }

        if (this.withReservationsOnHold) {
          data.onHold = {};
          let datesValid = ["from", "to"].every(holdType => {
            let date = ["Year", "Month", "Day"].map(datePath => {
              const select = this.$refs[`onHold${ capitalize(holdType) }${ datePath }`];
              return get(select, "selected.value", get(select, "selected"));
            }).join("-");

            if (!moment(date, holdDateFormat, true).isValid()) {
              errors.push('Something wrong with "From"/"To" dates, please check again.');
              return false;
            }

            data.onHold[holdType] = date;
            return true;
          });

          if (datesValid && moment(data.onHold.from, holdDateFormat) > moment(data.onHold.to, holdDateFormat)) {
            errors.push('The "To" date must be after the "From" date.');
          }
        }

        if (this.withPinEdit) {
          if (this.existingPin && this.newPin) {
            if (this.existingPin !== this.formData.pin || this.newPin !== this.newPinRepeat) {
              errors.push("Pins doesn't match or please check the existing pin.");
            } else {
              data.pin = this.newPin;
            }
          }
        }

        if (errors.length !== 0) {
          return this.$refs.alert.show({
            title: l10n("Error"),
            messageHTML: errors.map(error => `- ${ l10n(error) }`).join("<br/>")
          });
        }

        this.$refs.loader.show();
        try {
          await this.$easyscreenRequest.lmsConnector.updatePatronInfo({
            user: this.formData.cpr,
            pin: this.formData.pin,
            data: data
          });

          this.existingPin = this.newPin = this.newPinRepeat = "";

          this.$refs.loader.hide();
          this.$refs.alert.show({
            title: l10n("Success"),
            message: l10n("The data saved!")
          });

          if (data.pin) {
            this.$emit("pin-update", data.pin);
          }
        } catch (error) {
          this.$refs.loader.hide();
          this.$refs.alert.show({
            title: l10n("Error"),
            message: l10n(error.message)
          });
        }
      },
      /**
       * The proxy method of `lodash.get`.
       */
      _get: get
    },
    components: {
      "modal-alert": ModalAlert,
      "modal-layout": ModalLayout,
      "modal-edit-pin": ModalEditPin,
      "modal-edit-number": ModalEditNumber,
      "modal-edit-text": ModalEditText,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-form-section": EasyscreenFormSection,
      "easyscreen-form-group": EasyscreenFormGroup,
      "easyscreen-password-input": EasyscreenPasswordInput,
      "easyscreen-select": EasyscreenSelect,
      "loader": Loader
    }
  };
</script>
