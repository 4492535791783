<template>
  <div>
    <div><span style="font-size:20px;"><strong>{{ _l10n('Loan') }}</strong></span></div>
    <div v-if="safetyModeEnabled">
      {{ $l10n("!! PLEASE NOTE THAT THIS RECEIPT IS PROVISIONAL AS THE DEVICE THAT DID THIS TRANSACTION WAS NOT ONLINE. !!") }}
    </div>
    <div><br></div>
    <div><strong>{{ _l10n('Date') }}:</strong> {{ _moment().format("DD/MM-YYYY HH:mm") }}</div>
    <div><strong>{{ _l10n('User') }}:</strong> {{ _maskCPR(cpr) }}</div>
    <div><br></div>
    <div>{{ _l10n('Total loans') }}: {{ materials.length }}</div>
    <div><br></div>
    <div>--------------------------------</div>
    <div v-for="(material, index) in materials" :key="material.materialId">
      <div>{{ safetyModeEnabled ? $l10n("Title unavailable") : _get(material, "detail.title") }}</div>
      <div>{{ safetyModeEnabled ? $l10n("Author unavailable") : _get(material, "detail.author") }}</div>
      <div>{{ _get(material, "materialId") }}</div>
      <div><br></div>
      <!-- no-space-line -->
      <div>
        <strong>
          {{ _l10n('Return before') }}:
          {{ safetyModeEnabled ? $l10n("Please check with the personnel.") : _moment(material.loanResult).format("DD/MM-YYYY") }}
        </strong>
      </div>
      <div v-if="materials.length - 1 !== index">
        <div><br></div>
        <div>--------------------------------</div>
      </div>
    </div>
    <div>________________________________</div>
    <p>
      {{ $easyscreenConfig.get('library.name') }}<br>
      {{ $easyscreenConfig.get('library.location.street') }}<br>
      {{ $easyscreenConfig.get('library.location.city') }}
    </p>
    <p> {{ _l10n('Phone') }}: {{ $easyscreenConfig.get('library.phone') }}<span> | </span>
      <a :href="`mailto:${ $easyscreenConfig.get('library.email') }`">
        {{ $easyscreenConfig.get('library.email') }}
      </a><span> | </span>
      <a :href="$easyscreenConfig.get('library.website')">
        {{ $easyscreenConfig.get('library.website') }}
      </a>
    </p>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import moment from "moment";
  import { get } from "lodash";
  import l10n from "@/lib/localization/localization.js";
  import serializeMixin from "./serialize-mixin.js";

  export default {
    name: "materials-scanner-loan-email-template",
    mixins: [serializeMixin],
    props: {
      materials: Array,
      cpr: String
    },
    computed: {
      ...mapState({
        safetyModeEnabled: state => state.safetyModeEnabled
      })
    },
    methods: {
      /**
       * The proxy method of `lodash.get`.
       */
      _get: get,
      /**
       * The proxy method of `moment`.
       */
      _moment: moment,
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Mask the user cpr, replaces the last 4 symbols to *.
       *
       * @param {String} cpr - The user cpr.
       *
       * @returns {String} The masked cpr.
       */
      _maskCPR(cpr) {
        return cpr.substring(0, cpr.length - 4) + "****";
      }
    }
  };
</script>
