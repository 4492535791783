<template>
  <modal-blank
    ref="modal"
    @before-open="(event) => $emit('before-open', event)"
    @opened="(event) => $emit('opened', event)"
    @before-close="(event) => $emit('before-close', event)"
    @closed="(event) => $emit('closed', event)"

    :min-width="minWidth"
    :min-height="minHeight"
    :max-width="maxWidth"
    :max-height="maxHeight"
    :click-to-close="clickToClose"
    :auto-close="autoClose"
    :design="design"
    :position="position"
  >
    <template slot="header">
      <slot name="header"></slot>
      <div v-if="title">
        {{ title }}
      </div>
    </template>
    <template slot="content">
      <div v-if="message">{{ message }}</div>
      <div v-if="messageHTML" v-html="messageHTML"></div>
      <slot name="content"></slot>
    </template>
    <template slot="footer">
      <easyscreen-button-group gap="big">
        <easyscreen-button
          :color="_get(cancelOptionsDynamic || cancelOptions, 'color', design === 'light' ? 'white' : 'secondary')"
          :style="_get(cancelOptionsDynamic || cancelOptions, 'style')"
          :corners="design === 'light' ? 'round' : undefined"
          modificator="fixed-width"
          @click.native="(e) => { _emit('cancel', e); }"
        >
          {{ _get(cancelOptionsDynamic || cancelOptions, 'title', cancelTitleDynamic || cancelTitle || _l10n("Cancel")) }}
        </easyscreen-button>
        <easyscreen-button
          :color="_get(okOptionsDynamic || okOptions, 'color', 'primary')"
          :style="_get(okOptionsDynamic || okOptions, 'style')"
          :corners="design === 'light' ? 'round' : undefined"
          modificator="fixed-width"
          @click.native="(e) => { _emit('ok', e); }"
        >
          {{ _get(okOptionsDynamic || okOptions, 'title', okTitleDynamic || okTitle || _l10n("Ok")) }}
        </easyscreen-button>
      </easyscreen-button-group>
    </template>
  </modal-blank>
</template>

<script>
  import ModalBlank from "./blank.vue";
  import EasyscreenButton from "../button/button.vue";
  import EasyscreenButtonGroup from "../button/button-group.vue";

  import { get, isFunction } from "lodash";
  import l10n from "@/lib/localization/localization.js";
  import reEmitMixin from "../mixins/re-emit.js";

  export default {
    name: "modal-comfirm",
    mixins: [reEmitMixin],
    props: {
      /* The title of ok button, default: _l10n("Ok"). */
      okTitle: {
        type: String,
        default: ""
      },
      /*
       * The options of ok button.
       *
       * @type {Object}
       * @property {String} title - The ok button title will override the `okTitle` options and default value.
       * @property {(String|Object)} style - The ok button style, any style format supported by vue.
       * @property {String} color - The color of ok button, the default `primary`.
       */
      okOptions: Object,
      /* The title of cancel button, default: _l10n("Cancel"). */
      cancelTitle: {
        type: String,
        default: ""
      },
      /*
       * The options of ok button.
       *
       * @type {Object}
       * @property {String} title - The cancel button title will override the `cancelTitle` options and default value.
       * @property {(String|Object)} style - The cancel button style, any style format supported by vue.
       * @property {String} color - The color of cancel button, the default `secondary`.
       */
      cancelOptions: Object,
      /* Flag for close modal by clicking on backdrop. */
      clickToClose: {
        type: Boolean,
        default: true
      },
      /* The min width of modal. */
      minWidth: [Number, String],
      /* The min height of modal. */
      minHeight: [Number, String],
      /* The max width of modal. */
      maxWidth: [Number, String],
      /* The max height of modal. */
      maxHeight: [Number, String],
      /* Close the popup when standby is started. */
      autoClose: {
        type: Boolean,
        default: true
      },
      position: String,
      /* The global reskin. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    data() {
      return {
        title: "",
        message: "",
        messageHTML: "",
        okTitleDynamic: "",
        okOptionsDynamic: null,
        cancelTitleDynamic: "",
        cancelOptionsDynamic: null
      };
    },
    computed: {
      isShown: {
        cache: false,
        get() {
          return this.$refs.modal && this.$refs.modal.isShown;
        }
      }
    },
    methods: {
      /**
       * Show the current modal and set content and\or title (optional).
       *
       * @param {Object} [options] - Show options.
       * @param {String} [options.title] - Title of modal, will be added after the 'header' slot.
       * @param {String} [options.message] - The text message, will be added after the `content` slot.
       * @param {String} [options.messageHTML] - The html message, will be added after the `content` slot.
       * @param {Function} [options.callback] - The modal callback, (event, buttonType) => {}.
       *  Where `event` - the native click event, and `buttonType` one of: "ok".
       */
      show(options) {
        if (options) {
          this.title = options.title || this.title;
          this.message = options.message || this.message;
          this.messageHTML = options.messageHTML || this.messageHTML;
          this.okTitleDynamic = options.okTitle || this.okTitleDynamic;
          this.okOptionsDynamic = options.okOptions || this.okOptionsDynamic;
          this.cancelTitleDynamic = options.cancelTitle || this.cancelTitleDynamic;
          this.cancelOptionsDynamic = options.cancelOptions || this.cancelOptionsDynamic;

          if (isFunction(options.callback)) {
            let okCallback = (e) => { options.callback(e, "ok"); };
            let cancelCallback = (e) => { options.callback(e, "cancel"); };

            this.$on("ok", okCallback);
            this.$on("cancel", cancelCallback);
            this.$refs.modal.$once("closed", () => {
              this.$off("ok", okCallback);
              this.$off("cancel", cancelCallback);
            });
          }
        }

        this.$refs.modal.show();
      },
      /**
       * Hide the current modal.
       *
       * @param {Function} [callback] - The hide callback, will be called when modal is closed.
       */
      hide(callback) {
        this.$refs.modal.hide(() => {
          this.title = "";
          this.message = "";
          this.messageHTML = "";
          this.okTitleDynamic = "";
          this.okOptionsDynamic = null;
          this.cancelTitleDynamic = "";
          this.cancelOptionsDynamic = null;

          if (isFunction(callback)) {
            callback();
          }
        });
      },
      /**
       * The proxy method of `lodash.get`.
       */
      _get: get,
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Wrapper for re emit event with possible closing prevention.
       *
       * @param {String} eventName - The name of event for re-emit.
       * @param {Event} nativeEvent - The event for re-emit.
       */
      _emit(eventName, nativeEvent) {
        this._reEmit(eventName, nativeEvent, (prevented) => {
          if (!prevented) {
            this.hide();
          }
        });
      }
    },
    components: {
      "modal-blank": ModalBlank,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-button-group": EasyscreenButtonGroup
    }
  };
</script>
