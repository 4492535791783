<template>
  <table :class="[
    'meta-information-table',
    { 'meta-information-table_standalone': standalone }
  ]">
    <tbody>
      <tr
        class="meta-information-table--row"
        v-for="pair in metaPair"
        :key="`${ pair.property }-${ pair.value }-${ pair.placement }`"
      >
        <td colspan="2" v-if="pair.placement" class="meta-information-table--placement">
          {{ pair.placement }}
        </td>
        <td colspan="2" v-else-if="pair.delimiter" class="meta-information-table--delimiter"></td>
        <template v-else>
          <td class="meta-information-table--label">{{ pair.property }}:</td>
          <td class="meta-information-table--value">{{ pair.value }}</td>
        </template>
      </tr>
    </tbody>
  </table>
</template>

<style lang="less">
  @import "../core/mixins.less";

  .meta-information-table {
    &--delimiter {
      .es-pt-2();
    }

    &_standalone {
      width: 100%;

      .meta-information-table {
        &--label {
          width: 180px;
          font-weight: 600;
          font-style: normal;
        }

        &--value {
          font-style: italic;
        }

        &--placement {
          font-style: normal;
        }
      }
    }
  }

</style>

<script>
  export default {
    props: {
      standalone: Boolean,
      metaPair: {
        type: Array,
        default: () => ([])
      }
    }
  };
</script>
