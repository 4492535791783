var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'node-list-carousel',
    `node-list-carousel_scale-${ _vm.$easyscreenScale }`,
    `node-list-carousel_template-${ _vm.slideTemplateType }`,
    _vm.orientation
  ],style:({
    '--special-color': _vm.customSpecialColor
  })},[(_vm.$easyscreenSkin.isBtj)?_c('btj-header',{staticClass:"node-list-carousel--btj-header",attrs:{"size":"big"}}):_vm._e(),(_vm.template === 'krsbib-twin-items')?_c('krsbib-header',{class:[
      'node-list-carousel--krsbib-header',
      {
        'node-list-carousel--krsbib-header_events': _vm.isEvent,
        'node-list-carousel--krsbib-header_news': !_vm.isEvent
      }
    ],attrs:{"title":_vm.slideSettings.overrideTitle}}):_vm._e(),_c('h2',{staticClass:"node-list-carousel--title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('easyscreen-carousel',{ref:"carousel",staticClass:"node-list-carousel--carousel",attrs:{"type":"loop","optimization":false,"transition":_vm.carouselTransition,"layout-columns":_vm.carouselLayout.columns,"layout-rows":_vm.carouselLayout.rows},on:{"position-changed":(_activeSlideIndex) => _vm.activeSlideIndex = _activeSlideIndex}},_vm._l((_vm.items),function(element,elementIndex){return _c('div',{key:element.nid,staticClass:"node-list-carousel--element",on:{"click":() => _vm._expandElement(element, elementIndex)}},[_c(_vm.slideTemplate,_vm._b({ref:"carouselSlides",refInFor:true,tag:"slide",attrs:{"totalElements":Math.ceil(_vm.items.length / (_vm.carouselLayout.columns * _vm.carouselLayout.rows)),"elementIndex":elementIndex,"slideRows":_vm.carouselLayout.rows,"elementRow":elementIndex % _vm.carouselLayout.rows,"withSlideDelimiter":elementIndex % _vm.carouselLayout.rows < _vm.carouselLayout.rows - 1}},'slide',element,false)),(element.status && element.status.title)?_c('easyscreen-carousel-ribbon',{attrs:{"title":element.status.title,"background":element.status.color,"fontSize":element.status.fontSize,"aligment":element.status.aligment}}):_vm._e()],1)}),0),(_vm.template === 'krsbib-twin-items')?_c('krsbib-footer',{class:[
      'node-list-carousel--krsbib-footer',
      {
        'node-list-carousel--krsbib-footer_events': _vm.isEvent,
        'node-list-carousel--krsbib-footer_news': !_vm.isEvent
      }
    ]}):_vm._e(),(_vm.isEvent && _vm.hasDetails)?_c('modal-blank',{ref:"modal",attrs:{"click-to-close":true}},[_c('div',[(_vm.activeElement)?_c('node-list-info',{attrs:{"type":_vm.activeElement.type,"date":_vm.activeElement.date,"date-to":_vm.activeElement.dateTo,"location":_vm.activeElement.location,"price":_vm.activeElement.price,"image":_vm.activeElement.image,"title":_vm.activeElement.title,"es-teaser":_vm.activeElement.esTeaser,"teaser":_vm.activeElement.teaser,"text":_vm.activeElement.text,"lead":_vm.activeElement.lead}},[_c('easyscreen-circle-button',{staticClass:"node-list-carousel--close-button node-list-carousel--close-button_top-right",attrs:{"icon":"esi esi-times"},nativeOn:{"click":function($event){return _vm.$refs.modal.hide.apply(null, arguments)}}}),_c('easyscreen-circle-button',{staticClass:"node-list-carousel--close-button node-list-carousel--close-button_bottom-right",attrs:{"icon":"esi esi-times"},nativeOn:{"click":function($event){return _vm.$refs.modal.hide.apply(null, arguments)}}})],1):_vm._e()],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }