<template>
  <div class="easyscreen-krsbib-footer">
    <div class="easyscreen-krsbib-footer--logo"></div>
  </div>
</template>

<style src="./krsbib-footer.less" lang="less"></style>

<script>
  export default {
    name: "easyscreen-krsbib-footer"
  };
</script>
