var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'easyscreen-text-input',
    { 'easyscreen-text-input_has-error': !!_vm.error }
  ],on:{"click":_vm.focusTheHiddenInput}},[(_vm.label)?_c('label',{staticClass:"easyscreen-text-input--label",attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('pre',{ref:"inputField",class:[
      'easyscreen-text-input--field',
      'easyscreen-input',
      'easyscreen-text-input--input',
      { focus: _vm.focused },
      _vm.inputClass
    ],on:{"click":(e) => _vm._selectNearest({ x: e.clientX, y: e.clientY })}},[_vm._v("    "),_c('span',{ref:"defaultPositionPointer",staticClass:"easyscreen-text-input--default-position"}),_vm._l(((_vm.value || '').split('')),function(letter,index){return _c('span',{key:`${ letter }-${ index }`},[_vm._v(_vm._s(letter))])}),_vm._v("\n    "),(_vm.cursor && _vm.cursorPosition > 0)?_c('span',{ref:"cursor",class:['easyscreen-text-input--cursor', _vm.cursorClass],style:({ left: _vm.cursorPosition })}):_vm._e(),_vm._v("\n  ")],2),(_vm.error)?_c('div',{staticClass:"easyscreen-text-input--error"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('i',{class:[
      'fal',
      'fa-times',
      'easyscreen-text-input--clean-button',
      {
        'easyscreen-text-input--clean-button_visible': !!_vm.value
      },
      _vm.clearButtonClass
    ],on:{"click":function($event){_vm.setCursor(0); _vm.$emit('input', '')}}}),_c('input',{ref:"hiddenInput",staticClass:"easyscreen-text-input--hidden-input",attrs:{"id":_vm.id,"type":"text","autocomplete":"off","readonly":_vm.readonly},domProps:{"value":_vm.value},on:{"input":(event) => _vm.$emit('input', event.target.value),"submit":(event) => _vm.$emit('submit', event),"keydown":_vm._onNativeCursorChange,"keyup":_vm._onNativeCursorChange}})])
}
var staticRenderFns = []

export { render, staticRenderFns }