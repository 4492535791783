var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"easyscreen-progress-bar"},[_c('div',{staticClass:"easyscreen-progress-bar--progress",style:({
      width: `${ _vm.percentageProgress }%`
    })}),(_vm.endMarker !== 'none')?_c('div',{class:[
      'easyscreen-progress-bar--end-marker',
      `easyscreen-progress-bar--end-marker_${ _vm.endMarker }`
    ],style:({
      left: `${ _vm.percentageProgress }%`
    })}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }