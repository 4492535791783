import { isObjectLike, isString } from "lodash";

/**
 * Serialize the css styles from object to string.
 * 
 * @param {(String|Object)} styles - The css styles for serialization.
 * 
 * @returns {String} Serialized styles.
 */
export default function(styles) {
  if (isObjectLike(styles)) {
    return Object.keys(styles).map(key => {
      return styles[key] ? key + ": " + styles[key] : null;
    }).filter(Boolean).join("; ");
  } else if (isString(styles)) {
    return styles;
  } else {
    return "";
  }
}
