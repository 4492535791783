<template>
  <div
    class="node-list-carousel-default-slide"
    :style="{
      backgroundImage: backgroundImage ? `url('${ backgroundImage }')` : null
    }"
  >
    <div
      v-if="isEvent"
      class="node-list-carousel-default-slide--meta"
    >
      <div class="node-list-carousel-default-slide--date">
        {{ formattedDate }}
      </div>
      <div class="node-list-carousel-default-slide--location">
        {{ location }}
      </div>
    </div>
    <h1 class="node-list-carousel-default-slide--title">
      {{ title }}
      <span
        v-if="price"
        class="node-list-carousel-default-slide--price"
      >
        - {{ price }}
      </span>
    </h1>
    <div class="node-list-carousel-default-slide--teaser ck-editor" v-html="teaser"></div>
    <div
      v-if="qrCode"
      class="node-list-carousel-default-slide--qr-code"
      :style="{ backgroundImage: `url('${ $easyscreenRequest.lmsConnector.wrapCover(qrCode) }')` }"
    ></div>
  </div>
</template>

<style src="../../core/ck-editor.less" lang="less"></style>
<style src="./node-list-carousel-default-slide.less" lang="less"></style>

<script>
  import moment from "moment";
  import l10n from "@/lib/localization/localization.js";

  export default {
    name: "node-list-carousel-default-slide",
    props: {
      type: String,
      date: String,
      dateTo: String,
      location: String,
      price: [String, Number],
      bgImage: String,
      image: String,
      title: String,
      esTeaser: String,
      teaser: String,
      text: String,
      lead: String,
      qrCode: String
    },
    computed: {
      isEvent() {
        return this.type === "event";
      },
      backgroundImage: {
        get() {
          return this.bgImage || this.image;
        }
      },
      formattedDate: {
        get() {
          const dateFrom = moment(this.date);
          const dateTo = moment(this.dateTo);
          let date = moment();

          if (date < dateFrom) {
            date = dateFrom;
          } else if (date > dateTo) {
            date = dateTo;
          }

          date.hour(dateFrom.hour());
          date.minute(dateFrom.minute());

          let formattedDate = date.format("D. MMMM");
          if (this.layout === "welcome-screen") {
            formattedDate = date.format("DD.MM.YYYY");
          }

          if (date.hour() !== 0 || date.minute() !== 0) {
            formattedDate += " " + l10n("Kl.") + " " + date.format("HH:mm");
          }

          return formattedDate;
        }
      }
    }
  };
</script>
