<template>
  <div
    :class="[
      'search-suggestions',
      `search-suggestions_${ suggestionsType }`,
      `search-suggestions_${ type }`,
      `search-suggestions_design-${ design }`,
      orientation
    ]"
    :style="{ '--search-suggestions-background-color': backgroundColor }"
    v-if="_isShown()"
  >
    <div
      v-for="suggestions in _getSuggestions()"
      :key="suggestions.title"
      class="search-suggestions--column"
    >
      <div class="search-suggestions--title">{{ suggestions.title }}</div>
      <div
        :class="[
          'search-suggestions--content',
          { 'search-suggestions--content_with-covers': suggestions.withCovers }
        ]"
      >
        <div
          v-for="hit in suggestions.hits"
          :key="hit.id"
          :style="suggestions.withCovers ? { width: `${ 100 / suggestions.limit }%` } : null"
          :class="[
            'search-suggestions--hit'
          ]"
          @click="$emit('select', hit)"
        >
          <img
            v-if="hit.cover"
            class="search-suggestions--hit-image"
            draggable="false"
            :src="$easyscreenRequest.lmsConnector.wrapCover(hit.cover)"
          >
          <span v-else>
            {{ hit.title }}
            <span v-if="suggestions.type" class="search-suggestions--hit-type">
              ({{ hit.type || suggestions.type }})
            </span>
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./search-suggestions.less" lang="less"></style>

<script>
  import { uniqBy } from "lodash";
  import randomId from "@/lib/utils/random-id.js";
  import cutText from "@/lib/utils/cut-text.js";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../core/mixins/orientation.js";

  export default {
    name: "search-suggestions",
    mixins: [orientationMixin],
    props: {
      /* The search query for suggestion. */
      query: String,
      /* The amount of suggestion in group. */
      amountOfSuggestions: {
        type: Number,
        default() {
          return this.$easyscreenConfig.get("search.suggestions.amount", 5);
        }
      },
      /* The minimum length of query to get suggested. */
      minimalQueryLength: {
        type: Number,
        default() {
          return this.$easyscreenConfig.get("search.suggestions.minWordLength", 3);
        }
      },
      /*
       * The visual type of suggestion with simple suggestions:
       * - default - the single column with mixed content (titles and subjects);
       * - divided-view - two columns, one for titles and one for subjects;
       *
       * The visual type for extended suggestions will always have three columns:
       * images, titles, authors.
       */
      type: {
        type: String,
        default() {
          return this.$easyscreenConfig.get("search.suggestions.dividedView") ? "divided-view" : "default";
        },
        validator: _type => ["default", "divided-view"].includes(_type)
      },
      backgroundColor: String,
      /* The global reskin for materials list. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    data() {
      return {
        /*
         * The type of suggestions:
         * - simple - The suggestion provide the titles and authord with following format:
         *            {
         *              total: Number,
         *              hits: [{ title: String, score: Number }, { author: String, score: Number }]
         *            }
         * - extended - The suggestion provide the titles, authors and spellcheck with following format:
         *            {
         *              total: Number,
         *              hits: [{ title: String, score: Number, cover: String, (id||fasutNumber): String }],
         *              authors: [{ title: String }],
         *              spellcheck: [{ title: String }]
         *            }
         */
        suggestionsType: "",
        suggestions: [],
        authors: [],
        spellcheck: []
      };
    },
    watch: {
      query(newQuery) {
        this._fetchSuggestions(newQuery);
      }
    },
    methods: {
      /**
       * Fetch suggestions and update the view if the query has enough length.
       * @async
       *
       * @param {String} query - The query for suggestion.
       */
      async _fetchSuggestions(query) {
        if (!query || query.length < this.minimalQueryLength) {
          this.suggestionsType = "simple";
          this.suggestions = [];
          this.authors = [];
          this.spellcheck = [];

          return;
        }

        try {
          const suggestions = await this.$easyscreenRequest.lmsConnector.suggestions({
            query: query,
            step: this.amountOfSuggestions + 4
          });

          if (this.query === query) {
            if (("authors" in suggestions) || ("spellcheck" in suggestions)) {
              this.suggestionsType = "extended";
              this.suggestions = suggestions.hits.filter((hit) => hit.cover).map(hit => {
                hit.id = hit.id || hit.faustNumber || randomId();

                return hit;
              });
              this.authors = suggestions.authors;
              this.spellcheck = suggestions.spellcheck;
            } else {
              this.suggestionsType = "simple";
              this.suggestions = suggestions.hits;

              this.authors = [];
              this.spellcheck = [];
            }
          }
        } catch (error) {
          console.error("Can't fetch suggestions.", error);
          this.suggestionsType = "simple";
          this.suggestions = [];
          this.authors = [];
          this.spellcheck = [];
        }
      },
      /**
       * The search suggestions prepared for draw.
       *
       * @typedef {Object} SearchSuggestions
       *
       * @property {Number} limit - The limit of that suggestions.
       * @property {String} [type] - The type of simple suggestion, one of: l10n("title"), l10n("author").
       * @property {Boolean} withCovers - Means the hits have the covers.
       * @property {Object[]} hits - The suggestions hits.
       * @property {String} hits[].id - The id of hit, can be the faust number or random id.
       * @property {String} hits[].title - The hit title.
       * @property {String} hits[].cover - The hit cover (if the suggestion is material).
       * @property {String} [hits[].type] - The type of suggestion (will override the type on level above).
       */
      /**
       * Get the suggestions prepared for draw.
       *
       * @returns {SearchSuggestions[]} suggestions prepared for draw.
       */
      _getSuggestions() {
        let suggestions = [];
        let amountOfSuggestions = this.amountOfSuggestions;

        if (this.suggestionsType === "extended") {
          amountOfSuggestions = 4;
          suggestions = this._getExtendedSuggestions();
        } else {
          suggestions = this._getSimpleSuggestions();
        }

        suggestions = suggestions.map((suggestions) => {
          suggestions.limit = amountOfSuggestions;
          suggestions.hits = uniqBy(suggestions.hits, "title").map((hit) => {
            if (hit.cover && hit.cover.includes("defaultCover")) {
              delete hit.cover;
            }

            suggestions.withCovers = suggestions.withCovers || !!hit.cover;
            hit.id = hit.id || hit.faustNumber || randomId();
            if (!hit.title && hit.author) {
              hit.title = hit.author;
              hit.type = l10n("author");
            }

            if (hit.title) {
              hit.title = cutText(hit.title, this._getMaxTitleLength());
            }
            return hit;
          }).filter((hit) => {
            return hit.cover || hit.title;
          }).slice(0, amountOfSuggestions);

          return suggestions;
        });

        return suggestions;
      },
      /**
       * Get the maximum title length based on suggestion type and visual type.
       *
       * @returns {Number} The maximum title length (130, 55 or 40 characters).
       */
      _getMaxTitleLength() {
        let length = 130;

        if (this.suggestionsType === "simple" && this.type === "divided-view") {
          length = 55;
        } else if (this.suggestionsType === "extended") {
          length = 40;
        }

        return length;
      },
      /**
       * Get the suggestions for draw for externded fetch result.
       *
       * @returns {SearchSuggestions[]} suggestions prepared for draw.
       */
      _getExtendedSuggestions() {
        return [{
          title: l10n("Popular materials"),
          hits: this.suggestions
        }, {
          title: l10n("Popular authors"),
          hits: this.authors
        }, {
          title: l10n("Spellcheck"),
          hits: this.spellcheck
        }].filter((suggestions) => {
          return suggestions.hits && suggestions.hits.length;
        });
      },
      /**
       * Get the suggestions for draw for simple fetch result.
       *
       * @returns {SearchSuggestions[]} suggestions prepared for draw.
       */
      _getSimpleSuggestions() {
        return [{
          title: l10n("Titles"),
          type: l10n("title"),
          hits: this.type !== "divided-view" ? this.suggestions : this.suggestions.filter(hit => {
            return "title" in hit;
          })
        }, this.type === "divided-view" ? {
          title: l10n("Subjects"),
          type: l10n("author"),
          hits: this.suggestions.filter(hit => {
            return "author" in hit;
          }).map(hit => {
            return Object.assign(hit, { title: hit.author });
          })
        } : null].filter(Boolean).filter((suggestions) => {
          return suggestions.hits && suggestions.hits.length;
        });
      },
      /**
       * Check if the suggestions should be shown or not.
       *
       * @retruns {Boolean} The shown state.
       */
      _isShown() {
        return this.suggestions.length !== 0 || this.authors.length !== 0 || this.spellcheck.length !== 0;
      }
    }
  };
</script>
