var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['easyscreen-search--content', `easyscreen-search--content_design-${ _vm.design }`]},[(_vm.$easyscreenConfig.get('enable.popularSearches') && _vm.popularSearches)?[(_vm.popularSearches === 'multiline')?_c('popular-searches-multiline',{on:{"select":(tag) => _vm.$emit('find', tag.title, _vm.sorting)}}):(_vm.popularSearches.startsWith('inline'))?_c('popular-searches-inline',{staticClass:"easyscreen-search--inline-suggestions",attrs:{"hide-button":"close","full-type":_vm.popularSearches.split('-')[1] || 'none'},on:{"select":(tag) => _vm.$emit('find', tag.title, _vm.sorting)}}):_vm._e()]:_vm._e(),(_vm.$easyscreenConfig.get('enable.searchSuggestions') && _vm.withSearchSuggestions && _vm.design === 'light')?_c('search-suggestions',{attrs:{"query":_vm.query,"design":_vm.design,"background-color":_vm.specialColor},on:{"select":(suggestion) => _vm.$emit('find', suggestion.title, _vm.sorting)}}):_vm._e(),_c('form',{staticClass:"easyscreen-search--input",on:{"submit":(event) => {
      event.preventDefault();

      if (!_vm._isSortingElement(event.submitter)) {
        _vm.$emit('find', _vm.query, _vm.sorting);
      }

      return false;
    }}},[(_vm.$easyscreenConfig.get('enable.searchSuggestions') && _vm.withSearchSuggestions && _vm.design !== 'light')?_c('search-suggestions',{attrs:{"query":_vm.query},on:{"select":(suggestion) => _vm.$emit('find', suggestion.title, _vm.sorting)}}):_vm._e(),_c('search-input',{key:_vm.withCursor,ref:"input",attrs:{"cursor":_vm.withCursor,"value":_vm.query,"sorting":_vm.sorting,"scale-up":true,"with-sorting":_vm.withSorting,"design":_vm.design},on:{"input":(value) => { _vm.query = value },"text-input-click":(event) => _vm.$emit('text-input-click', event),"sorting-selected":(_sorting) => {
        _vm.sorting = _sorting.value
        _vm.$emit('sorting-selected', _sorting);
      }}})],1),_c('keyboard-fullsize',{staticClass:"easyscreen-search--keyboard es-mt-4",attrs:{"design":_vm.design,"gap":_vm.design === 'light' ? 6 : undefined,"override":_vm.design === 'light' ? {
      'enter': {
        offsetleft: -8,
        /* eslint-disable-next-line */
        path: 'M18.836,792.114C17.375,792.114 16.166,793.149 15.894,794.528L15.894,837.757C15.894,839.419 14.556,840.757 12.894,840.757L-17,841C-18.662,841 -20,842.338 -20,844L-20,880.426C-20,882.088 -18.662,883.426 -17,883.426L81.897,883.575C83.559,883.575 84.897,882.237 84.897,880.575L84.897,795.34C84.897,793.678 83.559,792.34 81.897,792.34L18.836,792.114Z'
      },
      'lshift': { style: { width: 112 }},
      'space': { style: { width: 293 }}
    } : {}},on:{"key-click":_vm._handleKeyboard}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }