import { trim } from "lodash";
import clampNumber from "@/lib/utils/clamp-number.js";

/**
 * Check if the given text is complex CQL query (e.g. contains the multiple conditions or use the facets).
 *
 * @param {String} text - CQL query.
 *
 * @returns {Boolean} is the complex query
 */
function isComplexCQL(text) {
  return /(\s+AND\s+|\s+OR\s+|\s*=")/.test(text);
}
/**
 * Serialize the cql from javascript object.
 * The different facets will be joined using AND, and facet values using OR
 *
 * @param {Object} options - The cql options.
 * @param {String} [options.text] - The text query of cql.
 * @param {Object} [options.facets] - The cql facets.
 * @param {String[]} [options.facets[key:facetId]] - The selected values of facet.
 *
 * @returns {String} the serialized cql query.
 */
function serializeCQL(options) {
  let text = "";
  if (options.text) {
    text = options.text;

    if (text.includes(" ") && !isComplexCQL(text)) {
      // eslint-disable-next-line
      text = `"${ trim(text, `'"`).replace(/"/ig, '\\"') }"`;
    }
  }

  let facets = "";
  if (options.facets) {
    facets = Object.keys(options.facets).map(facetId => {
      let values = options.facets[facetId];
      if (!Array.isArray(values)) {
        values = [values].filter(Boolean);
      }

      if (values.length === 0) {
        return null;
      }

      let serializedValues = values.map(value => `${ facetId }="${ value }"`).join(" OR ");
      if (values.length > 1) {
        serializedValues = `(${ serializedValues })`;
      }

      return serializedValues;
    }).filter(Boolean).join(" AND ");
  }

  return [text, facets].filter(Boolean).join(" AND ");
}

function getSearchQuery(state, options) {
  options = options || {};

  let facets = state.selectedFacets;
  if (options.withoutFacets) {
    facets = null;
  }

  return serializeCQL({
    text: options.withoutText !== true ? [
      state.query,
      state.queryPrefix && state.shelfOnly ? state.queryPrefix : null
    ].filter(Boolean).join(" AND ") || "*" : null,
    facets: facets
  });
}

export default {
  state: {
    layoutColumns: 8,
    layoutRows: 2,
    query: "",
    sorting: "",
    availableOnly: false,
    shelfOnly: false,
    hits: 0,
    results: [],
    facets: [],
    selectedFacets: {},
    searchInProgress: false,
    error: null
  },
  mutations: {
    setQuery(state, payload) {
      state.query = payload.query;
    }
  },
  actions: {
    resetSearchResults({ commit }) {
      commit({
        type: "setState",
        query: "",
        sorting: "",
        availableOnly: false,
        shelfOnly: false,
        hits: 0,
        results: [],
        facets: [],
        selectedFacets: {},
        searchInProgress: false,
        error: null
      });
    },
    /**
     * Do the new search request or clarify exising.
     * @async
     *
     * @param {Object} context - The vuex-like context (see more at vuex-like.js).
     * @param {Object} payload - The options of the page loading.
     * @param {Object} payload - The search options.
     * @param {String} payload.query - The text search query.
     * @param {String} payload.queryPrefix - The shelf only query prefix.
     * @param {String} payload.sorting - The sorting of results.
     * @param {Boolean} payload.availableOnly - Flag for get all or only available materials.
     * @param {Boolean} payload.shelfOnly - Flag for get all or only materials at current shelf.
     * @param {Object} payload.facets - The selected facets.
     * @param {String[]} payload.facets[key:facetId] - The selected values of facet.
     * @param {Number} [payload.step=(state.layoutColumns * state.layoutRows * 2)] - The amount of records on search response.
     * @param {Number} [payload.offset=0] - The offset of records on search response.
     * @param {Boolean} payload.isClarification - Flag means clarification search:
     *   only step and offset is used, other will be taken from state.
     */
    async find({ state, commit, emit }, payload) {
      payload = payload || {};

      const providers = await this.$easyscreenRequest.lmsConnector.providers();
      const isBibliofil = (providers["get /search"] || "").includes("bibliofil");

      if (payload.query && payload.query.includes(" ") && !isComplexCQL(payload.query)) {
        // eslint-disable-next-line
        payload.query = `"${ trim(payload.query, `'"`).replace(/"/ig, '\\"') }"`;
      }

      if (payload.isClarification !== true) {
        state = commit({
          type: "setState",
          query: payload.query,
          queryPrefix: payload.queryPrefix,
          sorting: payload.sorting,
          availableOnly: payload.availableOnly,
          shelfOnly: payload.shelfOnly,
          selectedFacets: payload.facets || {},
          searchInProgress: true
        });

        if (this.$easyscreenStatistic) {
          this.$easyscreenStatistic.makeSearch({ query: payload.query });
        }
      } else {
        state = commit({
          type: "setState",
          searchInProgress: true
        });
      }

      let primaryQuery = getSearchQuery(state, { withoutFacets: true });

      try {
        emit("search-start", { isClarification: payload.isClarification });
        /*
         * The facets for bibliofil or when the queryType is "freetext" is set manually
         * must be sent to LMS separately out of the text query to prevent the wrong
         * interpretation for SRU request.
         */
        let serializedFacets = "";
        if (isBibliofil) {
          serializedFacets = getSearchQuery({ selectedFacets: state.selectedFacets }, { withoutText: true }).trim();

          if (serializedFacets) {
            serializedFacets = "AND " + serializedFacets;
          }
        }

        let results = await this.$easyscreenRequest.lmsConnector.search({
          step: payload.step || (state.layoutColumns * state.layoutRows * 2),
          offset: payload.offset || 0,
          query: getSearchQuery(state, { withoutFacets: isBibliofil }),
          queryPostfix: serializedFacets,
          availableFacets: true,
          withMeta: true,
          withHoldings: true,
          availableOnly: state.availableOnly,
          withCoversOnly: this.$easyscreenConfig.get("search.withCoversOnly"),
          sorting: state.sorting
        });

        if (payload.isClarification === true) {
          results.objects = state.results.concat(results.objects || []);
        }

        commit({
          type: "setState",
          searchInProgress: false
        });

        results.facets = results.facets || [];
        /* Show the facets of previous request if the last facets selection has returned 0 results. */
        if (results.facets.length === 0 && state.facets.length !== 0 && primaryQuery === getSearchQuery(state, { withoutFacets: true })) {
          results.facets = state.facets;
        }

        commit({
          type: "setState",
          hits: results.hitCount || 0,
          results: results.objects || [],
          facets: results.facets || [],
          error: null
        });
      } catch (error) {
        console.error("Search error:", error);
        commit({
          type: "setState",
          searchInProgress: false,
          hits: 0,
          results: [],
          facets: [],
          error: error
        });
      }

      emit("search-end", { isClarification: payload.isClarification });
    },
    /**
     * Preload up to two pages for current query options (query, facets and sorting).
     *
     * @param {Object} context - The vuex-like context (see more at vuex-like.js).
     * @param {Object} payload - The options of the page loading.
     * @param {Number} [payload.amount=1] - The amount of pages for load.
     */
    async loadPage({ state, dispatch }, payload) {
      payload = payload || {};
      payload.amount = clampNumber(payload.amount, 0, Infinity) || 1;

      if (state.searchInProgress) {
        return console.warn("Search request already running.", state);
      }

      const materialsPerSlide = state.layoutColumns * state.layoutRows;
      const fullPagesLoaded = Math.floor(state.results.length / materialsPerSlide);
      const materialsForLoad = (fullPagesLoaded + 2) * materialsPerSlide - state.results.length;

      await dispatch("find", {
        isClarification: true,
        step: materialsForLoad,
        offset: state.results.length
      });
    },
    /**
     * Reset the search state.
     */
    reset({ commit }) {
      commit({
        type: "setState",
        hits: 0,
        results: [],
        facets: [],
        selectedFacets: {},
        searchInProgress: false
      });
    }
  }
};
