<template>
  <div :class="[
    'material-promotion-carousel-single-view',
    orientation
  ]">
    <h1
      v-if="title"
      class="material-promotion-carousel-single-view--title"
    >
      {{ title }}
    </h1>

    <easyscreen-carousel
      ref="carousel"
      class="material-promotion-carousel-single-view--carousel"
      type="infinite"
      :layout-rows="1"
      :layout-columns="1"
    >
      <div
        v-for="element in modifiedElements"
        :key="`element-${ element.id }`"
        class="material-promotion-carousel-single-view--element"
      >
        <img
          class="material-promotion-carousel-single-view--element-image"
          draggable="false"
          :src="$easyscreenRequest.lmsConnector.wrapCover(element.cover)"
        >

        <div class="material-promotion-carousel-single-view--element-content">
          <h2 class="material-promotion-carousel-single-view--element-title">
            {{ element.title }}
          </h2>
          <div class="material-promotion-carousel-single-view--element-meta">
            <span
              v-if="element.author"
            >
              {{ element.author }}
            </span>
            <span
              v-if="element.year"
            >
              ({{ element.year }})
            </span>
          </div>
          <div
            v-if="element.description && withMetaInformation !== true"
            class="material-promotion-carousel-single-view--element-teaser"
          >
            {{ _cutText(element.description || "", maxTeaserLength) }}
          </div>
          <div
            v-if="withMetaInformation === true"
            class="material-promotion-carousel-single-view--element-teaser"
          >
            <meta-information-table
              class="material-promotion-carousel-single-view--element-meta"
              :standalone="true"
              :metaPair="element.metaPair"
            />
          </div>
        </div>
      </div>
    </easyscreen-carousel>
  </div>
</template>

<style src="./material-promotion-carousel-single-view.less" lang="less"></style>

<script>
  import * as d3 from "d3-timer";
  import cutText from "@/lib/utils/cut-text.js";
  import orientationMixin from "../core/mixins/orientation.js";
  import materialPromotionMeta from "./material-promotion-meta.mixin.js";

  import EasyscreenCarousel from "../core/carousel/carousel.vue";
  import MetaInformationTable from "../materials-list/meta-information-table.vue";

  export default {
    name: "material-promotion-carousel-single-view",
    mixins: [
      orientationMixin,
      materialPromotionMeta
    ],
    props: {
      /* The title of promotion. */
      title: String,
      /**
       * Promotion element
       *
       * @typedef {Object} PromotionElement
       *
       * @property {Number} id - Element id (fasut number).
       * @property {Number} [cover="/images/defaultCover.jpg"] - Cover of the material.
       * @property {Number} title - Material title.
       * @property {Number} [author] - Material author.
       * @property {Number} [year] - Year of the publication.
       * @property {Number} [description] - Materail description.
       */
      /* The list of promotion elements (PromotionElement[]). */
      elements: Array,
      /* The duraion in ms of how long the every element will be shown at the screen. */
      animationSpeed: {
        type: Number,
        default: 5000
      },
      /*
       * The maximum description\teaser length. Will be cutted by the closest punctuation
       * mark or the space under `Number` characters.
       */
      maxTeaserLength: {
        type: Number,
        default: 500
      },
      /* Display the material meta information and holdings instead of description. */
      withMetaInformation: Boolean
    },
    watch: {
      /**
       * Update the list of elements at the scrollable area and reset the scroll.
       *
       * @param {PromotionElement[]} - The list of promotion elements.
       */
      elements (elements) {
        this.modifiedElements = elements;
        if (this._getHoldings) {
          this._getHoldings();
        }
      }
    },
    data() {
      return {
        modifiedElements: this.elements
      };
    },
    methods: {
      /* Proxy for `cutText` method. */
      _cutText: cutText,
      /**
       * Start the sliding animation.
       */
      _startAutoAnimation() {
        this._stopAutoAnimation();

        this._autoAnimationInterval = d3.interval(this.$refs.carousel.nextSlide, this.animationSpeed);
      },
      /**
       * Stop the sliding animation.
       */
      _stopAutoAnimation() {
        if (this._autoAnimationInterval) {
          this._autoAnimationInterval.stop();
          this._autoAnimationInterval = null;
        }
      }
    },
    async mounted() {
      this._startAutoAnimation();
    },
    beforeDestroy() {
      this._stopAutoAnimation();
    },
    components: {
      "easyscreen-carousel": EasyscreenCarousel,
      "meta-information-table": MetaInformationTable
    }
  };
</script>
