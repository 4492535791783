import moment from "moment";
import { get, trim } from "lodash";

/**
 * Adds the double line breaks to the all children of container.
 *
 * @param {HTMLElement} node - The container for processing.
 *
 * @returns {HTMLElement} The same container with modified children.
 */
function addLineBreaks(node) {
  [].forEach.call(node.children, child => {
    child.appendChild(document.createTextNode("\n\n"));
  });

  return node;
}

/**
 * Get the html element text with trim of '",\b\n.
 *
 * @param {HTMLElement} node - The html element which should be converted to text.
 *
 * @returns {String} The text from html element.
 */
function toText(node) {
  return trim(node.innerText, "'\", \n");
}

export default {
  methods: {
    /**
     * The fetched and\or proceeded review.
     *
     * @typedef {Object} MaterialReview
     *
     * @property {String} title - Title of review.
     * @property {String} author - Review author.
     * @property {String} date - Publication date or year.
     * @property {String} source - The name of source where the review has published..
     * @property {String} headline - The content headline.
     * @property {String} description - The review content.
     * @property {String} copyright - Review copyright.
     */
    /**
     * Fetch online review or proceed local.
     * @async
     *
     * @param {Object} review - The review object from materialDetail.externalResources.reviews
     * @param {String} review.url - The url of online review (should be lms url, otherwise will be used existing data).
     * @param {String} review.title - Title of review.
     * @param {String} review.author - Review author.
     * @param {String} review.description - The review content.
     * @param {String} review.year - Publication year.
     * @param {Object} review.meta - The meta infromation of the review.
     * @param {String} review.meta.isPartOf - The placement data in following format: [source, date, page || sourceNumber] .
     * @param {String} review.meta.source - The name of source where the review has published.
     * @param {String} review.meta.publisher - The name of publisher.
     *
     * @throws {wrapXMLHttpError} - The ajax request error.
     * @returns {Promise<MaterialReview>} The fetched and\or proceeded review.
     */
    async _fetchMaterialReview(review) {
      let content = {
        title: review.title,
        isInfomedia: false,
        author: review.author,
        source: "",
        date: "",
        headline: "",
        description: review.description
      };

      let partOf = get(review, "meta.isPartOf", []);
      if (partOf.length >= 3) {
        content.source = partOf[0];
        content.date = partOf[1];
      } else {
        let [source, date] = (partOf[0] || "").split(",");

        content.source = source;
        content.date = date;
      }

      if (!content.source) {
        content.source = get(review, "meta.source") || get(review, "meta.publisher");
      }

      if (content.date) {
        content.date = moment(content.date, "YYYY-MM-DD").format("DD. MMMM YYYY");
      } else {
        content.date = review.year;
      }

      if (content.description) {
        let container = document.createElement("div");
        container.innerHTML = content.description;

        content.description = toText(addLineBreaks(container));
      }

      /* Only the lms urls is used for loading content. Like `/naesbib/article?faustNumber=37802522` */
      if (!review.url || !review.url.startsWith("/")) {
        return content;
      }

      let _review = await this.$easyscreenRequest.lmsConnector.custom({
        url: review.url
      });

      let container = document.createElement("div");
      container.innerHTML = _review.text;

      if (_review.text.includes("infomedia_")) {
        content.isInfomedia = true;
        let nodes = {
          title: container.querySelector(".infomedia_HeadLine") || container.querySelector(".infomedia_SubHeadLine"),
          author: container.querySelector(".infomedia_ByLine"),
          date: container.querySelector(".infomedia_DateLine"),
          source: container.querySelector(".infomedia_paper"),
          headline: container.querySelector(".infomedia_hedline"),
          description: container.querySelector(".infomedia_text"),
          copyright: container.querySelector(".infomedia_logo")
        };

        if (nodes.description) {
          addLineBreaks(nodes.description);
        }

        Object.keys(nodes).forEach(key => {
          if (nodes[key]) {
            content[key] = toText(nodes[key]);
          }
        });
      } else {
        content.description = container.innerText;
      }

      return content;
    }
  }
};
