var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'easyscreen-game',
    { 'easyscreen-game_game-screen': _vm.isGameScreen }
  ],style:({
    backgroundImage: _vm.backgroundImage ? `url('${ _vm.backgroundImage }')` : null
  })},[_c('h1',{staticClass:"easyscreen-game--title"},[(_vm.backButtonImage)?_c('img',{staticClass:"easyscreen-game--back-button",attrs:{"draggable":"false","src":_vm.backButtonImage},on:{"click":_vm._popScreen}}):_vm._e(),_c('span',{staticClass:"easyscreen-game--title-text"},[_vm._v(" "+_vm._s(_vm.screenTitle)+" ")])]),_c('div',{class:[
      'easyscreen-game--content',
      { 'easyscreen-game--content_game-screen': _vm.isGameScreen }
    ]},[(_vm.isSplashScreen)?_c('div',{staticClass:"easyscreen-game--splash-screen",on:{"click":_vm._hideSplashScreen}},[_c('img',{staticClass:"easyscreen-game--splash-screen-image",attrs:{"src":_vm.splashImage}})]):_vm._e(),(_vm.isNavigationScreen)?_c('div',{staticClass:"easyscreen-game--navigation"},_vm._l((_vm.screenData),function(element,elementIndex){return _c('div',{key:element.title + elementIndex,staticClass:"easyscreen-game--navigation-element",on:{"click":() => {
          if ('games' in element) {
            _vm._pushScreen(`[${ elementIndex }].games`);
          } else {
            _vm._pushScreen(`[${ elementIndex }]`);
          }
        }}},[_c('div',{staticClass:"easyscreen-game--navigation-element-image",style:({
            backgroundImage: element.buttonImage ? `url('${ element.buttonImage }')` : null
          })}),_c('div',{staticClass:"easyscreen-game--navigation-element-title"},[_vm._v(" "+_vm._s(element.title)+" ")])])}),0):(_vm.isGameScreen)?_c('div',{staticClass:"easyscreen-game--game"},[_c('iframe',{staticClass:"easyscreen-game--game-iframe",attrs:{"frameborder":"0","src":_vm.screenData.url}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }