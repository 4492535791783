<template>
  <modal-layout class="modal-layout_fullheight">
    <template slot="header">
      {{ _l10n("Receipt") }}
    </template>
    <template slot="content">
      <div :class="['materials-scanner-sendmail', orientation]">
        <svg
          v-if="_isPortrait()"
          xmlns="http://www.w3.org/2000/svg"
          width="996"
          height="527"
          viewBox="0 0 996 527"
          class="materials-scanner-sendmail--background-image"
        >
          <g fill="#373737" fill-rule="evenodd">
            <!-- eslint-disable-next-line -->
          <path d="M168.045 407.216s-32.951-111.662-41.166-147.786L447.346 55.766l-279.301 351.45zM8.548 149.064L431.796 39.132 113.316 239.32c-.055-.08-104.768-90.257-104.768-90.257zM512.235-15.125C490.929-9.429-58.8 135.973-58.8 135.973S90.615 270.885 89.933 269.722c-.224-.376 65.02 204.842 65.02 204.842S519.954 12.585 525.8 4.985c5.733-9.806.084-22.908-13.564-20.11zM1055.493 457.996c0 3.501-.672 6.813-1.638 9.97l-153.61-165.218 155.248-120.749v275.997zM599.34 491.564L755.002 325.69l58.995 44.59 56.44-44.866 158.216 166.15c-2.467.569-426.846.569-429.313 0zm-26.84-33.568V181.999l155.248 120.749-153.61 165.218c-.966-3.157-1.638-6.469-1.638-9.97zM1038.244 147.5L813.997 319.997 589.749 147.5h448.495zm-17.25-34.5H606.999C568.894 113 538 143.894 538 182v275.996c0 38.104 30.894 68.999 69 68.999h413.994c38.105 0 68.999-30.895 68.999-69V182c0-38.105-30.894-68.999-69-68.999z"/>
          </g>
        </svg>

        <authentication-tooltip
          class="materials-scanner-sendmail--tip"
          :enabled="emailAutocomplete"
          :isCprTooltip="true"
          :tooltipMessage="tooltipMessage"
        >
          <p :class="{
            'es-mt-2': emailAutocomplete,
            'es-mb-2': emailAutocomplete
          }">{{ _l10n('Enter your email to get a receipt') }}</p>
          <p v-if="emailAutocomplete">{{ _l10n('(or scan your loan card to autocomplete)') }}</p>
        </authentication-tooltip>
        <easyscreen-text-input
          :cursor="true"
          :value="email"
          :style="{ width: '100%' }"
          @input="(value) => { email = value }"
          ref="input"
        />
        <keyboard-email-shortcuts class="es-mt-4" @selected="_handleKeyboard" />
        <keyboard-fullsize
          class="es-mb-2"
          :style="{ marginTop: '12px' }"
          @key-click="_handleKeyboard"
          :override="{
            enter: {
              label: _l10n('OK')
            }
          }"
        />
        <h3 class="materials-scanner-sendmail--status es-mt-4">
          <span class="materials-scanner-sendmail--status-label text-bold">
            {{ processedMaterialsLabel }}: {{ processedMaterials }}
          </span>
          <span class="materials-scanner-sendmail--status-label text-bold">
            {{ _l10n('Items failed') }}: {{ failedMaterials }}
          </span>
        </h3>
      </div>
      <div v-if="_isPortrait()" class="materials-scanner-sendmail--status-headline border-top border-disabled"></div>
    </template>
    <template slot="footer">
      <easyscreen-button-group>
        <easyscreen-button
          color="primary"
          modificator="fixed-width_small"
          @click.native="$emit('close')"
        >
          {{ _l10n("Close without receipt") }}
        </easyscreen-button>
        <easyscreen-button
          color="primary"
          modificator="fixed-width_small"
          @click.native="$emit('send', email)"
          :disabled="!email || !_isEmailValid(email)"
        >
          {{ _l10n("Send receipt") }}
        </easyscreen-button>
      </easyscreen-button-group>
    </template>
  </modal-layout>
</template>

<style lang="less" src="../../core/mixins.less"></style>
<style lang="less" src="./sendmail.less"></style>

<script>
  import ModalLayout from "../../core/modal/layout.vue";
  import EasyscreenButton from "../../core/button/button.vue";
  import EasyscreenButtonGroup from "../../core/button/button-group.vue";
  import EasyscreenTextInput from "../../core/input/input.vue";
  import KeyboardFullsize from "../../core/keyboard/fullsize.vue";
  import KeyboardEmailShortcuts from "../../core/keyboard/email-shortcuts.vue";
  import AuthenticationTooltip from "../../patron/authentication/authentication-tooltip.vue";

  import * as d3 from "d3-timer";
  import emailValidator from "email-validator";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../../core/mixins/orientation.js";

  export default {
    name: "materials-scanner-sendmail",
    mixins: [orientationMixin],
    props: {
      processedMaterialsLabel: String,
      processedMaterials: {
        type: Number,
        default: 0
      },
      failedMaterials: {
        type: Number,
        default: 0
      },
      defaultEmail: String,
      withEmailAutocomplete: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      emailAutocomplete() {
        return this.supportEmailAutocomplete && this.withEmailAutocomplete && this._isBarcodeScannerEnabled();
      }
    },
    data() {
      return {
        supportEmailAutocomplete: false,
        tooltipMessage: "",
        email: this.defaultEmail || ""
      };
    },
    methods: {
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * The proxy method of `emailValidator.validate` (see npmjs.com/package/email-validator).
       */
      _isEmailValid: emailValidator.validate,
      /**
       * Handler of keyboard key-click event.
       *
       * @param {String} key - The pressed key.
       */
      _handleKeyboard(key) {
        if (key === "enter") {
          if (this.email && this._isEmailValid(this.email)) {
            this.$emit("send", this.email);
          }

          return;
        }

        KeyboardFullsize.defaultKeyboardHandler({
          onUpdated: this.$nextTick,
          value: {
            set: (value) => { this.email = value; },
            get: () => this.email
          },
          cursor: {
            set: (cursorIndex) => { this.$refs.input.setCursor(cursorIndex); },
            get: () => this.$refs.input.cursorIndex
          }
        }, key);
      },
      /**
       * Checkes if the barcode scanner enabled.
       *
       * @returns {Boolean} Flag of barcode scanner status.
       */
      _isBarcodeScannerEnabled() {
        return this.$friendlyFrankBarcodeScanner && this.$friendlyFrankBarcodeScanner.isEnabled();
      },
      /**
       * Listener of barcode scanner scan event.
       * Autocomplete the email by cpr
       *
       * @param {String} scannedCpr - The scanned cpr.
       */
      async _onBarcodeScan(scannedCpr) {
        if (this.supportEmailAutocomplete) {
          try {
            const profileData = await this.$easyscreenRequest.lmsConnector.getPatronEmail({ user: scannedCpr });
            if (profileData.email) {
              this.email = profileData.email;
            } else {
              if (this._noEmailMessageTimeout) {
                this._noEmailMessageTimeout.stop();
              }

              this.tooltipMessage = this.$l10n("No email found for given CPR!");
              this._noEmailMessageTimeout = d3.timeout(() => {
                this.tooltipMessage = "";
              }, 2000);
            }
          } catch (error) {
            console.error("Can't get patron email:", error);
          }
        }
      }
    },
    async created() {
      let providers = await this.$easyscreenRequest.lmsConnector.providers();
      this.supportEmailAutocomplete = providers["get /patron/email"] === "bibliofil";

      if (this.emailAutocomplete) {
        this.$friendlyFrankBarcodeScanner.on("scan", this._onBarcodeScan);
      }
    },
    mounted() {
      this.$refs.input.focusTheHiddenInput();
    },
    beforeDestroy() {
      if (this.$friendlyFrankBarcodeScanner) {
        this.$friendlyFrankBarcodeScanner.off("scan", this._onBarcodeScan);
      }
    },
    components: {
      "modal-layout": ModalLayout,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-button-group": EasyscreenButtonGroup,
      "easyscreen-text-input": EasyscreenTextInput,
      "keyboard-fullsize": KeyboardFullsize,
      "keyboard-email-shortcuts": KeyboardEmailShortcuts,
      "authentication-tooltip": AuthenticationTooltip
    }
  };
</script>
