import EventEmitter from "eventemitter3";
import InputInterceptor from "./input-interceptor.js";

/**
 * Matched substring.
 *
 * @event PatternReader#match
 * @type {String}
 */

/**
 * Emit the event with matched strings from InputInterceptor stream.
 * @class PatternReader
 * @augments EventEmitter3
 * @fires PatternReader#match
 * 
 * @param {Object} options - The reader options.
 * @param {RegEx} options.pattern - The pattern to match from InputInterceptor stream.
 * @param {Boolean} [options.logBuffer=false] - Log the buffer of reader on InputInterceptor input.
 */
export default class PatternReader extends EventEmitter {
  constructor(options) {
    super();
    options = options || {};

    this.inputInterceptor = new InputInterceptor();
    this.buffer = "";
    this.pattern = options.pattern;

    this.inputListener = (event) => {
      this.buffer += event.data;
      if (options.logBuffer) {
        console.log(`PatternReader[${ this.pattern }].buffer: ${ this.buffer }`);
      }

      const matched = this.buffer.match(this.pattern);
      if (matched) {
        this.buffer = "";
        if (options.logBuffer) {
          console.log(`PatternReader[${ this.pattern }].found: ${ matched[0] }`);   
        }

        this.emit("match", matched[0]);
      }
    };

    this.inputInterceptor.on("input", this.inputListener);
  }

  /**
   * Destroy the InputInterceptor listener and remove all "match" event listeners.
   */
  destroy() {
    this.removeAllListeners("match");
    this.inputInterceptor.off("input", this.inputListener);
    this.inputInterceptor.destroy();
    this.inputInterceptor = null;
  }  
}
