var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"easyscreen-tab"},[(_vm.withBackground)?_c('div',{staticClass:"easyscreen-tab--backgrounds"},_vm._l((_vm.elements),function(element,elementIndex){return _c('div',{key:`background-element-${ elementIndex || 'index-' + elementIndex }`,class:[
        'easyscreen-tab--background',
        { 'easyscreen-tab--background_active': elementIndex === _vm.activeElementIndex }
      ],style:({ backgroundImage: element.image ? `url('${ element.image }')` : null })})}),0):_vm._e(),_c('div',{staticClass:"easyscreen-tab--overlay"}),_c('div',{staticClass:"easyscreen-tab--navigation"},[_vm._t("navigation"),_c('scrollable',{ref:"navigationScrollable",attrs:{"max-height":"100%"}},_vm._l((_vm.elements),function(element,elementIndex){return _c('div',{key:`navigation-element-${ elementIndex || 'index-' + elementIndex }`,ref:"navigationElements",refInFor:true,class:[
          'easyscreen-tab--navigation-element',
          { 'easyscreen-tab--navigation-element_active': elementIndex === _vm.activeElementIndex }
        ],on:{"click":() => { _vm.selectIndex(elementIndex) }}},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(element.title)+" ")]},{"element":element})],2)}),0)],2),_c('div',{staticClass:"easyscreen-tab--content"},_vm._l((_vm.elements),function(element,elementIndex){return _c('div',{key:`content-element-${ elementIndex || 'index-' + elementIndex }`,class:[
        'easyscreen-tab--content-element',
        { 'easyscreen-tab--content-element_active': elementIndex === _vm.activeElementIndex }
      ],on:{"click":function($event){return _vm.$emit('expand-element', element, elementIndex)}}},[_vm._t("content",function(){return [_vm._v(" "+_vm._s(element.content)+" ")]},{"element":element})],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }