var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['patron-profile--reservations', _vm.orientation]},[_c('modal-layout',[_c('template',{slot:"header"},[_c('div',{staticClass:"patron-profile--common-title"},[_c('span',{staticClass:"patron-profile--common-title-text"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.reservations.length !== 0)?_c('easyscreen-badge',{staticClass:"patron-profile--common-title-badge",attrs:{"color":"primary","type":"circle"}},[_vm._v(" "+_vm._s(_vm.reservations.length)+" ")]):_vm._e(),_c('label',{staticClass:"patron-profile--common-select-all"},[_c('span',{staticClass:"patron-profile--common-select-all-label"},[_vm._v(_vm._s(_vm._l10n("Select all")))]),_c('easyscreen-checkbox',{staticClass:"patron-profile--common-select-all-checkbox",attrs:{"checked":_vm.reservationsInfo.length !== 0 && _vm.reservationsInfo.every(reservationInfo => reservationInfo.checked)},on:{"input":(_checked) => {
              _vm.reservationsInfo.forEach(reservationInfo => {
                reservationInfo.checked = _checked;
              });
            }}})],1)],1)]),_c('template',{slot:"content"},[_c('scrollable',{attrs:{"max-height":_vm._isLandscape() ? 465 : 1245,"smooth-edge-color":"#262626"}},[_vm._l((_vm.reservationsInfo),function(reservationInfo,index){return [_c('profile-material-info',{key:`${ reservationInfo.title }-${ index }`,attrs:{"title":reservationInfo.title,"attributes":reservationInfo.attributes,"checked":reservationInfo.checked},on:{"input":(_checked) => {
              reservationInfo.checked = _checked
            }}})]})],2)],1),_c('template',{slot:"footer"},[_c('easyscreen-button-group',{attrs:{"gap":"big"}},[_c('easyscreen-button',{attrs:{"color":"danger","modificator":"fixed-width","disabled":_vm._selectedReservations().length === 0},nativeOn:{"click":function($event){return _vm._deleteSelected.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm._l10n("Delete selected"))+" ("+_vm._s(_vm._selectedReservations().length)+") ")]),(_vm.reservationsUpdatable && _vm.provider !== 'bibliofil')?_c('easyscreen-button',{attrs:{"color":"primary","modificator":"fixed-width","disabled":_vm._selectedReservations().length === 0},nativeOn:{"click":function($event){return _vm.$refs.updateReservationsModal.show.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm._l10n("Update selected"))+" ("+_vm._s(_vm._selectedReservations().length)+") ")]):_vm._e()],1)],1)],2),_c('loader',{ref:"loader"}),_c('modal-alert',{ref:"alert"}),(_vm.reservationsUpdatable)?_c('modal-confirm',{ref:"updateReservationsModal",attrs:{"ok-title":_vm._l10n('Update')},on:{"ok":_vm._updateSelected}},[_c('template',{slot:"header"},[_vm._v(_vm._s(_vm._l10n("Update reservations")))]),_c('template',{slot:"content"},[_c('form',{staticClass:"es-negative-m-2 es-mt-1"},[_c('easyscreen-form-group',{attrs:{"title":_vm._l10n('Pick up reservation on')}},[_c('easyscreen-select',{ref:"pickupBranchSelect",attrs:{"label":_vm._l10n('Pick up reservation on'),"options":_vm._branchesToSelect(),"value":_vm.preferredBranch}})],1),_c('easyscreen-form-group',{attrs:{"title":_vm._l10n('Maximum waiting time for reservations')}},[_c('easyscreen-select',{ref:"waitingTimeSelect",attrs:{"label":_vm._l10n('Maximum waiting time for reservations'),"options":[{
              label: _vm._l10n('6 month'),
              value: '6'
            }, {
              label: _vm._l10n('5 month'),
              value: '5'
            }, {
              label: _vm._l10n('3 month'),
              value: '3'
            }]}})],1)],1)])],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }