<template>
  <div>
    <modal-fullscreen
      ref="modal"

      @before-open="(event) => $emit('before-open', event)"
      @opened="(event) => $emit('opened', event)"
      @before-close="(event) => $emit('before-close', event)"
      @closed="(event) => $emit('closed', event)"

      :class="[
        'easyscreen-search-light',
        { 'easyscreen-search-light_without-popular-searches': !$easyscreenConfig.get('enable.popularSearches') || !popularSearches },
        orientation
      ]"
      :style="{ '--easyscreen-search-light-special-color': specialColor }"
      :hide-empty-container="true"
      :auto-close="autoClose"
      :position="position"
    >
      <template slot="content">
        <slot name="header"></slot>
        <div class="easyscreen-search-light--content">
          <easyscreen-circle-button
            v-if="withCloseControls"
            class="easyscreen-search-light--close-button"
            icon="fal fa-times"
            color="white"
            size="medium"

            @click.native="hide"
          />

          <div
            v-if="$easyscreenConfig.get('enable.popularSearches') && popularSearches"
            :class="[
              'easyscreen-search-light--popular-searches',
              { 'easyscreen-search-light--popular-searches_suggestions-found': popularSearchesFound }
            ]"
          >
            <popular-searches-list-with-covers
              ref="popularSearches"
              @select="(tag) => findBy(tag.title)"
              @ready="() => {
                popularSearchesFound = true;
                _updateSpecialColor($refs.popularSearches.firstCoverDominantColor);
              }"
            />
          </div>

          <search-form
            class="easyscreen-search-light--search-controls"
            ref="searchForm"
            :default-sorting="defaultSorting"
            :with-sorting="false"
            :with-search-suggestions="!searchInProgress"
            popular-searches="false"
            design="light"
            @find="(query) => findBy(query)"
          />
        </div>

        <search-results-light
          ref="searchResultsModal"
          :class="[
            'easyscreen-search-light--search-results',
            { 'easyscreen-search-light--search-results_active': $refs.searchResultsModal && $refs.searchResultsModal.isShown }
          ]"
          :position="position"
          :store="store"
          :available-only-button="availableOnlyButton"
          :shelf-only-button="shelfOnlyButton"
          :popular-searches="popularSearches"
          :defaultSpecialColor="specialColor"
          @opened="() => $emit('modal-opened', $refs.searchResultsModal.hide, 'searchResults')"
          @closed="() => $emit('closed-inner-modal', 'searchResults')"
          @modal-opened="(closeModal, type) => $emit('modal-opened', closeModal, type)"
          @closed-inner-modal="(type) => $emit('closed-inner-modal', type)"
          @before-close="() => {
            $emit('before-search-results-closed');

            if ($refs.searchForm) {
              $refs.searchForm.clearQuery();
            }
          }"
        />
        <loader :position="position" ref="searchLoader" design="light" />
      </template>
    </modal-fullscreen>
  </div>
</template>

<style src="./search-light.less" lang="search-light" />

<script>
  import ModalFullscreen from "../core/modal/fullscreen.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import Loader from "../core/loader/loader.vue";
  import PopularSearchesListWithCovers from "../popular-searches/list-with-covers.vue";
  import SearchForm from "./search-form.vue";
  import SearchResultsLight from "./search-results-light.vue";
  import searchOriginal from "./search.vue";

  export default {
    name: "search-light",
    mixins: [searchOriginal],
    props: {
      defaultSpecialColor: {
        type: String,
        default: "#f7f7f7"
      },
      position: String
    },
    computed: {
      specialColor() {
        return this.customSpecialColor || this.defaultSpecialColor;
      }
    },
    data() {
      return {
        popularSearchesFound: false,
        customSpecialColor: null
      };
    },
    methods: {
      async _updateSpecialColor(dominantColorOrPromise) {
        this.customSpecialColor = await dominantColorOrPromise;
      },
      _setOrientation() {
        let layoutColumns = 8;
        let layoutRows = 2;

        if (this.orientation === "portrait-orientation") {
          layoutColumns = 4;
          layoutRows = 4;
        }

        this.store.commit({
          type: "setState",
          layoutColumns: layoutColumns,
          layoutRows: layoutRows
        });
      }
    },
    components: {
      "loader": Loader,
      "modal-fullscreen": ModalFullscreen,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "popular-searches-list-with-covers": PopularSearchesListWithCovers,
      "search-form": SearchForm,
      "search-results-light": SearchResultsLight
    }
  };
</script>
