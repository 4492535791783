<template>
  <div>
    <modal-layout-fullscreen
      colorScheme="easyscreen-menu-front-page"
      :class="[
        'easyscreen-menu',
        { 'easyscreen-menu_safety-mode': safetyModeEnabled },
        orientation
      ]"
      :data-id="skinId"
    >
      <template slot="header-center">
        <div class="easyscreen-menu--main-header">
          <h1 class="easyscreen-menu--main-title">{{ title || _l10n('Welcome!') }}</h1>
          <div v-if="$easyscreenConfig.get('enable.popularSearches') && popularSearchesType !== 'none'">
            <popular-searches-inline
              class="es-mt-2"
              :full-type="popularSearchesType"
              @select="(tag) => {
                _openScreen({ screenName: 'search' }, () => {
                  $refs.searchScreen.find(tag.title);
                });
              }"
            />
          </div>
          <p v-else class="easyscreen-menu--main-text">
            {{ subTitle || _l10n('Search the base, get inspired or pay your debts') }}
          </p>
          <div
            v-if="searchEnabled"
            class="easyscreen-menu--input-like-button"
            @click="_openScreen({ screenName: 'search' })"
          >
            <div class="easyscreen-menu--input-like-button_input">
              {{ _l10n('Search titles, authors, subjects or ISBN number') }}
            </div>
            <button class="easyscreen-menu--input-like-button_button">
              {{ _l10n('Search!') }}
            </button>
          </div>
        </div>
      </template>
      <template slot="content">
        <div class="easyscreen-menu--main-content">
          <h1
            v-if="safetyModeEnabled"
            class="easyscreen-menu--message easyscreen-menu--message_safety-mode"
          >
            {{ $l10n("NOTE: This computer is running in safety mode.") }}
            <br>
            {{ $l10n("Only loan features is available until the computer is back online.") }}
          </h1>
          <digital-shelf
            v-else-if="hasDigitalShelf"
            :disable-external-features="true"
            :suggested-lists="true"
            :items="items"
            :tags="tags"
            :layout-columns="_isLandscape() ? 9 : 5"
            :layout-rows="_isLandscape() ? 1 : 3"
            @find-by-tag="(tag) => {
              _openScreen({ screenName: 'search' }, () => {
                $refs.searchScreen.find(tag);
              });
            }"
          />
          <div v-else-if="logoImage" class="easyscreen-menu--logo">
            <img class="easyscreen-menu--logo-image" :src="logoImage">
          </div>
        </div>

        <search-screen
          ref="searchScreen"
          :popular-searches-type="popularSearchesType"
        />

        <profile-screen
          v-if="screen === 'profile'"
          @closed="screen = ''"
          ref="profileScreen"
        />
        <profile-pay-screen
          v-if="screen === 'pay'"
          @closed="screen = ''"
          ref="payScreen"
        />
        <profile-renew-screen
          v-if="screen === 'renew'"
          @closed="screen = ''"
          ref="renewScreen"
        />

        <self-check-loan-screen
          v-if="screen === 'loan'"
          @closed="screen = ''"
          ref="loanScreen"
        />
        <self-check-return-screen
          v-if="screen === 'return'"
          @closed="screen = ''"
          ref="returnScreen"
        />
        <self-check-scan-screen
          v-if="screen === 'scan'"
          @closed="screen = ''"
          @open-loan="() => {
            $refs.scanScreen.hide(() => {
              _openScreen({ screenName: 'loan' })
            });
          }"
          @find-by-tag="(tag) => {
            $refs.scanScreen.hide(() => {
              _openScreen({ screenName: 'search' }, () => {
                $refs.searchScreen.find(tag);
              });
            });
          }"
          ref="scanScreen"
        />
        <generic-screen
          v-if="screen === 'generic'"
          @closed="screen = ''"
          ref="genericScreen"
          :title="_get(screenData, 'title')"
          :slides="_get(screenData, 'slides')"
          :backgrounds="_get(screenData, 'backgrounds')"
        />
        <inspiration-screen
          v-if="screen === 'inspiration'"
          @closed="screen = ''"
          @search="_openScreen({ screenName: 'search' })"
          @find-by-tag="(tag) => {
            $refs.inspirationScreen.hide(() => {
              _openScreen({ screenName: 'search' }, () => {
                $refs.searchScreen.find(tag);
              });
            });
          }"
          ref="inspirationScreen"
          :request="_get(screenData, 'request', [])"
          :items="_get(screenData, 'items', [])"
          :tags="_get(screenData, 'tags', [])"
        />
        <map-screen
          v-if="screen === 'map'"
          @closed="screen = ''"
          ref="mapScreen"
          :view-id="_get(screenData, 'view')"
          :mark-id="_get(screenData, 'markId')"
          :color="_get(screenData, 'rawSlideData.settings.backgroundColor')"
        />
      </template>
      <template slot="footer">
        <easyscreen-carousel
          class="easyscreen-menu--navigation"
          ref="carousel"
          :layout-columns="_getCarouselColumns()"
          :layout-rows="1"
          :step="_getCarouselBoundaries().step"
          :position-offset="_getCarouselBoundaries().offset"
          :position-limit-left="_getCarouselBoundaries().limitLeft"
          :position-limit-right="_getCarouselBoundaries().limitRight"
          :optimization="false"
          @drag="(offset) => { buttonsOpacity = _getButtonsOpacity(offset) }"
          @dragging-finished="(offset) => { buttonsOpacity = _getButtonsOpacity(offset) }"
        >
          <div
            v-for="(button, index) in filteredButtons"
            class="easyscreen-menu--navigation-button"
            :key="`${ index }_${ button.label }`"
            :style="{ opacity: buttonsOpacity[index] }"
            @click="() => _openScreen(button)"
          >
            <div class="easyscreen-menu--navigation-button_icon">
              <span v-if="button.type === 'font-awesome'" class="font-icon">
                <i :class="button.icon"></i>
              </span>
              <img v-else :src="button.icon" draggable="false">
            </div>
            <div class="easyscreen-menu--navigation-button_label">
              {{ button.label }}
            </div>
          </div>
        </easyscreen-carousel>
      </template>
    </modal-layout-fullscreen>

    <screen-control-safety-mode />
  </div>
</template>

<style lang="less" src="../core/mixins.less"></style>
<style lang="less" src="./easyscreen-menu.less"></style>
<script>
  import { get } from "lodash";
  import { mapState } from "vuex";
  import l10n from "@/lib/localization/localization.js";
  import SolidColorSkin from "./solid-color-skin.js";

  import EasyscreenCarousel from "../core/carousel/carousel.vue";
  import ModalLayoutFullscreen from "../core/modal/layout-fullscreen.vue";
  import DigitalShelf from "../digital-shelf/digital-shelf.vue";
  import PopularSearchesInline from "../popular-searches/inline.vue";
  import ScreenControlSafetyMode from "../screen-control/safety-mode.vue";
  import ProfileScreen from "./screens/profile/profile.vue";
  import ProfilePayScreen from "./screens/profile/pay.vue";
  import ProfileRenewScreen from "./screens/profile/renew.vue";
  import SelfCheckLoanScreen from "./screens/self-check/loan.vue";
  import SelfCheckReturnScreen from "./screens/self-check/return.vue";
  import SelfCheckScanScreen from "./screens/self-check/scan.vue";
  import SearchScreen from "./screens/search/search.vue";
  import GenericScreen from "./screens/generic/generic.vue";
  import InspirationScreen from "./screens/inspiration/inspiration.vue";
  import MapScreen from "./screens/map.vue";

  import orientationMixin from "../core/mixins/orientation.js";

  export default {
    name: "easyscreen-menu",
    mixins: [orientationMixin],
    props: {
      /* Flag for enable the search screen. */
      searchEnabled: {
        type: Boolean,
        default: true
      },
      /* The custom background image or color. */
      backgroundImage: String,
      /* Front screen title, default: _l10n('Welcome!'). */
      title: String,
      /* Front screen additional title. default: _l10n('Search the base, get inspired or pay your debts'). */
      subTitle: String,
      /* Digital shelf items of the front screen. */
      items: {
        type: Array,
        default: () => ([])
      },
      /* Digital shelf tags of the front screen. */
      tags: {
        type: Array,
        default: () => ([])
      },
      /* The data of related sub screens. */
      screensData: {
        type: Object,
        default: () => ({
          map: {},
          inspiration: {}
        })
      },
      /* List of navigation buttons */
      buttons: {
        type: Array,
        default: () => ([])
      },
      /* The custom button color. */
      buttonsColor: String,
      /* Flag for enable digital shelf on the from screen. */
      hasDigitalShelf: {
        type: Boolean,
        default: true
      },
      /* The logo which is shown when digital shelf if disabled. */
      logoImage: String,
      /* Type of the screen with all popular searches. */
      popularSearchesType: {
        type: String,
        validator: _type => ["none", "list", "cloud"].includes(_type),
        default: "none"
      }
    },
    computed: {
      ...mapState({
        safetyModeEnabled: state => state.safetyModeEnabled
      }),
      filteredButtons() {
        if (this.safetyModeEnabled) {
          return this.buttons.filter(button => ["loan", "return"].includes(button.screenName));
        }

        return this.buttons;
      }
    },
    data() {
      return {
        buttonsOpacity: [],
        skinId: "",
        screen: "",
        screenData: {}
      };
    },
    watch: {
      backgroundImage(newValue) {
        this.solidColorSkin.set({
          backgroundImage: newValue
        });
      },
      buttonsColor(newValue) {
        this.solidColorSkin.set({
          buttonsColor: newValue
        });
      },
      buttons() {
        this._updateButtonsOpacity();
      }
    },
    methods: {
      /**
       * The proxy method of `lodash.get`.
       */
      _get: get,
      /* Proxy for localization function. */
      _l10n: l10n,

      /**
       * Get the amount of carousel columns, based on orientation.
       *
       * @returns {Number} Number of columns (8 for landscape and 5 for portrait).
       */
      _getCarouselColumns() {
        return this._isLandscape() ? 8 : 5;
      },

      /**
       * Get the carousel boundaries.
       *
       * @returns {Object} boundaries - Carousel boundaries.
       * @returns {Number} [boundaries.step] - The width of one slide step (slide width).
       * @returns {Number} [boundaries.offset] - The static posistion offset of slides from left.
       * @returns {Number} [boundaries.limitLeft] - The left boundary of carousel (slides can't be scrolled to left more than that value).
       * @returns {Number} [boundaries.limitRight] - The right boundary of carousel (slides can't be scrolled to right more than that value).
       */
      _getCarouselBoundaries() {
        const columns = this._getCarouselColumns();
        const step = 100 / columns;

        let offset = -100 / columns * 0.5;
        let limitLeft = step * -2;
        let limitRight = (this.filteredButtons.length - columns + 1) * step;

        if (this.filteredButtons.length <= columns) {
          offset = 50 - 100 / columns * this.filteredButtons.length * 0.5;

          if (this._isLandscape() && this.filteredButtons.length > 6 || this._isPortrait() && this.filteredButtons.length > 3) {
            limitLeft = -100;
            limitRight = 100;
          } else {
            limitLeft = 0;
            limitRight = 0;
          }
        }

        return { step, offset, limitLeft, limitRight };
      },

      /**
       * Get the rages where to the carousel items should be applied opacity.
       *
       * @returns {Object[]} hidingRanges - hidingRanges.
       * @returns {Number} hidingRanges[].from - Left edge of hiding range.
       * @returns {Number} hidingRanges[].to - Right edge of hiding range.
       */
      _getHidingRanges() {
        const columns = this._getCarouselColumns();
        const boundaries = this._getCarouselBoundaries();

        return [{
          from: -5 * boundaries.step,
          to: -1 * boundaries.step
        }, {
          from: -1 * boundaries.step,
          to: boundaries.step
        }, {
          from: (columns + 1) * boundaries.step,
          to: (columns - 1) * boundaries.step
        }, {
          from: (columns + 1) * boundaries.step,
          to: (columns + 5) * boundaries.step
        }];
      },

      /**
       * Get the button of navigation opacity based on position in carousel.
       *
       * @param {Number} position - The position in carousel.
       * @param {Object} options - Checking options.
       * @param {Object} options.step - The width of one slide step (slide width).
       * @param {Object[]} options.ranges - The hiding ranges.
       * @param {Number} options.ranges[].from - Left edge of hiding range.
       * @param {Number} options.ranges[].to - Right edge of hiding range.
       *
       * @returns {(null|Number)} Opacity of button.
       */
      _getButtonOpacity(position, options) {
        if (!options) {
          options = {};
        }

        if (!options.ranges || options.ranges.length === 0) {
          return;
        }

        let opacity = null;
        let checkEdges = [position];
        if (options.step) {
          checkEdges.push(position + options.step);
        }

        checkEdges.forEach(function(position) {
          return options.ranges.some(function(range) {
            let from = range.from;
            let to = range.to;
            let reversed = false;

            if (from > to) {
              to = range.from;
              from = range.to;
              reversed = true;
            }

            if (from < position && position < to) {
              let step = to - from;
              let _opacity = (position - from) / step;

              if (reversed) {
                _opacity = 1 - _opacity;
              }

              if (opacity === null || opacity > _opacity) {
                opacity = _opacity;
              }

              return true;
            }
          });
        });

        return opacity;
      },

      /**
       * Get the opacity for all buttons in navigation carousel.
       *
       * @param {Number} offset - The carousel offset.
       *
       * @returns {(null|Numer)[]} List of opacities for navigation buttons.
       */
      _getButtonsOpacity(offset) {
        const boundaries = this._getCarouselBoundaries();
        const hidingRanges = this._getHidingRanges();

        return this.filteredButtons.map((_, index) => {
          let buttonPosition = offset + boundaries.step * index;
          return this._getButtonOpacity(buttonPosition, {
            ranges: hidingRanges,
            step: boundaries.step
          });
        });
      },

      /**
       * Open the screen by data.
       *
       * @param {Object} options - The screen options.
       * @param {String} options.screenName - The name of screen.
       * @param {Object} options.screenData - The screen data.
       * @param {Function} callback - Callback, will be called when screen will be opend.
       *
       * @returns {Promise} Do not use this promise as callback, since it's will not wait the open animation.
       */
      async _openScreen(options, callback) {
        if (options.label) {
          this.$easyscreenStatistic.clickEsMenuNavigation({ buttonTitle: options.label });
        }

        let { screenName, screenData } = options;
        let buttonIndex = this.filteredButtons.indexOf(options);
        if (buttonIndex !== -1 && this.buttonsOpacity[buttonIndex] !== null) {
          const leftFullyShown = this.buttonsOpacity.lastIndexOf(null, buttonIndex);
          const rightFullyShown = this.buttonsOpacity.indexOf(null, buttonIndex);
          const distanceToLeft = leftFullyShown === -1 ? Infinity : Math.abs(buttonIndex - leftFullyShown);
          const distanceToRight = rightFullyShown === -1 ? Infinity : Math.abs(buttonIndex - rightFullyShown);
          const closestFullyShown = distanceToLeft < distanceToRight ? leftFullyShown : rightFullyShown;
          const steps = closestFullyShown - buttonIndex;
          const offset = this.$refs.carousel.getOffset() + this.$refs.carousel.step * steps;

          this.buttonsOpacity = this._getButtonsOpacity(this.$refs.carousel.clampOffset(offset) + this.$refs.carousel.positionOffset);
          await this.$refs.carousel.setOffset(offset);
        }

        this.screen = screenName;
        this.screenData = screenData;
        await this.$nextTick();
        this.$refs[`${ screenName }Screen`].show(callback);
      },
      async _updateButtonsOpacity() {
        await this.$nextTick();
        this.buttonsOpacity = this._getButtonsOpacity(this.$refs.carousel.offset + this.$refs.carousel.positionOffset);
      }
    },
    mounted() {
      this.buttonsOpacity = this._getButtonsOpacity(this.$refs.carousel.offset + this.$refs.carousel.positionOffset);
      this.solidColorSkin = new SolidColorSkin({
        backgroundImage: this.backgroundImage,
        buttonsColor: this.buttonsColor
      });
      this.solidColorSkin.mount();
      this.skinId = this.solidColorSkin.id;

      this.$on("orientation-changed", this._updateButtonsOpacity);
    },
    beforeDestroy() {
      this.$off("orientation-changed", this._updateButtonsOpacity);

      if (this.solidColorSkin) {
        this.solidColorSkin.unmount();
      }
    },
    components: {
      "easyscreen-carousel": EasyscreenCarousel,
      "modal-layout-fullscreen": ModalLayoutFullscreen,
      "digital-shelf": DigitalShelf,
      "popular-searches-inline": PopularSearchesInline,
      "screen-control-safety-mode": ScreenControlSafetyMode,
      "profile-screen": ProfileScreen,
      "profile-pay-screen": ProfilePayScreen,
      "profile-renew-screen": ProfileRenewScreen,
      "self-check-loan-screen": SelfCheckLoanScreen,
      "self-check-return-screen": SelfCheckReturnScreen,
      "self-check-scan-screen": SelfCheckScanScreen,
      "search-screen": SearchScreen,
      "generic-screen": GenericScreen,
      "inspiration-screen": InspirationScreen,
      "map-screen": MapScreen
    }

  };
</script>
