<template>
  <div :class="[
    'node-list-accordion',
    `node-list-accordion_design-${ design }`,
    `node-list-accordion_scale-${ $easyscreenScale }`
  ]">
    <easyscreen-accordion
      :elements="elements"
      :animationDuration="animationDuration * 1000"
      :autoAnimation="autoAnimation"
      @expand-element="(_, elementIndex) => _expandElement(items[elementIndex])"
    >
      <template v-slot:title="props">
        <div class="node-list-accordion--title">
          {{ props.element.title }}
        </div>
        <div
          v-if="isEvent && props.element.date"
          class="node-list-accordion--date"
        >
          {{ props.element.date }}
        </div>
        <div
          v-if="isEvent && props.element.location"
          class="node-list-accordion--location"
        >
          <span class="node-list-accordion--location-text">
            {{ props.element.location }}
          </span>
          <span
            v-if="props.element.price"
            class="node-list-accordion--price node-list-accordion--price_comma"
          >
            , 
          </span>
          <span
            v-if="props.element.price"
            class="node-list-accordion--price"
          >
            {{ props.element.price }}
          </span>
        </div>
      </template>
      <template v-slot:content="props">
        <div class="node-list-accordion--teaser">
          {{ props.element.teaser }}
        </div>
      </template>
    </easyscreen-accordion>

    <modal-blank
      ref="modal"
      :click-to-close="true"
      :position="modalPosition"
      @before-open="$emit('modal-opened', $refs.modal.hide, 'libraryEventsListInfo')"
      @closed="$emit('closed-inner-modal', 'libraryEventsListInfo')"
    >
      <div>
        <node-list-info
          v-if="activeElement"
          :design="design"

          :type="activeElement.type"
          :date="activeElement.date"
          :date-to="activeElement.dateTo"
          :location="activeElement.location"
          :price="activeElement.price"
          :image="activeElement.image"
          :title="activeElement.title"
          :es-teaser="activeElement.esTeaser"
          :teaser="activeElement.teaser"
          :text="activeElement.text"
          :lead="activeElement.lead"
        >
          <easyscreen-circle-button
            v-if="withModalControls"
            class="node-list-accordion--close-button node-list-accordion--close-button_top-right"
            icon="esi esi-times"
            :color="design === 'light' ? 'white' : undefined"
            :size="design === 'light' ? 'medium' : undefined"

            @click.native="$refs.modal.hide"
          />

          <easyscreen-circle-button
            v-if="withModalControls"
            class="node-list-accordion--close-button node-list-accordion--close-button_bottom-right"
            icon="esi esi-times"
            :color="design === 'light' ? 'white' : undefined"
            :size="design === 'light' ? 'medium' : undefined"

            @click.native="$refs.modal.hide"
          />
        </node-list-info>
      </div>
    </modal-blank>
  </div>
</template>

<style src="./node-list-accordion.less" lang="less"></style>

<script>
  import moment from "moment";
  import { castArray } from "lodash";
  import l10n from "@/lib/localization/localization.js";

  import ModalBlank from "../core/modal/blank.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import EasyscreenAccordion from "../core/accordion/accordion.vue";
  import NodeListInfo from "./node-list-info.vue";

  export default {
    name: "node-list-accordion",
    props: {
      /* The list of events. */
      items: Array,
      /* The layout of view. */
      layout: {
        type: [String, Array],
        default: "six-elements",
        validator: _layout => castArray(_layout).reduce((options, option) => {
          return options.concat(option.split(" "));
        }, []).filter(Boolean).every(option => {
          return [
            "six-elements",
            "eight-elements",
            "ten-elements",
            "cinema",
            "for-game",
            "welcome-screen-modifer",
            "events",
            "news"
          ].includes(option);
        })
      },
      /* The duration of auto scroll till end of list. */
      animationDuration: {
        type: Number,
        default: 5
      },
      /* Flag for enable the autoamimation on standby. */
      autoAnimation: {
        type: Boolean,
        default: false
      },
      modalPosition: String,
      /* The global reskin for materials list. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      },
      withModalControls: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      isEvent() {
        return this.layout.includes("events");
      },
      elements() {
        return this.items.map(item => {
          return {
            title: item.title,
            date: this._getFormatterDate(item.date, item.dateTo),
            location: item.location,
            price: item.price,
            teaser: item.teaser,
            image: item.bgImage || item.image
          };
        });
      }
    },
    data() {
      return {
        activeElement: null
      };
    },
    methods: {
      /**
       * Get formatted event date in range `dateFrom` - `dateTo`.
       *
       * @param {String} dateFrom - The date from which the event starts.
       * @param {String} dateTo - The date when event ends.
       *
       * @returns {String} Date of event in range with format "D. MMMM Kl. HH.mm"
       * See format options here: https://momentjs.com/docs/#/displaying/
       */
      _getFormatterDate(dateFrom, dateTo) {
        const _dateFrom = moment(dateFrom);
        const _dateTo = moment(dateTo);
        let date = moment();

        if (date < _dateFrom) {
          date = _dateFrom;
        } else if (date > _dateTo) {
          date = _dateTo;
        }

        date.hour(_dateFrom.hour());
        date.minute(_dateFrom.minute());

        let formattedDate = date.format("D. MMMM");

        if (date.hour() !== 0 || date.minute() !== 0) {
          formattedDate += " " + l10n("Kl.") + " " + date.format("HH:mm");
        }

        return formattedDate;
      },
      /**
       * Open element info.
       *
       * @param {Object} element
       * @param {String} element.type - "event" or "news".
       * @param {String} element.date - From date of event.
       * @param {String} element.dateTo - End date of event.
       * @param {String} element.location - Event location.
       * @param {String} [element.price] - Event price
       * @param {String} element.image - Preview image for event.
       * @param {String} element.title - Event\News title.
       * @param {String} element.esTeaser - Event\News description (not used).
       * @param {String} element.teaser - Event\News description (not used).
       * @param {String} element.text - Event\News description (not used).
       * @param {String} element.lead - Event\News description.
       */
      _expandElement(element) {
        if (!this.$refs.modal) {
          return;
        }

        this.$easyscreenStatistic.openNodeList({
          nid: element.nid,
          title: element.title
        });
        this.activeElement = element;
        this.$refs.modal.show();
      }
    },
    components: {
      "modal-blank": ModalBlank,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "easyscreen-accordion": EasyscreenAccordion,
      "node-list-info": NodeListInfo
    }
  };
</script>
