import EventEmitter from "eventemitter3";
import { extend } from "lodash";

import FriendlyFrank from "./core.js";
import RFIDScanner from "./scanner.js";

/**
 * The interface of es-linux-apps rfid scanner.
 *
 * @class SortingBin
 * @augments EventEmitter3
 *
 * @param {Object} options - The connection options.
 * @param {String} options.uri - Connection uri (like: `ws://localhost:9211`).
 * @param {Number} [options.reopenTimeout=2000] - The reconnection interval in ms. Used if the connection is not established or lost.
 * @param {Number} [options.reopenAttempts=5] - Amonut of total reconnections in row.
 *
 * @returns {SortingBin} - instace of SortingBin class.
 */
export default class SortingBin extends EventEmitter {
  constructor(options) {
    super();

    options = options || {};

    this.inventory = {};
    extend(this, options);

    this._init(options);
  }

  /**
   * Closes connection and destroys the FriendlyFrank\KeyboardReader instance.
   */
  destroy() {
    this._destroyed = true;
    this.inventory = {};

    if (this._friendlyFrank) {
      this._friendlyFrank.destroy();
    }
  }

  /**
   * Inialization of sorting bin scanner. Creates the instance of Friendly Frank.
   * @listens module:FriendlyFrank~action:added-materials
   * @listens module:FriendlyFrank~action:removed-materials
   *
   * @param {object} options - The connection options.
   * @param {String} options.uri - Connection uri (like: `ws://localhost:9211`). Required.
   * @param {Number} [options.reopenTimeout=2000] - The reconnection interval in ms. Used if the connection is not established or lost.
   * @param {Number} [options.reopenAttempts=5] - Amonut of total reconnections in row.
   */
  _init(options) {
    this._friendlyFrank = new FriendlyFrank(Object.assign({}, options, { autoConnect: false }));
    this._friendlyFrank.on("action:sorting-bin:removed-materials", (message) => {
      (message.materials || []).forEach(materialId => {
        this._addMaterial(materialId, message.inventory);
      });
    });
    this._friendlyFrank.on("action:sorting-bin:added-materials", (message) => {
      (message.materials || []).forEach(materialId => {
        this._removeMaterial(materialId, message.inventory);
      });
    });
    this._friendlyFrank.connect();
  }

  /**
   * Add material to the insatnce inventory by material id and scanner inventory.
   * @fires SortingBin#material-updated
   * @fires SortingBin#inventory-updated
   *
   * @param {String} materialId - The material id.
   * @param {Object} inventory - Inventory of rfid scanner.
   */
  _addMaterial(materialId, inventory) {
    if (this._destroyed) {
      return;
    }

    let materialOptions = RFIDScanner.packData(materialId, inventory);
    if (this.inventory[materialId]) {
      this.inventory[materialId] = Object.assign(this.inventory[materialId] || {}, materialOptions, {
        updated: Date.now()
      });

      /**
       * Material data update event.
       *
       * @event SortingBin#material-updated
       * @type {RFIDScannerMaterial} Material without loading details.
       */
      this.emit("material-updated", materialOptions);
      /**
       * The inventory of rfid scanner update event.
       *
       * @event SortingBin#inventory-updated
       * @type {RFIDScannerInventory}
       */
      this.emit("inventory-updated", this.inventory);
      return;
    }

    materialOptions.created = Date.now();
    this.inventory[materialId] = materialOptions;

    this.emit("material-added", materialOptions);
    this.emit("inventory-updated", this.inventory);
  }

  /**
   * Removes the material from inventory.
   * @fires SortingBin#material-updated
   * @fires SortingBin#inventory-updated
   *
   * @param {String} materialId - The material id.
   * @param {Object} inventory - Inventory of rfid scanner.
   */
  _removeMaterial(materialId, inventory) {
    if (this._destroyed) {
      return;
    }

    if (inventory) {
      let materialOptions = RFIDScanner.packData(materialId, inventory);
      if (materialOptions) {
        this.inventory[materialId] = Object.assign(this.inventory[materialId] || {}, materialOptions, {
          updated: Date.now()
        });

        this.emit("material-updated", materialOptions);
        this.emit("inventory-updated", this.inventory);
        return;
      }
    }

    let material = this.inventory[materialId];
    delete this.inventory[materialId];
    this.emit("material-removed", material);
    this.emit("inventory-updated", this.inventory);
  }
}
