var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
  'node-list-carousel-herlev-slide',
  `node-list-carousel-herlev-slide_${ _vm.formattedColor }`,
  _vm.orientation
]},[(_vm.isEvent)?_c('div',{staticClass:"node-list-carousel-herlev-slide--date"},[_c('easyscreen-tear-off-calendar',{staticClass:"node-list-carousel-herlev-slide--calendar",attrs:{"day-of-week-format":"ddd","date":_vm.formattedDate}})],1):_vm._e(),_c('div',{staticClass:"node-list-carousel-herlev-slide--navigation"},_vm._l((_vm.totalElements),function(elementNumber){return _c('div',{key:elementNumber,class:[
        'node-list-carousel-herlev-slide--navigation-point',
        { 'node-list-carousel-herlev-slide--navigation-point_active': (elementNumber - 1) === _vm.elementIndex }
      ]})}),0),_c('div',{staticClass:"node-list-carousel-herlev-slide--content"},[_c('h1',{staticClass:"node-list-carousel-herlev-slide--title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"node-list-carousel-herlev-slide--teaser ck-editor",domProps:{"innerHTML":_vm._s(_vm.teaser)}}),(_vm.location)?_c('div',{staticClass:"node-list-carousel-herlev-slide--location"},[_vm._v("Sted: "+_vm._s(_vm.location))]):_vm._e()]),_c('div',{class:[
      'node-list-carousel-herlev-slide--image',
      { 'node-list-carousel-herlev-slide--image_without-image': !_vm.backgroundImage }
    ],style:({
      backgroundImage: `url('${ _vm.backgroundImage }')`
    })})])
}
var staticRenderFns = []

export { render, staticRenderFns }