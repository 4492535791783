import lodash from "lodash";
import authorSurname from "./author-surname.js";

export default {
  methods: {
    /**
     * Fetch the material holdings and fill-in the possible wayfinder point.
     * @async
     *
     * @param {Object} options - The fetch options.
     * @param {String} options.id - The id\faustNumber of material.
     * @param {Object} options.material - The material detail ().
     * @param {Object} options.material.meta - The material meta.
     * @param {String} options.material.meta.dk5 - DK5 (like the id of placement) of material.
     * @param {String} options.material.meta.dk5WithoutName - The DK5 of material without the author name.
     * @param {String} options.material.meta.hyllesignatur - The shelfmark of material.
     * @param {String} options.material.meta.dewey - The dewey (like a DK5) of material.
     * @param {String} options.material.author - Author of material.
     *
     * @throws {wrapXMLHttpError} - The ajax request error.
     * @returns {Object[]} The list of material holdings.
     */
    async _fetchMaterialHoldings(options) {
      const providersMap = await this.$easyscreenRequest.lmsConnector.providers();
      const holdingProvider = providersMap["get /holdings"];
      const holdings = await this.$easyscreenRequest.lmsConnector.holdings({
        faustNumber: options.id
      });

      holdings.forEach(holding => {
        if (holding.hasDK5) {
          return;
        }

        holding.placement = holding.placement.split(" > ");

        const authorLastName = authorSurname(options.material.author);
        const dk5WithoutName = lodash.get(options.material, "meta.dk5WithoutName");
        const hyllesignatur = lodash.get(options.material, "meta.hyllesignatur");
        if (holdingProvider === "fbs") {
          if (lodash.get(options.material, "meta.dk5") === "sk") {
            holding.placement.push(authorLastName);
          } else if (dk5WithoutName) {
            holding.placement.push(dk5WithoutName);
          }
        } else if (holdingProvider === "bibliofil") {
          if (hyllesignatur) {
            holding.placement.push(hyllesignatur);
          } else {
            holding.placement.push(authorLastName);
          }

          holding.placement = holding.placement.slice(1);
        }

        holding.placement = holding.placement.filter(Boolean).join(" > ");
        holding.hasDK5 = true;

        holding.wayfinderPoint = lodash.omitBy({
          branchId: holding.branchId,
          departmentId: holding.departmentId,
          locationId: holding.locationId,
          subLocationId: holding.subLocationId,
          dk5: dk5WithoutName,
          author: authorLastName,
          dewey: lodash.get(options.material, "meta.dewey"),
          shelfmark: hyllesignatur
        }, (value) => lodash.isNil(value) || value == "");
      });

      return holdings;
    }
  }
};
