import randomId from "../../lib/utils/random-id.js";

/**
 * The custom skin of easyscreen menu based on buttons and background color.
 *
 * @class SolidColorSkin
 *
 * @param {Object} options - The skin options.
 * @param {String} [options.backgroundImage] - The image\color of custom background.
 * @param {String} [options.buttonsColor] - The custom color of buttons.
 *
 * @returns {RFIDScanner} - instace of RFIDScanner class.
 */
export default class SolidColorSkin {
  constructor(options) {
    this.options = options || {};
    this.id = "skin-" + randomId().replace(".", "-");
    this.css = this._getCSS();
    this.styleNode = this._createStyleNode();
  }

  /**
   * Mount the style to selected or default container.
   *
   * @param {HTMLElement} [container=document.querySelector("head")] - Container for mount style node.
   */
  mount(container) {
    container = container || document.querySelector("head");
    container.appendChild(this.styleNode);
  }

  /**
   * Mount the style if that mounted.
   */
  unmount() {
    if (this.styleNode.parentNode) {
      this.styleNode.parentNode.removeChild(this.styleNode);
    }
  }

  /**
   * Update styles.
   *
   * @param {Object} options - The skin options.
   * @param {String} [options.backgroundImage] - The image\color of custom background.
   * @param {String} [options.buttonsColor] - The custom color of buttons.
   */
  set(options) {
    this.options = {
      ...this.options,
      ...options
    };

    this.css = this._getCSS();
    this.styleNode.innerHTML = this.css;
  }

  /**
   * The the custom styles based on insatnce options.
   *
   * @returns {String} The custom styles (css).
   */
  _getCSS() {
    return [this.options.backgroundImage ? `
      [data-id="${ this.id }"].easyscreen-menu,
      [data-id="${ this.id }"] .easyscreen-menu--popup,
      [data-id="${ this.id }"] .easyscreen-menu--inspiration-screen .digital-shelf--footer,
      [data-id="${ this.id }"] .layout-fullscreen--color-scheme_easyscreen-menu,
      [data-id="${ this.id }"] .layout-fullscreen--color-scheme_easyscreen-menu-front-page,
      [data-id="${ this.id }"] .layout-fullscreen--color-scheme_easyscreen-menu-profile-page,
      [data-id="${ this.id }"] .easyscreen-menu--profile-screen .User-profile--footer {
        background: ${ this.options.backgroundImage } !important;
      }

      [data-id="${ this.id }"] .easyscreen-menu--search-screen .search-popup,
      [data-id="${ this.id }"] .easyscreen-menu--profile-screen .User-profile .User-profile--footer {
        background: ${ this.options.backgroundImage } !important;
      }

      [data-id="${ this.id }"] .easyscreen-menu--profile-screen .User-profile.landscape-orientation .User-profile--footer {
        height: 197px;
      }

      [data-id="${ this.id }"] .easyscreen-menu--profile-screen .User-profile .User-profile--content {
        background-color: rgba(14, 20, 21, 0.6);
      }

      [data-id="${ this.id }"] .easyscreen-menu--search-screen .Search-result .Search--input.in-search-results {
        border-color: rgba(255, 255, 255, 0);
      }

      [data-id="${ this.id }"] .easyscreen-menu--main-content .easyscreen-menu--DS-tags .digital-shelf--tags_tag-active {
        color: #fff;
      }` : null, this.options.buttonsColor ? `
      [data-id="${ this.id }"] .easyscreen-menu--navigation-button_icon,
      [data-id="${ this.id }"] .easyscreen-menu--input-like-button_button,
      [data-id="${ this.id }"] .easyscreen-menu--inspiration-screen .digital-shelf--footer .digital-shelf--tags_tag-active,
      [data-id="${ this.id }"] .easyscreen-menu--profile-screen .User-profile--footer .ES-button.active,
      [data-id="${ this.id }"] .easyscreen-menu--search-screen .Search-result .Search--input.in-search-results .Search--input-button,
      [data-id="${ this.id }"] .easyscreen-menu--search-screen .Search-result .Search-result--available-only-button,
      [data-id="${ this.id }"] .easyscreen-menu--search-screen .Search-result--facets_more-btn,
      [data-id="${ this.id }"] .easyscreen-menu--search-screen .Search-result--facets_fast-link,
      [data-id="${ this.id }"] .patron-profile .easyscreen-button_color-profile.active {
        background:  ${ this.options.buttonsColor };
      }

      [data-id="${ this.id }"] .easyscreen-menu--input-like-button_button,
      [data-id="${ this.id }"] .easyscreen-menu--search-screen .Search-result .Search--input.in-search-results .Search--input-button {
        box-shadow: inset 0 0 7px 1px rgba(0, 0, 0, 0.4);
      }
    ` : null].filter(Boolean).join("\n");
  }

  /**
   * Creates the style node and fills-in with custom styles.
   *
   * @returns {HTMLElement} The style node with css.
   */
  _createStyleNode() {
    let node = document.createElement("style");
    node.setAttribute("data-solid-skin-id", this.id);
    node.innerHTML = this.css;

    return node;
  }
}
