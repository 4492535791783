export default {
  /**
   * Fetch material detail
   * @async
   *
   * @param {Object} options - The fetch options.
   * @param {String} options.id - The id\faustNumber of material.
   * @param {Boolean} [options.useCache=true] - Use the cache.
   * @param {Boolean} [options.withExternalResources=false] - Add the external resources to material data.
   *
   * @throws {wrapXMLHttpError} - The ajax request error.
   * @returns {Object} The material detail.
   */
  methods: {
    async _fetchMaterial(options) {
      let _fetchMaterial = this.$easyscreenRequest.lmsConnector.detail;
      if (options.useCache === false) {
        _fetchMaterial = this.$easyscreenRequest.lmsConnector.detailWithoutCache;
      }

      const detail = await _fetchMaterial({
        faustNumber: options.id,
        withExternalResources: options.withExternalResources
      });

      return detail;
    }
  }
};
