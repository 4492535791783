<template>
  <div :class="[
    'search-input',
    {
      'search-input_with-sorting': withSorting,
      'search-input_scale-one-and-half': scaleUp
    }
  ]">
    <easyscreen-text-input
      ref="input"
      class="search-input--input-field"
      inputClass="search-input--input-view"
      clearButtonClass="search-input--input-clear-button"
      cursorClass="search-input--input-cursor"
      :value="value"
      :cursor="cursor"
      :readonly="readonlyInput"
      @input="(value) => $emit('input', value)"
      @click.native="(event) => $emit('text-input-click', event)"
    />
    <easyscreen-select
      v-if="withSorting"
      class="search-input--sorting-select es-mr-1"
      inputClass="search-input--select-view"
      chevronClass="search-input--select-chevron"
      :options="_getSortingOptions()"
      :value="sorting"
      :key="sorting"
      :design="design"
      @selected="(value) => $emit('sorting-selected', value)"
    />
    <easyscreen-button
      class="search-input--submit-button"
      color="primary"
    >
      {{ _l10n("Search") }}
    </easyscreen-button>
  </div>
</template>

<style src="./search-input.less" lang="less"></style>
<style src="../core/mixins.less" lang="less"></style>

<script>
  import l10n from "@/lib/localization/localization.js";

  import EasyscreenButton from "../core/button/button.vue";
  import EasyscreenTextInput from "../core/input/input.vue";
  import EasyscreenSelect from "../core/input/select.vue";

  export default {
    name: "search-input",
    props: {
      /* Scale up to 1.5 times to fit into the main search screen. */
      scaleUp: Boolean,
      /* The the sorting select. */
      withSorting: {
        type: Boolean,
        default: true
      },
      /* Value of input */
      value: {
        type: String,
        default: ""
      },
      /* The flag of coursor, display the cursor when true. */
      cursor: {
        type: Boolean,
        default: false
      },
      /* Sets the text input readonly when true */
      readonlyInput: {
        type: Boolean,
        default: false
      },
      /* The default sorting of search input, used only on creation step. */
      sorting: String,
      /* The global reskin for materials list. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    computed: {
      cursorIndex: {
        get() {
          return this.$refs.input.cursorIndex;
        },
        set(value) {
          this.$refs.input.cursorIndex = value;
        }
      }
    },
    methods: {
      /**
       * Set the cursor postion.
       *
       * @param {Number} cursorIndex - The new position of cursor.
       */
      setCursor(index) {
        this.$refs.input.setCursor(index);
      },
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Get the available sorting options.
       *
       * @returns {Object[]} sortingOptions
       * @returns {String} sortingOptions[].value - The value of sorting, used for request.
       * @returns {String} sortingOptions[].label - The label of sorting, shown for user.
       */
      _getSortingOptions() {
        return [{
          value: "",
          label: l10n("Best Match")
        }, {
          value: "title_ascending",
          label: l10n("Title a &gt; z").replace("&gt;", ">")
        }, {
          value: "title_descending",
          label: l10n("Title z &gt; a").replace("&gt;", ">")
        }, {
          value: "creator_ascending",
          label: l10n("Author a &gt; z").replace("&gt;", ">")
        }, {
          value: "creator_descending",
          label: l10n("Author z &gt; a").replace("&gt;", ">")
        }, {
          value: "date_ascending",
          label: l10n("Publication - oldest first")
        }, {
          value: "date_descending",
          label: l10n("Year - Descending")
        }];
      }
    },
    mounted() {
      this.$refs.input.focusTheHiddenInput();
    },
    components: {
      "easyscreen-button": EasyscreenButton,
      "easyscreen-text-input": EasyscreenTextInput,
      "easyscreen-select": EasyscreenSelect
    }
  };
</script>
