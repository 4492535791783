<template>
  <div :class="[
    'node-list-carousel-herlev-slide',
    `node-list-carousel-herlev-slide_${ formattedColor }`,
    orientation
  ]">
    <div
      v-if="isEvent"
      class="node-list-carousel-herlev-slide--date"
    >
      <easyscreen-tear-off-calendar
        class="node-list-carousel-herlev-slide--calendar"
        day-of-week-format="ddd"
        :date="formattedDate"
      />
    </div>

    <div class="node-list-carousel-herlev-slide--navigation">
      <div
        v-for="elementNumber in totalElements"
        :key="elementNumber"
        :class="[
          'node-list-carousel-herlev-slide--navigation-point',
          { 'node-list-carousel-herlev-slide--navigation-point_active': (elementNumber - 1) === elementIndex }
        ]"
      ></div>
    </div>

    <div class="node-list-carousel-herlev-slide--content">
      <h1 class="node-list-carousel-herlev-slide--title">
        {{ title }}
      </h1>
      <div class="node-list-carousel-herlev-slide--teaser ck-editor" v-html="teaser"></div>
      <div
        v-if="location"
        class="node-list-carousel-herlev-slide--location"
      >Sted: {{ location }}</div>
    </div>

    <div
      :class="[
        'node-list-carousel-herlev-slide--image',
        { 'node-list-carousel-herlev-slide--image_without-image': !backgroundImage }
      ]"
      :style="{
        backgroundImage: `url('${ backgroundImage }')`
      }"
    ></div>
  </div>
</template>

<style src="../../core/ck-editor.less" lang="less"></style>
<style src="./node-list-carousel-herlev-slide.less" lang="less"></style>

<script>
  import moment from "moment";
  import { paramCase } from "param-case";
  import orientationMixin from "../../core/mixins/orientation.js";

  import EasyscreenTearOffCalendar from "../../core/tear-off-calendar/tear-off-calendar.vue";

  export default {
    name: "node-list-carousel-herlev-slide",
    mixins: [orientationMixin],
    props: {
      type: String,
      date: String,
      dateTo: String,
      location: String,
      price: [String, Number],
      bgImage: String,
      image: String,
      title: String,
      esTeaser: String,
      teaser: String,
      text: String,
      lead: String,
      color: String,
      totalElements: Number,
      elementIndex: Number,
      isActiveElement: Boolean
    },
    computed: {
      isEvent() {
        return this.type === "event";
      },
      backgroundImage: {
        get() {
          return this.bgImage || this.image;
        }
      },
      formattedDate() {
        const dateFrom = moment(this.date);
        const dateTo = moment(this.dateTo);
        let date = moment();

        if (date < dateFrom) {
          date = dateFrom;
        } else if (date > dateTo) {
          date = dateTo;
        }

        date.hour(dateFrom.hour());
        date.minute(dateFrom.minute());

        return date.format();
      },
      formattedColor() {
        return paramCase(this.color || "");
      }
    },
    components: {
      "easyscreen-tear-off-calendar": EasyscreenTearOffCalendar
    }
  };
</script>
