import { render, staticRenderFns } from "./generic.vue?vue&type=template&id=1539073e"
import script from "./generic.vue?vue&type=script&lang=js"
export * from "./generic.vue?vue&type=script&lang=js"
import style0 from "./generic.less?vue&type=style&index=0&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports