export default {
  data() {
    return {
      popularSearches: []
    };
  },
  methods: {
    _moreEnabled() {
      return ["cloud", "list"].includes(this.fullType);
    },
    /**
     * Loads the popular searches from manager.
     */
    async _fetchPopularSearches() {
      try {
        const popularSearches = await this.$easyscreenRequest.easyscreenManager.popularSearches({
          limit: 50
        });

        this.popularSearches = popularSearches.filter(tag => tag.title !== "*").map(tag => ({
          title: tag.title,
          weight: tag.frequency
        })).filter(tag => (tag.title || "").trim());
        this.$emit("fetched", this.popularSearches);
      } catch (error) {
        console.error("Can't load popular searches", error);
      }
    }
  },
  mounted() {
    this._fetchPopularSearches();
  }
};
