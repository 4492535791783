<template>
  <v-popover
    :open="_isBarcodeScannerEnabled() && enabled"
    :container="false"
    trigger="manual"
    :autoHide="false"
    class="patron-authentication-form--input"
    popoverClass="easyscreen-v-tooltip"
  >
    <slot></slot>

    <template slot="popover">
      <div v-if="tooltipMessage">
        <div class="col patron-authentication-form--popover-text" v-html="tooltipMessage"></div>
      </div>
      <div v-else-if="isCprTooltip" class="row no-gutters align-items-end">
        <div class="col-8 patron-authentication-form--popover-text" v-html="cprTooltipText"></div>
        <div
          :class="[
            'col-4',
            'patron-authentication-form--popover-image',
            { 'patron-authentication-form--popover-image__oversize': cprTooltipImageOversize }
          ]"
        >
          <img :src="cprTooltipImage">
        </div>
      </div>
      <div v-else-if="isPinTooltip" class="row no-gutters align-items-end">
        <div class="col patron-authentication-form--popover-text" v-html="pinTooltipText"></div>
      </div>
    </template>
  </v-popover>
</template>

<script>
  import "../../core/tooltip/tooltip.js";

  export default {
    props: {
      /* Flag for using the barcode scanner features. */
      withBarcodeScanner: {
        type: Boolean,
        default: true
      },
      enabled: {
        type: Boolean,
        default: false
      },
      isCprTooltip: {
        type: Boolean,
        default: false
      },
      isPinTooltip: {
        type: Boolean,
        default: false
      },
      cprTooltipImageOversize: {
        type: Boolean,
        default: true
      },
      tooltipMessage: String
    },
    data() {
      return {
        cprTooltipText: `<strong>${ this.$l10n("Tip") }:</strong> ${ this.$l10n("Hold your card below the barcode scanner") }`,
        cprTooltipImage: "/images/scanner/auth/frank.svg",
        pinTooltipText: this.$l10n("Please enter PIN")
      };
    },
    methods: {
      /**
       * Checkes if the barcode scanner enabled.
       *
       * @returns {Boolean} Flag of barcode scanner status.
       */
      _isBarcodeScannerEnabled() {
        return this.withBarcodeScanner && this.$friendlyFrankBarcodeScanner && this.$friendlyFrankBarcodeScanner.isEnabled();
      }
    }
  };
</script>
