/**
   * Get the surname of author.
   * Followign names should be tested after the changes for this function:
   * - "Vince Locke"
   * - "Ævar Örn Jósepsson"
   * - "Thu Huong Duong"
   * - "R. L. Stine"
   * - "John Rasmussen (f. 1937)"
   * - "H. C. andersen, (f. 1805)"
   *
   * @param {String} author - The string contains the author first and surname, might have the meta e.g. year.
   *
   * @returns {String} The author surname or empty string.
   */
export default function authorSurname(author) {
  if (!author) {
    return "";
  }
  const name = author.split(",")[0].split(" ");

  if (name.length === 3 && name.every(str => str.includes(".") === false)) {
    return name[1];
  }

  const surname = name && name.reverse().find(str => {
    return !/[()]/.test(str);
  });

  return surname || (name && name[0]) || "";
}
