<template>
  <div :class="['easyscreen-tooltip--modal', tooltipClass]" :style="position">
    <slot></slot>
  </div>
</template>

<style src="./tooltip.less" lang="less"></style>

<script>
  import orientationMixin from "../mixins/orientation.js";

  export default {
    name: "easyscreen-tooltip-modal",
    mixins: [orientationMixin],
    props: {
      /* The custom class of the tooltip modal. */
      tooltipClass: [String, Array, Object],
      targetNode: HTMLElement,
      placement: {
        type: String,
        default: "top-center",
        validator: _placement => [
          "top-start",
          "top-center",
          "top-end",
          "right-start",
          "right-center",
          "right-end",
          "bottom-start",
          "bottom-center",
          "bottom-end",
          "left-start",
          "left-center",
          "left-end"
        ].includes(_placement)
      }
    },
    data() {
      return {
        position: {}
      };
    },
    watch: {
      /* Update the position on `placement` property change. */
      placement() {
        this._updatePosition();
      }
    },
    methods: {
      /**
       * Update the position of tooltip modal attached to an html element.
       */
      _updatePosition() {
        if (this.targetNode) {
          let targetBoundings = this._applyParentsScaleToBoundings(this.targetNode, this.targetNode.getBoundingClientRect());
          let modalStyles = window.getComputedStyle(this.$el);
          let position = {
            top: targetBoundings.top,
            left: targetBoundings.left
          };

          if (this.placement.startsWith("top")) {
            position.top -= parseFloat(modalStyles.height);
          } else if (this.placement.startsWith("right")) {
            position.left -= parseFloat(modalStyles.width);
          } else if (this.placement.startsWith("bottom")) {
            position.top += targetBoundings.height;
          } else if (this.placement.startsWith("left")) {
            position.left += targetBoundings.width;
          }

          if (this.placement.startsWith("top") || this.placement.startsWith("bottom")) {
            if (this.placement.endsWith("center")) {
              position.left -= parseFloat(modalStyles.width) / 2;
              position.left += targetBoundings.width / 2;
            } else if (this.placement.endsWith("end")) {
              position.left -= parseFloat(modalStyles.width);
              position.left += targetBoundings.width;
            }
          } else if (this.placement.startsWith("right") || this.placement.startsWith("left")) {
            if (this.placement.endsWith("center")) {
              position.top -= parseFloat(modalStyles.height) / 2;
              position.top += targetBoundings.height / 2;
            } else if (this.placement.endsWith("end")) {
              position.top -= parseFloat(modalStyles.height);
              position.top += targetBoundings.height;
            }
          }

          this.position = position;
        }
      }
    },
    mounted() {
      this._updatePosition();
    }
  };
</script>
