import { get, pick } from "lodash";
import { ValidationError, wrapXMLHttpError } from "../../../lib/errors.js";
import l10n from "@/lib/localization/localization.js";

export default {
  data() {
    return {
      processingInventory: {},
      inventory: []
    };
  },
  methods: {
    /**
     * Remove zero bytes and data after it from the material id.
     *
     * @param {String} materialId - Material id from the es-linux-app.
     * @returns {String} materialId - Fixed material id.
     */
    fixMaterialId(materialId) {
      return materialId.split("\x00")[0];
    },
    /**
     * Check the printer errors and show the screen or warning message.
     */
    show() {
      if (!this.$friendlyFrankPrinter.isOnline()) {
        let notification = this.$friendlyFrankPrinter.getUserErrorNotification({}, this.$easyscreenConfig);
        return this.$refs.confirmModal.show({
          title: notification.title,
          messageHTML: notification.message,
          callback: (event, type) => {
            if (type === "ok") {
              return this._show();
            }

            this.$refs.confirmModal.$once("closed", (event) => this.$emit("closed", event));
          }
        });
      }

      this._show();
    },
    /**
     * Canceling the processing of selected materials.
     *
     * @param {String[]} materialIds - List of material ids for cancel.
     */
    _cancel(materialIds) {
      let processingInventory = {};
      Object.keys(this.processingInventory).forEach(materialId => {
        processingInventory[materialId] = Object.assign({}, this.processingInventory[materialId]);

        if (materialIds.includes(materialId)) {
          processingInventory[materialId].status = "CANCELED";
          processingInventory[materialId].message = `${ l10n("Loan canceled!") }<br/>${ l10n("Item is not loaned") }`;
        }
      });

      this.processingInventory = processingInventory;
    },
    /**
     * Check if the printer is online.
     *
     * @returns {Boolean} Flag of state of the printer.
     */
    _printerIsOnline() {
      return this.$friendlyFrankPrinter.isOnline() && this.withPrinter;
    },
    /**
     * Show the processing confirmation popup.
     */
    _showConfirmationModal() {
      if (Object.keys(this.$friendlyFrankScanner.inventory).length !== 0) {
        this._onScannerInventoryUpdate(this.$friendlyFrankScanner.inventory, { requiredConfirmation: true });
        this.$refs.materialsConfirmationModal.show();
      }
    },
    /**
     * Validate material and wait for details loading.
     *
     * @param {String} materialId - Material id.
     *
     * @returns {Promise} The promise-based callback, will be called when material is valid and loaded.
     */
    async _waitMaterialForLoadingAndValidate(materialId) {
      const material = this.inventory.find(material => material.materialId === materialId);
      const processingInfo = material && this.processingInventory[material.materialId];

      if (!material || ["UNKNOWN", "NOT_FOUND"].includes(processingInfo.status)) {
        throw new ValidationError({ message: `Material not found or have wrong structure. Material id: ${ JSON.stringify(materialId || null) }` });
      }

      /* Wait for load or timeout */
      if (!material.detail && processingInfo.status === "LOADING" && material.loadingPromise) {
        await material.loadingPromise();
      }
    },
    /**
     * Get the materials with selected status code.
     *
     * @param {String} statusCodes - The status code of material.
     *
     * @returns {ProcessingMaterialInfo[]} List of processing info for materials.
     */
    _materialsWithStatus(statusCodes) {
      if (!Array.isArray(statusCodes)) {
        statusCodes = [statusCodes];
      }

      return Object.keys(this.processingInventory).filter(materialId => {
        return statusCodes.includes(this.processingInventory[materialId].status);
      });
    },
    /**
     * Get the processed materials (materials with status code "ACCEPTED").
     *
     * @returns {ProcessingMaterialInfo[]} List of processing info for materials.
     */
    _precessedMaterials() {
      return this._materialsWithStatus("ACCEPTED");
    },
    /**
     * Get the failed materials (materials with status code "CANCELED" or "REJECTED").
     *
     * @returns {ProcessingMaterialInfo[]} List of processing info for materials.
     */
    _failedMaterials() {
      return this._materialsWithStatus(["CANCELED", "REJECTED"]);
    },
    /**
     * Get the materials in progress (materials with status code "IN_PROGRESS").
     *
     * @returns {ProcessingMaterialInfo[]} List of processing info for materials.
     */
    _inProgressMaterials() {
      return this._materialsWithStatus("IN_PROGRESS");
    },
    /**
     * Get the materials for email temlate (processed).
     *
     * @returns {CombinedProcessingMaterialInfo[]} List of materials info.
     */
    _materialsToEmailTemplate() {
      return this._precessedMaterials().map(materialId => {
        return this._materialDataByMaterialId(materialId);
      });
    },
    /**
     * Get the Combined processing material info (The processing info and inventory data) for selected material.
     *
     * @param {String} materialId - Material id.
     *
     * @returns {CombinedProcessingMaterialInfo}
     */
    _materialDataByMaterialId(materialId) {
      /**
       * The combined material of processing info and inventory material data.
       *
       * @typedef {Object} CombinedProcessingMaterialInfo
       *
       * @property {String} materialId - Id of the processing material.
       * @property {Boolean} requiredConfirmation - flag of confirmation needs.
       * @property {Number} created - Date of creation in ms since 1.01.1970 (`Date.now` format).
       * @property {String} status - The status of processing: LOADING, NOT_FOUND, UNKNOWN, IN_PROGRESS, ACCEPTED, CANCELED, REJECTED, WAITING.
       *
       * @property {Number} packSize - Amount of materials with the same material id in the pack.
       * @property {Number[]} indexes - Indexes of material pack placed on scanner.
       * @property {Boolean} isComplete - `true` when all indexes of material pack on the scanner, false otherwise.
       * @property {String} alarm - State of material alarm: enabled, disabled or unknown.
       *   The unknown if the scanner software not supported alarm status or alarm state is different for materials in pack.
       *
       * @property {Object} [error] - The error of loading material detal.
       * @property {String} [error.code] - The error code.
       * @property {String} [error.message] - The error message.
       *
       * @property {Object} [detail] - The material detail.
       * @property {String} [detail.id] - The faust number of material.
       * @property {String} [detail.faustNumber] - Duplicate of id.
       * @property {String} [detail.author] - Author of material.
       * @property {String} [detail.title] - The title of material.
       * @property {String} [detail.description] - The description of material (like on back book cover).
       * @property {String} [detail.year] - The year of publication.
       * @property {String} [detail.type] - The type of material (book, cd, music and etc).
       *
       */
      return Object.assign(
        {},
        this.processingInventory[materialId],
        this.inventory.find(_material => _material.materialId === materialId) || {}
      );
    },
    /**
     * The handler of scanner inventory-updated event.
     * Creates the processing info for new materials, and starts the processing of it.
     *
     * @param {RFIDScannerInventory} inventory - .
     * @param {Object} options - The processing options.
     * @param {Object} [options.requiredConfirmation=false] - Flag of needs to confirm processing.
     */
    _onScannerInventoryUpdate(inventory, options) {
      options = options || {};

      let materialsToHandle = [];
      let newInventory = Object.keys(inventory).map(materialId => {
        const processingInfo = this.processingInventory[materialId];
        const isComplete = inventory[materialId].isComplete;
        const hasNoError = !inventory[materialId].error;
        const initialization = !processingInfo;
        const updating = get(processingInfo, "status") === "CANCELED"
          && (inventory[materialId].updated || inventory[materialId].created) > processingInfo.created;

        if ((initialization || updating) && isComplete && hasNoError) {
          /**
           * The info of item processing.
           *
           * @typedef {Object} ProcessingMaterialInfo
           *
           * @property {String} materialId - Id of the processing material.
           * @property {Boolean} requiredConfirmation - flag of confirmation needs.
           * @property {Number} created - Date of creation in ms since 1.01.1970 (`Date.now` format).
           * @property {String} status - The status of processing: LOADING, NOT_FOUND, UNKNOWN, IN_PROGRESS, ACCEPTED, CANCELED, REJECTED, WAITING.
           */
          try {
            this.$easyscreenStatistic.scanScanner({ materialIds: materialId });
          } catch (error) {
            console.error("Can't send statistic", error);
          }

          this.processingInventory[materialId] = {
            materialId: materialId,
            requiredConfirmation: options.requiredConfirmation,
            created: Date.now(),
            status: "IN_PROGRESS"
          };

          if (!options.requiredConfirmation) {
            materialsToHandle.push(materialId);
          }
        }

        const existingMaterial = this.inventory.find(_material => _material.materialId === materialId);
        return Object.assign(inventory[materialId], pick(existingMaterial, ["detail"]));
      });

      const disabledMaterials = this.inventory.filter(material => {
        if (!this.processingInventory[material.materialId])
          return false;

        const removed = !newInventory.find(_material => _material.materialId === material.materialId);
        const processed = ["IN_PROGRESS", "ACCEPTED", "CANCELED", "REJECTED"].includes(this.processingInventory[material.materialId].status);
        return removed && processed;
      }).map(material => Object.assign(material, { disabled: true }));

      this.inventory = disabledMaterials.concat(newInventory).sort((a, b) => {
        return a.created - b.created;
      });

      Object.keys(this.processingInventory).forEach(materialId => {
        if (this.processingInventory[materialId].status === "IN_PROGRESS") {
          let material = this.inventory.find(material => material.materialId === materialId);
          if (!material || !material.isComplete) {
            delete this.processingInventory[materialId];
          }
        }
      });

      materialsToHandle.forEach(materialId => {
        this._processMaterials([materialId]).catch(error => {
          console.error(error);
        });
      });

      if (this._getRequiredConfirmation().length === 0) {
        this.$refs.materialsConfirmationModal.hide();
      }
    },
    /**
     * Get the loading status of the material based on detail info (NOT_FOUND, UNKNOWN, LOADING or WAITING).
     *
     * @param {RFIDScannerMaterial} material - The inventory material.
     *
     * @returns {String} Status of the material.
     */
    _getMaterialStatus(material) {
      if (material.error) {
        if (material.error.code === "NOT_FOUND_ERROR") {
          return "NOT_FOUND";
        } else {
          return "UNKNOWN";
        }
      } else if (!material.detail && !this.inventory.find(_material => _material.materialId === material.materialId)) {
        return "LOADING";
      } else {
        return get(this.processingInventory, `[${ material.materialId }].status`, "WAITING");
      }
    },
    /**
     * Get the error or action message of the material.
     *
     * @param {RFIDScannerMaterial} material - The inventory material.
     *
     * @returns {String} Message of the material.
     */
    _getMaterialMessage(material) {
      const processingInfo = this.processingInventory[material.materialId];
      if (!processingInfo) {
        return "";
      }

      return processingInfo.message;
    },
    /**
     * Get the title of material by material id.
     *
     * @param {String} materialId - Material id.
     *
     * @returns {String} The defaul title (Material number ${materialId}) or actual title if that loaded.
     */
    _getMaterialTitle(materialId) {
      let title = `${ l10n("Material number") }: ${ materialId }`;
      let material = this.inventory.find(material => material.materialId === materialId);
      if (material) {
        title = get(material, "detail.title");
      }

      return title;
    },
    /**
     * Get the processing material info which required.
     *
     * @returns {ProcessingMaterialInfo[]}
     */
    _getRequiredConfirmation() {
      return Object.keys(this.processingInventory).filter(key => {
        return this.processingInventory[key].requiredConfirmation;
      });
    },
    /**
     * Print the receit.
     * Prints the receit for compleate materials (with status "ACCEPTED").
     * @async
     *
     * @returns {Promise} The promise-based callback, will be called when print request will be compleate.
     */
    async _printReceipt() {
      try {
        await this.$friendlyFrankPrinter.print({
          text: this.$refs.emailTemplate.toText()
        });

        this.$refs.alertModal.show({
          title: l10n("Success!"),
          message: l10n("Please take the receipt."),
          callback: this.hide
        });
      } catch (printError) {
        console.error(printError);

        this.$refs.alertModal.show(Object.assign(this.$friendlyFrankPrinter.getUserErrorNotification({}, this.$easyscreenConfig), {
          callback: this.hide
        }));
      }
    },
    /**
     * Send receipt via email (receit for materials with status "ACCEPTED").
     *
     * @param {String} email - Prefilled user email.
     */
    async _emailReceipt(email) {
      let sendMailError = await this.$easyscreenRequest.lmsConnector.email({
        to: email,
        html: this.$refs.emailTemplate.toHTML(),
        subject: l10n("Hand in receipt from self service")
      });

      if (sendMailError) {
        sendMailError = wrapXMLHttpError(sendMailError);
        console.error(sendMailError);

        this.$refs.alertModal.show({
          title: l10n("Receipt not sended"),
          message: l10n("Something went wrong at the time of sending, receipt not sended."),
          callback: this.hide
        });

        return;
      }

      this.$refs.alertModal.show({
        title: l10n("Receipt sended"),
        message: l10n("Receipt successfully sended on your email."),
        callback: this.hide
      });
    }
  },
  mounted() {
    this.$friendlyFrankScanner.on("inventory-updated", this._onScannerInventoryUpdate);
    this.$on("closed", () => {
      this.processingInventory = {};
      this.inventory = [];
    });
  },
  beforeDestroy() {
    this.$friendlyFrankScanner.off("inventory-updated", this._onScannerInventoryUpdate);
  }
};
