<template>
  <div>
    <loader v-if="!detail" type="in-place"/>
    <div
      v-else
      :class="[
        'material-detail',
        `material-detail_${ _availabilityStatus(Object.assign({ holdings: holdings }, detail)) }`,
        orientation
      ]"
    >
      <loader ref="loader" />
      <div class="material-detail--section material-detail--section_primary">
        <div class="material-detail--section material-detail--section_cover">
          <!-- image, qr-code and buttons -->
          <img
            :src="$easyscreenRequest.lmsConnector.wrapCover(detail.cover)"
            class="material-detail--cover"
            draggable="false"
          >
          <easyscreen-button
            v-if="reservationEnabled"
            color="primary"
            :modificator="['full-width', 'fixed-height']"
            @click.native="_openReservation"
          >
            {{ _l10n("Book") }}
          </easyscreen-button>
          <easyscreen-button
            v-if="useLoan"
            color="primary"
            :modificator="['full-width', 'fixed-height']"
            @click.native="() => $emit('open-loan')"
          >
            {{ _l10n("Loan") }}
          </easyscreen-button>
          <div class="material-detail--cart-controls">
            <easyscreen-materials-cart-button
              v-if="reservationBasketEnabled"
              class="material-detail--cart-button_open"
              @click.native="_showCart"
            />
            <easyscreen-button
              v-if="reservationBasketEnabled"
              color="primary"
              class="material-detail--cart-button_add"
              modificator="fixed-height"
              @click.native="_addToCart"
            >
              {{ _l10n("Add to cart") }}
            </easyscreen-button>
          </div>
        </div>
        <div class="material-detail--section material-detail--section_content">
          <div class="material-detail--header">
            <span class="material-detail--type">{{ detail.type || "unknow" }}</span>
            <span v-if="detail.author" class="material-detail--author">{{ detail.author }}</span>
            <span v-if="detail.year" class="material-detail--year">({{ detail.year }})</span>
          </div>
          <!-- Title and description -->
          <div class="material-detail--content-group">
            <div class="material-detail--content-title">{{ detail.title }}</div>
            <div class="material-detail--content">
              <easyscreen-foldable
                :max-folded-height="80"
                :max-unfloded-height="200"
                background-color="#000000"
                tooltip-class="material-detail--content"
              >
                {{ detail.description }}
              </easyscreen-foldable>
            </div>
          </div>
          <!-- Subjects -->
          <div v-if="_hasTags()" class="material-detail--content-group">
            <div class="material-detail--content-title">{{ _l10n("Subject") }}:</div>
            <div class="material-detail--content">
              <easyscreen-foldable
                :max-folded-height="46"
                :max-unfloded-height="200"
                background-color="#000000"
                tooltip-class="material-detail--content"
              >
                <easyscreen-button
                  v-for="tag in uniqueTags"
                  :key="tag"
                  class="material-detail--tag"
                  @click.native="() => $emit('select-tag', tag)"
                >
                  {{ tag }}
                </easyscreen-button>
              </easyscreen-foldable>
            </div>
          </div>
          <!-- Meta -->
          <div v-if="_hasMeta()" class="material-detail--content-group">
            <div class="material-detail--content-title">{{ _l10n("Material details") }}:</div>
            <div class="material-detail--content">
              <easyscreen-foldable
                :max-folded-height="150"
                :max-unfloded-height="500"
                background-color="#000000"
                tooltip-class="material-detail--content"
              >
                <meta-information-table
                  class="material-detail--meta"
                  :metaPair="metaPair"
                />
              </easyscreen-foldable>
            </div>
          </div>
        </div>
        <div class="material-detail--section material-detail--section_holdings">
          <!-- Holdings -->
          <div class="material-detail--content-group">
            <div class="material-detail--content-title"></div>
            <div class="material-detail--content">
              <easyscreen-foldable
                v-if="holdings && holdings.length > 0"
                ref="holdingsFoldable"
                :max-folded-height="220"
                :max-unfloded-height="300"
                background-color="#000000"
                tooltip-class="material-detail--content"
              >
                <table class="material-detail--holdings">
                  <thead>
                    <tr>
                      <td>{{ _l10n("Find the book here") }}:</td>
                      <td>{{ _l10n("Number") }}:</td>
                      <td>{{ _l10n("On the shelf") }}:</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="holding in holdings"
                      :key="holding.placement"
                    >
                      <td>
                        <i
                          v-if="holding.wayfinderView && holding.available > 0"
                          class="fa fa-map-marker material-detail--wayfinder-marker"
                          @click="() => {
                            $refs.holdingsFoldable.hide();
                            _openWayfinder(holding.wayfinderView);
                          }"
                        ></i>
                        <span>
                          {{ (holding.placement || "").replace(/\s*>\s*>/g, "") }}
                        </span>
                      </td>
                      <td>{{ holding.total }}</td>
                      <td>{{ holding.available }}</td>
                    </tr>
                  </tbody>
                </table>
              </easyscreen-foldable>
            </div>
          </div>
        </div>
      </div>
      <div class="material-detail--section material-detail--section_secondary">
        <div :class="[
          'material-detail--section',
          'material-detail--section_suggestions',
          `material-detail--section_suggestions-${ suggestedLists.slice(0, 3).length }`
        ]">
          <!-- suggested materials -->
          <div
            v-for="list in suggestedLists.slice(0, 3)"
            :key="list.title"
            class="material-detail--suggested-list"
          >
            <h3 class="material-detail--suggested-list-title">{{ list.title }}</h3>
            <easyscreen-carousel
              ref="carousel"
              class="material-detail--suggested-list-carousel"
              :layout-columns="suggestedLists.length === 3 ? 8 : 5"
              :layout-rows="1"
            >
              <digital-shelf-cover
                v-for="material in list.materials"
                :key="material.id"
                :title="material.title"
                :author="material.author"
                :cover="material.cover"
                @click.native="() => $emit('suggested-material', list.materials.map(material => material.id), material.id)"
              />
            </easyscreen-carousel>
          </div>
        </div>
      </div>
      <div class="material-detail--section material-detail--section_navigation">
        <!-- detail controls and external materials -->
        <!-- publisher description, reviews, video from youtube and ereolen preview -->
        <easyscreen-button-group class="material-detail--navigation-element" gap="medium">
          <span
            v-for="group in navigation"
            :key="group.title"

            :is="group.list ? 'easyscreen-tooltip' : 'span'"
            :open="group.shown"
            placement="top-start"
          >
            <easyscreen-button
              class="tooltip-target material-detail--navigation-button"
              size="medium"
              @click.native="() => {
                navigation.forEach(_group => {
                  if (_group !== group) {
                    _group.shown = false;
                  }
                });

                if (group.list) {
                  group.shown = !group.shown;
                } else {
                  _openBy(group);
                }
              }"
            >
              {{ _l10n(group.title) }}
              <easyscreen-badge
                v-if="group.list || group._list"
                class="material-detail--navigation-button-counter"
                color="primary"
                type="circle"
              >
                {{ group.list ? group.list.length : group._list.length }}
              </easyscreen-badge>
            </easyscreen-button>
            <template v-if="group.list" slot="tooltip">
              <span class="material-detail--navigation-list">
                <span
                  v-for="element in group.list"
                  :key="element.id"
                  class="material-detail--navigation-list-element"
                  @click="() => _openBy(group, element)"
                >
                  {{ element.label }}
                </span>
              </span>
            </template>
          </span>
        </easyscreen-button-group>
      </div>

      <modal-alert
        ref="alert"
        @before-open="() => $emit('before-open-inner-modal')"
        @closed="() => $emit('closed-inner-modal')"
      />

      <modal-confirm
        ref="confirm"
        @before-open="() => $emit('before-open-inner-modal')"
        @closed="() => $emit('closed-inner-modal')"
      />

      <modal-blank
        v-if="activeWayfinderView"
        ref="wayfinderModal"

        @before-open="() => $emit('before-open-inner-modal')"
        @closed="() => {
          $emit('closed-inner-modal');
          activeWayfinderView = null;
        }"

        class="material-detail--wayfinder prevent-carousel-actions"
        min-width="100%"
        min-height="100%"
      >
        <template slot="raw">
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_bottom-right"
            icon="/images/es-menu/close_icon.png"
            icon-type="image"

            @click.native="() => $refs.wayfinderModal.hide()"
          />

          <easyscreen-iframe
            class="material-detail--wayfinder-view"
            :url="activeWayfinderView"
            :overlay="false"
          />
        </template>
      </modal-blank>

      <modal-blank
        v-if="ereolenView"
        ref="ereolenModal"

        @before-open="() => $emit('before-open-inner-modal')"
        @closed="() => $emit('closed-inner-modal')"

        class="material-detail--ereolen prevent-carousel-actions"
        min-width="100%"
        min-height="100%"
      >
        <template slot="raw">
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_bottom-right"
            icon="/images/es-menu/close_icon.png"
            icon-type="image"

            @click.native="() => $refs.ereolenModal.hide()"
          />

          <easyscreen-iframe
            class="material-detail--ereolen-view"
            :url="ereolenView"
            :overlay="false"
          />
        </template>
      </modal-blank>

      <modal-blank
        v-if="publisherDescription"
        ref="publisherDescriptionModal"

        @before-open="() => $emit('before-open-inner-modal')"
        @closed="() => {
          $emit('closed-inner-modal');
        }"

        class="material-detail--review prevent-carousel-actions"
        min-width="100%"
        min-height="100%"
      >
        <template slot="raw">
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_bottom-right"
            icon="/images/es-menu/close_icon.png"
            icon-type="image"

            @click.native="() => $refs.publisherDescriptionModal.hide()"
          />

          <scrollable
            class="material-detail--publisher-description material-detail--review-view"
            :max-height="_isLandscape() ? 900 : 850"
            smooth-edge-color="#000000"
          >
            <div v-html="publisherDescription"></div>
          </scrollable>
        </template>
      </modal-blank>

      <modal-blank
        v-if="activeReview"
        ref="reviewModal"

        @before-open="() => $emit('before-open-inner-modal')"
        @closed="() => {
          $emit('closed-inner-modal');
          activeReview = null;
        }"

        class="material-detail--review prevent-carousel-actions"
        min-width="100%"
        min-height="100%"
      >
        <template slot="raw">
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_bottom-right"
            icon="/images/es-menu/close_icon.png"
            icon-type="image"

            @click.native="() => $refs.reviewModal.hide()"
          />

          <scrollable
            class="material-detail--review-view"
            :max-height="_isLandscape() ? 900 : 850"
            smooth-edge-color="#000000"
          >
            <div class="material-detail--review-meta">
              <h3 class="material-detail--review-title">
                {{ activeReview.title }}
              </h3>
              <div class="material-detail--review-logo">
                <img
                  v-if="activeReview.isInfomedia"
                  src="/images/icon/infomedia-logo.png"
                  draggable="false"
                />
              </div>
              <div class="material-detail--review-author">
                {{ activeReview.author }}
              </div>
              <div class="material-detail--review-source">
                {{ activeReview.source }}
              </div>
              <div class="material-detail--review-date">
                {{ activeReview.date }}
              </div>
            </div>
            <h2 class="material-detail--review-headline">
              {{ activeReview.headline }}
            </h2>
            <div class="material-detail--review-content">
              {{ activeReview.description }}
            </div>
          </scrollable>
        </template>
      </modal-blank>

      <modal-blank
        v-if="videos"
        ref="videosModal"

        @before-open="() => $emit('before-open-inner-modal')"
        @closed="() => $emit('closed-inner-modal')"

        class="material-detail--videos prevent-carousel-actions"
        min-width="100%"
        min-height="100%"
      >
        <template slot="raw">
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_bottom-right"
            icon="/images/es-menu/close_icon.png"
            icon-type="image"

            @click.native="() => $refs.videosModal.hide()"
          />

          <scrollable
            class="material-detail--videos-view"
            :max-height="_isLandscape() ? 900 : 850"
            smooth-edge-color="#000000"
          >
            <div
              v-for="video in videos"
              :key="video.url"

              class="material-detail--video"
              @click="() => _openVideo(video)"
            >
              <img
                class="material-detail--video-thumbnail"
                draggable="false"
                :src="video.thumbnail"
              >
              <h2 class="material-detail--video-title">
                {{ video.title }}
                <span v-if="video.date" class="material-detail--video-date">({{ video.date }})</span>
              </h2>
              <div class="material-detail--video-description">
                {{ video.description }}
              </div>
            </div>
          </scrollable>
        </template>
      </modal-blank>

      <modal-blank
        v-if="activeVideo"
        ref="singleVideoModal"

        class="material-detail--single-video prevent-carousel-actions"
        min-width="100%"
        min-height="100%"
      >
        <template slot="raw">
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_bottom-right"
            icon="/images/es-menu/close_icon.png"
            icon-type="image"

            @click.native="() => $refs.singleVideoModal.hide()"
          />

          <div class="material-detail--single-video-view">
            <easyscreen-youtube
              :url="activeVideo.url"
              :muted="false"
            />
          </div>
        </template>
      </modal-blank>

      <authentication-form
        v-if="reservationEnabled"
        ref="authenticationForm"
        type="auto-reservation"
        class="material-reservation"
        :title="_l10n('Reserve item')"
        :ok-title="_l10n('Reserve')"
        :message="_l10n('Please login with library account to make the reservation')"
        @authenticated="(_, formData) => {
          reserveMaterial(formData);
        }"
      />

      <easyscreen-materials-cart
        v-if="reservationBasketEnabled"
        ref="materialsCart"

        @before-open="() => $emit('before-open-inner-modal')"
        @closed="() => $emit('closed-inner-modal')"
      />
    </div>
  </div>
</template>

<style src="./material-detail.less" lang="less"></style>
<script>
  import Loader from "../core/loader/loader.vue";
  import ModalBlank from "../core/modal/blank.vue";
  import ModalAlert from "../core/modal/alert.vue";
  import ModalConfirm from "../core/modal/confirm.vue";
  import EasyscreenButton from "../core/button/button.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import EasyscreenButtonGroup from "../core/button/button-group.vue";
  import EasyscreenCarousel from "../core/carousel/carousel.vue";
  import EasyscreenBadge from "../core/badge/badge.vue";
  import EasyscreenTooltip from "../core/tooltip/tooltip.vue";
  import EasyscreenFoldable from "../core/foldable/foldable.vue";
  import EasyscreenIframe from "../core/iframe/iframe.vue";
  import EasyscreenYoutube from "../core/youtube/youtube.vue";
  import Scrollable from "../core/scrollable/scrollable.vue";
  import DigitalShelfCover from "../digital-shelf/cover.vue";
  import AuthenticationForm from "../patron/authentication/authentication.vue";
  import EasyscreenMaterialsCart from "../materials-cart/materials-cart.vue";
  import EasyscreenMaterialsCartButton from "../materials-cart/materials-cart-button.vue";
  import MetaInformationTable from "./meta-information-table.vue";

  import { get, uniq, uniqBy } from "lodash";
  import isElementOf from "@/lib/utils/is-element-of.js";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../core/mixins/orientation.js";
  import availabilityStatus from "./availability-status.js";
  import fetchMaterialMixin from "./fetch-material-mixin.js";
  import fetchMaterialHoldingsMixin from "./fetch-material-holdings-mixin.js";
  import reorderMaterialHoldings from "./reorder-material-holdings.js";
  import fetchWayfinderViewMixin from "./fetch-wayfinder-view-mixin.js";
  import fetchMaterialSuggestedListsMixin from "./fetch-material-suggested-lists-mixin.js";
  import fetchMaterialReviewMixin from "./fetch-material-review-mixin.js";
  import reserveMaterialMixin from "./reserve-material-mixin.js";
  import getMaterialAvailability from "./get-material-availability.js";
  import metaInformationMixin from "./meta-information.mixin.js";

  /**
   * Material detail.
   *
   * @typedef MaterialDetail
   *
   * @property {Object} detail - The material detail.
   * @property {String} detail.id - The faust number of material.
   * @property {String} detail.faustNumber - Duplicate of id.
   * @property {String} detail.author - Author of material.
   * @property {String} detail.title - The title of material.
   * @property {String} detail.description - The description of material (like on back book cover).
   * @property {String} detail.year - The year of publication.
   * @property {String} detail.type - The type of material (book, cd, music and etc).
   * @property {Object} [detail.externalResources] - The external resources related to material.
   * @property {Object[]} [detail.externalResources.reviews] - The review object from materialDetail.externalResources.reviews
   * @property {String} [detail.externalResources.reviews[].url] - The url of online review (should be lms url, otherwise will be used existing data).
   * @property {String} [detail.externalResources.reviews[].title] - Title of review.
   * @property {String} [detail.externalResources.reviews[].author] - Review author.
   * @property {String} [detail.externalResources.reviews[].description] - The review content.
   * @property {String} [detail.externalResources.reviews[].year] - Publication year.
   * @property {Object} [detail.externalResources.reviews[].meta] - The meta infromation of the review.
   * @property {String} [detail.externalResources.reviews[].meta.isPartOf] - The placement data in following format:
   *                                                                         [source, date, page || sourceNumber].
   * @property {String} [detail.externalResources.reviews[].meta.source] - The name of source where the review has published.
   * @property {String} [detail.externalResources.reviews[].meta.publisher] - The name of publisher.
   * @property {Object[]} [detail.externalResources.videos] - The video data related to material.
   * @property {String} [detail.externalResources.videos[].url] - The url to video.
   * @property {String} [detail.externalResources.videos[].thumbnail] - The video thumbnail.
   * @property {String} [detail.externalResources.videos[].title] - The title of video.
   * @property {String} [detail.externalResources.videos[].date] - Date of video publication.
   * @property {String} [detail.externalResources.videos[].description] - Video description.
   */
  /**
   * Get the detail reviews sorted by group.
   *
   * @param {MaterialDetail} detail - The material detail.
   *
   * @returns {Object[]} reviews - Reviews
   * @returns {String} reviews[].type - The type of group (used for handle clicks in GUI).
   * @returns {String} reviews[].title - The title of group.
   * @returns {String} reviews[].list - The list of reviews (other review options see for MaterialDetail `detail.externalResources.reviews`).
   * @returns {String} reviews[].list[].label - The review label in following format: `year, source, author`.
   */
  function getReviewsByGroups(detail) {
    let groups = {};

    get(detail, "externalResources.reviews", []).forEach((review) => {
      review.type = review.type || "Review";
      review.label = [review.year, get(review, "meta.source"), review.author].filter(Boolean).join(", ");
      groups[review.type] = groups[review.type] || [];

      if (review.url && review.url.startsWith("/") || review.description) {
        groups[review.type].push(review);
      }
    });

    return Object.keys(groups).map(groupName => {
      return {
        type: "review",
        title: groupName,
        list: groups[groupName]
      };
    }).filter(group => group.list.length !== 0);
  }

  /**
   * Get the detail videos.
   *
   * @param {MaterialDetail} detail - The material detail.
   *
   * @returns {Object} videos - Material videos.
   * @returns {String} videos.type - The type of group (used for handle clicks in GUI), fixed: 'video'.
   * @returns {String} videos.title - The title of group, fixed: 'Videos'.
   * @returns {String} videos.list - The list of videos (see properties for MaterialDetail `detail.externalResources.videos`).
   */
  function getVideos(detail) {
    return {
      type: "video",
      title: "Videos",
      _list: uniqBy(get(detail, "externalResources.videos", []), "url")
    };
  }

  export default {
    name: "material-detail",
    mixins: [
      orientationMixin,
      fetchMaterialMixin,
      fetchMaterialHoldingsMixin,
      fetchWayfinderViewMixin,
      fetchMaterialSuggestedListsMixin,
      fetchMaterialReviewMixin,
      reserveMaterialMixin,
      metaInformationMixin
    ],
    props: {
      /* The id (faustnumber) of material. */
      id: String,
      /* Use the cache for material detail - useful for non dynamic materials. */
      useCache: {
        type: Boolean,
        default: true
      },
      /* Flag for use the loan button instead of reservation. */
      useLoan: Boolean,
      /* Flag of the suggested lists for material detail. Default: true */
      useSuggestedLists: {
        type: Boolean,
        default: true
      },
      /* Flag, true - mean the material is a first material in a list. */
      isFirstMaterial: Boolean,
      /* Flag, true - mean the material is a last material in a list. */
      isLastMaterial: Boolean
    },
    data() {
      return {
        detail: null,
        reservable: false,
        reservableId: null,
        holdings: [],
        suggestedLists: [],
        navigation: [],
        activeWayfinderView: null,
        activeReview: null,
        activeVideo: null,
        publisherDescription: null
      };
    },
    computed: {
      uniqueTags() {
        return uniq(this.detail.meta.tags);
      },
      /**
       * Get the converted original viewer url to proxy.
       * Proxy removes the unnecessary logos, markup and adds the standby handler.
       *
       * @returns {String} Url to material preview.
       */
      ereolenView: {
        get() {
          let ebookEnabled = this.$easyscreenConfig.get("enable.eReolen") || this.$easyscreenConfig.get("enable.ebook");
          if (!ebookEnabled) {
            return;
          }

          let url = get(this.detail, "externalResources.ebook") || "";
          if (url.includes("ereolen.dk") || url.includes("ereolengo.dk")) {
            url = `/ereolen-proxy?url=${ encodeURIComponent(url) }`;
          } else if (/\.pdf$/.test(url)) {
            url = `/pdf-viewer.html?url=${ encodeURIComponent(url) }`;
          }

          return url;
        }
      },
      /**
       * Get the material meta with filtration.
       *
       * @returns {Object} The detail meta, see structure for MaterialDetail (`detail.meta`).
       */
      visibleMeta: {
        get() {
          return this._getVisibleMeta(this.detail);
        }
      },
      /**
       * Get the sorted and filtered meta.
       *
       * @returns {Object[]} meta - The list of meta data.
       * @returns {String} meta[].property - The property key.
       * @returns {String} meta[].value - The property value.
       */
      metaPair: {
        get() {
          return this._getMetaPair(this.detail);
        }
      },
      /**
       * Get the list of uniq videos.
       *
       * @returns {Object[]} See properties for MaterialDetail (`detail.externalResources.videos`)
       */
      videos: {
        get() {
          let videoEnabled = this.$easyscreenConfig.get("enable.videosFromYT");
          if (!videoEnabled) {
            return;
          }

          return uniqBy(get(this.detail, "externalResources.videos"), "url");
        }
      },
      reservationEnabled() {
        return !this.useLoan && this.reservable && this.$easyscreenConfig.get("enable.reservation");
      },
      reservationBasketEnabled() {
        return this.reservationEnabled && this.$easyscreenConfig.get("enable.basket");
      }
    },
    methods: {
      /**
       * Reserve material by form data.
       * @async
       *
       * @param {Object} options - The reservation form data.
       * @param {String} options.cpr - The user cpr.
       * @param {String} options.pin - The user pin.
       * @param {Object} options.pickupPlacement - Pickup placement.
       * @param {Object} options.pickupPlacement.value - The id of pickup placement
       * @param {String} options.pickupPlacement.locationType - Type of pickup placement, one of: "branch", "department".
       */
      async reserveMaterial(options) {
        if (this.$refs.authenticationForm) {
          this.$refs.authenticationForm.hide();
        }

        this.$refs.loader.show();

        try {
          await this._reserveMaterial({
            cpr: options.cpr,
            pin: options.pin,
            id: this.reservableId,
            locationId: options.pickupPlacement.value,
            locationType: options.pickupPlacement.locationType,
            title: this.detail && this.detail.title
          });

          this.$refs.alert.show({
            title: l10n("Successful reservation"),
            message: l10n("Item booked.")
          });
        } catch (error) {
          this.$refs.alert.show({
            title: l10n("Reservation error"),
            message: error.message
          });
        }

        return this.$refs.loader.hide();
      },
      /* Proxy for localization function. */
      _l10n: l10n,
      /** Proxy of availabilityStatus, see more in "./availability-status.js" */
      _availabilityStatus: availabilityStatus,
      /**
       * Check if the material has the tags.
       *
       * @returns {Boolean} `true` - has tags.
       */
      _hasTags() {
        return (get(this.detail, "meta.tags") || []).length !== 0;
      },
      /**
       * Check if the material has the meta after filtration.
       *
       * @returns {Boolean} `true` - has meta.
       */
      _hasMeta() {
        return Object.keys(this.visibleMeta).length !== 0;
      },
      /**
       * Open modal by click on navigation element or list element with group.
       *
       * @param {Object} group - The group element
       * @param {String} group.type - The type of group. One of: "publisher-description", "review", "video", "ereolen".
       *                              The
       */
      _openBy(group, listElement) {
        group.shown = false;

        switch (group.type) {
          case "publisher-description":
            this.$refs.publisherDescriptionModal.show();
            break;
          case "review":
            this._openReview(listElement);
            break;
          case "video":
            this._openVideosList();
            break;
          case "ereolen":
            this._openEreolen();
            break;
          default:
            console.warn(`The group type "${ group.type }" not found. Add the handler for it.`, group);
            break;
        }
      },
      /**
       * Open the wayfinder view by url.
       * @async
       *
       * @param {String} wayfinderView - The url of wayfinder view.
       */
      async _openWayfinder(wayfinderView) {
        this.activeWayfinderView = wayfinderView;

        await this.$nextTick();
        return this.$refs.wayfinderModal.show();
      },
      /**
       * Open the reservation form.
       * @async
       */
      _openReservation() {
        if (this.$refs.authenticationForm) {
          this.$refs.authenticationForm.show();
          this.$emit("modal-opened", () => this.$refs.authenticationForm.hide());
        }
      },
      /**
       * Open the modal with list of related videos.
       * @async
       */
      _openVideosList() {
        if (this.$refs.videosModal) {
          return this.$refs.videosModal.show();
        }
      },
      /**
       * Open the single video by video data.
       * @async
       *
       * @param {Object} - The material video (see properties for MaterialDetail `detail.externalResources.videos`).
       */
      async _openVideo(video) {
        this.activeVideo = video;

        await this.$nextTick();
        return this.$refs.singleVideoModal.show();
      },
      /**
       * Open the ereolen (materail preview).
       * @async
       */
      _openEreolen() {
        if (this.$refs.ereolenModal) {
          return this.$refs.ereolenModal.show();
        }
      },
      /**
       * Open single review by review data.
       * @async
       *
       * @param {Object} review (see properties for MaterialDetail `detail.externalResources.reviews`).
       */
      async _openReview(review) {
        if (review.title) {
          this.$easyscreenStatistic.openMaterialReview({ title: review.title });
        }

        try {
          this.$refs.loader.show();
          let _review = await this._fetchMaterialReview(review);
          this.activeReview = _review;

          this.$refs.loader.hide();
          await this.$nextTick();
          return this.$refs.reviewModal.show();
        } catch (error) {
          this.$refs.loader.hide();
          console.error("Can't load review:", review, error);
          return this.$refs.alert.show({
            title: l10n("Loading error"),
            message: l10n("We can't load selected review. Please try again later.")
          });
        }
      },
      /**
       * The mouse\touch down event handler.
       * Used for close the reviews list and other navigation lists after click outside of that list.
       *
       * @param {Event} event - The native mousedown event.
       */
      _mouseDownListener(event) {
        let isListElement = isElementOf(event.target, "material-detail--navigation-list-element");
        let isToolipControl = isElementOf(event.target, "material-detail--navigation-button");
        if (isListElement || isToolipControl) {
          return;
        }

        this.navigation.forEach(group => {
          group.shown = false;
        });
      },
      /**
       * Add current material to materials cart.@
       */
      _addToCart() {
        this.$refs.confirm.show({
          title: l10n("Add to basket"),
          message: l10n("Would you like to add this item to your basket?"),
          okTitle: l10n("Yes"),
          cancelTitle: l10n("No"),
          callback: (_, type) => {
            if (type === "ok") {
              try {
                this.$store.dispatch("materialsCart/addMaterial", {
                  materialDetail: this.detail,
                  materialHoldings: this.holdings,
                  reservableId: this.reservableId
                });
              } catch (error) {
                this.$refs.alert.show({
                  title: l10n("Error."),
                  message: l10n(error.message)
                });
              }
            }
          }
        });
      },
      /**
       * Show the material cart.
       */
      async _showCart() {
        if (this.$refs.materialsCart) {
          await this.$refs.materialsCart.show();
        }
      }
    },
    async mounted() {
      window.addEventListener("mousedown", this._mouseDownListener);

      const addGroupVisibilityProperty = (group) => {
        group.shown = false;

        return group;
      };

      /* Load the material data if they are not loaded yet. */
      if (this.detail) {
        this.$emit("detail-loaded");
      }

      if (!this.detail) {
        try {
          this.detail = await this._fetchMaterial({
            id: this.id,
            useCache: this.useCache,
            withExternalResources: this.$easyscreenConfig.get("enable.externalResources") || this.$easyscreenConfig.get("enable.videosFromYT")
          });
          await this.$nextTick();
          this.$emit("detail-loaded", this.detail);

          if (this.$easyscreenConfig.get("enable.externalResources")) {
            const publisherDescription = get(this.detail, "externalResources.publisherDescription");
            if (publisherDescription) {
              this.publisherDescription = publisherDescription;
              this.navigation = this.navigation.concat([{
                type: "publisher-description",
                title: l10n("Publisher Description")
              }]);
            }

            this.navigation = this.navigation.concat(getReviewsByGroups(this.detail).map(addGroupVisibilityProperty));

          }

          const videos = get(this.detail, "externalResources.videos");
          if (this.$easyscreenConfig.get("enable.videosFromYT") && videos && videos.length !== 0) {
            this.navigation = this.navigation.concat([getVideos(this.detail)].map(addGroupVisibilityProperty));
          }

          let ebookEnabled = this.$easyscreenConfig.get("enable.eReolen") || this.$easyscreenConfig.get("enable.ebook");
          if (ebookEnabled && get(this.detail, "externalResources.ebook")) {
            this.navigation = this.navigation.concat([{
              type: "ereolen",
              title: l10n("Snippets")
            }]);
          }

          this.holdings = await this._fetchMaterialHoldings({
            id: this.id,
            material: this.detail
          });
          this.holdings = reorderMaterialHoldings(this.holdings);

          let availability = getMaterialAvailability(this.holdings);
          this.reservable = availability.reservable;
          this.reservableId = availability.reservableId;

          if (this.$easyscreenConfig.get("enable.suggestedLists") && this.suggestedLists && this.useSuggestedLists !== false) {
            this.suggestedLists = await this._fetchMaterialSuggestedLists({
              id: this.id,
              materialDetail: this.detail,
              withCoversOnly: this.$easyscreenConfig.get("recommendations.withCoversOnly"),
              recommendationsInSuggestedLists: this.$easyscreenConfig.get("enable.recommendationsInSuggestedLists")
            });
            this.$emit("suggested-lists-loaded");
          }

          this.holdings = await this._fetchWayfinderView(this.holdings, {
            language: this.$easyscreenConfig.get("wayfinder.lang"),
            url: this.$easyscreenConfig.get("wayfinder.url")
          });
        } catch (error) {
          console.error("Can't load material details or holdings.", error);
        }
      }
    },
    beforeDestroy() {
      window.removeEventListener("mousedown", this._mouseDownListener);
    },
    components: {
      "loader": Loader,
      "modal-blank": ModalBlank,
      "modal-alert": ModalAlert,
      "modal-confirm": ModalConfirm,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "easyscreen-button-group": EasyscreenButtonGroup,
      "easyscreen-carousel": EasyscreenCarousel,
      "easyscreen-badge": EasyscreenBadge,
      "easyscreen-tooltip": EasyscreenTooltip,
      "easyscreen-foldable": EasyscreenFoldable,
      "easyscreen-iframe": EasyscreenIframe,
      "easyscreen-youtube": EasyscreenYoutube,
      "scrollable": Scrollable,
      "digital-shelf-cover": DigitalShelfCover,
      "authentication-form": AuthenticationForm,
      "easyscreen-materials-cart": EasyscreenMaterialsCart,
      "easyscreen-materials-cart-button": EasyscreenMaterialsCartButton,
      "meta-information-table": MetaInformationTable
    }
  };
</script>
