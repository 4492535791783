var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'patron-profile-material-info',
    'border-bottom',
    'border-disabled',
    'border-last-0',
    'es-pb-2',
    'es-mb-2',
    _vm.orientation
  ]},[_c('h2',{staticClass:"patron-profile-material-info--title mb-3"},[_vm._v(_vm._s(_vm.title))]),(_vm.subTitle)?_c('h4',{staticClass:"patron-profile-material-info--sub-title mb-3"},[_vm._v(_vm._s(_vm.subTitle))]):_vm._e(),_c('div',{class:[
      'patron-profile-material-info--attributes',
      'easyscreen-grid',
      {
        'easyscreen-grid_flow-cols': _vm._isLandscape(),
        'easyscreen-grid_two-rows': _vm._isLandscape(),
        'easyscreen-grid_three-cols': _vm._isLandscape(),
        'easyscreen-grid_gap-2': _vm._isLandscape(),
        'easyscreen-grid_gap-1': _vm._isPortrait()
      }
    ],style:(_vm.attributesStyle)},_vm._l((_vm.attributes),function(attribute,index){return _c('div',{key:`${ attribute.label }-${ index }`,class:[
        'patron-profile-material-info--attribute',
        'easyscreen-grid',
        'easyscreen-grid_two-cols',
        'easyscreen-grid_gap-2'
      ]},[_c('div',{class:[
        'patron-profile-material-info--attribute-title text-bold',
        attribute.color || ''
      ]},[_vm._v(_vm._s(attribute.label))]),_c('div',{class:[
        'patron-profile-material-info--attribute-value',
        attribute.color || 'text-disabled'
      ]},[_vm._v(_vm._s(attribute.value))])])}),0),(_vm.checkbox)?_c('div',{staticClass:"patron-profile-material-info--checkbox"},[(!_vm.hasSlot('checkbox'))?_c('easyscreen-checkbox',{attrs:{"checked":_vm.checked},on:{"input":(_checked) => { _vm.$emit('input', _checked) }}}):_vm._e(),_vm._t("checkbox")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }