<template>
  <span :class="[
    'easyscreen-badge',
    color ? `easyscreen-badge_color-${ color }` : '',
    type ? `easyscreen-badge_${ type }` : ''
  ]">
    <slot></slot>
  </span>
</template>

<style lang="less" src="./badge.less" />
<script>
  export default {
    name: "easyscreen-badge",
    props: {
      /* The color of badge. */
      color: {
        type: String,
        default: "primary",
        validator: _color => ["primary", "secondary", "danger"].includes(_color)
      },
      /* The type of badge, rectangle or circle. */
      type: {
        type: String,
        default: "",
        validator: _type => ["", "circle"].includes(_type)
      }
    }
  };
</script>
