var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['patron-profile--user-account', _vm.orientation]},[_c('modal-layout',[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm._l10n("User account - See profile information, edit favorite library and default reservation time"))+" ")]),_c('template',{slot:"content"},[_c('form',[_c('easyscreen-form-section',{attrs:{"content-style":_vm._isLandscape() ? {
            'grid-template-columns': _vm.provider !== 'bibliofil' ? '1fr 1fr 1fr 1fr' : '1fr 1fr',
            'grid-template-rows': 'repeat(1, 77px)'
          } : {}}},[_c('easyscreen-form-group',{attrs:{"title":_vm._l10n('Full name')}},[_c('input',{staticClass:"easyscreen-input easyscreen-input_full-width",attrs:{"type":"text","disabled":"","readonly":""},domProps:{"value":_vm.data.name}})]),_c('easyscreen-form-group',{attrs:{"title":_vm._l10n('Street and number')}},[_c('input',{staticClass:"easyscreen-input easyscreen-input_full-width",attrs:{"type":"text","disabled":"","readonly":""},domProps:{"value":_vm.data.address.street}})]),_c('easyscreen-form-group',{attrs:{"title":_vm._l10n('Zip')}},[_c('input',{staticClass:"easyscreen-input easyscreen-input_full-width",attrs:{"type":"text","disabled":"","readonly":""},domProps:{"value":_vm.data.address.zip}})]),_c('easyscreen-form-group',{attrs:{"title":_vm._l10n('City')}},[_c('input',{staticClass:"easyscreen-input easyscreen-input_full-width",attrs:{"type":"text","disabled":"","readonly":""},domProps:{"value":_vm.data.address.city}})])],1),(_vm.withEmailEdit || _vm.withPhoneEdit || _vm.withServiceNotification || _vm.withPickupBranch || _vm.withMaximumWaitingTime)?_c('easyscreen-form-section',{attrs:{"content-style":_vm._isLandscape() ? {
            'grid-template-columns': '1fr 1fr 1fr 1fr',
            'grid-template-rows': 'repeat(1, 77px)'
          } : {}}},[(_vm.withEmailEdit)?_c('easyscreen-form-group',{attrs:{"title":_vm._l10n('Email')}},[_c('input',{staticClass:"easyscreen-input easyscreen-input_full-width",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.email},on:{"click":() => _vm._editForm({ type: 'text', field: 'email', label: _vm._l10n('Email') })}})]):_vm._e(),(_vm.withPhoneEdit)?_c('easyscreen-form-group',{attrs:{"title":_vm._l10n('Phone')}},[_c('input',{staticClass:"easyscreen-input easyscreen-input_full-width",attrs:{"type":"text","readonly":""},domProps:{"value":_vm.phone},on:{"click":() => _vm._editForm({ type: 'number', field: 'phone', label: _vm._l10n('Phone') })}})]):_vm._e(),(_vm.withServiceNotification)?_c('easyscreen-form-group',{attrs:{"title":_vm._l10n('Sevice notification')}},[_c('easyscreen-select',{ref:"seviceNotificationSelect",attrs:{"label":_vm._l10n('Sevice notification'),"options":[{
                label: _vm._l10n('Disabled'),
                value: ''
              }, {
                label: _vm._l10n('Email only'),
                value: 'email'
              }, {
                label: _vm._l10n('SMS'),
                value: 'SMS'
              }, {
                label: _vm._l10n('Email + SMS'),
                value: 'emailSMS'
              }],"value":_vm._getServiceNotificationValue()}})],1):_vm._e(),(_vm.withPickupBranch)?_c('easyscreen-form-group',{attrs:{"title":_vm._l10n('Pick up reservation on')}},[_c('easyscreen-select',{ref:"pickupBranchSelect",attrs:{"label":_vm._l10n('Pick up reservation on'),"options":_vm._branchesToSelect(),"value":_vm.data.preferredBranch}})],1):_vm._e(),(_vm.withMaximumWaitingTime)?_c('easyscreen-form-group',{attrs:{"title":_vm._l10n('Maximum waiting time for reservations')}},[_c('easyscreen-select',{ref:"interestPeriodSelect",attrs:{"disabled":true,"label":_vm._l10n('Maximum waiting time for reservations'),"options":[{
                label: _vm._l10n('30 days'),
                value: 30
              }, {
                label: _vm._l10n('60 days'),
                value: 60
              }, {
                label: _vm._l10n('90 days'),
                value: 90
              }, {
                label: _vm._l10n('120 days'),
                value: 120
              }, {
                label: _vm._l10n('180 days'),
                value: 180
              }, {
                label: _vm._l10n('500 days'),
                value: 500
              }, {
                label: _vm._l10n('720 days'),
                value: 720
              }],"value":_vm.data.defaultInterestPeriod}})],1):_vm._e()],1):_vm._e(),(_vm.withReservationsOnHold)?_c('easyscreen-form-section',{attrs:{"title":_vm._isPortrait() ? _vm._l10n('Reservations on hold') : '',"content-style":_vm._isLandscape() ? {
            'grid-template-columns': '1fr 1fr 1fr',
            'grid-template-rows': 'repeat(1, 77px)'
          } : {}}},[_c('easyscreen-form-group',{attrs:{"title":_vm._isLandscape()
                ? _vm._l10n('Reservations on hold') + ' - ' + _vm._l10n('From').toLowerCase()
                : _vm._l10n('From')}},[_c('div',{staticClass:"easyscreen-grid easyscreen-grid_three-cols"},[_c('easyscreen-select',{ref:"onHoldFromDay",attrs:{"label":_vm._l10n('Reservations on hold') + ' - ' + _vm._l10n('From').toLowerCase(),"options":_vm._getDays(),"value":_vm._convertDate(_vm._get(_vm.data, 'onHold.from'), 'DD')}}),_c('easyscreen-select',{ref:"onHoldFromMonth",attrs:{"label":_vm._l10n('Reservations on hold') + ' - ' + _vm._l10n('From').toLowerCase(),"options":_vm._getMonths(),"value":_vm._convertDate(_vm._get(_vm.data, 'onHold.from'), 'MM')}}),_c('easyscreen-select',{ref:"onHoldFromYear",attrs:{"label":_vm._l10n('Reservations on hold') + ' - ' + _vm._l10n('From').toLowerCase(),"options":_vm._getYears(),"value":_vm._convertDate(_vm._get(_vm.data, 'onHold.from'), 'YYYY')}})],1)]),_c('easyscreen-form-group',{attrs:{"title":_vm._isLandscape()
                ? _vm._l10n('Reservations on hold') + ' - ' + _vm._l10n('To').toLowerCase()
                : _vm._l10n('To')}},[_c('div',{staticClass:"easyscreen-grid easyscreen-grid_three-cols"},[_c('easyscreen-select',{ref:"onHoldToDay",attrs:{"label":_vm._l10n('Reservations on hold') + ' - ' + _vm._l10n('to').toLowerCase(),"options":_vm._getDays(),"value":_vm._convertDate(_vm._get(_vm.data, 'onHold.to'), 'DD')}}),_c('easyscreen-select',{ref:"onHoldToMonth",attrs:{"label":_vm._l10n('Reservations on hold') + ' - ' + _vm._l10n('to').toLowerCase(),"options":_vm._getMonths(),"value":_vm._convertDate(_vm._get(_vm.data, 'onHold.to'), 'MM')}}),_c('easyscreen-select',{ref:"onHoldToYear",attrs:{"label":_vm._l10n('Reservations on hold') + ' - ' + _vm._l10n('to').toLowerCase(),"options":_vm._getYears(),"value":_vm._convertDate(_vm._get(_vm.data, 'onHold.to'), 'YYYY')}})],1)])],1):_vm._e(),(_vm.withPinEdit)?_c('easyscreen-form-section',{attrs:{"content-style":_vm._isLandscape() ? {
            'grid-template-columns': '1fr 1fr 1fr',
            'grid-template-rows': 'repeat(1, 77px)'
          } : {}}},[_c('easyscreen-form-group',{attrs:{"title":_vm._l10n('Existing pin')}},[_c('easyscreen-password-input',{attrs:{"readonly":true,"value":_vm.existingPin},on:{"click":() => _vm._editForm({ type: 'pin', field: 'existingPin', label: _vm._l10n('Existing pin') })}})],1),_c('easyscreen-form-group',{attrs:{"title":_vm._l10n('New pin')}},[_c('easyscreen-password-input',{attrs:{"readonly":true,"value":_vm.newPin},on:{"click":() => _vm._editForm({ type: 'pin', field: 'newPin', label: _vm._l10n('New pin') })}})],1),_c('easyscreen-form-group',{attrs:{"title":_vm._l10n('Repeat new pin')}},[_c('easyscreen-password-input',{attrs:{"readonly":true,"value":_vm.newPinRepeat},on:{"click":() => _vm._editForm({ type: 'pin', field: 'newPinRepeat', label: _vm._l10n('Repeat new pin') })}})],1)],1):_vm._e()],1)]),_c('template',{slot:"footer"},[(_vm.hasEditableFields)?_c('easyscreen-button',{attrs:{"color":"primary","modificator":"fixed-width"},nativeOn:{"click":function($event){return _vm._save.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm._l10n("Save"))+" ")]):_vm._e()],1)],2),_c('modal-edit-pin',{ref:"modalPin"}),_c('modal-edit-number',{ref:"modalNumber"}),_c('modal-edit-text',{ref:"modalText"}),_c('modal-alert',{ref:"alert"}),_c('loader',{ref:"loader"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }