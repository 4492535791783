<template>
  <div
    :class="[
      'material-detail-light',
      `material-detail-light_${ _availabilityStatus(Object.assign({ holdings: holdings }, detail)) }`,
      { 'material-detail-light_medium-screen': isMediumScreen },
      orientation
    ]"
    :style="{
      '--material-detail-light-special-color': specialColor,
      '--material-detail-light-special-color-with-overlay': specialColorWithOverlay
    }"
  >
    <loader v-if="!detail" type="in-place" design="light" />
    <div class="material-detail-light--wrapper" v-else>
      <loader ref="loader" design="light" />

      <div class="material-detail-light--primary-content">
        <!-- Start: Acessability featured, navigation buttons for light design. -->
        <easyscreen-circle-button
          v-if="isFirstMaterial !== true && $easyscreenConfig.get('enable.accessibility')"
          class="material-detail-light--material-navigation-button material-detail-light--material-navigation-button_left"
          :size="isMediumScreen ? 'small' : 'medium'"
          icon="fal fa-chevron-left"
          color="outline-white"
          @click.native="$emit('previous-material')"
        />

        <easyscreen-circle-button
          v-if="isLastMaterial !== true && $easyscreenConfig.get('enable.accessibility')"
          class="material-detail-light--material-navigation-button material-detail-light--material-navigation-button_right"
          :size="isMediumScreen ? 'small' : 'medium'"
          icon="fal fa-chevron-right"
          color="outline-white"
          @click.native="$emit('next-material')"
        />
        <!-- END: Acessability featured, navigation buttons for light design. -->

        <div class="material-detail-light--primary-content-overlay"></div>
        <div class="material-detail-light--type-wrapper">
          <div class="material-detail-light--type">
            <i class="fal fa-arrow-down material-detail-light--type-icon"></i>
            {{ detail.type || "unknow" }}
          </div>
        </div>
        <div class="material-detail-light--sections-group">
          <!-- image (cover) -->
          <div class="material-detail-light--cover-section">
            <div class="material-detail-light--cover-wrapper">
              <img
                ref="image"
                class="material-detail-light--cover"
                draggable="false"
                crossOrigin="annonimus"
                :src="$easyscreenRequest.lmsConnector.wrapCover(detail.cover)"
              >
            </div>
          </div>
          <!-- description -->
          <div class="material-detail-light--text-section">
            <div class="material-detail-light--text-section-overlay" :style="{ height: textOverlayHeight }"></div>
            <h2 ref="title" class="material-detail-light--title" :style="{ fontSize: `${ titleFontSize }` }">
              {{ detail.title }}
              <div>
                <span v-if="detail.author">{{ $l10n("Of") }} </span>
                <span
                  v-if="detail.author"
                  class="material-detail-light--author"
                  @click="() => $emit('select-tag', detail.author)"
                >{{ detail.author }}</span>
                <span v-if="detail.year" class="material-detail-light--year"> ({{ detail.year }})</span>
              </div>
            </h2>
            <div ref="description" class="material-detail-light--content-group">
              <div class="material-detail-light--content">
                <easyscreen-foldable
                  class="material-detail-light--description"
                  tooltip-class="material-detail-light--content"
                  design="light"
                  :open="false"
                  :toggle-button="false"
                  :max-folded-height="60"
                  :max-unfloded-height="200"
                  :background-color="specialColorWithOverlay"
                >
                  {{ detail.description }}
                </easyscreen-foldable>
              </div>
            </div>
            <!-- Subjects -->
            <div v-if="_hasTags()" class="material-detail-light--content-group">
              <div class="material-detail-light--content-title">{{ $l10n("Subject") }}:</div>
              <div class="material-detail-light--content">
                <easyscreen-foldable
                  toggle-button-class="material-detail-light--toggle-content"
                  tooltip-class="material-detail-light--content"
                  design="light"
                  :open="false"
                  :toggle-button="false"
                  :max-folded-height="28"
                  :max-unfloded-height="200"
                  :background-color="specialColorWithSecondOverlay"
                >
                  <span
                    v-for="subject in uniqueTags"
                    :key="subject"
                    class="material-detail-light--subject"
                    @click="() => $emit('select-tag', subject)"
                  >{{ subject }}</span>
                </easyscreen-foldable>
              </div>
            </div>
            <!-- Meta -->
            <div v-if="_hasMeta()" class="material-detail-light--content-group">
              <div class="material-detail-light--content-title">{{ $l10n("Material details") }}:</div>
              <div class="material-detail-light--content">
                <easyscreen-foldable
                  toggle-button-class="material-detail-light--toggle-content"
                  tooltip-class="material-detail-light--content"
                  design="light"
                  :open="false"
                  :toggle-button="false"
                  :max-folded-height="100"
                  :max-unfloded-height="500"
                  :background-color="specialColorWithSecondOverlay"
                >
                  <table class="material-detail-light--meta">
                    <tbody>
                      <tr
                        v-for="pair in metaPair"
                        :key="`${ pair.property }-${ pair.value }`"
                      >
                        <td>{{ $l10n(pair.property) }}:</td>
                        <td>{{ pair.value }}</td>
                      </tr>
                    </tbody>
                  </table>
                </easyscreen-foldable>
              </div>
            </div>
            <!-- Holdings -->
            <div class="material-detail-light--content-group material-detail-light--content-group_holdings">
              <div
                v-if="holdings && holdings.length > 0"
                class="material-detail-light--content-title"
              >
                {{ $l10n("Find the book here") }}
              </div>
              <div class="material-detail-light--content">
                <easyscreen-foldable
                  v-if="holdings && holdings.length > 0"
                  ref="holdingsFoldable"
                  toggle-button-class="material-detail-light--toggle-content"
                  tooltip-class="material-detail-light--content"
                  design="light"
                  :open="false"
                  :toggle-button="false"
                  :max-folded-height="220"
                  :max-unfloded-height="300"
                  :background-color="specialColorWithSecondOverlay"
                >
                  <table class="material-detail-light--holdings">
                    <thead>
                      <tr>
                        <td>{{ $l10n("Placement") }}:</td>
                        <td>{{ $l10n("Number") }}:</td>
                        <td>{{ $l10n("On the shelf") }}:</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="holding in holdingsSlice"
                        :key="holding.placement"
                        @click="() => {
                          if (holding.wayfinderView && holding.available > 0) {
                            $refs.holdingsFoldable.hide();
                            _openWayfinder(holding.wayfinderView);
                          }
                        }"
                      >
                        <td>
                          <i
                            v-if="holding.wayfinderView && holding.available > 0"
                            class="fa fa-map-marker material-detail-light--wayfinder-marker"
                          ></i>
                          <span>
                            {{ (holding.placement || "").replace(/\s*>\s*>/g, "") }}
                          </span>
                        </td>
                        <td>{{ holding.total }}</td>
                        <td>{{ holding.available }}</td>
                      </tr>
                    </tbody>
                  </table>
                </easyscreen-foldable>
              </div>
            </div>
          </div>
        </div>
        <div :class="[
          'material-detail-light--controls',
          {
            'material-detail-light--controls_only-info': (useLoan || !reservationEnabled) && !hasReviews && !hasVideos && !hasEreolen,
            'material-detail-light--controls_only-reserve-and-info': reservationEnabled && !hasReviews && !hasVideos && !hasEreolen
          }
        ]">
          <div class="material-detail-light--primary-controls">
            <easyscreen-button
              v-if="reservationEnabled"
              class="material-detail-light--material-button material-detail-light--material-button_reserve"
              color="white"
              modificator="only-text"
              @click.native="_openReservation"
              :size="isMediumScreen ? 'small' : 'medium'"
            >
              <easyscreen-circle-button
                class="material-detail-light--material-button-icon"
                icon="fal fa-arrow-right"
                :size="isMediumScreen ? 'small' : 'medium'"
                color="white"
              />
              {{ $l10n("Reserve") }}
            </easyscreen-button>
          </div>

          <div class="material-detail-light--secondary-controls">
            <easyscreen-button
              :class="[
                'material-detail-light--material-button',
                'material-detail-light--material-button_more-information'
              ]"
              color="white"
              modificator="only-text"
              @click.native="() => $refs.materialDetailsModal.show()"
              :size="isMediumScreen ? 'small' : 'medium'"
            >
              <easyscreen-circle-button
                class="material-detail-light--material-button-icon"
                icon="fal fa-arrow-right"
                :size="isMediumScreen ? 'small' : 'medium'"
                color="white"
              />
              {{ $l10n("More information") }}
            </easyscreen-button>

            <easyscreen-button
              v-if="hasReviews"
              class="material-detail-light--material-button"
              color="white"
              modificator="only-text"
              @click.native="() => _selectSecondaryContent('review')"
              :size="isMediumScreen ? 'small' : 'medium'"
            >
              <easyscreen-circle-button
                class="material-detail-light--material-button-icon"
                icon="fal fa-arrow-right"
                :size="isMediumScreen ? 'small' : 'medium'"
                color="white"
              />
              {{ $l10n("Reviews") }}
            </easyscreen-button>

            <easyscreen-button
              v-if="hasVideos"
              class="material-detail-light--material-button"
              color="white"
              modificator="only-text"
              @click.native="() => _selectSecondaryContent('video')"
              :size="isMediumScreen ? 'small' : 'medium'"
            >
              <easyscreen-circle-button
                class="material-detail-light--material-button-icon"
                icon="fal fa-arrow-right"
                :size="isMediumScreen ? 'small' : 'medium'"
                color="white"
              />
              {{ $l10n("Videos") }}
            </easyscreen-button>

            <easyscreen-button
              v-if="hasEreolen"
              class="material-detail-light--material-button"
              color="white"
              modificator="only-text"
              @click.native="() => _selectSecondaryContent('ereolen')"
              :size="isMediumScreen ? 'small' : 'medium'"
            >
              <easyscreen-circle-button
                class="material-detail-light--material-button-icon"
                icon="fal fa-arrow-right"
                :size="isMediumScreen ? 'small' : 'medium'"
                color="white"
              />
              {{ $l10n("Snippets") }}
            </easyscreen-button>
          </div>
        </div>
      </div>
      <div class="material-detail-light--secondary-content">
        <div class="material-detail-light--secondary-content-navigation">
          <div
            v-for="navigationElement in filteredNavigation"
            :key="navigationElement.title"
            :class="[
              'material-detail-light--navigation-button',
              { 'material-detail-light--navigation-button_active': navigationElement.type === activeSecondaryContentType }
            ]"
            @click="_selectSecondaryContent(navigationElement.type)"
          >
            <i class="fal fa-arrow-down material-detail-light--navigation-button-icon"></i>
            {{ $l10n(navigationElement.title) }}
          </div>
        </div>
        <!-- suggested materials -->
        <div v-if="activeSecondaryContentType.includes('-materials')" class="material-detail-light--active-secondary-content">
          <div :class="[
            'material-detail-light--suggestions',
            { 'material-detail-light--suggestions_with-navigation-buttons': $easyscreenConfig.get('enable.accessibility') }
          ]">
            <div v-if="$easyscreenConfig.get('enable.accessibility')">
              <easyscreen-circle-button
                :class="[
                  'material-detail-light--suggestions-navigation',
                  'material-detail-light--suggestions-navigation_left',
                  { 'material-detail-light--suggestions-navigation_visible': leftSuggestionsNavigationButtonVisible }
                ]"
                :size="isMediumScreen ? 'small' : 'medium'"
                icon="fal fa-chevron-left"
                color="outline-custom"
                :custom-color="isSpecialColorLight ? specialColorWithOverlay : specialColor"
                :custom-active-color="isSpecialColorLight ? specialColor : specialColorWithOverlay"
                @click.native="$refs.carousel.previousSlide()"
              />
            </div>
            <easyscreen-carousel
              ref="carousel"
              class="material-detail-light--suggested-list-carousel"
              :key="activeSecondaryContentType"
              :layout-columns="suggestionsCarouselColumns"
              :layout-rows="suggestionsCarouselRows"
              @before-position-change="(_, followingSlideIndex) => {
                suggestionsSlideIndex = followingSlideIndex;
              }"
            >
              <digital-shelf-cover
                class="material-detail-light--suggestions-cover"
                v-for="material in suggestionMaterials"
                :key="material.id"
                :title="material.title"
                :author="material.author"
                :cover="material.cover"
                :with-cover-background="false"
                align="bottom"
                horizontal-align="center"
                width="80%"
                @click.native="() => {
                  $emit(
                    'suggested-material',
                    suggestionMaterials.map(material => material.id),
                    material.id
                  )
                }"
              />
            </easyscreen-carousel>
            <div v-if="$easyscreenConfig.get('enable.accessibility')">
              <easyscreen-circle-button
                :class="[
                  'material-detail-light--suggestions-navigation',
                  'material-detail-light--suggestions-navigation_right',
                  { 'material-detail-light--suggestions-navigation_visible': rightSuggestionsNavigationButtonVisible }
                ]"
                :size="isMediumScreen ? 'small' : 'medium'"
                icon="fal fa-chevron-right"
                color="outline-custom"
                :custom-color="isSpecialColorLight ? specialColorWithOverlay : specialColor"
                :custom-active-color="isSpecialColorLight ? specialColor : specialColorWithOverlay"
                @click.native="$refs.carousel.nextSlide()"
              />
            </div>
          </div>
        </div>

        <div v-if="activeSecondaryContentType === 'video'">
          <scrollable
            class="material-detail-light--video"
            smooth-edge-color="#ffffff"
            :max-height="_isPortrait() ? 730 : 870"
          >
            <div
              v-for="video in videos"
              :key="video.url"

              class="material-detail--video material-detail-light--video"
              @click="() => _openVideo(video)"
            >
              <img
                class="material-detail--video-thumbnail"
                draggable="false"
                :src="video.thumbnail"
              >
              <h2 class="material-detail--video-title">
                {{ video.title }}
                <span v-if="video.date" class="material-detail--video-date">({{ video.date }})</span>
              </h2>
              <div class="material-detail--video-description">
                {{ video.description }}
              </div>
            </div>
          </scrollable>
        </div>
        <div v-if="activeSecondaryContentType === 'review'">
          <scrollable
            class="material-detail-light--reviews"
            smooth-edge-color="#ffffff"
            :max-height="730"
          >
            <ul class="material-detail-light--reviews-list">
              <li
                v-for="review in activeSecondaryContent.list"
                :key="review.id || review.label"
                class="material-detail-light--reviews-list-element"
                @click="() => _openReview(review)"
              >{{ review.label }}</li>
            </ul>
          </scrollable>
        </div>
      </div>

      <modal-blank
        ref="materialDetailsModal"

        @before-open="() => $emit('before-open-inner-modal', $refs.materialDetailsModal.hide, 'materialDetails')"
        @closed="() => $emit('closed-inner-modal', 'materialDetails')"
        @opened="_adjustFullDetailTitle"

        class="material-detail-light--modal prevent-carousel-actions"
        min-width="100%"
        min-height="100%"
      >
        <template slot="raw">
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_top-right"
            icon="fal fa-times"
            color="white"
            :size="isMediumScreen ? 'small' : 'medium'"

            @click.native="() => $refs.materialDetailsModal.hide()"
          />
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_bottom-right"
            icon="fal fa-times"
            color="white"
            :size="isMediumScreen ? 'small' : 'medium'"

            @click.native="() => $refs.materialDetailsModal.hide()"
          />

          <div
            :class="['material-detail-light--full-details', orientation]"
            :style="{
              '--primary-background-color': isSpecialColorLight ? specialColorWithOverlay : specialColor,
              '--secondary-background-color': isSpecialColorLight ? specialColorWithSecondOverlay : specialColorWithOverlay
            }"
          >
            <!-- Primary info:Start -->
            <div class="material-detail-light--full-details-primary-info">
              <div class="material-detail-light--full-details-title" ref="fullDetailTitle">
                <h2 class="material-detail-light--title" :style="{ fontSize: `${ fullDetailTitleFontSize }px` }">
                  {{ detail.title }}
                </h2>
                <h3 class="material-detail-light--full-details-subtitle" :style="{ fontSize: `${ fullDetailAuthorFontSize }px` }">
                  <span
                    v-if="detail.author"
                    class="material-detail-light--full-details-author"
                    @click="() => $emit('select-tag', detail.author)"
                  >{{ detail.author }}</span>
                  <span v-if="detail.year"> ({{ detail.year }})</span>
                </h3>
              </div>
              <scrollable
                class="material-detail-light--full-details-description"
                :max-height="_isLandscape() ? 75 : 270"
                :smooth-edge-color="isSpecialColorLight ? specialColorWithOverlay : specialColor"
              >
                {{ detail.description }}
              </scrollable>
            </div>
            <!-- Primary info:End -->
            <div :class="[
              'material-detail-light--full-details-secondary-info',
              {
                'material-detail-light--full-details-secondary-info_with-subjects': _hasTags(),
                'material-detail-light--full-details-secondary-info_with-holdings': holdings && holdings.length > 0
              }
            ]">
              <!-- Subjects:Start -->
              <div class="material-detail-light--content-group material-detail-light--full-details-subject">
                <div v-if="_hasTags()" class="material-detail-light--content-title">{{ $l10n("Subject") }}:</div>
                <div v-if="_hasTags()" class="material-detail-light--content">
                  <scrollable
                    :max-height="_isLandscape() ? 60 : 180"
                    :smooth-edge-color="isSpecialColorLight ? specialColorWithSecondOverlay : specialColorWithOverlay"
                  >
                    <span
                      v-for="subject in uniqueTags"
                      :key="subject"
                      class="material-detail-light--subject"
                      @click="() => $emit('select-tag', subject)"
                    >{{ subject }}</span>
                  </scrollable>
                </div>
              </div>
              <!-- Subjects:End -->

              <!-- Holdings:Start -->
              <div class="material-detail-light--content-group material-detail-light--full-details-holdings">
                <div v-if="holdings && holdings.length > 0" class="material-detail-light--content">
                  <scrollable
                    :smooth-edge-color="isSpecialColorLight ? specialColorWithSecondOverlay : specialColorWithOverlay"
                    :max-height="_isLandscape() ? 135 : 430"
                  >
                    <table class="material-detail-light--holdings">
                      <thead>
                        <tr>
                          <td>{{ $l10n("Find the book here") }}:</td>
                          <td>{{ $l10n("Number") }}:</td>
                          <td>{{ $l10n("On the shelf") }}:</td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="holding in holdings"
                          :key="holding.placement"
                          @click="() => {
                            if (holding.wayfinderView && holding.available > 0) {
                              $refs.holdingsFoldable.hide();
                              _openWayfinder(holding.wayfinderView);
                            }
                          }"
                        >
                          <td>
                            <i
                              v-if="holding.wayfinderView && holding.available > 0"
                              class="fa fa-map-marker material-detail-light--wayfinder-marker"
                            ></i>
                            <span>
                              {{ (holding.placement || "").replace(/\s*>\s*>/g, "") }}
                            </span>
                          </td>
                          <td>{{ holding.total }}</td>
                          <td>{{ holding.available }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </scrollable>
                </div>
              </div>
              <!-- Holdings:End -->

              <!-- Meta:Start -->
              <div v-if="_hasMeta()" class="material-detail-light--content-group material-detail-light--full-details-meta">
                <div class="material-detail-light--content-title">{{ $l10n("Material details") }}:</div>
                <div class="material-detail-light--content">
                  <scrollable
                    :smooth-edge-color="isSpecialColorLight ? specialColorWithOverlay : specialColor"
                    :max-height="_isLandscape() ? 220 : 270"
                  >
                    <div class="material-detail-light--full-details-meta-wrapper">
                      <table class="material-detail-light--meta">
                        <tbody>
                          <tr
                            v-for="pair in metaPair"
                            :key="`${ pair.property }-${ pair.value }`"
                          >
                            <td>{{ $l10n(pair.property) }}:</td>
                            <td>{{ pair.value }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </scrollable>
                </div>
              </div>
              <!-- Meta:End -->
            </div>
            <div class="material-detail-light--full-details-image">
              <div
                class="material-detail-light--full-details-cover"
                :style="{ backgroundImage: `url(${ $easyscreenRequest.lmsConnector.wrapCover(detail.cover) })` }"
              ></div>
            </div>
          </div>

        </template>
      </modal-blank>

      <modal-blank
        v-if="activeReview"
        ref="reviewModal"

        @before-open="() => $emit('before-open-inner-modal', $refs.reviewModal.hide, 'materialViewReview')"
        @closed="() => {
          $emit('closed-inner-modal', 'materialViewReview');
          activeReview = null;
        }"

        class="material-detail-light--modal material-detail-light--review material-detail--review prevent-carousel-actions"
        min-width="100%"
        min-height="100%"
      >
        <template slot="raw">
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_top-right"
            icon="fal fa-times"
            color="white"
            :size="isMediumScreen ? 'small' : 'medium'"

            @click.native="() => $refs.reviewModal.hide()"
          />
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_bottom-right"
            icon="fal fa-times"
            color="white"
            :size="isMediumScreen ? 'small' : 'medium'"

            @click.native="() => $refs.reviewModal.hide()"
          />

          <scrollable
            class="material-detail-light--review-view"
            :max-height="reviewHeight"
            :visible-scroll-bar="true"
          >
            <div class="material-detail--review-meta">
              <h3 class="material-detail--review-title material-detail-light--review-title">
                {{ activeReview.title }}
              </h3>
              <div class="material-detail--review-logo">
                <img
                  v-if="activeReview.isInfomedia"
                  src="/images/icon/infomedia-logo.png"
                  draggable="false"
                />
              </div>
              <div class="material-detail--review-author material-detail-light--review-author">
                {{ activeReview.author }}
              </div>
              <div class="material-detail--review-source material-detail-light--review-source">
                {{ activeReview.source }}
              </div>
              <div class="material-detail--review-date material-detail-light--review-date">
                {{ activeReview.date }}
              </div>
            </div>
            <h2 class="material-detail--review-headline material-detail-light--review-headline">
              {{ activeReview.headline }}
            </h2>
            <div class="material-detail--review-content material-detail-light--review-content" v-html="activeReview.description"></div>
          </scrollable>
        </template>
      </modal-blank>

      <modal-blank
        v-if="activeVideo"
        ref="videoModal"

        class="material-detail-light--modal material-detail--single-video prevent-carousel-actions"
        min-width="100%"
        min-height="100%"
        @before-open="() => $emit('before-open-inner-modal', $refs.videoModal.hide, 'materialViewVideo')"
        @closed="() => $emit('closed-inner-modal', 'materialViewVideo')"
      >
        <template slot="raw">
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_top-right"
            icon="fal fa-times"
            color="white"
            :size="isMediumScreen ? 'small' : 'medium'"

            @click.native="() => $refs.videoModal.hide()"
          />
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_bottom-right"
            icon="fal fa-times"
            color="white"
            :size="isMediumScreen ? 'small' : 'medium'"

            @click.native="() => $refs.videoModal.hide()"
          />

          <div class="material-detail--single-video-view material-detail-light--single-video-view">
            <easyscreen-youtube
              :url="activeVideo.url"
              :muted="false"
            />
          </div>
        </template>
      </modal-blank>

      <modal-blank
        v-if="ereolenView"
        ref="ereolenModal"

        @before-open="() => $emit('before-open-inner-modal', $refs.ereolenModal.hide, 'materialViewEreolen')"
        @closed="() => $emit('closed-inner-modal', 'materialViewEreolen')"

        class="material-detail-light--modal material-detail--ereolen material-detail-light--ereolen prevent-carousel-actions"
        min-width="100%"
        min-height="100%"
      >
        <template slot="raw">
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_top-right"
            icon="fal fa-times"
            color="white"
            :size="isMediumScreen ? 'small' : 'medium'"

            @click.native="() => $refs.ereolenModal.hide()"
          />
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_bottom-right"
            icon="fal fa-times"
            color="white"
            :size="isMediumScreen ? 'small' : 'medium'"

            @click.native="() => $refs.ereolenModal.hide()"
          />

          <loader class="material-detail-light--ereolen-loader" type="in-place" design="light" />
          <easyscreen-iframe
            class="material-detail-light--ereolen-view"
            :url="ereolenView"
            :overlay="false"
          />
        </template>
      </modal-blank>

      <authentication-form
        v-if="reservationEnabled"
        ref="authenticationForm"
        type="auto-reservation"
        class="material-reservation"
        design="light"
        :title="$l10n('Reserve item')"
        :ok-title="$l10n('Reserve')"
        :message="$l10n('Please login with library account to make the reservation')"
        @authenticated="(_, formData) => {
          reserveMaterial(formData);
        }"
        @before-open="() => $emit('before-open-inner-modal', $refs.authenticationForm.hide, 'materialViewAuthenticationForm')"
        @closed="() => $emit('closed-inner-modal', 'materialViewAuthenticationForm')"
      />

      <modal-blank
        v-if="activeWayfinderView"
        ref="wayfinderModal"

        @before-open="() => {
          $emit('before-open-inner-modal', $refs.wayfinderModal.hide, 'materialViewWayfinder')
        }"
        @closed="() => {
          $emit('closed-inner-modal', 'materialViewWayfinder');
          activeWayfinderView = null;
        }"

        class="material-detail--wayfinder prevent-carousel-actions"
        design="light"
        min-width="100%"
        min-height="100%"
      >
        <template slot="raw">
          <easyscreen-circle-button
            class="material-detail--close-button material-detail--close-button_bottom-right"
            icon="fal fa-times"
            color="white"
            :size="isMediumScreen ? 'small' : 'medium'"

            @click.native="() => $refs.wayfinderModal.hide()"
          />

          <easyscreen-iframe
            class="material-detail--wayfinder-view"
            :url="activeWayfinderView"
            :overlay="false"
          />
        </template>
      </modal-blank>
    </div>

    <modal-alert
      ref="alert"
      design="light"
      @before-open="() => $emit('before-open-inner-modal', $refs.alert.hide, 'materialViewModalAlert')"
      @closed="() => $emit('closed-inner-modal', 'materialViewModalAlert')"
    />

    <modal-confirm
      ref="confirm"
      design="light"
      @before-open="() => $emit('before-open-inner-modal', $refs.confirm.hide, 'materialViewModalConfirm')"
      @closed="() => $emit('closed-inner-modal', 'modalConfirm')"
    />
  </div>
</template>

<style src="./material-detail-light.less" lang="less" />

<script>
  import Loader from "../core/loader/loader.vue";
  import ModalBlank from "../core/modal/blank.vue";
  import ModalAlert from "../core/modal/alert.vue";
  import ModalConfirm from "../core/modal/confirm.vue";
  import EasyscreenButton from "../core/button/button.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import EasyscreenCarousel from "../core/carousel/carousel.vue";
  import EasyscreenFoldable from "../core/foldable/foldable.vue";
  import EasyscreenIframe from "../core/iframe/iframe.vue";
  import EasyscreenYoutube from "../core/youtube/youtube.vue";
  import Scrollable from "../core/scrollable/scrollable.vue";
  import DigitalShelfCover from "../digital-shelf/cover.vue";
  import AuthenticationForm from "../patron/authentication/authentication.vue";
  import MaterialDetail from "./material-detail.vue";

  import fitByFontSizeMixin from "../core/mixins/fit-by-font-size.js";
  import { sortHoldigns } from "../material-promotion/material-promotion-meta.mixin.js";

  import ColorMixer from "color-mixer";
  import { normal as normalColor } from "color-blend";
  import resizeMixin from "../core/mixins/resize.js";
  import getDominantImageColor from "@/lib/utils/get-dominant-image-color.js";
  import getStyle from "@/lib/utils/get-style.js";

  const overlayBackgroundColor = { r: 0, g: 0, b: 0, a: 0.25 };
  export default {
    name: "material-detail-light",
    mixins: [MaterialDetail, fitByFontSizeMixin, resizeMixin],
    computed: {
      hasReviews() {
        return this.navigation.some(navigationElement => navigationElement.type === "review");
      },
      hasVideos() {
        return this.navigation.some(navigationElement => navigationElement.type === "video");
      },
      hasEreolen() {
        return this.navigation.some(navigationElement => navigationElement.type === "ereolen");
      },
      filteredNavigation() {
        return this.navigation.filter(navigationElement => {
          return !["review", "video", "ereolen"].includes(navigationElement.type);
        });
      },
      activeSecondaryContent() {
        return this.navigation.find(navigationElement => navigationElement.type === this.activeSecondaryContentType);
      },
      holdingsSlice() {
        return sortHoldigns(this.holdings).slice(0, 1);
      },
      isSpecialColorLight() {
        const hslColor = new ColorMixer.Color({ hex: this.specialColor }).hsl();
        if (hslColor[2] > 60) {
          return true;
        }

        return false;
      },
      suggestionsCarouselColumns() {
        if (this.isMediumScreen)
          return 3;

        return 4;
      },
      suggestionsCarouselRows() {
        return 2;
      },
      suggestionsCarouselSlidesAmount() {
        const elementsPerSlide = this.suggestionsCarouselColumns * this.suggestionsCarouselRows;
        return Math.ceil(this.suggestionMaterials.length / elementsPerSlide);
      },
      leftSuggestionsNavigationButtonVisible() {
        if (this.suggestionsCarouselSlidesAmount < 2)
          return false;

        return this.suggestionsSlideIndex !== 0;
      },
      rightSuggestionsNavigationButtonVisible() {
        if (this.suggestionsCarouselSlidesAmount < 2)
          return false;

        return this.suggestionsSlideIndex !== this.suggestionsCarouselSlidesAmount - 1;
      },
      reviewHeight() {
        if (this._isLandscape()) {
          if (this.isMediumScreen)
            return 800;

          return 900;
        }

        return 1700;
      }
    },
    data() {
      return {
        specialColor: "#aaaaaa",
        specialColorWithOverlay: null,
        specialColorWithSecondOverlay: null,
        navigation: [],
        titleFontSize: this.isMediumScreen ? 26 : 40,
        fullDetailTitleFontSize: 36,
        fullDetailAuthorFontSize: 28,
        activeSecondaryContentType: "",
        textOverlayHeight: "",
        activeReview: null,
        activeVideo: null,
        suggestionMaterials: [],
        suggestionsSlideIndex: 0
      };
    },
    watch: {
      navigation() {
        if (this.activeSecondaryContentType == "") {
          this.activeSecondaryContentType = (this.navigation[0] || {}).type || "";
          this.suggestionMaterials = this._getListMaterialsByListId(this.activeSecondaryContentType);
          this.suggestionsSlideIndex = 0;
        }
      }
    },
    methods: {
      async _selectSecondaryContent(type) {
        if (type === "ereolen") {
          if (this.$refs.ereolenModal) {
            this.$refs.ereolenModal.show();
            return;
          }

          return;
        }

        this.activeSecondaryContentType = type || "";
        this.suggestionMaterials = this._getListMaterialsByListId(this.activeSecondaryContentType);
        this.suggestionsSlideIndex = 0;
      },
      _updateSpecialColorsWithOverlay() {
        let specialColor = new ColorMixer.Color({ hex: this.specialColor }).rgb();
        specialColor = { r: specialColor[0], g: specialColor[1], b: specialColor[2], a: 1 };

        const specialColorWithOverlay = normalColor(specialColor, overlayBackgroundColor);
        this.specialColorWithOverlay = new ColorMixer.Color({
          rgb: [
            specialColorWithOverlay.r,
            specialColorWithOverlay.g,
            specialColorWithOverlay.b
          ]
        }).hex();

        const specialColorWithSecondOverlay = normalColor(specialColorWithOverlay, overlayBackgroundColor);
        this.specialColorWithSecondOverlay = new ColorMixer.Color({
          rgb: [
            specialColorWithSecondOverlay.r,
            specialColorWithSecondOverlay.g,
            specialColorWithSecondOverlay.b
          ]
        }).hex();
      },
      _adjustFullDetailTitle() {
        return this._fitByFontSize({
          maxHeight: 126,
          target: [{
            default: 36,
            property: "fullDetailTitleFontSize"
          }, {
            default: 28,
            property: "fullDetailAuthorFontSize"
          }],
          ref: "fullDetailTitle"
        });
      },
      async _resizeTextSectionOverlay() {
        /* Adjust title height   */
        await this._fitByFontSize({ maxHeight: 126, target: {
          default: this.isMediumScreen ? 26 : 40,
          property: "titleFontSize"
        }, ref: "title" });

        let offset = [];
        [this.$refs.title, this.$refs.description].forEach(htmlElement => {
          if (!htmlElement) {
            return;
          }

          offset.push(getStyle(htmlElement, "height"));
          offset.push(getStyle(htmlElement, "margin-top"));
          offset.push(getStyle(htmlElement, "margin-bottom"));
        });

        /* -15 - the offset from edge of "subject" container. */
        offset = (offset.filter(Boolean).reduce((sum, offset) => sum + offset) - 15) + "px";

        this.textOverlayHeight = `calc(100% - ${ offset })`;
      },
      _getListMaterialsByListId(listId) {
        return (this.suggestedLists.find(list => list.id === listId) || {}).materials || [];
      },
      async _openReview(review) {
        await MaterialDetail.methods._openReview.call(this, review);
      },
      async _openVideo(video) {
        this.activeVideo = video;
        await this.$nextTick();
        if (this.$refs.videoModal) {
          this.$refs.videoModal.show();
        }
      },
      _closeEreolen() {
        if (this.$refs.ereolenModal) {
          this.$refs.ereolenModal.hide();
        }
      },
      _closeReview() {
        if (this.$refs.reviewModal) {
          this.$refs.reviewModal.hide();
        }
      },
      _closeVideo() {
        if (this.$refs.videoModal) {
          this.$refs.videoModal.hide();
        }
      }
    },
    mounted() {
      this.$once("detail-loaded", async () => {
        this._updateSpecialColorsWithOverlay();
        this._resizeTextSectionOverlay();

        const dominantColor = await getDominantImageColor(this.$refs.image);
        if (dominantColor) {
          this.specialColor = dominantColor;
          this._updateSpecialColorsWithOverlay();
        }
      });

      this.$on("orientation-changed", this._resizeTextSectionOverlay);
      this.$on("orientation-changed", this._adjustFullDetailTitle);
      this.$once("suggested-lists-loaded", () => {
        this.navigation = this.suggestedLists.slice(0, 3).map(list => ({ title: list.title, type: list.id })).concat(this.navigation);
        this.activeSecondaryContentType = (this.navigation[0] || {}).type || this.activeSecondaryContentType;
        this.suggestionMaterials = this._getListMaterialsByListId(this.activeSecondaryContentType);
        this.suggestionsSlideIndex = 0;
      });

    },
    beforeDestroy() {
      this.$off("orientation-changed", this._resizeTextSectionOverlay);
      this.$off("orientation-changed", this._adjustFullDetailTitle);
    },
    components: {
      "loader": Loader,
      "modal-blank": ModalBlank,
      "modal-alert": ModalAlert,
      "modal-confirm": ModalConfirm,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "easyscreen-carousel": EasyscreenCarousel,
      "easyscreen-foldable": EasyscreenFoldable,
      "easyscreen-iframe": EasyscreenIframe,
      "easyscreen-youtube": EasyscreenYoutube,
      "scrollable": Scrollable,
      "digital-shelf-cover": DigitalShelfCover,
      "authentication-form": AuthenticationForm
    }
  };
</script>
