<template>
  <div>
    <modal-fullscreen
      ref="modal"

      color-scheme="easyscreen-menu"

      @before-open="(event) => $emit('before-open', event)"
      @opened="(event) => $emit('opened', event)"
      @before-close="(event) => $emit('before-close', event)"
      @closed="(event) => {
        $emit('closed', event);
        _resetSearchResults();
      }"

      :class="['easyscreen-search', `easyscreen-search_${ colorScheme }`, orientation]"
      :hide-empty-container="true"
      header-height="150px"
      :footer-height="design === 'standalone-search' ? '150px' : null"
      :auto-close="autoClose"
      :position="position"
    >
      <template slot="header">
        <slot name="header"></slot>
        <easyscreen-circle-button
          v-if="withCloseControls"
          class="easyscreen-search--close-button"
          icon="/images/es-menu/close_icon.png"
          icon-type="image"

          @click.native="hide"
        />
      </template>
      <template slot="content">
        <search-form
          ref="searchForm"
          :default-sorting="defaultSorting"
          :with-sorting="false"
          :with-search-suggestions="!searchInProgress"
          :popular-searches="popularSearches"
          @find="(query) => findBy(query)"
        />
      </template>
      <template slot="footer">
        <span v-if="design === 'standalone-search'">X</span>
      </template>
    </modal-fullscreen>
    <search-results
      ref="searchResultsModal"
      :store="store"
      :color-scheme="colorScheme"
      :available-only-button="availableOnlyButton"
      :shelf-only-button="shelfOnlyButton"
      :popular-searches="popularSearches"
      :title="title"
      :position="position"
      @opened="() => $emit('modal-opened', $refs.searchResultsModal.hide, 'searchResults')"
      @closed="() => $emit('closed-inner-modal', 'searchResults')"
      @modal-opened="(closeModal, type) => $emit('modal-opened', closeModal, type)"
      @closed-inner-modal="(type) => $emit('closed-inner-modal', type)"
      @before-close="() => {
        $emit('before-search-results-closed');

        if ($refs.searchForm) {
          $refs.searchForm.clearQuery();
        }
      }"
      @go-home="hide"
    />
    <loader :position="position" ref="searchLoader" />
  </div>
</template>

<style lang="less" src="./search.less"></style>
<style lang="less" src="../core/mixins.less"></style>

<script>
  import ModalFullscreen from "../core/modal/fullscreen.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import Loader from "../core/loader/loader.vue";
  import SearchForm from "./search-form.vue";
  import SearchResults from "./search-results.vue";

  import { get } from "lodash";
  import VuexLike from "@/lib/vuex-like.js";
  import orientationMixin from "../core/mixins/orientation.js";
  import searchStore from "./search-store.js";

  export default {
    name: "easyscreen-search",
    mixins: [
      orientationMixin
    ],
    props: {
      /*
       * The color scheme of search and search results:
       * - classic - the classic background with smoke (default).
       * - easyscreen-menu - the solid color or image background.
       */
      colorScheme: {
        type: String,
        default: "classic",
        validator: _colorScheme => ["classic", "easyscreen-menu"].includes(_colorScheme)
      },
      /*
       * The visual type of search:
       * - default - The search with smoked background (modal).
       * - standalone-search - The search with smoked background and without the close buttons on the main search screen (always shown).
       * - easyscreen-menu-search - The search with dark-green or overrided background (modal).
       */
      design: {
        type: String,
        default: "default",
        validator: _design => ["default", "standalone-search", "easyscreen-menu-search"].includes(_design)
      },
      /**
       * The default sorting of search resutls from the main search screen.
       */
      defaultSorting: {
        type: String,
        default: ""
      },
      /* The state of availableOnly flag on search resutls from the main search screen.  */
      availableOnly: {
        type: Boolean,
        default: false
      },
      /* Show the available only button. Which allow the user to get the only available materials in resutls. */
      availableOnlyButton: {
        type: Boolean,
        default: false
      },
      /* The state of shelfOnly flag on search resutls from the main search screen.  */
      shelfOnly: {
        type: Boolean,
        default: false
      },
      /*
       * Show the this self only button. Which allow the user to get results from current
       * shelf (to query will be applied the custom sub-query).
       */
      shelfOnlyButton: {
        type: Boolean,
        default: false
      },
      /* The prefix of query which used for shelfOnly feature. */
      queryPrefix: String,
      /* The type of popular search, one of: "multiline", "inline", "inline-cloud", "inline-list". */
      popularSearches: String,
      /* The title of search results screen, used for shelfOnly feature. */
      title: String,
      /* Close the popup when standby is started. */
      autoClose: {
        type: Boolean,
        default: false
      },
      withCloseControls: {
        type: Boolean,
        default: true
      },
      position: String
    },
    data() {
      const store = new VuexLike("search", searchStore);
      store.$easyscreenConfig = this.$easyscreenConfig;
      store.$easyscreenRequest = this.$easyscreenRequest;
      store.$easyscreenStatistic = this.$easyscreenStatistic;

      return {
        store: store
      };
    },
    /* The watch is used for apply the changes in search store. */
    watch: {
      "store.state": {
        handler() {},
        deep: true
      }
    },
    computed: {
      query: {
        get() {
          return get(this.store, "state.query");
        },
        set(value) {
          this.store.commit("setQuery", { query: value });
        }
      },
      searchInProgress: {
        get() {
          return get(this.store, "state.searchInProgress");
        }
      },
      isShown: {
        cache: false,
        get() {
          return this.$refs.modal && this.$refs.modal.isShown;
        }
      }
    },
    methods: {
      /**
       * Show the current modal.
       *
       * @param {Function} [callback] - The show callback, will be called when modal is opened.
       */
      show(callback) {
        return this.$refs.modal.show(callback);
      },
      /**
       * Hide the current modal.
       *
       * @param {Function} [callback] - The hide callback, will be called when modal is closed.
       */
      hide(callback) {
        return this.$refs.modal.hide(callback);
      },
      /**
       * Do the search with default sorting, availableOnly and shelfOnly flags.
       * @async
       *
       * @param {String} query - The text query of the search.
       */
      findBy(query) {
        if (this.$refs.searchForm)
          this.$refs.searchForm.setQuery(query);

        return this.store.dispatch("find", {
          query: query,
          sorting: this.defaultSorting,
          availableOnly: this.availableOnly,
          shelfOnly: this.shelfOnly,
          queryPrefix: this.queryPrefix
        });
      },
      _resetSearchResults() {
        return this.store.dispatch("resetSearchResults");
      },
      _setOrientation() {
        let layoutColumns = 8;
        let layoutRows = 2;

        if (this.orientation === "portrait-orientation") {
          layoutColumns = 4;
          layoutRows = 3;
        }

        this.store.commit({
          type: "setState",
          layoutColumns: layoutColumns,
          layoutRows: layoutRows
        });
      }
    },
    mounted() {
      /*
       * The listeners on search-start and search-end store events:
       * will show and hide the loader.
       */
      this.$once("hook:beforeDestroy", this.store.on("search-start", () => {
        if (!get(this.$refs, "searchResultsModal.isShown")) {
          this.$refs.searchLoader.show();
        }
      }));
      this.$once("hook:beforeDestroy", this.store.on("search-end", () => {
        if (!get(this.$refs, "searchResultsModal.isShown")) {
          this.$refs.searchLoader.hide();
          /*
           * Show the search result modal after the search is performed.
           * Modal will not be shown twice.
           */
          this.$refs.searchResultsModal.show();
        }
      }));
      this.$on("orientation-changed", this._setOrientation);
      this._setOrientation();
    },
    beforeDestroy() {
      if (module.hot) {
        this.$emit("before-hot-update");
      }
    },
    components: {
      "modal-fullscreen": ModalFullscreen,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "loader": Loader,
      "search-form": SearchForm,
      "search-results": SearchResults
    }
  };
</script>
