<template>
  <div class="easyscreen-progress-bar">
    <div
      class="easyscreen-progress-bar--progress"
      :style="{
        width: `${ percentageProgress }%`
      }"
    ></div>
    <div
      v-if="endMarker !== 'none'"
      :class="[
        'easyscreen-progress-bar--end-marker',
        `easyscreen-progress-bar--end-marker_${ endMarker }`
      ]"
      :style="{
        left: `${ percentageProgress }%`
      }"
    ></div>
  </div>
</template>

<style src="./progress-bar.less" lang="less"></style>

<script>
  export default {
    name: "easyscreen-progress-bar",
    props: {
      /*
       * The marker of the progress bar end.
       * - none - No markers, just a line end (default).
       * - circle - Circle on the line end.
       * - square - Square on the line end.
       */
      endMarker: {
        type: String,
        default: "none",
        validator: _endMarker => ["none", "circle", "square"].includes(_endMarker)
      },
      /* The left border of progress bar scale (completely empty on this value). */
      from: {
        type: Number,
        default: 0
      },
      /* The rigth border of progress bar scale (fully filled on this value). */
      to: {
        type: Number,
        default: 100
      },
      /* Current progress - some value between `from` and `to` */
      progress: {
        type: Number,
        default: 0
      }
    },
    computed: {
      /**
       * Getter of percentage progress based on `from`, `to` and `progress`.
       *
       * @returns {Number}
       */
      percentageProgress() {
        return this.progress / (this.to - this.from) * 100;
      }
    }
  };
</script>
