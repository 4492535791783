<template>
  <div>
    <p>
      {{ $l10n("To whom it may concern,") }}<br/>
      {{ $l10n("The operations below have failed during a safety mode.") }}
    </p>

    <div v-if="loanOperations.length !== 0">
      <div><strong>{{ $l10n('Loan') }}</strong></div>
      <p
        v-for="operation in loanOperations"
        :key="operation.id"
      >
        CPR: {{ operation.cpr }},<br/>
        PIN: {{ operation.pin }},<br/>
        Materials: {{ operation.materials.join(", ") }}<br/>
        Time: {{ _moment(operation.timestamp).format('DD/MM-YYYY HH:mm:ss') }}<br/>
        Screen ID: {{ operation.screenId }}<br/>
        Error message: {{ operation.message }}
        <br/>
      </p>
    </div>

    <div v-if="returnOperations.length !== 0">
      <div><strong>{{ $l10n('Return') }}</strong></div>
      <p
        v-for="operation in returnOperations"
        :key="operation.id"
      >
        Materials: {{ operation.materials.join(", ") }}<br/>
        Time: {{ _moment(operation.timestamp).format('DD/MM-YYYY HH:mm:ss') }}<br/>
        Screen ID: {{ operation.screenId }}<br/>
        Error message: {{ operation.message }}
        <br/>
      </p>
    </div>
  </div>
</template>

<script>
  import moment from "moment";
  import serializeMixin from "../materials-scanner/email-templates/serialize-mixin.js";

  export default {
    name: "safety-mode-failed-operations",
    mixins: [serializeMixin],
    props: {
      failedOperations: Array
    },
    computed: {
      loanOperations() {
        return this.failedOperations.filter(operation => operation.action === "loan");
      },
      returnOperations() {
        return this.failedOperations.filter(operation => operation.action === "return");
      }
    },
    methods: {
      /**
       * The proxy method of `moment`.
       */
      _moment: moment
    }
  };
</script>
