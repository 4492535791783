<template>
  <span
    :class="['easyscreen-checkbox', `easyscreen-checkbox_design-${ design }`]"
    @click="(event) => $emit('input', !this.checked)"
  >
    <i v-if="checked" class="easyscreen-checkbox--check-mark fa fa-check"></i>
  </span>
</template>

<style lang="less" src="./checkbox.less"></style>
<script>
  export default {
    name: "easyscreen-checkbox",
    props: {
      /* State of checkbox. */
      checked: {
        type: Boolean,
        default: false
      },
      /* The global reskin for materials list. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    }
  };
</script>
