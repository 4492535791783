<template>
  <div :class="['patron-profile--debts', orientation]">
    <modal-layout>
      <template slot="header">
        <div class="patron-profile--common-title">
          <span class="patron-profile--common-title-text">
            {{ _l10n("Debts") }}
          </span>

          <easyscreen-badge
            v-if="fees.length !== 0"
            :class="{ 'patron-profile--common-title-badge': _isPortrait() }"
            color="danger"
            type="circle"
          >
            {{ fees.length }}
          </easyscreen-badge>

          <label v-if="provider !== 'bibliofil'" class="patron-profile--common-select-all">
            <span class="patron-profile--common-select-all-label">{{ _l10n("Select all") }}</span>
            <easyscreen-checkbox
              class="patron-profile--common-select-all-checkbox"
              :checked="feesInfo.length !== 0 && feesInfo.every(feeInfo => feeInfo.checked)"
              @input="(_checked) => {
                feesInfo.forEach(feeInfo => {
                  feeInfo.checked = _checked;
                });
              }"
            />
          </label>
        </div>
      </template>
      <template slot="content">
        <div
          class="easyscreen-grid easyscreen-grid_gap-2"
          :style="{
            gridTemplateColumns: '2fr 3fr',
            height: _isLandscape() ? 465 : 1245
          }"
        >
          <div class="es-pr-1 border-right border-disabled">
            <h4 class="es-mb-2">{{ _l10n('Payment terms') }}</h4>
            <p class="text-disabled es-mb-4 text-height-1-5">{{ terms }}</p>
            <h4 class="es-mb-2">{{ _l10n("We accept the following payment methods") }}</h4>
            <img class="patron-profile--debts--terms-image" :src="termsImage">
          </div>
          <scrollable
            class="es-pl-1"
            :max-height="_isLandscape() ? 430 : 1210"
            smooth-edge-color="#262626"
          >
            <profile-material-info
              v-for="(feeInfor, index) in feesInfo"
              :checkbox="provider !== 'bibliofil'"
              :key="`${ feeInfor.title }-${ index }`"
              :title="feeInfor.title"
              :attributes="feeInfor.attributes"
              :attributes-style="{ gridTemplateColumns: '1fr 1fr' }"
              :checked="feeInfor.checked"
              @input="(_checked) => { feeInfor.checked = _checked }"
            />
          </scrollable>
        </div>
      </template>
      <template slot="footer">
        <div class="patron-profile--debts-footer">
          <easyscreen-button-group gap="big">
            <easyscreen-button
              color="primary"
              modificator="fixed-width"
              @click.native="_payAll"
              :disabled="fees.length === 0"
            >
              {{ _l10n("Pay all") }}
              ({{ _feesPrice(feesInfo) }})
            </easyscreen-button>

            <easyscreen-button
              v-if="provider !== 'bibliofil'"
              color="primary"
              modificator="fixed-width"
              @click.native="_paySelected"
              :disabled="_selectedFees().length === 0"
            >
              {{ _l10n("Pay selected") }}
              ({{ _selectedFees().length }})
              ({{ _feesPrice(_selectedFees()) }})
            </easyscreen-button>
          </easyscreen-button-group>

          <div class="patron-profile--debts-selection-status easyscreen-grid easyscreen-grid_two-rows easyscreen-grid_gap-1">
            <div class="easyscreen-grid easyscreen-grid_two-cols">
              <p class="text-bold text-left">{{ _l10n('Total') }}</p>
              <p class="text-disabled">{{ _feesPrice(feesInfo) }}</p>
            </div>
            <div v-if="provider !== 'bibliofil'" class="easyscreen-grid easyscreen-grid_two-cols">
              <p class="text-bold text-left">{{ _l10n('Total selected') }}</p>
              <p class="text-disabled">{{ _feesPrice(_selectedFees()) }}</p>
            </div>
          </div>
        </div>
      </template>
    </modal-layout>

    <loader ref="loader" />
    <modal-alert ref="alert" />
    <modal-edit-number
      :ok-title="_l10n('Send to MobilePay app')"
      :input-style="{ width: 294, margin: '0 auto' }"
      ref="paymentPhoneModal"
    ></modal-edit-number>
    <modal-alert
      ref="payTimerAlert"
      min-width="1040px"
      :okOptions="{ color: 'secondary', title: _l10n('Cancel / new number') }"
      @opened="() => {
        _startCountdown(() => {
          _pay(payTimer.fees, payTimer.phone);
          $refs.payTimerAlert.hide();
        });
        _startFeesUpdateChecking();
      }"
      @closed="() => {
        _stopCountdown();
        _stopFeesUpdateChecking();
      }"
      @ok="() => {
        _pay(payTimer.fees, payTimer.phone)
      }"
    >
      <template slot="header">{{ payTimer.title }}</template>
      <template slot="content">
        <div class="patron-profile--debts--pay-timer es-p-4 text-center">
          <p class="patron-profile--debts--pay-timer-message text-bold es-mb-3">
            {{ _l10n("Please open MobilePay on your phone and accept the payment.") }}
          </p>
          <h3 class="patron-profile--debts--pay-timer-phone es-mb-2">
            {{ _addSpacesToNumber(payTimer.phone) }}
          </h3>
          <img class="patron-profile--debts--pay-timer-logo" src="/images/user-profile/phone.png">
          <h3 class="patron-profile--debts--pay-timer-countdown es-mt-2">
            {{ _humanizeCountdown(payTimer.countdown) }}
          </h3>
        </div>
      </template>
    </modal-alert>
    <modal-alert
      ref="manualConfirmationAlert"
      min-width="1040px"
      :okOptions="{ color: 'secondary', title: _l10n('Cancel') }"
      @opened="() => { _startFeesUpdateChecking(1000) }"
      @closed="() => { _stopFeesUpdateChecking() }"
    >
      <template slot="header">{{ _l10n("Confirm the payment.") }}</template>
      <template slot="content">
        <div class="patron-profile--debts--confirmation">
          <iframe class="patron-profile--debts--confirmation-iframe" :src="payTimer.url"></iframe>
          <div class="patron-profile--debts--confirmation-top-overlay"></div>
          <div class="patron-profile--debts--confirmation-bottom-overlay"></div>
        </div>
      </template>
    </modal-alert>

    <modal-alert
      ref="paymentCompleteAlert"
      min-width="1040px"
    >
      <template slot="header">{{ payTimer.title }}</template>
      <template slot="content">
        <div class="patron-profile--debts--complete text-center">
          <h4 class="es-mb-2">{{ _l10n("Payment completed!") }}</h4>
          <h4 class="es-mb-2">{{ _l10n("Thank you :)") }}</h4>
          <img class="patron-profile--debts--complete-logo" src="/images/user-profile/complete.png">
        </div>
      </template>
    </modal-alert>
  </div>
</template>

<style lang="less" src="./common.less"></style>
<style lang="less" src="./debts.less"></style>
<style lang="less" src="../../../core/mixins.less"></style>
<style lang="less" src="../../../core/form/grid.less"></style>
<script>
  import ModalAlert from "../../../core/modal/alert.vue";
  import ModalEditNumber from "../../../core/modal/edit/number.vue";
  import ModalLayout from "../../../core/modal/layout.vue";
  import Scrollable from "../../../core/scrollable/scrollable.vue";
  import Loader from "../../../core/loader/loader.vue";
  import EasyscreenButton from "../../../core/button/button.vue";
  import EasyscreenButtonGroup from "../../../core/button/button-group.vue";
  import EasyscreenBadge from "../../../core/badge/badge.vue";
  import EasyscreenCheckbox from "../../../core/input/checkbox.vue";
  import ProfileMaterialInfo from "../parts/material-info.vue";

  import { get, isFunction } from "lodash";
  import parseDuration from "parse-duration";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../../../core/mixins/orientation.js";
  import convertDateMixin from "../../../core/mixins/convert-date.js";

  /* The text and image of terms for different providers. */
  const terms = {
    // eslint-disable-next-line
    default: "Fees: You can pay your fees (fines) to the library on this page. The fee is payable to Greve Library. Once the fee is paid, it is not possible to cancel the payment. The amount will not be raised in the account until it has been found that the amount due is registered paid in the library system. Bills: However, if you have received an invoice from us, payment can be made by visiting the library, via online bank account, to account number 3191-3191169174 or MobilePay to 20 48 24 64 Remember to enter the bill number in both cases.",
    bibliofil: "terms for vipps-bilbiofil here"
  };

  const termsImage = {
    default: "/images/user-profile/mobile-pay.png",
    bibliofil: "/images/user-profile/vipps-rgb-hvit.png"
  };

  const SECOND = parseDuration("1s");
  const MINUTE = parseDuration("60s");
  const defaultCountdownDuration = parseDuration("5m");
  const feesUpdateCheckingInterval = parseDuration("15s");

  export default {
    name: "patron-profile-debts",
    mixins: [orientationMixin, convertDateMixin],
    props: {
      /* The name of info-data provider, used for partially disable features on screen. */
      provider: String,
      /* List of raw user fees, see response on GET [lms]/[consumer-hash]/patron/fees */
      fees: {
        type: Array,
        default: () => ([])
      },
      /* The name of payment provider, used for display the different terms of usage text and image */
      paymentProvider: String,
      /* The fee currency. */
      currency: String,
      /* The authentication user form data: cpr and pin. */
      formData: Object
    },
    data() {
      return {
        feesInfo: this._getFeesInfo(),
        terms: terms[this.provider] || terms.default,
        termsImage: termsImage[this.provider] || termsImage.default,
        payTimer: {
          url: null,
          title: "",
          phone: "",
          countdown: defaultCountdownDuration,
          fees: []
        }
      };
    },
    watch: {
      fees(newFees) {
        this.feesInfo = this._getFeesInfo(newFees);
      }
    },
    methods: {
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Get the total price of fees.
       *
       * @param {FeeInfo[]} fees - The list of fee info.
       *
       * @returns {Number} Total price of passed fees.
       */
      _feesPrice(fees) {
        return `${ (fees || []).reduce((sum, fee) => sum + fee.price, 0).toFixed(2) } ${ this.currency }`;
      },
      /**
       * Get the selected fees.
       *
       * @returns {FeeInfo} The list of selected fees.
       */
      _selectedFees() {
        return this.feesInfo.filter(feeInfo => feeInfo.checked);
      },
      /**
       * Converts the raw fee into unifiend fee info.
       *
       * @param {Object[]} fees - The raw user fees.
       *
       * @returns {FeeInfo[]} List of converted fees.
       */
      _getFeesInfo(fees) {
        return (fees || this.fees || []).map(fee => {
          /**
           * Converted raw fee from lms into unified fee info.
           * @typedef {Object} FeeInfo
           *
           * @property {String} id - The fee id.
           * @property {String} title - Title of loaned material.
           * @property {String} author - Author of loaned material..
           * @property {Boolean} checked - Flag means fee is checked in GUI.
           * @property {Number} price - The price of fee.
           * @property {String} currency - The currency of fee.
           * @property {Object[]} attributes - List of fee attributes.
           * @property {String} attributes[].label - Name of attribute.
           * @property {String} attributes[].value - Value of attribute.
           * @property {String} [attributes[].id] - The id of attribute. Used for matching in special cases.
           */
          return {
            id: get(fee, "feeId"),
            title: get(fee, "materials[0].materialGroup.title"),
            checked: false,
            price: get(fee, "amount"),
            currency: this.currency,
            attributes: [{
              label: l10n("Materialnumber"),
              value: fee.materials.map(material => material.materialItemNumber).join(", ")
            }, get(fee, "creationDate") ? {
              label: l10n("Creation date"),
              value: this._convertDate(get(fee, "creationDate"))
            } : {
              label: l10n("Due date"),
              value: fee.materials.map(material => material.dueDate).filter(Boolean).map(this._convertDate).join(", ")
            }, {
              label: l10n("Cause"),
              value: get(fee, "type")
            }, {
              label: l10n("Amount"),
              value: `${ get(fee, "amount") } ${ this.currency }`
            }, {
              id: "price",
              label: l10n(""),
              value: get(fee, "")
            }].filter(function(attribute){
              return attribute.value;
            })
          };
        });
      },
      /**
       * Starts the payment countdown.
       *
       * @param {Function} callback - The callback of countdown, will be called when time is elapsed. `() => {}`.
       */
      _startCountdown(callback) {
        this._stopCountdown();

        this.countdownIntervalId = setInterval(() => {
          this.payTimer.countdown -= SECOND;

          if (this.payTimer.countdown <= 0) {
            this._stopCountdown();
            if (isFunction(callback)) {
              callback();
            }
          }
        }, SECOND);
      },
      /**
       * Stops and resets the countdown.
       */
      _stopCountdown() {
        if (this.countdownIntervalId) {
          clearInterval(this.countdownIntervalId);
          this.countdownIntervalId = null;
        }

        this.payTimer.countdown = defaultCountdownDuration;
      },
      /**
       * Start the checking of fees updates. When the all selected fees
       * is updated the countdown will be hided and alert is show.
       *
       * @param {Number} [interval=15000] - Checking interval in ms.
       */
      _startFeesUpdateChecking(interval) {
        this._stopFeesUpdateChecking();

        this.feesUpdateCheckingIntervalId = setInterval(async () => {
          try {
            let paid = await this._checkFees(this.payTimer.fees);
            if (paid) {
              this.$emit("debts-updated");
              this.$refs.payTimerAlert.hide();
              this.$refs.paymentCompleteAlert.show();

              this.$easyscreenStatistic.paymentUserProfile({ feeIds: this.payTimer.fees.map(fee => fee.id).join(", ") });
            }
          } catch (err) {
            console.log("Checking fees error:", err);
          }
        }, interval || feesUpdateCheckingInterval);
      },
      /**
       * Stops the fees update checking.
       */
      _stopFeesUpdateChecking() {
        if (this.feesUpdateCheckingIntervalId) {
          clearInterval(this.feesUpdateCheckingIntervalId);
          this.feesUpdateCheckingIntervalId = null;
        }
      },
      /**
       * Convert the duration in ms into human readable format.
       *
       * @param {Number} duration - Duration in ms.
       *
       * @returns {String} The humanized duration in format mm:ss.
       */
      _humanizeCountdown(duration) {
        let seconds = duration % MINUTE;
        return `${ (duration - seconds) / MINUTE }:${ ("" + (seconds / 1000)).padStart(2, "0") }`;
      },
      /**
       * Adds the one space after each two symbols.
       *
       * @param {String} phoneNumber - Phone number without spaces.
       *
       * @returns {String} Phone number with spaces.
       */
      _addSpacesToNumber(phoneNumber) {
        return (phoneNumber.match(/.{1,2}/g) || []).join(" ");
      },
      /**
       * Checks if the passed fees are already paid.
       * @async
       *
       * @param {FeeInfo} fees - The list of fees info for checking.
       *
       * @returns {Promise<Boolean>} The promise-based callback.
       */
      async _checkFees(fees) {
        const fetchedFees = await this.$easyscreenRequest.lmsConnector.getFees({
          user: this.formData.cpr,
          pin: this.formData.pin
        });

        let paidIds = fetchedFees.map((fee) => fee.feeId);
        let isPaid = fees.every((fee) => paidIds.indexOf(fee.id) === -1);

        if (isPaid) {
          return true;
        }

        return false;
      },
      /**
       * Shows the form for user phone and waits for fill.
       * @async
       *
       * @param {FeeInfo[]} fees - The list of fees for payment.
       * @param {String} previousPhone - The phone number of previous payment attempt in a row.
       *
       * @returns {(undefined|Number)} The user phone number.
       */
      async _authenticate(fees, previousPhone) {
        let message = l10n("Enter your phone number to recieve the MobilePay notification.");

        if (previousPhone) {
          message = `
            ${ l10n("The payment failed.") }
            ${ l10n("You entered") } “${ this._addSpacesToNumber(previousPhone) }”.
            ${ l10n("Please try again or another number.") }
          `.trim();
        }

        return new Promise((resolve) => {
          this.$refs.paymentPhoneModal.show({
            title: `${ l10n("Payment") } ${ this._feesPrice(fees) }`,
            messageHTML: `<p class="text-center text-bold es-mb-4">${ message }</p>`,
            callback: (e, type) => {
              if (type !== "ok") {
                return resolve();
              }

              if (e.value.length !== 8) {
                this.$refs.paymentPhoneModal.showError(l10n("Should contains 8 digits."));
                e.preventDefault();
                return;
              }

              resolve(e.value);
            }
          });
        });
      },
      /**
       * Shows the modal with payment error.
       *
       * @param {Object} options - The payment and error options.
       * @param {FeeInfo[]} options.fees - The list of fees of payment.
       * @param {String} [options.message] - The error message.
       */
      _paymentError(options) {
        this.$refs.loader.hide();
        this.$refs.paymentPhoneModal.hide();
        this.$refs.payTimerAlert.hide();
        this.$refs.alert.show({
          title: `${ l10n("Payment") } ${ this._feesPrice(options.fees) }`,
          message: options.message || "Something went wrong, try again later."
        });
      },
      /**
       * Start the payment flow for passed fees.
       * @async
       *
       * @param {FeeInfo[]} fees - The fees of payment.
       * @param {String} previousPhone - The phone used the last wrong payment attempt.
       *
       * @returns {Promise} Do not use it as the end of payment since the promise will be resolved on sent request to lms.
       */
      async _pay(fees, previousPhone) {
        try {
          let phone = await this._authenticate(fees, previousPhone);
          if (!phone) {
            return;
          }

          this.$refs.loader.show();
          const paymentGatewayResult = await this.$easyscreenRequest.lmsConnector.paymentGateway({
            user: this.formData.cpr,
            pin: this.formData.pin,
            phone: phone || previousPhone,
            feeId: this.feesInfo.map(feeInfo => feeInfo.id).join(",")
          });

          this.$refs.loader.hide();

          this.payTimer.title = `${ l10n("Payment") } ${ this._feesPrice(fees) }`;
          this.payTimer.phone = phone || previousPhone;
          this.payTimer.fees = fees;

          if (this.paymentProvider === "vipps") {
            this.$refs.payTimerAlert.show();

            /* Payment confirmation by script on server side. */
            return this.$easyscreenRequest.easyscreenServer.paymentConfirmation({
              url: paymentGatewayResult.url,
              paymentProvider: "vipps"
            });
          }

          /* Manual payment confirmation. */
          this.payTimer.url = paymentGatewayResult.url;
          this.$refs.manualConfirmationAlert.show();
        } catch (error) {
          this.$refs.loader.hide();
          this._paymentError({ fees, message: error.message });
        }
      },
      /**
       * Start the payment for all fees.
       */
      _payAll() {
        this._pay(this.feesInfo);
      },
      /**
       * Start the payment for selected fees.
       */
      _paySelected() {
        this._pay(this._selectedFees());
      }
    },
    beforeDestroy() {
      this._stopCountdown();
      this._stopFeesUpdateChecking();
    },
    components: {
      "modal-alert": ModalAlert,
      "modal-edit-number": ModalEditNumber,
      "modal-layout": ModalLayout,
      "scrollable": Scrollable,
      "loader": Loader,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-button-group": EasyscreenButtonGroup,
      "easyscreen-badge": EasyscreenBadge,
      "easyscreen-checkbox": EasyscreenCheckbox,
      "profile-material-info": ProfileMaterialInfo
    }
  };
</script>
