<template>
  <div class="node-list-carousel-horsholm-krsbib-slide">
    <h1 class="node-list-carousel-horsholm-krsbib-slide--title">
      {{ title }}
    </h1>
    <div
      v-if="isEvent"
      class="node-list-carousel-horsholm-krsbib-slide--meta"
    >
      <div>
        <span
          class="node-list-carousel-horsholm-krsbib-slide--location"
        >
          {{ locationOrDate }}
        </span>
        <span
          v-if="price"
          class="node-list-carousel-horsholm-krsbib-slide--price"
        >
          {{ price }}
        </span>
      </div>
    </div>
    <div class="node-list-carousel-horsholm-krsbib-slide--teaser">
      <div
        v-if="teaserImage"
        class="node-list-carousel-horsholm-krsbib-slide--teaser-image"
        :style="{
          backgroundImage: `url('${ teaserImage }')`
        }"
      ></div>
      <div class="node-list-carousel-horsholm-krsbib-slide--teaser-text ck-editor" v-html="teaser"></div>
    </div>
  </div>
</template>

<style src="../../core/ck-editor.less" lang="less"></style>
<style src="./node-list-carousel-horsholm-krsbib-slide.less" lang="less"></style>

<script>
  import moment from "moment";

  export default {
    name: "node-list-carousel-horsholm-krsbib-slide",
    props: {
      type: String,
      date: String,
      dateTo: String,
      location: String,
      price: [String, Number],
      bgImage: String,
      image: String,
      title: String,
      esTeaser: String,
      teaser: String,
      text: String,
      lead: String
    },
    computed: {
      isEvent() {
        return this.type === "event";
      },
      formattedDate() {
        const dateFrom = moment(this.date);
        const dateTo = moment(this.dateTo);
        let date = moment();

        if (date < dateFrom) {
          date = dateFrom;
        } else if (date > dateTo) {
          date = dateTo;
        }

        date.hour(dateFrom.hour());
        date.minute(dateFrom.minute());

        let formattedDate = date.format("dddd D. MMMM");

        if (date.hour() !== 0 || date.minute() !== 0) {
          const fromTime = dateFrom.format("HH:mm");
          const toTime = dateTo.format("HH:mm");

          formattedDate += ` kl. ${ fromTime }`;

          if (fromTime !== toTime) {
            formattedDate += ` til. ${ toTime }`;
          }
        }

        return formattedDate;
      },
      locationOrDate() {
        let location = this.location;
        if (this.$easyscreenSkin.isHorsholm) {
          location = location.split(", ");
          if (location.length > 2){
            location = location.slice(0, 1).concat("...");
          }

          location = location.join(", ");
        } else if (this.$easyscreenSkin.isKrsbib) {
          location = this.formattedDate;
        }

        return location;
      },
      teaserImage() {
        return this.image || this.bgImage;
      }
    }
  };
</script>
