var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm._isShown())?_c('div',{class:[
    'search-suggestions',
    `search-suggestions_${ _vm.suggestionsType }`,
    `search-suggestions_${ _vm.type }`,
    `search-suggestions_design-${ _vm.design }`,
    _vm.orientation
  ],style:({ '--search-suggestions-background-color': _vm.backgroundColor })},_vm._l((_vm._getSuggestions()),function(suggestions){return _c('div',{key:suggestions.title,staticClass:"search-suggestions--column"},[_c('div',{staticClass:"search-suggestions--title"},[_vm._v(_vm._s(suggestions.title))]),_c('div',{class:[
        'search-suggestions--content',
        { 'search-suggestions--content_with-covers': suggestions.withCovers }
      ]},_vm._l((suggestions.hits),function(hit){return _c('div',{key:hit.id,class:[
          'search-suggestions--hit'
        ],style:(suggestions.withCovers ? { width: `${ 100 / suggestions.limit }%` } : null),on:{"click":function($event){return _vm.$emit('select', hit)}}},[(hit.cover)?_c('img',{staticClass:"search-suggestions--hit-image",attrs:{"draggable":"false","src":_vm.$easyscreenRequest.lmsConnector.wrapCover(hit.cover)}}):_c('span',[_vm._v(" "+_vm._s(hit.title)+" "),(suggestions.type)?_c('span',{staticClass:"search-suggestions--hit-type"},[_vm._v(" ("+_vm._s(hit.type || suggestions.type)+") ")]):_vm._e()])])}),0)])}),0):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }