var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'easyscreen-carousel-ribbon',
    `easyscreen-carousel-ribbon_${ _vm.aligment }`
  ]},[_c('div',{staticClass:"easyscreen-carousel-ribbon--content",style:({
      fontSize: _vm.fontSize,
      backgroundColor: _vm.background
    })},[_c('div',{staticClass:"easyscreen-carousel-ribbon--text"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }