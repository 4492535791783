import EventEmitter from "eventemitter3";
import { get, extend } from "lodash";

import FriendlyFrank from "./core.js";

/**
 * The interface of es-linux-apps barcode scanner.
 *
 * @class BarcodeScanner
 * @augments EventEmitter3
 *
 * @param {object} options - The connection options.
 * @param {String} [options.uri=lodash.get(window.ESCONFIG, "scanner.ws", "ws://localhost:9211")] - Connection uri (like: `ws://localhost:9211`).
 * @param {Number} [options.reopenTimeout=2000] - The reconnection interval in ms. Used if the connection is not established or lost.
 * @param {Number} [options.reopenAttempts=5] - Amonut of total reconnections in row.
 *
 * @returns {BarcodeScanner} - instace of BarcodeScanner class.
 */
export default class BarcodeScanner extends EventEmitter {
  constructor(options) {
    super();

    options = options || {};
    options.uri = options.uri || get(window.ESCONFIG, "scanner.ws", "ws://localhost:9211");

    extend(this, options);

    this._init(options);
  }

  /**
   * Closes connection and destroys the FriendlyFrank instance.
   */
  destroy() {
    if (this._friendlyFrank) {
      this._friendlyFrank.destroy();
    }
  }

  /**
   * Inialization of barcode scanner. Creates the instance of Friendly Frank.
   * @listens module:FriendlyFrank~action:barcodescanner-handshake
   * @listens module:FriendlyFrank~action:scanned-barcode
   *
   * @param {object} options - The connection options.
   * @param {String} options.uri - Connection uri (like: `ws://localhost:9211`). Required.
   * @param {Number} [options.reopenTimeout=2000] - The reconnection interval in ms. Used if the connection is not established or lost.
   * @param {Number} [options.reopenAttempts=5] - Amonut of total reconnections in row.
   * @param {Boolean} [options.autoConnect=true] - Connecting on instantiate.
   */
  _init(options) {
    this._friendlyFrank = new FriendlyFrank(Object.assign({}, options, { autoConnect: false }));
    this._friendlyFrank.on("action:scanned-barcode", (message) => {
      this.emit("scan", message.barcode);
    });
    this._friendlyFrank.on("action:barcodescanner-handshake", (message) => {
      this.enabled = true;
      this.type = message.type;
    });
    this._friendlyFrank.connect();
  }

  /*
   * Check if the barcode scanner is enabled - true in case when app doesn't supports the
   * handshake or barcodescanner handshake called and application is connected by websockets.
   *
   * @returns {boolean} The enable status of barcode scanner.
   */
  isEnabled() {
    var oldApplicationFallbackChecking = get(window.ESCONFIG, "enable.cprScanner")
      && !get(this._friendlyFrank, "_handshake['application-handshake']");

    return this._friendlyFrank._socket && (this.enabled || oldApplicationFallbackChecking);
  }
}
