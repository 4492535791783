import * as querystring from "querystring";

/**
 * Get availability based on material detail with holdings.
 *
 * @param {Object} materialDetail - The detail of material (see full scheme at LMS route /[consumer-hash]/search);
 * @param {Boolean} materialDetail.availableOnline - The flag means online material.
 * @param {Object[]} materialDetail.holdings - The holdings of physical material;
 * @param {Number} materialDetail.holdings[].available - The amount of available materials.
 * @param {String} materialDetail.holdings[].branchId - The id of branch where material is available.
 * @param {String} materialDetail.holdings[].departmentId - The id of department where material is available.
 *
 * @returns {String} The type of availability. One of: unknown, available, not-available, available-online,
 * available-at-another-branch.
 */
export default function availabilityStatus(materialDetail) {
  if (materialDetail.availableOnline) {
    return "available-online";
  }

  if (!materialDetail.holdings) {
    return "unknown";
  }

  const avaialble = (materialDetail.holdings || []).some(holding => holding.available > 0);
  if (!avaialble) {
    return "not-available";
  }

  const urlOptions = querystring.parse(window.location.href.split("#")[1]);
  const departmentId = (urlOptions.departmentId || "").split(",").filter(Boolean);

  if (!urlOptions.departmentId && departmentId.length !== 0) {
    return "available";
  }

  const avaialbleAtSelectedPlace = (materialDetail.holdings || []).some(holding => {
    const sameBranch = !urlOptions.branchId || urlOptions.branchId === holding.branchId;
    const sameDepartment = departmentId.length === 0 || departmentId.includes(holding.departmentId);

    return sameBranch && sameDepartment && holding.available > 0;
  });

  if (!avaialbleAtSelectedPlace) {
    return "available-at-another-branch";
  }

  return "available";
}
