import moment from "moment";

export default {
  methods: {
    /**
     * Helper for convert the date format. Used for: debts.vue, loans.vue, overview.vue, reservations.vue and user-account.vue.
     *
     * @param {String} date - The date in format "YYYY-MM-DD".
     * @param {String} [format="DD. MMMM YYYY"] - The new date format.
     *
     * @returns {String} The converted date in passed or default ("DD. MMMM YYYY") format.
     */
    _convertDate(date, format) {
      let parsedDate = moment(date, "YYYY-MM-DD");
      if (!date || !parsedDate.isValid()) {
        return "";
      }

      return parsedDate.format(format || "DD. MMMM YYYY").trim();
    }
  }
};
