var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal-layout-fullscreen',{class:[
      'easyscreen-menu',
      { 'easyscreen-menu_safety-mode': _vm.safetyModeEnabled },
      _vm.orientation
    ],attrs:{"colorScheme":"easyscreen-menu-front-page","data-id":_vm.skinId}},[_c('template',{slot:"header-center"},[_c('div',{staticClass:"easyscreen-menu--main-header"},[_c('h1',{staticClass:"easyscreen-menu--main-title"},[_vm._v(_vm._s(_vm.title || _vm._l10n('Welcome!')))]),(_vm.$easyscreenConfig.get('enable.popularSearches') && _vm.popularSearchesType !== 'none')?_c('div',[_c('popular-searches-inline',{staticClass:"es-mt-2",attrs:{"full-type":_vm.popularSearchesType},on:{"select":(tag) => {
              _vm._openScreen({ screenName: 'search' }, () => {
                _vm.$refs.searchScreen.find(tag.title);
              });
            }}})],1):_c('p',{staticClass:"easyscreen-menu--main-text"},[_vm._v(" "+_vm._s(_vm.subTitle || _vm._l10n('Search the base, get inspired or pay your debts'))+" ")]),(_vm.searchEnabled)?_c('div',{staticClass:"easyscreen-menu--input-like-button",on:{"click":function($event){return _vm._openScreen({ screenName: 'search' })}}},[_c('div',{staticClass:"easyscreen-menu--input-like-button_input"},[_vm._v(" "+_vm._s(_vm._l10n('Search titles, authors, subjects or ISBN number'))+" ")]),_c('button',{staticClass:"easyscreen-menu--input-like-button_button"},[_vm._v(" "+_vm._s(_vm._l10n('Search!'))+" ")])]):_vm._e()])]),_c('template',{slot:"content"},[_c('div',{staticClass:"easyscreen-menu--main-content"},[(_vm.safetyModeEnabled)?_c('h1',{staticClass:"easyscreen-menu--message easyscreen-menu--message_safety-mode"},[_vm._v(" "+_vm._s(_vm.$l10n("NOTE: This computer is running in safety mode."))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.$l10n("Only loan features is available until the computer is back online."))+" ")]):(_vm.hasDigitalShelf)?_c('digital-shelf',{attrs:{"disable-external-features":true,"suggested-lists":true,"items":_vm.items,"tags":_vm.tags,"layout-columns":_vm._isLandscape() ? 9 : 5,"layout-rows":_vm._isLandscape() ? 1 : 3},on:{"find-by-tag":(tag) => {
            _vm._openScreen({ screenName: 'search' }, () => {
              _vm.$refs.searchScreen.find(tag);
            });
          }}}):(_vm.logoImage)?_c('div',{staticClass:"easyscreen-menu--logo"},[_c('img',{staticClass:"easyscreen-menu--logo-image",attrs:{"src":_vm.logoImage}})]):_vm._e()],1),_c('search-screen',{ref:"searchScreen",attrs:{"popular-searches-type":_vm.popularSearchesType}}),(_vm.screen === 'profile')?_c('profile-screen',{ref:"profileScreen",on:{"closed":function($event){_vm.screen = ''}}}):_vm._e(),(_vm.screen === 'pay')?_c('profile-pay-screen',{ref:"payScreen",on:{"closed":function($event){_vm.screen = ''}}}):_vm._e(),(_vm.screen === 'renew')?_c('profile-renew-screen',{ref:"renewScreen",on:{"closed":function($event){_vm.screen = ''}}}):_vm._e(),(_vm.screen === 'loan')?_c('self-check-loan-screen',{ref:"loanScreen",on:{"closed":function($event){_vm.screen = ''}}}):_vm._e(),(_vm.screen === 'return')?_c('self-check-return-screen',{ref:"returnScreen",on:{"closed":function($event){_vm.screen = ''}}}):_vm._e(),(_vm.screen === 'scan')?_c('self-check-scan-screen',{ref:"scanScreen",on:{"closed":function($event){_vm.screen = ''},"open-loan":() => {
          _vm.$refs.scanScreen.hide(() => {
            _vm._openScreen({ screenName: 'loan' })
          });
        },"find-by-tag":(tag) => {
          _vm.$refs.scanScreen.hide(() => {
            _vm._openScreen({ screenName: 'search' }, () => {
              _vm.$refs.searchScreen.find(tag);
            });
          });
        }}}):_vm._e(),(_vm.screen === 'generic')?_c('generic-screen',{ref:"genericScreen",attrs:{"title":_vm._get(_vm.screenData, 'title'),"slides":_vm._get(_vm.screenData, 'slides'),"backgrounds":_vm._get(_vm.screenData, 'backgrounds')},on:{"closed":function($event){_vm.screen = ''}}}):_vm._e(),(_vm.screen === 'inspiration')?_c('inspiration-screen',{ref:"inspirationScreen",attrs:{"request":_vm._get(_vm.screenData, 'request', []),"items":_vm._get(_vm.screenData, 'items', []),"tags":_vm._get(_vm.screenData, 'tags', [])},on:{"closed":function($event){_vm.screen = ''},"search":function($event){return _vm._openScreen({ screenName: 'search' })},"find-by-tag":(tag) => {
          _vm.$refs.inspirationScreen.hide(() => {
            _vm._openScreen({ screenName: 'search' }, () => {
              _vm.$refs.searchScreen.find(tag);
            });
          });
        }}}):_vm._e(),(_vm.screen === 'map')?_c('map-screen',{ref:"mapScreen",attrs:{"view-id":_vm._get(_vm.screenData, 'view'),"mark-id":_vm._get(_vm.screenData, 'markId'),"color":_vm._get(_vm.screenData, 'rawSlideData.settings.backgroundColor')},on:{"closed":function($event){_vm.screen = ''}}}):_vm._e()],1),_c('template',{slot:"footer"},[_c('easyscreen-carousel',{ref:"carousel",staticClass:"easyscreen-menu--navigation",attrs:{"layout-columns":_vm._getCarouselColumns(),"layout-rows":1,"step":_vm._getCarouselBoundaries().step,"position-offset":_vm._getCarouselBoundaries().offset,"position-limit-left":_vm._getCarouselBoundaries().limitLeft,"position-limit-right":_vm._getCarouselBoundaries().limitRight,"optimization":false},on:{"drag":(offset) => { _vm.buttonsOpacity = _vm._getButtonsOpacity(offset) },"dragging-finished":(offset) => { _vm.buttonsOpacity = _vm._getButtonsOpacity(offset) }}},_vm._l((_vm.filteredButtons),function(button,index){return _c('div',{key:`${ index }_${ button.label }`,staticClass:"easyscreen-menu--navigation-button",style:({ opacity: _vm.buttonsOpacity[index] }),on:{"click":() => _vm._openScreen(button)}},[_c('div',{staticClass:"easyscreen-menu--navigation-button_icon"},[(button.type === 'font-awesome')?_c('span',{staticClass:"font-icon"},[_c('i',{class:button.icon})]):_c('img',{attrs:{"src":button.icon,"draggable":"false"}})]),_c('div',{staticClass:"easyscreen-menu--navigation-button_label"},[_vm._v(" "+_vm._s(button.label)+" ")])])}),0)],1)],2),_c('screen-control-safety-mode')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }