<template>
  <div
    :class="[
      'easyscreen-game',
      { 'easyscreen-game_game-screen': isGameScreen }
    ]"
    :style="{
      backgroundImage: backgroundImage ? `url('${ backgroundImage }')` : null
    }"
  >
    <h1 class="easyscreen-game--title">
      <img
        v-if="backButtonImage"
        class="easyscreen-game--back-button"
        draggable="false"
        :src="backButtonImage"
        @click="_popScreen"
      >

      <span class="easyscreen-game--title-text">
        {{ screenTitle }}
      </span>
    </h1>

    <div
      :class="[
        'easyscreen-game--content',
        { 'easyscreen-game--content_game-screen': isGameScreen }
      ]"
    >
      <div
        v-if="isSplashScreen"
        class="easyscreen-game--splash-screen"
        @click="_hideSplashScreen"
      >
        <img
          class="easyscreen-game--splash-screen-image"
          :src="splashImage"
        >
      </div>

      <div
        v-if="isNavigationScreen"
        class="easyscreen-game--navigation"
      >
        <div
          v-for="(element, elementIndex) in screenData"
          :key="element.title + elementIndex"
          class="easyscreen-game--navigation-element"
          @click="() => {
            if ('games' in element) {
              _pushScreen(`[${ elementIndex }].games`);
            } else {
              _pushScreen(`[${ elementIndex }]`);
            }
          }"
        >
          <div
            class="easyscreen-game--navigation-element-image"
            :style="{
              backgroundImage: element.buttonImage ? `url('${ element.buttonImage }')` : null
            }"
          ></div>
          <div class="easyscreen-game--navigation-element-title">
            {{ element.title }}
          </div>
        </div>
      </div>
      <div
        v-else-if="isGameScreen"
        class="easyscreen-game--game"
      >
        <iframe
          class="easyscreen-game--game-iframe"
          frameborder="0"
          :src="screenData.url"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<style src="./game.less" lang="less"></style>

<script>
  import { toPath, get } from "lodash";

  export default {
    name: "easyscreen-game",
    props: {
      /* Front page title. */
      title: String,
      /* Splash image shown on first mount and on enabled standby.  */
      splashImage: String,
      /* background image, by default black color. */
      backgroundImage: String,
      /* The list of game collections (groups). */
      collection: Array
    },
    computed: {
      /**
       * Getter for splash screen flag.
       *
       * @returns {Boolean}
       */
      isSplashScreen() {
        return this.showSplashScreen && this.splashImage;
      },
      /**
       * Getter for navigation screen flag.
       *
       * @returns {Boolean}
       */
      isNavigationScreen() {
        return Array.isArray(this.screenData);
      },
      /**
       * Getter for game screen flag.
       *
       * @returns {Boolean}
       */
      isGameScreen() {
        return !Array.isArray(this.screenData);
      },
      /**
       * Getter for back button icon at the title. Nothing for main screen, arrow for
       * navigation screen and X for game screen.
       */
      backButtonImage() {
        if (this.screenPath.length === 0) {
          return;
        }

        if (this.isGameScreen) {
          return "/images/game/times-icon.png";
        }

        return "/images/game/arrow-left-icon.png";
      },
      /**
       * Geter for normalized lodash path: ["collection.games"] -> ["collection", "games"];
       *
       * @returns {String[]}
       */
      normalizedScreenPath() {
        return this.screenPath.reduce((path, chunk) => path.concat(toPath(chunk)), []);
      },
      /**
       * Getter for screen title.
       *
       * @retruns {String}
       */
      screenTitle() {
        if (this.screenPath.length === 0) {
          return this.title;
        }

        if (this.isNavigationScreen) {
          return this.screenDataParent.title;
        }

        return this.screenData.title;
      },
      /**
       * Getter for parent object for current screen from initial data by the normalized path.
       *
       * @returns {Object}
       */
      screenDataParent() {
        if (this.normalizedScreenPath.length === 0) {
          return this.collection;
        }

        return get(this.collection, this.normalizedScreenPath.slice(0, -1));
      },
      /**
       * Getter for current screen data from initial data by the normalized path.
       *
       * @returns {Object}
       */
      screenData() {
        if (this.normalizedScreenPath.length === 0) {
          return this.collection;
        }

        return get(this.collection, this.normalizedScreenPath);
      }
    },
    data() {
      return {
        showSplashScreen: this.splashImage,
        screenPath: []
      };
    },
    methods: {
      /**
       * Show the screen by relative path from last pushed screen.
       *
       * @param {String} path - The relative path from current screen.
       */
      _pushScreen(path) {
        this.screenPath = this.screenPath.concat(path);
      },
      /**
       * Hides the current screen and shows the previous one, or default (root) screen.
       */
      _popScreen() {
        this.screenPath = this.screenPath.slice(0, -1);
      },
      /**
       * Show the splash screen and reset the active screen to root.
       */
      _showSplashScreen() {
        this.showSplashScreen = true;
        this.screenPath = [];
      },
      /**
       * Hide the splash screen.
       */
      _hideSplashScreen() {
        this.showSplashScreen = false;
      }
    },
    screenStandby() {
      this._showSplashScreen();
    }
  };
</script>
