<template>
  <div :class="[
    'presentation-set-title',
    `presentation-set-title_scale-${ $easyscreenScale }`,
    orientation
  ]">
    {{ title }}
  </div>
</template>

<style src="./presentation-set-title.less" lang="less"></style>

<script>
  import orientationMixin from "../core/mixins/orientation.js";

  export default {
    name: "presentation-set-title",
    mixins: [orientationMixin],
    props: {
      title: String
    }
  };
</script>
