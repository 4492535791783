<template>
  <easyscreen-button-group gap="auto" class="keyboard-email-shortcuts">
    <easyscreen-button
      class="keyboard-fullsize--key keyboard-email-shortcuts--key"
      color="light-gray"
      v-for="shortcut in shortcuts"
      :key="shortcut"
      @click.native="(e) => { $emit('selected', shortcut) }"
    >
      {{ shortcut }}
    </easyscreen-button>
  </easyscreen-button-group>
</template>

<style lang="less" src="./fullsize.less"></style>
<style lang="less" src="./email-shortcuts.less"></style>
<script>
  import EasyscreenButton from "../button/button.vue";
  import EasyscreenButtonGroup from "../button/button-group.vue";

  const shortcuts = () => {
    return ["@", ".dk", ".com", "@gmail.com", "@hotmail.com", "@yahoo.com", "@outlook.com"];
  };

  export default {
    name: "keyboard-email-shortcuts",
    components: {
      "easyscreen-button": EasyscreenButton,
      "easyscreen-button-group": EasyscreenButtonGroup
    },
    data() {
      return {
        shortcuts: shortcuts()
      };
    }
  };
</script>
