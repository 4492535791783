/**
 * @file The core class for connect to es-linux-apps (https://github.com/inleadmedia/es-linux-apps).
 */

import { promisify } from "util";
import moment from "moment";
import capitalize from "capitalize";
import EventEmitter from "eventemitter3";
import { interval } from "d3-timer";
import { isFunction, get, omit, clone } from "lodash";

import l10n from "@/lib/localization/localization.js";
import { TimeoutError, InternalError, ValidationError } from "../lib/errors";

/**
 * The interface for es-linux-apps.
 * @class FriendlyFrank
 * @augments EventEmitter3
 * @listens FriendlyFrank~action:printer-handshake
 * @listens FriendlyFrank~action:barcodescanner-handshake
 * @listens FriendlyFrank~action:application-handshake
 *
 * @param {object} options - The connection options.
 * @param {String} [options.uri=lodash.get(window.ESCONFIG, "scanner.ws", "ws://localhost:9211")] - Connection uri (like: `ws://localhost:9211`).
 * @param {Number} [options.reopenTimeout=2000] - The reconnection interval in ms. Used if the connection is not established or lost.
 * @param {Number} [options.reopenAttempts=5] - Amonut of total reconnections in row.
 * @param {Boolean} [options.autoConnect=true] - Connecting on instantiate.
 *
 * @returns {FriendlyFrank} - instace of FriendlyFrank class.
 */
export default class FriendlyFrank extends EventEmitter {
  /**
   * Get the notification for staff by the error messages.
   * @static
   *
   * @param {Object} data - The data of templating.
   * @param {Error[]} data.messages - The error messages.
   * @param {String} [data.tmid] - The teamviewer id.
   *
   * @returns {Object} notificaiton
   * @returns {String} notificaiton.title - The title of notificaiton.
   * @returns {String} notificaiton.message - The message of notificaiton.
   */
  static getStaffNotification(data, config) {
    const title = get(config, "printerErrors.staffMessage.title")
      || l10n("[ES_NOTIFICATION] {{screenName}} - {{messageType}}.");
    const message = get(config, "printerErrors.staffMessage.message")
      || l10n("FF with TMID {{tmid}} and screen name {{screenName}}[{{screenId}}] receives the next messages: {{messages}}.");

    return FriendlyFrank.getNotification({ title, message }, {
      tmid: data.tmid || "",
      messages: data.messages
    });
  }

  /**
   * Applies the data to message template.
   * @static
   *
   * @param {NotificationMessage} message - The template of message and title.
   * @param {Object} data - The data of template.
   * @param {String} [data.tmid] - The teamviewer id.
   * @param {String[]} [data.messages] - List of error messages.
   * @param {Boolean} [data.withDefaultMessage=true] - Allow default message if the `data.messages` are empty.
   *
   * @returns {NotificationMessage} The message from template.
   */
  static getNotification(message, data) {
    data = clone(data);
    data.tmid = data.tmid || "";
    if (!Array.isArray(data.messages)) {
      data.messages = [data.messages];
    }

    data.messages = data.messages.filter(Boolean);

    if ((!data.messages || data.messages.length == 0) && !data.withDefaultMessage) {
      return null;
    }

    data.screenName = get(window.screenControl, "data.screenData[0].title") || l10n("Unknown");
    data.screenId = get(window.location, "_query.id");

    data.date = moment().format("DD.MM.YYYY");
    data.time = moment().format("HH:mm");

    var uniqueMessageCodes = [];
    data.messages.forEach(function(message) {
      if (uniqueMessageCodes.indexOf(message.code) === -1) {
        uniqueMessageCodes.push(message.code);
      }
    });

    if (uniqueMessageCodes.length === 1) {
      data.messageType = uniqueMessageCodes[0].toLowerCase().replace(/_/, " ");
    } else {
      data.messageType = l10n("combined error");
    }

    data.messages = data.messages.map(function(message) {
      return "[" + message.code + "] " + message.message;
    }).join(", ");

    return {
      title: capitalize(FriendlyFrank._templatingMessage(message.title || "", data).trim()),
      message: FriendlyFrank._templatingMessage(message.message, data).trim()
    };
  }

  /**
   * Applies the data to string template.
   * @static
   *
   * @param {String} string - The string template where {{name}} represents the data property which should be replaced.
   * @param {Object} data - The template data.
   *
   * @returns {String} The string with replaced {{name}} substrings.
   */
  static _templatingMessage(string, data) {
    Object.keys(data).forEach(function(key) {
      var match = new RegExp("\\{\\{" + key + "\\}\\}", "");
      string = string.replace(match, data[key]);
    });

    return string;
  }

  constructor(options) {
    options = options || {};
    super();

    const uri = options.uri;
    if (!uri) {
      console.warn("`uri` for RFIDScanner must be specified!", this);
    }

    if (FriendlyFrank._instances && FriendlyFrank._instances[uri]) {
      return FriendlyFrank._instances[uri];
    }

    this.easyscreenConfig = options.easyscreenConfig;
    this.requestLMS = options.request;
    this.uri = uri;
    this.reopenTimeout = options.reopenTimeout || 2000;
    this.reopenAttempts = options.reopenAttempts || 5;
    this._reopenInterval = null;
    this._reopenAttempt = 0;
    this._requestCallbacks = {};
    this._handshake = {};
    this._socket = null;

    FriendlyFrank._instances = FriendlyFrank._instances || {};
    FriendlyFrank._instances[uri] = this;

    if (options.autoConnect !== false) {
      this.connect();
    }

    this.on("action:printer-handshake", this._saveHandshake.bind(this, "action:printer-handshake"));
    this.on("action:barcodescanner-handshake", this._saveHandshake.bind(this, "action:barcodescanner-handshake"));
    this.on("action:application-handshake", this._saveHandshake.bind(this, "action:application-handshake"));
  }

  /**
   * Wrapper for EventEmitter3.on which re-emits the handshake on subscription.
   *
   * @param {(String|Symbol)} event - The event name.
   * @param {Function} listener - The listener function.
   * @param {*} [context=this] - The context to invoke the listener with.
   *
   * @returns {EventEmitter} `this`.
   */
  on(event, listener, context) {
    super.on(event, listener, context);
    if (this._handshake[event]) {
      this.emit(event, this._handshake[event]);
    }
  }

  async _notifyStaffByHardwareStatus(hardwareStatus) {
    const emails = get(window.screenControl, "data.screenOptions.notifications.email_notification") || [];
    if (!Array.isArray(emails) || emails.length === 0) {
      return;
    }

    let devices = Object.keys(hardwareStatus).filter(device => hardwareStatus[device] === "offline");

    try {
      const notificationStatus = await this.requestAsync({
        action: "hardware-notification-status"
      });

      devices = devices.filter(device => {
        return device in notificationStatus && !notificationStatus[device].sending && !notificationStatus[device].sent;
      });

      if (devices.length === 0) {
        return;
      }

      await this.requestAsync({
        action: "hardware-status_sending",
        devices: devices
      });

      const notification = FriendlyFrank.getStaffNotification({
        messages: devices.map(device => {
          return {
            code: "CRITICAL_ERROR",
            message: "Following device got critical error: '" + device + "'. The attention required."
          };
        })
      }, this.easyscreenConfig);

      await this.requestLMS.email({
        to: emails.join(","),
        html: notification.message,
        subject: notification.title
      });

      await this.requestAsync({
        action: "hardware-status_sent",
        devices: devices,
        isSent: true
      });
    } catch (error) {
      console.error("Can't send the notification for staff.", error);
      try {
        await this.requestAsync({
          action: "hardware-status_sent",
          devices: devices,
          isSent: false
        });
      } catch (error) {
        console.error("Can't reset the error status for devices", devices, error);
      }
    }
  }

  /**
   * Get the status of the hardware (disabled, online, offline).
   * @async
   *
   * @returns {Object} status - The status of the hardware.
   */
  hardwareStatus() {
    return new Promise((resolve, reject) => {
      let _reject = (error) => {
        if (error.hardwareStatus) {
          /*
           * Start the notification process in 30s after the error is happened.
           * That required to get the app time for restore on non critical error.
           */
          setTimeout(() => {
            this._notifyStaffByHardwareStatus(error.hardwareStatus);
          }, 30000);
        }

        console.error(error);
        reject(error);
      };

      this.request({
        _timeoutDuration: 500,
        action: "hardware-status"
      }, (requestError, hardwareStatus) => {
        if (requestError) {
          return _reject(requestError);
        }

        let error = {
          code: "HARDWARE_ERROR",
          hardwareStatus: hardwareStatus
        };

        if (hardwareStatus.RFIDScanner === "offline") {
          error.displayTitle = "Scanner is unavailable";
          error.displayMessage = "We are not able to scan materials for you at the moment. Ask the library staff for help or look anything else.";
          error.message = "RFID scanner is unavailable.";
          error.isFlowBlocker = true;
        } else if (window.ESCONFIG.enable.selfcheckViaBarcode && hardwareStatus.barcodeScanner === "offline") {
          error.displayTitle = "Scanner is unavailable";
          // eslint-disable-next-line
          error.displayMessage = "We are not able to scan materials for you at the moment. Ask the library staff for help or look anything else.";
          error.message = "Barcode scanner is unavailable.";
          error.isFlowBlocker = true;
        } else if (window.ESCONFIG.enable.print && hardwareStatus.printer === "offline") {
          error.displayTitle = "Printer is unavailable";
          error.displayMessage = "We are not able to print a receipt for you at the moment. Are you sure you wanna continue?";
          error.message = "Printer is unavailable.";
          error.isFlowBlocker = false;
        } else if (window.ESCONFIG.enable.cprScanner && hardwareStatus.barcodeScanner === "offline") {
          error.message = "Barcode scanner is unavailable.";
          error.isFlowBlocker = false;
        }

        if (error.message) {
          return _reject(new InternalError(error));
        }

        resolve(hardwareStatus);
      });
    });
  }

  requestAsync() {
    return promisify(this.request).apply(this, arguments);
  }

  /**
   * Callback based request of es-linux-app using websockets.
   *
   * @param {Object} data - The data to send.
   * @param {Boolean} [data._withTimeout=true] - Set the timeout for response.
   * @param {Number} [data._timeoutDuration=10000] - The duration of response timeout in ms.
   * @param {Function} callback - Request callback `(err, data) => {}`
   */
  request(data, callback) {
    if (!callback) {
      return new Promise((resolve, reject) => {
        this.request(data, (error, response) => {
          if (error) {
            return reject(error);
          }

          resolve(response);
        });
      });
    }

    if (!this._socket) {
      return callback(new InternalError({ message: "Socket not initialized." }));
    }

    if (typeof data !== "object" || !data) {
      callback(new ValidationError({ message: "Data should be a JS object" }));
    }

    data.callbackId = Date.now() + "-" + Math.random();
    this._requestCallbacks[data.callbackId] = callback;

    if (data._withTimeout !== false) {
      this._requestCallbacks[data.callbackId].timeout = setTimeout(() => {
        delete this._requestCallbacks[data.callbackId];
        callback(new TimeoutError({ message: "Request to scanner timedout" }));
      }, data._timeoutDuration || 10000);
    }

    this._socket.send(JSON.stringify(omit(data, ["_withTimeout", "_timeoutDuration"])));
  }

  /**
   * Opens the socket connection by passed uri.
   */
  connect() {
    if (this._socket || !this.uri) {
      return;
    }

    this._socket = new WebSocket(this.uri);
    this._socket.onopen = this._onopen.bind(this);
    this._socket.onerror = this._onerror.bind(this);
    this._socket.onclose = this._onclose.bind(this);
    this._socket.onmessage = this._onmessage.bind(this);
    this._requestCallbacks = {};
  }

  /**
   * Closes the web socket connection.
   */
  disconnect(options) {
    options = options || {};

    if (this._reopenInterval && options.isReopen !== true) {
      this._reopenInterval.stop();
      this._reopenInterval = null;
    }

    Object.keys(this._requestCallbacks).forEach(callbackId => {
      this._requestCallbacks[callbackId](new InternalError({ message: "Socket are closed." }));
    });

    this._requestCallbacks = {};
    this._handshake = {};

    if (!this._socket) {
      return;
    }

    this._socket.onopen = null;
    this._socket.onerror = null;
    this._socket.onclose = null;
    this._socket.onmessage = null;
    this._socket.close();

    this._socket = null;
  }

  /**
   * Closes connection and destroys the instance.
   */
  destroy() {
    this.disconnect();

    if (FriendlyFrank._instances) {
      delete FriendlyFrank._instances[this.uri];
    }
  }

  /**
   * Saves the message of a-linux-app as handshake to repeat on new subscription.
   *
   * @param {String} name - The event(handshake) name.
   * @param {Object} message - The event data.
   */
  _saveHandshake(name, message) {
    this._handshake[name] = message;
  }

  /**
   * The handshake event of es-linux-apps printer module.
   *
   * @event FriendlyFrank#action:printer-handshake
   * @type {Object}
   * @property {String[]} features - List of supported features by printer: "printing" and\or "printer-status".
   * @property {Object} config - The es-linux-app config required for notifications.
   * @property {String} config.tmid - The teamviewer id installed for PC with es-linux-app.
   */
  /**
   * The handshake event of es-linux-apps barcodescanner module.
   *
   * @event FriendlyFrank#action:barcodescanner-handshake
   * @type {Object}
   * @property {String} type - Type of the barcode scanner: serialport or evtest (keyboard emulator).
   */
  /**
   * The handshake event of es-linux-apps.
   *
   * @event FriendlyFrank#action:application-handshake
   * @type {Object}
   * @property {String[]} modules - List of mosules which should be initialized (any filename without extention from ./modules folder).
   * @property {(String|Number)} version - The version of the es-linux-apps.
   */
  /**
   * Scanning via barcode event.
   *
   * @event FriendlyFrank#action:scanned-barcode
   * @type {Object}
   * @property {String} barcode - Scanned data.
   */
  /**
   * Device error.
   * @typedef {Object} DeviceError
   *
   * @property {String} code - The error code: NOTIFICATION, WARNING and CRITICAL_ERROR.
   * @property {String} message - The error message.
   * @property {Boolean} sent - Message is sent to staff. Is not provided when false.
   * @property {Boolean} sending - Message is sending to staff. Is not provided when false.
   */
  /**
   * Printer error event.
   *
   * @event FriendlyFrank#action:device-error
   * @type {Object}
   * @property {String} device - The type of device where error is happend.
   * @property {DeviceError[]} messages - List of device errors.
   */
  /**
   * New scanned material via rfid scanner.
   *
   * @event FriendlyFrank#action:added-books
   * @type {Object}
   * @property {String[]} books - List of material ids placed to scanner.
   * @property {RFIDScannerInventory} inventory - The scanner inventory.
   */
  /**
   * Removed previously scanned material via rfid scanner.
   *
   * @event FriendlyFrank#action:removed-books
   * @type {Object}
   * @property {String[]} books - List of material ids removed from scanner.
   * @property {RFIDScannerInventory} inventory - The scanner inventory.
   */
  /**
   * New scanned material via rfid scanner (for sorting bin scanner).
   *
   * @event FriendlyFrank#action:sorting-bin:removed-materials
   * @type {Object}
   * @property {String[]} materials - List of material ids placed to scanner.
   */
  /**
   * Removed previously scanned material via rfid scanner (for sorting bin scanner).
   *
   * @event FriendlyFrank#action:sorting-bin:added-materials
   * @type {Object}
   * @property {String[]} materials - List of material ids removed from scanner.
   */

  /**
   * Handler of web socket messages from es-linux-app.
   * Re-emits messages as events or calling registered callbacks.
   * @fires FriendlyFrank#action:printer-handshake
   * @fires FriendlyFrank#action:barcodescanner-handshake
   * @fires FriendlyFrank#action:application-handshake
   * @fires FriendlyFrank#action:scanned-barcode
   * @fires FriendlyFrank#action:device-error
   * @fires FriendlyFrank#action:added-books
   * @fires FriendlyFrank#action:removed-books
   * @fires FriendlyFrank#action:sorting-bin:removed-materials
   * @fires FriendlyFrank#action:sorting-bin:added-materials
   *
   * @param {Object} event - The native web socket message event.
   */
  _onmessage(event) {
    let message = JSON.parse(event.data);
    let callbackId = message.callbackId;
    if (message.data) {
      // ES linux common app.
      message = message.data;
    }

    if (callbackId) {
      let callback = this._requestCallbacks[callbackId];
      delete this._requestCallbacks[callbackId];

      if (message.callbackId) {
        // For windows scanner.
        delete message.callbackId;
      }


      if (isFunction(callback)) {
        if (callback.timeout) {
          clearTimeout(callback.timeout);
          callback.timeout = null;
        }

        if (message.error) {
          callback(message.error);
        } else {
          callback(null, message);
        }
      }

      return;
    }

    this.emit("message", message);
    if (message.action) {
      this.emit(`action:${ message.action }`, message);
    }
  }

  /**
   * Handler of web socket open event. Used for stop reconnection loop.
   */
  _onopen() {
    this._reopenAttempt = 0;

    if (this._reopenInterval) {
      this._reopenInterval.stop();
      this._reopenInterval = null;
    }
  }

  /**
   * Starts the reconnection loop.
   */
  _reopen() {
    let reopen = () => {
      if (!this._reopenInterval) {
        return;
      }

      if (this._reopenAttempt >= this.reopenAttempts) {
        return this._reopenInterval.stop();
      }

      this._reopenAttempt += 1;
      this.disconnect({ isReopen: true });
      this.connect();
    };

    reopen();
    this._reopenInterval = interval(reopen, this.reopenTimeout);
  }
  /**
   * Handler of web socket error event. Used for start reconnection loop.
   */
  _onerror(error) {
    console.error("'Friendly Frank' socket error:", error);
    this._reopen();
  }
  /**
   * Handler of web socket close event. Used for start reconnection loop.
   * Will not be called on closing via this.disconnect() or this.destroy() methods.
   */
  _onclose(event) {
    console.warn("'Friendly Frank' socket closed.", event);
    console.info("'Friendly Frank' reconnection initialized.");
    this._reopen();
  }
}
