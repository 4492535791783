<template>
  <component
    :is="isWelcomeScreenLight && !$easyscreenIsMobile ? 'scrollable' : 'div'"
    :class="[
      'text-and-image',
      `text-and-image_align-${ imageAlign }`,
      `text-and-image_design-${ design }`,
      subType,
      skin
    ]"
    :style="{ '--text-and-image-special-color': specialColor }"
    max-height="100%"
  >
    <div
      v-if="fixedImage"
      class="text-and-image--image"
      :style="{
        backgroundImage: !isWelcomeScreen ? `url('${ fixedImage }')` : null
      }"
    >
      <qr-code
        v-if="qrCode && isWelcomeScreenLight"
        class="text-and-image--qr-code"
        :url="qrCode"
        :size="80"
      />
    </div>
    <h1
      v-if="title"
      class="text-and-image--title"
    >
      <i v-if="design === 'light'" class="fal fa-arrow-down text-and-image--title-icon"></i>
      {{ title }}
    </h1>
    <h2
      v-if="subheadline"
      class="text-and-image--subtitle"
    >
      {{ subheadline }}
    </h2>

    <img
      v-if="fixedImage && isWelcomeScreen"
      class="text-and-image--image"
      :src="fixedImage"
    >

    <img
      v-if="fixedImage && design === 'light'"
      ref="image"
      class="text-and-image--image-reference"
      :src="`/proxy?url=${ fixedImage }`"
      crossOrigin="annonimus"
    >

    <component
      :is="isWelcomeScreenLight ? 'div' : 'scrollable'"
      v-if="text"
      class="text-and-image--text-wrapper"
    >
      <div class="text-and-image--text ck-editor" v-html="text"></div>
    </component>
  </component>
</template>

<style src="../core/ck-editor.less" lang="less"></style>
<style src="./text-and-image.less" lang="less"></style>

<script>
  import getDominantImageColor from "@/lib/utils/get-dominant-image-color.js";
  import qrCode from "../qr-code/qr-code.vue";
  import Scrollable from "../core/scrollable/scrollable.vue";

  export default {
    name: "text-and-image",
    props: {
      image: String,
      title: String,
      text: String,
      subheadline: String,
      qrCode: String,
      imageAlign: {
        type: String,
        default: "width",
        validator: _imageAlign => ["width", "height"].includes(_imageAlign)
      },
      subType: String,
      skin: String,
      /* The global reskin for materials list. */
      design: {
        type: String,
        default: "classic",
        /*default: "light",*/
        validator: _design => ["classic", "light"].includes(_design)
      },
      defaultSpecialColor: {
        type: String,
        default: "#f7f7f7"
      }
    },
    computed: {
      fixedImage: {
        get() {
          if (this.image && this.image.includes("dummy.gif")) {
            return "";
          }

          return this.image;
        }
      },
      isWelcomeScreen() {
        return this.subType && this.subType.includes("welcome-screen-modifer");
      },
      isWelcomeScreenLight() {
        return this.subType && this.subType.includes("welcome-screen-light-modifer");
      },
      specialColor() {
        if (this.design !== "light" || !this.fixedImage) {
          return null;
        }

        return this.customSpecialColor || this.defaultSpecialColor;
      }
    },
    data() {
      return {
        customSpecialColor: null
      };
    },
    async mounted() {
      if (this.design === "light") {
        this.customSpecialColor = await getDominantImageColor(this.$refs.image);
      }
    },
    components: {
      "scrollable": Scrollable,
      "qr-code": qrCode
    }
  };
</script>
