<template>
  <div class="materials-scanner"
  >
    <loan-screen
      ref="loan"
      v-if="_screenIsOpened('loan')"
      :with-printer="withPrinter"
      :with-barcode-scanner="withBarcodeScanner"
      @closed="_screenClosed('loan')"
    />
    <return-screen
      ref="return"
      v-if="_screenIsOpened('return')"
      :with-printer="withPrinter"
      @closed="_screenClosed('return')"
    />
    <scan-screen
      ref="scan"
      v-if="_screenIsOpened('scan')"
      @closed="_screenClosed('scan')"
      @open-loan="() => $emit('open-loan')"
      @find-by-tag="(tag) => $emit('find-by-tag', tag)"
    />

    <modal-alert ref="alertModal" />
    <modal-confirm ref="confirmModal" />
  </div>
</template>

<script>
  import ModalAlert from "../core/modal/alert.vue";
  import ModalConfirm from "../core/modal/confirm.vue";
  import LoanScreen from "./screens/loan.vue";
  import ReturnScreen from "./screens/return.vue";
  import ScanScreen from "./screens/scan.vue";

  import { isFunction } from "lodash";
  import l10n from "@/lib/localization/localization.js";

  export default {
    name: "materials-scanner",
    data() {
      return {
        availableScreens: ["loan", "return", "scan"],
        activeScreens: [],
        withPrinter: true,
        withBarcodeScanner: true
      };
    },
    computed: {
      /* Proxy for modal isShown variable. */
      isShown: {
        cache: false,
        get() {
          let loanIsShown = this.$refs.loan && this.$refs.loan.isShown;
          let returnIsShown = this.$refs.return && this.$refs.return.isShown;
          let scanIsShown = this.$refs.scan && this.$refs.scan.isShown;

          return loanIsShown || returnIsShown || scanIsShown;
        }
      }
    },
    methods: {
      /**
       * Shows the selected material scanner screen.
       * @async
       *
       * @param {String} screenName - Name of screen to open, one of: "loan", "return", "scan".
       * @param {Function} callback - Callback function, will be called immedeatly if the screen is opend or after when screen will be opened.
       */
      async show(screenName, callback) {
        if (this._screenIsOpened(screenName)) {
          return isFunction(callback) && callback();
        }

        try {
          await this._checkHardwareStatus(screenName);
        } catch (error) {
          console.error("Hardware error", error);
          return;
        }

        this.activeScreens.push(screenName);
        await this.$nextTick();

        if (!this.$refs[screenName]) {
          return this._screenClosed(screenName);
        }

        this.$refs[screenName].show(callback);
      },
      /**
       * Hides the selected material scanner screen.
       *
       * @param {String} screenName - Name of screen to close, one of: "loan", "return", "scan".
       * @param {Function} callback - Callback function, will be called immedeatly if the screen is closed or after when screen will be closed.
       */
      hide(screenName, callback) {
        if (!this._screenIsOpened(screenName)) {
          return isFunction(callback) && callback();
        }

        if (this.$refs[screenName]) {
          return this.$refs[screenName].hide(callback);
        }

        this._screenClosed(screenName);
        isFunction(callback) && callback();
      },
      /**
       * Checks if the selected screen is alrady shown.
       *
       * @param {String} screenName - Name of screen to check, one of: "loan", "return", "scan".
       *
       * @returns {Boolean} `true` - the screen is opened, `false` otherwise.
       */
      _screenIsOpened(screenName) {
        return this.activeScreens.includes(screenName);
      },
      /**
       * The handler of close screen event.
       * @fires materials-scanner#screen-closed
       *
       * @param {String} screenName - Name of closed screen, one of: "loan", "return", "scan".
       */
      _screenClosed(screenName) {
        if (!this._screenIsOpened(screenName)) {
          return;
        }

        this.activeScreens = this.activeScreens.filter(_screenName => _screenName !== screenName);
        /**
         * Event of closing any material scanner screen.
         *
         * @event materials-scanner#screen-closed
         * @type {String}
         */
        this.$emit("screen-closed", screenName);
      },
      /**
       * Check the hardware status and block\notify user about error if any.
       *
       * @param {String} screenName - The name of the screen which should be shown (loan, return, scan).
       */
      async _checkHardwareStatus(screenName) {
        this.withPrinter = true;
        this.withBarcodeScanner = true;

        function disableFeatures(hardwareStatus) {
          if (!hardwareStatus) {
            return;
          }

          if (hardwareStatus.printer !== "online") {
            this.withPrinter = false;
          }

          if (hardwareStatus.barcodeScanner !== "online") {
            this.withBarcodeScanner = false;
          }
        }

        return new Promise((resolve, reject) => {
          /* The 'soket' - fallback for wrong option naming for old clients. */
          if (["socket", "soket"].includes(this.$easyscreenConfig.get("scanner.type"))) {
            return resolve();
          }

          this.$friendlyFrankCore.hardwareStatus().then((hardwareStatus) => {
            disableFeatures.call(this, hardwareStatus);
            resolve();
          }).catch(error => {
            if (error.isFlowBlocker) {
              this.$refs.alertModal.show({
                title: l10n(error.displayTitle),
                message: l10n(error.displayMessage)
              });

              return reject(error);
            }

            disableFeatures.call(this, error.hardwareStatus);

            if (screenName === "scan") {
              /*
               * The scan screen can working fine even if the barcode and printer is broken.
               * But in case when the barcode used for self check the condition above will be called before.
               */
              return resolve();
            }

            if (error.displayTitle && error.displayMessage) {
              return this.$refs.confirmModal.show({
                title: l10n(error.displayTitle),
                message: l10n(error.displayMessage),
                callback: (_, type) => {
                  if (type === "ok") {
                    return resolve();
                  }

                  reject(error);
                }
              });
            }

            resolve();
          });
        });
      }
    },
    components: {
      "modal-alert": ModalAlert,
      "modal-confirm": ModalConfirm,
      "loan-screen": LoanScreen,
      "return-screen": ReturnScreen,
      "scan-screen": ScanScreen
    }
  };
</script>
