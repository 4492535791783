var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
  'node-list-carousel-krsbib-slide',
  `node-list-carousel-krsbib-slide_${ _vm.type }`,
  {
    'node-list-carousel-krsbib-slide_transparent': _vm.showBackground,
    'node-list-carousel-krsbib-slide_top-slide-element': _vm.elementRow === 0,
    'node-list-carousel-krsbib-slide_bottom-slide-element': _vm.elementRow === (_vm.slideRows - 1),
    'node-list-carousel-krsbib-slide_with-delimiter': _vm.withSlideDelimiter
  }
]},[_c('div',{staticClass:"node-list-carousel-krsbib-slide--wrapper"},[_c('div',{staticClass:"node-list-carousel-krsbib-slide--meta"},[(_vm.isEvent)?_c('div',[_c('div',{staticClass:"node-list-carousel-krsbib-slide--calendar"},[_c('div',{staticClass:"node-list-carousel-krsbib-slide--calendar-day"},[_vm._v(" "+_vm._s(_vm.calendarDay)+" ")]),_c('div',{staticClass:"node-list-carousel-krsbib-slide--calendar-month"},[_vm._v(" "+_vm._s(_vm.calendarMonth)+" ")])]),(_vm.date)?_c('div',{staticClass:"node-list-carousel-krsbib-slide--date"},[_c('i',{staticClass:"fal fa-clock"}),_vm._v(" "+_vm._s(_vm.formattedDate)+" ")]):_vm._e(),(_vm.location)?_c('div',{staticClass:"node-list-carousel-krsbib-slide--location"},[_c('i',{staticClass:"fal fa-home-lg-alt"}),_vm._v(" "+_vm._s(_vm.location)+" ")]):_vm._e(),(_vm.price)?_c('div',{staticClass:"node-list-carousel-krsbib-slide--price"},[_c('i',{staticClass:"fal fa-info-circle"}),_vm._v(" "+_vm._s(_vm.price)+" ")]):_vm._e()]):_vm._e()]),_c('div',{ref:"content",staticClass:"node-list-carousel-krsbib-slide--content"},[(_vm.teaserImage)?_c('div',{ref:"teaserImage",staticClass:"node-list-carousel-krsbib-slide--teaser-image",style:({
          backgroundImage: `url('${ _vm.teaserImage }')`
        })}):_vm._e(),_c('h1',{ref:"title",staticClass:"node-list-carousel-krsbib-slide--title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.teaser)?_c('div',{ref:"delimiter",staticClass:"node-list-carousel-krsbib-slide--content-delimiter"}):_vm._e(),_c('div',{staticClass:"node-list-carousel-krsbib-slide--teaser-wrapper"},[_c('scrollable',{attrs:{"max-height":_vm.textHeight}},[_c('div',{staticClass:"node-list-carousel-krsbib-slide--teaser ck-editor",domProps:{"innerHTML":_vm._s(_vm.teaser)}}),_c('div',{staticClass:"node-list-carousel-krsbib-slide--text ck-editor",domProps:{"innerHTML":_vm._s(_vm.text)}})])],1)])]),(_vm.withSlideDelimiter)?_c('div',{staticClass:"node-list-carousel-krsbib-slide--slide-delimiter"}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }