var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['easyscreen-select', `easyscreen-select_design-${ _vm.design }`, { 'easyscreen-select_disabled': _vm.disabled }]},[_c('input',{class:[
      'easyscreen-input',
      'easyscreen-select--preview',
      {
        focus: _vm.focused,
        disabled: _vm.disabled
      },
      _vm.inputClass
    ],attrs:{"autocomplete":"off","readonly":""},domProps:{"value":_vm.selected && (_vm.selected.label || _vm.selected)},on:{"click":_vm._showEditForm}}),_c('i',{class:['fal fa-chevron-down easyscreen-select--chevron', _vm.chevronClass]}),_c('div',{ref:"exampleOption",class:[
      'easyscreen-select--option',
      'easyscreen-select--option_selected',
      !_vm.optionStep && !_vm.activeOptionColor ? 'easyscreen-select--option_transparent' : 'easyscreen-select--option_hidden'
    ]},[_c('span',{ref:"exampleOptionText",staticClass:"easyscreen-select--option-text"})]),_c('div',{class:[
      'easyscreen-select--option',
      !_vm.defaultOptionColor ? 'easyscreen-select--option_transparent' : 'easyscreen-select--option_hidden'
    ]},[_c('span',{ref:"exampleDefaultOptionText",staticClass:"easyscreen-select--option-text"})]),_c('modal-confirm',{ref:"modal",staticClass:"easyscreen-select--modal",attrs:{"min-width":"1040px","design":_vm.design},on:{"opened":() => {
      _vm._initDragAndDrop();
      _vm.$refs.swipeExample && _vm.$refs.swipeExample.show();
    },"before-close":() => {
      _vm._destroyDragAndDrop();
      _vm._cleanupSavedSelection();
      _vm.$refs.swipeExample && _vm.$refs.swipeExample.hide();
    },"before-open":_vm._saveSelected,"ok":() => {
      if (_vm._savedSelectedIndex !== _vm.options.indexOf(_vm.selected)) {
        _vm.$emit('selected', _vm.selected)
      }
    },"cancel":_vm._restoreSelected}},[_c('template',{slot:"header"},[_vm._v(_vm._s(_vm.label))]),_c('template',{slot:"content"},[_c('swipe-example',{ref:"swipeExample",staticClass:"easyscreen-select--swipe-example",attrs:{"duration":1100}}),_c('div',{staticClass:"easyscreen-select--smooth-bottom-top"}),_c('div',{ref:"optionsWrapper",staticClass:"easyscreen-select--options-wrapper"},[_c('div',{staticClass:"easyscreen-select--options",style:({ top: -_vm.selectOffset })},_vm._l((_vm.options),function(option,index){return _c('div',{key:`${ option.value || option }-${ index }`,staticClass:"easyscreen-select--option",on:{"click":() => { if (_vm._selectionByClickAllowed()) { _vm.selectByIndex(index) } }}},[_c('span',{staticClass:"easyscreen-select--option-text",style:({ color: _vm._getOptionColor(index) })},[_vm._v(" "+_vm._s(option.label || option)+" ")])])}),0)]),_c('div',{staticClass:"easyscreen-select--smooth-bottom-edge"})],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }