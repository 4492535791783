<template>
  <popular-searches-inline
    class="popular-searches-multiline"
    :preview-amount="previewAmount"
    :max-title-length="maxTitleLength"
    full-type="none"
    @select="(tag) => $emit('select', tag)"
    @ready="() => $emit('ready')"
  />
</template>

<style src="./multiline.less" lang="less"></style>

<script>
  import PopularSearchesInline from "./inline.vue";

  export default {
    name: "popular-searches-multiline",
    props: {
      /* Amount of searches on preview. */
      previewAmount: {
        type: Number,
        default: 3
      },
      /* The maximim title length of single tag. Default uses default value of "tags-inline". */
      maxTitleLength: {
        type: Number,
        default: 60
      }
    },
    components: {
      "popular-searches-inline": PopularSearchesInline
    }
  };
</script>
