var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal-confirm',{ref:"modal",class:['search-facets-modal', 'search-single-facet-modal', _vm.orientation],attrs:{"position":_vm.position,"design":_vm.design,"cancel-options":{
    color: 'danger'
  }},on:{"before-open":(event) => _vm.$emit('before-open', event),"opened":(event) => {
    _vm.$emit('opened', event);
    _vm.$refs.swipeExample && _vm.$refs.swipeExample.show();
  },"before-close":(event) => {
    _vm.$emit('before-close', event);
    _vm.$refs.swipeExample && _vm.$refs.swipeExample.hide();
  },"closed":(event) => _vm.$emit('closed', event),"ok":(event) => { _vm.$emit('selected', this.selected); }}},[_c('template',{slot:"header"},[_c('div',{staticClass:"search-single-facet-modal--header"},[_c('span',[_c('span',[_vm._v(_vm._s(_vm.name)+" ")]),(_vm._selectedAmount() !== 0)?_c('span',[_vm._v("("+_vm._s(_vm._selectedAmount())+")")]):_vm._e()]),(_vm._selectedAmount() !== 0)?_c('easyscreen-button',{staticClass:"search-single-facet--clear-button",nativeOn:{"click":function($event){return _vm._clearSelected.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm._l10n("Clear all facets"))+" ")]):_vm._e()],1)]),_c('template',{slot:"content"},[_c('div',{staticClass:"search-single-facet"},[_c('swipe-example',{ref:"swipeExample",staticClass:"search-single-facet--swipe-example"}),_c('scrollable',{key:_vm.design + _vm.orientation,attrs:{"max-height":_vm.design === 'light' && _vm._isLandscape() ? 725 : 820}},[_c('div',{staticClass:"search-facets-list"},[_c('div',{staticClass:"search-facets-list--preview"},_vm._l(((_vm.facet || [])),function(value){return _c('div',{key:value.value,staticClass:"search-facets-list--preview-element",on:{"click":(event) => {
                /* Skip the click event by label (checkbox input). */
                if (!event.defaultPrevented) {
                  _vm._toggleFacet(value.value);
                }
              }}},[_c('easyscreen-checkbox',{staticClass:"search-facets-list--preview-checkbox",attrs:{"design":_vm.design,"checked":_vm._isSelectedFacet(value.value)}}),_c('span',{staticClass:"search-facets-list--preview-element_text"},[_vm._v(_vm._s(value.name))]),_c('span',{staticClass:"search-facets-list--preview-element_frequence"},[_vm._v("("+_vm._s(value.frequence)+")")])],1)}),0)])])],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }