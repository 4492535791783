/**
 * The point in euclidean space.
 *
 * @typedef {Object} EuclideanPoint
 *
 * @property {Number} x - The distance to point by X axis.
 * @property {Number} y - The distance to point by Y axis.
 */
/**
 * Returns the euclidean distance between the two points.
 *
 * @param {EuclideanPoint} a - The first point.
 * @param {EuclideanPoint} b - The second point.
 *
 * @returns {Number} Euclidean distance with no units.
 */
module.exports = function euclideanDistance(a, b) {
  return Math.sqrt( Math.pow(b.x - a.x, 2) + Math.pow(b.y - a.y, 2) );
};
