<template>
  <div :class="['patron-authentication-form', `patron-authentication-form_design-${ design }`]">
    <div class="patron-authentication-form--text">
      <div v-if="message">
        {{ message }}
      </div>
      <div v-else :style="{ marginBottom: '-28px' }">
        {{ _l10n("When you are logged in you can:") }}
        <ul>
          <li>{{ _l10n("Renew loans") }}</li>
          <li>{{ _l10n("Change reservations") }}</li>
          <li>{{ _l10n("Pay debts") }}</li>
          <li>{{ _l10n("Edit your profile info") }}</li>
        </ul>
      </div>
    </div>
    <div :class="['row', 'align-items-end', `patron-authentication-form--layout-${ type }`]">
      <div class="col">
        <form class="patron-authentication-form--form">
          <authentication-tooltip
            :enabled="activeInput === 'cpr' && displayed"
            :withBarcodeScanner="withBarcodeScanner"
            :isCprTooltip="true"
          >
            <easyscreen-password-input
              class="tooltip-target"
              label="CPR"
              :value="cprValue"
              :focused="activeInput === 'cpr'"
              @click.native="() => { activeInput = 'cpr' }"
              @input="_setCprWithLimit"
              ref="cprInput"
            />
          </authentication-tooltip>

          <authentication-tooltip
            :enabled="activeInput === 'pin' && displayed"
            :withBarcodeScanner="withBarcodeScanner"
            :isPinTooltip="true"
          >
            <easyscreen-password-input
              class="tooltip-target"
              label="PIN"
              v-model="pinValue"
              :focused="activeInput === 'pin'"
              @click.native="() => { activeInput = 'pin' }"
              ref="pinInput"
            />
          </authentication-tooltip>

          <easyscreen-select
            v-if="withPickupSelect"
            ref="pickupSelect"
            class="patron-authentication-form--input patron-authentication-form--pickup-select"
            :design="design"
            :label="_l10n('Pickup branch:')"
            :options="pickupPlacements"
            @selected="(value) => { selectedPickupPlacement = value }"
          />
        </form>
      </div>
      <div class="col text-center">
        <keyboard-numeric
          v-if="['numeric'].includes(type) || (type === 'combined' && activeInput === 'cpr')"
          :design="design"
          class="patron-authentication-form--numeric-keyboard"
          @key-click="_handleNumericKeyboard"
        />
        <keyboard-fullsize
          v-if="type === 'fullsize' || (type === 'combined' && activeInput === 'pin')"
          class="patron-authentication-form--fullsize-keyboard"
          :design="design"
          :override="{ enter: { label: $l10n('Login') }}"
          @key-click="_handleFullsizeKeyboard"
        />
      </div>
    </div>
  </div>
</template>

<style lang="less" src="../../core/mixins.less"></style>
<style lang="less" src="./authentication-form.less"></style>
<script>
  import EasyscreenPasswordInput from "../../core/input/password.vue";
  import EasyscreenSelect from "../../core/input/select.vue";
  import KeyboardNumeric from "../../core/keyboard/numeric.vue";
  import KeyboardFullsize from "../../core/keyboard/fullsize.vue";
  import AuthenticationTooltip from "./authentication-tooltip.vue";

  import * as querystring from "querystring";
  import { clone } from "lodash";
  import { RequestError } from "@/lib/errors.js";
  import l10n from "@/lib/localization/localization.js";

  /* The prefilled cpr and pin - for development needs. */
  const urlOptions = querystring.parse(window.location.href.split("#")[1]);
  const defaultCPR = urlOptions.defaultCPR;
  const defaultPIN = urlOptions.defaultPIN;
  const defaultPickupPlacement = urlOptions.defaultPickupPlacement;

  export default {
    name: "authentication-form",
    props: {
      /* The maximum size of cpr after which the pin filed will be automatically selected. */
      cprLimit: {
        type: Number,
        default: 10
      },
      /* Flag for using the barcode scanner features. */
      withBarcodeScanner: {
        type: Boolean,
        default: true
      },
      /*
       * The type of keyboard:
       * - numeric - The numeric keyboard for cpr and pin.
       * - combined - The numeric keyboard for cpr and fullsize for pin.
       * - fullsize - The fullsize keyboards for cpr and pin.
       */
      type: {
        type: String,
        default: "numeric",
        validator: _type => ["numeric", "combined", "fullsize"].includes(_type)
      },
      /* Flag login without password when cpr is scanner with barcode. */
      requirePasswordForBarcodeScanner: {
        type: Boolean,
        default: true
      },
      /* The custom form message. Default: the profile overview. */
      message: String,
      /* Add the select with library branches to form. */
      withPickupSelect: {
        type: Boolean,
        default: false
      },
      /* The global reskin. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    data() {
      return {
        displayed: false,
        activeInput: "cpr",
        cprValue: defaultCPR || "",
        pinValue: defaultPIN || "",
        selectedPickupPlacement: "",
        pickupPlacements: []
      };
    },
    methods: {
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Cut the input value based on limit.
       *
       * @param {String} value - The input value.
       * @param {Number} limit - The input limit.
       *
       * @returns {String} The full or cuted input value.
       */
      _getInputLimit(value, limit) {
        let limited = false;
        if (value.length >= limit) {
          value = value.substring(0, limit);
          limited = true;
        }

        return { value, limited };
      },
      /**
       * Listener of numeric keyboard "key-click" event.
       * Adds or removes the symbols to selected input.
       *
       * @param {String} key - The name or value of clicked key on the numeric keyboard.
       */
      _handleNumericKeyboard(key) {
        const activeValueProperty = `${ this.activeInput }Value`;
        if (key === "backspace") {
          this[activeValueProperty] = KeyboardNumeric.removeLastSymbol(this[activeValueProperty]);
        } else {
          let value = KeyboardNumeric.appendSymbol(this[activeValueProperty], key);
          if (this.activeInput === "cpr") {
            return this._setCprWithLimit(value);
          }

          this[activeValueProperty] = value;
        }
      },
      /**
       * Listener of fullsize keyboard "key-click" event.
       * Adds or removes the symbols to selected input.
       *
       * @param {String} key - The name or value of clicked key on the numeric keyboard.
       */
      _handleFullsizeKeyboard(key) {
        if (key === "enter") {
          if (this.activeInput === "cpr") {
            this.activeInput = "pin";
          } else {
            this.$emit("login");
          }

          return;
        }

        const activeValueProperty = `${ this.activeInput }Value`;
        KeyboardFullsize.defaultKeyboardHandler({
          value: {
            set: (value) => {
              if (this.activeInput === "cpr") {
                return this._setCprWithLimit(value);
              }

              this[activeValueProperty] = value;
            },
            get: () => this[activeValueProperty]
          }
        }, key);
      },
      /**
       * Applies the changes for cpr input with length limit.
       * Sets the pin input as active if the cpr field is fully filled.
       *
       * @param {String} _value - The value which should be applied cpr field.
       */
      _setCprWithLimit(value) {
        let { value: _value, limited } = this._getInputLimit(value, this.cprLimit);
        this.cprValue = _value;
        if (limited) {
          this.activeInput = "pin";
          this.$refs.pinInput.focusTheHiddenInput();
        }
      },
      /**
       * Checkes if the barcode scanner enabled.
       *
       * @returns {Boolean} Flag of barcode scanner status.
       */
      _isBarcodeScannerEnabled() {
        return this.withBarcodeScanner && this.$friendlyFrankBarcodeScanner && this.$friendlyFrankBarcodeScanner.isEnabled();
      },
      /**
       * Setter\getter of form data.
       *
       * @param {Object} [data] - The data for set form. Should be undefined\null\false if the method should be used as getter.
       * @param {String} [data.cpr] - The data for cpr field.
       * @param {String} [data.pin] - The data for pin field.
       *
       * @returns {(undefined|Object)} formData - nothing is returned on set or the form data on get.
       * @returns {String} formData.cpr - The user cpr.
       * @returns {String} formData.pin - The user pin.
       * @returns {Object} formData.pickupPlacement - Pickup placement.
       * @returns {Object} formData.pickupPlacement.value - The id of pickup placement
       * @returns {String} formData.pickupPlacement.locationType - Type of pickup placement, one of: "branch", "department".
       */
      formData(data) {
        if (data) {
          ["cpr", "pin"].forEach(field => {
            if (field in data) {
              this[field + "Value"] = data[field] || "";
            }
          });

          return;
        }

        let formData = {
          cpr: this.cprValue,
          pin: this.pinValue
        };

        if (this.withPickupSelect) {
          formData.pickupPlacement = clone(this.selectedPickupPlacement);
        }

        return formData;
      },
      /**
       * Listener of barcode scanner scan event.
       * Replaces the cpr input value to scanned.
       *
       * @param {String} scannedCpr - The scanned cpr.
       */
      _onBarcodeScan(scannedCpr) {
        this.cprValue = scannedCpr;
        this.$refs.cprInput.setMasked(true);

        if (this.$refs.pinInput) {
          this.$refs.pinInput.setMasked(true);
          this.activeInput = "pin";
        }

        if (this.requirePasswordForBarcodeScanner !== true) {
          this.$emit("fully-filled", "loan-without-pin");
        }
      },
      /**
       * Fetch the list of library branches.
       */
      async _fetchPickupPlacements() {
        let pickupLocationType = "branch";
        try {
          let providers = await this.$easyscreenRequest.lmsConnector.providers();
          if (providers)
            if (providers["get /branches"] === "bibliofil" && providers["get /departments"] === "bibliofil") {
              pickupLocationType = "department";
            }

          let placements = {};
          if (pickupLocationType === "department") {
            placements = await this.$easyscreenRequest.lmsConnector.departments({ pickup: true });
          } else {
            placements = await this.$easyscreenRequest.lmsConnector.branches({ pickup: true });
          }

          this.pickupPlacements = [{
            label: l10n("Favorite"),
            value: ""
          }].concat(Object.keys(placements).map(placementId => {
            return {
              label: placements[placementId],
              value: placementId,
              locationType: pickupLocationType
            };
          }));

        } catch (error) {
          throw new RequestError({
            message: l10n(`Can't fetch providers or ${ pickupLocationType }.`),
            ajaxRequest: error
          });
        }

        await this.$nextTick();
        this.$refs.pickupSelect.selectByIndex(0);

        if (defaultPickupPlacement) {
          this.$refs.pickupSelect.selectByIndex(this.pickupPlacements.findIndex(placement => {
            return placement.value === defaultPickupPlacement;
          }));
        }
      }
    },
    created() {
      if (this._isBarcodeScannerEnabled() && this.$friendlyFrankBarcodeScanner) {
        this.$friendlyFrankBarcodeScanner.on("scan", this._onBarcodeScan);
      }
    },
    mounted() {
      /* Hack for show tooltip only when the popup is animated. */
      this._displayedTimeout = setTimeout(() => {
        this.displayed = true;
        this._displayedTimeout = null;
      }, 500);

      if (this.withPickupSelect) {
        this._fetchPickupPlacements();
      }
    },
    beforeDestroy() {
      if (this._displayedTimeout) {
        clearTimeout(this._displayedTimeout);
        this._displayedTimeout = null;
      }

      if (this.$friendlyFrankBarcodeScanner) {
        this.$friendlyFrankBarcodeScanner.off("scan", this._onBarcodeScan);
      }
    },
    components: {
      "easyscreen-password-input": EasyscreenPasswordInput,
      "easyscreen-select": EasyscreenSelect,
      "keyboard-numeric": KeyboardNumeric,
      "keyboard-fullsize": KeyboardFullsize,
      "authentication-tooltip": AuthenticationTooltip
    }
  };
</script>
