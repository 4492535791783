<template>
  <div class="easyscreen-krsbib-header">
    {{ title }}
  </div>
</template>

<style src="./krsbib-header.less" lang="less"></style>

<script>
  export default {
    name: "easyscreen-krsbib-header",
    props: {
      title: String
    }
  };
</script>
