<template>
  <modal-blank
    class="modal-fullscreen"

    ref="modal"

    @before-open="(event) => $emit('before-open', event)"
    @opened="(event) => $emit('opened', event)"
    @before-close="(event) => $emit('before-close', event)"
    @closed="(event) => $emit('closed', event)"

    :min-width="minWidth"
    :min-height="minHeight"
    :max-width="maxWidth"
    :max-height="maxHeight"
    :auto-close="autoClose"
    :position="position"
  >
    <template slot="raw">
      <modal-layout-fullscreen
        :hide-empty-container="true"
        :color-scheme="colorScheme"
        :header-height="headerHeight"
        :footer-height="footerHeight"
      >
        <template slot="header" v-if="hasSlot('header')">
          <slot name="header"></slot>
        </template>
        <template slot="header-left" v-if="hasSlot('header-left')">
          <slot name="header-left"></slot>
        </template>
        <template slot="header-center" v-if="hasSlot('header-center')">
          <slot name="header-center"></slot>
        </template>
        <template slot="header-right" v-if="hasSlot('header-right')">
          <slot name="header-right"></slot>
        </template>

        <template slot="content">
          <slot name="content"></slot>
        </template>

        <template slot="footer" v-if="hasSlot('footer')">
          <slot name="footer"></slot>
        </template>
        <template slot="footer-left" v-if="hasSlot('footer-left')">
          <slot name="footer-left"></slot>
        </template>
        <template slot="footer-center" v-if="hasSlot('footer-center')">
          <slot name="footer-center"></slot>
        </template>
        <template slot="footer-right" v-if="hasSlot('footer-right')">
          <slot name="footer-right"></slot>
        </template>
      </modal-layout-fullscreen>
    </template>
  </modal-blank>
</template>

<style lang="less" src="./fullscreen.less"></style>
<script>
  import ModalBlank from "./blank.vue";
  import ModalLayoutFullscreen from "./layout-fullscreen.vue";

  import hasSlotMixin from "../mixins/has-slot.js";

  export default {
    name: "modal-fullscreen",
    mixins: [hasSlotMixin],
    props: {
      /*
       * The color scheme of fullscreen layout (also tweaks the size on layout: "header", "content" and "footer").
       * See available values for "layout-fullscreen"
       */
      colorScheme: String,
      /* The min width of modal. */
      minWidth: [Number, String],
      /* The min height of modal. */
      minHeight: [Number, String],
      /* The max width of modal. */
      maxWidth: [Number, String],
      /* The max height of modal. */
      maxHeight: [Number, String],
      /* The header height. */
      headerHeight: [Number, String],
      /* The footer height. */
      footerHeight: [Number, String],
      /* Close the popup when standby is started. */
      autoClose: {
        type: Boolean,
        default: true
      },
      position: String
    },
    computed: {
      isShown: {
        cache: false,
        get() {
          return this.$refs.modal && this.$refs.modal.isShown;
        }
      }
    },
    methods: {
      /**
       * Show the current modal.
       *
       * @param {Function} [callback] - The show callback, will be called when modal is opened.
       */
      show(callback) {
        return this.$refs.modal.show(callback);
      },
      /**
       * Hide the current modal.
       *
       * @param {Function} [callback] - The hide callback, will be called when modal is closed.
       */
      hide(callback) {
        return this.$refs.modal.hide(callback);
      }
    },
    components: {
      "modal-blank": ModalBlank,
      "modal-layout-fullscreen": ModalLayoutFullscreen
    }
  };
</script>
