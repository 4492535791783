var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'content-switcher',
    {
      'content-switcher_with-sound-control': _vm.withSoundControl,
      'content-switcher_with-pause-control': _vm.withPauseControl
    }
  ]},[_c('div',{staticClass:"content-switcher--content"},[_vm._t("default")],2),_c('scrollable',{ref:"scrollable",staticClass:"content-switcher--scrollable",attrs:{"max-height":"100%","smooth-edge-color":"var(--fill-color)"}},[_c('div',{staticClass:"content-switcher--navigation"},_vm._l((_vm.options),function(option,optionIndex){return _c('div',{key:_vm._getOptionKey(option),ref:"navigationElement",refInFor:true,class:[
          'content-switcher--navigation-element',
          { 'content-switcher--navigation-element_active': _vm.selectedIndex === optionIndex }
        ],on:{"click":() => _vm.select(optionIndex)}},[_c('div',{staticClass:"content-switcher--navigation-element-image",style:({
            backgroundImage: option.image ? `url('${ option.image }')` : null
          })}),_c('h4',{staticClass:"content-switcher--navigation-element-title"},[_vm._v(" "+_vm._s(option.title)+" ")]),_c('div',{staticClass:"content-switcher--navigation-element-duration"},[_vm._v(" "+_vm._s(_vm._humanizeDuration(option.duration))+" ")])])}),0)]),_c('div',{staticClass:"content-switcher--controls"},[_c('easyscreen-progress-bar',{staticClass:"content-switcher--progressbar",attrs:{"from":0,"to":_vm.currentDuration,"progress":_vm.elapsedTime}}),_c('div',{staticClass:"content-switcher--time-left"},[_vm._v(" "+_vm._s(_vm.timeLeft)+" ")]),_c('div',{staticClass:"content-switcher--controls-group"},[_c('h2',{staticClass:"content-switcher--title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.withSoundControl)?_c('easyscreen-volume-control',{ref:"volumeControl",staticClass:"content-switcher--volume-control",attrs:{"default-volume":_vm.defaultVolume,"default-muted":_vm.defaultMuted},on:{"volume-changed":(volume) => _vm.$emit('volume-changed', volume),"muted":(volume) => _vm.$emit('muted', volume),"unmuted":(volume) => _vm.$emit('unmuted', volume)}}):_vm._e(),_c('i',{staticClass:"content-switcher--flow-control fal fa-step-backward",on:{"click":function($event){return _vm.selectPrevious()}}}),(_vm.withPauseControl)?_c('i',{class:[
          'content-switcher--flow-control',
          'fal',
          _vm.state === 'playing' ? 'fa-pause' : 'fa-play'
        ],on:{"click":function($event){return _vm.togglePlayPause()}}}):_vm._e(),_c('i',{staticClass:"content-switcher--flow-control fal fa-step-forward",on:{"click":function($event){return _vm.selectNext()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }