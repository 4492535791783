<template>
  <div class="opening-hours">
    <div class="opening-hours--header">
      <i
        class="opening-hours--carousel-control fa fa-angle-left"
        @click="() => $refs.carousel.setOffset($refs.carousel.getOffset() + 100)"
      ></i>
      <h2 class="opening-hours--title">
        {{ _l10n("week") }} {{ currentWeek }}
      </h2>
      <i
        class="opening-hours--carousel-control fa fa-angle-right"
        @click="() => $refs.carousel.setOffset($refs.carousel.getOffset() - 100)"
      ></i>
    </div>
    <easyscreen-carousel
      ref="carousel"
      :height="$easyscreenIsMobile ? 'content-aware' : null"
      :layout-columns="1"
      :layout-rows="1"
      :default-position="defaultCarouselPosition"
      @before-position-change="(_, followingSlideIndex) => {
        _setActiveWeekBySlideIndex(followingSlideIndex)
      }"
    >
      <table
        class="opening-hours--list"
        v-for="weekOpeningHours in openingHoursWeekGroup"
        :key="weekOpeningHours.displayWeekNumber"
      >
        <tbody>
          <tr class="opening-hours--list-header">
            <th>{{ _l10n(dayLabel || "day") }}</th>
            <th>{{ _l10n(openedTodayLabel || "opening hours") }}</th>
            <th v-if="localWithServiceTime">{{ _l10n(serviceTimeLabel || "service time") }}</th>
          </tr>
          <tr
            :class="[
              'opening-hours--list-element',
              { 'opening-hours--list-element_active': currentDate === _openingHours.date }
            ]"
            v-for="_openingHours in weekOpeningHours.weekDays"
            :key="_openingHours.date"
            :style="{
              backgroundColor: currentDate === _openingHours.date ? tileColor : ''
            }"
          >
            <td class="opening-hours--service-days">
              <span>{{ _convertDate(_openingHours.date, "dddd") }}</span>
              <span>{{ " " }}</span>
              <span>{{ _convertDate(_openingHours.date, "DD/MM") }}</span>
            </td>

            <td>
              <span v-if="_openingHours.time" class="opening-hours--service-time">
                <span>{{ _openingHours.time.from }}</span>
                <span class="opening-hours--separator"> - </span>
                <span>{{ _openingHours.time.to }}</span>
              </span>
              <span v-else-if="_openingHours.isUnknown !== true">
                {{ _l10n("Closed") }}
              </span>
            </td>
            <td v-if="localWithServiceTime">
              <span v-if="_openingHours.serviceTime" class="opening-hours--service-time">
                <span>{{ _openingHours.serviceTime.from }}</span>
                <span class="opening-hours--separator"> - </span>
                <span>{{ _openingHours.serviceTime.to }}</span>
              </span>
              <span v-else-if="_openingHours.isUnknown !== true">
                {{ _l10n("Closed") }}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </easyscreen-carousel>
  </div>
</template>

<style lang="less" src="./opening-hours.less" />
<script>
  import EasyscreenCarousel from "../core/carousel/carousel.vue";

  import moment from "moment";
  import l10n from "@/lib/localization/localization.js";
  import convertDateMixin from "../core/mixins/convert-date.js";

  export default {
    name: "opening-hours",
    mixins: [convertDateMixin],
    props: {
      /* The background color of the list line with current date. */
      tileColor: String,
      /* Custom text for "day" label (first table column). */
      dayLabel: String,
      /* Custom text for "opening hours" label (second table column). */
      openedTodayLabel: String,
      /* Custom text for "service time" label (third table column). */
      serviceTimeLabel: String,
      /**
       * The point in euclidean space.
       * Format reference: https://momentjs.com/docs/#/displaying/format.
       *
       * @typedef {Object} OpeningHours
       *
       * @property {String} date - The record date in format `YYYY-MM-DD`.
       * @property {Object} [time] - The opening hours for `date`. If the field is missing then mean `closed`.
       * @property {String} time.from - The start time of opening hours in format `HH.mm`.
       * @property {String} time.to - The end time of opening hours in format `HH.mm`.
       * @property {Object} [serviceTime] - The service time for `date`. If the field is missing then mean `closed`.
       * @property {String} serviceTime.from - The start time of service time in format `HH.mm`.
       * @property {String} serviceTime.to - The end time of service time in format `HH.mm`.
       */
      /* The library opening hours with type: OpeningHours[] */
      openingHours: {
        type: Array,
        default: () => ([])
      },
      /* Enable or disable the displaying the service time in widget. */
      withServiceTime: {
        type: Boolean,
        default: true
      }
    },
    data() {
      const currentDate = moment();
      return {
        currentDate: currentDate.format("YYYY-MM-DD"),
        currentWeek: currentDate.format("W")
      };
    },
    computed: {
      /**
       * Getter of the opening hours grouped by week number.
       *
       * @returns {Object[]} groups - The list of OpeningHours and the number of the week.
       * @returns {Number} groups[i].displayWeekNumber - The ISO number of the week year.
       * @returns {OpeningHours[]} groups[i].weekDays - The opening hours for week `groups.displayWeekNumber`
       */
      openingHoursWeekGroup: {
        get() {
          let weeks = {};
          const baseYear = parseInt(moment(this.openingHours[0].date, "YYYY-MM-DD").format("YYYY"), 10);
          const baseYearWeeks = parseInt(moment(`${ baseYear }-12-31`, "YYYY-MM-DD").format("W"), 10);

          this.openingHours.forEach(_openingHours => {
            const date =  moment(_openingHours.date, "YYYY-MM-DD");
            const year = date.format("YYYY");
            const monthNumber = parseInt(date.format("M"), 10);
            const rawWeekNumber = parseInt(date.format("W"), 10);
            const dayNumber = date.format("e");

            const isNextYear = year > baseYear;
            const isNextYearWeek = monthNumber === 1 ? rawWeekNumber < 6 : isNextYear;
            const weekNumber = rawWeekNumber + (isNextYearWeek ? baseYearWeeks : 0);

            if (!weeks[weekNumber]) {
              weeks[weekNumber] = {
                displayWeekNumber: rawWeekNumber,
                days: new Array(7).fill(null).map((_, index) => {
                  return {
                    date: moment().year(year).week(rawWeekNumber).weekday(index).format("YYYY-MM-DD")
                  };
                })
              };
            }

            if (weeks[weekNumber] && weeks[weekNumber].days && weeks[weekNumber].days[dayNumber]) {
              weeks[weekNumber].days[dayNumber] = Object.assign(weeks[weekNumber].days[dayNumber], _openingHours);
            }
          });

          return Object.keys(weeks).map(weekNumber => {
            return {
              displayWeekNumber: weeks[weekNumber].displayWeekNumber,
              weekDays: weeks[weekNumber].days
            };
          });
        }
      },
      defaultCarouselPosition() {
        const currentWeek = parseInt(moment().format("W"), 10);
        const weekIndex = this.openingHoursWeekGroup.findIndex((weekGroup) => {
          return currentWeek === weekGroup.displayWeekNumber;
        });

        if (weekIndex === -1)
          return 0;

        return weekIndex * -100;
      },
      localWithServiceTime() {
        if (this.$easyscreenSkin.isRclbib)
          return false;

        return this.withServiceTime;
      }
    },
    methods: {
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Set the displayed week number based on index of active slide in carousel.
       *
       * @param {Number} index - The index of the slide in carousel.
       */
      _setActiveWeekBySlideIndex(index) {
        this.currentWeek = this.openingHoursWeekGroup[index].displayWeekNumber;
      }
    },
    components: {
      "easyscreen-carousel": EasyscreenCarousel
    }
  };
</script>
