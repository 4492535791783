/**
 * Get material available, reservable states and reservableId based on holdings.
 *
 * @param {Object[]} holdings - List of material holdings.
 * @param {Number} holdings[].available - The amount of available materials.
 * @param {Number} holdings[].reservableId - The id of material for reservation.
 *
 * @returns {Object} availability - Material availability.
 * @returns {Boolean} availability.available - True if the material available.
 * @returns {Boolean} availability.reservable - True if the material reservable.
 * @returns {String} availability.reservableId - The id of material for reservation.
 */
export default function getMaterialAvailability(holdings) {
  let availability = {
    available: holdings.find(holding => holding.available > 0),
    reservable: false,
    reservableId: null
  };

  let reservableHolding = holdings.find(holding => {
    return holding.reservableId;
  });

  if (reservableHolding) {
    availability.reservable = true;
    availability.reservableId = reservableHolding.reservableId;
  }

  return availability;
}
