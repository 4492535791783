<template>
  <div
    :class="[
      'digital-shelf--wrapper',
      { 'digital-shelf_videohost': isVideoHostTemplate }
    ]"
  >
    <modal-layout-fullscreen
      color-scheme="digital-shelf"
      :footer-height="footerHeight"
      :class="[
        'digital-shelf',
        orientation
      ]"
    >
      <template slot="header">
        <div class="digital-shelf--header" :style="{ backgroundImage: _get(bg, 'top') }">
          <div
            v-if="isVideoHostTemplate === false && !disableExternalFeatures"
            class="digital-shelf--head"
          >
            <h3 class="digital-shelf--head_label">{{ title }}</h3>
            <div
              v-if="$easyscreenConfig.get('enable.search')"
              class="digital-shelf--shortcut digital-shelf--shortcut_search"
              @click="() => $refs.search.show()"
            >
              <i class="fa fa-search"></i>
            </div>
            <div
              v-if="$easyscreenConfig.get('enable.basket')"
              class="digital-shelf--shortcut"
              @click="_showCart"
            >
              <easyscreen-materials-cart-button />
            </div>
            <div
              v-if="$easyscreenConfig.get('enable.unloan')"
              class="digital-shelf--shortcut"
              @click="_showUnloan"
            >
              <i class="esi esi-hand-in"></i>
            </div>

            <div
              v-if="$easyscreenConfig.get('enable.profile')"
              class="digital-shelf--shortcut"
              @click="_showProfile"
            >
              <i class="esi esi-user"></i>
            </div>
          </div>

          <div class="digital-shelf--sub-head" @click="showInfo">
            <div class="digital-shelf--sub-head_tag-name">{{ getActiveTag() }}</div>
            <div class="digital-shelf--sub-head_info">
              {{ _l10n("Introduction to the topic") }}
              <span class="digital-shelf--sub-head_info-icon">i</span>
            </div>
          </div>
        </div>
      </template>
      <template slot="content">
        <div class="digital-shelf--content">
          <div class="digital-shelf-carousel" :style="carouselStyle">
            <easyscreen-carousel
              ref="carousel"
              :layout-columns="_getCarouselLayout().columns"
              :layout-rows="_getCarouselLayout().rows"
              :key="['layout', selected, JSON.stringify(_getCarouselLayout())].join('')"
            >
              <digital-shelf-cover
                v-for="item in items[selected]"
                :key="item.faustNumber"
                :title="item.title"
                :author="item.author"
                :cover="item.cover"
                @click.native="_showMaterialView(item, selected)"
              />
            </easyscreen-carousel>
          </div>
        </div>
      </template>
      <template slot="footer">
        <div class="digital-shelf--footer">
          <digital-shelf-tags
            ref="tags"
            :tags="tags"
            :active="selected"
            @selected="_selectTag"
          />
        </div>
      </template>
    </modal-layout-fullscreen>

    <patron-profile v-if="$easyscreenConfig.get('enable.profile')" ref="patronProfile" />
    <modal-blank
      :class="['digital-shelf--info-modal', orientation]"
      ref="infoModal"
      position="absolute"
      min-width="100%"
      min-height="100%"
    >
      <template slot="raw">
        <div class="digital-shelf--info">
          <easyscreen-circle-button
            class="digital-shelf--info-close digital-shelf--info-close_top"
            icon="fal fa-times"
            @click.native="$refs.infoModal.hide"
          />
          <easyscreen-circle-button
            class="digital-shelf--info-close digital-shelf--info-close_bottom"
            icon="fal fa-times"
            @click.native="$refs.infoModal.hide"
          />

          <div class="digital-shelf--info-content">
            <scrollable
              class="digital-shelf--info-text"
              max-height="100%"
              smooth-edge-color="#ffffff"
            >
              <div v-html="getHelpText()"></div>
            </scrollable>
            <easyscreen-youtube
              class="digital-shelf--info-video"
              v-if="!!_getHelpVideo()"
              :url="_getHelpVideo()"
              :muted="false"
            />
          </div>
        </div>
      </template>
    </modal-blank>

    <easyscreen-search
      v-if="!disableExternalFeatures"
      ref="search"
      :availableOnlyButton="true"
      :auto-close="true"
    />
    <materials-list
      v-if="selectedMaterial"
      ref="materialsList"
      :auto-close="!isAnimation"
      :use-suggested-lists="isVideoHostTemplate === false && this.suggestedLists"
      :default-materials="selectedMaterialsList"
      :default-selected="selectedMaterial"
      @select-tag="_findByTag"
    />

    <easyscreen-materials-cart
      v-if="$easyscreenConfig.get('enable.basket')"
      ref="materialsCart"
    />

    <materials-scanner v-if="$easyscreenConfig.get('enable.profile')" ref="materialsScanner" />
  </div>
</template>

<style lang="less" src="../core/icons.less" ></style>
<style lang="less" src="./digital-shelf.less"></style>
<style lang="less" src="./shortcut.less"></style>

<script>
  import ModalLayoutFullscreen from "../core/modal/layout-fullscreen.vue";
  import ModalBlank from "../core/modal/blank.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import EasyscreenCarousel from "../core/carousel/carousel.vue";
  import EasyscreenYoutube from "../core/youtube/youtube.vue";
  import Scrollable from "../core/scrollable/scrollable.vue";
  import PatronProfile from "../patron/profile/profile.vue";
  import MaterialsScanner from "../materials-scanner/materials-scanner.vue";
  import DigitalShelfCover from "./cover.vue";
  import DigitalShelfTags from "./tags.vue";
  import EasyscreenSearch from "../search/search.vue";
  import MaterialsList from "../materials-list/materials-list.vue";
  import EasyscreenMaterialsCart from "../materials-cart/materials-cart.vue";
  import EasyscreenMaterialsCartButton from "../materials-cart/materials-cart-button.vue";

  import lodash from "lodash";
  import * as d3 from "d3-timer";
  import getStyle from "@/lib/utils/get-style.js";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../core/mixins/orientation.js";
  import barcodeSelfcheckSupportMinix from "../materials-scanner/screens/barcode-selfcheck-support-minix.js";

  const selectTagAnimationDuration = 600;

  export default {
    name: "digital-shelf",
    mixins: [orientationMixin, barcodeSelfcheckSupportMinix],
    props: {
      /* The style depends on digital shelf container height. */
      styleType: {
        type: Number,
        default: 100
      },
      /* Request settings for get digital shelf items from lms search. */
      request: {
        type: Object,
        default: () => ({})
      },
      /* The title of standalone digital shelf (in top left corner). */
      title: {
        type: String,
        default() {
          return this.$easyscreenConfig.name || "";
        }
      },
      /* The tag (groups) of digital shelf */
      tags: {
        type: Array,
        default: () => ([])
      },
      /* Items for all tags in format array with arrays of detail ([tag1:[MaterialDetail], tag2:[MaterialDetail]]) */
      items: {
        type: Array,
        default: () => ([])
      },
      /* Custom background of digital shelf. */
      bg: {
        type: Object,
        default: () => ({})
      },
      /* The index of default active tag. */
      active: {
        type: Number,
        default: 0
      },
      /* Overrided columns in carousel layout. */
      layoutColumns: Number,
      /* Overrided rows in carousel layout. */
      layoutRows: Number,
      /* xi component of search */
      /* TODO: remove and replace the external search internal component usage. */
      search: {
        type: [HTMLElement, Boolean],
        default: null
      },
      /* Flag of the suggested lists for material detail. */
      suggestedLists: {
        type: Boolean,
        default() {
          return this.$easyscreenConfig.get("enable.suggestedLists");
        }
      },
      /* Flag for disable standalone features: search, profile and self checking (loan, return). */
      disableExternalFeatures: {
        type: Boolean,
        default: false
      },
      animationInterval: Number
    },
    computed: {
      isVideoHostTemplate() {
        return this.styleType !== 100;
      }
    },
    data: function() {
      return {
        /* The flag for barcode selfcheck handler. */
        stage: "scan",
        selected: this.active,
        footerHeight: null,
        carouselStyle: {},
        selectedMaterialsList: [],
        selectedMaterial: "",
        isAnimation: false
      };
    },
    methods: {
      /**
       * Get the active tag.
       *
       * @returns {String} Tag.
       */
      getActiveTag() {
        return this.tags[this.selected];
      },
      /**
       * Get the help text of active shelf.
       *
       * @returns {String} The help text.
       */
      getHelpText() {
        return lodash.get(this.request, `shelves[${ this.selected }].options.helptext`);
      },
      /**
       * Show the help text of active shelf.
       */
      showInfo() {
        if (!this.getHelpText() && !this._getHelpVideo()) {
          return;
        }

        this.$refs.infoModal.show();
      },
      /**
       * The proxy method of `lodash.get`.
       */
      _get: lodash.get,
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Hanlder of find-by-tag event on material view.
       * @fires digital-shelf#find-by-tag
       *
       * @param {String} tag - The selected material tag for search.
       */
      async _findByTag(tag) {
        if (this.$refs.materialsList) {
          await this.$refs.materialsList.hide();
        }

        if (this.disableExternalFeatures) {
          /**
           * The event of calling _findByTag method when the digital shelf have not own search widget (not in standalone mode).
           *
           * @event digital-shelf#find-by-tag
           * @type {String + Function} The search query and `done` callback.
           */
          return this.$emit("find-by-tag", tag);
        }

        if (this.$refs.search) {
          await this.$refs.search.show();
          await this.$refs.search.findBy(tag);
        }
      },
      /**
       * Show the unloan screen of attached material scanner component in standalone mode.
       */
      _showUnloan() {
        this.$refs.materialsScanner.show("return");
      },
      /**
       * Show the overview profile screen of attached profile component in standalone mode.
       */
      _showProfile() {
        this.$refs.patronProfile.authenticate();
      },
      /**
       * Show the reservation cart.
       */
      _showCart() {
        this.$refs.materialsCart.show();
      },
      /**
       * Open the material view for selected material.
       * @async
       *
       * @param {Object} item - The materail detail (see response of GET [lms]/[consumer-hash]/detail).
       * @param {String} item.faustNumber - The faust number of material.
       * @param {Number} selected - The index of selected tag.
       */
      async _showMaterialView({ faustNumber }, selected) {
        this.selectedMaterialsList = this.items[selected].map(material => material.id || material.faustNumber);
        this.selectedMaterial = faustNumber;
        await this.$nextTick();

        await this.$refs.materialsList.show();
      },
      /**
       * Handler of tags~selected event.
       * @fires digital-shelf#tag-selected
       *
       * @param {Number} index - .
       * @param {Function} callback - .
       */
      _selectTag(index, callback) {
        this.$easyscreenStatistic.selectDigitalShelfTheme({ tagName: this.tags[index] });

        clearTimeout(this.selectTagAnimationTimeout);
        let halfDurationOfAnimation = selectTagAnimationDuration / 2;
        this.carouselStyle = {
          position: "relative",
          transition: `all ${ halfDurationOfAnimation }ms ease`,
          left: 0
        };

        this.$nextTick(() => {
          this.carouselStyle = Object.assign({}, this.carouselStyle, { left: "130%" });

          this.selectTagAnimationTimeout = setTimeout(() => {
            this.selected = index;
            this.$forceUpdate();
            this.$nextTick(() => {
              this.carouselStyle = Object.assign({}, this.carouselStyle, { left: 0 });
              this.selectTagAnimationTimeout = setTimeout(() => {
                this.carouselStyle = {};
                callback && callback();
                /**
                 * Event of new selected tag.
                 *
                 * @event digital-shelf#tag-selected
                 * @type {Number} The index of selected tag.
                 */
                this.$emit("tag-selected", this.selected);
              }, halfDurationOfAnimation);
            });
          }, halfDurationOfAnimation);
        });
      },
      /**
       * Get the layout of carousel, layout depeneds on orientation, this.styleType and media type.
       *
       * @returns {Object} layout - The carousel layout.
       * @returns {Number} layout.columns - The columns of layout.
       * @returns {Number} layout.rows - The rows of layout.
       */
      _getCarouselLayout() {
        const layoutFromStyle = {
          100: { portrait: 3, landscape: 2 },
          75: { portrait: 3, landscape: 2 },
          50: { portrait: 2, landscape: 1 }
        };

        let media = null;
        if (navigator.userAgent.match(/iPad/i) != null) {
          media = "ipad";
        }

        let defaultColumns = 5;
        let defaultRows = (layoutFromStyle[this.styleType] || layoutFromStyle[100]).portrait;
        if (this._isLandscape()) {
          if (media == "ipad") {
            defaultColumns = 7;
            defaultRows = 2;
          } else {
            defaultColumns = 10;
            defaultRows = (layoutFromStyle[this.styleType] || layoutFromStyle[100]).landscape;
          }
        }

        return {
          columns: this.layoutColumns || defaultColumns,
          rows: this.layoutRows || defaultRows
        };
      },
      /**
       * Adjust the content and tags height for fit all tags.
       */
      _adjustSize() {
        if (!this.$refs.tags) {
          return;
        }

        let tagsHeight = getStyle(this.$refs.tags.$el, "height");
        if (!tagsHeight) {
          return;
        }

        this.footerHeight = tagsHeight + 32;
      },
      /**
       * Get the url to youtube help video of active shelf.
       *
       * @returns {String} Url to youtube video.
       */
      _getHelpVideo() {
        return lodash.get(this.request, `shelves[${ this.selected }].options.youtubelink`);
      },
      /**
       * Handler of rfid scanner inventory-updated event.
       *
       * @param {RFIDScannerInventory} inventory - The rfid scanner inventory.
       */
      _onScannerInventoryUpdate(inventory) {
        if (Object.keys(inventory).length !== 0 && !this.$refs.materialsScanner.isShown) {
          this.$refs.materialsScanner.show("loan");
        }
      },
      async _autoAnimationSelectTheme() {
        if (this.$refs.materialsList) {
          await this.$refs.materialsList.hide();
        }

        const _tags = this.tags.filter((_, index) => index !== this.selected);
        const targetTag = _tags[lodash.random(0, _tags.length - 1, false)];
        this._selectTag(this.tags.indexOf(targetTag));
      },
      async _autoAnimationSelectMaterial() {
        if (this.$refs.materialsList) {
          await this.$refs.materialsList.hide();
        }

        const _materials = this.items[this.selected].filter(material => material.id !== this.selectedMaterial);
        const targetMaterial = _materials[lodash.random(0, _materials.length - 1, false)];
        this._showMaterialView(targetMaterial, this.selected);
      },
      _stopAutoAnimation() {
        this.isAnimation = false;
        if (this._autoAnimationInterval) {
          this._autoAnimationInterval.stop();
          this._autoAnimationInterval = null;
        }
      },
      _startAutoAnimation() {
        this._stopAutoAnimation();

        if (this.animationInterval != null && this.tags.length > 0) {
          this.isAnimation = true;
          this._autoAnimationInterval = d3.interval(() => {
            const rnd = Math.random();
            if ((rnd < 0.10 || rnd > 0.90) && this.tags.length !== 1) {
              this._autoAnimationSelectTheme();
            } else {
              this._autoAnimationSelectMaterial();
            }
          }, this.animationInterval);
        }
      }
    },
    screenStandby() {
      if (this.$refs.materialsList) {
        this.$refs.materialsList.hide();
      }

      /* Reset the selected theme. */
      if (this.active !== this.selected) {
        this._selectTag(this.active);
      } else if (this.$refs.carousel) {
        /* Reset the carousel state */
        this.$refs.carousel.selectByIndex(0);
      }

      this._startAutoAnimation();
    },
    screenActive() {
      this._stopAutoAnimation();
      if (this.$refs.materialsList) {
        this.$refs.materialsList.hide();
      }
    },
    mounted() {
      this.$on("orientation-changed", this._adjustSize);
      this._adjustSize();

      if (!this.disableExternalFeatures) {
        if (this.$easyscreenConfig.get("enable.loan")) {
          this.$friendlyFrankScanner.on("inventory-updated", this._onScannerInventoryUpdate);
          this._onScannerInventoryUpdate(this.$friendlyFrankScanner.inventory);
        }
      }
    },
    beforeDestroy() {
      if (this.$friendlyFrankScanner) {
        this.$friendlyFrankScanner.off("inventory-updated", this._onScannerInventoryUpdate);
      }

      this.$off("orientation-changed", this._adjustSize);
    },
    components: {
      "modal-layout-fullscreen": ModalLayoutFullscreen,
      "modal-blank": ModalBlank,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "easyscreen-carousel": EasyscreenCarousel,
      "easyscreen-youtube": EasyscreenYoutube,
      "scrollable": Scrollable,
      "patron-profile": PatronProfile,
      "materials-scanner": MaterialsScanner,
      "digital-shelf-cover": DigitalShelfCover,
      "digital-shelf-tags": DigitalShelfTags,
      "easyscreen-search": EasyscreenSearch,
      "materials-list": MaterialsList,
      "easyscreen-materials-cart": EasyscreenMaterialsCart,
      "easyscreen-materials-cart-button": EasyscreenMaterialsCartButton
    }
  };
</script>
