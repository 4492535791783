<template>
  <div class="presentation-set-collection">
    <easyscreen-content-switcher
      ref="contentSwitcher"
      :options="switcherOptions"
      :with-sound-control="false"
      :with-pause-control="false"
      :default-selected="defaultSelected"
      @selected="_onSelected"
    >
      <div
        ref="content"
        class="presentation-set-collection--content"
      >
        <presentation-set
          ref="presentationSet"
          :with-top-bar="false"
          :with-duration-bar="false"
          :with-background="false"
          :slides="activeSlide.content"
          :style="{
            transform: `scale(${ scaleFactor })`,
            transformOrigin: 'top left',
            width: `${ 100 / scaleFactor }%`,
            height: `${ 100 / scaleFactor }%`
          }"
        />
      </div>
    </easyscreen-content-switcher>
  </div>
</template>

<style src="./presentation-set-collection.less" lang="less"></style>

<script>
  import orientationMixin from "../core/mixins/orientation.js";

  import EasyscreenContentSwitcher from "../content-switcher/content-switcher.vue";
  const PresentationSet = () => import("../presentation-set/presentation-set.vue");

  export default {
    name: "presentation-set-collection",
    mixins: [orientationMixin],
    props: {
      slides: Array,
      defaultSelected: {
        type: Number,
        default: 0
      }
    },
    computed: {
      switcherOptions() {
        return this.slides.map(slide => {
          return {
            title: slide.title,
            image: slide.background || "/images/beach.png",
            duration: slide.content.reduce((duration, contentSlide) => {
              duration += contentSlide.duration;

              return duration;
            }, 0),
            autoplay: true
          };
        });
      },
      activeSlide() {
        return this.slides[this.activeSlideIndex];
      },
      scaleTarget() {
        if (this._isLandscape) {
          return {
            width: 1920,
            height: 1080
          };
        }

        return {
          width: 1080,
          height: 1920
        };
      }
    },
    data() {
      return {
        paused: false,
        scaleFactor: 1,
        activeSlideIndex: this.defaultSelected
      };
    },
    methods: {
      play() {
        if (this.$refs.contentSwitcher) {
          this.$refs.contentSwitcher.play();
        }

        if (this.$refs.presentationSet) {
          this.$refs.presentationSet.play();
        }

        this.paused = false;
      },
      pause() {
        if (this.$refs.contentSwitcher) {
          this.$refs.contentSwitcher.pause();
        }

        if (this.$refs.presentationSet) {
          this.$refs.presentationSet.pause();
        }

        this.paused = true;
      },
      _onSelected(index) {
        this.activeSlideIndex = index;
      },
      _updateContentScaleFactor() {
        if (!this.$refs.content) {
          return;
        }

        let contentBoundings = this._applyParentsScaleToBoundings(this.$refs.content, this.$refs.content.getBoundingClientRect());
        return contentBoundings.width / this.scaleTarget.width;
      }
    },
    mounted() {
      this.scaleFactor = this._updateContentScaleFactor();

      this.$on("orientation-changed", this._updateContentScaleFactor);
    },
    screenStandby() {
      this.play();
    },
    screenActive() {
      this.pause();
    },
    beforeDestroy() {
      this.$off("orientation-changed", this._updateContentScaleFactor);
    },
    components: {
      "easyscreen-content-switcher": EasyscreenContentSwitcher,
      "presentation-set": PresentationSet
    }
  };
</script>
