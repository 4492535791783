<template>
  <div :class="[
    'node-list-carousel-krsbib-slide',
    `node-list-carousel-krsbib-slide_${ type }`,
    {
      'node-list-carousel-krsbib-slide_transparent': showBackground,
      'node-list-carousel-krsbib-slide_top-slide-element': elementRow === 0,
      'node-list-carousel-krsbib-slide_bottom-slide-element': elementRow === (slideRows - 1),
      'node-list-carousel-krsbib-slide_with-delimiter': withSlideDelimiter
    }
  ]">
    <div class="node-list-carousel-krsbib-slide--wrapper">
      <div class="node-list-carousel-krsbib-slide--meta">
        <div v-if="isEvent">
          <div class="node-list-carousel-krsbib-slide--calendar">
            <div class="node-list-carousel-krsbib-slide--calendar-day">
              {{ calendarDay }}
            </div>
            <div class="node-list-carousel-krsbib-slide--calendar-month">
              {{ calendarMonth }}
            </div>
          </div>

          <div
            v-if="date"
            class="node-list-carousel-krsbib-slide--date"
          >
            <i class="fal fa-clock"></i>
            {{ formattedDate }}
          </div>
          <div
            v-if="location"
            class="node-list-carousel-krsbib-slide--location"
          >
            <i class="fal fa-home-lg-alt"></i>
            {{ location }}
          </div>
          <div
            v-if="price"
            class="node-list-carousel-krsbib-slide--price"
          >
            <i class="fal fa-info-circle"></i>
            {{ price }}
          </div>
        </div>
      </div>
      <div ref="content" class="node-list-carousel-krsbib-slide--content">
        <div
          v-if="teaserImage"
          class="node-list-carousel-krsbib-slide--teaser-image"
          :style="{
            backgroundImage: `url('${ teaserImage }')`
          }"
          ref="teaserImage"
        ></div>

        <h1 class="node-list-carousel-krsbib-slide--title" ref="title">
          {{ title }}
        </h1>

        <div
          v-if="teaser"
          class="node-list-carousel-krsbib-slide--content-delimiter"
          ref="delimiter"
        ></div>

        <div class="node-list-carousel-krsbib-slide--teaser-wrapper">
          <scrollable :max-height="textHeight">
            <div class="node-list-carousel-krsbib-slide--teaser ck-editor" v-html="teaser"></div>
            <div class="node-list-carousel-krsbib-slide--text ck-editor" v-html="text"></div>
          </scrollable>
        </div>
      </div>
    </div>
    <div
      v-if="withSlideDelimiter"
      class="node-list-carousel-krsbib-slide--slide-delimiter"
    ></div>
  </div>
</template>

<style src="../../core/ck-editor.less" lang="less"></style>
<style src="./node-list-carousel-krsbib-slide.less" lang="less"></style>

<script>
  import lodash from "lodash";
  import moment from "moment";
  import htmlElementHeight from "@/lib/utils/html-element-height.js";

  import Scrollable from "../../core/scrollable/scrollable.vue";

  export default {
    name: "node-list-carousel-krsbib-slide",
    props: {
      type: String,
      date: String,
      dateTo: String,
      location: String,
      price: [String, Number],
      bgImage: String,
      image: String,
      title: String,
      esTeaser: String,
      teaser: String,
      text: String,
      lead: String,
      showBackground: Boolean,
      withSlideDelimiter: Boolean,
      slideRows: Number,
      elementRow: Number
    },
    computed: {
      isEvent() {
        return this.type === "event";
      },
      fixedDate() {
        const dateFrom = moment(this.date);
        const dateTo = moment(this.dateTo);
        let date = moment();

        if (date < dateFrom) {
          date = dateFrom;
        } else if (date > dateTo) {
          date = dateTo;
        }

        date.hour(dateFrom.hour());
        date.minute(dateFrom.minute());

        return {
          from: dateFrom,
          to: dateTo,
          current: date
        };
      },
      calendarDay() {
        return this.fixedDate.current.format("D");
      },
      calendarMonth() {
        return this.fixedDate.current.format("MMM.");
      },
      formattedDate() {
        let formattedDate = this.$l10n("All day");

        if (this.fixedDate.current.hour() !== 0 || this.fixedDate.current.minute() !== 0) {
          const fromTime = this.fixedDate.from.format("HH.mm");
          const toTime = this.fixedDate.to.format("HH.mm");

          formattedDate = `${ fromTime }`;

          if (fromTime !== toTime) {
            formattedDate += ` - ${ toTime }`;
          }
        }

        return formattedDate;
      },
      teaserImage() {
        return this.image || this.bgImage;
      }
    },
    data() {
      return {
        textHeight: null
      };
    },
    methods: {
      fitTeaser() {
        this.textHeight = [
          this.$refs.teaserImage,
          this.$refs.title,
          this.$refs.delimiter
        ].filter(Boolean).reduce((availableHeight, htmlElement) => {
          return availableHeight - htmlElementHeight(htmlElement);
        }, htmlElementHeight(this.$refs.content)) - 17 + "px";
      }
    },
    mounted() {
      this._fitTeaser = this.fitTeaser;
      this.fitTeaser = lodash.throttle(() => {
        setTimeout(() => {
          this._fitTeaser();
        }, 16);
      }, 16);

      this.fitTeaser();

      window.addEventListener("resize", this.fitTeaser);
    },
    beforeDestroy() {
      window.removeEventListener("resize", this.fitTeaser);
    },
    components: {
      Scrollable
    }
  };
</script>
