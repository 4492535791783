var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'library-events-list',
    `library-events-list_design-${ _vm.design }`,
    {
      'library-events-list_with-title': _vm.title
    },
    _vm.layoutClass,
    _vm.orientation
  ],style:(_vm.tileColor ? `--library-events-list--primary-color: ${ _vm.tileColor }` : null)},[(_vm.$easyscreenSkin.isBtj)?_c('btj-header',{staticClass:"library-events-list--btj-header",attrs:{"size":"small"}}):_vm._e(),(_vm.title)?_c('h2',{staticClass:"library-events-list--title"},[_c('span',[_vm._v(" "+_vm._s(_vm.title)+" ")])]):_vm._e(),_c('scrollable',{ref:"scrollable",staticClass:"library-events-list--elements",attrs:{"max-height":"100%"}},[(_vm.items.length === 0)?_c('h2',{staticClass:"library-events-list--no-data"},[_vm._v(_vm._s(_vm.$l10n("No data for show")))]):_vm._e(),_vm._l((_vm.items),function(element,elementIndex){return _c('div',{key:element.nid,class:[
        'library-events-list--element',
        { 'library-events-list--element_without-image': !element.image }
      ],on:{"click":() => _vm._expandElement(element, elementIndex)}},[_c('easyscreen-tear-off-calendar',{staticClass:"library-events-list--element-calendar",attrs:{"date":element.date,"with-time":_vm.isCinemaLayout}}),(element.image)?_c('div',{staticClass:"library-events-list--element-image",style:({
          backgroundImage: `url('${ element.image }')`
        })}):_vm._e(),_c('h2',{staticClass:"library-events-list--element-title"},[(_vm.isGameLayout)?_c('span',{staticClass:"library-events-list--element-date"},[_vm._v(" "+_vm._s(_vm._formatDate(element.date))+" ")]):_vm._e(),_c('span',[_vm._v(_vm._s(element.title))])]),_c('div',{staticClass:"library-events-list--element-teaser"},[_vm._v(" "+_vm._s(element.teaser)+" ")])],1)})],2),_c('modal-blank',{ref:"modal",attrs:{"click-to-close":true,"position":_vm.modalPosition},on:{"before-open":function($event){return _vm.$emit('modal-opened', _vm.$refs.modal.hide, 'libraryEventsListInfo')},"closed":function($event){return _vm.$emit('closed-inner-modal', 'libraryEventsListInfo')}}},[_c('div',[(_vm.activeElement)?_c('node-list-info',{attrs:{"design":_vm.design,"type":_vm.activeElement.type,"date":_vm.activeElement.date,"date-to":_vm.activeElement.dateTo,"location":_vm.activeElement.location,"price":_vm.activeElement.price,"image":_vm.activeElement.image,"title":_vm.activeElement.title,"es-teaser":_vm.activeElement.esTeaser,"teaser":_vm.activeElement.teaser,"text":_vm.activeElement.text,"lead":_vm.activeElement.lead,"qrCode":_vm.activeElement.qrCode}},[(_vm.withModalControls)?_c('easyscreen-circle-button',{staticClass:"library-events-list--close-button library-events-list--close-button_top-right",attrs:{"icon":"esi esi-times","color":_vm.design === 'light' ? 'white' : undefined,"size":_vm.design === 'light' ? 'medium' : undefined},nativeOn:{"click":function($event){return _vm.$refs.modal.hide.apply(null, arguments)}}}):_vm._e(),(_vm.withModalControls)?_c('easyscreen-circle-button',{staticClass:"library-events-list--close-button library-events-list--close-button_bottom-right",attrs:{"icon":"esi esi-times","color":_vm.design === 'light' ? 'white' : undefined,"size":_vm.design === 'light' ? 'medium' : undefined},nativeOn:{"click":function($event){return _vm.$refs.modal.hide.apply(null, arguments)}}}):_vm._e()],1):_vm._e()],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }