<template>
  <div>
    <modal-fullscreen
      ref="modal"

      colorScheme="easyscreen-menu"

      @before-open="(event) => {
        $emit('before-open', event);
        shown = true;
      }"
      @opened="(event) => $emit('opened', event)"
      @before-close="(event) => {
        $emit('before-close', event);
        shown = false;
      }"
      @closed="(event) => $emit('closed', event)"

      class="materials-scanner-scan--modal"
    >
      <template slot="header-left">
        <easyscreen-circle-button
          icon="/images/es-menu/home_icon.png"
          icon-type="image"

          @click.native="hide"
        />
      </template>
      <template slot="header-center">
        <h1 class="easyscreen-header_1">
          {{ _l10n("Scan") }}
        </h1>
        <p class="easyscreen-text-2">{{ _l10n("Place a material on the scanner to see more info") }}</p>
      </template>
      <template slot="content">
        <div class="materials-scanner-scan">
          <img class="materials-scanner-scan--image" :src="scanImage">
        </div>
      </template>
    </modal-fullscreen>

    <materials-list
      v-if="selectedMaterial"
      ref="materialsList"
      :use-loan="true"
      :use-cache="false"
      :default-materials="selectedMaterialsList"
      :default-selected="selectedMaterial"
      @select-tag="(tag) => $emit('find-by-tag', tag)"
      @open-loan="() => {
        if ($refs.materialsList) {
          $refs.materialsList.hide();
        }

        $emit('open-loan');
      }"
    />
  </div>
</template>

<style lang="less" src="../../core/mixins.less"></style>
<style lang="less">
  .materials-scanner-scan {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    &--image {
      width: 460px;
    }
    &--modal {
      & > .vm--modal > .v-modal--content > .modal-layout > .modal-layout--content {
        padding: 0 !important;
      }
    }
  }
</style>
<script>
  import ModalFullscreen from "../../core/modal/fullscreen.vue";
  import EasyscreenCircleButton from "../../core/button/circle-button.vue";
  import MaterialsList from "../../materials-list/materials-list.vue";

  import { get } from "lodash";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../../core/mixins/orientation.js";
  import barcodeSelfcheckSupportMinix from "./barcode-selfcheck-support-minix.js";

  export default {
    name: "materials-scanner-scan",
    mixins: [orientationMixin, barcodeSelfcheckSupportMinix],
    data() {
      let scanImage = "/images/scanner/scan-icon.png";
      if (this.$easyscreenSkin.isVestbib) {
        scanImage = "/images/vestbib/vestbib-scanner.png";
      }

      return {
        /* The flag for barcode selfcheck handler. */
        stage: "scan",
        scanImage,
        shown: false,
        materialSlider: null,
        selectedMaterialsList: [],
        selectedMaterial: ""
      };
    },
    computed: {
      /* Proxy for modal isShown variable. */
      isShown: {
        cache: false,
        get() {
          return this.$refs.modal && this.$refs.modal.isShown;
        }
      }
    },
    methods: {
      /**
       * Show the main screen of material scanner (not a scanned material).
       *
       * @param {Function} callback - Callback, will be called when screen will be shown.
       */
      show(callback) {
        this.$refs.modal.show(callback);

        this._onScannerInventoryUpdate(this.$friendlyFrankScanner.inventory);
      },
      /**
       * Hide the material scanner screen with the opened materials.
       *
       * @param {Function} callback - Callback, will be called when screen will be hided.
       */
      hide(callback) {
        return this.$refs.modal.hide(callback);
      },
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Handler of scanner inventory-updated event.
       * Shows the material or updates related materials of the already shown material.
       * @async
       *
       * @param {RFIDScannerInventory} inventory - The inventory of rfid scanner.
       */
      async _onScannerInventoryUpdate(inventory) {
        this.selectedMaterialsList = Object.keys(inventory).map(materialId => {
          return get(inventory[materialId], "detail.id");
        }).filter(Boolean);

        if (!this.selectedMaterialsList.includes(this.selectedMaterial)) {
          this.selectedMaterial = this.selectedMaterialsList[0];
        }

        await this.$nextTick();

        if (this.$refs.materialsList && this.shown) {
          this.$refs.materialsList.show();
        }
      }
    },
    mounted() {
      this.$friendlyFrankScanner.on("inventory-updated", this._onScannerInventoryUpdate);
      this.$on("closed", () => {
        this.processingInventory = {};
        this.inventory = [];
      });
    },
    beforeDestroy() {
      this.$friendlyFrankScanner.off("inventory-updated", this._onScannerInventoryUpdate);
    },
    components: {
      "modal-fullscreen": ModalFullscreen,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "materials-list": MaterialsList
    }
  };
</script>
