<template>
  <div class="tags-cloud">
    <div ref="wrapper" class="tags-cloud--wrapper">
      <span
        v-for="tag in shuffled"
        class="tags-cloud--tag"
        :key="tag.title"
        :style="{ fontSize: fontSizePerWeightUnit * tag.weight + minFontSize }"
        @click="$emit('select', tag)"
      >{{ tag.title }}</span>
    </div>
  </div>
</template>

<style lang="less" src="./cloud.less"></style>

<script>
  import getStyle from "../../../lib/utils/get-style.js";

  export default {
    name: "tags-cloud",
    props: {
      /* The list of tags. */
      tags: {
        type: Array,
        default: () => ([])
      },
      /* Minimum font size of tag in cloud. */
      minFontSize: {
        type: Number,
        default: 30
      },
      /* Maximum font size of tag in cloud. */
      maxFontSize: {
        type: Number,
        default: 180
      },
      /* The font size step for scaling in case when the container with tags bigger than available space. */
      adjustStep: {
        type: Number,
        default: 5,
        validator: _adjustStep => _adjustStep > 0
      }
    },
    data() {
      return {
        shuffled: this._shuffle(),
        fontSizePerWeightUnit: this._getFontSizePerWeightUnit(this.maxFontSize, this.minFontSize)
      };
    },
    methods: {
      /**
       * Get the tags in random order.

       */
      _shuffle() {
        return [].concat(this.tags).sort(() => Math.random() - 0.5);
      },
      /**
       * Get the font size per one weight unit.
       *
       * @param {Number} max - Maximum font size of tag in cloud.
       * @param {Number} min - Minimum font size of tag in cloud.
       *
       * @returns {Number} Font size per one weight unit in the same units as the max and min fields.
       */
      _getFontSizePerWeightUnit(max, min) {
        const maxWeigth = this.tags.reduce((maxWeigth, tag) => {
          const tagWeight = parseInt(tag.weight, 10);
          if (tagWeight > maxWeigth) {
            return tagWeight;
          }

          return maxWeigth;
        }, 0);

        return (max - min) / maxWeigth;
      },
      /**
       * Checks if the cloud wrapper placed within container boundaries.
       *
       * @returns {Boolean} true - if the wrapper within the container boundaries.
       */
      _isWrapperHeightValid() {
        const rootHeight = getStyle(this.$el, "height")
          - getStyle(this.$el, "padding-top")
          - getStyle(this.$el, "padding-bottom");
        const wrapperHeight = getStyle(this.$refs.wrapper, "height");

        return rootHeight >= wrapperHeight;
      },
      /**
       * Adjusting the size of tags.
       * @async
       *
       * @returns {Promise} Promise-based callback, will be called when tags will be adjusted.
       */
      async _adjustSize() {
        if (this._isWrapperHeightValid()) {
          return;
        }

        const steps = Math.ceil(this.maxFontSize / this.adjustStep);
        for (let step = 1; step < steps; step++) {
          this.fontSizePerWeightUnit = this._getFontSizePerWeightUnit(
            this.maxFontSize - this.adjustStep * step,
            this.minFontSize
          );
          await this.$nextTick();

          if (this._isWrapperHeightValid()) {
            return;
          }
        }
      }
    },
    mounted() {
      this._adjustSize();
    }
  };
</script>
