<template>
  <easyscreen-search
    ref="search"

    @before-open="(event) => $emit('before-open', event)"
    @opened="(event) => $emit('opened', event)"
    @before-close="(event) => $emit('before-close', event)"
    @closed="(event) => $emit('closed', event)"

    class="easyscreen-menu--search-screen"
    color-scheme="easyscreen-menu"
    :available-only="false"
    :available-only-button="true"
    :popular-searches-type="popularSearchesType !== 'none' ? `inline-${ popularSearchesType }` : 'multiline'"
    :auto-close="true"
  />
</template>

<style lang="less" src="./search.less"></style>

<script>
  import EasyscreenSearch from "../../../search/search.vue";

  export default {
    name: "easyscreen-menu-search-screen",
    props: {
      /* Type of the screen with all popular searches. */
      popularSearchesType: String
    },
    methods: {
      /**
       * Open the map screen.
       */
      show(callback) {
        return this.$refs.search.show(callback);
      },

      /**
       * Close the map screen.
       */
      hide(callback) {
        return this.$refs.search.hide(callback);
      },

      /**
       * Do the search by string.
       *
       * @param {String} string - The search query.
       */
      find(string) {
        this.$refs.search.findBy(string);
      }
    },
    components: {
      "easyscreen-search": EasyscreenSearch
    }
  };
</script>
