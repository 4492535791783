import { isString } from "lodash";
import { parentNodeByFilter, classFilter, htmlElementFilter } from "./parent-node-by-filter.js";

/**
 * Check if the element has the class or equals to target or some
 * of the parents of this node has the class or equals to target.
 *
 * @param {HTMLElement} node - The start element for checking.
 * @param {(String|HTMLElement)} target - The class or html element which should be matched.
 *
 * @returns {Boolean} `true` if the class is matched.
 */
export default function isElementOf(node, target) {
  let check = isString(target) ? classFilter(target) : htmlElementFilter(target);

  return check(node) || !!parentNodeByFilter(node, check);
}
