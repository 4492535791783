<template>
  <div :class="[
    'easyscreen-btj-header',
    `easyscreen-btj-header_${ size }`
  ]">
    <div class="easyscreen-btj-header--logo"></div>
    <p class="easyscreen-btj-header--tagline">Smarta vägar till nya upplevelser av media</p>
  </div>
</template>

<style src="./btj-header.less" lang="less"></style>

<script>
  export default {
    name: "easyscreen-btj-header",
    props: {
      size: {
        type: String,
        default: "small",
        validator: (_type) => ["small", "big"].includes(_type)
      }
    }
  };
</script>
