var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'easyscreen-carousel',
    `easyscreen-carousel_${ _vm.transition }`
  ],style:({ height: _vm.currentCarouselHeight })},[_c('div',{class:[
      'easyscreen-carousel--wrapper',
      {
        'easyscreen-carousel--wrapper_dragging': _vm.dragging
      }
    ],style:(_vm._getWrapperStyles())},_vm._l((_vm.slidesAmount),function(slideNumber){return _c('div',{key:`slide-${ slideNumber + _vm.slideOffset }`,staticClass:"easyscreen-carousel--slide",style:(_vm._getSlideStyles(slideNumber - 1))},[(_vm._slideIsShown(slideNumber - 1))?[_vm._l((_vm.layoutRows),function(rowIndex){return [(!!_vm._get(_vm.$slots, 'default', [])[_vm._getItemIndex(slideNumber - 1, rowIndex - 1, 0)])?_c('div',{key:`row-${ rowIndex }`,staticClass:"easyscreen-carousel--row",style:({ height: `${ 100 / _vm.layoutRows }%` })},[_vm._l((_vm.layoutColumns),function(columnIndex){return [(!!_vm._get(_vm.$slots, 'default', [])[_vm._getItemIndex(slideNumber - 1, rowIndex - 1, columnIndex - 1)])?_c('div',{key:`column-${ columnIndex }`,staticClass:"easyscreen-carousel--item",style:({
                  width: `${ 100 / _vm.layoutColumns }%`
                })},[_c('get-component',{attrs:{"data":_vm.$slots.default,"path":_vm._getItemIndex(slideNumber - 1, rowIndex - 1, columnIndex - 1)}})],1):_vm._e()]})],2):_vm._e()]})]:_vm._e()],2)}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }