<template>
  <div>
    <modal-blank
      :class="['materials-list-modal', 'materials-list-modal_' + design, orientation]"

      ref="modal"

      :auto-close="autoClose"
      @before-open="(event) => $emit('before-open', event)"
      @opened="(event) => {
        $emit('opened', event);
        $refs.swipeExample && $refs.swipeExample.show();
      }"
      @before-close="(event) => {
        $emit('before-close', event);
        $refs.swipeExample && $refs.swipeExample.hide();
      }"
      @closed="(event) => {
        _emitInnerModalsClose();
        $emit('closed', event);
      }"

      min-width="100%"
      min-height="100%"
      :position="modalPosition"
    >
      <template slot="raw">
        <easyscreen-circle-button
          v-if="useControls"
          class="materials-list--close-button materials-list--close-button_top-right"
          icon="/images/es-menu/close_icon.png"
          icon-type="image"

          @click.native="hide"
        />
        <easyscreen-carousel
          ref="carousel"
          class="materials-list"
          :suspended="controls == false"
          :swipe-only="true"
          :layout-columns="1"
          :layout-rows="1"
          :key="JSON.stringify(materials) + selected"
          :defaultPosition="_getMaterialIndex(selected) * -100"
          :additionalVisibleSlides="$easyscreenConfig.get('itemPopup.preload')"
          @before-position-change="_adjustNavigationButtons"
          @position-changed="(index) => _sendMaterialOpenStatistic(materials[index])"
        >
          <component
            class="materials-list--element"
            :is="materialDetailComponentName"
            v-for="material in materials"
            :key="material"
            :id="material"
            :use-cache="useCache"
            :use-loan="useLoan"
            :use-suggested-lists="useSuggestedLists"
            :is-first-material="_isFirstMaterial(material)"
            :is-last-material="_isLastMaterial(material)"
            @next-material="() => nextMaterial()"
            @previous-material="() => previousMaterial()"
            @detail-loaded="(detail) => _updateShortDetail(detail)"
            @select-tag="(tag) => $emit('select-tag', tag)"
            @open-loan="() => $emit('open-loan')"
            @suggested-material="(list, material) => {
              materials = list;
              selected = material;
            }"
            @before-open-inner-modal="(closeModal, type) => {
              $emit('modal-opened', closeModal, type || 'materialsListInnerModal')
              _addOpenedModal(type || 'materialsListInnerModal');
            }"
            @closed-inner-modal="(type) => {
              $emit('closed-inner-modal', type || 'materialsListInnerModal');
              _removeOpenedModal(type || 'materialsListInnerModal');
            }"
          />
        </easyscreen-carousel>

        <!-- START: Navigation controls, previous slide button. -->
        <easyscreen-button
          v-if="!firstSelected && useControls && design !== 'light'"
          class="materials-list--navigation-button materials-list--navigation-button_previous"
          size="medium"
          @click.native="() => previousMaterial()"
        >
          <i class="materials-list--navigation-button-icon fal fa-chevron-left"></i>
          {{ _l10n("Previous") }}
        </easyscreen-button>
        <!-- END: Navigation controls, previous slide button. -->

        <!-- START: Navigation controls, previous slide button. -->
        <easyscreen-button
          v-if="!lastSelected && useControls && design !== 'light'"
          class="materials-list--navigation-button materials-list--navigation-button_next"
          size="medium"
          @click.native="() => nextMaterial()"
        >
          {{ _l10n("Next") }}
          <i class="materials-list--navigation-button-icon fal fa-chevron-right"></i>
        </easyscreen-button>
        <!-- END: Navigation controls, previous slide button. -->

        <easyscreen-circle-button
          v-if="useControls"
          class="materials-list--close-button materials-list--close-button_bottom-right"
          :icon="design === 'light' ? '/images/es-menu/close_icon_design-light.png' : '/images/es-menu/close_icon.png'"
          :color="design === 'light' ? 'outline-black' : undefined"
          icon-type="image"

          @click.native="hide"
        />

        <swipe-example
          v-if="design === 'light'"
          ref="swipeExample"
          class="materials-list--swipe-example"
        />
      </template>
    </modal-blank>
  </div>
</template>

<style src="./materials-list.less" lang="less"></style>
<script>
  import lodash from "lodash";
  import l10n from "@/lib/localization/localization.js";

  import ModalBlank from "../core/modal/blank.vue";
  import EasyscreenButton from "../core/button/button.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import EasyscreenCarousel from "../core/carousel/carousel.vue";
  import SwipeExample from "../core/swipe-example/swipe-example.vue";
  import MaterialDetail from "./material-detail.vue";
  import MaterialDetailLight from "./material-detail-light.vue";

  import orientationMixin from "../core/mixins/orientation.js";

  export default {
    name: "materials-list",
    mixins: [orientationMixin],
    props: {
      /* The global reskin for materials list. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      },
      /* List of materials id's (faustnumbers). */
      defaultMaterials: {
        type: Array,
        default: () => ([])
      },
      /* Use the cache for material detail - useful for non dynamic lists. */
      useCache: {
        type: Boolean,
        default: true
      },
      /* The id (faustnumber) of material shown by default (on .show call). */
      defaultSelected: String,
      /* Flag for use the loan button instead of reservation. See default for material-detail.vue */
      useLoan: Boolean,
      /* Flag of the suggested lists for material detail. See default for material-detail.vue */
      useSuggestedLists: {
        type: Boolean,
        default() {
          return this.$easyscreenConfig.get("enable.suggestedLists");
        }
      },
      /* Manually enable or disable navigation controls. */
      withNavigationControls: {
        type: Boolean,
        default: null
      },
      /* Use controls independent of design. */
      forceUseControls: Boolean,
      /* Close the popup when standby is started. */
      autoClose: {
        type: Boolean,
        default: true
      },
      position: String
    },
    computed: {
      useControls() {
        if (this.withNavigationControls != null)
          return this.controls && this.withNavigationControls;

        return this.controls && (this.design !== "light" || this.forceUseControls);
      },
      materialDetailComponentName() {
        if (this.design === "light") {
          return "material-detail-light";
        }

        return "material-detail";
      },
      /* Proxy for modal isShown variable. */
      isShown: {
        cache: false,
        get() {
          return this.$refs.modal && this.$refs.modal.isShown;
        }
      },
      modalPosition() {
        if (this.position != null)
          return this.position;

        if (this.design === "light")
          return "absolute";

        return "fixed";
      }
    },
    data() {
      return {
        materials: this.defaultMaterials,
        selected: this.defaultSelected,
        firstSelected: false,
        lastSelected: false,
        controls: true,
        openedModals: [],
        shortDetail: {}
      };
    },
    watch: {
      defaultMaterials(_defaultMaterials) {
        this.materials = _defaultMaterials;
      },
      defaultSelected(_defaultSelected) {
        this.selected = _defaultSelected;
      }
    },
    methods: {
      /**
       * Show the current modal. The promise will be returned in case when callback is not provided.
       * @async
       *
       * @param {Function} [callback] - The show callback, will be called when modal is opened.
       */
      show(callback) {
        this.materials = this.defaultMaterials;
        this.selected = this.defaultSelected;

        this._adjustNavigationButtons(0, this.materials.indexOf(this.selected));

        this._sendMaterialOpenStatistic(this.selected);
        return this.$refs.modal.show(callback);
      },
      /**
       * Hide the current modal. The promise will be returned in case when callback is not provided.
       * @async
       *
       * @param {Function} [callback] - The hide callback, will be called when modal is closed.
       */
      hide(callback) {
        return this.$refs.modal.hide(callback);
      },
      /*
       * Scroll to the next materail in the list
       */
      nextMaterial() {
        if (!this.$refs.carousel)
          return;

        return this.$refs.carousel.setOffset(this.$refs.carousel.getOffset() - 100);
      },
      /*
       * Scroll to the previous materail in the list
       */
      previousMaterial() {
        if (!this.$refs.carousel)
          return;

        return this.$refs.carousel.setOffset(this.$refs.carousel.getOffset() + 100);
      },
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Show or hide navigation buttons (next, previous) for selected slide.
       *
       * @param {Number} currentSliderPosition - The Index of selected slide.
       * @param {Number} newSliderPosition - The index of slide which will be selected.
       */
      _adjustNavigationButtons(currentSliderPosition, newSliderPosition) {
        this.firstSelected = false;
        this.lastSelected = false;

        if (newSliderPosition <= 0) {
          this.firstSelected = true;
        }

        if (newSliderPosition >= this.materials.length - 1) {
          this.lastSelected = true;
        }
      },
      _addOpenedModal(type) {
        this.openedModals.push(type);

        if (this.openedModals.length > 0) {
          this.controls = false;
        }
      },
      _removeOpenedModal(type) {
        this.openedModals = this.openedModals.filter(_type => _type !== type);

        if (this.openedModals.length === 0) {
          this.controls = true;
        }
      },
      _emitInnerModalsClose() {
        this.openedModals.forEach(type => {
          this.$emit("closed-inner-modal", type);
        });

        this.openedModals = [];
        this.controls = true;
      },
      _getMaterialIndex(id) {
        const directMatchIndex = this.materials.indexOf(id);
        if (directMatchIndex !== -1)
          return directMatchIndex;

        return this.materials.findIndex(_id => _id.indexOf(id) !== -1);
      },
      _sendMaterialOpenStatistic(id) {
        const title = lodash.get(this.shortDetail, `['${ id }'].title`);
        if (title) {
          this.$easyscreenStatistic.openMaterial({ title, faustNumber: id });
          return;
        }

        this.$once(`detail-loaded:${ id }`, (detail) => {
          this.$easyscreenStatistic.openMaterial({ title: detail.title, faustNumber: id });
        });
      },
      _updateShortDetail(detail) {
        this.shortDetail[detail.id] = this.shortDetail[detail.id] || {};
        this.shortDetail[detail.id].title = detail.title;

        this.$emit(`detail-loaded:${ detail.id }`, detail);
      },
      _isFirstMaterial(material) {
        return this.materials.indexOf(material) === 0;
      },
      _isLastMaterial(material) {
        return this.materials.indexOf(material) === this.materials.length - 1;
      }
    },
    components: {
      "modal-blank": ModalBlank,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "easyscreen-carousel": EasyscreenCarousel,
      "swipe-example": SwipeExample,
      "material-detail": MaterialDetail,
      "material-detail-light": MaterialDetailLight
    }
  };
</script>
