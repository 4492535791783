import { get, extend } from "lodash";

import FriendlyFrank from "./core.js";
import l10n from "@/lib/localization/localization.js";
import { ValidationError } from "../lib/errors";

/**
 * The interface of es-linux-apps printer.
 *
 * @class Printer
 *
 * @param {object} options - The connection options.
 * @param {String} options.uri - Connection uri (like: `ws://localhost:9211`).
 * @param {Number} [options.reopenTimeout=2000] - The reconnection interval in ms. Used if the connection is not established or lost.
 * @param {Number} [options.reopenAttempts=5] - Amonut of total reconnections in row.
 *
 * @returns {Printer} - instace of Printer class.
 */
export default class Printer {
  constructor(options) {
    options = options || {};
    extend(this, options);

    this.printerConfig = {};
    this.printerMessages = [];

    this._init(options);
  }

  /**
   * Closes connection and destroys the FriendlyFrank instance.
   */
  destroy() {
    this._destroyed = true;

    if (this._friendlyFrank) {
      this._friendlyFrank.destroy();
    }
  }

  /**
   * Inialization of barcode scanner. Creates the instance of Friendly Frank.
   * @listens module:FriendlyFrank~action:printer-handshake
   * @listens module:FriendlyFrank~action:device-error
   *
   * @param {object} options - The connection options.
   * @param {String} options.uri - Connection uri (like: `ws://localhost:9211`). Required.
   * @param {Number} [options.reopenTimeout=2000] - The reconnection interval in ms. Used if the connection is not established or lost.
   * @param {Number} [options.reopenAttempts=5] - Amonut of total reconnections in row.
   * @param {Boolean} [options.autoConnect=true] - Connecting on instantiate.
   */
  _init(options) {
    this._friendlyFrank = new FriendlyFrank(Object.assign({}, options));
    this._friendlyFrank.on("action:printer-handshake", (message) => {
      if (message.features.indexOf("printer-status") !== -1) {
        this.enabled = true;
        this.printerConfig = message.config;
      }
    });
    this._friendlyFrank.on("action:device-error", ({ device, messages }) => {
      if (device === "printer") {
        this.printerMessages = messages;

        if (!this.isOnline()) {
          this._notifyStaff().catch(error => {
            console.error("Staff notification error", error);
          });
        }
      }
    });
  }

  /**
   * Checks if the printer have no critical errors.
   *
   * @returns {Boolean} true - all is fine.
   */
  isOnline() {
    return this.printerMessages.every((message) => message.code !== "CRITICAL_ERROR");
  }

  /**
   * @async
   * Request to print.
   *
   * @param {Object} options - The print params.
   * @param {String} options.text - The text to print.
   * @param {Boolean} options.example - The flag for log the text into terminal instead of printing.
   *
   * @returns {Promise<Object>} Promise-based callback.
   */
  print(options) {
    options = options || {};
    if (!options.text) {
      throw new ValidationError({ message: "option 'text' are required." });
    }

    return this._friendlyFrank.request({
      action: "print",
      text: options.text,
      example: options.example
    });
  }

  /**
   * The notification message with title.
   *
   * @typedef {Object} NotificationMessage
   *
   * @property {String} title - The message title.
   * @property {String} message - The message body.
   */
  /**
   * Get the printer error message for users based on settings of
   * easyscreen client and screen data.
   *
   * @param {Object} options - The fallback options.
   * @param {String} [options.message] - The fallback message.
   *
   * @returns {NotificationMessage}
   */
  getUserErrorNotification(options, config) {
    options = options || {};

    let title = get(config, "printerErrors.usersMessage.title")
      || l10n("Printer is unavailable");
    let message = get(window.screenControl, "data.screenOptions.notifications.error_message")
      || get(config, "printerErrors.usersMessage.message")
      || options.message
      || l10n("We are not able to print a receipt for you at the moment. Are you sure you wanna continue?");

    return FriendlyFrank.getNotification({ title, message }, {
      tmid: this.printerConfig.tmid,
      withDefaultMessage: true
    });
  }
  /**
   * Sends the notification email for staff if printer app have unsent error messages.
   */
  async _notifyStaff() {
    if (!this.enabled) {
      return;
    }

    let messagesForSend = this.printerMessages.filter(function(message) {
      return message.code !== "NOTIFICATION" && !message.sent && !message.sending;
    });

    var emails = get(window.screenControl, "data.screenOptions.notifications.email_notification", []);
    if (messagesForSend.length === 0 || !Array.isArray(emails) || emails.length === 0) {
      return;
    }

    let data = await this._friendlyFrank.request({
      action: "printer-notifications_sending",
      messages: messagesForSend
    });

    if (!data || !data.messages || data.messages.length === 0) {
      return;
    }

    const notification = FriendlyFrank.getStaffNotification({
      tmid: this.printerConfig.tmid,
      messages: data.messages
    }, this.easyscreenConfig);

    let sendEmailError;
    try {
      await this.request.email({
        to: emails.join(","),
        html: notification.message,
        subject: notification.title
      });
    } catch (error) {
      sendEmailError = error;
    }

    await this._friendlyFrank.request({
      action: "printer-notifications_sent",
      messages: data.messages,
      isSent: !sendEmailError
    });
  }
}
