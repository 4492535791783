<template>
  <modal-fullscreen
    ref="modal"

    colorScheme="easyscreen-menu"

    @before-open="(event) => $emit('before-open', event)"
    @opened="(event) => $emit('opened', event)"
    @before-close="(event) => $emit('before-close', event)"
    @closed="(event) => $emit('closed', event)"

    class="easyscreen-menu--map-screen"
  >
    <template slot="header-left">
      <easyscreen-circle-button
        icon="/images/es-menu/home_icon.png"
        icon-type="image"

        @click.native="hide"
      />
    </template>
    <template slot="header-center">
      <h1 class="easyscreen-header_1">
        {{ _l10n(title) }}
      </h1>
    </template>
    <template slot="content">
      <easyscreen-iframe :url="_getMapLink()" :overlay="false" />
    </template>
    <template slot="footer">
    </template>
  </modal-fullscreen>
</template>

<style lang="less">
  .easyscreen-menu--map-screen {
    & > .vm--modal > .v-modal--content > .modal-layout > .modal-layout--content {
      padding: 0 !important;
    }
  }
</style>

<script>
  import * as querystring from "querystring";
  import l10n from "@/lib/localization/localization.js";

  import ModalFullscreen from "../../core/modal/fullscreen.vue";
  import EasyscreenCircleButton from "../../core/button/circle-button.vue";
  import EasyscreenIframe from "../../core/iframe/iframe.vue";

  export default {
    name: "easyscreen-menu-map-screen",
    props: {
      viewId: [String, Number],
      markId: [String, Number],
      color: String
    },
    data() {
      return {
        title: l10n("Map")
      };
    },
    methods: {
      /**
       * Open the map screen.
       */
      show() {
        this.$refs.modal.show();
      },

      /**
       * Close the map screen.
       */
      hide() {
        this.$refs.modal.hide();
      },
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Get the link to wayfinder (easyway) map with selected viewId and markId.
       *
       * @returns {String} The full url.
       */
      _getMapLink() {
        const urlOptions = querystring.parse(window.location.href.split("#")[1]);
        const here = urlOptions.here ? `&here=${ urlOptions.here }` : "";
        const color = this.color && this.color !== "#000000" ? `&color=${ window.encodeURIComponent(color) }` : "";

        return `${ this.$easyscreenConfig.get("wayfinder.url") }?view=${ this.viewId }&type=es-menu${ color }#markId=${ this.markId }${ here }`;
      }
    },
    async mounted() {
      try {
        const viewTitles = await this.$easyscreenRequest.wayfinder.viewTitle({
          viewId: this.viewId
        });
        this.title = viewTitles[this.$easyscreenConfig.get("wayfinder.lang")] || viewTitles["en"];
      } catch (error) {
        console.error("Can't get wayfinder view title.", error);
      }
    },
    components: {
      "modal-fullscreen": ModalFullscreen,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "easyscreen-iframe": EasyscreenIframe
    }
  };
</script>
