<template>
  <div
    :class="[
      'materials-scanner-material',
      { 'materials-scanner-material_disabled': disabled }
    ]">
    <div
      v-if="status === 'LOADING'"
    >
      <div class="materials-scanner-material--placeholder">
        <i class="fa fa-spin fa-spinner"></i>
      </div>
    </div>
    <div
      v-else-if="status === 'NOT_FOUND'"
      class="materials-scanner-material--placeholder text-upper"
    >
      {{ _l10n("No material found") }}
      <br>
      <span class="text-small">({{ _l10n("Material number") }}: {{ material.materialId }})</span>
    </div>
    <div
      v-else-if="status === 'UNKNOWN'"
      class="materials-scanner-material--placeholder text-upper text-danger text-center"
    >
      {{ _l10n("Unknown material type") }}
      <br>
      <span class="text-small text-danger">({{ _l10n("Material number") }}: {{ material.materialId }})</span>
    </div>
    <div
      v-else-if="material"
      class="materials-scanner-material--content"
    >
      <div>
        <img class="materials-scanner-material--image" :src="material.cover || '/images/defaultCover.jpg'" />
      </div>
      <div>
        <h4 class="es-mb-2">{{ material.title }}</h4>
        <p class="es-mb-4">{{ material.author }}</p>
        <p>
          {{ _l10n("Material number") }}: {{ material.materialId }}
          <span v-if="!material.isComplete && !disabled" class="text-primary text-cursive">
            ({{ _l10n("materials in pack") }}:
            <span
              v-for="index in material.packSize"
              :key="index"
              :class="{
                'text-warn': !material.indexes.includes(index),
                'text-primary': material.indexes.includes(index)
              }"
            >{{ index }}{{ index !== material.packSize ? ", " : "" }}</span>)
          </span>
        </p>
      </div>
      <div>
        <div class="materials-scanner-material--status">
          <span>
            <i :class="['materials-scanner-material--icon', 'fa', {
              'fa-spin fa-spinner': status === 'IN_PROGRESS',
              'fa-check': status === 'ACCEPTED',
              'fa-times': ['CANCELED', 'REJECTED'].includes(status),
              'text-primary': status === 'ACCEPTED',
              'text-warn': status === 'CANCELED',
              'text-danger': status === 'REJECTED'
            }]"></i>
          </span>
          <p v-if="message" v-html="message"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="less" src="../../core/mixins.less"></style>
<style lang="less" src="./material.less"></style>

<script>
  import l10n from "@/lib/localization/localization.js";

  export default {
    name: "materials-scanner-material",
    props: {
      disabled: Boolean,
      material: Object,
      message: String,
      status: {
        type: String,
        default: "LOADING",
        validator: _status => [
          "LOADING",
          "NOT_FOUND",
          "UNKNOWN",
          "IN_PROGRESS",
          "ACCEPTED",
          "CANCELED",
          "REJECTED",
          "WAITING"
        ].includes(_status)
      }
    },
    methods: {
      /* Proxy for localization function. */
      _l10n: l10n
    }
  };
</script>
