<template>
  <div class="easyscreen-volume-control">
    <i
      :class="[
        'easyscreen-volume-control--icon',
        'fal',
        icon
      ]"
      @click="toggleMute"
    ></i>
    <easyscreen-progress-bar
      ref="dndContainer"
      class="easyscreen-volume-control--progress"
      end-marker="circle"
      :from="0"
      :to="100"
      :progress="muted ? 0 : volume"
    />
  </div>
</template>

<style src="./volume-control.less" lang="less"></style>

<script>
  import Hammer from "hammerjs";
  import { clamp } from "lodash";
  import debounceDrop from "@/lib/utils/debounce-drop.js";
  import getStyle from "@/lib/utils/get-style.js";
  import orientationMixin from "../mixins/orientation.js";
  import hammerjsToolsMixin from "../mixins/hammerjs-tools.js";

  import EasyscreenProgressBar from "../progress-bar/progress-bar.vue";

  /**
   * The event fires when the volume of content has been changed by user actions or programmatically.
   *
   * @event easyscreen-volume-control#volume-changed
   * @type {Number} - Volume level in range 0-100.
   */

  /**
   * The event fires when the volume of content has been muted by user actions or programmatically.
   *
   * @event easyscreen-volume-control#muted
   */

  /**
   * The event fires when the volume of content has been unmuted by user actions or programmatically.
   *
   * @event easyscreen-volume-control#unmuted
   */

  export default {
    name: "easyscreen-volume-control",
    mixins: [orientationMixin, hammerjsToolsMixin],
    props: {
      /* Default volume level. */
      defaultVolume: {
        type: Number,
        default: 50
      },
      /* Default mute state. */
      defaultMuted: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      /**
       * Getter of sound icon - off, low, normal, high.
       *
       * @returns {String}
       */
      icon() {
        if (this.muted || this.volume === 0) {
          return "fa-volume-off";
        } else if (this.volume <= 33) {
          return "fa-volume-down";
        } else if (this.volume > 33 && this.volume <= 66) {
          return "fa-volume";
        } else {
          return "fa-volume-up";
        }
      }
    },
    data() {
      return {
        muted: this.defaultMuted,
        volume: this.defaultVolume
      };
    },
    methods: {
      /**
       * Set volume.
       * @fires easyscreen-volume-control#volume-changed
       *
       * @param {Number} volume - The volume level. Must be in range 0-100. Note: 0 volume not equal to mute.
       */
      setVolume(volume) {
        volume = clamp(volume, 0, 100);
        if (this.volume === volume) {
          return;
        }

        this.volume = volume;
        this.$emit("volume-changed", this.volume);
      },
      /**
       * Get the colume level.
       *
       * @returns {Number} Volume level in range 0-100.
       */
      getVolume() {
        return this.volume;
      },
      /**
       * Mutes sound.
       * @fires easyscreen-volume-control#muted
       */
      mute() {
        if (this.muted === true) {
          return;
        }

        this.muted = true;
        //this.$emit("volume-changed", 0);
        this.$emit("muted");
      },
      /**
       * Unmutes sound.
       * @fires easyscreen-volume-control#unmuted
       */
      unmute() {
        if (this.muted === false) {
          return;
        }

        this.muted = false;
        //this.$emit("volume-changed", this.volume);
        this.$emit("unmuted");
      },
      /**
       * Toggle mute based on mute state.
       * @fires easyscreen-volume-control#muted
       * @fires easyscreen-volume-control#unmuted
       */
      toggleMute() {
        if (this.muted) {
          this.unmute();
        } else {
          this.mute();
        }
      },
      /**
       * Hanlder of mouse click for set volume.
       *
       * @param {Event} event - The native mousedown event.
       */
      _MouseDownHandlerByVolumeProgressBar(event) {
        this.unmute();

        const boundaries = this.$refs.dndContainer.$el.getBoundingClientRect();
        const volume = (event.clientX - boundaries.left) / boundaries.width * 100;
        this.setVolume(volume);
      },
      /**
       * Initialize the volume set by clicking on volume control GUI.
       */
      _initMouseDownBasedVolumeEdit() {
        this._destroyMouseDownBasedVolumeEdit();

        this.$refs.dndContainer.$el.addEventListener("mousedown", this._MouseDownHandlerByVolumeProgressBar);
      },
      /**
       * Destroy the volume set by clicking on volume control GUI.
       */
      _destroyMouseDownBasedVolumeEdit() {
        this.$refs.dndContainer.$el.removeEventListener("mousedown", this._MouseDownHandlerByVolumeProgressBar);
      },
      /**
       * Initialize the volume set by click and grad on volume control GUI.
       */
      _initDragAndDropVolumeEdit() {
        this._destroyDragAndDropVolumeEdit();

        this._hammerInstance = new Hammer(this.$refs.dndContainer.$el);
        this._hammerInstance.get("pan").set({
          direction: Hammer.DIRECTION_HORIZONTAL,
          threshold: 0
        });

        let startX;
        let progressBarWidth;
        let volume;
        this._hammerInstance.on("panstart", (e) => {
          this.unmute();

          if (this._isHammerjsPrevented()) {
            return true;
          }

          this._preventHammerjs();

          startX = e.center.x;
          progressBarWidth = getStyle(this.$refs.dndContainer.$el, "width");
          volume = this.getVolume();
        });

        this._hammerInstance.on("panleft panright panend", debounceDrop((e) => {
          if (this._isHammerjsPrevented()) {
            return true;
          }

          let volumeDelta = this._applyScreenScale(e.center.x - startX) / progressBarWidth * 100;
          this.setVolume(volume + volumeDelta);
        }, 16)); // Timeout for events due to next animation frame (the number of calls is usually 60 times per second).

        this._hammerInstance.on("panend", () => {
          if (this._isHammerjsPrevented()) {
            return true;
          }

          /* Required for prevent click events after when the sliding is finished. */
          setTimeout(() => {
            this._allowHammerjs();
          }, 50);
        });
      },
      /**
       * Destroy the volume set by click and grad on volume control GUI.
       */
      _destroyDragAndDropVolumeEdit() {
        if (this._hammerInstance) {
          this._hammerInstance.destroy();
        }
      }
    },
    mounted() {
      this._initMouseDownBasedVolumeEdit();
      this._initDragAndDropVolumeEdit();
    },
    beforeDestroy() {
      this._destroyMouseDownBasedVolumeEdit();
      this._destroyDragAndDropVolumeEdit();
    },
    components: {
      "easyscreen-progress-bar": EasyscreenProgressBar
    }
  };
</script>
