<template>
  <div
    :class="[
      'node-list-carousel-bronbib-slide',
      { 'node-list-carousel-bronbib-slide_is-medium-scren': isMediumScreen }
    ]"
    :style="{
      backgroundImage: backgroundImage ? `url('${ backgroundImage }')` : null
    }"
  >
    <div
      v-if="qrCode"
      class="node-list-carousel-bronbib-slide--qr-code"
      :style="{ backgroundImage: `url('${ $easyscreenRequest.lmsConnector.wrapCover(qrCode) }')` }"
    ></div>
    <div class="node-list-carousel-bronbib-slide--content">
      <h1 class="node-list-carousel-bronbib-slide--title">
        {{ title }} -
        <span
          v-if="isEvent"
          class="node-list-carousel-bronbib-slide--location"
        >{{ location }}</span>
      </h1>
      <div
        v-if="isEvent"
        class="node-list-carousel-bronbib-slide--meta"
      >
        <div class="node-list-carousel-bronbib-slide--date">
          {{ formattedDate }}
          <span
            v-if="price"
            class="node-list-carousel-bronbib-slide--price"
          >
            - {{ _l10n("Price:") }} {{ price }}
          </span>
        </div>
      </div>
      <div class="node-list-carousel-bronbib-slide--teaser ck-editor" v-html="teaser"></div>
    </div>
  </div>
</template>

<style src="../../core/ck-editor.less" lang="less"></style>
<style src="./node-list-carousel-bronbib-slide.less" lang="less"></style>

<script>
  import moment from "moment";
  import l10n from "@/lib/localization/localization.js";
  import resizeMixin from "@/components/core/mixins/resize";

  const dateFormat = "dddd. [d]. D MMMM";
  const timeFormat = "[kl]. HH.mm";
  const secondaryTimeFormat = "HH.mm";

  export default {
    name: "node-list-carousel-bronbib-slide",
    mixins: [resizeMixin],
    props: {
      type: String,
      date: String,
      dateTo: String,
      location: String,
      price: [String, Number],
      bgImage: String,
      image: String,
      title: String,
      esTeaser: String,
      teaser: String,
      text: String,
      lead: String,
      qrCode: String
    },
    computed: {
      isEvent() {
        return this.type === "event";
      },
      backgroundImage: {
        get() {
          return this.bgImage || this.image;
        }
      },
      formattedDate: {
        get() {
          const dateFrom = moment(this.date);
          const dateTo = moment(this.dateTo);
          let date = moment();

          if (date < dateFrom) {
            date = dateFrom;
          } else if (date > dateTo) {
            date = dateTo;
          }

          date.hour(dateFrom.hour());
          date.minute(dateFrom.minute());

          let formattedDateFrom = date.format(dateFormat);
          let formattedDateTo = dateTo.format(dateFormat);

          let formattedDate = `${ formattedDateFrom } ${ date.format(timeFormat) } - `;
          if (formattedDateFrom !== formattedDateTo) {
            formattedDate += `${ formattedDateTo } ${ dateTo.format(timeFormat) }`;
          } else {
            formattedDate += dateTo.format(secondaryTimeFormat);
          }

          return formattedDate;
        }
      }
    },
    methods: {
      /* Proxy for localization function. */
      _l10n: l10n
    }
  };
</script>
