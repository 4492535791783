var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'digital-shelf--cover',
    `digital-shelf--cover_align-${ _vm.align }`,
    {
      'digital-shelf--cover_without-cover-background': !_vm.withCoverBackground,
      'digital-shelf--cover_is-default-cover': _vm.isDefaultCover || _vm.isLoaded === false,
      [`digital-shelf--cover_horizontal-align-${ _vm.horizontalAlign }`]: _vm.horizontalAlign === 'center'
    }
  ],style:({
    width: _vm.width
  })},[_c('div',{staticClass:"digital-shelf--cover-bg"},[_c('div',{staticClass:"digital-shelf--cover-wrapper",style:({
        backgroundImage: `url('${ _vm.blurredCover }'), url('/images/defaultCover.blur.jpg')`
      })}),(_vm.isDefaultCover || _vm.isLoaded === false)?_c('div',{staticClass:"digital-shelf--cover-wrapper_cover"},[_c('p',{staticClass:"digital-shelf--cover-wrapper_title"},[_vm._v(_vm._s(_vm.title))]),_c('p',{staticClass:"digital-shelf--cover-wrapper_author"},[_vm._v(_vm._s(_vm.author))])]):_vm._e(),(_vm.isDefaultCover !== true)?_c('div',{class:[
        'digital-shelf--cover-wrapper_image',
        { 'digital-shelf--cover-wrapper_loading': _vm.isLoaded === false }
      ]},[_c('img',{ref:"image",attrs:{"draggable":"false","src":_vm.$easyscreenRequest.lmsConnector.wrapCover(_vm.cover),"crossOrigin":"annonimus"}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }