import * as querystring from "querystring";
import { pickBy, cloneDeep } from "lodash";

export default {
  methods: {
  /* [major] TODO: Rewrite to using of wayfinder mixin. */
  /**
   * Check the wayfinder points from material holdings and add the url to view if the point exists.
   * @async
   *
   * @param {Object[]} holdings - The list of material holdings.
   * @param {Object} [wayfinderOptions] - The options of the wayfinder.
   * @param {String} [wayfinderOptions.language] - The prefferent wayfinder language.
   * @param {String} [wayfinderOptions.url] - The base url of the wayfinder service.
   *
   * @returns {Object[]} - The list of material holdings with loaded view urls.
   */
    async _fetchWayfinderView(holdings, wayfinderOptions) {
      const wayfinderUrl = this.$easyscreenConfig.get("wayfinder.url");
      const wayfinderDisabled =
        !wayfinderUrl
        || (wayfinderUrl.includes("//localhost") && this.$easyscreenConfig.get("debug.enable") !== true)
        || this.$easyscreenConfig.get("debug.wayfinderDisabled") === true;

      if (wayfinderDisabled) {
        return holdings;
      }

      holdings = cloneDeep(holdings);
      wayfinderOptions = wayfinderOptions || {};

      const urlOptions = querystring.parse(window.location.href.split("#")[1]);
      for (let holding of holdings) {
        if (holding.wayfinderPoint) {
          try {
            /*
             * Delete the empty keys from wayfinder query.
             * Null and undefined fields will be filtered out automatically
             * but the empty string values must be removed manually.
             */
            Object.keys(holding.wayfinderPoint).forEach(key => {
              if (!holding.wayfinderPoint[key])
                delete holding.wayfinderPoint[key];
            });

            const placementData = await this.$easyscreenRequest.wayfinder.includes(holding.wayfinderPoint);

            let searchQuery = querystring.stringify(pickBy({
              view: placementData.viewId,
              lang: wayfinderOptions.language
            }, Boolean));

            let hashQuery = querystring.stringify(pickBy({
              map: placementData.map,
              markId: placementData.markId,
              here: urlOptions.here
            }, Boolean));

            holding.wayfinderView = `${ wayfinderOptions.url }?${ searchQuery }#${ hashQuery }`;
          } catch (error) {
            if (error.code !== "NOT_FOUND_ERROR") {
              console.error("Can't fetch wayfinder point:", error);
            }
          }
        }
      }

      return holdings;
    }
  }
};
