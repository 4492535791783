import ColorThief from "colorthief";
import ColorMixer from "color-mixer";
import checkImageStatus from "./check-image-status.js";

const colorThief = new ColorThief();

export default function getDominantImageColor(image) {
  const imageStatus = checkImageStatus(image);
  const isDefaultImage = ((image && image.src) || "").includes("defaultCover.jpg");

  if (imageStatus === -1 || isDefaultImage) {
    return null;
  }

  if (imageStatus === 1) {
    let dominantColor = colorThief.getColor(image);
    if (dominantColor) {
      dominantColor = new ColorMixer.Color({ rgb: dominantColor }).hex();
    }

    return dominantColor;
  }

  return new Promise(resolve => {
    image.addEventListener("load", function() {
      resolve(getDominantImageColor(image));
      colorThief.getColor(image);
    });
  });
}
