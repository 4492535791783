<template>
  <div class="digital-shelf--tags">
    <template v-for="(tag, index) in tags">
      <div
        :class="['digital-shelf--tags_tag', { 'digital-shelf--tags_tag-active': index === active }]"
        :key="tag"
        v-on:click="select(index)"
      >
        {{ tag }}
      </div>
      <br v-if="tagsPerLine && i % tagsPerLine === tagsPerLine - 1" :key="tag + '-br'">
    </template>
  </div>
</template>

<style lang="less" src="./tags.less" />
<script>
  export default {
    name: "digital-shelf_tags",
    props: {
      /* List of tags */
      tags: {
        type: Array,
        default: function() {
          return [];
        }
      },
      /* Index of active tag */
      active: {
        type: Number,
        default: 0
      },
      /* Maximum amount of tags in one line. */
      tagsPerLine: {
        type: Number,
        default: 0
      }
    },
    methods: {
      /**
       * Hanlder of tag click event.
       *
       * @param {Number} index - Index of clicked tag.
       */
      select: function(index) {
        if (this.active === index) {
          return;
        }

        this.$emit("selected", index);
      }
    }
  };
</script>
