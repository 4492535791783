<template>
  <modal-fullscreen
    ref="modal"

    colorScheme="easyscreen-menu"

    @before-open="(event) => $emit('before-open', event)"
    @opened="(event) => $emit('opened', event)"
    @before-close="(event) => $emit('before-close', event)"
    @closed="(event) => $emit('closed', event)"

  >
    <template slot="header-left">
      <easyscreen-circle-button
        v-if="hideButton === 'home'"
        icon="/images/es-menu/home_icon.png"
        icon-type="image"

        @click.native="hide"
      />
    </template>
    <template slot="header-right">
      <easyscreen-circle-button
        v-if="hideButton === 'close'"
        icon="/images/es-menu/close_icon.png"
        icon-type="image"

        @click.native="hide"
      />
    </template>
    <template slot="header-center">
      <h1 class="easyscreen-header_1">
        {{ _l10n('Popular searches') }}
      </h1>
    </template>
    <template slot="content">
      <tags-cloud
        v-if="fullType === 'cloud'"
        :tags="popularSearches"
        @select="(tag) => hide(() => $emit('select', tag))"
      />
      <tags-list
        v-if="fullType === 'list'"
        :tags="popularSearches"
        @select="(tag) => hide(() => $emit('select', tag))"
      />
    </template>
    <template slot="footer">
    </template>
  </modal-fullscreen>
</template>

<script>
  import l10n from "@/lib/localization/localization.js";

  import ModalFullscreen from "../core/modal/fullscreen.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import TagsCloud from "../core/tags/cloud.vue";
  import TagsList from "../core/tags/list.vue";

  export default {
    name: "popular-searches-inline",
    props: {
      /* Object[] ({ title: String, weight: Number }) - The list poppular searches. */
      popularSearches: {
        type: Array,
        default: () => ([])
      },
      /*
       * The type of full view:
       * - none - the full view disabled.
       * - cloud - the cloud view (tags-cloud)
       * - list - the list view (tags-list)
       */
      fullType: {
        type: String,
        default: "none",
        validator: _fullType => ["none", "cloud", "list"].includes(_fullType)
      },
      /*
       * The type of close button:
       * - home - The house icon and placement at left corner of header.
       * - close - The X icon and placement at right corner of header.
       */
      hideButton: {
        type: String,
        default: "home",
        validator: _hideButton => ["home", "close"].includes(_hideButton)
      }
    },
    methods: {
      /**
       * Hides the modal with all tags (full view).
       *
       * @param {Function} callback - The hide callback: `() => {}`.
       */
      hide(callback) {
        this.$refs.modal.hide(callback);
      },
      /**
       * Shows the modal with all tags (full view).
       *
       * @param {Function} callback - The show callback: `() => {}`.
       */
      show(callback) {
        this.$refs.modal.show(callback);
      },
      /* Proxy for localization function. */
      _l10n: l10n
    },
    components: {
      "modal-fullscreen": ModalFullscreen,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "tags-cloud": TagsCloud,
      "tags-list": TagsList
    }
  };
</script>
