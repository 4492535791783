<template>
  <div :class="['patron-profile--reservations', orientation]">
    <modal-layout>
      <template slot="header">
        <div class="patron-profile--common-title">
          <span class="patron-profile--common-title-text">
            {{ title }}
          </span>

          <easyscreen-badge
            v-if="reservations.length !== 0"
            class="patron-profile--common-title-badge"
            color="primary"
            type="circle"
          >
            {{ reservations.length }}
          </easyscreen-badge>

          <label class="patron-profile--common-select-all">
            <span class="patron-profile--common-select-all-label">{{ _l10n("Select all") }}</span>
            <easyscreen-checkbox
              class="patron-profile--common-select-all-checkbox"
              :checked="reservationsInfo.length !== 0 && reservationsInfo.every(reservationInfo => reservationInfo.checked)"
              @input="(_checked) => {
                reservationsInfo.forEach(reservationInfo => {
                  reservationInfo.checked = _checked;
                });
              }"
            />
          </label>
        </div>
      </template>
      <template slot="content">
        <scrollable :max-height="_isLandscape() ? 465 : 1245" smooth-edge-color="#262626">
          <template v-for="(reservationInfo, index) in reservationsInfo">
            <profile-material-info
              :key="`${ reservationInfo.title }-${ index }`"
              :title="reservationInfo.title"
              :attributes="reservationInfo.attributes"
              :checked="reservationInfo.checked"
              @input="(_checked) => {
                reservationInfo.checked = _checked
              }"
            />
          </template>
        </scrollable>
      </template>
      <template slot="footer">
        <easyscreen-button-group gap="big">
          <easyscreen-button
            color="danger"
            modificator="fixed-width"
            @click.native="_deleteSelected"
            :disabled="_selectedReservations().length === 0"
          >
            {{ _l10n("Delete selected") }} ({{ _selectedReservations().length }})
          </easyscreen-button>
          <easyscreen-button
            v-if="reservationsUpdatable && provider !== 'bibliofil'"
            color="primary"
            modificator="fixed-width"
            @click.native="$refs.updateReservationsModal.show"
            :disabled="_selectedReservations().length === 0"
          >
            {{ _l10n("Update selected") }} ({{ _selectedReservations().length }})
          </easyscreen-button>
        </easyscreen-button-group>
      </template>
    </modal-layout>

    <loader ref="loader" />
    <modal-alert ref="alert" />
    <modal-confirm
      v-if="reservationsUpdatable"
      :ok-title="_l10n('Update')"
      ref="updateReservationsModal"
      @ok="_updateSelected"
    >
      <template slot="header">{{ _l10n("Update reservations") }}</template>
      <template slot="content">
        <form class="es-negative-m-2 es-mt-1">
          <easyscreen-form-group :title="_l10n('Pick up reservation on')">
            <easyscreen-select
              :label="_l10n('Pick up reservation on')"
              :options="_branchesToSelect()"
              :value="preferredBranch"
              ref="pickupBranchSelect"
            />
          </easyscreen-form-group>

          <easyscreen-form-group :title="_l10n('Maximum waiting time for reservations')">
            <easyscreen-select
              :label="_l10n('Maximum waiting time for reservations')"
              :options="[{
                label: _l10n('6 month'),
                value: '6'
              }, {
                label: _l10n('5 month'),
                value: '5'
              }, {
                label: _l10n('3 month'),
                value: '3'
              }]"
              ref="waitingTimeSelect"
            />
          </easyscreen-form-group>
        </form>
      </template>
    </modal-confirm>
  </div>
</template>

<style lang="less" src="./common.less"></style>
<style lang="less" src="../../../core/mixins.less"></style>
<script>
  import ModalAlert from "../../../core/modal/alert.vue";
  import ModalConfirm from "../../../core/modal/confirm.vue";
  import ModalLayout from "../../../core/modal/layout.vue";
  import Scrollable from "../../../core/scrollable/scrollable.vue";
  import Loader from "../../../core/loader/loader.vue";
  import EasyscreenButton from "../../../core/button/button.vue";
  import EasyscreenButtonGroup from "../../../core/button/button-group.vue";
  import EasyscreenBadge from "../../../core/badge/badge.vue";
  import EasyscreenCheckbox from "../../../core/input/checkbox.vue";
  import EasyscreenSelect from "../../../core/input/select.vue";
  import EasyscreenFormGroup from "../../../core/form/form-group.vue";
  import ProfileMaterialInfo from "../parts/material-info.vue";

  import { get } from "lodash";
  import moment from "moment";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../../../core/mixins/orientation.js";
  import convertDateMixin from "../../../core/mixins/convert-date.js";

  export default {
    name: "patron-profile-reservations",
    mixins: [orientationMixin, convertDateMixin],
    props: {
      provider: String,
      title: String,
      reservationsUpdatable: {
        type: Boolean,
        default: false
      },
      reservations: Array,
      formData: Object,
      branches: Object,
      preferredBranch: String
    },
    data() {
      return {
        reservationsInfo: this._getReservationsInfo()
      };
    },
    watch: {
      reservations(newReservations) {
        this.reservationsInfo = this._getReservationsInfo(newReservations);
      }
    },
    methods: {
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Template-friendly reservation.
       *
       * @typedef {Object} ReservationInfo
       *
       * @property {String} id - Reservation id.
       * @property {String} title - Reservation material title.
       * @property {Boolean} checked - Check state of reservation.
       * @property {Object[]} attributes - Visible reservation attributes.
       * @property {String} attributes[].label - Attibute label for GUI.
       * @property {String} attributes[].value - Attibute value for GUI.
       */
      /**
       * Get selected reservations.
       *
       * @returns {ReservationInfo[]} list of selected reservations
       */
      _selectedReservations() {
        return this.reservationsInfo.filter(reservation => reservation.checked);
      },
      /**
       * Get all reservations.
       *
       * @param {Object[]} reservations - Raw reservations from lms.
       *
       * @returns {ReservationInfo[]} Template-friendly reservations.
       */
      _getReservationsInfo(reservations) {

        return (reservations || this.reservations || []).map(reservation => {
          let existingReservation = (this.reservationsInfo || []).find(_reservation => {
            return _reservation.id === reservation.reservationId;
          });

          return {
            id: get(reservation, "reservationId"),
            title: get(reservation, "material.title"),
            checked: existingReservation && existingReservation.checked ? true : false,
            attributes: [{
              label: l10n("Number in que"),
              value: get(reservation, "providerSpecificFields.numberInQueue")
            }, {
              label: l10n("Pick up at"),
              value: get(reservation, "pickupBranch.name")
            }, {
              label: l10n("Date"),
              value: this._convertDate(get(reservation, "dateOfReservation"))
            }, {
              label: l10n("Date of expiry"),
              value: this._convertDate(get(reservation, "providerSpecificFields.expiryDate"))
            }, {
              label: l10n("Type"),
              value: get(reservation, "material.type")
            }, {
              label: l10n("Ordrenumber"),
              value: get(reservation, "providerSpecificFields.pickupNumber") || get(reservation, "reservationId")
            }].filter(function(attribute){
              return attribute.value;
            })
          };
        });
      },
      /**
       * Get the list of branches (select-ready) from the map of branches.
       *
       * @returns {Object[]} branches
       * @returns {Object[]} branches[].value - Branch id.
       * @returns {Object[]} branches[].label - Branch name.
       */
      _branchesToSelect() {
        return Object.keys(this.branches).sort((a, b) => {
          if (this.branches[a] > this.branches[b]) {
            return 1;
          }

          if (this.branches[a] < this.branches[b]) {
            return -1;
          }

          return 0;
        }).map((branchId) => {
          return { value: branchId, label: this.branches[branchId] };
        });
      },
      /**
       * Update reservation for selected elements.
       * @async
       */
      async _updateSelected() {
        try {
          this.$refs.loader.show();

          let selected = this._selectedReservations();
          let pickupBranch = this.$refs.pickupBranchSelect.selected.value;
          let waitingTime = this.$refs.waitingTimeSelect.selected.value;
          let dateOfExpiry = moment().add(parseInt(waitingTime, 10), "months").format("YYYY-MM-DD");

          const res = await this.$easyscreenRequest.lmsConnector.updateReservations({
            user: this.formData.cpr,
            pin: this.formData.pin,
            reservationId: selected.map(reservation => reservation.id).join(","),
            branchId: pickupBranch,
            to: dateOfExpiry
          });

          this.$emit("reservation-updated");
          this.$refs.loader.hide();

          if (!res.status) {
            return;
          }

          this.$refs.alert.show({
            title: l10n("Success!"),
            messageHTML: `<b>${ selected.map(reservation => reservation.title).join(",<br>") }</b> - ${ l10n("Updated") }`
          });
        } catch (error) {
          this.$refs.loader.hide();
          this.$refs.alert.show({
            title: l10n("Error"),
            message: l10n(error.message)
          });
        }
      },
      /**
       * Delete selected reservations (cannot be undone).
       * @async
       */
      async _deleteSelected() {
        try {
          this.$refs.loader.show();

          let selected = this._selectedReservations();
          const res = await this.$easyscreenRequest.lmsConnector.deleteReservations({
            user: this.formData.cpr,
            pin: this.formData.pin,
            reservationId: selected.map(reservation => reservation.id)
          });

          this.$refs.loader.hide();

          if (!res.status) {
            return;
          }

          this.$emit("reservation-deleted");
          this.$refs.alert.show({
            title: l10n("Success!"),
            messageHTML: `<b>${ selected.map(reservation => reservation.title).join(",<br>") }</b> - ${ l10n("Deleted") }`
          });
        } catch (error) {
          this.$refs.loader.hide();
          this.$refs.alert.show({
            title: l10n("Error"),
            message: l10n(error.message)
          });
        }
      }
    },
    components: {
      "modal-alert": ModalAlert,
      "modal-confirm": ModalConfirm,
      "modal-layout": ModalLayout,
      "scrollable": Scrollable,
      "loader": Loader,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-button-group": EasyscreenButtonGroup,
      "easyscreen-badge": EasyscreenBadge,
      "easyscreen-checkbox": EasyscreenCheckbox,
      "easyscreen-select": EasyscreenSelect,
      "easyscreen-form-group": EasyscreenFormGroup,
      "profile-material-info": ProfileMaterialInfo
    }
  };
</script>
