<template>
  <div class="tags-list">
    <span
      v-for="(tag, index) in tags"
      :key="tag.title"
      class="tags-list--tag"
      @click="$emit('select', tag)"
    >
      <span class="tags-list--tag-index">{{ index + 1 }}.</span>
      {{ _cutText(tag.title, maxTitleLength) }}
    </span>
  </div>
</template>

<style lang="less" src="./list.less"></style>

<script>
  import cutText from "../../../lib/utils/cut-text.js";

  export default {
    name: "tags-list",
    props: {
      /* The list of tags. */
      tags: {
        type: Array,
        default: () => ([])
      },
      /* The maximim title length. */
      maxTitleLength: {
        type: Number,
        default: 35
      }
    },
    methods: {
      /**
       * Proxy of the "cutText".
       * See description for "cutText" in file: "~/src/lib/utils/cut-text.js"
       */
      _cutText: cutText
    }
  };
</script>
