export const layouts = {
  0: [{
    height: "100%",
    columns: 0,
    rows: 0
  }],

  1: [{
    height: "100%",
    columns: 5,
    rows: 4
  }],

  2: [{
    height: "50%",
    columns: 5,
    rows: 2
  }, {
    height: "50%",
    columns: 5,
    rows: 2
  }],

  3: [{
    height: "50%",
    columns: 5,
    rows: 2
  }, {
    height: "25%",
    columns: 5,
    rows: 1
  }, {
    height: "25%",
    columns: 5,
    rows: 1
  }],

  4: [{
    height: "25%",
    columns: 5,
    rows: 1
  }, {
    height: "25%",
    columns: 5,
    rows: 1
  }, {
    height: "25%",
    columns: 5,
    rows: 1
  }, {
    height: "25%",
    columns: 5,
    rows: 1
  }]
};

export const maxLayout = layouts[4];
