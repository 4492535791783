<template>
  <div
    :class="[
      'digital-shelf-light',
      { 'digital-shelf-light_medium-screen': isMediumScreen },
      orientation
    ]"
    :style="{ '--digital-shelf-light-special-color': specialColor }"
  >
    <modal-layout-navigation
      ref="screenNavigation"
      :title="title"
      :withSearch="!disableExternalFeatures"
      :searchDisabled="disableExternalFeatures || searchShown"
      :wayfinder-data="wayfinderData"
      :disable-navigation="disableNavigation"
      @show-search="() => {
        $refs.screenNavigation.toHome();
        $refs.search.show();
      }"
    >
      <div
        :class="[
          'digital-shelf-light--theme-data',
          { 'digital-shelf-light--theme-data_info-hidden': themeInfoHidden }
        ]"
        :style="{ backgroundColor: themeColor, height: this.themeInfoHeight ? `${ this.themeInfoHeight }px` : null }"
      >
        <div class="digital-shelf-light--theme-data-wrapper">
          <h3 class="digital-shelf-light--theme-label">
            {{ getActiveTag() }}
          </h3>

          <div :class="[
            'digital-shelf-light--theme-info',
            { 'digital-shelf-light--theme-info_disabled': !getHelpText() }
          ]" >
            <div
              class="digital-shelf-light--more-theme-info"
              @click="toggleThemeInfo"
            >
              <i
                :class="[
                  'digital-shelf-light--more-theme-info-icon',
                  'fal',
                  {
                    'fa-arrow-down': themeInfoHidden,
                    'fa-times': !themeInfoHidden
                  }
                ]"
              ></i>
              <span class="digital-shelf-light--more-theme-info-label">{{ _l10n("Introduction to the topic") }}</span>
            </div>
            <scrollable
              class="digital-shelf-light--theme-text"
              :max-height="themeContentHeight"
              :smooth-edge-color="specialColorWithOverlay"
              ref="themeText"
              :style="{ height: themeContentHeight ? `${ themeContentHeight }px` : themeContentHeight }"
            >
              <easyscreen-youtube
                class="digital-shelf-light--theme-video"
                v-if="!!_getHelpVideo() && !themeInfoHidden"
                :url="_getHelpVideo()"
                :muted="false"
              />
              <div v-html="getHelpText()"></div>
            </scrollable>
          </div>
        </div>
      </div>
      <div class="digital-shelf-light--content">
        <div class="digital-shelf-light--sliders">
          <div v-if="$easyscreenConfig.get('enable.accessibility')">
            <easyscreen-circle-button
              :class="[
                'digital-shelf-light--carousel-navigation',
                'digital-shelf-light--carousel-navigation_left',
                { 'digital-shelf-light--carousel-navigation_visible': leftCarouselNavigationButtonVisible }
              ]"
              icon="fal fa-chevron-left"
              color="outline-custom"
              :size="isMediumScreen ? 'medium' : 'big'"
              :custom-color="specialColor"
              :custom-active-color="specialColorWithOverlay"
              @click.native="$refs.carousel.previousSlide()"
            />
          </div>

          <transition name="digital-shelf-light--slider" mode="out-in" @after-leave="() => {
            /*_loadDominantCoverColor(selected, carouselPageIndex, 100);*/
          }">
            <easyscreen-carousel
              ref="carousel"
              class="digital-shelf-light--slider"
              :layout-columns="carouselColumns"
              :layout-rows="carouselRows"
              @before-position-change="(_, followingSlideIndex) => {
                closeThemeInfo()
                carouselPageIndex = followingSlideIndex;
                /*_loadDominantCoverColor(selected, carouselPageIndex);*/
              }"
              @dragging-started="closeThemeInfo"
              :key="`slider-${ selected }-${ _isLandscape() }`"
            >

              <template
                v-for="(element, elementIndex) in carouselElements"
              >
                <div v-if="element.isStub" :key="`stab-${ elementIndex }`"></div>
                <digital-shelf-cover
                  v-else
                  ref="covers"
                  :class="['digital-shelf-light--cover', { 'digital-shelf-light--cover_no-padding': isMediumScreen }]"
                  :with-cover-background="false"
                  :key="element.faustNumber"
                  :title="element.title"
                  :author="element.author"
                  :cover="element.cover"
                  align="bottom"
                  horizontal-align="center"
                  width="75%"
                  @click.native="{
                    $refs.screenNavigation.pushBackAction(() => { $refs.materialsList.hide() });
                    _showMaterialView(element, selected);
                  }"
                />
              </template>
            </easyscreen-carousel>
          </transition>
          <div v-if="$easyscreenConfig.get('enable.accessibility')">
            <easyscreen-circle-button
              :class="[
                'digital-shelf-light--carousel-navigation',
                'digital-shelf-light--carousel-navigation_right',
                { 'digital-shelf-light--carousel-navigation_visible': rightCarouselNavigationButtonVisible }
              ]"
              icon="fal fa-chevron-right"
              color="outline-custom"
              :size="isMediumScreen ? 'medium' : 'big'"
              :custom-color="specialColor"
              :custom-active-color="specialColorWithOverlay"
              @click.native="$refs.carousel.nextSlide()"
            />
          </div>
        </div>
        <div class="digital-shelf-light--themes-wrapper">
          <div class="digital-shelf-light--themes" :style="{ '--digital-shelf-light--theme-lines': Math.ceil(tags.length / themesPerLine) }">
            <template
              v-for="(theme, themeIndex) in tags"
            >
              <div
                :key="`${ theme }-${ themeIndex }`"
                :class="[
                  'digital-shelf-light--theme',
                  { 'digital-shelf-light--theme_active': themeIndex === selected }
                ]"
                @click="() => {
                  closeThemeInfo();
                  _selectTag(themeIndex);
                }"
              >
                <span class="digital-shelf-light--theme-text_default">{{ theme }}</span>
                <span class="digital-shelf-light--theme-text_active">{{ theme }}</span>
              </div>
              <div
                v-if="(themeIndex + 1) % themesPerLine === 0 && themeIndex !== 0"
                :key="`theme-break-${ themeIndex }`"
                class="digital-shelf-light--theme-break"
              ></div>
            </template>
          </div>
        </div>
        <div class="digital-shelf-light--dynamic-footer-line" :style = "{ backgroundColor: themeColor }">
          <div
            v-for="number in carouselSlidesAmount"
            :key="number"
            :class="[
              'digital-shelf-light--slide-indicator',
              { 'digital-shelf-light--slide-indicator_active': carouselPageIndex === number - 1 }
            ]"
          ></div>
        </div>
        <div class="digital-shelf-light--static-footer-line"></div>
      </div>

      <easyscreen-search-light
        v-if="!disableExternalFeatures"
        ref="search"
        position="absolute"
        popular-searches="multiline"
        :auto-close="true"
        :available-only-button="true"
        :with-close-controls="false"
        :default-special-color="specialColor"
        @before-open="() => {
          $refs.screenNavigation.pushBackAction(() => {
            $refs.search.hide();
          });
          searchShown = true;
        }"
        @before-close="searchShown = false"
        @modal-opened="(closeModal, type) => $refs.screenNavigation.pushBackAction(closeModal, type)"
        @closed-inner-modal="(type) => $refs.screenNavigation.popBackAction(type)"
      />
      <materials-list
        v-if="selectedMaterial"
        ref="materialsList"
        design="light"
        :auto-close="true"
        :use-suggested-lists="isVideoHostTemplate === false && this.suggestedLists"
        :default-materials="selectedMaterialsList"
        :default-selected="selectedMaterial"
        @select-tag="(tag) => {
          $refs.screenNavigation.toHome();
          _findByTag(tag);
        }"
        @modal-opened="(closeModal, type) => $refs.screenNavigation.pushBackAction(closeModal, type)"
        @closed-inner-modal="(type) => $refs.screenNavigation.popBackAction(type)"
      />
    </modal-layout-navigation>

    <patron-profile v-if="$easyscreenConfig.get('enable.unloan')" ref="patronProfile" />

    <easyscreen-materials-cart
      v-if="$easyscreenConfig.get('enable.basket')"
      ref="materialsCart"
    />

    <materials-scanner v-if="$easyscreenConfig.get('enable.profile')" ref="materialsScanner" />
  </div>
</template>

<style lang="less" src="../core/icons.less" ></style>
<style lang="less" src="./digital-shelf-light.less"></style>

<script>
  import ModalLayoutNavigation from "../core/modal/layout-navigation.vue";
  import EasyscreenCarousel from "../core/carousel/carousel.vue";
  import Scrollable from "../core/scrollable/scrollable.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import PatronProfile from "../patron/profile/profile.vue";
  import MaterialsScanner from "../materials-scanner/materials-scanner.vue";
  import EasyscreenSearchLight from "../search/search-light.vue";
  import MaterialsList from "../materials-list/materials-list.vue";
  import EasyscreenMaterialsCart from "../materials-cart/materials-cart.vue";
  import DigitalShelfCover from "../digital-shelf/cover.vue";
  import DigitalShelfOriginal from "../digital-shelf/digital-shelf.vue";

  import { normal as normalColor } from "color-blend";
  import ColorMixer from "color-mixer";
  import resizeMixin from "../core/mixins/resize.js";
  import htmlElementHeight from "@/lib/utils/html-element-height.js";
  /*import asyncTimeout from "@/lib/utils/async-timeout.js";*/

  const themeDataWrapperBackgroundColor = { r: 0, g: 0, b: 0, a: 0.2 };

  export default {
    name: "digital-shelf-light",
    mixins: [DigitalShelfOriginal, resizeMixin],
    props: {
      themesPerLine: {
        type: Number,
        default: 4
      },
      defaultSpecialColor: {
        type: String,
        default: "#4D898E"
      },
      disableNavigation: {
        type: Boolean,
        default: false
      },
      wayfinderData: Object
    },
    computed: {
      specialColorWithOverlay() {
        let specialColor = new ColorMixer.Color({ hex: this.specialColor }).rgb();
        specialColor = { r: specialColor[0], g: specialColor[1], b: specialColor[2], a: 1 };

        const mixedSpecialColor = normalColor(specialColor, themeDataWrapperBackgroundColor);
        return new ColorMixer.Color({ rgb: [mixedSpecialColor.r, mixedSpecialColor.g, mixedSpecialColor.b] }).hex();
      },
      coversPerSlide() {
        return this.carouselColumns * this.carouselRows;
      },
      carouselRows() {
        if (this._isLandscape()) {
          return 2;
        }

        if (this.isMediumScreen)
          return 3;

        return 5;
      },
      carouselColumns() {
        if (this._isLandscape()) {
          if (this.isMediumScreen)
            return 6;

          return 6;
        }

        if (this.isMediumScreen)
          return 3;

        return 4;
      },
      carouselElements() {
        const themeElements = this.items[this.selected].concat([]);
        if (this._isLandscape() && this.isMediumScreen !== true) {
          return themeElements;
        }

        const slidesAmount = Math.ceil(themeElements.length / this.coversPerSlide);
        let slidesElements = [];
        let stubRows = [0, 1];
        let stubColumns = [0, 1];
        if (this.isMediumScreen) {
          stubRows = [0];
        }

        for (let slideIndex = 0; slideIndex < slidesAmount; slideIndex++) {
          for (let rowIndex = 0; rowIndex < this.carouselRows; rowIndex++) {
            for (let columnIndex = 0; columnIndex < this.carouselColumns; columnIndex++) {
              if (stubColumns.includes(columnIndex) && stubRows.includes(rowIndex)) {
                slidesElements.push({ isStub: true });
              } else {
                slidesElements.push(themeElements.shift());
              }
            }
          }
        }

        return slidesElements.filter(Boolean);
      },
      carouselSlidesAmount() {
        return Math.ceil(this.items[this.selected].length / this.coversPerSlide);
      },
      leftCarouselNavigationButtonVisible() {
        if (this.carouselSlidesAmount < 2)
          return false;

        return this.carouselPageIndex !== 0;
      },
      rightCarouselNavigationButtonVisible() {
        if (this.carouselSlidesAmount < 2)
          return false;

        return this.carouselPageIndex !== this.carouselSlidesAmount - 1;
      },
      themeColor() {
        const currentTag = this.request.shelves[this.selected];
        if (currentTag)
          return currentTag.options.theme_color_value;

        return this.specialColor;
      }
    },
    data() {
      return {
        searchShown: false,
        specialColor: this.defaultSpecialColor,
        themeInfoHidden: true,
        carouselPageIndex: 0,
        backActions: [],
        themeInfoHeight: null,
        themeContentHeight: null
      };
    },
    methods: {
      _setTimeout: setTimeout,
      closeThemeInfo() {
        this.themeInfoHidden = true;
        if (this.$refs.themeText) {
          this.$refs.themeText.setScroll(0);
        }
      },
      async toggleThemeInfo() {
        this.themeInfoHidden = !this.themeInfoHidden;
        this.themeContentHeight = this._isLandscape() ? 400 : 900;
        if (this.isMediumScreen) {
          this.themeContentHeight = 490;
        }

        this.themeInfoHeight = null;

        if (this.$refs.themeText) {
          this.$refs.themeText.setScroll(0);
        }

        if (!this.themeInfoHidden && this.$refs.themeText) {
          await this.$nextTick();
          // Additional 16px required to avoid fade on buttom edge for small content
          this.themeContentHeight = Math.min(this.themeContentHeight + 16, htmlElementHeight(this.$refs.themeText.$el.children[0]));
          // Additional 400px it's the size of title with top marting at the introduction block.
          // `Math.max(this.themeContentHeight + 400, 700)` - used to consistent animation when content is too small.
          this.themeInfoHeight = Math.min(Math.max(this.themeContentHeight + 400, 700), this.orientation === "portrait-orientation" ? 1350 : 850);
        }
      },
      _selectTag(index) {
        this.$easyscreenStatistic.selectDigitalShelfTheme({ tagName: this.tags[index] });

        this.selected = index;
        this.carouselPageIndex = 0;
        this.$emit("tag-selected", this.selected);
      },
      _getCarouselPageCovers(themeIndex, carouselPageIndex) {
        const from = carouselPageIndex * this.coversPerSlide;

        return new Array(this.coversPerSlide).fill(0).map((_, index) => {
          const item = this.items[themeIndex][from + index];
          if (!item) {
            return;
          }

          return this.$refs.covers.find(coverElement => !coverElement.isDefaultCover && coverElement.cover === item.cover);
        }).filter(Boolean);
      },
      _resetCarouselPageIndex() {
        this.carouselPageIndex = 0;
      }
      /*async _loadDominantCoverColor(themeIndex, carouselPageIndex, timeout) {
        if (timeout) {
          await asyncTimeout(timeout)
        }

        themeIndex = themeIndex || 0;
        carouselPageIndex = carouselPageIndex || 0;

        if (!this.$refs.covers) {
          await this.$nextTick();
        }

        if (!this.$refs.covers) {
          return;
        }

        const covers = this._getCarouselPageCovers(themeIndex, carouselPageIndex);
        let coverIndex = 0;
        let targerCover = covers[coverIndex];
        while (targerCover != null) {
          const dominantColor = await targerCover.dominantColor;

          if (this.selected !== themeIndex || this.carouselPageIndex !== carouselPageIndex) {
            break;
          }

          if (dominantColor) {
            this.specialColor = dominantColor;
            break;
          }

          coverIndex += 1;
          targerCover = this.$refs.covers[coverIndex];
        }
      },
      _reloadDominantColorOnOrientationChange() {
        // wait for slider out-in animation.
        setTimeout(async () => {
          this.carouselPageIndex = 0;
          this._loadDominantCoverColor(this.selected, this.carouselPageIndex);
        }, 650);
      }*/
    },
    mounted() {
      //this._loadDominantCoverColor(this.selected);
      //this.$on("orientation-changed", this._reloadDominantColorOnOrientationChange);
      this.$on("orientation-changed", this._resetCarouselPageIndex);
    },
    beforeDestroy() {
      //this.$off("orientation-changed", this._reloadDominantColorOnOrientationChange);
      this.$off("orientation-changed", this._resetCarouselPageIndex);
    },
    components: {
      "modal-layout-navigation": ModalLayoutNavigation,
      "easyscreen-carousel": EasyscreenCarousel,
      "scrollable": Scrollable,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "patron-profile": PatronProfile,
      "materials-scanner": MaterialsScanner,
      "digital-shelf-cover": DigitalShelfCover,
      "easyscreen-search-light": EasyscreenSearchLight,
      "materials-list": MaterialsList,
      "easyscreen-materials-cart": EasyscreenMaterialsCart
    }
  };
</script>
