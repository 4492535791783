<template>
  <div :class="['easyscreen-search--content', `easyscreen-search--content_design-${ design }`]">
    <template v-if="$easyscreenConfig.get('enable.popularSearches') && popularSearches">
      <popular-searches-multiline
        v-if="popularSearches === 'multiline'"
        @select="(tag) => $emit('find', tag.title, sorting)"
      />
      <popular-searches-inline
        v-else-if="popularSearches.startsWith('inline')"
        class="easyscreen-search--inline-suggestions"
        hide-button="close"
        :full-type="popularSearches.split('-')[1] || 'none'"
        @select="(tag) => $emit('find', tag.title, sorting)"
      />
    </template>
    <search-suggestions
      v-if="$easyscreenConfig.get('enable.searchSuggestions') && withSearchSuggestions && design === 'light'"
      :query="query"
      :design="design"
      :background-color="specialColor"
      @select="(suggestion) => $emit('find', suggestion.title, sorting)"
    />
    <form
      class="easyscreen-search--input"
      @submit="(event) => {
        event.preventDefault();

        if (!_isSortingElement(event.submitter)) {
          $emit('find', query, sorting);
        }

        return false;
      }"
    >
      <search-suggestions
        v-if="$easyscreenConfig.get('enable.searchSuggestions') && withSearchSuggestions && design !== 'light'"
        :query="query"
        @select="(suggestion) => $emit('find', suggestion.title, sorting)"
      />
      <search-input
        :key="withCursor"
        ref="input"
        :cursor="withCursor"
        :value="query"
        :sorting="sorting"
        :scale-up="true"
        :with-sorting="withSorting"
        :design="design"
        @input="(value) => { query = value }"
        @text-input-click="(event) => $emit('text-input-click', event)"
        @sorting-selected="(_sorting) => {
          sorting = _sorting.value
          $emit('sorting-selected', _sorting);
        }"
      />
    </form>
    <keyboard-fullsize
      class="easyscreen-search--keyboard es-mt-4"
      @key-click="_handleKeyboard"
      :design="design"
      :gap="design === 'light' ? 6 : undefined"
      :override="design === 'light' ? {
        'enter': {
          offsetleft: -8,
          /* eslint-disable-next-line */
          path: 'M18.836,792.114C17.375,792.114 16.166,793.149 15.894,794.528L15.894,837.757C15.894,839.419 14.556,840.757 12.894,840.757L-17,841C-18.662,841 -20,842.338 -20,844L-20,880.426C-20,882.088 -18.662,883.426 -17,883.426L81.897,883.575C83.559,883.575 84.897,882.237 84.897,880.575L84.897,795.34C84.897,793.678 83.559,792.34 81.897,792.34L18.836,792.114Z'
        },
        'lshift': { style: { width: 112 }},
        'space': { style: { width: 293 }}
      } : {}"
    />
  </div>
</template>

<style lang="less" src="./search.less"></style>
<style lang="less" src="../core/mixins.less"></style>

<script>
  import KeyboardFullsize from "../core/keyboard/fullsize.vue";
  import PopularSearchesInline from "../popular-searches/inline.vue";
  import PopularSearchesMultiline from "../popular-searches/multiline.vue";
  import SearchInput from "./search-input.vue";
  import SearchSuggestions from "./search-suggestions.vue";

  import isElementOf from "@/lib/utils/is-element-of.js";

  /**
   * Check if the html element is a part of the search form sorting select.
   *
   * @param {HTMLElement} node - The html element for test.
   *
   * @returns {Boolean} true - the element is a part of sorting select.
   */
  export function isSortingElement(node) {
    return isElementOf(node, "search-input--sorting-select");
  }

  /**
   * Check if the html element is a part of the search form clear input button.
   *
   * @param {HTMLElement} node - The html element for test.
   *
   * @returns {Boolean} true - the element is a part of clear input button.
   */
  export function isClearButtonElement(node) {
    return isElementOf(node, "search-input--input-clear-button");
  }

  export default {
    name: "easyscreen-search--form",
    props: {
      /* The default query of search input, used only on creation step. */
      defaultQuery: String,
      /* The default sorting of search input, used only on creation step. */
      defaultSorting: {
        type: String,
        default: ""
      },
      /* Show the sorting select in form, shown by default. */
      withSorting: {
        type: Boolean,
        default: true
      },
      /*
       * Show the suggestions (the this.$easyscreenConfig.get('enable.searchSuggestions') also should be enabled).
       * Should be used for prevent the suggestions for example when the search already in progress.
       */
      withSearchSuggestions: {
        type: Boolean,
        default: true
      },
      /* Display the cursor for search input */
      withCursor: {
        type: Boolean,
        default: true
      },
      /*
       * Type of the view for posular searches.
       * The multiline suggestions have no extended view (modal with all or most of all suggestions).
       */
      popularSearches: {
        type: String,
        default: "multiline",
        validator: _popularSearches => [
          "false",
          "multiline",
          "inline",
          "inline-cloud",
          "inline-list"
        ].includes(_popularSearches)
      },
      /* The global reskin for materials list. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      },
      specialColor: String
    },
    data() {
      return {
        query: this.defaultQuery,
        sorting: this.defaultSorting
      };
    },
    methods: {
      /**
       * Set the query.
       * The method will wail view update using $nextTick()
       * for set the correct position of cursor.
       * @async
       *
       * @param {String} query - The new query.
       */
      async setQuery(query) {
        query = query || "";

        this.query = query;
        await this.$nextTick();
        this.setCursor(query.length);
      },
      /**
       * Set the sorting.
       *
       * @param {String} sorting - The new sorting value.
       */
      setSorting(sorting) {
        sorting = sorting || this.defaultSorting;
        this.sorting = sorting;
      },
      /**
       * Reset the query.
       */
      clearQuery() {
        this.query = "";
        this.setCursor(0);
      },
      /**
       * Set the cursor postion.
       *
       * @param {Number} cursorIndex - The new position of cursor.
       */
      setCursor(cursorIndex) {
        this.$refs.input && this.$refs.input.setCursor(cursorIndex);
      },
      /**
       * Proxy for isSortingElement. See the more above.
       */
      _isSortingElement: isSortingElement,
      /**
       * The handler of keyboard events.
       *
       * @param {String} key - The key symbol or special name.
       */
      _handleKeyboard(key) {
        if (key === "enter") {
          return this.$emit("find", this.query, this.sorting);
        }

        KeyboardFullsize.defaultKeyboardHandler({
          onUpdated: this.$nextTick,
          value: {
            set: (query) => this.query = query,
            get: () => this.query
          },
          cursor: {
            set: this.setCursor,
            get: () => this.$refs.input.cursorIndex
          }
        }, key);
      }
    },
    components: {
      "keyboard-fullsize": KeyboardFullsize,
      "popular-searches-inline": PopularSearchesInline,
      "popular-searches-multiline": PopularSearchesMultiline,
      "search-input": SearchInput,
      "search-suggestions": SearchSuggestions
    }
  };
</script>
