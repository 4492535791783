import * as d3 from "d3-timer";
import parseDuration from "parse-duration";
import * as querystring from "querystring";
import metaInformationMixin from "../materials-list/meta-information.mixin.js";

const urlOptions = querystring.parse(window.location.href.split("#")[1]);
urlOptions.departmentId = (urlOptions.departmentId || "").split(",").filter(Boolean);
function isSelectedBranch(holding) {
  return urlOptions.branchId && urlOptions.branchId === holding.branchId;
}

function isSelectedDepartment(holding) {
  return urlOptions.departmentId.length !== 0 && urlOptions.departmentId.includes(holding.departmentId);
}

export function sortHoldigns(holdings) {
  return holdings.slice().sort((a, b) => {
    const aScore = (isSelectedBranch(a) ? 2 : 0) + (isSelectedDepartment(a) ? 1 : 0);
    const bScore = (isSelectedBranch(b) ? 2 : 0) + (isSelectedDepartment(b) ? 1 : 0);

    return bScore - aScore;
  });
}

export default {
  mixins: [metaInformationMixin],
  props: {
    /* Display the material meta information and holdings instead of description. */
    withMetaInformation: Boolean,
    /* The timeout between holdigns update. */
    holdingsUpdateInterval: {
      type: Number,
      default: parseDuration("10m")
    }
  },
  methods: {
    async _getMeta() {
      if (this.withMetaInformation === true) {
        this.modifiedElements = this.modifiedElements.map(element => {
          let metaPair = this._getMetaPair(element, {
            keys: ["dk5", "genre"],
            map: {
              dk5: this.$l10n("Shelf mark"),
              genre: this.$l10n("Genre")
            }
          });

          if (element.type) {
            metaPair.push({
              property: this.$l10n("Material Type"),
              value: element.type
            });
          }

          if (element.holdings && element.holdings.length !== 0) {
            metaPair.push({ delimiter: true });

            sortHoldigns(element.holdings).forEach(holding => {
              metaPair.push({
                placement: holding.placement
              });
            });
          }

          return Object.assign({}, element, { metaPair });
        });
      }
    },
    async _getHoldings() {
      await Promise.all(this.modifiedElements.map(async (element) => {
        try {
          const holdings = await this.$easyscreenRequest.lmsConnector.holdings({
            faustNumber: element.id
          });

          /* The search is required for case when the original element are modified. */
          const _element = this.modifiedElements.find(_element => _element.id === element.id);
          _element.holdings = holdings || [];
        } catch (error) {
          console.error(`Can't load holdings for element with id "${ element.id }".`, error);
        }
      }));

      this.modifiedElements = this.modifiedElements.slice();
    },
    async _startHoldingsUpdate() {
      this._stopHoldingsUpdate();

      this._holdingsUpdateInterval = d3.interval(async () => {
        await this._getHoldings();
        this._getMeta();
      }, this.holdingsUpdateInterval);

      await this._getHoldings();
      this._getMeta();
    },
    _stopHoldingsUpdate() {
      if (this._holdingsUpdateInterval) {
        this._holdingsUpdateInterval.stop();
        this._holdingsUpdateInterval = null;
      }
    }
  },
  async mounted() {
    if (this.withMetaInformation === true)
      this._startHoldingsUpdate();
  },
  beforeDestroy() {
    this._stopHoldingsUpdate();
  }
};
