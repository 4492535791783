<template>
  <div
    :class="['presentation-set', customOptions.class, orientation]"
    :style="{
      background: background,
      '--top-bar-fill-color': slideTopBarColor,
      '--duration-bar-fill-color': slideDurationBarColor
    }"
    :key="loopIndex"
  >
    <!-- "loopIndex" required to proper work of the presentation set's with one slide. -->
    <div
      v-if="message"
      class="presentation-set--error-message"
      v-html="message"
    ></div>
    <template
      v-else
    >
      <div
        v-if="slideWithTopBar"
        class="presentation-set--top-bar"
      ></div>

      <div
        v-if="slideWithOverlay"
        class="presentation-set--overlay"
      ></div>

      <easyscreen-progress-bar
        v-if="slideWithDurationBar"
        :from="0"
        :to="activeSlide.duration"
        :progress="activeSlide.duration - elapsedTime"
        class="presentation-set--duration-bar"
      />

      <div
        v-for="widget in activeSlide.widgets"
        :key="widget.id"
        :class="[
          'presentation-set--element',
          `presentation-set--element_${ widget.name }`,
          {
            'presentation-set--element_with-background': widget.withBackground !== false,
            'presentation-set--element_is-single': activeSlide.widgets.length === 1
          }
        ]"
        :style="{
          top: widget.top,
          left: widget.left,
          width: widget.width,
          height: widget.height
        }"
      >
        <easyscreen-vue-component
          :props="{
            ...widget.options,
            ...(widget.name === 'video-host' ? { defaultSelected: activeSlideIndex } : {})
          }"
          :name="widget.name"
          :getter="widget.getter"
          :settings="widget.settings"

          v-on="$listeners"
          @disable-countdown="_disableCountdown"
          @select-slide="select"
        />
      </div>
    </template>
  </div>
</template>

<style src="./presentation-set.less" lang="less"></style>

<script>
  import { get } from "lodash";
  import * as d3 from "d3-timer";
  import parseDuration from "parse-duration";
  import fitNumber from "@/lib/utils/fit-number.js";
  import orientationMixin from "../core/mixins/orientation.js";

  import EasyscreenProgressBar from "../core/progress-bar/progress-bar.vue";
  import EasyscreenVueComponent from "../core/get-component/vue-component.vue";

  /* The duration of animation frame for ~60fps. */
  const playIntervalTic = parseDuration("16ms");

  /**
   * The event fires when selected the slide with index over than provided slides.
   *
   * @event presentation-set#end-of-presentation-set
   */

  /**
   * The event fires when `this.select` method is called.
   *
   * @event presentation-set#selected
   * @type {Number} - Index of selected presentation set.
   */

  /**
   * The event fires when screen in standby mode and presentation slide start the countdown.
   *
   * @event presentation-set#playing
   */

  /**
   * The event fires when screen in usage mode and presentation slide pauses the countdown.
   *
   * @event presentation-set#paused
   */

  /**
   * The event fires when some slide data overrides the countdown behavior.
   *
   * @event presentation-set#stopped
   */

  /**
   * The event fires when the coundown for active slide is ended.
   *
   * @event presentation-set#ended
   */
  export default {
    name: "presentation-set",
    mixins: [orientationMixin],
    props: {
      slides: {
        type: Array,
        default: () => ([])
      },
      defaultActiveSlide: {
        type: Number,
        default: 0
      },
      message: String,
      withTopBar: {
        type: Boolean,
        default: true
      },
      withOverlay: {
        type: Boolean,
        default: true
      },
      withDurationBar: {
        type: Boolean,
        default: true
      },
      withBackground: {
        type: Boolean,
        default: true
      },
      screenOptions: {
        type: Object
      }
    },
    computed: {
      customOptions() {
        let options = {};

        this.activeSlide.widgets.forEach(widget => {
          if (widget.presentationSet) {
            Object.keys(widget.presentationSet).forEach(key => {
              if (!options[key]) {
                options[key] = [];
              }

              options[key].push(widget.presentationSet[key]);
            });
          }
        });

        return options;
      },
      background() {
        if (this.withBackground === false) {
          return "";
        }

        if (get(this.activeSlide, "data.settings.backgroundColorEnabled") === true) {
          return get(this.activeSlide, "data.settings.backgroundColor");
        }

        let background = get(this.activeSlide, "data.customBg");
        return background ? `url('${ background }')` : null;
      },
      activeSlide() {
        let slide = { ...this.slides[this.activeSlideIndex] };
        slide.widgets = (slide.widgets || []).map(widget => {
          widget = {
            ...widget,
            id: JSON.stringify(widget)
          };

          ["top", "left", "width", "height"].forEach(placementStyle => {
            if (widget[placementStyle] !== undefined && !("" + widget[placementStyle]).includes("%")) {
              widget[placementStyle] = `${ widget[placementStyle] }%`;
            }
          });

          return widget;
        });

        return slide;
      },
      slideWithTopBar() {
        return this.withTopBar !== false && get(this.activeSlide, "data.settings.topBarColorEnabled") !== false;
      },
      slideTopBarColor() {
        return get(this.activeSlide, "data.settings.topBarColor", "");
      },
      slideWithOverlay() {
        return this.withOverlay !== false;
      },
      slideWithDurationBar() {
        return this.withDurationBar !== false && get(this.activeSlide, "data.settings.durationBarColorEnabled") !== false;
      },
      slideDurationBarColor() {
        return get(this.activeSlide, "data.settings.durationBarColor", "");
      }
    },
    watch: {
      defaultActiveSlide(index) {
        this.select(index);
      },
      slides: {
        handler() {
          this._resetStandbyPendingTime();
          this.select(this.defaultActiveSlide);
        },
        deep: true
      }
    },
    data() {
      return {
        loopIndex: 0,
        activeSlideIndex: this.defaultActiveSlide,
        state: "initialization",
        countdownDisabled: false,
        elapsedTime: 0
      };
    },
    methods: {
      /**
       * Select presentation set slide by index.
       * @fires presentation-set#end-of-presentation-set
       * @fires presentation-set#selected
       *
       * @param {Number} index - The presentation slide index
       */
      select(index) {
        if (index >= this.slides.length) {
          this.loopIndex += 1;
          this.$emit("end-of-presentation-set");
        }

        this.elapsedTime = 0;
        this.activeSlideIndex = fitNumber(index, {
          lt: 0,
          value: this.slides.length - 1
        }, {
          gt: this.slides.length - 1,
          value: 0
        });
        this.$emit("selected", this.activeSlideIndex);
        this._enableCountdown();
      },
      /**
       * Select next slide. Or first in case when the active slide is last.
       * @fires presentation-set#end-of-presentation-set
       * @fires presentation-set#selected
       */
      selectNext() {
        this.select(this.activeSlideIndex + 1);
      },
      /**
       * Select previous slide. Or last in case when the active slide is first.
       * @fires presentation-set#end-of-presentation-set
       * @fires presentation-set#selected
       */
      selectPrevious() {
        this.select(this.activeSlideIndex - 1);
      },
      /**
       * Start\continue the slide countdown and emit play event.
       * @fires presentation-set#playing
       */
      play() {
        if (this.state === "playing" || this.countdownDisabled === true || this.message) {
          return;
        }

        this._resetPlayInterval();
        this._playInterval = d3.interval(() => {
          this.elapsedTime += playIntervalTic;

          if (this.elapsedTime >= this.activeSlide.duration) {
            this.end();
            this.selectNext();
          }
        }, playIntervalTic);
        this.state = "playing";
        this.$emit("playing");
      },
      /**
       * Pause slide countdown and emit pause event.
       * @fires presentation-set#paused
       */
      pause() {
        if (this.state === "paused") {
          return;
        }

        this._resetPlayInterval();
        this.state = "paused";
        this.$emit("paused");
      },
      /**
       * Stop the countdown, reset the progress and emit stop event.
       * @fires presentation-set#stopped
       */
      stop() {
        if (this.state === "stopped") {
          return;
        }

        this._resetPlayInterval();
        this.state = "stopped";
        this.elapsedTime = 0;
        this.$emit("stopped");
      },
      /**
       * Stop the countdown and emit end event.
       * @fires presentation-set#ended
       */
      end() {
        if (this.state === "ended") {
          return;
        }

        this._resetPlayInterval();
        this.state = "ended";
        this.$emit("ended");
      },
      /**
       * Stop the countdown.
       */
      _resetPlayInterval() {
        if (this._playInterval) {
          this._playInterval.stop();
          this._playInterval = null;
        }
      },
      /**
       * Stop the countdown and prevent playing by internal methods.
       */
      _disableCountdown() {
        this.countdownDisabled = true;
        this.stop();
      },
      /**
       * Allow the play by internal methods and starts the countdown if standby is active.
       */
      _enableCountdown() {
        this.countdownDisabled = false;
        if (this.$easyscreenScreenActivity.state === "running") {
          this.play();
        }
      },
      /**
       * Set the standby waiting time to default screen value (`this.$easyscreenConfig.waitForUserAction`).
       */
      _resetStandbyPendingTime() {
        this.$easyscreenScreenActivity.changePendingTime(this.$easyscreenConfig.waitForUserAction || parseDuration("30s"));
      }
    },
    mounted() {
      this._resetStandbyPendingTime();
    },
    screenStandby() {
      this.play();
    },
    screenActive() {
      this.pause();
    },
    components: {
      "easyscreen-progress-bar": EasyscreenProgressBar,
      "easyscreen-vue-component": EasyscreenVueComponent
    }
  };
</script>
