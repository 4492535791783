<template>
  <div class="library-events-timetable">
    <div class="library-events-timetable--header">
      <div class="library-events-timetable--navigation">
        <div
          v-for="(group, groupIndex) in eventsGroups"
          :key="group.rawDate"
          @click="() => selectGroup(groupIndex)"
          :class="[
            'library-events-timetable--week-days',
            { 'library-events-timetable--week-days_active': group.date === activeDate }
          ]"
        >
          {{ group.weekDay }}
        </div>
      </div>
      <div class="library-events-timetable--active-date">
        {{ activeDate }} {{ $easyscreenConfig.libraryLabel }}
      </div>
    </div>
    <scrollable
      v-if="activeEvents.length !== 0"
      ref="scrollable"
      max-height="calc(100% - 180px)"
    >
      <div
        v-for="event in activeEvents"
        :key="event.key"
        class="library-events-timetable--event"
      >
        <div
          v-if="event.allDay"
          class="library-events-timetable--event-time"
        >
          <span>
            {{ _l10n("All day") }}
          </span>
        </div>
        <div
          v-else
          class="library-events-timetable--event-time"
        >
          <span>
            <span class="library-events-timetable--event-time-label">
              {{ _l10n("From") }}:
            </span>
            {{ event.from }}
          </span>

          <span>
            <span class="library-events-timetable--event-time-label">
              {{ _l10n("To") }}:
            </span>
            {{ event.to }}
          </span>
        </div>

        <div
          v-if="event.title"
          class="library-events-timetable--event-title"
        >
          {{ event.title }}
        </div>

        <div
          v-if="event.location"
          class="library-events-timetable--event-location"
        >
          {{ _l10n("Local") }}: {{ event.location }}
        </div>

        <div
          v-if="event.organizer"
          class="library-events-timetable--event-organizer"
        >
          {{ _l10n("Organizer") }}: {{ event.organizer }}
        </div>
      </div>
    </scrollable>
    <div
      v-else
      class="library-events-timetable--message library-events-timetable--message_no-events-found"
    >
      {{ _l10n('Inquire about events or premises of librarian') }}
    </div>
  </div>
</template>

<style lang="less" src="./library-events-timetable.less"/>

<script>
  import moment from "moment";
  import * as d3 from "d3-timer";
  import { clamp, uniqBy } from "lodash";
  import l10n from "@/lib/localization/localization.js";

  import Scrollable from "../core/scrollable/scrollable.vue";

  export default {
    name: "library-events-timetable",
    props: {
      items: {
        type: Object,
        default: () => ({})
      },
      pageDuration: {
        type: Number,
        default: 5
      },
      autoAnimation: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      eventsGroups: {
        get() {
          return this._getEventsGroups();
        }
      },
      pageDurationMs() {
        return this.pageDuration * 1000;
      }
    },
    data() {
      const activeGroup = this._getEventsGroups()[0];

      return {
        activeIndex: 0,
        activeDate: activeGroup && activeGroup.date,
        activeEvents: activeGroup && activeGroup.events || [],
        pageChanged: Date.now()
      };
    },
    methods: {
      /**
       * Select the group (date) of events by index.
       * @fires library-events-timetable#group-change
       *
       * @param {Number} index - The index of group from 0 to `this.eventsGroups.length - 1`.
       */
      async selectGroup(index) {
        const _index = clamp(index, 0, this.eventsGroups.length - 1);
        const activeGroup = this.eventsGroups[_index] || {};

        this.activeIndex = _index;
        this.activeDate = activeGroup.date || "";
        this.activeEvents = activeGroup.events || [];
        this.pageChanged = Date.now();

        await this.$nextTick();
        if (this.$refs.scrollable) {
          this.$refs.scrollable.setScroll(0);
        }
        /**
         * The event of calling selectGroup method when the group (date) of events are changed.
         *
         * @event library-events-timetable#group-change
         * @type {Number} The index of active group (date).
         */
        this.$emit("group-change", this.activeIndex);
      },
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Get template-ready events data.
       * Are defined as method due to the `computed` property are empty on `data` initialization step.
       * At the template and outside of `data()` handler should be used as `this.eventsGroups`.
       *
       * @returns {Object[]} eventsGroups - The list of events groups.
       * @returns {} eventsGroups[].rawDate - The raw group date from original data (key of group).
       * @returns {} eventsGroups[].weekDay - The short week day (format `ddd`).
       * @returns {} eventsGroups[].date - Date in format `dddd DD-MM-YY`
       * @returns {} eventsGroups[].events - The list of events.
       * @returns {} eventsGroups[].events[].key - The custom key of event.
       * @returns {} eventsGroups[].events[].title - The event title.
       * @returns {} eventsGroups[].events[].organizer - The event organizer.
       * @returns {} eventsGroups[].events[].allDay - Meaning the event going all day or have time frames.
       * @returns {} eventsGroups[].events[].from - The time from which the event are started.
       * @returns {} eventsGroups[].events[].to - The time when the event are ends.
       * @returns {} eventsGroups[].events[].location - The location of event.
       */
      _getEventsGroups() {
        return Object.keys(this.items).sort((a, b) => new Date(a) - new Date(b)).map(key => {
          return {
            rawDate: key,
            weekDay: moment(new Date(key)).format("ddd"),
            date: moment(new Date(key)).format("dddd DD-MM-YY"),
            events: uniqBy(this.items[key].map(event => {
              return {
                key: `${ event.title }_${ event.organizer }_${ event.from }_${ event.to }`,
                title: event.title,
                organizer: event.organizer,
                allDay: event.allDay,
                from: event.allDay ? null : moment(new Date(event.from)).format("HH.mm"),
                to: event.allDay ? null : moment(new Date(event.to)).format("HH.mm"),
                location: this.$easyscreenSkin.isMiddelfart ? event.rooms : event.location
              };
            }), "key")
          };
        });
      },
      /**
       * Start auto page switch (selection of active date).
       * The auto page switch might be stoped\started by standby events when the `this.autoAnimation` property are `true`.
       */
      _startAutoPageChange() {
        this._stopAutoPageChange();

        this.autoPageSwitch = d3.interval(() => {
          let nextIndex = this.activeIndex + 1;
          if (nextIndex >= this.eventsGroups.length) {
            nextIndex = 0;
          }

          this.selectGroup(nextIndex);
        }, this.pageDurationMs);

        this._startAutoScroll();
        this.$on("group-change", this._startAutoScroll);
      },
      /**
       * Stop auto page switch (selection of active date).
       * The auto page switch might be stoped\started by standby events when the `this.autoAnimation` property are `true`.
       */
      _stopAutoPageChange() {
        if (this.autoPageSwitch && this.autoPageSwitch.stop) {
          this.autoPageSwitch.stop();
        }

        this.$off("group-change", this._startAutoScroll);
        this._stopAutoScroll();
      },
      /**
       * Start auto scroll loop for events list.
       * The auto scroll might be stoped\started by standby events when the `this.autoAnimation` property are `true`.
       */
      _startAutoScroll() {
        this._stopAutoScroll();
        if (!this.$refs.scrollable) {
          return;
        }

        const availableScroll = this.$refs.scrollable.getAvailableScroll();
        if (availableScroll <= 0) {
          return;
        }

        /*
         * The animation will be finished little bit faster than the page is changed.
         * In that case the transition looks better.
         */
        const transitionDuration = this.pageDurationMs - (Date.now() - this.pageChanged) - 1000;
        this.$refs.scrollable.setScroll(availableScroll, {
          easing: "linear",
          behavior: "smooth",
          duration: transitionDuration
        });
      },
      /**
       * Stop auto scroll loop for events list.
       * The auto scroll might be stoped\started by standby events when the `this.autoAnimation` property are `true`.
       */
      _stopAutoScroll() {
        if (this.$refs.scrollable) {
          this.$refs.scrollable.stopScrollTransition();
        }
      }
    },
    components: {
      "scrollable": Scrollable
    },
    screenStandby() {
      if (this.autoAnimation) {
        this._startAutoPageChange();
      }
    },
    screenActive() {
      this._stopAutoPageChange();
    }
  };
</script>
