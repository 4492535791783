var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"easyscreen-accordion"},_vm._l((_vm.elements),function(element,elementIndex){return _c('div',{key:element.key || `element-index-${ elementIndex }`,class:[
      'easyscreen-accordion--element',
      { 'easyscreen-accordion--element_active': elementIndex === _vm.activeElementIndex }
    ],on:{"click":() => { _vm.selectIndex(elementIndex) }}},[_c('div',{staticClass:"easyscreen-accordion--element-title"},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(element.title)+" ")]},{"element":element})],2),_c('div',{staticClass:"easyscreen-accordion--element-content-wrapper",style:({
        backgroundImage: element.image ? `url(${ element.image })` : null,
        height: elementIndex === _vm.activeElementIndex ? _vm.activeContentHeight : null
      }),on:{"click":function($event){return _vm.$emit('expand-element', element, elementIndex)}}},[_c('div',{staticClass:"easyscreen-accordion--element-overlay"}),_c('div',{staticClass:"easyscreen-accordion--element-content"},[_vm._t("content",function(){return [_vm._v(" "+_vm._s(element.content)+" ")]},{"element":element})],2)])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }