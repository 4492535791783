<template>
  <div class="patron-profile">
    <authentication-form
      ref="authenticationForm"
      type="auto-profile"
      @authenticated="_authenticated"
      @closed="(e) => {
        if (state === 'authentication') {
          _reEmit('closed', e);
        }
      }"
    />
    <modal-fullscreen
      ref="profile"
      :class="`patron-profile--skin_${ skin }`"
      colorScheme="easyscreen-menu-profile-page"
      @closed="(e) => {
        profileData = null;
        formData = null;
        _reEmit('closed', e);
      }"
    >
      <template slot="header-left">
        <easyscreen-circle-button
          :icon="skin === 'digital-shelf-profile' ? 'fa fa-home' : '/images/es-menu/home_icon.png'"
          :icon-type="skin === 'digital-shelf-profile' ? 'font-icon' : 'image'"
          :label-placement="skin === 'digital-shelf-profile' ? 'left' : 'right'"
          @click.native="hide"
        >
          {{ _l10n("Logout") }}
        </easyscreen-circle-button>
      </template>
      <template slot="header-center">
        <h1 v-if="title" class="patron-profile--custom-title">{{ title }}</h1>
        <h1 class="easyscreen-header_1">
          {{ screens[activeScreen] && screens[activeScreen].title }}
        </h1>
      </template>
      <template slot="content" v-if="profileData">
        <section-overview
          v-if="screens.overview && activeScreen === 'overview'"
          :provider="screens.overview.cutFor"
          :data="profileData"
          :screens="screens"
          @select-screen="_setScreen"
        />
        <section-user-account
          v-if="screens.userAccount && activeScreen === 'userAccount'"
          :provider="screens.userAccount.cutFor"
          :data="profileData.userAccount"
          :branches="profileData.branches"
          :formData="formData"
          @pin-update="(newPin) => { formData.pin = newPin }"
        />
        <section-debts
          v-if="screens.debts && activeScreen === 'debts'"
          currency="kr."
          :provider="screens.debts.cutFor"
          :fees="profileData.fees"
          :formData="formData"
          :paymentProvider="'dibs'"
          @debts-updated="() => { _reFetch('fees') }"
        />
        <section-loans
          v-if="screens.loans && activeScreen === 'loans'"
          :provider="screens.loans.cutFor"
          :loans="_get(profileData, 'loans.list')"
          :upcoming="_get(profileData, 'loans.nextUpcoming')"
          :formData="formData"
          @loans-updated="() => { _reFetch('loans') }"
        />
        <section-reservations
          v-if="screens.reservations && activeScreen === 'reservations'"
          key="reservations-waiting"
          :provider="screens.reservations.cutFor"
          :title="_l10n('Reservations - See all reservations, change reservation time and library')"
          :reservationsUpdatable="true"
          :reservations="_get(profileData, 'reservations.waiting', [])"
          :formData="formData"
          :branches="profileData.branches"
          :preferredBranch="_get(profileData, 'userAccount.preferredBranch', '')"
          @reservation-updated="() => { _reFetch('reservations') }"
          @reservation-deleted="() => { _reFetch('reservations') }"
        />
        <section-reservations
          v-if="screens.reservationsReady && activeScreen === 'reservationsReady'"
          key="reservations-ready"
          :provider="screens.reservationsReady.cutFor"
          :title="_l10n('Reservations ready to pick up')"
          :reservations="_get(profileData, 'reservations.ready', [])"
          :formData="formData"
          :branches="profileData.branches"
          :preferredBranch="_get(profileData, 'userAccount.preferredBranch', '')"
          @deleted="() => { _reFetch('reservations') }"
        />
      </template>
      <template slot="footer">
        <easyscreen-button-group gap-side="both">
          <easyscreen-button
            class="easyscreen-button_color-profile"
            v-for="(screenOptions, screenKey) in screens"
            :key="screenKey"

            size="big"
            :active="screenKey === activeScreen"
            @click.native="() => { _setScreen(screenKey) }"
          >
            {{ screenOptions.title }}
          </easyscreen-button>
        </easyscreen-button-group>
      </template>
    </modal-fullscreen>
    <modal-alert
      ref="alert"
      @closed="(e) => {
        profileData = null;
        formData = null;
        _reEmit('closed', e);
      }"
    />
  </div>
</template>

<style lang="less" src="./profile.less" />
<style lang="less" src="../../core/mixins.less" />
<script>
  import { get } from "lodash";
  import moment from "moment";
  import capitalize from "capitalize";
  import l10n from "@/lib/localization/localization.js";
  import { ValidationError } from "@/lib/errors.js";
  import reEmitMixin from "../../core/mixins/re-emit.js";

  import ModalAlert from "../../core/modal/alert.vue";
  import ModalFullscreen from "../../core/modal/fullscreen.vue";
  import EasyscreenButton from "../../core/button/button.vue";
  import EasyscreenButtonGroup from "../../core/button/button-group.vue";
  import EasyscreenCircleButton from "../../core/button/circle-button.vue";
  import SectionOverview from "./sections/overview.vue";
  import SectionUserAccount from "./sections/user-account.vue";
  import SectionDebts from "./sections/debts.vue";
  import SectionReservations from "./sections/reservations.vue";
  import SectionLoans from "./sections/loans.vue";
  import AuthenticationForm from "../authentication/authentication.vue";

  /*
   * Map of screens to routes where
   * cutFor - cut in accordance with the possibilities of the provider
   * disabledFor - will disabeld for providers.
   * (By default if route is not evailable this screen also will disabled).
   */
  const routesMap = [{
    route: "get /patron/info",
    screenName: "userAccount",
    cutFor: ["bibliofil", "ex_alma"]
  }, {
    route: "get /patron/fees",
    additionalRoutes: ["get /patron/payment/gateway"],
    screenName: "debts",
    cutFor: ["bibliofil"]
  }, {
    route: "get /patron/reservations",
    screenName: "reservations",
    cutFor: ["bibliofil"]
  }, {
    route: "get /patron/reservations",
    screenName: "reservationsReady",
    disabledFor: ["bibliofil"]
  }, {
    route: "get /patron/loans",
    screenName: "loans"
  }];

  export default {
    name: "patron-profile",
    mixins: [reEmitMixin],
    props: {
      /* The additional title of profile, used for reskin profile on standalone digital shelf. */
      title: String,
      /* The skin of profile, used for reskin profile on standalone digital shelf. */
      skin: {
        type: String,
        default: "default",
        validator: _skin => ["default", "digital-shelf-profile"].includes(_skin)
      }
    },
    data: function() {
      return {
        state: "authentication",
        activeScreen: "overview",
        screens: {
          overview: {
            title: l10n("Overview"),
            cutFor: ""
          },
          userAccount: {
            title: l10n("User account"),
            cutFor: ""
          },
          debts: {
            title: l10n("Debts"),
            cutFor: ""
          },
          loans: {
            title: l10n("Loans"),
            cutFor: ""
          },
          reservations: {
            title: l10n("Reservations"),
            cutFor: ""
          },
          reservationsReady: {
            title: l10n("Reservations ready to pick up"),
            cutFor: ""
          }
        },
        formData: null,
        paymentProvider: "",
        profileData: {
          loans: {
            list: [],
            nextUpcoming: {}
          },
          reservations: {
            total: [],
            waiting: [],
            ready: []
          },
          fees: []
        }
      };
    },
    methods: {
      /**
       * Shows the profile on selected screen.
       *
       * @param {String} screen - The one of supported screen which should be opened, if the screen isn't supported then the "overview" will be used.
       *   Supported screens: overview, userAccount, debts, loans, reservations, reservationsReady.
       *   Note: some screens can be disabled for specific consumer.
       */
      show(screen) {
        if (this.profileData !== null) {
          return false;
        }

        this.$refs.profile.show();
        this._setScreen(screen);
      },
      /**
       * Closes the profile.
       */
      hide() {
        this.$refs.authenticationForm.hide();
        this.$refs.profile.hide();
      },
      /**
       * Shows the authentication user form before profile screen.
       *
       * @param {String} screen - The one of supported screen which should be opened (see supported screen on .show method).
       */
      authenticate(screen) {
        this.state = "authentication";
        this.$refs.authenticationForm.show();
        this._setScreen(screen);
      },
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Set's the active screen if the selected screen are supported.
       *
       * @param {String} screen - The one of supported screen which should be opened (see supported screen on .show method).
       * @param {String} [defaultScreen="overview"] - The default screen which will be shown when the main screen is missing.
       */
      _setScreen(screen, defaultScreen) {
        if (screen && screen in this.screens && this.screens[screen]) {
          this.activeScreen = screen;
        } else {
          this.activeScreen = defaultScreen || "overview";
        }
      },
      /**
       * The callback of authentication user form.
       * Loading the profile data based on user profile and form info.
       * @async
       *
       * @param {Object} userProfileData - The user profile info (data from GET [lms]/[consumer-hash]/patron/info).
       * @param {Object} formData - The authentication user form data.
       * @param {String} formData.cpr - The user cpr.
       * @param {String} formData.pin - The user pin.
       */
      async _authenticated(userProfileData, formData) {
        try {
          this.state = "loading-profile-data";
          this.profileData = await this._downloadProfileData(formData);
          this.state = "profile-data-loaded";

          this.profileData.userAccount = userProfileData;
          this.formData = formData;
          this.$refs.authenticationForm.hide();
          this.$refs.profile.show();
        } catch (err) {
          console.error(err);
          this.$refs.authenticationForm.hide();
          this.$refs.alert.show({
            title: l10n("Error"),
            message: l10n("E.g. incorrect CPR or PIN")
          });
        }

      },
      /**
       * Wrapper for loading the reservations from lms.
       * @async
       *
       * @param {Object} options - The authentication user form data.
       * @param {String} options.cpr - The user cpr.
       * @param {String} options.pin - The user pin.
       */
      async _fetchReservations(options) {
        if (!this.screens.reservations && !this.screens.reservationsReady) {
          return null;
        }

        if (!options.cpr || !options.pin) {
          throw new ValidationError({ message: l10n("E.g. incorrect CPR or PIN") });
        }

        let reservations = await this.$easyscreenRequest.lmsConnector.getReservations({
          user: options.cpr,
          pin: options.pin
        });

        reservations = reservations || [];

        let waiting = [];
        let ready = [];

        reservations.filter(reservation => {
          if (get(reservation, "providerSpecificFields.state") === "readyForPickup") {
            ready.push(reservation);
          } else {
            waiting.push(reservation);
          }
        });

        return {
          total: reservations,
          waiting: waiting,
          ready: ready
        };
      },
      /**
       * Wrapper for loading the loans from lms.
       * @async
       *
       * @param {Object} options - The authentication user form data.
       * @param {String} options.cpr - The user cpr.
       * @param {String} options.pin - The user pin.
       */
      async _fetchLoans(options) {
        if (!this.screens.loans) {
          return null;
        }

        let loans = await this.$easyscreenRequest.lmsConnector.getLoans({
          user: options.cpr,
          pin: options.pin
        });

        loans = loans || [];

        return {
          nextUpcoming: loans.length === 0 ? false : loans.reduce((upcomingLoan, loan) => {
            const loanDueDate = moment(get(loan, "loanDetails.dueDate"), "YYYY-MM-DD");
            const upcomingLoanDueDate = moment(get(upcomingLoan, "loanDetails.dueDate"), "YYYY-MM-DD");
            if (loanDueDate < upcomingLoanDueDate) {
              return loan;
            }

            return upcomingLoan;
          }),
          list: loans
        };
      },
      /**
       * Wrapper for loading the fees from lms.
       * @async
       *
       * @param {Object} options - The authentication user form data.
       * @param {String} options.cpr - The user cpr.
       * @param {String} options.pin - The user pin.
       */
      _fetchFees(options) {
        if (!this.screens.debts) {
          return null;
        }

        return this.$easyscreenRequest.lmsConnector.getFees({
          user: options.cpr,
          pin: options.pin
        });
      },
      /**
       * The helper for refetch data and force updated the view.
       *
       * @param {String} type - The type of data: reservations, loans, fees.
       */
      async _reFetch(type) {
        let method = `_fetch${ capitalize(type) }`;
        if (!this[method]) {
          console.warn(`Method "${ method }" not exists.`);

          return;
        }

        try {
          const data = await this[method](this.formData);

          this.profileData[type] = data;
          this.$forceUpdate();
        } catch (error) {
          console.error("Can't refetch content!", error);
        }
      },
      /**
       * Loads the profile user data.
       * @async
       *
       * @param {Object} options - The authentication user form data.
       * @param {String} options.cpr - The user cpr.
       * @param {String} options.pin - The user pin.
       *
       * @return {Promise} The promise-based callback with data `{ reservations: [], loans: [], fees: [], branches: {} }`
       */
      async _downloadProfileData(options) {
        try {
          const [
            reservations,
            loans,
            fees,
            branches
          ] = await Promise.all([
            this._fetchReservations(options),
            this._fetchLoans(options),
            this._fetchFees(options),
            this.$easyscreenRequest.lmsConnector.branches()
          ]);

          this.$easyscreenStatistic.loginUserProfile();
          return {
            reservations,
            loans,
            fees,
            branches
          };
        } catch (error) {
          this.$easyscreenStatistic.loginUserProfileFailed();
          throw error;
        }
      },
      /**
       * The proxy method of `lodash.get`.
       */
      _get: get
    },
    async created() {
      try {
        const providers = await this.$easyscreenRequest.lmsConnector.providers();
        this.paymentProvider = providers["get /patron/payment/gateway"];
        routesMap.forEach(mapItem => {
          let provider = providers[mapItem.route];

          /* See description for mapItem in top of script tag. */
          let isDisabled = mapItem.disabledFor && mapItem.disabledFor.includes(provider);
          let isCutted = mapItem.cutFor && mapItem.cutFor.includes(provider);
          let additionalRoutesIsEnabled = !mapItem.additionalRoutes || mapItem.additionalRoutes.every((route) => !!providers[route]);

          if (!provider || isDisabled || !additionalRoutesIsEnabled) {
            delete this.screens[mapItem.screenName];
          } else if (isCutted) {
            this.screens[mapItem.screenName].cutFor = provider;
          }
        });

        await this.$forceUpdate();
        this._setScreen(this.activeScreen);
      } catch (error) {
        console.error("Providers list can't be taken", error);
      }
    },
    components: {
      "authentication-form": AuthenticationForm,
      "modal-alert": ModalAlert,
      "modal-fullscreen": ModalFullscreen,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-button-group": EasyscreenButtonGroup,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "section-overview": SectionOverview,
      "section-user-account": SectionUserAccount,
      "section-debts": SectionDebts,
      "section-reservations": SectionReservations,
      "section-loans": SectionLoans
    }
  };
</script>
