<template>
  <div class="easyscreen-form-section">
    <h3 class="easyscreen-form-section--title" v-if="title">{{ title }}</h3>
    <div class="easyscreen-form-section--content" :style="contentStyle">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="less" src="./form-section.less"></style>
<script>
  export default {
    name: "easyscreen-form-section",
    props: {
      /* The title of form section */
      title: String,
      /* Custom styles for content node. */
      contentStyle: [String, Number, Object, Array]
    }
  };
</script>
