<!-- TODO: Fix the loading of styles. -->
<style lang="less" src="./vue-component.less"></style>
<script>
  import { isFunction } from "lodash";

  import LibraryEventsTimetable from "../../library-events/library-events-timetable.vue";
  import LibraryEventsList from "../../library-events/library-events-list.vue";
  import OpeningHours from "../../opening-hours/opening-hours.vue";
  // eslint-disable-next-line
  import DigitalShelf from "../../digital-shelf/digital-shelf.vue";
  import DigitalShelfLight from "../../digital-shelf-light/digital-shelf-light.vue";
  import PatronProfile from "../../patron/profile/profile.vue";
  import MaterialsScanner from "../../materials-scanner/materials-scanner.vue";
  import EasyscreenMenu from "../../easyscreen-menu/easyscreen-menu.vue";
  import PopularSearchesInline from "../../popular-searches/inline.vue";
  import EasyscreenSearch from "../../search/search.vue";
  import EasyscreenSearchStandalone from "../../search/standalone.vue";
  import EasyscreenSearchVisualShelf from "../../search/visual-shelf.vue";
  import WelcomeScreen from "../../welcome-screen/welcome-screen.vue";
  import TextAndImage from "../../text-and-image/text-and-image.vue";
  import EasyscreenIframe from "../../core/iframe/iframe.vue";
  import EasyscreenYoutube from "../../core/youtube/youtube.vue";
  import EasyscreenVideo from "../../core/video/video-native.vue";
  import NodeListCarousel from "../../node-list/node-list-carousel.vue";
  import NodeListAccordion from "../../node-list/node-list-accordion.vue";
  import NodeListTab from "../../node-list/node-list-tab.vue";
  import MaterialPromotion from "../../material-promotion/material-promotion.vue";
  import InspirationScanner from "../../inspiration-scanner/inspiration-scanner.vue";
  const VideoHost = () => import("../../video-host/video-host.vue");
  const PresentationSet = () => import("../../presentation-set/presentation-set.vue");
  import PresentationSetTitle from "../../presentation-set/presentation-set-title.vue";
  import PresentationSetCollection from "../../presentation-set-collection/presentation-set-collection.vue";
  import QrShelf from "../../qr-shelf/qr-shelf.vue";
  import Loader from "../loader/loader.vue";
  import EasyscreenGame from "../../game/game.vue";
  import EasyscreenEventsPromotion from "../../events-promotion/events-promotion.vue";

  /* TODO: render loader when the component have getter and getter is not called yet. */
  const scheme = {
    name: "vue-component",
    props: ["props", "name", "getter", "settings"],
    data() {
      let state = "ready";
      if (scheme.components[this.name] === undefined) {
        state = "missing";
      } else if (this.getter) {
        state = "loading";
      }

      return {
        state: state,
        getterOptions: {}
      };
    },
    render(createElement) {
      if (this.state === "missing") {
        return createElement("div", {
          ref: "component",
          attrs: {
            className: "vue-component--message"
          }
        }, [
          `${ this.name } are missing`
        ]);
      } else if (this.state === "loading") {
        return createElement("loader", {
          props: {
            type: "in-place"
          }
        });
      } else if (this.state === "error") {
        return createElement("div", {
          ref: "component",
          attrs: {
            className: "vue-component--message"
          }
        }, [
          `Can't load content for ${ this.name }`
        ]);
      }

      return createElement(this.name, {
        ref: "component",
        props: {
          ...this.props,
          ...this.getterOptions
        },
        on: this.$listeners
      });
    },
    async mounted() {
      this.$component = this.$refs.component;

      if (isFunction(this.settings)) {
        this.settings(this);
      }

      if (this.getter) {
        try {
          this.getterOptions = await this.getter(this);
          this.state = "ready";
        } catch (error) {
          console.error(`Can't load content for ${ this.name }`, error);
          this.state = "error";
        }
      }
    },
    components: {
      "loader": Loader,
      "digital-shelf": DigitalShelf,
      "digital-shelf-light": DigitalShelfLight,
      "library-events-timetable": LibraryEventsTimetable,
      "library-events-list": LibraryEventsList,
      "opening-hours": OpeningHours,
      "patron-profile": PatronProfile,
      "materials-scanner": MaterialsScanner,
      "easyscreen-menu": EasyscreenMenu,
      "popular-searches-inline": PopularSearchesInline,
      "easyscreen-search": EasyscreenSearch,
      "easyscreen-search--standalone": EasyscreenSearchStandalone,
      "easyscreen-search--visual-shelf": EasyscreenSearchVisualShelf,
      "welcome-screen": WelcomeScreen,
      "text-and-image": TextAndImage,
      "easyscreen-iframe": EasyscreenIframe,
      "easyscreen-youtube": EasyscreenYoutube,
      "easyscreen-video": EasyscreenVideo,
      "node-list-carousel": NodeListCarousel,
      "node-list-accordion": NodeListAccordion,
      "node-list-tab": NodeListTab,
      "material-promotion": MaterialPromotion,
      "inspiration-scanner": InspirationScanner,
      "video-host": VideoHost,
      "presentation-set": PresentationSet,
      "presentation-set-title": PresentationSetTitle,
      "easyscreen-game": EasyscreenGame,
      "presentation-set-collection": PresentationSetCollection,
      "qr-shelf": QrShelf,
      "easyscreen-events-promotion": EasyscreenEventsPromotion
    }
  };

  export default scheme;
</script>
