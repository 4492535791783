export default {
  methods: {
    _onBarcodeMeterialScanner(barcodeValue) {
      if (this.isShown !== true)
        return;

      const isReturnStage = this.stage === "return";
      const isLoanStage = this.stage === "loan" && (Date.now() - this.authenticationTimestamp > 100);
      const isScanStage = this.stage === "scan";

      if (isReturnStage || isLoanStage || isScanStage) {
        this.$friendlyFrankScanner._addMaterial(barcodeValue);
      }
    }
  },
  mounted() {
    if (this.$easyscreenConfig.get("enable.selfcheckViaBarcode")) {
      if (this.$friendlyFrankBarcodeScanner) {
        this.$friendlyFrankBarcodeScanner.on("scan", this._onBarcodeMeterialScanner);
      }

      this.$on("closed", () => this.$friendlyFrankScanner.clearInventory());
    }
  },
  beforeDestroy() {
    if (this.$friendlyFrankBarcodeScanner) {
      this.$friendlyFrankBarcodeScanner.off("scan", this._onBarcodeMeterialScanner);
    }
  }
};
