<template>
  <div
    :class="[
      'digital-shelf--cover',
      `digital-shelf--cover_align-${ align }`,
      {
        'digital-shelf--cover_without-cover-background': !withCoverBackground,
        'digital-shelf--cover_is-default-cover': isDefaultCover || isLoaded === false,
        [`digital-shelf--cover_horizontal-align-${ horizontalAlign }`]: horizontalAlign === 'center'
      }
    ]"
    :style="{
      width: width
    }"
  >
    <div class="digital-shelf--cover-bg">
      <div
        class="digital-shelf--cover-wrapper"
        :style="{
          backgroundImage: `url('${ blurredCover }'), url('/images/defaultCover.blur.jpg')`
        }"
      ></div>
      <div
        v-if="isDefaultCover || isLoaded === false"
        class="digital-shelf--cover-wrapper_cover"
      >
        <p class="digital-shelf--cover-wrapper_title">{{ title }}</p>
        <p class="digital-shelf--cover-wrapper_author">{{ author }}</p>
      </div>
      <div
        v-if="isDefaultCover !== true"
        :class="[
          'digital-shelf--cover-wrapper_image',
          { 'digital-shelf--cover-wrapper_loading': isLoaded === false }
        ]"
      >
        <img
          ref="image"
          draggable="false"
          :src="$easyscreenRequest.lmsConnector.wrapCover(cover)"
          crossOrigin="annonimus"
        >
      </div>
    </div>
  </div>
</template>

<style lang="less" src="./cover.less" />
<script>
  import checkImageStatus from "@/lib/utils/check-image-status.js";
  import getDominantImageColor from "@/lib/utils/get-dominant-image-color.js";

  export default {
    name: "digital-shelf_cover",
    props: {
      withCoverBackground: {
        type: Boolean,
        default: true
      },
      align: {
        type: String,
        default: "center",
        validator: _align => ["top", "center", "bottom"].includes(_align)
      },
      /* The title of material for fallback cover with default image. */
      title: String,
      /* The author of material for fallback cover with default image. */
      author: String,
      /* Url of the cover, from material detail (wihtout the host) */
      cover: {
        type: String,
        default: "/images/defaultCover.jpg"
      },
      horizontalAlign: {
        type: String,
        default: "center",
        validator: _align => ["top", "center", "bottom"].includes(_align)
      },
      /* Custom width of cover */
      width: [Number, String]
    },
    computed: {
      isDefaultCover() {
        return this.loadingFailed || (this.cover || "").includes("defaultCover.jpg");
      },
      blurredCover() {
        if (this.isDefaultCover) {
          return "/images/defaultCover.blur.jpg";
        }

        return this.$easyscreenRequest.lmsConnector.wrapCover(this.cover + "/blur");
      }
    },
    data() {
      return {
        loadingFailed: null,
        dominantColor: null,
        isLoaded: false
      };
    },
    methods: {
      /**
       * Check the cover status and applies default and set the failing status if needed.
       */
      _checkImageStatus() {
        if (this.$refs.image) {
          let imageStatus = checkImageStatus(this.$refs.image);
          this.isLoaded = imageStatus === 1;

          if (imageStatus === 0) {
            this.$refs.image.onload = this._checkImageStatus;
            this.$refs.image.onerror = this._checkImageStatus;
          } else if (imageStatus === -1) {
            this.loadingFailed = true;
            this.$emit("failed");
          }
        }
      }
    },
    async mounted() {
      this._checkImageStatus();
      this.dominantColor = getDominantImageColor(this.$refs.image);
      this.dominantColor = await this.dominantColor;
    }
  };
</script>
