<template>
  <transition name="fade">
    <img
      v-if="shown"
      src="/images/icon/swipe.gif"
    />
  </transition>
</template>

<style src="../transition.less" lang="less"></style>

<script>
  export default {
    name: "swipe-example",
    props: {
      duration: {
        type: Number,
        default: 900
      }
    },
    data() {
      return {
        shown: true
      };
    },
    methods: {
      /**
       * Show the image with swipe animation.
       */
      show() {
        this.shown = true;
        this.timeout = setTimeout(this.hide, this.duration);
      },
      /**
       * Hide the image with swipe animation.
       */
      hide() {
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }

        this.shown = false;
      }
    }
  };
</script>
