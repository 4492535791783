import lodash from "lodash";

/* The options of display and sort the detail meta for norway and denmark views. */
const detailOptions = {
  skipMeta: [
    "abstract",
    "hylle",
    "krydderpop",
    "krydderbildeurl",
    "websokurl",
    "krydderantstjernekast",
    "rating",
    "bibliofilid",
    "lf",
    "omfang",
    "undertekster",
    "tags",
    "dk5WithoutName"
  ],
  sortings: {
    norway: [
      "shelfMark",
      "dewey",
      "language",
      "dokumenttype",
      "extent",
      "publisher",
      "isbn",
      "audience"
    ],
    denmark: [
      "language",
      "genre",
      "version",
      "extent",
      "alternative",
      "audience",
      "temporal",
      "spatial",
      "published",
      "source",
      "isbn"
    ]
  }
};

export default {
  data() {
    return {
      metaSorting: detailOptions.sortings[this.$easyscreenConfig.get("itemPopup.metaSorting")] || []
    };
  },
  methods: {
    /**
     * Get the material meta with filtration.
     * @param {Object} materialDetail - Material detail.
     * @param {Object} [materialDetail.meta] - Material detail meta.
     *
     * @returns {Object} The detail meta, see structure for MaterialDetail (`detail.meta`).
     */
    _getVisibleMeta(materialDetail) {
      let omitList = detailOptions.skipMeta;
      if (this.$easyscreenConfig.get("enable.hideShelfMark")) {
        omitList = omitList.concat(["shelfMark"]);
      }

      return lodash.omit(lodash.get(materialDetail, "meta"), omitList);
    },
    /**
     * Get the sorted and filtered meta.
     * @param {Object} materialDetail - Material detail.
     * @param {Object} [materialDetail.meta] - Material detail meta.
     *
     * @returns {Object[]} meta - The list of meta data.
     * @returns {String} meta[].property - The property key.
     * @returns {String} meta[].value - The property value.
     */
    _getMetaPair(materialDetail, options) {
      options = {
        keys: null,
        order: null,
        map: {},
        ...(options || {})
      };

      const isNorvegian = this.$easyscreenConfig.get("lmsConnector.l10n.language", "").toLowerCase() === "no";
      let meta = this._getVisibleMeta(materialDetail);
      if (options.keys) {
        meta = lodash.pick(meta, options.keys);
        if (options.order == null) {
          options.order = options.keys;
        }
      }

      if (isNorvegian && materialDetail.bibliofilid && meta.hylle) {
        delete meta.dewey;
      } else if (meta.hylle && meta.dewey) {
        meta.hylle = meta.dewey;
      }

      const order = options.order || this.metaSorting;
      let unsortedKeys = Object.keys(lodash.omit(meta, order));
      return [].concat(order).concat(unsortedKeys).map(key => {
        let value = meta[key];
        if (Array.isArray(value)) {
          value = value.join(", ");
        }

        return {
          property: options.map[key] || key,
          value: value
        };
      }).filter(pair => pair.value);
    }
  }
};
