import EventEmitter from "eventemitter3";
import { isString, isObjectLike, clamp } from "lodash";

let activeInstances = 0;
let inputInterceptorInstance;
/**
 * Intercepted input.
 *
 * @event InputInterceptor#input
 * @type {Object}
 * @property {String} data - The input value.
 */

/**
 * Intercepts the page input and emits the input events.
 * @class InputInterceptor
 * @augments EventEmitter3
 * @fires InputInterceptor#input
 */
export default class InputInterceptor extends EventEmitter {
  static get instance() {
    return inputInterceptorInstance;
  }

  /**
   * Static method for emulate the keyboard input.
   * 
   * @param {String} string - The value which input should be emulated.
   */
  static emulateInput(string) {
    if (!isString(string) || !inputInterceptorInstance) {
      return;
    }


    string.split("").forEach((char) => {
      inputInterceptorInstance.emit("input", { data: char });
    });
  }

  constructor() {
    /*
     * Count the active references for detach input from
     * dom when the active references are gone.
     */
    activeInstances += 1;

    if (inputInterceptorInstance) {
      return inputInterceptorInstance;
    }

    super();

    this.attach();
    inputInterceptorInstance = this;
  }

  /**
   * Decrease the counter of active instance references and
   * detach the input from document if active references are gone.
   */
  destroy() {
    activeInstances = clamp(activeInstances - 1, 0, Infinity);

    if (activeInstances === 0) {
      this.detach();
    } 
  }

  /**
   * Attach the input into the document and add the interception events
   * (the input will be focused on input blur event).
   */
  attach() {
    if (this.input) {
      return;
    }

    this.input = document.createElement("input");
    this.input.style.position = "absolute";
    this.input.style.left = "-1000px";
    this.input.style.top = "-1000px";

    this.input.oninput = () => {
      const data = this.input.value;
      this.input.value = "";
      this.emit("input", { data });
    };

    this.input.onblur = () => {
      this.input.focus();
    };

    document.body.appendChild(this.input);
    this.input.focus();
  }

  /**
   * Detach the input from document and remove the "input" event listeners.
   */
  detach() {
    if (this.input && this.input.parentNode) {
      this.removeAllListeners("input");
      this.input.parentNode.removeChild(this.input);
      this.input = null;
    }
  }
}

if (isObjectLike(window)) {
  /* Only for development needs. */
  window.InputInterceptor = InputInterceptor;
}
