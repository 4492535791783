<template>
  <div
    :class="[
      'easyscreen-foldable',
      `easyscreen-foldable_design-${ design }`,
      { 'easyscreen-foldable_height-limit-is-reached': heightLimitIsReached }
    ]"
  >
    <easyscreen-circle-button
      v-if="heightLimitIsReached && toggleButton"
      :class="['easyscreen-foldable--toggle', toggleButtonClass]"
      :icon="[
        'fal',
        {
          'fa-chevron-left': !computedOpen && design === 'classic',
          'fa-chevron-down': computedOpen && design === 'classic',
          'fa-arrow-left': !computedOpen && design === 'light',
          'fa-arrow-down': computedOpen && design === 'light'
        }
      ]"
      size="small"
      :color="design === 'light' ? 'white' : undefined"

      @click.native="toggle"
    />

    <div
      ref="content"
      class="easyscreen-foldable--content"
      :style="{ maxHeight: maxFoldedHeight }"
    >
      <slot></slot>
    </div>
    <easyscreen-tooltip
      v-if="heightLimitIsReached"
      :open="computedOpen"
      placement="bottom-start"
      :tooltip-class="['easyscreen-foldable--tooltip', tooltipClass]"
    >
      <div class="tooltip-target easyscreen-foldable--tooltip-target"></div>
      <template slot="tooltip">
        <div
          class="easyscreen-foldable--scrollable-wrapper"
          :style="{ width: contentWidth }"
        >
          <easyscreen-scrollable
            :max-height="maxUnflodedHeight"
            :smooth-edge-color="backgroundColor"
          >
            <div
              class="easyscreen-foldable--tooltip-content"
              :style="{ backgroundColor: backgroundColor }"
            >
              <slot></slot>
            </div>
          </easyscreen-scrollable>
        </div>
      </template>
    </easyscreen-tooltip>
  </div>
</template>
<style src="./foldable.less" lang="less"></style>

<script>
  import EasyscreenCircleButton from "../button/circle-button.vue";
  import EasyscreenTooltip from "../tooltip/tooltip.vue";
  import EasyscreenScrollable from "../scrollable/scrollable.vue";

  import isElementOf from "@/lib/utils/is-element-of.js";
  import { parentNodeByFilter, classFilter } from "@/lib/utils/parent-node-by-filter.js";
  import mutationObservationMixin from "../mixins/mutation-observation.js";

  export default {
    name: "easyscreen-foldable",
    mixins: [mutationObservationMixin],
    props: {
      /* The open state of foldable content */
      open: {
        type: Boolean,
        default: null
      },
      /* The max height of folded view in px. */
      maxFoldedHeight: {
        type: Number
      },
      /* The max height of unfolded view in px. */
      maxUnflodedHeight: {
        type: Number
      },
      /* The color of background in any css compatible format. Default: rgba(0, 0, 0, 0) */
      backgroundColor: {
        type: String,
        default: "rgba(0, 0, 0, 0)"
      },
      /* The classname of the tooltip element. */
      tooltipClass: [String, Array, Object],
      toggleButton: {
        type: Boolean,
        default: true
      },
      toggleButtonClass: [String, Array, Object],
      /* The global reskin. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    computed: {
      computedOpen() {
        return this.open == null ? this.localOpen : this.open;
      }
    },
    data() {
      return {
        localOpen: false,
        heightLimitIsReached: false,
        contentWidth: 0,
        watchRefs: "content"
      };
    },
    watch: {
      /* Update the current height limits on `maxFoldedHeight` property change. */
      maxFoldedHeight() {
        this._checkHeightLimits();
      }
    },
    methods: {
      /**
       * Show the unfolded view.
       */
      show() {
        this.$emit("before-open");
        this.localOpen = true;
        this.$emit("opened");
        this._updateContentWidth();
      },
      /**
       * Hide the unfolded view.
       */
      hide() {
        this.$emit("before-close");
        this.localOpen = false;
        this.$emit("closed");
      },
      /**
       * Toggle the unfolded view.
       */
      toggle() {
        if (this.computedOpen) {
          this.hide();
        } else {
          this.show();
        }
      },
      /**
       * Set the unfolded container with based on original container width.
       */
      _updateContentWidth() {
        if (this.computedOpen) {
          this.contentWidth = parseFloat(window.getComputedStyle(this.$refs.content).width);
        }
      },
      /**
       * Check if the folded view is reached the limit.
       * Sets the limit flag and shows\hides the toggle button.
       */
      _checkHeightLimits() {
        let contentHeight = parseFloat(window.getComputedStyle(this.$refs.content).height);
        this.heightLimitIsReached = contentHeight >= this.maxFoldedHeight;
      },
      /**
       * The mouse\touch down event handler.
       * Used for close the unfolded view after click outside of unfolded content.
       *
       * @param {Event} event - The native mousedown event.
       */
      _mouseDownListener(event) {
        let isToggleButton = isElementOf(event.target, "easyscreen-foldable--toggle");
        let isToggleOfCurrentInstance;
        if (isToggleButton) {
          let targetIsCurrentInstance = event.target === this.$el;
          let parentOfTartgetIsCurrentInstance = parentNodeByFilter(event.target, classFilter("easyscreen-foldable"));

          isToggleOfCurrentInstance = targetIsCurrentInstance || parentOfTartgetIsCurrentInstance;
        }

        /*
         * Only one tooltip of this component can be shown at the one time.
         * Due to this the following checking is not require the checking of owner.
         */
        let isTooltipElement = isElementOf(event.target, "easyscreen-foldable--tooltip");
        if ((isToggleButton && isToggleOfCurrentInstance) || isTooltipElement) {
          return;
        }

        this.hide();
      }
    },
    mounted() {
      window.addEventListener("mousedown", this._mouseDownListener);
      /*
       * The mutation observer events has coming from mutationObservationMixin using `watchRefs`
       * For update the content of unfolded view in runtime on parent changes.
       */
      this.$on("mutation:content", this._checkHeightLimits);
      this._checkHeightLimits();
    },
    beforeDestroy() {
      window.removeEventListener("mousedown", this._mouseDownListener);
      this.$off("mutation:content", this._checkHeightLimits);
    },
    components: {
      "easyscreen-circle-button": EasyscreenCircleButton,
      "easyscreen-tooltip": EasyscreenTooltip,
      "easyscreen-scrollable": EasyscreenScrollable
    }
  };
</script>
