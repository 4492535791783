var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal-fullscreen',{ref:"modal",class:[
      'easyscreen-search-results',
      `easyscreen-search-results_${ _vm.colorScheme }`,
      _vm.orientation,
      { 'easyscreen-search-results_with-title': _vm.title }
    ],attrs:{"colorScheme":"easyscreen-menu","hide-empty-container":true,"header-height":_vm.orientation === 'landscape-orientation' ? '150px' : '246px',"position":_vm.position},on:{"before-open":(event) => _vm.$emit('before-open', event),"opened":(event) => _vm.$emit('opened', event),"before-close":(event) => _vm.$emit('before-close', event),"closed":(event) => {
      _vm._reset();
      _vm.$emit('closed', event);
    }}},[(_vm.title)?_c('template',{slot:"header-center"},[_c('div',{staticClass:"easyscreen-search-results--title"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]):_vm._e(),_c('template',{slot:"header-left"},[_c('div',{staticClass:"easyscreen-search-results--navigation"},[_c('easyscreen-circle-button',{staticClass:"easyscreen-search-results--home-button",attrs:{"icon":"/images/es-menu/home_icon.png","icon-type":"image"},nativeOn:{"click":function($event){return ((event) => {
            _vm.hide();
            _vm.$emit('go-home', event)
          }).apply(null, arguments)}}}),_c('search-input',{staticClass:"easyscreen-search-results--input es-ml-4",attrs:{"value":_vm.query,"readonly-input":true,"sorting":_vm.sorting},on:{"sorting-selected":(sorting) => _vm._clarifySearch({ sorting: sorting.value })},nativeOn:{"click":function($event){return ((event) => {
            /* Check if the clicked element has mounted. Otherwise the `_isSortingElement` might return the false-negative result. */
            if (_vm._isElementOf(event.target, _vm.winproxy.document.body) && !_vm._isSortingElement(event.target)) {
              _vm.formQuery = _vm._isClearButtonElement(event.target) ? '' : _vm.query;
              _vm.$refs.searchFormModal.show();
            }
          }).apply(null, arguments)}}}),_c('div',{staticClass:"easyscreen-search-results--facets-group"},[(_vm.availableOnlyButton)?_c('easyscreen-button',{staticClass:"easyscreen-search-results--facet-button easyscreen-search-results--facet-button_fixed-text",attrs:{"color":"primary"},nativeOn:{"click":function($event){return (() => _vm._clarifySearch({ availableOnly: !_vm.availableOnly })).apply(null, arguments)}}},[_c('span',{staticClass:"easyscreen-search-results--facet-button-text"},[_vm._v(_vm._s(_vm._l10n('Available only')))]),_c('i',{class:[
              'fa',
              'fa-check',
              'easyscreen-search-results--facet-icon_circle-border',
              { 'easyscreen-search-results--facet-icon_border-only': !_vm.availableOnly }
            ]})]):_vm._e(),(_vm.shelfOnlyButton)?_c('easyscreen-button',{staticClass:"easyscreen-search-results--facet-button",attrs:{"color":"primary"},nativeOn:{"click":function($event){return (() => _vm._clarifySearch({ shelfOnly: !_vm.shelfOnly })).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm._l10n('This shelf only'))+" "),_c('i',{class:[
              'fa',
              'fa-check',
              'easyscreen-search-results--facet-icon_circle-border',
              { 'easyscreen-search-results--facet-icon_border-only': !_vm.shelfOnly }
            ]})]):_vm._e(),_vm._l((_vm._getPreviewFacets()),function(previewFacet){return _c('easyscreen-button',{key:previewFacet.id,staticClass:"easyscreen-search-results--facet-button easyscreen-search-results--facet-button_fixed-text",attrs:{"color":"primary"},nativeOn:{"click":function($event){return (() => _vm.$refs.searchFacets.showSingleFacetModal(previewFacet.id, { standalone: true })).apply(null, arguments)}}},[_c('span',{class:[
                'easyscreen-search-results--facet-button-text',
                { 'easyscreen-search-results--facet-button-text_with-counter': (_vm.selectedFacets[previewFacet.id] || []).length !== 0 }
              ]},[_c('span',{staticClass:"easyscreen-search-results--facet-button-text_label"},[_vm._v(" "+_vm._s(previewFacet.name)+" ")]),((_vm.selectedFacets[previewFacet.id] || []).length !== 0)?_c('span',{staticClass:"easyscreen-search-results--facet-button-text_counter"},[_vm._v(" ("+_vm._s((_vm.selectedFacets[previewFacet.id] || []).length)+") ")]):_vm._e()]),_c('i',{staticClass:"fa fa-caret-down easyscreen-search-results--facet-icon_right-center"})])}),((_vm.facets || []).length !== 0)?_c('easyscreen-button',{staticClass:"easyscreen-search-results--facet-button",attrs:{"color":"primary"},nativeOn:{"click":function($event){return (() => _vm.$refs.searchFacets.show()).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm._l10n("Show more facets"))+" ")]):_vm._e()],2)],1)]),_c('template',{slot:"header-right"},[_c('easyscreen-circle-button',{staticClass:"easyscreen-search-results--close-button",attrs:{"icon":"/images/es-menu/close_icon.png","icon-type":"image"},nativeOn:{"click":function($event){return _vm.hide.apply(null, arguments)}}})],1),_c('template',{slot:"content"},[_c('div',{staticClass:"easyscreen-search-results--content"},[_c('div',{staticClass:"easyscreen-search-results--page-info"},[_vm._v(" "+_vm._s(_vm._l10n('Showing'))+" "+_vm._s(_vm._getShownRange())+" "+_vm._s(_vm._l10n('out of'))+" "+_vm._s(_vm.hits)+" "+_vm._s(_vm._l10n('results'))+" "),(_vm.searchInProgress)?_c('i',{staticClass:"fa fa-spinner fa-spin"}):_vm._e()]),_c('div',{staticClass:"easyscreen-search-results--carousel"},[_c('easyscreen-carousel',{ref:"carousel",attrs:{"layout-columns":_vm.layoutColumns,"layout-rows":_vm.layoutRows},on:{"before-position-change":(_, followingSlideIndex) => {
              _vm.carouselSlideIndex = followingSlideIndex;
              _vm._preload();
            }}},_vm._l((_vm.results),function(item){return _c('search-cover',{key:item.id,attrs:{"title":item.title,"author":item.author,"year":item.year,"type":item.type,"cover":item.cover,"availability":_vm._availabilityStatus(item)},nativeOn:{"click":function($event){return (() => _vm._showMaterialView(item)).apply(null, arguments)}}})}),1)],1)])])],2),_c('modal-fullscreen',{ref:"searchFormModal"},[_c('template',{slot:"header-right"},[_c('easyscreen-circle-button',{attrs:{"icon":"/images/es-menu/close_icon.png","icon-type":"image"},nativeOn:{"click":function($event){return _vm.$refs.searchFormModal.hide.apply(null, arguments)}}})],1),_c('template',{slot:"content"},[_c('search-form',{ref:"searchForm",attrs:{"with-sorting":true,"default-query":_vm.formQuery,"default-sorting":_vm.sorting,"popular-searches":_vm.popularSearches},on:{"find":(query, sorting) => {
          _vm._clarifySearch({ query: query, sorting: sorting });
          _vm.$refs.searchFormModal.hide();
        }}})],1),_c('template',{slot:"footer-right"},[_c('easyscreen-circle-button',{attrs:{"icon":"/images/es-menu/close_icon.png","icon-type":"image"},nativeOn:{"click":function($event){return _vm.$refs.searchFormModal.hide.apply(null, arguments)}}})],1)],2),_c('search-facets',{ref:"searchFacets",attrs:{"facets":_vm.facets || [],"selected-default":_vm.selectedFacets,"position":_vm.position},on:{"opened":() => _vm.$emit('modal-opened', _vm.$refs.searchFacets.hide, 'searchResultsSearchFacets'),"closed":() => _vm.$emit('closed-inner-modal', 'searchResultsSearchFacets'),"modal-opened":(closeModal, type) => _vm.$emit('modal-opened', closeModal, 'searchResults' + type),"closed-inner-modal":(type) => _vm.$emit('closed-inner-modal', 'searchResults' + type),"selected":(_selectedFacets) => _vm._clarifySearch({ facets: _selectedFacets })}},[_c('template',{slot:"title"},[_c('span',[_c('span',{staticClass:"Search-facets--hits bold"},[_vm._v(_vm._s(_vm.hits))]),_vm._v("  "),_c('span',{staticClass:"bold"},[_vm._v(_vm._s(_vm._l10n('results')))]),_vm._v("  "+_vm._s(_vm._l10n('for'))+"  “"),_c('span',{staticClass:"Search-facets--query"},[_vm._v(_vm._s(_vm.query))]),_vm._v("”  "),_c('span',[_vm._v(_vm._s(_vm._l10n('with current facets').trim()))]),_vm._v(". ")])])],2),(_vm.selectedMaterial)?_c('materials-list',{ref:"materialsList",attrs:{"use-cache":false,"default-materials":_vm.selectedMaterialsList,"default-selected":_vm.selectedMaterial,"position":_vm.position},on:{"select-tag":_vm._findByTag,"opened":() => _vm.$emit('modal-opened', _vm.$refs.materialsList.hide, 'searchResultsMaterialsList'),"closed":() => _vm.$emit('closed-inner-modal', 'searchResultsMaterialsList'),"modal-opened":(closeModal, type) => _vm.$emit('modal-opened', closeModal, 'searchResults' + type),"closed-inner-modal":(type) => _vm.$emit('closed-inner-modal', 'searchResults' + type)}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }