<template>
  <modal-fullscreen
    ref="modal"

    colorScheme="easyscreen-menu"
    :class="['easyscreen-menu--inspiration-screen', orientation]"

    @before-open="(event) => $emit('before-open', event)"
    @opened="(event) => $emit('opened', event)"
    @before-close="(event) => $emit('before-close', event)"
    @closed="(event) => $emit('closed', event)"
  >
    <template slot="header-left">
      <easyscreen-circle-button
        icon="/images/es-menu/home_icon.png"
        icon-type="image"

        @click.native="hide"
      />
    </template>
    <template slot="header-center">
      <h1 class="easyscreen-header_1">
        {{ activeTag }}
      </h1>
      <p class="easyscreen-menu--inspiration-screen--more-text">
        <span>{{ readMoreCuted }}</span>
        <span
          v-if="readMoreFull.length > readMoreCuted.length"
          @click="() => $refs.digitalShelf.showInfo()"
          class="easyscreen-menu--inspiration-screen--more-button es-ml-1"
        >{{ _l10n("Read more") }}</span>
      </p>
    </template>
    <template slot="header-right">
      <easyscreen-circle-button
        icon="/images/es-menu/search_icon.png"
        icon-type="image"

        @click.native="hide(() => { $emit('search') })"
      />
    </template>
    <template slot="content">
      <digital-shelf
        ref="digitalShelf"
        :disable-external-features="true"
        :suggested-lists="true"
        :request="request"
        :items="items"
        :tags="tags"

        @tag-selected="() => { _tagSelected() }"
        @find-by-tag="(tag) => $emit('find-by-tag', tag)"
      />
    </template>
    <template slot="footer">
    </template>
  </modal-fullscreen>
</template>

<style lang="less" src="./inspiration.less"></style>

<script>
  import HTMLToText from "../../../../lib/utils/html-to-text.js";
  import cutText from "../../../../lib/utils/cut-text.js";
  import l10n from "@/lib/localization/localization.js";

  import ModalFullscreen from "../../../core/modal/fullscreen.vue";
  import EasyscreenCircleButton from "../../../core/button/circle-button.vue";
  import DigitalShelf from "../../../digital-shelf/digital-shelf.vue";

  import orientationMixin from "../../../core/mixins/orientation.js";

  export default {
    name: "easyscreen-menu-inspiration-screen",
    mixins: [orientationMixin],
    props: {
      title: l10n("Inspiration"),
      request: Object,
      items: Array,
      tags: Array
    },
    data() {
      return {
        activeTag: "",
        readMoreFull: "",
        readMoreCuted: ""
      };
    },
    methods: {
      /**
       * Open the inspiration (digital shelf with skin) screen.
       */
      show() {
        this.$refs.modal.show(() => {
          this._tagSelected();
        });
      },

      /**
       * Close the inspiration screen.
       *
       * @param {Function} callback - Callback, will be called when screen is closed.
       */
      hide(callback) {
        this.$refs.modal.hide(callback);
      },
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Hanlder of selected tag.
       * Changes the active active tag of digital shelf.
       */
      _tagSelected() {
        this.activeTag = this.$refs.digitalShelf.getActiveTag();
        this.readMoreFull = HTMLToText(this.$refs.digitalShelf.getHelpText());
        this.readMoreCuted = cutText(this.readMoreFull, 60);
      }
    },
    components: {
      "modal-fullscreen": ModalFullscreen,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "digital-shelf": DigitalShelf
    }
  };
</script>
