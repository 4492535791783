<template>
  <div class="qr-shelf">
    <div class="qr-shelf--groups">
      <div
        v-for="(elementLayout, elementIndex) in layout"
        :key="elements[elementIndex].tag + elementIndex"
        class="qr-shelf--group"
        :style="{
          height: elementLayout.height
        }"
      >
        <div class="qr-shelf--group-title">
          <span class="qr-shelf--group-title-text">
            {{ elements[elementIndex].tag }}
            <div class="qr-shelf--group-text-underline"></div>
          </span>
        </div>

        <easyscreen-carousel
          class="qr-shelf--group-carousel"
          ref="carousel"
          :optimization="false"
          :swipe-only="true"
          :position-limit-right="(slidesAmount - 1) * -100"
          :layout-columns="elementLayout.columns"
          :layout-rows="elementLayout.rows"
          @before-position-change="(_, followingSlideIndex) => _syncCarouselPositionChange(elementIndex, followingSlideIndex)"
        >
          <qr-shelf-cover
            v-for="material in elements[elementIndex].materials"
            :key="material.id"
            :title="material.title"
            :author="material.author"
            :cover="material.cover"
            :qr-code="material.qrCode"
            @click.native="() => _showMaterialView(elements[elementIndex].materials, material)"
          />
        </easyscreen-carousel>
      </div>
    </div>
    <div class="qr-shelf--navigation">
      <div
        v-for="slideNumber in slidesAmount"
        :key="`navigation-element-${ slideNumber }`"
        :class="[
          'qr-shelf--navigation-element',
          { 'qr-shelf--navigation-element_active': activeSlideIndex === slideNumber - 1 }
        ]"
        @click="() => _syncCarouselPositionChange(-1, slideNumber - 1)"
      ></div>
    </div>

    <materials-list
      v-if="selectedMaterial"
      ref="materialsList"
      :use-cache="false"
      :default-materials="selectedMaterialsList"
      :default-selected="selectedMaterial"
      @closed="_resetSelectedMaterial"
    />
  </div>
</template>

<style src="./qr-shelf.less" lang="less"></style>

<script>
  import { castArray } from "lodash";
  import * as d3 from "d3-timer";
  import fitNumber from "@/lib/utils/fit-number.js";
  import { layouts, maxLayout } from "./qr-shelf-layouts.js";

  import EasyscreenCarousel from "../core/carousel/carousel.vue";
  import MaterialsList from "../materials-list/materials-list.vue";
  import QrShelfCover from "./qr-shelf-cover.vue";

  export default {
    name: "qr-shelf",
    props: {
      elements: Array,
      defaultSelected: {
        type: Number,
        default: 0
      },
      animationDuration: {
        type: Number,
        default: 30000
      }
    },
    computed: {
      rawLayout() {
        return layouts[this.elements.length];
      },
      layout() {
        if (!this.rawLayout) {
          console.warn(
            `QR shelf not support given amount of themes: ${ this.elements.length }.`,
            "The maximum found is used: ", maxLayout
          );
        }

        return this.rawLayout || maxLayout;
      },
      slidesAmount() {
        return this.elements.slice(0, this.layout.length).map((element, elementIndex) => {
          const totalMaterials = element.materials.length;
          const materialsPerSlide = this.layout[elementIndex].rows * this.layout[elementIndex].columns;

          return Math.ceil(totalMaterials / materialsPerSlide);
        }).sort().reverse()[0];
      }
    },
    data() {
      return {
        activeSlideIndex: 0,
        selectedMaterialsList: null,
        selectedMaterial: null
      };
    },
    methods: {
      /**
       * Show the material view list
       * @async
       *
       * @param {Object[]} materialsList - List of materials.
       * @param {String} materialsList[].id - Material faust number.
       * @param {Object} material - Selected material.
       * @param {String} material.id - Selected material faust number.
       */
      async _showMaterialView(materialsList, material) {
        this.selectedMaterialsList = materialsList.map(material => material.id);
        this.selectedMaterial = material.id;

        await this.$nextTick();

        if (this.$refs.materialsList) {
          this.$refs.materialsList.show();
        }
      },
      /**
       * Reset selected material and hides the material view.
       */
      _resetSelectedMaterial() {
        this.selectedMaterialsList = null;
        this.selectedMaterial = null;
      },
      /**
       * Sync the carousels position change.
       *
       * @param {Number} initiatorIndex - Index of carousel which fires position change events.
       * @param {Number} activeSlideIndex - Selected slide index for apply on all carousels.
       */
      _syncCarouselPositionChange(initiatorIndex, activeSlideIndex) {
        this.activeSlideIndex = activeSlideIndex;

        castArray(this.$refs.carousel).filter(Boolean).forEach((carouselInstance, index) => {
          if (initiatorIndex === index) {
            return;
          }

          carouselInstance.selectByIndex(activeSlideIndex, {
            withoutEvents: true,
            withClamp: false
          });
        });
      },
      /**
       * Select next slide for all carousels.
       */
      _nextSlide() {
        castArray(this.$refs.carousel).filter(Boolean).forEach(carouselInstance => {
          carouselInstance.nextSlide({
            withoutEvents: true
          });
        });

        this.activeSlideIndex = fitNumber(this.activeSlideIndex + 1, {
          lt: 0,
          value: this.slidesAmount - 1
        }, {
          gt: this.slidesAmount - 1,
          value: 0
        });
      },
      /**
       * Start auto animation with interval `this.animationDuration`.
       */
      _startAutoAnimation() {
        this._stopAutoAnimation();

        this._autoAnimationInterval = d3.interval(this._nextSlide, this.animationDuration);
      },
      /**
       * Stop auto animation.
       */
      _stopAutoAnimation() {
        if (this._autoAnimationInterval) {
          this._autoAnimationInterval.stop();
          this._autoAnimationInterval = null;
        }
      }
    },
    screenStandby() {
      if (this.animationDuration > 0) {
        this._startAutoAnimation();
      }
    },
    screenActive() {
      this._stopAutoAnimation();
    },
    components: {
      "easyscreen-carousel": EasyscreenCarousel,
      "materials-list": MaterialsList,
      "qr-shelf-cover": QrShelfCover
    }
  };
</script>
