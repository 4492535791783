var render = function render(){var _vm=this,_c=_vm._self._c;return _c('modal-layout',{staticClass:"modal-layout_fullheight"},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm._l10n("Receipt"))+" ")]),_c('template',{slot:"content"},[_c('div',{class:['materials-scanner-sendmail', _vm.orientation]},[(_vm._isPortrait())?_c('svg',{staticClass:"materials-scanner-sendmail--background-image",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"996","height":"527","viewBox":"0 0 996 527"}},[_c('g',{attrs:{"fill":"#373737","fill-rule":"evenodd"}},[_c('path',{attrs:{"d":"M168.045 407.216s-32.951-111.662-41.166-147.786L447.346 55.766l-279.301 351.45zM8.548 149.064L431.796 39.132 113.316 239.32c-.055-.08-104.768-90.257-104.768-90.257zM512.235-15.125C490.929-9.429-58.8 135.973-58.8 135.973S90.615 270.885 89.933 269.722c-.224-.376 65.02 204.842 65.02 204.842S519.954 12.585 525.8 4.985c5.733-9.806.084-22.908-13.564-20.11zM1055.493 457.996c0 3.501-.672 6.813-1.638 9.97l-153.61-165.218 155.248-120.749v275.997zM599.34 491.564L755.002 325.69l58.995 44.59 56.44-44.866 158.216 166.15c-2.467.569-426.846.569-429.313 0zm-26.84-33.568V181.999l155.248 120.749-153.61 165.218c-.966-3.157-1.638-6.469-1.638-9.97zM1038.244 147.5L813.997 319.997 589.749 147.5h448.495zm-17.25-34.5H606.999C568.894 113 538 143.894 538 182v275.996c0 38.104 30.894 68.999 69 68.999h413.994c38.105 0 68.999-30.895 68.999-69V182c0-38.105-30.894-68.999-69-68.999z"}})])]):_vm._e(),_c('authentication-tooltip',{staticClass:"materials-scanner-sendmail--tip",attrs:{"enabled":_vm.emailAutocomplete,"isCprTooltip":true,"tooltipMessage":_vm.tooltipMessage}},[_c('p',{class:{
          'es-mt-2': _vm.emailAutocomplete,
          'es-mb-2': _vm.emailAutocomplete
        }},[_vm._v(_vm._s(_vm._l10n('Enter your email to get a receipt')))]),(_vm.emailAutocomplete)?_c('p',[_vm._v(_vm._s(_vm._l10n('(or scan your loan card to autocomplete)')))]):_vm._e()]),_c('easyscreen-text-input',{ref:"input",style:({ width: '100%' }),attrs:{"cursor":true,"value":_vm.email},on:{"input":(value) => { _vm.email = value }}}),_c('keyboard-email-shortcuts',{staticClass:"es-mt-4",on:{"selected":_vm._handleKeyboard}}),_c('keyboard-fullsize',{staticClass:"es-mb-2",style:({ marginTop: '12px' }),attrs:{"override":{
          enter: {
            label: _vm._l10n('OK')
          }
        }},on:{"key-click":_vm._handleKeyboard}}),_c('h3',{staticClass:"materials-scanner-sendmail--status es-mt-4"},[_c('span',{staticClass:"materials-scanner-sendmail--status-label text-bold"},[_vm._v(" "+_vm._s(_vm.processedMaterialsLabel)+": "+_vm._s(_vm.processedMaterials)+" ")]),_c('span',{staticClass:"materials-scanner-sendmail--status-label text-bold"},[_vm._v(" "+_vm._s(_vm._l10n('Items failed'))+": "+_vm._s(_vm.failedMaterials)+" ")])])],1),(_vm._isPortrait())?_c('div',{staticClass:"materials-scanner-sendmail--status-headline border-top border-disabled"}):_vm._e()]),_c('template',{slot:"footer"},[_c('easyscreen-button-group',[_c('easyscreen-button',{attrs:{"color":"primary","modificator":"fixed-width_small"},nativeOn:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm._l10n("Close without receipt"))+" ")]),_c('easyscreen-button',{attrs:{"color":"primary","modificator":"fixed-width_small","disabled":!_vm.email || !_vm._isEmailValid(_vm.email)},nativeOn:{"click":function($event){return _vm.$emit('send', _vm.email)}}},[_vm._v(" "+_vm._s(_vm._l10n("Send receipt"))+" ")])],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }