<template>
  <div :class="[
    'node-list-carousel-btj-slide',
    { 'node-list-carousel-btj-slide_without-image': !teaserImage },
    orientation
  ]">
    <div class="node-list-carousel-btj-slide--content-wrapper">
      <div
        class="node-list-carousel-btj-slide--content"
      >
        <div
          v-if="teaserImage"
          class="node-list-carousel-btj-slide--image"
          :style="{
            backgroundImage: `url('${ teaserImage }')`
          }"
        ></div>
        <h1 class="node-list-carousel-btj-slide--title">
          {{ title }}
        </h1>
        <div
          v-if="isEvent"
          class="node-list-carousel-btj-slide--meta"
        >
          <div
            class="node-list-carousel-btj-slide--date"
          >
            {{ formattedDate }}
          </div>
          <div
            class="node-list-carousel-btj-slide--location"
          >
            {{ location }}
            <span
              v-if="price"
              class="node-list-carousel-btj-slide--price"
            >
              - {{ price }}
            </span>
          </div>
        </div>
        <div class="node-list-carousel-btj-slide--teaser ck-editor" v-html="teaser"></div>
      </div>
    </div>
  </div>
</template>

<style src="../../core/ck-editor.less" lang="less"></style>
<style src="./node-list-carousel-btj-slide.less" lang="less"></style>

<script>
  import moment from "moment";
  import orientationMixin from "../../core/mixins/orientation.js";

  export default {
    name: "node-list-carousel-btj-slide",
    mixins: [orientationMixin],
    props: {
      type: String,
      date: String,
      dateTo: String,
      location: String,
      price: [String, Number],
      bgImage: String,
      image: String,
      title: String,
      esTeaser: String,
      teaser: String,
      text: String,
      lead: String
    },
    computed: {
      isEvent() {
        return this.type === "event";
      },
      formattedDate() {
        const dateFrom = moment(this.date);
        const dateTo = moment(this.dateTo);
        let date = moment();

        if (date < dateFrom) {
          date = dateFrom;
        } else if (date > dateTo) {
          date = dateTo;
        }

        date.hour(dateFrom.hour());
        date.minute(dateFrom.minute());

        let formattedDate = date.format("dddd D. MMMM");

        if (date.hour() !== 0 || date.minute() !== 0) {
          const fromTime = dateFrom.format("HH:mm");
          const toTime = dateTo.format("HH:mm");

          formattedDate += ` kl. ${ fromTime }`;

          if (fromTime !== toTime) {
            formattedDate += ` - ${ toTime }`;
          }
        }

        return formattedDate;
      },
      teaserImage() {
        return this.image || this.bgImage;
      }
    }
  };
</script>
