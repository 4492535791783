<template>
  <div :class="['keyboard-numeric', 'es-negative-m-2', `keyboard-numeric_design-${ design }`]">
    <div class="keyboard-numeric--row" v-for="(keyboardRow, index) in dataLayout" :key="`row-${ index }`">
      <easyscreen-button
        class="keyboard-numeric--key es-m-2 rounded border-disabled"
        v-for="button in keyboardRow"
        :key="`key-${ button.data || button }`"
        :style="button.style || {}"
        :color="design === 'light' ? 'white' : 'black'"
        @click.native="(event) => { _keyClick(button.data || button, event); }"
      >
        {{ button.label || button }}
      </easyscreen-button>
    </div>
  </div>
</template>

<style lang="less" src="../mixins.less"></style>
<style lang="less" src="./numeric.less"></style>
<script>
  import EasyscreenButton from "../button/button.vue";

  const layouts = {
    default: () => ([
      ["1", "2", "3"],
      ["4", "5", "6"],
      ["7", "8", "9"],
      ["0", { label: "←", data: "backspace", style: { width: "182px" } }]
    ]),
    norvegian: () => ([
      ["1", "2", "3"],
      ["4", "5", "6"],
      ["7", "8", "9"],
      ["0", "N", { label: "←", data: "backspace" }]
    ])
  };

  const KeyboardNumeric = {
    name: "keyboard-numeric",
    props: {
      /*
       * The predefined keyboard layout:
       * - auto - Alias for `default`, in future can be changed to using external client options.
       * - default - The danish layout.
       * - norvegian - The norvegian layout.
       */
      layout: {
        type: String,
        default: "auto",
        validator: _type => ["auto", "default", "norvegian"].includes(_type)
      },
      /* The global reskin. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    data() {
      let activeLayout = this.layout;
      if (activeLayout === "auto") {
        activeLayout = this.$easyscreenConfig.get("enable.norwayReservationKeyboard") ? "norvegian" : "default";
      }

      return {
        dataLayout: layouts[activeLayout]()
      };
    },
    methods: {
      /**
       * The handler of native of click event for screen keyboard.
       * @fires keyboard-numeric#key-click
       *
       * @param {String} key - The key symbol.
       * @param {Event} event - The browser native click event.
       */
      _keyClick(key, event) {
        /**
         * The key clicked event.
         *
         * @event keyboard-numeric#key-click
         * @type {String + Event} - The key symbol + native click event.
         */
        this.$emit("key-click", key, event);
      }
    },
    components: {
      "easyscreen-button": EasyscreenButton
    },
    /* Static methods. */
    /**
     * Remove the last symbol at string (helper for `removeAt`).
     *
     * @param {String} string - The string where symbol should be removed.
     *
     * @returns {String} The string with removed symbol.
     */
    removeLastSymbol(string) {
      string = string || "";

      return string.substring(0, string.length - 1);
    },

    /**
     * Add the symbol at the end of string (helper for `addAt`).
     *
     * @param {String} string - String to update.
     * @param {String} symbol - The symbol or substring to insertion.
     *
     * @returns {String} Updated string.
     */
    appendSymbol(string, symbol) {
      return (string || "") + (symbol || "");
    },

    /**
     * The default handler of keyboard events.
     *
     * @param {Object} options - The handler options.
     * @param {String} options.value - The name of value property for direct modification.
     * @param {Function} options.value.get - Method for get the input value.
     * @param {Function<String>} options.value.set - Method for set the input value.
     * @param {String} key - The key symbol or special name.
     */
    defaultKeyboardHandler({ value }, key) {
      let currentValue = value.get() || "";
      let newValue = currentValue;
      if (key === "backspace") {
        newValue = KeyboardNumeric.removeLastSymbol(currentValue);
      } else {
        newValue = KeyboardNumeric.appendSymbol(currentValue, key);
      }

      value.set(newValue);
    }
  };

  export default KeyboardNumeric;
</script>
