import * as querystring from "querystring";

/**
 * Reorder material holdings according to screen branch and department.
 *
 * @param {Object[]} holdings - The list of material holdings.
 *
 * @returns {Object[]} - Reoredered list of material holdings.
 */
export default function reorderMaterialHoldings(holdings) {
  const urlOptions = querystring.parse(window.location.href.split("#")[1]);
  const branchId = urlOptions.branchId;
  const departmentId = (urlOptions.departmentId || "").split(",").filter(Boolean);

  if (branchId || departmentId.length !== 0) {
    let matches = {
      branchDepartment: [],
      branchOnly: [],
      other: []
    };

    holdings.forEach(holding => {

      const sameBranch = !branchId || holding.branchId === branchId;
      const sameDepartment = departmentId.length === 0 || departmentId.includes(holding.departmentId);

      let container = "other";
      if (sameBranch && sameDepartment) {
        container = "branchDepartment";
      } else if (sameBranch) {
        container = "branchOnly";
      }

      matches[container].push(holding);
    });

    matches.branchDepartment.sort((a, b) => b.available - a.available);
    matches.branchOnly.sort((a, b) => b.available - a.available);

    holdings = matches.branchDepartment.concat(matches.branchOnly).concat(matches.other);
  }

  return holdings;
}
