<template>
  <div class="easyscreen-tooltip">
    <slot></slot>

    <easyscreen-tooltip-modal
      v-if="!container && open"
    >
      <div class="easyscreen-tooltip--content" ref="content">
        <slot name="tooltip"></slot>
      </div>
    </easyscreen-tooltip-modal>
  </div>
</template>

<style src="./tooltip.less" lang="less"></style>

<script>
  import Vue from "vue";
  import EasyscreenTooltipModal from "./tooltip-modal.vue";
  import mutationObservationMixin from "../mixins/mutation-observation.js";

  export default {
    name: "easyscreen-tooltip",
    mixins: [mutationObservationMixin],
    props: {
      /* The custom class of the tooltip modal. */
      tooltipClass: [String, Array, Object],
      /*
       * Container where the tooltip will be appended (e.g. 'body').
       * Set it to false to append popover on target parent node.
       * Default: document.body
       */
      container: {
        type: [Boolean, String, HTMLElement],
        default: () => document.body
      },
      /* Boolean that shows or hide the tooltip. */
      open: {
        type: Boolean,
        default: false
      },
      /* The placemet of tooltip, see available values for ./tooltip-modal.vue */
      placement: String
    },
    data() {
      return {
        targetNode: null,
        tooltipInstance: null,
        watchRefs: "content"
      };
    },
    watch: {
      /*
       * Watch the state of "open" property for mount and unmount
       * the tooltip modal in case when the tooltip has external container.
       */
      open(_open) {
        if (!this.container) {
          return;
        }

        if (_open) {
          this._mountTooltip();
        } else {
          this._unmountTooltip();
        }
      }
    },
    methods: {
      /**
       * Initialize and mount the tooltip modal.
       */
      _mountTooltip() {
        if (!this.container) {
          return;
        }

        if (!this.targetNode) {
          throw new Error("Target must be specified for tooltip.");
        }

        this._unmountTooltip();
        this.tooltipInstance = new (Vue.extend(EasyscreenTooltipModal))({
          propsData: {
            targetNode: this.targetNode,
            tooltipClass: this.tooltipClass,
            placement: this.placement
          }
        });

        this.tooltipInstance.$slots.default = [this.$slots.tooltip];

        let elementForReplace = document.createElement("div");
        this.container.appendChild(elementForReplace);

        this.tooltipInstance.$mount(elementForReplace);
      },
      /**
       * Unmount and destroy the initialize tooltip modal.
       */
      _unmountTooltip() {
        if (!this.tooltipInstance) {
          return;
        }

        this.tooltipInstance.$destroy();
        if (this.tooltipInstance.$el && this.tooltipInstance.$el.parentNode) {
          this.tooltipInstance.$el.parentNode.removeChild(this.tooltipInstance.$el);
        }
        this.tooltipInstance = null;
      },
      /**
       * Update slots for mounted tooltip modal.
       */
      _updateTooltipSlot() {
        if (!this.tooltipInstance) {
          return;
        }

        this.tooltipInstance.$slots.default = [this.$slots.tooltip];
        this.tooltipInstance.$forceUpdate();
      }
    },
    /*
     * Update the mounted tooltip modal properties and slots on tooltip update.
     */
    updated() {
      if (this.tooltipInstance) {
        this.tooltipInstance.$slots.default = [this.$slots.tooltip];
        this.tooltipInstance.$props.tooltipClass = this.tooltipClass;
        this.tooltipInstance.$props.placement = this.placement;
        this.tooltipInstance.$forceUpdate();
      }
    },
    mounted() {
      this.targetNode = this.$el.querySelector(".tooltip-target");
      if (this.open) {
        this._mountTooltip();
      }

      /*
       * The mutation observer events has coming from mutationObservationMixin using `watchRefs`
       * For update the content for mounted tooltip modal in runtime on parent changes.
       */
      this.$on("mutation:content", this._updateTooltipSlot);
    },
    beforeDestroy() {
      this._unmountTooltip();
      this.$off("mutation:content", this._updateTooltipSlot);
    },
    components: {
      "easyscreen-tooltip-modal": EasyscreenTooltipModal
    }
  };
</script>
