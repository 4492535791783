<template>
  <div class="easyscreen-form-group">
    <label class="easyscreen-form-group--title">{{ title || "" }}</label>
    <div class="easyscreen-form-group--content">
      <slot></slot>
    </div>
  </div>
</template>

<style lang="less" src="./form-group.less"></style>
<script>
  export default {
    name: "easyscreen-form-group",
    props: {
      /* The title of form group. */
      title: String
    }
  };
</script>
