export default {
  methods: {
    /**
     * Helper for reemit native event with the working `preventDefault` method.
     *
     * @param {String} eventName - The name of the re-emited event.
     * @param {Event} nativeEvent - The browser native event any other event with `preventDefault` method (bubbling state).
     * @param {Function} callback - The callback of the emition, `(isPrevented) => {}`.
     */
    _reEmit(eventName, nativeEvent, callback) {
      let prevented = false;
      const _preventDefault = nativeEvent.preventDefault;
      nativeEvent.preventDefault = function() {
        prevented = true;
        nativeEvent.preventDefault = _preventDefault;
        nativeEvent.preventDefault();
      };

      this.$emit(eventName, nativeEvent);
      callback && callback(prevented);
    }
  }
};
