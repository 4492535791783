<template>
  <div
    :class="[
      'patron-profile-material-info',
      'border-bottom',
      'border-disabled',
      'border-last-0',
      'es-pb-2',
      'es-mb-2',
      orientation
    ]"
  >
    <h2 class="patron-profile-material-info--title mb-3">{{ title }}</h2>
    <h4
      class="patron-profile-material-info--sub-title mb-3"
      v-if="subTitle"
    >{{ subTitle }}</h4>
    <div
      :class="[
        'patron-profile-material-info--attributes',
        'easyscreen-grid',
        {
          'easyscreen-grid_flow-cols': _isLandscape(),
          'easyscreen-grid_two-rows': _isLandscape(),
          'easyscreen-grid_three-cols': _isLandscape(),
          'easyscreen-grid_gap-2': _isLandscape(),
          'easyscreen-grid_gap-1': _isPortrait()
        }
      ]"
      :style="attributesStyle"
    >
      <div
        :class="[
          'patron-profile-material-info--attribute',
          'easyscreen-grid',
          'easyscreen-grid_two-cols',
          'easyscreen-grid_gap-2'
        ]"
        v-for="(attribute, index) in attributes"
        :key="`${ attribute.label }-${ index }`"
      >
        <div :class="[
          'patron-profile-material-info--attribute-title text-bold',
          attribute.color || ''
        ]">{{ attribute.label }}</div>
        <div :class="[
          'patron-profile-material-info--attribute-value',
          attribute.color || 'text-disabled'
        ]">{{ attribute.value }}</div>
      </div>
    </div>
    <div v-if="checkbox" class="patron-profile-material-info--checkbox">
      <easyscreen-checkbox
        v-if="!hasSlot('checkbox')"
        :checked="checked"
        @input="(_checked) => { $emit('input', _checked) }"
      />
      <slot name="checkbox"></slot>
    </div>
  </div>
</template>

<style lang="less" src="../../../core/mixins.less"></style>
<style lang="less" src="../../../core/form/grid.less"></style>
<style lang="less" src="./material-info.less"></style>
<script>
  import EasyscreenCheckbox from "../../../core/input/checkbox.vue";

  import orientationMixin from "../../../core/mixins/orientation.js";
  import hasSlot from "../../../core/mixins/has-slot.js";

  export default {
    name: "patron-profile-debts",
    mixins: [orientationMixin, hasSlot],
    props: {
      title: String,
      subTitle: String,
      attributes: Array,
      attributesStyle: [Object, String],
      checkbox: {
        type: Boolean,
        default: true
      },
      checked: Boolean
    },
    components: {
      "easyscreen-checkbox": EasyscreenCheckbox
    }
  };
</script>
