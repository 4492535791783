<template>
  <div class="qr-shelf-cover">
    <div
      v-if="isDefaultCover"
      class="qr-shelf-cover--default-cover"
    >
      <p class="qr-shelf-cover--default-cover-title">{{ title }}</p>
      <p class="qr-shelf-cover--default-cover-author">{{ author }}</p>
    </div>
    <img
      v-else
      ref="image"
      draggable="false"
      class="qr-shelf-cover--image"
      :src="$easyscreenRequest.lmsConnector.wrapCover(cover)"
    >

    <div
      v-if="qrCode"
      class="qr-shelf-cover--qr-code"
      :style="{
        backgroundImage: `url('${ $easyscreenRequest.lmsConnector.wrapCover(qrCode) }')`
      }"
    ></div>
  </div>
</template>

<style lang="less" src="./qr-shelf-cover.less" />
<script>
  import checkImageStatus from "../../lib/utils/check-image-status.js";

  export default {
    name: "qr-shelf-cover",
    props: {
      /* The title of material for fallback cover with default image. */
      title: String,
      /* The author of material for fallback cover with default image. */
      author: String,
      /* Url of the cover, from material detail (wihtout the host) */
      cover: {
        type: String,
        default: "/images/defaultCover.jpg"
      },
      /* Url of material qr-code. */
      qrCode: String
    },
    computed: {
      isDefaultCover() {
        return this.loadingFailed || (this.cover || "").includes("defaultCover.jpg");
      }
    },
    data() {
      return {
        loadingFailed: false
      };
    },
    methods: {
      /**
       * Check the cover status and applies default and set the failing status if needed.
       */
      _checkImageStatus() {
        if (this.$refs.image) {
          let imageStatus = checkImageStatus(this.$refs.image);

          if (imageStatus === 0) {
            this.$refs.image.onload = this._checkImageStatus;
            this.$refs.image.onerror = this._checkImageStatus;
          } else if (imageStatus === -1) {
            this.loadingFailed = true;
          }
        }
      }
    },
    mounted: function() {
      this._checkImageStatus();
    }
  };
</script>
