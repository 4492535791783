<template>
  <div class="node-list-carousel-slagbib-booking-slide">
    <div class="node-list-carousel-slagbib-booking-slide--meta-wrapper">
      <div class="node-list-carousel-slagbib-booking-slide--date">
        <div
          v-if="isEvent"
          class="node-list-carousel-slagbib-booking-slide--day-of-week"
        >
          {{ dayOfWeek }}
        </div>
        <div
          v-if="isEvent"
          class="node-list-carousel-slagbib-booking-slide--day-of-month"
        >
          {{ dayOfMonth }}
        </div>
        <div
          v-if="isEvent"
          class="node-list-carousel-slagbib-booking-slide--month"
        >
          {{ month }}
        </div>
      </div>
      <div class="node-list-carousel-slagbib-booking-slide--meta">
        <div
          v-if="isEvent && location"
          class="node-list-carousel-slagbib-booking-slide--location"
        >
          <i class="esi esi-map-marker"></i>
          {{ location }}
        </div>
        <div
          v-if="isEvent"
          class="node-list-carousel-slagbib-booking-slide--time"
        >
          <i class="esi esi-clock"></i>
          {{ time }}
        </div>
        <div
          v-if="isEvent && price"
          class="node-list-carousel-slagbib-booking-slide--price"
        >
          <i class="esi esi-label"></i>
          {{ price }}
        </div>
      </div>
    </div>

    <div class="node-list-carousel-slagbib-booking-slide--content">
      <h1 class="node-list-carousel-slagbib-booking-slide--title">
        {{ title }}
      </h1>
      <div class="node-list-carousel-slagbib-booking-slide--teaser ck-editor" v-html="teaser"></div>
    </div>
  </div>
</template>

<style src="../../core/ck-editor.less" lang="less"></style>
<style src="../../core/icons.less" lang="less"></style>
<style src="./node-list-carousel-slagbib-booking-slide.less" lang="less"></style>

<script>
  import moment from "moment";

  const timeFormat = "HH.mm";

  export default {
    name: "node-list-carousel-slagbib-booking-slide",
    props: {
      type: String,
      date: String,
      dateTo: String,
      location: String,
      price: [String, Number],
      bgImage: String,
      image: String,
      title: String,
      esTeaser: String,
      teaser: String,
      text: String,
      lead: String
    },
    computed: {
      isEvent() {
        return this.type === "event";
      },
      dayOfWeek() {
        return moment(this.formattedDate).format("dddd");
      },
      dayOfMonth() {
        return moment(this.formattedDate).format("D");
      },
      month() {
        return moment(this.formattedDate).format("MMMM");
      },
      time() {
        const timeFrom = moment(this.formattedDate).format(timeFormat);
        const timeTo = moment(this.dateTo).format(timeFormat);

        let time = timeFrom;
        if (timeFrom !== timeTo) {
          time += ` - ${ timeTo }`;
        }

        return time;
      },
      formattedDate: {
        get() {
          const dateFrom = moment(this.date);
          const dateTo = moment(this.dateTo);
          let date = moment();

          if (date < dateFrom) {
            date = dateFrom;
          } else if (date > dateTo) {
            date = dateTo;
          }

          date.hour(dateFrom.hour());
          date.minute(dateFrom.minute());

          return date.format();
        }
      }
    }
  };
</script>
