<template>
  <div
    :class="[
      'node-list-carousel-color-image-slide',
      formattedColor ? `node-list-carousel-color-image-slide_${ formattedColor }` : null,
      orientation
    ]"
  >
    <div class="node-list-carousel-color-image-slide--image-wrapper">
      <div
        class="node-list-carousel-color-image-slide--image"
        :style="{
          backgroundImage: backgroundImage ? `url('${ backgroundImage }')` : null
        }"
      ></div>
    </div>
    <div class="node-list-carousel-color-image-slide--content">
      <h1
        class="node-list-carousel-color-image-slide--title"
        :style="{
          fontSize: this.title.length <= 20 ? 116 : null
        }"
      >
        <img
          v-if="logo"
          class="node-list-carousel-color-image-slide--logo node-list-carousel-color-image-slide--logo_in-text"
          :src="logo"
        />
        {{ title }}
      </h1>
      <div class="node-list-carousel-color-image-slide--teaser ck-editor" v-html="formattedTeaser"></div>
      <div
        v-if="isEvent"
        class="node-list-carousel-color-image-slide--meta"
      >
        <div
          v-if="date"
          class="node-list-carousel-color-image-slide--date"
        >
          {{ formattedDate }}
        </div>
        <div
          v-if="location"
          class="node-list-carousel-color-image-slide--location"
        >
          {{ location }} 
        </div>
        <div
          v-if="price"
          class="node-list-carousel-color-image-slide--price"
        >
          {{ _l10n("Price:") }} {{ price }}
        </div>
      </div>
      <div
        v-if="link"
        class="node-list-carousel-color-image-slide--link"
      >
        {{ link }}
      </div>
      <img
        v-if="logo"
        class="node-list-carousel-color-image-slide--logo"
        :src="logo"
      />
    </div>
  </div>
</template>

<style src="../../core/ck-editor.less" lang="less"></style>
<style src="./node-list-carousel-color-image-slide.less" lang="less"></style>

<script>
  import moment from "moment";
  import { paramCase } from "param-case";
  import l10n from "@/lib/localization/localization.js";

  import orientationMixin from "../../core/mixins/orientation.js";

  export default {
    name: "node-list-carousel-color-image-slide",
    mixins: [orientationMixin],
    props: {
      type: String,
      date: String,
      dateTo: String,
      location: String,
      price: [String, Number],
      bgImage: String,
      image: String,
      title: String,
      esTeaser: String,
      teaser: String,
      text: String,
      lead: String,
      color: String
    },
    computed: {
      link() {
        if (this.$easyscreenSkin.isMiddelfart) {
          return l10n("See more on www.middelfartbibliotek.dk");
        }

        return null;
      },
      logo() {
        if (this.$easyscreenSkin.isMiddelfart) {
          return "/skins/middelfart/color-image-logo.png";
        }

        return null;
      },
      isEvent() {
        return this.type === "event";
      },
      backgroundImage: {
        get() {
          return this.bgImage || this.image;
        }
      },
      formattedDate() {
        const dateFrom = moment(this.date);
        const dateTo = moment(this.dateTo);
        let date = moment();

        if (date < dateFrom) {
          date = dateFrom;
        } else if (date > dateTo) {
          date = dateTo;
        }

        date.hour(dateFrom.hour());
        date.minute(dateFrom.minute());

        let formattedDate = date.format("dddd D. MMMM");

        if (date.hour() !== 0 || date.minute() !== 0) {
          formattedDate += " " + l10n("Kl.") + " " + date.format("HH:mm");
        }

        return formattedDate;
      },
      formattedTeaser() {
        return (this.teaser || "").replace(/\n/, "<br/>");
      },
      formattedColor() {
        return paramCase(this.color || "");
      }
    },
    methods: {
      /* Proxy for localization function. */
      _l10n: l10n
    }
  };
</script>
