export default {
  methods: {
    /**
     * Get the compoenent html as a text.
     *
     * @returns {String} The component text.
     */
    toText() {
      return this.$el.innerHTML
        .replace(/[ \t]{2,}/g, "")
        .replace(/(<br>|<\/h1>|<\/div>|<\/p>|\|)/g, "\n")
        .replace(/(\n\s*){2,}<!-- no-space-line -->/g, "")
        .replace(/<[^]+?>/g, "")
        .replace(/&nbsp;/g, " ")
        .replace(/-{3,}/g, "--------------------------------")
        .replace(/_{3,}/g, "________________________________\n")
        .split("\n")
        .map(function(str) {
          return str.trim();
        })
        .join("\n")
        .replace(/(\n\s*){2,}\n/g, "\n\n");
    },
    /**
     * Get the compoenent html.
     *
     * @returns {String} The component thml.
     */
    toHTML() {
      return this.$el.outerHTML;
    }
  }
};
