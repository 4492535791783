<template>
  <div
    :class="[
      'library-events-list',
      `library-events-list_design-${ design }`,
      {
        'library-events-list_with-title': title
      },
      layoutClass,
      orientation
    ]"
    :style="tileColor ? `--library-events-list--primary-color: ${ tileColor }` : null"
  >
    <btj-header
      v-if="$easyscreenSkin.isBtj"
      class="library-events-list--btj-header"
      size="small"
    />

    <h2
      v-if="title"
      class="library-events-list--title"
    >
      <!-- span element are required for custom styles of for-game layout. -->
      <span>
        {{ title }}
      </span>
    </h2>
    <scrollable
      ref="scrollable"
      class="library-events-list--elements"
      max-height="100%"
    >
      <h2 v-if="items.length === 0" class="library-events-list--no-data">{{ $l10n("No data for show") }}</h2>
      <div
        v-for="(element, elementIndex) in items"
        :key="element.nid"
        :class="[
          'library-events-list--element',
          { 'library-events-list--element_without-image': !element.image }
        ]"
        @click="() => _expandElement(element, elementIndex)"
      >
        <easyscreen-tear-off-calendar
          class="library-events-list--element-calendar"
          :date="element.date"
          :with-time="isCinemaLayout"
        />
        <div
          v-if="element.image"
          class="library-events-list--element-image"
          :style="{
            backgroundImage: `url('${ element.image }')`
          }"
        ></div>
        <h2 class="library-events-list--element-title">
          <span
            v-if="isGameLayout"
            class="library-events-list--element-date"
          >
            {{ _formatDate(element.date) }}
          </span>
          <!-- span element are required for custom styles of btj skin. -->
          <span>{{ element.title }}</span>
        </h2>
        <div class="library-events-list--element-teaser">
          {{ element.teaser }}
        </div>
      </div>
    </scrollable>

    <modal-blank
      ref="modal"
      :click-to-close="true"
      :position="modalPosition"
      @before-open="$emit('modal-opened', $refs.modal.hide, 'libraryEventsListInfo')"
      @closed="$emit('closed-inner-modal', 'libraryEventsListInfo')"
    >
      <div>
        <node-list-info
          v-if="activeElement"
          :design="design"

          :type="activeElement.type"
          :date="activeElement.date"
          :date-to="activeElement.dateTo"
          :location="activeElement.location"
          :price="activeElement.price"
          :image="activeElement.image"
          :title="activeElement.title"
          :es-teaser="activeElement.esTeaser"
          :teaser="activeElement.teaser"
          :text="activeElement.text"
          :lead="activeElement.lead"
          :qrCode="activeElement.qrCode"
        >
          <easyscreen-circle-button
            v-if="withModalControls"
            class="library-events-list--close-button library-events-list--close-button_top-right"
            icon="esi esi-times"
            :color="design === 'light' ? 'white' : undefined"
            :size="design === 'light' ? 'medium' : undefined"

            @click.native="$refs.modal.hide"
          />

          <easyscreen-circle-button
            v-if="withModalControls"
            class="library-events-list--close-button library-events-list--close-button_bottom-right"
            icon="esi esi-times"
            :color="design === 'light' ? 'white' : undefined"
            :size="design === 'light' ? 'medium' : undefined"

            @click.native="$refs.modal.hide"
          />
        </node-list-info>
      </div>
    </modal-blank>
  </div>
</template>

<style src="./library-events-list.less" lang="less"></style>

<script>
  import { castArray } from "lodash";
  import * as d3 from "d3-timer";
  import moment from "moment";
  import orientationMixin from "../core/mixins/orientation.js";

  import ModalBlank from "../core/modal/blank.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import Scrollable from "../core/scrollable/scrollable.vue";
  import EasyscreenTearOffCalendar from "../core/tear-off-calendar/tear-off-calendar.vue";
  import BtjHeader from "../core/btj-header/btj-header.vue";
  import NodeListInfo from "../node-list/node-list-info.vue";

  export default {
    name: "library-events-list",
    mixins: [orientationMixin],
    props: {
      /* The primary color of some elements. */
      tileColor: String,
      /* Widget title. */
      title: String,
      /* The list of events. */
      items: Array,
      /* The layout of view. */
      layout: {
        type: [String, Array],
        default: "six-elements",
        validator: _layout => castArray(_layout).reduce((options, option) => {
          return options.concat(option.split(" "));
        }, []).filter(Boolean).every(option => {
          return [
            "six-elements",
            "eight-elements",
            "ten-elements",
            "cinema",
            "for-game",
            "welcome-screen-modifer",
            "welcome-screen-light-modifer",
            "events",
            "news"
          ].includes(option);
        })
      },
      /* The duration of auto scroll till end of list. */
      animationDuration: {
        type: Number,
        default: 5
      },
      /* Flag for enable the autoamimation on standby. */
      autoAnimation: {
        type: Boolean,
        default: false
      },
      modalPosition: String,
      /* The global reskin for materials list. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      },
      withModalControls: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      layoutClass() {
        return castArray(this.layout).filter(Boolean).map(_layout => `library-events-list_${ _layout }`);
      },
      isCinemaLayout() {
        return this.layout.includes("cinema");
      },
      isGameLayout() {
        return this.layout.includes("for-game");
      }
    },
    data() {
      return {
        activeElement: null
      };
    },
    methods: {
      /**
       * Scroll the list of events to top with 250ms transition.
       * The promise, will be resolved when transition will be finished.
       * Keep in mind that callback might be never called in case if the _scrollTop will be called untill
       * the previous call is not finished yet or component are destroyed.
       * @async
       */
      async _scrollTop() {
        return this.$refs.scrollable.setScroll(0, {
          behavior: "smooth",
          duration: 250
        });
      },
      /**
       * Start auto scroll loop for events list.
       * The auto scroll might be stoped\started by standby events when the `this.autoAnimation` property are `true`.
       */
      async _startAutoScroll() {
        if (!this.$refs.scrollable || this.$refs.scrollable.scrollTransitionInProgress()) {
          return;
        }

        this._stopAutoScroll();
        this._scrollTopTimeout = null;

        const availableScroll = this.$refs.scrollable.getAvailableScroll();
        if (availableScroll <= 0) {
          return;
        }

        const canceled = await this.$refs.scrollable.setScroll(availableScroll, {
          behavior: "smooth",
          easing: "linear",
          duration: this.animationDuration * 1000
        });

        if (canceled) {
          return;
        }

        this._scrollTopTimeout = d3.timeout(async () => {
          const canceled = await this.$refs.scrollable.setScroll(0, {
            behavior: "smooth",
            easing: "linear",
            duration: this.animationDuration * 1000
          });

          if (canceled) {
            return;
          }

          this._scrollTopTimeout = d3.timeout(this._startAutoScroll, 5000);
        }, 5000);
      },
      /**
       * Stop auto scroll loop for events list.
       * The auto scroll might be stoped\started by standby events when the `this.autoAnimation` property are `true`.
       */
      _stopAutoScroll() {
        if (this._scrollTopTimeout) {
          this._scrollTopTimeout.stop();
          this._scrollTopTimeout.stopped = null;
        }

        if (this.$refs.scrollable) {
          this.$refs.scrollable.stopScrollTransition();
        }
      },
      _expandElement(element, index) {
        if (this.layout.includes("for-game")) {
          return;
        }

        this.$easyscreenStatistic.openNodeList({
          nid: this.items[index].nid,
          title: this.items[index].title
        });

        if (this.layout.includes("welcome-screen-modifer") || this.layout.includes("welcome-screen-light-modifer")) {
          this.$emit("expand-element", this.items[index]);
        } else {
          this.activeElement = element;
          this.$refs.modal.show();
        }
      },
      _formatDate(date) {
        return moment(date).format("DD.MM.YY [KL.] HH.mm");
      }
    },
    screenStandby() {
      if (this.autoAnimation) {
        this._startAutoScroll();
      }
    },
    screenActive() {
      this._stopAutoScroll();
    },
    components: {
      "modal-blank": ModalBlank,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "scrollable": Scrollable,
      "easyscreen-tear-off-calendar": EasyscreenTearOffCalendar,
      "btj-header": BtjHeader,
      "node-list-info": NodeListInfo
    }
  };
</script>
