<template>
  <div class="material-promotion-scroller">
    <h1
      v-if="title"
      class="material-promotion-scroller--title"
    >
      {{ title }}
    </h1>
    <scrollable
      ref="scrollable"
      class="material-promotion-scroller--scrollable"
      max-height="100%"
      smooth-edge-color="var(--info-fill-color)"
    >
      <div ref="elements">
        <div
          v-for="element in slicedModifiedElements"
          :key="`element-${ element.id }`"
          class="material-promotion-scroller--element"
          :data-id="element.id"
        >
          <div
            class="material-promotion-scroller--element-image-wrapper"
          >
            <img
              class="material-promotion-scroller--element-image"
              draggable="false"
              :src="$easyscreenRequest.lmsConnector.wrapCover(element.cover)"
            >
          </div>
          <div class="material-promotion-scroller--element-content">
            <h2 class="material-promotion-scroller--element-title">
              {{ element.title }}
            </h2>
            <div class="material-promotion-scroller--element-meta">
              <span
                v-if="element.author"
              >
                {{ element.author }}
              </span>
              <span
                v-if="element.year"
              >
                ({{ element.year }})
              </span>
            </div>
            <div
              v-if="element.description && withMetaInformation !== true"
              class="material-promotion-scroller--element-teaser"
            >
              {{ _cutText(element.description, maxTeaserLength) }}
            </div>
            <div
              v-if="withMetaInformation === true"
              class="material-promotion-scroller--element-teaser"
            >
              <meta-information-table
                class="material-promotion-scroller--element-meta"
                :standalone="true"
                :metaPair="element.metaPair"
              />
            </div>
          </div>
        </div>
      </div>
    </scrollable>
  </div>
</template>

<style src="./material-promotion-scroller.less" lang="less"></style>

<script>
  import * as d3 from "d3-timer";
  import cutText from "@/lib/utils/cut-text.js";
  import asyncTimeout from "@/lib/utils/async-timeout.js";
  import orientationMixin from "../core/mixins/orientation.js";
  import getStyle from "@/lib/utils/get-style.js";
  import materialPromotionMeta from "./material-promotion-meta.mixin.js";

  import Scrollable from "../core/scrollable/scrollable.vue";
  import MetaInformationTable from "../materials-list/meta-information-table.vue";

  export default {
    name: "material-promotion-scroller",
    mixins: [
      orientationMixin,
      materialPromotionMeta
    ],
    props: {
      /* The title of promotion. */
      title: String,
      /**
       * Promotion element
       *
       * @typedef {Object} PromotionElement
       *
       * @property {Number} id - Element id (fasut number).
       * @property {Number} [cover="/images/defaultCover.jpg"] - Cover of the material.
       * @property {Number} title - Material title.
       * @property {Number} [author] - Material author.
       * @property {Number} [year] - Year of the publication.
       * @property {Number} [description] - Materail description.
       */
      /* The list of promotion elements (PromotionElement[]). */
      elements: Array,
      /* The duraion in ms of how long the every element will be shown at the screen. */
      animationSpeed: {
        type: Number,
        default: 5000
      },
      /* The timeout between the tic of animation (scroll). */
      animationInterval: {
        type: Number,
        default: 16
      },
      /*
       * The timeout between checking of element which are scrolled out of screen to place
       * it to end of the list (for the smooth infinite scroll).
       */
      reorderInterval: {
        type: Number,
        default: 1000
      },
      /*
       * The maximum description\teaser length. Will be cutted by the closest punctuation
       * mark or the space under `Number` characters.
       */
      maxTeaserLength: {
        type: Number,
        default: 300
      }
    },
    watch: {
      /**
       * Update the list of elements at the scrollable area and reset the scroll.
       *
       * @param {PromotionElement[]} - The list of promotion elements.
       */
      elements (elements) {
        this.modifiedElements = elements;
        if (this.$refs.scrollable) {
          this.$refs.scrollable.setScroll(0);
        }

        if (this._getHoldings) {
          this._getHoldings();
        }
      }
    },
    data() {
      return {
        modifiedElements: this.elements
      };
    },
    computed: {
      slicedModifiedElements() {
        return this.modifiedElements.slice(0, 8);
      }
    },
    methods: {
      /* Proxy for `cutText` method. */
      _cutText: cutText,
      /**
       * Move the elements to end of list in case when they our of screen by the top edge.
       */
      _reorderElements() {
        if (!this.$refs.elements) {
          return;
        }

        let reorder = [];
        let scrollOffset = 0;
        [].forEach.call(this.$refs.elements.children, (elementNode, elementIndex) => {
          const boundaries = elementNode.getBoundingClientRect();
          if (boundaries.top + boundaries.height < 0) {
            reorder.push(this.modifiedElements[elementIndex]);
            scrollOffset += this._applyParentsScale(elementNode, { x: 0, y: boundaries.height }).y;
          }
        });

        if (reorder.length === 0) {
          return;
        }

        this.modifiedElements = this.modifiedElements.filter(elementData => {
          return !reorder.includes(elementData);
        }).concat(reorder);

        if (this.$refs.scrollable) {
          this.$refs.scrollable.setScroll(this.$refs.scrollable.getScroll() - scrollOffset);
        }
      },
      /**
       * Start the scroll animation and checking for reordering.
       */
      _startAutoAnimation() {
        this._stopAutoAnimation();
        if (!this.$refs.scrollable) {
          return;
        }

        const viewportHeight = getStyle(this.$refs.scrollable.$el, "height");
        const averageElementHeight = [].slice.call(this.$refs.elements.children).reduce((totalHeight, child) => {
          return totalHeight + getStyle(child, "height");
        }, 0) / this.$refs.elements.children.length;

        if (viewportHeight > 0 && averageElementHeight > 0) {
          const scrollPerTic = Math.round((viewportHeight - averageElementHeight) / (this.animationSpeed / this.animationInterval));
          this._autoAnimationInterval = d3.interval(() => {
            this.$refs.scrollable.setScroll(this.$refs.scrollable.getScroll() + scrollPerTic);
          }, this.animationInterval);

          this._reorderInterval = d3.interval(() => {
            this._reorderElements();
          }, this.reorderInterval);
        }
      },
      /**
       * Stop the scroll animation and checking for reordering.
       */
      _stopAutoAnimation() {
        if (this._autoAnimationInterval) {
          this._autoAnimationInterval.stop();
          this._autoAnimationInterval = null;
        }

        if (this._reorderInterval) {
          this._reorderInterval.stop();
          this._reorderInterval = null;
        }
      }
    },
    async mounted() {
      /* Wait for apply css on first initialization. */
      await asyncTimeout(400);
      this._startAutoAnimation();
    },
    beforeDestroy() {
      this._stopAutoAnimation();
    },
    components: {
      "scrollable": Scrollable,
      "meta-information-table": MetaInformationTable
    }
  };
</script>
