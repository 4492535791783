<template>
  <div class="material-promotion">
    <btj-header
      class="material-promotion--btj-header"
      v-if="$easyscreenSkin.isBtj"
    />
    <h2
      v-else
      class="material-promotion--header"
    >
      {{ libraryName }}
    </h2>
    <div class="material-promotion--content">
      <material-promotion-single-view-carousel
        v-if="isSingleViewCarouselLayout"
        :title="title"
        :elements="elements"
        :animation-speed="animationSpeed"
        :withMetaInformation="withMetaInformation"
      />
      <material-promotion-multimple-view-carousel
        v-else-if="isMultipleViewCarouselLayout"
        :title="title"
        :elements="elements"
        :animation-speed="animationSpeed"
        :withMetaInformation="withMetaInformation"
      />
      <material-promotion-scroller
        v-else-if="isScrollerLayout"
        :title="title"
        :elements="elements"
        :animation-speed="animationSpeed"
        :withMetaInformation="withMetaInformation"
      />
    </div>
  </div>
</template>

<style src="./material-promotion.less" lang="less"></style>

<script>
  import orientationMixin from "../core/mixins/orientation.js";

  import BtjHeader from "../core/btj-header/btj-header.vue";
  import MaterialPromotionSingleViewCarousel from "./material-promotion-carousel-single-view.vue";
  import MaterialPromotionMultimpleViewCarousel from "./material-promotion-carousel-multimple-view.vue";
  import MaterialPromotionScroller from "./material-promotion-scroller.vue";

  export default {
    name: "material-promotion",
    mixins: [orientationMixin],
    props: {
      title: String,
      elements: Array,
      animationSpeed: Number,
      /* Display the material meta information and holdings instead of description. */
      withMetaInformation: Boolean,
      layout: {
        type: String,
        default: "auto",
        validator: _layout => ["auto", "single-view-carousel", "multiple-view-carousel", "scroller"].includes(_layout)
      }
    },
    computed: {
      libraryName() {
        return this.$easyscreenConfig.name;
      },
      isSingleViewCarouselLayout() {
        return this.layout === "single-view-carousel";
      },
      isMultipleViewCarouselLayout() {
        return this.layout === "multiple-view-carousel" || this.layout === "auto" && this._isLandscape();
      },
      isScrollerLayout() {
        return this.layout === "scroller" || this.layout === "auto" && this._isPortrait();
      }
    },
    components: {
      "btj-header": BtjHeader,
      "material-promotion-single-view-carousel": MaterialPromotionSingleViewCarousel,
      "material-promotion-multimple-view-carousel": MaterialPromotionMultimpleViewCarousel,
      "material-promotion-scroller": MaterialPromotionScroller
    }
  };
</script>
