import { castArray, isString } from "lodash";

import { JsonParseError, ProviderError } from "@/lib/errors.js";
import l10n from "@/lib/localization/localization.js";

/**
 * Helper for convert reservation error.
 *
 * @param {Object} [options={}] - The error options.
 * @param {String} [options.message=""] - The error message.
 * @param {Object} [options.errors] - The list of errors.
 * @param {String} [options.errors.id] - The reservableId of material.
 * @param {String} [options.errors.code] - The error code from server side, or unified on client side.
 * @param {String} [options.errors.message] - The error message.
 * @param {Function} [_constructor=ProviderError] - The error constructor.
 *
 * @returns {(ProviderError|_constructor)} Instance of ProviderError or _constructor with original and translated messages.
 */
function getReservationError(options, _constructor) {
  options = options || {};
  _constructor = _constructor || ProviderError;

  return new _constructor({
    errors: options.errors,
    originalMessage: `The reservation could not be completed. ${ options.message }`,
    message: l10n("The reservation could not be completed.") + " " + l10n(options.message || "")
  });
}

export default {
  methods: {
    /**
     * The options of reservation for material.
     * @typedef {Object} ReservationOptions
     *
     * @property {String} reservableId - The id of material for reservation (reservableId).
     * @property {String} [from] - The start of interest period in format YYYY-MM-DD.
     * @property {String} [to] - The end of interest period in format YYYY-MM-DD.
     * @property {String} [branchId] - Pickup branch.
     * @property {String} [departmentId] - Pickup department (used for bibliofil, since their customers have only one branch).
     */
    /**
     * Reserve material by reservableId, borrCard and pinCode.
     *
     * @param {Object} options - Reservation data.
     * @param {(String|String[]|ReservationOptions|ReservationOptions[])} options.id - The of material for reservation
     *        (reservableId or extended options).
     * @param {String} options.cpr - The cpr\borrCard or user.
     * @param {String} options.pin - The pin code or user.
     * @param {String} [options.branchId] - Pickup branch.
     * @param {String} [options.departmentId] - Pickup department (used for bibliofil, since their customers have only one branch).
     * @param {String} [options.locationId] - The id of pickup location - can be branch id or department id.
     * @param {String} [options.locationType] - The type of pickup location id, one of "branch", "department".
     *                                          Related to how the `options.locationId` will be recognized.
     * @param {String} [options.reservableId] - Same as `options.id` but with less priority.
     * @param {String} [options.reservations] - Same as `options.reservableId` but with less priority.
     *
     * @throws {(JsonParseError|ProviderError)} The provider error or response parse error.
     * @returns {Promise<>} - Promise based callback wihtout data.
     */
    async _reserveMaterial(options) {
      let branchId = options.branchId;
      let departmentId = options.departmentId;
      let id = options.id || options.reservableId || options.reservations;

      if (options.locationId && options.locationType) {
        if (options.locationType === "branch") {
          branchId = options.locationId;
        } else if (options.locationType === "department") {
          departmentId = options.locationId;
        }
      }

      try {
        var result = await this.$easyscreenRequest.lmsConnector.reserveMaterial({
          borrCard: options.cpr,
          pinCode: options.pin,
          reservableId: id,
          branchId: branchId,
          departmentId: departmentId
        });

        if (result.reservableId !== undefined) {
          result = {
            [result.reservableId]: result
          };
        }
      } catch (error) {
        try {
          error.responseJSON = JSON.parse(error.responseText);
        } catch (parseError) {
          throw getReservationError(null, JsonParseError);
        }

        console.error("Reservation error", error);
        throw getReservationError();
      }

      id = castArray(id).map(id => isString(id) ? id : id.reservableId);
      const isSingleReservation = id.length === 1;
      const errors = id.map(id => {
        if (!result[id].ok) {
          let error = Object.assign({ id: id }, result[id]);
          if (error.message === "already_reserved" || error.message === "reservationAlreadyReserved") {
            error.code = "ALREADY_RESERVED";
          }

          return error;
        }
      }).filter(Boolean);

      if (errors.length !== 0) {
        if (isSingleReservation && errors[0].code === "ALREADY_RESERVED") {
          throw getReservationError({ errors: errors, message: "Item already reserved." });
        }

        throw getReservationError({ errors: errors });
      }

      if (options.title) {
        this.$easyscreenStatistic.reserveMaterial({ title: options.title });
      }
    }
  }
};
