var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:_vm.loopIndex,class:['presentation-set', _vm.customOptions.class, _vm.orientation],style:({
    background: _vm.background,
    '--top-bar-fill-color': _vm.slideTopBarColor,
    '--duration-bar-fill-color': _vm.slideDurationBarColor
  })},[(_vm.message)?_c('div',{staticClass:"presentation-set--error-message",domProps:{"innerHTML":_vm._s(_vm.message)}}):[(_vm.slideWithTopBar)?_c('div',{staticClass:"presentation-set--top-bar"}):_vm._e(),(_vm.slideWithOverlay)?_c('div',{staticClass:"presentation-set--overlay"}):_vm._e(),(_vm.slideWithDurationBar)?_c('easyscreen-progress-bar',{staticClass:"presentation-set--duration-bar",attrs:{"from":0,"to":_vm.activeSlide.duration,"progress":_vm.activeSlide.duration - _vm.elapsedTime}}):_vm._e(),_vm._l((_vm.activeSlide.widgets),function(widget){return _c('div',{key:widget.id,class:[
        'presentation-set--element',
        `presentation-set--element_${ widget.name }`,
        {
          'presentation-set--element_with-background': widget.withBackground !== false,
          'presentation-set--element_is-single': _vm.activeSlide.widgets.length === 1
        }
      ],style:({
        top: widget.top,
        left: widget.left,
        width: widget.width,
        height: widget.height
      })},[_c('easyscreen-vue-component',_vm._g({attrs:{"props":{
          ...widget.options,
          ...(widget.name === 'video-host' ? { defaultSelected: _vm.activeSlideIndex } : {})
        },"name":widget.name,"getter":widget.getter,"settings":widget.settings},on:{"disable-countdown":_vm._disableCountdown,"select-slide":_vm.select}},_vm.$listeners))],1)})]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }