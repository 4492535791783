<template>
  <div
    v-if="visible"
    class="easyscreen-fullscreen-button"
    @click="toggleFullscreen"
  ></div>
</template>

<style src="./fullscreen-button.less" lang="less"></style>

<script>
  import parseDuration from "parse-duration";
  import * as d3 from "d3-timer";

  export default {
    name: "easyscreen-fullscreen-button",
    props: {
      /* The duration in which the fullscreen button will be hided. */
      visibilityTimeout: {
        type: Number,
        default: parseDuration("15s")
      }
    },
    data() {
      return {
        visible: true
      };
    },
    methods: {
      /**
       * Toggle fullscreen mode based on browser state.
       */
      toggleFullscreen() {
        if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement) {
          this.enableFullscreen();
        } else {
          this.disableFullscreen();
        }
      },
      /**
       * Enable fullscreen mode.
       */
      enableFullscreen() {
        const elem = document.documentElement;

        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.mozRequestFullScreen) {
          elem.mozRequestFullScreen();
        } else if (elem.webkitRequestFullscreen) {
          elem.webkitRequestFullscreen();
        }
      },
      /**
       * Disable fullscreen mode.
       */
      disableFullscreen() {
        if (document.cancelFullScreen) {
          document.cancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      }
    },
    mounted() {
      this._timeout = d3.timeout(() => {
        this.visible = false;
        this._timeout = null;
      }, this.visibilityTimeout);
    },
    beforeDestroy() {
      if (this._timeout) {
        this._timeout.stop();
        this._timeout = null;
      }
    }
  };
</script>
