<template>
  <div
    :class="[
      'materials-scanner-layout',
      orientation
    ]"
  >
    <div v-if="_isLandscape()" class="materials-scanner-layout--info">
      <slot name="content-info"></slot>
    </div>
    <modal-layout>
      <template slot="header">
        <slot name="header"></slot>
      </template>
      <template slot="content">
        <slot name="content"></slot>
        <div v-if="_isPortrait()" class="materials-scanner-layout--info">
          <slot name="content-info"></slot>
        </div>
        <scrollable
          class="materials-scanner-layout--content-materials"
          :max-height="_isLandscape() ? 665 : 1210"
          smooth-edge-color="#262626"
          :activate-scroll-bottom="true"
        >
          <slot name="content-materials"></slot>
        </scrollable>
        <div v-if="_isPortrait()" class="materials-scanner-layout--counter">
          <slot name="footer-counter"></slot>
        </div>
      </template>
      <template slot="footer">
        <slot name="footer"></slot>
        <div class="materials-scanner-layout--footer">
          <div class="materials-scanner-layout--buttons">
            <slot name="footer-buttons"></slot>
          </div>
          <div v-if="_isLandscape()" class="materials-scanner-layout--counter">
            <slot name="footer-counter"></slot>
          </div>
        </div>
      </template>
    </modal-layout>
  </div>
</template>

<style lang="less" src="./layout.less"></style>

<script>
  import ModalLayout from "../../core/modal/layout.vue";
  import Scrollable from "../../core/scrollable/scrollable.vue";

  import orientationMixin from "../../core/mixins/orientation.js";

  export default {
    name: "materials-scanner-layout",
    mixins: [orientationMixin],
    components: {
      "modal-layout": ModalLayout,
      "scrollable": Scrollable
    }
  };
</script>
