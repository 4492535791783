<template>
  <div
    :class="[
      'easyscreen-carousel-ribbon',
      `easyscreen-carousel-ribbon_${ aligment }`
    ]"
  >
    <div
      class="easyscreen-carousel-ribbon--content"
      :style="{
        fontSize: fontSize,
        backgroundColor: background
      }"
    >
      <div class="easyscreen-carousel-ribbon--text">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<style src="./carousel-ribbon.less" lang="less"></style>

<script>
  export default {
    name: "easyscreen-carousel-ribbon",
    props: {
      /* Label\title of ribbon. */
      title: String,
      /* Background color of ribbon (any valid css color). */
      background: String,
      /* Font size of ribbon (any valid css size unit - px, pt, % and etc.) */
      fontSize: [Number, String],
      /*
       * The placement of ribbon:
       * - top-left
       * - top-right
       * - bottom-left
       * - bottom-right (default)
       */
      aligment: {
        type: String,
        default: "bottom-right",
        validator: (_aligment) => [
          "top-left",
          "top-right",
          "bottom-left",
          "bottom-right"
        ].includes(_aligment)
      }
    }
  };
</script>
