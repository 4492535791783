var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"opening-hours"},[_c('div',{staticClass:"opening-hours--header"},[_c('i',{staticClass:"opening-hours--carousel-control fa fa-angle-left",on:{"click":() => _vm.$refs.carousel.setOffset(_vm.$refs.carousel.getOffset() + 100)}}),_c('h2',{staticClass:"opening-hours--title"},[_vm._v(" "+_vm._s(_vm._l10n("week"))+" "+_vm._s(_vm.currentWeek)+" ")]),_c('i',{staticClass:"opening-hours--carousel-control fa fa-angle-right",on:{"click":() => _vm.$refs.carousel.setOffset(_vm.$refs.carousel.getOffset() - 100)}})]),_c('easyscreen-carousel',{ref:"carousel",attrs:{"height":_vm.$easyscreenIsMobile ? 'content-aware' : null,"layout-columns":1,"layout-rows":1,"default-position":_vm.defaultCarouselPosition},on:{"before-position-change":(_, followingSlideIndex) => {
      _vm._setActiveWeekBySlideIndex(followingSlideIndex)
    }}},_vm._l((_vm.openingHoursWeekGroup),function(weekOpeningHours){return _c('table',{key:weekOpeningHours.displayWeekNumber,staticClass:"opening-hours--list"},[_c('tbody',[_c('tr',{staticClass:"opening-hours--list-header"},[_c('th',[_vm._v(_vm._s(_vm._l10n(_vm.dayLabel || "day")))]),_c('th',[_vm._v(_vm._s(_vm._l10n(_vm.openedTodayLabel || "opening hours")))]),(_vm.localWithServiceTime)?_c('th',[_vm._v(_vm._s(_vm._l10n(_vm.serviceTimeLabel || "service time")))]):_vm._e()]),_vm._l((weekOpeningHours.weekDays),function(_openingHours){return _c('tr',{key:_openingHours.date,class:[
            'opening-hours--list-element',
            { 'opening-hours--list-element_active': _vm.currentDate === _openingHours.date }
          ],style:({
            backgroundColor: _vm.currentDate === _openingHours.date ? _vm.tileColor : ''
          })},[_c('td',{staticClass:"opening-hours--service-days"},[_c('span',[_vm._v(_vm._s(_vm._convertDate(_openingHours.date, "dddd")))]),_c('span',[_vm._v(_vm._s(" "))]),_c('span',[_vm._v(_vm._s(_vm._convertDate(_openingHours.date, "DD/MM")))])]),_c('td',[(_openingHours.time)?_c('span',{staticClass:"opening-hours--service-time"},[_c('span',[_vm._v(_vm._s(_openingHours.time.from))]),_c('span',{staticClass:"opening-hours--separator"},[_vm._v(" - ")]),_c('span',[_vm._v(_vm._s(_openingHours.time.to))])]):(_openingHours.isUnknown !== true)?_c('span',[_vm._v(" "+_vm._s(_vm._l10n("Closed"))+" ")]):_vm._e()]),(_vm.localWithServiceTime)?_c('td',[(_openingHours.serviceTime)?_c('span',{staticClass:"opening-hours--service-time"},[_c('span',[_vm._v(_vm._s(_openingHours.serviceTime.from))]),_c('span',{staticClass:"opening-hours--separator"},[_vm._v(" - ")]),_c('span',[_vm._v(_vm._s(_openingHours.serviceTime.to))])]):(_openingHours.isUnknown !== true)?_c('span',[_vm._v(" "+_vm._s(_vm._l10n("Closed"))+" ")]):_vm._e()]):_vm._e()])})],2)])}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }