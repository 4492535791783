<template>
  <div :class="['patron-profile--overview', orientation]">
    <!-- User account: start -->
    <modal-layout v-if="screens.userAccount" class="patron-profile--overview-card">
      <template slot="header">
        {{ _l10n("User account") }}
      </template>
      <template slot="content">
        <p>{{ _l10n("See profile information and edit favorite library and default reservation time.") }}</p>
        <ul>
          <li>{{ _l10n("Edit profile information") }}</li>
          <li>{{ _l10n("Change favorite library") }}</li>
          <li>{{ _l10n("Change PIN code") }}</li>
          <li>{{ _l10n("Set reservations on hold") }}</li>
        </ul>
      </template>
      <template slot="footer">
        <easyscreen-button
          class="patron-profile--overview-action-button"
          color="primary"
          modificator="fixed-width_small"
          @click.native="$emit('select-screen', 'userAccount')"
        >
          {{ _l10n("Open User account") }}
        </easyscreen-button>
      </template>
    </modal-layout>
    <!-- User account: end -->

    <!-- Debts: start -->
    <modal-layout v-if="screens.debts" class="patron-profile--overview-card">
      <template slot="header">
        {{ _l10n("Debts") }}
        <easyscreen-badge color="danger" type="circle" v-if="_get(data, 'fees.length', 0) !== 0">
          {{ _get(data, "fees.length", 0) }}
        </easyscreen-badge>
      </template>
      <template slot="content">
        <p>
          {{ _l10n("You have {d.COUNT} unpaid debt{d.COUNT === 1 ? '' : 's'}", { COUNT: _get(data, "debts.length", 0) }) }}
        </p>
        <ul>
          <li>{{ _l10n("See and pay debts") }}</li>
        </ul>
      </template>
      <template slot="footer">
        <easyscreen-button
          class="patron-profile--overview-action-button"
          color="primary"
          modificator="fixed-width_small"
          @click.native="$emit('select-screen', 'debts')"
        >
          {{ _l10n("Open Debts") }}
        </easyscreen-button>
      </template>
    </modal-layout>
    <!-- Debts: end -->

    <!-- Loans: start -->
    <modal-layout v-if="screens.loans" class="patron-profile--overview-card">
      <template slot="header">
        {{ _l10n("Loans") }}
      </template>
      <template slot="content">
        <p>
          {{ _l10n("Next upcoming return") }}:
          <span class="patron-profile--highlight" v-if="_get(data, 'loans.nextUpcoming')">
            {{ _l10n("Return before") }}
            {{ _convertDate(_get(data, "loans.nextUpcoming.loanDetails.dueDate")) }}
          </span>
          <span v-else>-</span>
        </p>
        <ul>
          <li>{{ _l10n("See all loans") }}</li>
          <li>{{ _l10n("Renew") }}</li>
        </ul>
      </template>
      <template slot="footer">
        <easyscreen-button
          class="patron-profile--overview-action-button"
          color="primary"
          modificator="fixed-width_small"
          @click.native="$emit('select-screen', 'loans')"
        >
          {{ _l10n("Open Loans") }}
        </easyscreen-button>
      </template>
    </modal-layout>
    <!-- Loans: end -->

    <!-- Reservations: start -->
    <modal-layout v-if="screens.reservations" class="patron-profile--overview-card">
      <template slot="header">
        {{ _l10n("Reservations") }}
        <easyscreen-badge color="primary" type="circle" v-if="_get(data, 'reservations.waiting.length', 0) !== 0">
          {{ _get(data, "reservations.waiting.length", 0) }}
        </easyscreen-badge>
      </template>
      <template slot="content">
        <p>{{ _l10n("You have {d.COUNT} reservation{d.COUNT === 1 ? '' : 's'}", { COUNT: _get(data, "reservations.waiting.length", 0) }) }}</p>
        <ul>
          <li>{{ _l10n("See all reservations") }}</li>
          <li>{{ _l10n("Change reservation time and library") }}</li>
        </ul>
      </template>
      <template slot="footer">
        <easyscreen-button
          class="patron-profile--overview-action-button"
          color="primary"
          modificator="fixed-width_small"
          @click.native="$emit('select-screen', 'reservations')"
        >
          {{ _l10n("Open Reservations") }}
        </easyscreen-button>
      </template>
    </modal-layout>
    <!-- Reservations: end -->

    <!-- Reservations ready: start -->
    <modal-layout v-if="screens.reservationsReady" class="patron-profile--overview-card">
      <template slot="header">
        {{ _l10n("Ready to pick up") }}
        <easyscreen-badge color="primary" type="circle" v-if="_get(data, 'reservations.ready.length', 0) !== 0">
          {{ _get(data, "reservations.ready.length", 0) }}
        </easyscreen-badge>
      </template>
      <template slot="content">
        <p>
          {{
            _l10n("You have {d.COUNT} reservation{d.COUNT === 1 ? '' : 's'} ready for pick up.", {
              COUNT: _get(data, "reservations.ready.length", 0)
            })
          }}
        </p>
        <ul>
          <li>{{ _l10n("See all reservations") }}</li>
          <li>{{ _l10n("Change reservation time and library") }}</li>
        </ul>
      </template>
      <template slot="footer">
        <easyscreen-button
          class="patron-profile--overview-action-button"
          color="primary"
          modificator="fixed-width_small"
          @click.native="$emit('select-screen', 'reservationsReady')"
        >
          {{ _l10n("Open Reservations") }}
        </easyscreen-button>
      </template>
    </modal-layout>
    <!-- Reservations ready: end -->
  </div>
</template>

<script>
  import { get } from "lodash";
  import l10n from "@/lib/localization/localization.js";

  import ModalLayout from "../../../core/modal/layout.vue";
  import EasyscreenButton from "../../../core/button/button.vue";
  import EasyscreenBadge from "../../../core/badge/badge.vue";

  import orientationMixin from "../../../core/mixins/orientation.js";
  import convertDateMixin from "../../../core/mixins/convert-date.js";

  export default {
    name: "patron-profile-overview",
    mixins: [orientationMixin, convertDateMixin],
    props: {
      /* The user profile data: loans, reservations and etc. */
      data: Object,
      /* List of enabled screens. */
      screens: Object
    },
    methods: {
      /**
       * The proxy method of `lodash.get`.
       */
      _get: get,
      /* Proxy for localization function. */
      _l10n: l10n
    },
    components: {
      "modal-layout": ModalLayout,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-badge": EasyscreenBadge
    }
  };
</script>
