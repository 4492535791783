<template>
  <div>
    <materials-scanner
      @screen-closed="() => $emit('closed')"
      ref="materialsScanner"
    />
    <modal-confirm
      ref="confirm"
      max-width="900px"
    />
  </div>
</template>

<script>
  import ModalConfirm from "../../../core/modal/confirm.vue";
  import MaterialsScanner from "../../../materials-scanner/materials-scanner.vue";

  import { mapState } from "vuex";

  export default {
    name: "easyscreen-menu-loan-screen",
    computed: {
      ...mapState({
        safetyModeEnabled: state => state.safetyModeEnabled
      })
    },
    methods: {
      /**
       * Open the self check loan screen.
       *
       * @param {Function} callback - Callback, will be called when screen is opend.
       */
      show(callback) {
        if (this.safetyModeEnabled) {
          return this.$refs.confirm.show({
            message: this.$l10n("We will save your credentials locally on this computer," 
              + " until the computer is online and the loan is processed. By proceeding you accept this term."),
            callback: (_, callbackType) => {
              if (callbackType === "ok") {
                return this.$refs.materialsScanner.show("loan", callback);
              }

              this.$emit("closed");
            }
          });
        }

        this.$refs.materialsScanner.show("loan", callback);
      },
      /**
       * Close the self check loan screen.
       *
       * @param {Function} callback - Callback, will be called when screen is closed.
       */
      hide(callback) {
        this.$refs.materialsScanner.hide("loan", callback);
      }
    },
    components: {
      "modal-confirm": ModalConfirm,
      "materials-scanner": MaterialsScanner
    }
  };
</script>
