<template>
  <div
    :class="[
      'easyscreen-carousel',
      `easyscreen-carousel_${ transition }`
    ]"
    :style="{ height: currentCarouselHeight }"
  >
    <div
      :class="[
        'easyscreen-carousel--wrapper',
        {
          'easyscreen-carousel--wrapper_dragging': dragging
        }
      ]"
      :style="_getWrapperStyles()"
    >
      <div
        v-for="slideNumber in slidesAmount"
        :key="`slide-${ slideNumber + slideOffset }`"
        class="easyscreen-carousel--slide"
        :style="_getSlideStyles(slideNumber - 1)"
      >
        <template v-if="_slideIsShown(slideNumber - 1)">
          <template v-for="rowIndex in layoutRows">
            <div
              v-if="!!_get($slots, 'default', [])[_getItemIndex(slideNumber - 1, rowIndex - 1, 0)]"
              :key="`row-${ rowIndex }`"
              class="easyscreen-carousel--row"
              :style="{ height: `${ 100 / layoutRows }%` }"
            >
              <template v-for="columnIndex in layoutColumns">
                <div
                  v-if="!!_get($slots, 'default', [])[_getItemIndex(slideNumber - 1, rowIndex - 1, columnIndex - 1)]"
                  :key="`column-${ columnIndex }`"
                  class="easyscreen-carousel--item"
                  :style="{
                    width: `${ 100 / layoutColumns }%`
                  }"
                >
                  <get-component
                    :data="$slots.default"
                    :path="_getItemIndex(slideNumber - 1, rowIndex - 1, columnIndex - 1)"
                  />
                </div>
              </template>
            </div>
          </template>
        </template>
      </div>
    </div>
  </div>
</template>
<style lang="less" src="./carousel.less"></style>

<script>
  import { get } from "lodash";
  import * as d3 from "d3-timer";
  import Hammer from "hammerjs";
  import propagatingHammer from "propagating-hammerjs";
  import debounceDrop from "@/lib/utils/debounce-drop.js";
  import getStyle from "@/lib/utils/get-style.js";
  import clampNumber from "@/lib/utils/clamp-number.js";
  import fitNumber from "@/lib/utils/fit-number.js";
  import asyncTimeout from "@/lib/utils/async-timeout.js";
  import { parentNodeByFilter, classFilter } from "@/lib/utils/parent-node-by-filter.js";

  import orientationMixin from "../mixins/orientation.js";
  import hammerjsToolsMixin from "../mixins/hammerjs-tools.js";

  import GetComponent from "../get-component/get-component.vue";

  const amountOfFallbackSlideForInfinite = 2;
  export default {
    name: "easyscreen-carousel",
    mixins: [orientationMixin, hammerjsToolsMixin],
    props: {
      /* Step in % of container width for scroll left\right (default: 100). */
      step: {
        type: Number,
        default: 100
      },
      /* Amount of columns of content per slide (default: 5). */
      layoutColumns: {
        type: Number,
        default: 5
      },
      /* Amount of rows of content per slide (default: 3). */
      layoutRows: {
        type: Number,
        default: 3
      },
      /* Start position\offset of slider (default: 0). */
      defaultPosition: {
        type: Number,
        default: 0
      },
      /* Fixed position offset applied on top of default offset handler (default: 0). */
      positionOffset: {
        type: Number,
        default: 0
      },
      /* The min value of offset without `positionOffset` property - left border of scroll (default: 0). */
      positionLimitLeft: {
        type: Number,
        default: 0
      },
      /* The max value of offset without `positionOffset` property - right border of scroll (default: based on `type` and amount of slides). */
      positionLimitRight: Number,
      /* Flag for disable the drag and drop scroll animation (default: flase - animation enabled). */
      swipeOnly: {
        type: Boolean,
        default: false
      },
      /*
       * Flag for hide the slides which is out of screen - give a huge performance
       * boost on massive slides with big amount of images\video (default: true).
       * Will be skipped if the `type` if `infinite`.
       */
      optimization: {
        type: Boolean,
        default: true
      },
      /* The amount of slides which rendered with active. Used only when "optimization" is `true`. */
      additionalVisibleSlides: {
        type: Number,
        default: 2
      },
      /*
       * The type of handling edges of slider:
       * - default - Do nothing if border is reached (default).
       * - loop - Scroll to last\first when the one left\right border is reached.
       * - infinite - Moves the slides from start of list to end and back while scrolling - will be looked as infinite while scroll.
       */
      type: {
        type: String,
        default: "default",
        validator: _type => ["default", "loop", "infinite"].includes(_type)
      },
      /*
       * Type of transition between slides:
       * - slide - slides the content to left\right while moving the cursor to left\right\swipe; slides placed in a row (default).
       * - fade - changes the opacity of slides while moving the cursor to left\right\swipe; slides placed on top of each other.
       */
      transition: {
        type: String,
        default: "slide",
        validator: _transition => ["slide", "fade"].includes(_transition)
      },
      suspended: Boolean,
      height: {
        type: String,
        default: "fixed",
        validator: _height => ["fixed", "content-aware"].includes(_height)
      }
    },
    watch: {
      async _slideIndex(){
        if (this.height === "fixed")
          return;

        await this.$nextTick();
        await this.$nextTick();

        this.currentCarouselHeight = this._getCarouselHeight();
      }
    },
    computed: {
      /**
       * Getter of elements from the $slots.default.
       *
       * @returns {VNode[]} The list of VNodes from default slot.
       */
      elements: {
        cache: false,
        get() {
          return get(this.$slots, "default", []);
        }
      },
      /**
       * Getter of amount element per slide.
       *
       * @returns {Number}
       */
      elementsPerSlide() {
        return this.layoutColumns * this.layoutRows;
      },
      /**
       * The getter of constant value of amount of fallback slides infinite slider type.
       *
       * @returns {Number}
       */
      amountOfFallbackSlideForInfinite() {
        return amountOfFallbackSlideForInfinite;
      },
      /**
       * Getter of default slides amount.
       *
       * @returns {Number}
       */
      defaultSlidesAmount: {
        cache: false,
        get() {
          return Math.ceil(this.elements.length / this.elementsPerSlide);
        }
      },
      /**
       * Get amount of slides based on layout and amount of items.
       *
       * @returns {Number} The amount of slides.
       */
      slidesAmount: {
        cache: false,
        get() {
          if (this.type === "infinite") {
            return 1 + amountOfFallbackSlideForInfinite * 2;
          }

          return this.defaultSlidesAmount;
        }
      },
      /**
       * Get the left limit for offset of carousel wrapper.
       *
       * @returns {Number} left limit of offset.
       */
      leftLimit() {
        let leftLimit = this.positionLimitLeft;

        if (this.type === "infinite") {
          return -Number.MAX_SAFE_INTEGER + 1000;
        }

        return leftLimit;
      },
      /**
       * Get the right limit for offset of carousel wrapper.
       *
       * @returns {Number} Right limit of offset.
       */
      rightLimit: {
        cache: false,
        get() {
          let rightLimit = this.positionLimitRight;
          if (typeof rightLimit !== "number") {
            rightLimit = (this.slidesAmount - 1) * -100;
          }

          if (this.type === "infinite") {
            return Number.MAX_SAFE_INTEGER - 1000;
          }

          return rightLimit;
        }
      },
      _slideIndex(){
        return this.getActiveSlideIndex();
      }
    },
    data() {
      let defaultPosition = this.defaultPosition;
      if (this.type === "infinite") {
        defaultPosition += amountOfFallbackSlideForInfinite * -100;
      }

      return {
        dragging: false,
        slideOffset: 0,
        offset: defaultPosition,
        currentCarouselHeight: null
      };
    },
    methods: {
      /**
       * Select slide by index.
       *
       * @param {Number} index - The slide index counting by `step`.
       * @param {Object} options - The `.setOffset` options.
       */
      selectByIndex(index, options) {
        this.setOffset(this._getOffsetByIndex(index), options);
      },
      /**
       * Select previous slide or last when `loop` is `true` and the offset is equal or greater than 0.
       *
       * @param {Object} options - The `.setOffset` options.
       */
      previousSlide(options) {
        this.setOffset(this.offset + this.step, options);
      },
      /**
       * Select next slide or first when `this.type === "loop"` and the offset is equal or less than right limit.
       *
       * @param {Object} options - The `.setOffset` options.
       */
      nextSlide(options) {
        this.setOffset(this.offset - this.step, options);
      },
      /**
       * Get the active offset wihtout the static offset from props.
       *
       * @returns {Number} Current offset in percentages.
       */
      getOffset() {
        return this.offset;
      },
      /**
       * Get index of active slide.
       *
       * @returns {Number} Index of active slide. Might have decimal part (this means the slide out of center).
       */
      getActiveSlideIndex() {
        return clampNumber(this.offset / this.step * -1, 0, Infinity);
      },
      /**
       * Clamp offset according to left and right borders, type and options.
       * @param {Number} offset - The offset if slides inside the slider container in %.
       * @param {Object} options - The set offset options.
       * @param {Boolean} [options.withClamp=true] - Disable\enable the offset borders.
       *
       * @returns {Number} offset inside the given borders.
       */
      clampOffset(offset, options) {
        options = options || {};

        if (this.offset > this.leftLimit && offset > this.leftLimit && this.type === "loop") {
          offset = this.rightLimit;
        } else if (this.offset < this.rightLimit && offset < this.rightLimit && this.type === "loop") {
          offset = this.leftLimit;
        }

        if (options.withClamp !== false) {
          offset = clampNumber(offset, this.leftLimit, this.rightLimit);
        }

        return offset;
      },
      /**
       * Set the offset of slider (the offset will be clamped by left and right limit).
       * @async
       *
       * @param {Number} offset - The offset if slides inside the slider container in %.
       * @param {Object} options - The set offset options.
       * @param {Boolean} [options.withoutAnimation=false] - Set the offset without transition.
       * @param {Boolean} [options.withoutEvents=false] - Disable\enable the events of this update.
       * @param {Boolean} [options.withClamp=true] - Disable\enable the offset borders.
       *
       * @returns {Promise} Promise-based callback, will be called when change offset animation will be finished.
       */
      async setOffset(offset, options) {
        options = options || {};
        offset = this.clampOffset(offset, options);

        const activeSlide = this._getIndexByOffset(this.offset);
        const followingSlideIndex = this._getIndexByOffset(offset);

        if (options.withoutEvents !== true) {
          this.$emit("before-position-change", activeSlide, followingSlideIndex);
        }

        if (options.withoutAnimation) {
          this.dragging = true;
          await this.$nextTick();
        }

        this.offset = offset;
        if (!options.withoutAnimation) {
          await asyncTimeout(600);
        }

        if (this.type === "infinite") {
          this.slideOffset = Math.round(offset / -100 - this.amountOfFallbackSlideForInfinite);
          if (!this.dragging) {
            this.dragging = "_setOffset";
            /* The $nextTick is not enough for disable the css transition via class modifier. */
            await this.$nextTick();
            await asyncTimeout(50);
            if (this.dragging === "_setOffset") {
              this.dragging = false;
            }
          }
        }

        if (options.withoutEvents !== true) {
          this.$emit("position-changed", followingSlideIndex);
        }


        if (options.withoutAnimation) {
          /* The nextTick does not perform right the animation disabling. */
          await asyncTimeout(200);
          this.dragging = false;
        }

        return this.$nextTick();
      },

      /**
       * The proxy method of `lodash.get`.
       */
      _get: get,

      /**
       * Get the style of carousel wrapper (offset and total width).
       *
       * @returns {Object} styles - The style supprorted by vue.
       * @returns {String} styles.width - Total width.
       * @returns {String} styles.marginLeft - Offset.
       */
      _getWrapperStyles() {
        if (this.transition === "fade") {
          return { width: "100%" };
        }

        let marginLeft = this.positionOffset + this.offset;
        if (this.type === "infinite") {
          marginLeft += this.slideOffset * 100;
        }

        return {
          width: `${ this.slidesAmount * 100 }%`,
          marginLeft: `${ marginLeft }%`
        };
      },

      /**
       * Get the style of slide (width).
       *
       * @param {Number} slideIndex - The index of slide, used for non "slide" transition.
       *
       * @returns {Object} styles - The style supprorted by vue.
       * @returns {String} styles.width - The slide width.
       */
      _getSlideStyles(slideIndex) {
        if (this.transition === "fade") {
          const lastSlideIndex = this.slidesAmount - 1;
          const position = this.getActiveSlideIndex();
          const progress = Math.abs(position % 1);

          const lowerLimit = { lt: 0, value: lastSlideIndex };
          const upperLimit = { gt: lastSlideIndex, value: 0 };
          let topIndex = fitNumber(Math.ceil(position), lowerLimit, upperLimit);
          let bottomIndex = fitNumber(Math.floor(position), lowerLimit, upperLimit);
          if (position < 0) {
            const tmpIndex = bottomIndex;
            bottomIndex = topIndex;
            topIndex = tmpIndex;
          }

          if (topIndex === bottomIndex && topIndex === slideIndex) {
            return { opacity: 1, zIndex: 1 };
          } else if (slideIndex === topIndex) {
            return { opacity: progress };
          } else if (slideIndex === bottomIndex) {
            return { opacity: 1 - progress };
          }

          return {
            opacity: 0
          };
        }

        return {
          width: `${ 100 / this.slidesAmount }%`
        };
      },
      /**
       * Get the item index based on slide, rows and column indexes.
       *
       * @param {Number} slideIndex - Slide index.
       * @param {Number} rowIndex - Row index.
       * @param {Number} columnIndex - Column index.
       *
       * @returns {Number} The index of item.
       */
      _getItemIndex(slideIndex, rowIndex, columnIndex) {
        let elementIndex = (slideIndex * this.elementsPerSlide) + (rowIndex * this.layoutColumns) + columnIndex;

        if (this.type === "infinite") {
          /* Substruct the initial offset and slide offset (slide offset based on actual offset). */
          elementIndex -= (this.amountOfFallbackSlideForInfinite - this.slideOffset) * this.elementsPerSlide;
          /* Fit the index into the borders 0 - this.elements.length. */
          elementIndex = elementIndex % this.elements.length;

          elementIndex = parseInt(elementIndex, 10);

          if (elementIndex < 0) {
            elementIndex = this.elements.length + elementIndex;
          }
        }

        return elementIndex;
      },

      /**
       * Get the offset by the visible slide index.
       *
       * @param {Number} index - The visible slide index.
       */
      _getOffsetByIndex(index) {
        return index * -this.step;
      },
      /**
       * Get the visible slide index based on offset.
       *
       * @param {Number} [offset=this.offset] - The offset of carousel wrapper.
       *
       * @returns {Number} The index of visible slide for passed offset.
       */
      _getIndexByOffset(offset) {
        if (offset === undefined) {
          offset = this.offset;
        }

        /* Return cached value for speedup the update loop. */
        if (this._getIndexByOffset._offset === offset) {
          return this._getIndexByOffset._cached;
        }

        this._getIndexByOffset._offset = offset;
        let slideIndex = Math.ceil(Math.abs(offset / this.step));
        if (this.type === "infinite") {
          /* Subtract the initial offset for get the start at center of available view. */
          slideIndex = (offset - this.amountOfFallbackSlideForInfinite * -100) / this.step;
          /* Negate the index, since the offset are calculating based on negative margin. */
          slideIndex = Math.round(slideIndex * -1);
        }

        this._getIndexByOffset._cached = slideIndex;
        return this._getIndexByOffset._cached;
      },

      /**
       * Check if the passed html element is the child of another carousel instance.
       *
       * @param {HTMLElement} node - The html element for check.
       *
       * @returns {Boolean} `true` - if the element is child of another carousel.
       */
      _isAnotherCarousel(node) {
        let check = classFilter("easyscreen-carousel");
        let carousel = check(node) ? node : parentNodeByFilter(node, check);

        return carousel !== this.$el;
      },

      /**
       * Initialize the change slide with dragging animation (slide will be follow to finger).
       */
      _initSmoothDragAndDrop() {
        this.hammer = propagatingHammer(new Hammer(this.$el));

        let offset;
        let percentPerPixel;
        let endTimeout;

        this.hammer.get("pan").set({ direction: Hammer.DIRECTION_ALL });
        this.hammer.on("panstart", (hammerEvent) => {
          if (this.suspended) { return; }

          hammerEvent.stopPropagation();
          if (this._isAnotherCarousel(hammerEvent.firstTarget) || this._isHammerjsPrevented()) {
            return true;
          }

          this._preventHammerjs();

          if (endTimeout) {
            endTimeout.stop();
          }

          this.dragging = true;
          offset = this.offset;
          percentPerPixel = 100 / getStyle(this.$el, "width");

          this.$emit("dragging-started", this.positionOffset + offset);
        });

        // Timeout for events due to next animation frame (the number of callbacks is usually 60 times per second).
        this.hammer.on("panleft panright", debounceDrop((hammerEvent) => {
          if (this.suspended) { return; }

          hammerEvent.stopPropagation();
          if (this._isAnotherCarousel(hammerEvent.firstTarget) || this._isHammerjsPrevented()) {
            return true;
          }

          // Required for a HACK_2.
          this.hammer.lastHammerPanEvent = hammerEvent;

          this.offset = offset + this._applyParentsScale(this.$el, { x: hammerEvent.deltaX, y: 0 }).x * percentPerPixel;
          this.$emit("drag", this.positionOffset + this.offset);
        }, 16));

        const panEnd = (hammerEvent) => {
          let hackCalled = false;
          if (!hammerEvent && this.hammer.lastHammerPanEvent)
            hackCalled = true;

          /*
           * HACK_2: to finish the carousel drag and drop when the hammer event not fired (use the pointerend).
           *
           * By default the hammerjs `panend` event will be called before the `pointerup` and `mouseup` that will
           * allow to process the panend with defaul case, the `pointerup` and `mouseup` will be ignored.
           * Otherwise to the finish pan action will be used last saved `pan` event.
           */
          hammerEvent = hammerEvent || this.hammer.lastHammerPanEvent;
          if (hammerEvent == null) {
            this.dragging = false;
            return;
          }

          this.hammer.lastHammerPanEvent = null;
          // END HACK

          hammerEvent.stopPropagation();
          if (hackCalled !== true && (this._isAnotherCarousel(hammerEvent.firstTarget) || this._isHammerjsPrevented())) {
            return true;
          }

          /*
           * HACK: required for prevent cases when item handler is called on swipe
           * if cursor will placed under item with click handler when dragging is finished.
           */
          const done = (offset) => {
            let resultOffset = clampNumber(offset, this.leftLimit, this.rightLimit);
            endTimeout = d3.timeout(() => {
              this.dragging = false;
              this._allowHammerjs();
              this.$emit("dragging-finished", this.positionOffset + resultOffset);
              this.$nextTick(() => {
                this.setOffset(offset);
              });
            }, 50);
          };


          // vertical swipe
          const angle = Math.abs(hammerEvent.angle);
          if (angle > 60 && angle < 120) {
            // close the slider
            const longSwipe = Math.abs(hammerEvent.deltaY) > 300;
            const fastSwipe = Math.abs(hammerEvent.deltaY) > 100 && Math.abs(hammerEvent.velocityY) > 2.5;

            if (longSwipe || fastSwipe){
              this.$emit("vertical-swipe");
              if (hammerEvent.angle < 0) {
                this.$emit("swipe-top");
              } else {
                this.$emit("swipe-bottom");
              }
            }

            return done(offset);
          }

          // horizontal swipe
          const draggingOffset = offset - this.offset;
          if (Math.abs(draggingOffset / this.step) < 0.2) {
            return done(offset);
          }

          let multiplier = this.offset < 0 ? -1 : 1;
          let roundMethod = draggingOffset < 0 ? Math.floor : Math.ceil;

          if (this.type === "infinite" && this.offset > 0) {
            roundMethod = draggingOffset < 0 ? Math.ceil : Math.floor;
          } else if (this.type === "loop" && this.offset > 0) {
            roundMethod = Math.ceil;
          }

          done(roundMethod(Math.abs(this.offset / this.step)) * this.step * multiplier);
        };

        this.hammer.on("panend", panEnd);

        // Required for a HACK_2.
        const onPointerUp = () => panEnd();
        window.addEventListener("pointerup", onPointerUp);
        // Fallback for a `pointerup` for a tizen compatible build.
        window.addEventListener("mouseup", onPointerUp);
        window.addEventListener("touchend", onPointerUp);

        this.hammer.offNative = () => {
          window.removeEventListener("pointerup", onPointerUp);
          window.removeEventListener("mouseup", onPointerUp);
          window.removeEventListener("touchend", onPointerUp);
        };
      },

      /**
       * Initialize the change slide with only swipe gesture (slide will not be follow to finger).
       */
      _initSwipeDragAndDrop() {
        let swipeLeftEndTimeout;
        let swipeRightEndTimeout;

        this.hammer = propagatingHammer(new Hammer(this.$el));

        this.hammer.get("swipe").set({ direction: Hammer.DIRECTION_HORIZONTAL });
        this.hammer.on("swipeleft", (hammerEvent) => {
          if (this.suspended) { return; }

          hammerEvent.stopPropagation();
          if (this._isAnotherCarousel(hammerEvent.firstTarget) || this._isHammerjsPrevented()) {
            return true;
          }

          if (swipeLeftEndTimeout) {
            swipeLeftEndTimeout.stop();
          }

          this._preventHammerjs();
          swipeLeftEndTimeout = d3.timeout(() => {
            this._allowHammerjs();
          }, 50);

          let prevented = false;
          let _preventDefault = hammerEvent.preventDefault;
          hammerEvent.preventDefault = () => {
            prevented = true;
            hammerEvent.preventDefault = _preventDefault;
            hammerEvent.preventDefault();
          };

          this.$emit("before-swipe", hammerEvent);
          this.$emit("before-swipe-left", hammerEvent);
          if (!prevented) {
            this.nextSlide();
            this.$emit("swipe", hammerEvent);
            this.$emit("swipe-left", hammerEvent);
          }
        });

        this.hammer.on("swiperight", (hammerEvent) => {
          if (this.suspended) { return; }

          hammerEvent.stopPropagation();
          if (this._isAnotherCarousel(hammerEvent.firstTarget) || this._isHammerjsPrevented()) {
            return true;
          }

          if (swipeRightEndTimeout) {
            swipeRightEndTimeout.stop();
          }

          this._preventHammerjs();
          swipeRightEndTimeout = d3.timeout(() => {
            this._allowHammerjs();
          }, 50);

          let prevented = false;
          let _preventDefault = hammerEvent.preventDefault;
          hammerEvent.preventDefault = () => {
            prevented = true;
            hammerEvent.preventDefault = _preventDefault;
            hammerEvent.preventDefault();
          };

          this.$emit("before-swipe", hammerEvent);
          this.$emit("before-swipe-right", hammerEvent);
          if (!prevented) {
            this.previousSlide();
            this.$emit("swipe", hammerEvent);
            this.$emit("swipe-right", hammerEvent);
          }
        });
      },

      /**
       * Destroy the drag and drop library which tracks the gestures and finger movements.
       */
      _destroyDragAndDrop() {
        if (this.hammer) {
          if (this.hammer.offNative)
            this.hammer.offNative();

          this.hammer.off("panstart panleft panright panend swipeleft swiperight");
          this.hammer.destroy();
          this.hammer = null;
        }
      },
      /**
       * Check if the slide with index should be shown.
       *
       * @param {Number} slideIndex - The index of slide to check.
       */
      _slideIsShown(slideIndex) {
        return this.type === "infinite"
          || this.optimization === false
          || Math.abs(slideIndex - this._getIndexByOffset(this.offset)) <= Math.round(this.additionalVisibleSlides / 2);
      },
      _getCarouselHeight(){
        if (this.height === "fixed")
          return null;

        let availableHeight =  [].map.call(this.$el.querySelectorAll(".easyscreen-carousel--item"), (carouselElement) => {
          return getStyle(carouselElement.children[0], "height");
        });
        availableHeight = Math.max.apply(null, availableHeight);

        return availableHeight + "px";
      },
      _updateCarouselHeight(){
        this.currentCarouselHeight = this._getCarouselHeight();
      }
    },
    created() {
      this._updateCarouselHeight = this._updateCarouselHeight.bind(this);
    },
    mounted() {
      if (this.swipeOnly) {
        this._initSwipeDragAndDrop();
      } else {
        this._initSmoothDragAndDrop();
      }

      /* setTimeout require for waiting css style load */
      if (this.height === "content-aware"){
        setTimeout(this._updateCarouselHeight, 200);
        window.addEventListener("resize",  this._updateCarouselHeight);
      }
    },
    beforeDestroy() {
      this._destroyDragAndDrop();
      window.removeEventListener("resize",  this._updateCarouselHeight);
    },
    components: {
      "get-component": GetComponent
    }
  };
</script>
