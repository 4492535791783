var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal-confirm',{ref:"modal",class:['search-facets-modal', _vm.orientation],attrs:{"cancel-options":{
      title: _vm._l10n('Clear all facets'),
      color: 'danger'
    },"position":_vm.position,"design":_vm.design,"min-width":"90%","min-height":"90%"},on:{"before-open":(event) => _vm.$emit('before-open', event),"opened":(event) => _vm.$emit('opened', event),"before-close":(event) => _vm.$emit('before-close', event),"closed":(event) => _vm.$emit('closed', event),"ok":() => { _vm.$emit('selected', _vm.selectedFacets) },"cancel":() => { _vm.reset(); _vm.$emit('selected', {}) }}},[_c('template',{slot:"header"},[_vm._t("title"),(_vm.withCloseControls)?_c('easyscreen-circle-button',{staticClass:"search-facets-modal--close-button_top-right",attrs:{"icon":"/images/es-menu/close_icon.png","icon-type":"image"},nativeOn:{"click":function($event){return _vm.hide.apply(null, arguments)}}}):_vm._e()],2),_c('template',{slot:"content"},[_c('scrollable',{key:_vm.design + _vm.orientation,attrs:{"max-height":(_vm._isLandscape() ? 810 : 1550) - (_vm.design === 'light' ? 120 : 0)}},[_c('div',{staticClass:"search-facets"},_vm._l((_vm.facets),function(facet){return _c('div',{key:facet.id,staticClass:"search-facets-list"},[_c('div',{staticClass:"search-facets-list--title"},[_c('span',[_vm._v(_vm._s(facet.name)+" ")]),(_vm._selectedAmount(facet.id) !== 0)?_c('span',[_vm._v("("+_vm._s(_vm._selectedAmount(facet.id))+")")]):_vm._e()]),_c('div',{staticClass:"search-facets-list--preview"},_vm._l((_vm._getFacetsForPreview(facet)),function(value){return _c('div',{key:value.value,staticClass:"search-facets-list--preview-element",on:{"click":(event) => {
                  /* Skip the click event by label (checkbox input). */
                  if (!event.defaultPrevented) {
                    _vm._toggleFacet(facet.id, value.value);
                  }
                }}},[_c('easyscreen-checkbox',{staticClass:"search-facets-list--preview-checkbox",attrs:{"design":_vm.design,"checked":_vm._isSelectedFacet(facet.id, value.value)}}),_c('span',{staticClass:"search-facets-list--preview-element_text"},[_vm._v(_vm._s(value.name))]),_c('span',{staticClass:"search-facets-list--preview-element_frequence"},[_vm._v("("+_vm._s(value.frequence)+")")])],1)}),0),_c('easyscreen-button',{class:{ 'border-disabled': _vm.design === 'light' },attrs:{"color":_vm.design === 'light' ? 'white' : 'gray',"corners":_vm.design === 'light' ? 'round' : undefined,"modificator":"full-width"},nativeOn:{"click":function($event){return (() => _vm.showSingleFacetModal(facet.id)).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm._l10n("Show more"))+" ")]),_c('div',{staticClass:"search-facets-list--delimiter"})],1)}),0)]),(_vm.withCloseControls)?_c('easyscreen-circle-button',{staticClass:"search-facets-modal--close-button_bottom-right",attrs:{"icon":"/images/es-menu/close_icon.png","icon-type":"image"},nativeOn:{"click":function($event){return _vm.hide.apply(null, arguments)}}}):_vm._e()],1)],2),(_vm.activeFacetId)?_c('search-single-facet',{key:_vm.activeFacetId,ref:"singleFacetModal",attrs:{"name":_vm._getActiveFacet().name || '',"facet":_vm._getActiveFacet().values || [],"selected-default":_vm.selectedFacets[_vm.activeFacetId] || [],"position":_vm.position,"design":_vm.design},on:{"opened":(event) => _vm.$emit('modal-opened', _vm.$refs.singleFacetModal.hide, 'singleFacet'),"closed":(event) => {
      _vm.$emit('closed-inner-modal', 'singleFacet')
      _vm.standaloneSingleFacet = false;
    },"selected":(selected) => {
      _vm.selectedFacets[_vm.activeFacetId] = selected;
      if (_vm.standaloneSingleFacet) {
        _vm.$emit('selected', _vm.selectedFacets);
      } else {
        _vm.$forceUpdate();
      }
    }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }