/**
 * Get the parent document node using filter function.
 * The parent will be found when the filter will return `true`;
 *
 * @param {HTMLElement} node - The element from which checking will be stated.
 * @param {Function} filter - The filter function with following format: `(parent<HTMLElement>) => { return <Boolean> }`.
 *
 * @returns {(null|HTMLElement)} The found parent or null.
 */
export function parentNodeByFilter(node, filter) {
  var parent = node && node.parentNode;
  if (parent && parent !== document.body.parentNode && parent.parentNode) {
    if (filter(parent)) {
      return parent;
    } else {
      return parentNodeByFilter(parent, filter);
    }
  } else {
    return null;
  }
}

/**
 * The constructor of filter for html element by class.
 * Will match the single class in html element.
 *
 * @param {String} _class - The class for match.
 *
 * @returns {Function<HTMLElement>} - The filter of html elements wihtout passed class.
 */
export function classFilter(_class) {
  return (node) => node && node.classList && node.classList.contains(_class);
}

/**
 * The constructor of filter for html element by direct match.
 * Will match the html element using the strict equality (===).
 *
 * @param {HTMLElement} htmlElement - The html element for match.
 *
 * @returns {Function<HTMLElement>} - The filter of html elements wihtout passed class.
 */
export function htmlElementFilter(htmlElement) {
  return (node) => node && node === htmlElement;
}

export default parentNodeByFilter;
