<template>
  <div :class="['patron-profile--loans', orientation]">
    <modal-layout>
      <template slot="header">
        <div class="patron-profile--common-title">
          <span class="patron-profile--common-title-text">
            {{ _l10n("Loans - See all your loans and renew then if needed") }}
          </span>

          <easyscreen-badge
            v-if="loans.length !== 0"
            :class="{ 'patron-profile--common-title-badge': _isPortrait() }"
            color="primary"
            type="circle"
          >
            {{ loans.length }}
          </easyscreen-badge>
        </div>
      </template>
      <template slot="content">
        <scrollable :max-height="_isLandscape() ? 465 : 1245" smooth-edge-color="#262626">
          <profile-material-info
            v-for="(loanInfo, index) in loansInfo"
            :key="`${ loanInfo.title }-${ index }`"
            :title="loanInfo.title"
            :sub-title="loanInfo.author"
            :attributes="loanInfo.attributes"
            :checked="loanInfo.checked"
            @input="(_checked) => { loanInfo.checked = _checked }"
          >
            <template v-if="loanInfo.renewable === false" slot="checkbox">
              <div class="patron-profile--loans-renew-warning text-center">
                <i class="patron-profile--loans-renew-warning-image esi esi-warning"></i>
                <span class="text-primary">{{ _l10n("Can't be renewed") }}</span>
              </div>
            </template>
          </profile-material-info>
        </scrollable>
      </template>
      <template slot="footer">
        <easyscreen-button-group gap="big">
          <easyscreen-button
            color="primary"
            modificator="fixed-width"
            @click.native="_renewPossible"
            :disabled="_renewableLoans().length === 0"
          >
            {{ _l10n("Renew all posible") }}

            ({{ _renewableLoans().length }}
            {{ _l10n("of") }}
            {{ loansInfo.length }})
          </easyscreen-button>
          <easyscreen-button
            color="primary"
            modificator="fixed-width"
            @click.native="_renewSelected"
            :disabled="_selectedLoans().length === 0"
          >
            {{ _l10n("Renew selected") }}

            ({{ _selectedLoans().length }})
          </easyscreen-button>
        </easyscreen-button-group>
      </template>
    </modal-layout>

    <loader ref="loader" />
    <modal-alert ref="alert" />
  </div>
</template>

<style lang="less" src="./common.less"></style>
<style lang="less" src="./loans.less"></style>
<style lang="less" src="../../../core/mixins.less"></style>
<style lang="less" src="../../../core/icons.less"></style>
<script>
  import ModalAlert from "../../../core/modal/alert.vue";
  import ModalLayout from "../../../core/modal/layout.vue";
  import Scrollable from "../../../core/scrollable/scrollable.vue";
  import Loader from "../../../core/loader/loader.vue";

  import EasyscreenButton from "../../../core/button/button.vue";
  import EasyscreenButtonGroup from "../../../core/button/button-group.vue";
  import EasyscreenBadge from "../../../core/badge/badge.vue";
  import ProfileMaterialInfo from "../parts/material-info.vue";

  import { get } from "lodash";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../../../core/mixins/orientation.js";
  import convertDateMixin from "../../../core/mixins/convert-date.js";

  export default {
    name: "patron-profile-loans",
    mixins: [orientationMixin, convertDateMixin],
    props: {
      /* The name of info-data provider, used for partially disable features on screen. */
      provider: String,
      /* List of raw user loans, see response on GET [lms]/[consumer-hash]/patron/loans */
      loans: {
        type: Array,
        default: () => ([])
      },
      /* The authentication user form data: cpr and pin. */
      formData: Object
    },
    data() {
      return {
        loansInfo: this._getLoansInfo()
      };
    },
    watch: {
      loans(newLoans) {
        this.loansInfo = this._getLoansInfo(newLoans);
      }
    },
    methods: {
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Get the selected loans.
       *
       * @returns {LoanInfo[]} The list of selected loans.
       */
      _selectedLoans() {
        return (this.loansInfo || []).filter(loan => loan.checked);
      },
      /**
       * Get the loans which can be renewed.
       *
       * @returns {LoanInfo[]} The list of renewable loans.
       */
      _renewableLoans() {
        return (this.loansInfo || []).filter(loan => loan.renewable);
      },
      /**
       * Converts the list of raw loans from lms into unified loan info.
       *
       * @param {Object[]} [loans=this.loans] - The list of raw loans.
       *
       * @returns {LoanInfo[]} List of converted loans.
       */
      _getLoansInfo(loans) {
        return (loans || this.loans || []).map(loan => {
          /**
           * Converted raw loan from lms into unified loan info.
           * @typedef {Object} LoanInfo
           *
           * @property {String} id - The loan id.
           * @property {String} title - Title of loaned material.
           * @property {String} author - Author of loaned material..
           * @property {Boolean} checked - Flag means loan is checked in GUI.
           * @property {String} renewable - Flag means if loan is renewable.
           * @property {Object[]} attributes - List of loan attributes.
           * @property {String} attributes[].label - Name of attribute.
           * @property {String} attributes[].value - Value of attribute.
           * @property {String} [attributes[].color] - Special color of attribute.
           */
          return {
            id: get(loan, "loanDetails.loanId"),
            title: get(loan, "loanDetails.material.title"),
            author: get(loan, "loanDetails.material.author"),
            checked: false,
            renewable: loan.isRenewable,
            attributes: [{
              label: l10n("Type"),
              value: get(loan, "loanDetails.material.type")
            }, {
              label: l10n("Material number"),
              value: get(loan, "loanDetails.materialItemNumber")
            }, {
              label: l10n("Date of loan"),
              value: this._convertDate(get(loan, "loanDetails.loanDate"))
            }, {
              label: l10n("Return before"),
              value: this._convertDate(get(loan, "loanDetails.dueDate")),
              color: "text-primary"
            }].filter(function(attribute) {
              return attribute.value;
            })
          };
        });
      },
      /**
       * Renew all possible loans.
       */
      _renewPossible() {
        this._renew(this._renewableLoans());
      },
      /**
       * Renew selected loans.
       */
      _renewSelected() {
        this._renew(this._selectedLoans());
      },
      /**
       * Renew loans by list.
       *
       * @param {LoanInfo[]} loans - List of loans for renew.
       */
      async _renew(loans) {
        this.$refs.loader.show();

        try {
          const result = await this.$easyscreenRequest.lmsConnector.renewLoan({
            user: this.formData.cpr,
            pin: this.formData.pin,
            loanId: loans.map(loan => loan.id).join(",")
          });

          this.$emit("loans-updated");

          let renewed = [];
          let nonRenewed = [];

          loans.forEach((loan) => {
            let key = loan.id;
            if (!(key in result)) {
              key = Object.keys(result).find(_key => _key.includes(loan.id));
            }

            if (get(result[key], "status") === true) {
              renewed.push(loan.title);
            } else {
              nonRenewed.push(loan.title);
            }
          });

          let message = "";
          if (renewed.length > 0) {
            message = `${ l10n("Renewed") }:<br/><b>${ renewed.map(v => " - " + v).join("<br/>") }</b>`;
          }

          if (nonRenewed.length > 0) {
            message = `${ l10n("Non Renewed") }:<br/><b>${ nonRenewed.map(v => " - " + v).join("<br/>") }</b>`;
          }

          this.$refs.loader.hide();
          this.$refs.alert.show({
            title: l10n("Success!"),
            messageHTML: message
          });
        } catch (error) {
          this.$refs.loader.hide();
          this.$refs.alert.show({
            title: l10n("Error"),
            message: l10n(error.message)
          });
        }
      }
    },
    components: {
      "modal-alert": ModalAlert,
      "modal-layout": ModalLayout,
      "scrollable": Scrollable,
      "loader": Loader,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-button-group": EasyscreenButtonGroup,
      "easyscreen-badge": EasyscreenBadge,
      "profile-material-info": ProfileMaterialInfo
    }
  };
</script>
