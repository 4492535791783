<template>
  <div class="material-promotion-carousel-multimple-view">
    <h1
      v-if="title"
      class="material-promotion-carousel-multimple-view--title"
    >
      {{ title }}
    </h1>
    <easyscreen-carousel
      ref="carousel"
      class="material-promotion-carousel-multimple-view--carousel"
      type="infinite"
      :layout-rows="1"
      :layout-columns="columns"
      :step="100 / columns"
      :position-offset="-100 / columns * 0.5 + 50"
      @before-position-change="(_, _followingSlideIndex) => {
        activeSlideIndex = _followingSlideIndex % elements.length;
        if (activeSlideIndex < 0) {
          activeSlideIndex = elements.length + activeSlideIndex;
        }
      }"
    >
      <div
        v-for="(element, elementIndex) in modifiedElements"
        :key="`element-${ element.id }`"
        :class="[
          'material-promotion-carousel-multimple-view--element-wrapper',
          { 'material-promotion-carousel-multimple-view--element_active': activeSlideIndex === elementIndex }
        ]"
      >
        <div class="material-promotion-carousel-multimple-view--element">
          <img
            class="material-promotion-carousel-multimple-view--element-image"
            draggable="false"
            :src="$easyscreenRequest.lmsConnector.wrapCover(element.cover)"
          >
          <div class="material-promotion-carousel-multimple-view--element-overlay"></div>
        </div>
      </div>
    </easyscreen-carousel>
    <div class="material-promotion-carousel-multimple-view--element-info">
      <template
        v-if="activeElement"
      >
        <h2 class="material-promotion-carousel-multimple-view--element-title">
          {{ activeElement.title }}
        </h2>
        <div class="material-promotion-carousel-multimple-view--element-meta">
          <span
            v-if="activeElement.author"
          >
            {{ activeElement.author }}
          </span>
          <span
            v-if="activeElement.year"
          >
            ({{ activeElement.year }})
          </span>
        </div>
        <div
          v-if="activeElement.description && withMetaInformation !== true"
          class="material-promotion-carousel-multimple-view--element-teaser"
        >
          {{ _cutText(activeElement.description, maxTeaserLength) }}
        </div>
        <div
          v-if="withMetaInformation === true"
          :class="[
            'material-promotion-carousel-multimple-view--element-teaser',
            'material-promotion-carousel-multimple-view--additional-element-meta'
          ]"
        >
          <meta-information-table :standalone="true" :metaPair="activeElement.metaOnly" />
          <meta-information-table :standalone="true" :metaPair="activeElement.placementsOnly" />
        </div>
      </template>
    </div>
  </div>
</template>

<style src="./material-promotion-carousel-multimple-view.less" lang="less"></style>

<script>
  import * as d3 from "d3-timer";
  import cutText from "@/lib/utils/cut-text.js";
  import materialPromotionMeta from "./material-promotion-meta.mixin.js";

  import EasyscreenCarousel from "../core/carousel/carousel.vue";
  import MetaInformationTable from "../materials-list/meta-information-table.vue";

  export default {
    name: "material-promotion-carousel-multimple-view",
    mixins: [materialPromotionMeta],
    props: {
      /* The title of promotion. */
      title: String,
      /**
       * Promotion element
       *
       * @typedef {Object} PromotionElement
       *
       * @property {Number} id - Element id (fasut number).
       * @property {Number} [cover="/images/defaultCover.jpg"] - Cover of the material.
       * @property {Number} title - Material title.
       * @property {Number} [author] - Material author.
       * @property {Number} [year] - Year of the publication.
       * @property {Number} [description] - Materail description.
       */
      /* The list of promotion elements (PromotionElement[]). */
      elements: Array,
      /* The duraion in ms of how long the every element will active. */
      animationSpeed: {
        type: Number,
        default: 5000
      },
      /* The amount of elements on the screen (first and last element will be shown at half). */
      columns: {
        type: Number,
        default: 6
      },
      /*
       * The maximum description\teaser length. Will be cutted by the closest punctuation
       * mark or the space under `Number` characters.
       */
      maxTeaserLength: {
        type: Number,
        default: 300
      },
      /* Display the material meta information and holdings instead of description. */
      withMetaInformation: Boolean
    },
    computed: {
      activeElement() {
        const activeElement = this.modifiedElements[this.activeSlideIndex];
        if (!activeElement)
          return null;

        return {
          ...activeElement,
          metaOnly: (activeElement.metaPair || []).filter(pair => pair.value != null),
          placementsOnly: (activeElement.metaPair || []).filter(pair => pair.placement != null).slice(0, 3)
        };
      }
    },
    watch: {
      /**
       * Update the list of elements at the scrollable area and reset the scroll.
       *
       * @param {PromotionElement[]} - The list of promotion elements.
       */
      elements (elements) {
        this.modifiedElements = elements;
        if (this._getHoldings) {
          this._getHoldings();
        }
      }
    },
    data() {
      return {
        activeSlideIndex: 0,
        modifiedElements: this.elements
      };
    },
    methods: {
      /* Proxy for `cutText` method. */
      _cutText: cutText,
      /**
       * Start the sliding animation.
       */
      _startAutoAnimation() {
        this._stopAutoAnimation();

        this._autoAnimationInterval = d3.interval(this.$refs.carousel.nextSlide, this.animationSpeed);
      },
      /**
       * Stop the sliding animation.
       */
      _stopAutoAnimation() {
        if (this._autoAnimationInterval) {
          this._autoAnimationInterval.stop();
          this._autoAnimationInterval = null;
        }
      }
    },
    async mounted() {
      this._startAutoAnimation();
    },
    beforeDestroy() {
      this._stopAutoAnimation();
    },
    components: {
      "easyscreen-carousel": EasyscreenCarousel,
      "meta-information-table": MetaInformationTable
    }
  };
</script>
