<template>
  <div class="materials-cart-button">
    <i class="esi esi-cart"></i>
    <easyscreen-badge
      class="materials-cart-button--badge"
      color="primary"
      type="circle"
    >
      {{ materials.length }}
    </easyscreen-badge>
  </div>
</template>

<style src="../core/icons.less" lang="less" ></style>
<style src="./materials-cart-button.less" lang="less"></style>

<script>
  import EasyscreenBadge from "../core/badge/badge.vue";

  export default {
    name: "materials-cart-button",
    computed: {
      materials: {
        get() {
          return this.$store.getters["materialsCart/materials"];
        }
      }
    },
    components: {
      "easyscreen-badge": EasyscreenBadge
    }
  }; 
</script>
