<template>
  <div :class="['easyscreen-select', `easyscreen-select_design-${ design }`, { 'easyscreen-select_disabled': disabled }]">
    <input
      :class="[
        'easyscreen-input',
        'easyscreen-select--preview',
        {
          focus: focused,
          disabled: disabled
        },
        inputClass
      ]"
      :value="selected && (selected.label || selected)"
      autocomplete="off"
      readonly
      @click="_showEditForm"
    />
    <i :class="['fal fa-chevron-down easyscreen-select--chevron', chevronClass]"></i>

    <div
      :class="[
        'easyscreen-select--option',
        'easyscreen-select--option_selected',
        !optionStep && !activeOptionColor ? 'easyscreen-select--option_transparent' : 'easyscreen-select--option_hidden'
      ]"
      ref="exampleOption"
    >
      <span class="easyscreen-select--option-text" ref="exampleOptionText"></span>
    </div>
    <div
      :class="[
        'easyscreen-select--option',
        !defaultOptionColor ? 'easyscreen-select--option_transparent' : 'easyscreen-select--option_hidden'
      ]"
    >
      <span class="easyscreen-select--option-text" ref="exampleDefaultOptionText"></span>
    </div>

    <modal-confirm
      class="easyscreen-select--modal"
      ref="modal"
      min-width="1040px"
      :design="design"

      @opened="() => {
        _initDragAndDrop();
        $refs.swipeExample && $refs.swipeExample.show();
      }"
      @before-close="() => {
        _destroyDragAndDrop();
        _cleanupSavedSelection();
        $refs.swipeExample && $refs.swipeExample.hide();
      }"
      @before-open="_saveSelected"
      @ok="() => {
        if (_savedSelectedIndex !== options.indexOf(selected)) {
          $emit('selected', selected)
        }
      }"
      @cancel="_restoreSelected"
    >
      <template slot="header">{{ label }}</template>
      <template slot="content">
        <swipe-example ref="swipeExample" class="easyscreen-select--swipe-example" :duration="1100" />
        <div class="easyscreen-select--smooth-bottom-top"></div>
        <div class="easyscreen-select--options-wrapper" ref="optionsWrapper">
          <div class="easyscreen-select--options" :style="{ top: -selectOffset }">
            <div
              class="easyscreen-select--option"
              v-for="(option, index) in options"
              :key="`${ option.value || option }-${ index }`"
              @click="() => { if (_selectionByClickAllowed()) { selectByIndex(index) } }"
            >
              <span class="easyscreen-select--option-text" :style="{ color: _getOptionColor(index) }">
                {{ option.label || option }}
              </span>
            </div>
          </div>
        </div>
        <div class="easyscreen-select--smooth-bottom-edge"></div>
      </template>
    </modal-confirm>
  </div>
</template>

<style lang="less" src="./input.less"></style>
<style lang="less" src="./select.less"></style>
<style lang="less" src="../transition.less"></style>

<script>
  import Hammer from "hammerjs";
  import colorLerp from "color-lerp";

  import ModalConfirm from "../modal/confirm.vue";
  import SwipeExample from "../swipe-example/swipe-example.vue";

  import clampNumber from "@/lib/utils/clamp-number.js";
  import debounceDrop from "@/lib/utils/debounce-drop.js";
  import getStyle from "@/lib/utils/get-style.js";
  import Animate from "@/lib/utils/animate.js";
  import orientationMixin from "../mixins/orientation.js";

  export default {
    name: "easyscreen-select",
    mixins: [orientationMixin],
    props: {
      /* The custom class of input field (<input class="easyscreen-select--preview">) */
      inputClass: [String, Object, Array],
      /* The custom class of input field (<i class="easyscreen-select--chevron">) */
      chevronClass: [String, Object, Array],
      /* Label of select field */
      label: {
        type: String
      },
      /* List of possible values in format: "option" or { label: "", value: "" }  */
      options: {
        type: Array,
        required: true
      },
      /* Value of select */
      value: {
        type: [String, Number, Object],
        default: ""
      },
      /* Sets the select focused when true. */
      focused: {
        type: Boolean,
        default: false
      },
      /* Sets the select readonly when true. */
      readonly: {
        type: [Boolean, String],
        default: false
      },
      /* Sets the select disabled when true. */
      disabled: {
        type: [Boolean, String],
        default: false
      },
      /* The global reskin. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    data() {
      return {
        selected: null,
        selectOffset: 0,
        optionTopOffset: null,
        optionHeight: null,
        optionBottomOffset: null,
        optionStep: null,
        activeOptionColor: null,
        defaultOptionColor: null
      };
    },
    methods: {
      /**
       * Set selected by value.
       * @fires easyscreen-select#selected
       *
       * @param {(String || Object)} value - The option or option value.
       */
      select(value) {
        this.selectByIndex(this.options.findIndex(option => option.value === value || option === value));
      },
      /**
       * Set selected by index.
       * @fires easyscreen-select#selected
       *
       * @param {Number} index - The index of selected option, index should be in rage of options list.
       */
      selectByIndex(index) {
        if (index === this.options.indexOf(this.selected)) {
          return;
        }

        if (this.options[index]) {
          this.selected = this.options[index];

          if (this._modalShown) {
            this._scrollTo(index * this.optionStep);
          } else {
            this.selectOffset = index * this.optionStep;
          }

          /**
           * Selected option event.
           *
           * @event easyscreen-select#selected
           * @type {*} The selected option
           */
          this.$emit("selected", this.selected);
        }
      },
      /**
       * Open the modal with vertical carousel of options.
       */
      _showEditForm() {
        if (this.readonly || this.disabled) {
          return;
        }

        this.$refs.modal.show();
      },
      /**
       * Set selected based on carousel offset.
       */
      _setSelectedByOffset() {
        let selectedIndex = Math.round(this.selectOffset / this.optionStep);
        if (this.options[selectedIndex]) {
          this.selected = this.options[selectedIndex];
        }
      },
      /**
       * Get the color of option based on position in carousel.
       * The option color is mixing with primary, where mixing coefficient
       * how close the option to vertical center of carousel.
       *
       * @param {Number} optionIndex - The option index in the options list.
       *
       * @returns {String} The color in hex format.
       */
      _getOptionColor(optionIndex) {
        if (this.optionStep === null) {
          return;
        }

        let selectedIndex = this.selectOffset / this.optionStep;
        let decimalIndex = selectedIndex - Math.floor(selectedIndex);
        let floorIndex = Math.floor(selectedIndex);
        let ceilIndex = Math.ceil(selectedIndex);

        if (floorIndex === ceilIndex && floorIndex === optionIndex) {
          return this.activeOptionColor;
        } else if (ceilIndex === optionIndex) {
          decimalIndex = 1 - decimalIndex;
        } else if (floorIndex !== optionIndex) {
          decimalIndex = -1;
        }

        return this.optionColorLerp[Math.floor(decimalIndex * 100)] || this.defaultOptionColor;
      },
      /**
       * Initialize the select.
       */
      _initSelect() {
        this._setOptionHeight();
        this._getOptionColors();
      },
      /**
       * Set the option styles in edit modal based on example option
       * outside of modal (example option are not visible for users).
       */
      _setOptionHeight() {
        if (!this.$refs.exampleOption) {
          return;
        }

        let optionNode = this.$refs.exampleOption;
        let joinStyles = (styles) => {
          return styles.reduce((sum, styleName) => sum + getStyle(optionNode, styleName), 0);
        };

        this.optionTopOffset = joinStyles(["margin-top", "padding-top"]);
        this.optionBottomOffset = joinStyles(["margin-bottom", "padding-bottom"]);
        this.optionHeight = getStyle(optionNode, "height");
        this.optionStep = this.optionTopOffset + this.optionHeight;
      },
      /**
       * Get the default, active colors and discrete gradient between it.
       * Gradient contains the 100 steps.
       */
      _getOptionColors() {
        if (this.$refs.exampleOptionText) {
          this.activeOptionColor = getStyle(this.$refs.exampleOptionText, "color", { raw: true });
        }

        if (this.$refs.exampleDefaultOptionText) {
          this.defaultOptionColor = getStyle(this.$refs.exampleDefaultOptionText, "color", { raw: true });
        }

        if (this.activeOptionColor && this.defaultOptionColor) {
          this.optionColorLerp = colorLerp(this.activeOptionColor, this.defaultOptionColor, 100, "hex");
        }
      },
      /**
       * Round the current offset of carousel to one of closest option.
       */
      _roundSelectOffset() {
        let roudedIndex = Math.round(this.selectOffset / this.optionStep);
        this._scrollTo(roudedIndex * this.optionStep);
      },
      /**
       * Set the offset of carousel.
       *
       * @param {Number} offset - The total offset of carousel.
       */
      async _scrollTo(offset) {
        if (this._selectOffsetTransition) {
          this._selectOffsetTransition.stop();
        }

        this._selectOffsetTransition = new Animate({
          easingName: "easeOutQuad",
          from: { selectOffset: this.selectOffset },
          to: { selectOffset: offset },
          duration: 300,
          tic: ({ selectOffset }) => {
            this.selectOffset = selectOffset;
          },
          done: () => {
            this._selectOffsetTransition = null;
          }
        });

        return this._selectOffsetTransition.start();
      },
      /**
       * Get the maximum offset of carousel.
       *
       * @returns {} .
       */
      _getScrollLimit() {
        return this.optionStep * (this.options.length - 1);
      },
      /**
       * Initialize the drag and drop for select the value on selectection modal.
       */
      _initDragAndDrop() {
        this._destroyDragAndDrop();
        this._modalShown = true;

        this._hammerInstance = new Hammer(this.$refs.optionsWrapper);
        this._hammerInstance.get("pan").set({ direction: Hammer.DIRECTION_VERTICAL });

        let startY;
        this._hammerInstance.on("panstart", (e) => {
          if (this._selectOffsetTransition) {
            this._selectOffsetTransition.stop();
          }

          startY = e.center.y;
        });
        this._hammerInstance.on("panup pandown panend", debounceDrop((e) => {
          let delta = this._applyScreenScale(e.center.y - startY);
          let offset = this.selectOffset;
          offset = clampNumber(offset - delta, 0, this._getScrollLimit());

          startY = e.center.y;
          this.selectOffset = offset;
          this._setSelectedByOffset();
        }, 16)); // Timeout for events due to next animation frame (the number of calls is usually 60 times per second).
        this._hammerInstance.on("panend", () => {
          this._endOfHammerDrag = Date.now();
          this._roundSelectOffset();
          this._setSelectedByOffset();
        });
      },
      /**
       * Destroy the drag and drop handling.
       */
      _destroyDragAndDrop() {
        this._modalShown = false;

        if (this._hammerInstance) {
          this._hammerInstance.destroy();
        }

        if (this._selectOffsetTransition) {
          this._selectOffsetTransition.stop();
          this._selectOffsetTransition = null;
        }
      },
      /**
       * Get the flag if the selection by click to option is enabled.
       * The selection will be disabled in a short amount of time after when dragging is fninished.
       *
       * @returns {Boolean} State of the selection by click.
       */
      _selectionByClickAllowed () {
        return !this._endOfHammerDrag || Date.now() - this._endOfHammerDrag > 50;
      },
      /**
       * Removed the selection which has saved by "this._saveSelected".
       * Used for recovery previously selected value in case when "cancel" is clicked on selection model.
       */
      _cleanupSavedSelection() {
        this._savedSelectedIndex = null;
      },
      /**
       * Save the current selection.
       * Used for recovery previously selected value in case when "cancel" is clicked on selection model.
       */
      _saveSelected() {
        this._savedSelectedIndex = this.options.findIndex(option => option.value === (this.selected && this.selected.value));
      },
      /**
       * Restore the saved selected value.
       * @fires easyscreen-select#selected
       */
      _restoreSelected() {
        this.selectByIndex(this._savedSelectedIndex);
        this._cleanupSavedSelection();
      }
    },
    created() {
      let activeIndex = this.options.findIndex(option => option.selected);
      if (this.value !== "") {
        activeIndex = this.options.findIndex(option => option.value == this.value || option == this.value);
      }

      if (activeIndex === -1) {
        activeIndex = 0;
      }

      this.selectByIndex(activeIndex);
    },
    mounted() {
      this._initSelect();
      this.selectOffset = this.options.indexOf(this.selected) * this.optionStep;
    },
    beforeDestroy() {
      this._destroyDragAndDrop();
    },
    components: {
      "modal-confirm": ModalConfirm,
      "swipe-example": SwipeExample
    }
  };
</script>
