<template>
  <div class="easyscreen-accordion">
    <div
      v-for="(element, elementIndex) in elements"
      :key="element.key || `element-index-${ elementIndex }`"
      :class="[
        'easyscreen-accordion--element',
        { 'easyscreen-accordion--element_active': elementIndex === activeElementIndex }
      ]"
      @click="() => { selectIndex(elementIndex) }"
    >
      <div class="easyscreen-accordion--element-title">
        <slot name="title" :element="element">
          {{ element.title }}
        </slot>
      </div>
      <div
        class="easyscreen-accordion--element-content-wrapper"
        :style="{
          backgroundImage: element.image ? `url(${ element.image })` : null,
          height: elementIndex === activeElementIndex ? activeContentHeight : null
        }"
        @click="$emit('expand-element', element, elementIndex)"
      >
        <div class="easyscreen-accordion--element-overlay"></div>
        <div class="easyscreen-accordion--element-content">
          <slot name="content" :element="element">
            {{ element.content }}
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./accordion.less" lang="less"></style>

<script>
  import * as d3 from "d3-timer";
  import fitNumber from "@/lib/utils/fit-number.js";
  import asyncTimeout from "@/lib/utils/async-timeout.js";
  import getStyle from "@/lib/utils/get-style.js";
  import htmlElementHeight from "@/lib/utils/html-element-height.js";
  import orientationMixin from "../mixins/orientation.js";

  export default {
    name: "easyscreen-accordion",
    mixins: [orientationMixin],
    props: {
      elements: Array,
      /* The amount of milliseconds when single element is active. */
      animationDuration: {
        type: Number,
        default: 5000
      },
      /* Flag for enable the autoamimation on standby. */
      autoAnimation: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        activeElementIndex: 0,
        activeContentHeight: 0
      };
    },
    methods: {
      /**
       * Select the element by index. First in case when the index bigger than elements amount and last if less than 0.
       *
       * @param {Number} index - The element index.
       */
      selectIndex(index) {
        this.activeElementIndex = fitNumber(index, {
          lt: 0,
          value: this.elements.length - 1
        }, {
          gt: this.elements.length - 1,
          value: 0
        });
      },
      /**
       * Select next element. Or first in case when the active element is last.
       */
      selectNext() {
        this.selectIndex(this.activeElementIndex + 1);
      },
      /**
       * Select previous element. Or last in case when the active element is first.
       */
      selectPrevious() {
        this.selectIndex(this.activeElementIndex - 1);
      },
      /**
       * Set the size for content area of active element based on titles of other elements.
       */
      _updateActiveContentHeight() {
        const elementsHeight = [].map.call(this.$el.children || [], (node) => {
          const titleHeight = htmlElementHeight(node.querySelector(".easyscreen-accordion--element-title"));
          const elementGap =
            getStyle(node, "margin-top") +
            getStyle(node, "padding-top") +
            getStyle(node, "padding-bottom") +
            getStyle(node, "margin-bottom");

          return (titleHeight + elementGap) + "px";
        });

        this.activeContentHeight = `calc(100% - ${ elementsHeight.join(" - ") })`;
      },
      /**
       * Starts the auto animation (select next silde) with `this.animationDuration` interval.
       */
      _startAutoAnimation() {
        this._stopAutoAnimation();

        this._autoAnimationInterval = d3.interval(this.selectNext, this.animationDuration);
      },
      /**
       * Stops the auto animation.
       */
      _stopAutoAnimation() {
        if (this._autoAnimationInterval) {
          this._autoAnimationInterval.stop();
          this._autoAnimationInterval = null;
        }
      }
    },
    async mounted() {
      await asyncTimeout(200);
      this._updateActiveContentHeight();

      this.$on("orientation-changed", this._updateActiveContentHeight);
    },
    screenStandby() {
      if (this.autoAnimation) {
        this._startAutoAnimation();
      }
    },
    screenActive() {
      this._stopAutoAnimation();
    },
    beforeDestroy() {
      this.$off("orientation-changed", this._updateActiveContentHeight);
    }
  };
</script>
