<template>
  <span class="tags-inline">
    <span
      v-for="tag in tags.slice(0, previewAmount)"
      :key="tag.title"
      class="tags-inline--item"
      @click="$emit('select', tag)"
    >
      {{ _cutText(tag.title, maxTitleLength) }}
    </span>

    <span
      v-if="useMore && tags.length > previewAmount"
      class="tags-inline--item tags-inline--more"
      @click="() => $emit('more')"
    >
      {{ _l10n("See more") }}
    </span>
  </span>
</template>

<style lang="less" src="./inline.less"></style>

<script>
  import cutText from "../../../lib/utils/cut-text.js";
  import l10n from "@/lib/localization/localization.js";

  export default {
    name: "tags-inline",
    props: {
      /* The list of tags. */
      tags: {
        type: Array,
        default: () => ([])
      },
      /* Amount of tags for inline view. */
      previewAmount: {
        type: Number,
        default: 5
      },
      /* Flag for enable more button when total amount of tags is more than preview. */
      useMore: {
        type: Boolean,
        default: true
      },
      /* The maximim title length. */
      maxTitleLength: {
        type: Number,
        default: 35
      }
    },
    methods: {
      /**
       * Proxy of the "cutText".
       * See description for "cutText" in file: "~/src/lib/utils/cut-text.js"
       */
      _cutText: cutText,
      /* Proxy for localization function. */
      _l10n: l10n
    }
  };
</script>
