import { render, staticRenderFns } from "./material-info.vue?vue&type=template&id=7b47317c"
import script from "./material-info.vue?vue&type=script&lang=js"
export * from "./material-info.vue?vue&type=script&lang=js"
import style0 from "../../../core/mixins.less?vue&type=style&index=0&prod&lang=less&external"
import style1 from "../../../core/form/grid.less?vue&type=style&index=1&prod&lang=less&external"
import style2 from "./material-info.less?vue&type=style&index=2&prod&lang=less&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports