<template>
  <div class="popular-searches-inline">
    <span
      v-if="popularSearches.length !== 0"
      class="popular-searches-inline--title"
    >
      {{ _l10n('Popular searches') }}:
    </span>
    <tags-inline
      :tags="popularSearches"
      :preview-amount="previewAmount"
      :use-more="_moreEnabled()"
      :max-title-length="maxTitleLength"
      @select="(tag) => $emit('select', tag)"
      @more="() => $refs.allSuggestions.show()"
    />

    <all-suggestion-popup
      ref="allSuggestions"
      :popular-searches="popularSearches"
      :full-type="fullType"
      :hide-button="hideButton"
    ></all-suggestion-popup>
  </div>
</template>

<style lang="less">
  .popular-searches-inline {
    &--title {
      font-size: 22px;
    }
  }
</style>

<script>
  import l10n from "@/lib/localization/localization.js";

  import TagsInline from "../core/tags/inline.vue";
  import AllSuggestionPopup from "./all-suggestion-popup.vue";

  import PopularSearchesMixin from "./popular-searches-mixin.js";

  export default {
    name: "popular-searches-inline",
    mixins: [PopularSearchesMixin],
    props: {
      /* Amount of searches on preview. Default: uses default value of "tags-inline". */
      previewAmount: Number,
      /* The maximim title length of single tag. Default uses default value of "tags-inline". */
      maxTitleLength: Number,
      /*
       * The type of full view:
       * - none - the full view disabled.
       * - cloud - the cloud view (tags-cloud)
       * - list - the list view (tags-list)
       */
      fullType: {
        type: String,
        default: "none",
        validator: _fullType => ["none", "cloud", "list"].includes(_fullType)
      },
      /*
       * The type of close button:
       * - home - The house icon and placement at left corner of header.
       * - close - The X icon and placement at right corner of header.
       */
      hideButton: {
        type: String,
        default: "home",
        validator: _hideButton => ["home", "close"].includes(_hideButton)
      }
    },
    methods: {
      /* Proxy for localization function. */
      _l10n: l10n,
      _moreEnabled() {
        return ["cloud", "list"].includes(this.fullType);
      }
    },
    mounted() {
      this.$once("fetched", () => {
        if (this.popularSearches.length !== 0) {
          this.$emit("ready");
        }
      });
    },
    components: {
      "tags-inline": TagsInline,
      "all-suggestion-popup": AllSuggestionPopup
    }
  };
</script>
