var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal-fullscreen',{ref:"modal",class:[
      'inspiration-scanner-carousel',
      `inspiration-scanner-carousel_design-${ _vm.design }`,
      _vm.orientation
    ],style:({ '--easyscreen-inspiration-scanner-carousel-special-color': _vm.design === 'light' ? _vm.specialColor : undefined }),attrs:{"color-scheme":_vm.design === 'classic' ? 'easyscreen-menu' : undefined,"position":_vm.position,"hide-empty-container":true,"auto-close":_vm.autoClose,"header-height":"150px"},on:{"before-open":(event) => _vm.$emit('before-open', event),"opened":(event) => _vm.$emit('opened', event),"before-close":(event) => _vm.$emit('before-close', event),"closed":(event) => { _vm.$emit('closed', event); }}},[(_vm.title)?_c('template',{slot:"header-center"},[_c('div',{staticClass:"inspiration-scanner-carousel--title"},[_vm._v(" "+_vm._s(_vm.title)+" ")])]):_vm._e(),_c('template',{slot:"header-right"},[(_vm.withNavigationControls)?_c('easyscreen-circle-button',{staticClass:"easyscreen-search-results--close-button",attrs:{"icon":"/images/es-menu/close_icon.png","icon-type":"image"},nativeOn:{"click":function($event){return _vm.hide.apply(null, arguments)}}}):_vm._e(),_c('span')],1),_c('template',{slot:"content"},[_c('div',{staticClass:"inspiration-scanner-carousel--content"},[_c('easyscreen-carousel',{ref:"carousel",staticClass:"inspiration-scanner-carousel--carousel",attrs:{"optimization":false,"layout-columns":_vm.layoutColumns,"layout-rows":_vm.layoutRows},on:{"before-position-change":(_, followingSlideIndex) => {
            _vm.activeSlideIndex = followingSlideIndex;
            _vm._updateSpecialColor();
          }}},_vm._l((_vm.elements),function(element,elementIndex){return _c('div',{key:element.id,staticClass:"inspiration-scanner-carousel--element",on:{"click":function($event){return _vm.$emit('selected', element, elementIndex)}}},[_c('div',{staticClass:"inspiration-scanner-carousel--element-cover"},[_c('img',{staticClass:"inspiration-scanner-carousel--element-image",attrs:{"draggable":"false","src":_vm.$easyscreenRequest.lmsConnector.wrapCover(element.cover),"crossOrigin":"annonimus"}}),_c('div',{staticClass:"inspiration-scanner-carousel--element-type"},[_vm._v(" "+_vm._s(element.type)+" ")])]),_c('div',{staticClass:"inspiration-scanner-carousel--element-title"},[_vm._v(" "+_vm._s(element.title)+" ")]),_c('div',{staticClass:"inspiration-scanner-carousel--element-library"},[_vm._v(" "+_vm._s(element.library)+" ")]),_c('div',{staticClass:"inspiration-scanner-carousel--element-placement"},[_vm._v(" "+_vm._s(element.placement)+" ")])])}),0),_c('div',{staticClass:"inspiration-scanner-carousel--navigation"},_vm._l((_vm.slidesAmount),function(slideNumber){return _c('div',{key:`slide-number-${ slideNumber }`,class:[
              'inspiration-scanner-carousel--navigation-point',
              { 'inspiration-scanner-carousel--navigation-point_active': slideNumber - 1 === _vm.activeSlideIndex }
            ],on:{"click":() => _vm.$refs.carousel.selectByIndex(slideNumber - 1)}})}),0)],1)])],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }