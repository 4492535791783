var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal-confirm',{ref:"modal",class:['patron-authentication', `patron-authentication_design-${ _vm.design }`],attrs:{"ok-title":_vm.okTitle || _vm.$l10n('Login'),"cancel-title":_vm.cancelTitle || _vm.$l10n('Cancel'),"design":_vm.design,"min-width":"1040px"},on:{"before-open":(event) => _vm.$emit('before-open', event),"opened":(event) => _vm.$emit('opened', event),"before-close":(event) => _vm.$emit('before-close', event),"ok":_vm.authenticate,"closed":(event) => {
      _vm.selectedType = '';
      _vm.$emit('closed', event);
    }}},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm.title || _vm.$l10n('Login to profile'))+" ")]),_c('template',{slot:"content"},[_c('div',{style:({
        width: '1000px',
        height: ['fullsize', 'combined'].includes(_vm._getAuthenticationType()) ? null : '550px'
      })},[_c('authentication-form',{ref:"form",attrs:{"type":_vm._getAuthenticationType(),"message":_vm.message,"with-pickup-select":_vm.type === 'auto-reservation',"with-barcode-scanner":_vm.withBarcodeScanner,"require-password-for-barcode-scanner":_vm.requirePasswordForBarcodeScanner,"design":_vm.design},on:{"login":_vm.authenticate,"fully-filled":function($event){return _vm.authenticate(null, { type: $event })}}})],1)])],2),_c('modal-confirm',{ref:"authenticationTypeModal",attrs:{"ok-title":_vm.$l10n('Password'),"ok-options":{ color: 'secondary', style: { width: 477 }},"cancel-title":_vm.$l10n('Pin'),"cancel-options":{ style: { width: 477 }},"min-width":"1040px"}},[_c('template',{slot:"header"},[_vm._v(" "+_vm._s(_vm.$l10n('Select login option'))+" ")]),_c('template',{slot:"content"},[_vm._v(" "+_vm._s(_vm.$l10n('Please select how you want to login'))+" ")])],2),_c('modal-alert',{ref:"alert"}),_c('loader',{ref:"loader"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }