<template>
  <div
    :class="[
      'content-switcher',
      {
        'content-switcher_with-sound-control': withSoundControl,
        'content-switcher_with-pause-control': withPauseControl
      }
    ]"
  >
    <div class="content-switcher--content">
      <slot></slot>
    </div>
    <scrollable
      ref="scrollable"
      class="content-switcher--scrollable" max-height="100%"
      smooth-edge-color="var(--fill-color)"
    >
      <div class="content-switcher--navigation">
        <div
          ref="navigationElement"
          v-for="(option, optionIndex) in options"
          :key="_getOptionKey(option)"
          :class="[
            'content-switcher--navigation-element',
            { 'content-switcher--navigation-element_active': selectedIndex === optionIndex }
          ]"
          @click="() => select(optionIndex)"
        >
          <div
            class="content-switcher--navigation-element-image"
            :style="{
              backgroundImage: option.image ? `url('${ option.image }')` : null
            }"
          ></div>
          <h4 class="content-switcher--navigation-element-title">
            {{ option.title }}
          </h4>
          <div class="content-switcher--navigation-element-duration">
            {{ _humanizeDuration(option.duration) }}
          </div>
        </div>
      </div>
    </scrollable>
    <div class="content-switcher--controls">
      <easyscreen-progress-bar
        class="content-switcher--progressbar"
        :from="0"
        :to="currentDuration"
        :progress="elapsedTime"
      />
      <div class="content-switcher--time-left">
        {{ timeLeft }}
      </div>
      <div class="content-switcher--controls-group">
        <h2 class="content-switcher--title">
          {{ title }}
        </h2>

        <easyscreen-volume-control
          v-if="withSoundControl"
          ref="volumeControl"
          class="content-switcher--volume-control"
          :default-volume="defaultVolume"
          :default-muted="defaultMuted"
          @volume-changed="(volume) => $emit('volume-changed', volume)"
          @muted="(volume) => $emit('muted', volume)"
          @unmuted="(volume) => $emit('unmuted', volume)"
        />

        <i
          class="content-switcher--flow-control fal fa-step-backward"
          @click="selectPrevious()"
        ></i>
        <i
          v-if="withPauseControl"
          :class="[
            'content-switcher--flow-control',
            'fal',
            state === 'playing' ? 'fa-pause' : 'fa-play'
          ]"
          @click="togglePlayPause()"
        ></i>
        <i
          class="content-switcher--flow-control fal fa-step-forward"
          @click="selectNext()"
        ></i>
      </div>
    </div>
  </div>
</template>

<style src="./content-switcher.less" lang="less"></style>

<script>
  import { get } from "lodash";
  import * as d3 from "d3-timer";
  import parseDuration from "parse-duration";
  import fitNumber from "@/lib/utils/fit-number.js";

  import Scrollable from "../core/scrollable/scrollable.vue";
  import EasyscreenProgressBar from "../core/progress-bar/progress-bar.vue";
  import EasyscreenVolumeControl from "../core/volume-control/volume-control.vue";

  const playIntervalTic = parseDuration("16ms");
  /**
   * The event fires when the new slide must be selected.
   *
   * @event content-switcher#selected
   * @type {Number} slideIndex - The index of selected slide.
   */

  /**
   * The event fires when the timer (progress bar) has been started.
   *
   * @event content-switcher#playing
   */

  /**
   * The event fires when the timer (progress bar) has been paused.
   *
   * @event content-switcher#paused
   */

  /**
   * The event fires when the timer (progress bar) has been stopped.
   *
   * @event content-switcher#stopped
   */

  /**
   * The event fires when the timer (progress bar) has been ended.
   *
   * @event content-switcher#ended
   */

  /**
   * The event fires when the volume of content has been changed by user actions or programmatically.
   *
   * @event content-switcher#volume-changed
   * @type {Number} - Volume level in range 0-100.
   */

  /**
   * The event fires when the volume of content has been muted by user actions or programmatically.
   *
   * @event content-switcher#muted
   */

  /**
   * The event fires when the volume of content has been unmuted by user actions or programmatically.
   *
   * @event content-switcher#unmuted
   */
  export default {
    name: "content-switcher",
    props: {
      /* The list of content previews. */
      options: Array,
      /* The index of content which selected by default. (default: 0)  */
      defaultSelected: {
        type: Number,
        default: 0
      },
      /* The default volume of content active content. (default: 50)  */
      defaultVolume: {
        type: Number,
        default: 50
      },
      /* Defines if the content is muted by default. (default: false)  */
      defaultMuted: {
        type: Boolean,
        default: false
      },
      /* Allow the user change the content volume level. (default: true)  */
      withSoundControl: {
        type: Boolean,
        default: true
      },
      /* Allow the user play\pause\select the content. (default: true)  */
      withPauseControl: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      /**
       * Getter of current content preview based on selected index (`this.selectedIndex`).
       * @returns {Object} [currentElement={}] - The content preview (one elment which passed as `this.options` property).
       * @returns {String} [currentElement.title] - The content title.
       * @returns {String} [currentElement.image] - The content preview image.
       * @returns {Number} [currentElement.duration] - The content duration.
       * @returns {Boolean} [currentElement.autoplay] - The autoplay flag.
       */
      currentElement() {
        return get(this.options, `[${ this.selectedIndex }]`, {});
      },
      /**
       * Getter of title for current content preview based on selected index (`this.selectedIndex`).
       * @returns {String} The content title (equal to `currentElement.title || ""`).
       */
      title() {
        return get(this.options, `[${ this.selectedIndex }].title`, "");
      },
      /**
       * Getter of duration for current content preview based on selected index (`this.selectedIndex`).
       * @returns {Number} The content duration (equal to `currentElement.duration || 0`).
       */
      currentDuration() {
        return get(this.options, `[${ this.selectedIndex }].duration`, 0);
      },
      /**
       * Getter of human readable left time for playing content.
       * @returns {String} The left time in format "mm:ss".
       */
      timeLeft() {
        return this._humanizeDuration(this.currentDuration - this.elapsedTime);
      }
    },
    data() {
      return {
        selectedIndex: this.defaultSelected,
        /* The state might be: `initialization`, `playing`, `paused`, `stopped`, `ended`. */
        state: "initialization",
        elapsedTime: 0
      };
    },
    methods: {
      /**
       * Fires event to select slide with `index` and restarts the timer (progress bar).
       * @fires content-switcher#selected
       *
       * @param {Number} index - The index of slide which should be selected.
       */
      async select(index) {
        if (index === this.selectedIndex)
          return;

        this.elapsedTime = 0;
        this.selectedIndex = fitNumber(index, {
          lt: 0,
          value: this.options.length - 1
        }, {
          gt: this.options.length - 1,
          value: 0
        });
        await this.$nextTick();
        this._scrollToActiveNavigationElement();
        this.play();

        this.$emit("selected", this.selectedIndex);
      },
      /**
       * Select next slide. Or first in case when the active slide is last.
       */
      selectNext() {
        this.select(this.selectedIndex + 1);
      },
      /**
       * Select previous slide. Or last in case when the active slide is first.
       */
      selectPrevious() {
        this.select(this.selectedIndex - 1);
      },
      /**
       * Toggle play based on component state.
       */
      togglePlayPause() {
        if (this.state === "playing") {
          this.pause();
        } else {
          this.play();
        }
      },
      /**
       * Starts the timer (progress bar). Do nothing if already playing.
       * @fires content-switcher#playing
       */
      play() {
        if (this.state === "playing") {
          return;
        }

        this._resetPlayInterval();
        this._playInterval = d3.interval(() => {
          this.elapsedTime += playIntervalTic;

          if (this.elapsedTime >= this.currentDuration) {
            this.end();
            this.selectNext();
          }
        }, playIntervalTic);
        this.state = "playing";
        this.$emit("playing");
      },
      /**
       * Pause the timer (progress bar) and fires pause event. Do nothing if already paused.
       * @fires content-switcher#paused
       */
      pause() {
        if (this.state === "paused") {
          return;
        }

        this._resetPlayInterval();
        this.state = "paused";
        this.$emit("paused");
      },
      /**
       * Pause the timer (progress bar) and fires stop event. Do nothing if already paused.
       * @fires content-switcher#stopped
       */
      stop() {
        if (this.state === "stopped") {
          return;
        }

        this._resetPlayInterval();
        this.state = "stopped";
        this.$emit("stopped");
      },
      /**
       * Pause the timer (progress bar) and fires end event. Do nothing if already ended.
       * @fires content-switcher#ended
       */
      end() {
        if (this.state === "ended") {
          return;
        }

        this._resetPlayInterval();
        this.state = "ended";
        this.$emit("ended");
      },
      /**
       * Set the volume though the volume control component.
       * @fires content-switcher#volume-changed
       *
       * @param {Number} volume - The volume of active content. Must be in range 0-100. Note: 0 volume not equal to mute.
       */
      setVolume(volume) {
        if (!this.$refs.volumeControl) {
          return;
        }

        return this.$refs.volumeControl.setVolume(volume);
      },
      /**
       * Get the current volume from volume control component.
       *
       * @returns {Number} Volume level in range 0-100.
       */
      getVolume() {
        if (!this.$refs.volumeControl) {
          return;
        }

        return this.$refs.volumeControl.getVolume();
      },

      /**
       * Mutes the content though the volume control component.
       */
      mute() {
        if (!this.$refs.volumeControl) {
          return;
        }

        return this.$refs.volumeControl.mute();
      },
      /**
       * Unmutes the content though the volume control component.
       */
      unmute() {
        if (!this.$refs.volumeControl) {
          return;
        }

        return this.$refs.volumeControl.unmute();
      },
      /**
       * Call play if the autoplay is enabled for active content.
       */
      _autoplay() {
        if (this.currentElement.autoplay) {
          this.play();
        }
      },
      /**
       * Reset the interval which used for timer (progress bar).
       */
      _resetPlayInterval() {
        if (this._playInterval) {
          this._playInterval.stop();
          this._playInterval = null;
        }
      },
      /**
       * Get the string key based on `title`, `image` and `duration` of content.
       *
       * @param {Object} option - The content for which should be created key.
       * @param {String} [option.title] - The content title.
       * @param {String} [option.image] - The content preview image.
       * @param {Number} [option.duration] - The content duration.
       */
      _getOptionKey(option) {
        return option.title + option.image + option.duration;
      },
      /**
       * Converts the duration from milliseconds to human readable format: "mm:ss".
       *
       * @param {Number} - The duration in milliseconds.
       *
       * @returns {String} - The duration in human readable format: "mm:ss".
       */
      _humanizeDuration(duration) {
        duration = parseInt(duration / 1000, 10);
        const seconds = duration % 60;
        const minutes = (duration - seconds) / 60;

        return `${ minutes }:${ seconds < 10 ? "0" + seconds : seconds }`;
      },
      /**
       * Scroll the area with content previews to preview of active content.
       */
      async _scrollToActiveNavigationElement(options) {
        const activenavigationElement = this.$refs.navigationElement[this.selectedIndex];
        return this.$refs.scrollable && this.$refs.scrollable.scrollIntoView(activenavigationElement, options);
      }
    },
    mounted() {
      this._autoplay();
      this._scrollToActiveNavigationElement({ behavior: "instant" });
    },
    components: {
      "scrollable": Scrollable,
      "easyscreen-progress-bar": EasyscreenProgressBar,
      "easyscreen-volume-control": EasyscreenVolumeControl
    }
  };
</script>
