<template>
  <modal-confirm
    ref="modal"
    min-width="1040px"

    @before-open="(event) => { dataValue = value || ''; $emit('before-open', event) }"
    @opened="(event) => $emit('opened', event)"
    @before-close="(event) => $emit('before-close', event)"
    @closed="(event) => { dataValue = ''; $emit('closed', event) }"

    :ok-title="okTitle"
    :cancel-title="cancelTitle"
    :min-height="minHeight"

    @ok="(e) => { e.value = dataValue; _reEmit('ok', e); }"
    @cancel="(e) => { e.value = value; _reEmit('cancel', e); }"
  >
    <template slot="content">
      <div :style="{ width: '855px', margin: '0 auto', textAlign: 'center' }">
        <easyscreen-text-input
          class="mt-2"
          :value="dataValue"
          :error="error || errorData"
          :style="Object.assign({ width: '100%' }, inputStyle || {})"
          @input="(value) => { dataValue = value }"
          ref="input"
        />
        <keyboard-numeric class="mt-4 mb-2" @key-click="_handleKeyboard"/>
      </div>
    </template>
  </modal-confirm>
</template>

<style lang="less" src="../../mixins.less"></style>
<style lang="less" src="../../input/input.less"></style>
<script>
  import ModalConfirm from "../confirm.vue";
  import EasyscreenTextInput from "../../input/input.vue";
  import KeyboardNumeric from "../../keyboard/numeric.vue";

  import reEmitMixin from "../../mixins/re-emit.js";

  export default {
    name: "modal-edit-pin",
    mixins: [reEmitMixin],
    props: {
      /* The initial value of number input. */
      value: [String, Number],
      /* The title of ok button. */
      okTitle: {},
      /* The title of canlce button. */
      cancelTitle: {},
      /* The min width of modal. */
      minWidth: {},
      /* The min height of modal. */
      minHeight: {},
      /* The input error message. */
      error: {},
      /* Custom styles of input. */
      inputStyle: Object
    },
    data() {
      return {
        errorData: "",
        dataValue: this.value || ""
      };
    },
    computed: {
      isShown: {
        cache: false,
        get() {
          return this.$refs.modal && this.$refs.modal.isShown;
        }
      }
    },
    methods: {
      /**
       * Show the current modal and set the value of input.
       *
       * @param {Object} [options] - Show options.
       * @param {String} [options.value] - The value of input.
       * @param {String} [options.title] - Title of modal, empty by default.
       * @param {Function} [options.callback] - The modal callback, (event, buttonType) => {}.
       *  Where `event` - the native click event, and `buttonType` one of: "ok", "cancel".
       */
      show(options) {
        this.$refs.modal.show(options);
        if ("value" in options) {
          this.dataValue = options.value;
        }
      },
      /**
       * Hide the current modal.
       */
      hide() {
        this.$refs.modal.hide();
      },
      /**
       * Show the error for 3s.
       *
       * @param {String} error - The error message.
       */
      showError(error) {
        this.errorData = error;
        setTimeout(() => {
          this.errorData = "";
        }, 3000);
      },
      /**
       * The handler of keyboard events.
       *
       * @param {String} key - The key symbol or special name.
       */
      _handleKeyboard(key) {
        KeyboardNumeric.defaultKeyboardHandler({
          value: {
            set: (value) => { this.dataValue = value; },
            get: () => this.dataValue
          }
        }, key);
      },
      /**
       * Set focus to hidden input of number field to get the physical keyboard working.
       */
      _focusInput() {
        this.$refs.input.focusTheHiddenInput();
      }
    },
    mounted() {
      this.$refs.modal.$on("opened", this._focusInput);
    },
    beforeDestroy() {
      this.$refs.modal.$off("opened", this._focusInput);
    },
    components: {
      "modal-confirm": ModalConfirm,
      "easyscreen-text-input": EasyscreenTextInput,
      "keyboard-numeric": KeyboardNumeric
    }
  };
</script>
