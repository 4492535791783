var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal-fullscreen',{ref:"modal",staticClass:"materials-scanner-scan--modal",attrs:{"colorScheme":"easyscreen-menu"},on:{"before-open":(event) => {
      _vm.$emit('before-open', event);
      _vm.shown = true;
    },"opened":(event) => _vm.$emit('opened', event),"before-close":(event) => {
      _vm.$emit('before-close', event);
      _vm.shown = false;
    },"closed":(event) => _vm.$emit('closed', event)}},[_c('template',{slot:"header-left"},[_c('easyscreen-circle-button',{attrs:{"icon":"/images/es-menu/home_icon.png","icon-type":"image"},nativeOn:{"click":function($event){return _vm.hide.apply(null, arguments)}}})],1),_c('template',{slot:"header-center"},[_c('h1',{staticClass:"easyscreen-header_1"},[_vm._v(" "+_vm._s(_vm._l10n("Scan"))+" ")]),_c('p',{staticClass:"easyscreen-text-2"},[_vm._v(_vm._s(_vm._l10n("Place a material on the scanner to see more info")))])]),_c('template',{slot:"content"},[_c('div',{staticClass:"materials-scanner-scan"},[_c('img',{staticClass:"materials-scanner-scan--image",attrs:{"src":_vm.scanImage}})])])],2),(_vm.selectedMaterial)?_c('materials-list',{ref:"materialsList",attrs:{"use-loan":true,"use-cache":false,"default-materials":_vm.selectedMaterialsList,"default-selected":_vm.selectedMaterial},on:{"select-tag":(tag) => _vm.$emit('find-by-tag', tag),"open-loan":() => {
      if (_vm.$refs.materialsList) {
        _vm.$refs.materialsList.hide();
      }

      _vm.$emit('open-loan');
    }}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }