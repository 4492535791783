<template>
  <patron-profile
    ref="profile"
    @closed="(event) => $emit('closed', event)"
  />
</template>

<script>
  import PatronProfile from "../../../patron/profile/profile.vue";

  export default {
    name: "easyscreen-menu-pay-profile-screen",
    methods: {
      /**
       * Open the profile debts screen.
       */
      show() {
        this.$refs.profile.authenticate("debts");
      },
      /**
       * Close the profile debts screen.
       */
      hide() {
        this.$refs.profile.hide();
      }
    },
    components: {
      "patron-profile": PatronProfile
    }
  };
</script>
