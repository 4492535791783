<template>
  <modal-fullscreen
    ref="modal"

    colorScheme="easyscreen-menu"

    @before-open="(event) => $emit('before-open', event)"
    @opened="(event) => $emit('opened', event)"
    @before-close="(event) => $emit('before-close', event)"
    @closed="(event) => $emit('closed', event)"

    class="easyscreen-menu--generic-screen"
  >
    <template slot="header-left">
      <easyscreen-circle-button
        icon="/images/es-menu/home_icon.png"
        icon-type="image"

        @click.native="hide"
      />
    </template>
    <template slot="header-center">
      <h1 class="easyscreen-header_1">
        {{ title }}
      </h1>
    </template>
    <template slot="content">
      <presentation-set
        :muteInfo="true"
        :slides="slides || []"
      />
    </template>
    <template slot="footer">
    </template>
  </modal-fullscreen>
</template>

<style lang="less" src="./generic.less"></style>

<script>
  import ModalFullscreen from "../../../core/modal/fullscreen.vue";
  import EasyscreenCircleButton from "../../../core/button/circle-button.vue";
  const PresentationSet = () => import("../../../presentation-set/presentation-set.vue");

  export default {
    name: "easyscreen-menu-generic-screen",
    props: {
      title: String,
      slides: Array,
      backgrounds: Array
    },
    methods: {
      /**
       * Open the map screen.
       */
      show() {
        this.$refs.modal.show();
      },
      /**
       * Close the map screen.
       */
      hide() {
        this.$refs.modal.hide();
      }
    },
    components: {
      "modal-fullscreen": ModalFullscreen,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "presentation-set": PresentationSet
    }
  };
</script>
