<template>
  <div class="node-list-carousel-single-detail-slide">
    <div
      class="node-list-carousel-single-detail-slide--header"
      :style="{
        backgroundImage: `url('${ teaserImage }')`
      }"
    >
      <h1
        ref="title"
        class="node-list-carousel-single-detail-slide--title"
        :style="{
          height: customTitleHeight
        }"
      >
        {{ title }}
      </h1>
    </div>
    <div class="node-list-carousel-single-detail-slide--content">
      <div
        v-if="isEvent"
        class="node-list-carousel-single-detail-slide--meta"
      >
        <div
          v-if="date"
          class="node-list-carousel-single-detail-slide--date"
        >
          <i class="esi esi-clock"></i>
          {{ formattedDate }}
        </div>
        <div
          v-if="location"
          class="node-list-carousel-single-detail-slide--location"
        >
          <i class="esi esi-map-marker"></i>
          {{ location }}
        </div>
        <div
          v-if="price"
          class="node-list-carousel-single-detail-slide--price"
        >
          <i class="esi esi-label"></i>
          {{ price }}
        </div>
      </div>

      <scrollable
        class="node-list-carousel-single-detail-slide--text ck-editor" max-height="100%"
        smooth-edge-color="var(--primary-fill-color)"
      >
        <div class="node-list-carousel-single-detail-slide--teaser" v-html="teaser"></div>
      </scrollable>
    </div>
  </div>
</template>

<style src="../../core/ck-editor.less" lang="less"></style>
<style src="../../core/icons.less" lang="less"></style>
<style src="./node-list-carousel-single-detail-slide.less" lang="less"></style>

<script>
  import moment from "moment";
  import htmlElementHeight from "@/lib/utils/html-element-height.js";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../../core/mixins/orientation.js";

  import Scrollable from "../../core/scrollable/scrollable.vue";

  export default {
    name: "node-list-carousel-single-detail-slide",
    mixins: [orientationMixin],
    props: {
      type: String,
      date: String,
      dateTo: String,
      location: String,
      price: [String, Number],
      bgImage: String,
      image: String,
      title: String,
      esTeaser: String,
      teaser: String,
      text: String,
      lead: String
    },
    computed: {
      isEvent() {
        return this.type === "event";
      },
      backgroundImage: {
        get() {
          return this.bgImage || this.image;
        }
      },
      momentDate() {
        const dateFrom = moment(this.date);
        const dateTo = moment(this.dateTo);
        let date = moment();

        if (date < dateFrom) {
          date = dateFrom;
        } else if (date > dateTo) {
          date = dateTo;
        }

        date.hour(dateFrom.hour());
        date.minute(dateFrom.minute());

        return date;
      },
      formattedDate: {
        get() {
          const date = this.momentDate;

          let formattedDate = date.format("D. MMMM");
          if (this.layout === "welcome-screen") {
            formattedDate = date.format("DD.MM.YYYY");
          }

          const timeFrom = date.format("HH.mm");
          const timeTo = moment(this.dateTo).format("HH.mm");

          if (timeFrom !== "00.00") {
            formattedDate += ` ${ l10n("Kl.") } ${ timeFrom }`;
            if (timeFrom !== timeTo) {
              formattedDate += ` - ${ timeTo }`;
            }
          }

          return formattedDate;
        }
      },
      teaserImage() {
        return this.image || this.bgImage;
      }
    },
    data() {
      return {
        defaultTitleHeight: null,
        customTitleHeight: null
      };
    },
    methods: {
      getDefaultTitleHeight() {
        if (this.defaultTitleHeight === null && this.$refs.title) {
          this.defaultTitleHeight = htmlElementHeight(this.$refs.title);
        }

        return this.defaultTitleHeight || 0;
      },
      setTitleHeight(height) {
        this.customTitleHeight = height;
      },
      _resetCustomTitleHeight() {
        this.customTitleHeight = null;
        this.defaultTitleHeight = null;
      }
    },
    mounted() {
      this.$on("before-orientation-change", this._resetCustomTitleHeight);
    },
    beforeDestroy() {
      this.$off("before-orientation-change", this._resetCustomTitleHeight);
    },
    components: {
      "scrollable": Scrollable
    }
  };
</script>
