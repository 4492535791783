/**
 * Removes the html from string.
 *
 * @param {String} html - The string with html.
 *
 * @returns {String} string with removed html tags.
 */
export default function HTMLToText(html) {
  let container = document.createElement("div");
  container.innerHTML = html;

  return container.innerText.replace(/[\n\r]+/g, "\n").replace(/&nbsp;/ig, " ").replace(/\s+/g, " ");
}
