/**
 * Checks is the image are loaded fine.
 *
 * @param {HTMLElement} image - The html image element for check.
 *
 * @returns {number} Result of checking: 0 - image still loading; -1 - image not loaded\broken; 1 - image is fine.
 */
export default function checkImageStatus(image) {
  if (!image) {
    return -1;
  }
  /*
   * During the onload event, IE correctly identifies any images that
   * weren’t downloaded as not complete. Others should too. Gecko-based
   * browsers act like NS4 in that they report this incorrectly.
   */
  if (!image.complete) {
    return 0;
  }

  /*
   * However, they do have two very useful properties: naturalWidth and
   * naturalHeight. These give the true size of the image. If it failed
   * to load, either of these should be zero.
   */
  if (image.naturalWidth === 0) {
    return -1;
  }

  /* No other way of checking: assume it’s ok. */
  return 1;
}
