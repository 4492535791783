<template>
  <div
    :class="[
      'easyscreen-search-results-light',
      {
        'easyscreen-search-results-light_dark-background': isSpecialColorDark,
        'easyscreen-search-results-light_medium-screen': isMediumScreen
      },
      orientation
    ]"
    :style="{ '--easyscreen-search-results-light-special-color': specialColor }"
  >
    <div class="easyscreen-search-results-light--wrapper">
      <modal-fullscreen
        ref="modal"

        @before-open="(event) => $emit('before-open', event)"
        @opened="(event) => $emit('opened', event)"
        @before-close="(event) => $emit('before-close', event)"
        @closed="(event) => {
          _reset();
          $emit('closed', event);
        }"

        :hide-empty-container="true"
        :position="position"
      >
        <template slot="content">
          <div class="easyscreen-search-results-light--content">
            <div class="easyscreen-search-results-light--carousel">
              <div v-if="$easyscreenConfig.get('enable.accessibility')">
                <easyscreen-circle-button
                  :class="[
                    'easyscreen-search-results-light--carousel-navigation',
                    'easyscreen-search-results-light--carousel-navigation_left',
                    { 'easyscreen-search-results-light--carousel-navigation_visible': leftCarouselNavigationButtonVisible }
                  ]"
                  icon="fal fa-chevron-left"
                  color="outline-custom"
                  :size="isMediumScreen ? 'medium' : 'big'"
                  :custom-color="isSpecialColorDark ? specialColor : specialColorWithOverlay"
                  :custom-active-color="isSpecialColorDark ? specialColorWithOverlay : specialColor"
                  @click.native="$refs.carousel.previousSlide()"
                />
              </div>
              <easyscreen-carousel
                ref="carousel"
                :layout-columns="layoutColumns"
                :layout-rows="layoutRows"
                @before-position-change="(_, followingSlideIndex) => {
                  _setSearchResultsSpecialColor(followingSlideIndex);
                  carouselSlideIndex = followingSlideIndex;
                  _preload();
                }"
              >
                <search-cover
                  class="easyscreen-search-results-light--cover"
                  ref="covers"
                  v-for="item in results"
                  :maxTitleLength="35"
                  :key="item.id"
                  :title="item.title"
                  :author="item.author"
                  :year="item.year"
                  :type="item.type"
                  :cover="item.cover"
                  :availability="_availabilityStatus(item)"
                  @click.native="() => _showMaterialView(item)"
                />
              </easyscreen-carousel>
              <div v-if="$easyscreenConfig.get('enable.accessibility')">
                <easyscreen-circle-button
                  :class="[
                    'easyscreen-search-results-light--carousel-navigation',
                    'easyscreen-search-results-light--carousel-navigation_right',
                    { 'easyscreen-search-results-light--carousel-navigation_visible': rightCarouselNavigationButtonVisible }
                  ]"
                  icon="fal fa-chevron-right"
                  color="outline-custom"
                  :size="isMediumScreen ? 'medium' : 'big'"
                  :custom-color="isSpecialColorDark ? specialColor : specialColorWithOverlay"
                  :custom-active-color="isSpecialColorDark ? specialColorWithOverlay : specialColor"
                  @click.native="$refs.carousel.nextSlide()"
                />
              </div>
            </div>

            <div :class="[
              'easyscreen-search-results-light--page-info',
              { 'easyscreen-search-results-light--page-info_shown-search-keyboard': searchKeyboardIsShown }
            ]">
              {{ _l10n('Showing') }}
              {{ _getShownRange() }}
              {{ _l10n('out of') }}
              {{ hits }}
              {{ _l10n('results') }}
              <i v-if="searchInProgress" class="fa fa-spinner fa-spin"></i>
            </div>

            <div :class="[
              'easyscreen-search-results-light--popular-searches',
              { 'easyscreen-search-results-light--popular-searches_active': searchKeyboardIsShown }
            ]">
              <div :class="[
                'easyscreen-search-results-light--popular-searches-wrapper',
                { 'easyscreen-search-results-light--popular-searches-wrapper_active': popularSearchesFound }
              ]">
                <popular-searches-list-with-covers
                  ref="popularSearches"
                  @select="(tag) => {
                    _hideSearchKeyboard();
                    _clarifySearch({ query: tag.title });
                  }"
                  @ready="() => {
                    popularSearchesFound = true;
                    if (searchKeyboardIsShown) {
                      _updateSpecialColor($refs.popularSearches.firstCoverDominantColor);
                    }
                  }"
                />
              </div>
            </div>
            <div :class="[
              'easyscreen-search-results-light--navigation',
              { 'easyscreen-search-results-light--navigation_active': searchKeyboardIsShown }
            ]">
              <search-form
                class="easyscreen-search-results-light--search-controls"
                ref="searchForm"
                :default-query="query"
                :default-sorting="sorting"
                :with-search-suggestions="!searchInProgress && searchKeyboardIsShown"
                :with-cursor="searchKeyboardIsShown"
                :special-color="specialColor"
                popular-searches="false"
                design="light"
                @sorting-selected="(_sorting) => {
                  if (searchKeyboardIsShown) {
                    return;
                  }

                  _clarifySearch({ sorting: _sorting.value });
                }"
                @find="(_query, _sorting) => {
                  _hideSearchKeyboard();
                  _clarifySearch({ query: _query, sorting: _sorting });
                }"
                @text-input-click="() => _showSearchKeyboard()"
              />

              <div class="easyscreen-search-results-light--facets-group">
                <button
                  v-if="availableOnlyButton"
                  class="easyscreen-search-results-light--facets-button"
                  @click="() => _clarifySearch({ availableOnly: !availableOnly })"
                >
                  <i :class="[
                    'fa',
                    'fa-check',
                    'easyscreen-search-results--facet-icon_circle-border',
                    { 'easyscreen-search-results--facet-icon_border-only': !availableOnly }
                  ]"></i>
                  <span class="easyscreen-search-results--facet-button-text">{{ _l10n('Available only') }}</span>
                </button>

                <button
                  v-if="shelfOnlyButton"
                  class="easyscreen-search-results-light--facets-button"
                  @click="() => _clarifySearch({ shelfOnly: !shelfOnly })"
                >
                  {{ _l10n('This shelf only') }}
                  <i :class="[
                    'fa',
                    'fa-check',
                    'easyscreen-search-results--facet-icon_circle-border',
                    { 'easyscreen-search-results--facet-icon_border-only': !shelfOnly }
                  ]"></i>
                </button>

                <button
                  class="easyscreen-search-results-light--facets-button"
                  v-for="previewFacet in _getPreviewFacets()"
                  :key="previewFacet.id"
                  @click="() => $refs.searchFacets.showSingleFacetModal(previewFacet.id, { standalone: true })"
                >
                  <i class="fal fa-arrow-right"></i>
                  <span
                    :class="[
                      'easyscreen-search-results--facet-button-text',
                      { 'easyscreen-search-results--facet-button-text_with-counter': (selectedFacets[previewFacet.id] || []).length !== 0 }
                    ]"
                  >
                    <span class="easyscreen-search-results--facet-button-text_label">
                      {{ previewFacet.name }}
                    </span>
                    <span
                      v-if="(selectedFacets[previewFacet.id] || []).length !== 0"
                      class="easyscreen-search-results--facet-button-text_counter"
                    >
                      ({{ (selectedFacets[previewFacet.id] || []).length }})
                    </span>
                  </span>
                </button>

                <button
                  v-if="(facets || []).length !== 0"
                  class="easyscreen-search-results-light--facets-button"
                  @click="() => $refs.searchFacets.show()"
                >
                  <i class="fal fa-arrow-right"></i>
                  <span>
                    {{ _l10n("Show more facets") }}
                    <span v-if="totalSelectedFacets !== 0"> ({{ totalSelectedFacets }})</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </modal-fullscreen>

      <search-facets
        class="search-facets-light"
        ref="searchFacets"
        design="light"
        :facets="facets || []"
        :selected-default="selectedFacets"
        :with-close-controls="false"
        :position="position"
        @opened="() => $emit('modal-opened', $refs.searchFacets.hide, 'searchResultsSearchFacets')"
        @closed="() => $emit('closed-inner-modal', 'searchResultsSearchFacets')"
        @modal-opened="(closeModal, type) => $emit('modal-opened', closeModal, 'searchResults' + type)"
        @closed-inner-modal="(type) => $emit('closed-inner-modal', 'searchResults' + type)"
        @selected="(_selectedFacets) => _clarifySearch({ facets: _selectedFacets })"
      >
        <template slot="title">
          <span>
            <span class="Search-facets--hits bold">{{ hits }}</span>&nbsp;
            <span class="bold">{{ _l10n('results') }}</span>&nbsp;
            {{ _l10n('for') }}&nbsp;
            “<span class="Search-facets--query">{{ query }}</span>”&nbsp;
            <span>{{ _l10n('with current facets').trim() }}</span>.
          </span>
        </template>
      </search-facets>

      <materials-list
        v-if="selectedMaterial"
        ref="materialsList"
        design="light"
        :use-cache="false"
        :default-materials="selectedMaterialsList"
        :default-selected="selectedMaterial"
        @select-tag="_findByTag"
        @opened="() => $emit('modal-opened', $refs.materialsList.hide, 'searchResultsMaterialsList')"
        @closed="() => $emit('closed-inner-modal', 'searchResultsMaterialsList')"
        @modal-opened="(closeModal, type) => $emit('modal-opened', closeModal, 'searchResults' + type)"
        @closed-inner-modal="(type) => $emit('closed-inner-modal', 'searchResults' + type)"
      />
    </div>
  </div>
</template>

<style src="./search-results-light.less" lang="less" />

<script>
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import PopularSearchesListWithCovers from "../popular-searches/list-with-covers.vue";
  import searchResultsOriginal from "./search-results.vue";
  import SearchCover from "./search-cover.vue";

  import { normal as normalColor } from "color-blend";
  import ColorMixer from "color-mixer";
  import resizeMixin from "../core/mixins/resize.js";

  const themeDataWrapperBackgroundColor = { r: 0, g: 0, b: 0, a: 0.2 };

  export default {
    name: "search-results-light",
    mixins: [searchResultsOriginal, resizeMixin],
    props: {
      defaultSpecialColor: {
        type: String,
        default: "#f7f7f7"
      }
    },
    computed: {
      isSpecialColorDark() {
        const hslColor = new ColorMixer.Color({ hex: this.specialColor }).hsl();
        if (hslColor[2] < 50) {
          return true;
        }

        return false;
      },
      specialColor() {
        return this.customSpecialColor || this.defaultSpecialColor;
      },
      specialColorWithOverlay() {
        let specialColor = new ColorMixer.Color({ hex: this.specialColor }).rgb();
        specialColor = { r: specialColor[0], g: specialColor[1], b: specialColor[2], a: 1 };

        const mixedSpecialColor = normalColor(specialColor, themeDataWrapperBackgroundColor);
        return new ColorMixer.Color({ rgb: [mixedSpecialColor.r, mixedSpecialColor.g, mixedSpecialColor.b] }).hex();
      },
      totalSelectedFacets() {
        if (!this.selectedFacets || Object.keys(this.selectedFacets) === 0)
          return 0;

        return Object.keys(this.selectedFacets).reduce((totalSelected, facetKey) => {
          return totalSelected + this.selectedFacets[facetKey].length;
        }, 0);
      },
      carouselSlidesAmount() {
        return Math.ceil(this.hits / (this.layoutColumns * this.layoutRows));
      },
      leftCarouselNavigationButtonVisible() {
        return this.carouselSlideIndex !== 0;
      },
      rightCarouselNavigationButtonVisible() {
        return this.carouselSlideIndex !== this.carouselSlidesAmount - 1;
      }
    },
    data() {
      return {
        customSpecialColor: null,
        previousSpecialColors: [],
        searchKeyboardIsShown: false,
        popularSearchesFound: false
      };
    },
    created() {
      this.super_findByTag = this._findByTag;
      this._findByTag = (tag) => {
        this.$refs.searchForm.setQuery(tag);
        this.super_findByTag(tag);
      };
    },
    methods: {
      _showSearchKeyboard() {
        if (this.searchKeyboardIsShown) {
          return;
        }

        this.searchKeyboardIsShown = true;
        this.$emit("modal-opened", this._hideSearchKeyboard, "searchResultsKeyboard");

        this._setPopularSearchesSpecialColor();
      },
      _hideSearchKeyboard() {
        if (!this.searchKeyboardIsShown) {
          return;
        }

        if (this.$refs.searchForm) {
          this.$refs.searchForm.setQuery(this.query);
        }

        this.searchKeyboardIsShown = false;
        this.$emit("closed-inner-modal", "searchResultsKeyboard");

        this._setSearchResultsSpecialColor();
      },
      _setPopularSearchesSpecialColor() {
        if (this.popularSearchesFound && this.$refs.popularSearches) {
          this._updateSpecialColor(this.$refs.popularSearches.firstCoverDominantColor);
        }
      },
      async _setSearchResultsSpecialColor(slideIndex) {
        await this.$nextTick();
        if (!this.$refs.carousel) {
          return;
        }

        if (slideIndex === undefined) {
          slideIndex = this.$refs.carousel.getActiveSlideIndex();
        }

        const sliceFrom = slideIndex * this.$refs.carousel.elementsPerSlide;
        const targetCovers = this.results.slice(sliceFrom, sliceFrom + this.$refs.carousel.elementsPerSlide).map(materialData => {
          return this.$refs.covers.find(cover => materialData.cover === cover.cover);
        }).filter(cover => {
          return cover && cover.cover && !cover.isDefaultCover;
        });

        if (targetCovers && targetCovers.length !== 0) {
          this._updateSpecialColor(targetCovers[0].dominantColor);
        }
      },
      async _updateSpecialColor(dominantColorOrPromise) {
        this.customSpecialColor = await dominantColorOrPromise;
      }
    },
    mounted() {
      this.store.on("search-end", this._setSearchResultsSpecialColor);
      if (this.isMediumScreen) {
        this.store.commit({
          type: "setState",
          layoutColumns: 5,
          layoutRows: 1
        });
      }
    },
    beforeDestroy() {
      this.store.off("search-end", this._setSearchResultsSpecialColor);
    },
    components: {
      "easyscreen-circle-button": EasyscreenCircleButton,
      "search-cover": SearchCover,
      "popular-searches-list-with-covers": PopularSearchesListWithCovers
    }
  };
</script>
