<template>
  <div
    :class="[
      'node-list-tab',
      `node-list-tab_design-${ design }`,
      `node-list-tab_scale-${ $easyscreenScale }`
    ]"
    :style="{ '--node-list-tab-special-color': design === 'light' ? specialColor : undefined }"
  >
    {{ /* The image is used for dominant color calculation. */ }}
    <div v-if="design === 'light'" ref="images">
      <img
        v-for="element in elements"
        :key="element.id"
        class="node-list-tab--image-reference"
        :data-image="element.image"
        :src="`/proxy?url=${ element.image }`"
        crossOrigin="annonimus"
      >
    </div>

    <easyscreen-tab
      :elements="elements"
      :animation-duration="animationDuration * 1000"
      :auto-animation="autoAnimation"
      :with-background="design !== 'light'"
      @expand-element="(_, elementIndex) => _expandElement(items[elementIndex])"
      @selected="(element, elementIndex) => {
        _updateSpecialColor(element);
        _getNavigationContentBridge(elementIndex);
      }"
    >
      <template slot="navigation">
        <div v-if="design === 'light'" class="node-list-tab--title">
          <i class="fal fa-arrow-down node-list-tab--title-icon"></i>
          {{ title }}
        </div>
      </template>
      <template v-slot:title="props">
        <div class="node-list-tab--navigation-content">
          <div v-if="design === 'light'" class="node-list-tab--navigation-overlay"></div>
          <div class="node-list-tab--navigation-title">
            {{ props.element.title }}
          </div>
          <div
            v-if="isEvent && props.element.date"
            class="node-list-tab--navigation-date"
          >
            {{ props.element.date }}
            <span
              v-if="design === 'light' && isEvent && props.element.location"
              class="node-list-tab--navigation-location"
            >
              {{ props.element.location }}
              <span
                v-if="props.element.price"
                class="node-list-tab--navigation-price"
              >
                , {{ props.element.price }}
              </span>
            </span>
          </div>
        </div>
      </template>
      <template v-slot:content="props">
        <div class="node-list-tab--navigation-element-bridge-mask"></div>
        <div
          ref="navigationElementBrige"
          v-if="design === 'light'"
          class="node-list-tab--navigation-element-bridge"
          :style="navigationContentBridgeStyle"
        ></div>
        <div v-if="design === 'light'" class="node-list-tab--content-overlay"></div>
        <div class="node-list-tab--element-content">
          <div
            v-if="design === 'light'"
            class="node-list-tab--image"
            :style="`background-image: url(/proxy?url=${ props.element.image })`"
          ></div>
          <div v-if="design !== 'light'" class="node-list-tab--element-title">
            {{ props.element.title }}
          </div>
          <div
            v-if="design !== 'light' && isEvent && props.element.date"
            class="node-list-tab--date"
          >
            {{ props.element.date }}
          </div>
          <div
            v-if="design !== 'light' && isEvent && props.element.location"
            class="node-list-tab--location"
          >
            {{ props.element.location }}
            <span
              v-if="props.element.price"
              class="node-list-tab--price"
            >
              , {{ props.element.price }}
            </span>
          </div>
          <div class="node-list-tab--teaser">
            {{ props.element.teaser }}
          </div>
        </div>
      </template>
    </easyscreen-tab>

    <modal-blank
      ref="modal"
      :click-to-close="true"
      :position="modalPosition"
      @before-open="$emit('modal-opened', $refs.modal.hide, 'libraryEventsListInfo')"
      @closed="$emit('closed-inner-modal', 'libraryEventsListInfo')"
      v-if="isEvent"
    >
      <div>
        <node-list-info
          v-if="activeElement"
          :design="design"

          :type="activeElement.type"
          :date="activeElement.date"
          :date-to="activeElement.dateTo"
          :location="activeElement.location"
          :price="activeElement.price"
          :image="activeElement.image"
          :title="activeElement.title"
          :es-teaser="activeElement.esTeaser"
          :teaser="activeElement.teaser"
          :text="activeElement.text"
          :lead="activeElement.lead"
        >
          <easyscreen-circle-button
            v-if="withModalControls"
            class="node-list-tab--close-button node-list-tab--close-button_top-right"
            icon="esi esi-times"
            :color="design === 'light' ? 'white' : undefined"
            :size="design === 'light' ? 'medium' : undefined"

            @click.native="$refs.modal.hide"
          />

          <easyscreen-circle-button
            v-if="withModalControls"
            class="node-list-tab--close-button node-list-tab--close-button_bottom-right"
            icon="esi esi-times"
            :color="design === 'light' ? 'white' : undefined"
            :size="design === 'light' ? 'medium' : undefined"

            @click.native="$refs.modal.hide"
          />
        </node-list-info>
      </div>
    </modal-blank>
  </div>
</template>

<style src="./node-list-tab.less" lang="less"></style>

<script>
  import moment from "moment";
  import { castArray } from "lodash";
  import * as d3 from "d3-timer";
  import l10n from "@/lib/localization/localization.js";
  import getDominantImageColor from "@/lib/utils/get-dominant-image-color.js";
  import orientationMixin from "../core/mixins/orientation.js";

  import ModalBlank from "../core/modal/blank.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import EasyscreenTab from "../core/tab/tab.vue";
  import NodeListInfo from "./node-list-info.vue";

  export default {
    name: "node-list-tab",
    mixins: [orientationMixin],
    props: {
      title: String,
      /* The list of events. */
      items: Array,
      /* The layout of view. */
      layout: {
        type: [String, Array],
        default: "six-elements",
        validator: _layout => castArray(_layout).reduce((options, option) => {
          return options.concat(option.split(" "));
        }, []).filter(Boolean).every(option => {
          return [
            "six-elements",
            "eight-elements",
            "ten-elements",
            "cinema",
            "for-game",
            "welcome-screen-modifer",
            "events",
            "news"
          ].includes(option);
        })
      },
      /* The duration of auto scroll till end of list. */
      animationDuration: {
        type: Number,
        default: 5
      },
      /* Flag for enable the autoamimation on standby. */
      autoAnimation: {
        type: Boolean,
        default: false
      },
      modalPosition: String,
      /* The global reskin for materials list. */
      design: {
        type: String,
        default: "classic",
        /*default: "light",*/
        validator: _design => ["classic", "light"].includes(_design)
      },
      fullStandalone: {
        type: Boolean,
        default: true
      },
      defaultSpecialColor: {
        type: String,
        default: "#27a19d"
      },
      withModalControls: {
        type: Boolean,
        default: true
      }
    },
    computed: {
      isEvent() {
        return this.layout.includes("events");
      },
      elements() {
        return this.items.map(item => {
          return {
            id: item.nid,
            title: item.title,
            date: this._getFormatterDate(item.date, item.dateTo),
            location: item.location,
            price: item.price,
            teaser: item.teaser,
            image: item.bgImage || item.image
          };
        });
      },
      specialColor() {
        return this.customSpecialColor || this.defaultSpecialColor;
      }
    },
    data() {
      return {
        activeElement: null,
        customSpecialColor: null,
        activeNavigationElementNode: null,
        navigationContentBridgeStyle: null,
        specialColors: {}
      };
    },
    methods: {
      /**
       * Get formatted event date in range `dateFrom` - `dateTo`.
       *
       * @param {String} dateFrom - The date from which the event starts.
       * @param {String} dateTo - The date when event ends.
       *
       * @returns {String} Date of event in range with format "D. MMMM Kl. HH.mm"
       * See format options here: https://momentjs.com/docs/#/displaying/
       */
      _getFormatterDate(dateFrom, dateTo) {
        const _dateFrom = moment(dateFrom);
        const _dateTo = moment(dateTo);
        let date = moment();

        if (date < _dateFrom) {
          date = _dateFrom;
        } else if (date > _dateTo) {
          date = _dateTo;
        }

        date.hour(_dateFrom.hour());
        date.minute(_dateFrom.minute());

        let formattedDate = date.format("D. MMMM");

        if (date.hour() !== 0 || date.minute() !== 0) {
          formattedDate += " " + l10n("Kl.") + " " + date.format("HH:mm");
        }

        return formattedDate;
      },
      /**
       * Open element info.
       *
       * @param {Object} element
       * @param {String} element.type - "event" or "news".
       * @param {String} element.date - From date of event.
       * @param {String} element.dateTo - End date of event.
       * @param {String} element.location - Event location.
       * @param {String} [element.price] - Event price
       * @param {String} element.image - Preview image for event.
       * @param {String} element.title - Event\News title.
       * @param {String} element.esTeaser - Event\News description (not used).
       * @param {String} element.teaser - Event\News description (not used).
       * @param {String} element.text - Event\News description (not used).
       * @param {String} element.lead - Event\News description.
       */
      _expandElement(element) {
        if (!this.$refs.modal) {
          return;
        }

        this.$easyscreenStatistic.openNodeList({
          nid: element.nid,
          title: element.title
        });

        this.activeElement = element;
        this.$refs.modal.show();
      },
      _getSpecialColors() {
        [].forEach.call(this.$refs.images.children, async (image) => {
          const imageUrl = image.getAttribute("data-image");
          const specialColor = getDominantImageColor(image);

          this.specialColors[imageUrl] = specialColor;
          this.specialColors[imageUrl] = await specialColor;
        });
      },
      async _updateSpecialColor(element) {
        if (this.design !== "light") {
          return;
        }

        const specialColor = this.specialColors[element.image];
        if (specialColor) {
          this.customSpecialColor = await specialColor;
        }
      },
      _getNavigationContentBridge(elementIndex) {
        if (this.fullStandalone === false) {
          return;
        }

        const navigationElements = [].slice.call(this.$el.querySelectorAll(".node-list-tab--navigation-content"));
        const activeNavigationElement = navigationElements[elementIndex];

        if (!activeNavigationElement) {
          return;
        }

        this.activeNavigationElementNode = activeNavigationElement;
        this._startAdjustBridgePosition();
      },
      _startAdjustBridgePosition() {
        this._stopBridgePositionAdjust();
        if (!this.activeNavigationElementNode || this.fullStandalone === false) {
          return;
        }

        this._adjustBridgePositionInterval = d3.interval(() => {
          const containerBoundingRect = this._applyParentsScaleToBoundings(this.$el, this.$el.getBoundingClientRect());
          const selectedElementBoundingRect = this._applyParentsScaleToBoundings(this.$el, this.activeNavigationElementNode.getBoundingClientRect());

          this.navigationContentBridgeStyle = {
            top: (selectedElementBoundingRect.top - containerBoundingRect.top) + "px",
            height: selectedElementBoundingRect.height + "px"
          };
        }, 16);
      },
      _stopBridgePositionAdjust() {
        if (this._adjustBridgePositionInterval) {
          this._adjustBridgePositionInterval.stop();
          this._adjustBridgePositionInterval = null;
        }
      }
    },
    mounted() {
      if (this.design === "light") {
        this._getSpecialColors();
        this._updateSpecialColor(this.elements[0]);
        this._getNavigationContentBridge(0);
      }
    },
    beforeDestroy() {
      this._stopBridgePositionAdjust();
    },
    components: {
      "modal-blank": ModalBlank,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "easyscreen-tab": EasyscreenTab,
      "node-list-info": NodeListInfo
    }
  };
</script>
