var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal-blank',{ref:"modal",class:['materials-list-modal', 'materials-list-modal_' + _vm.design, _vm.orientation],attrs:{"auto-close":_vm.autoClose,"min-width":"100%","min-height":"100%","position":_vm.modalPosition},on:{"before-open":(event) => _vm.$emit('before-open', event),"opened":(event) => {
      _vm.$emit('opened', event);
      _vm.$refs.swipeExample && _vm.$refs.swipeExample.show();
    },"before-close":(event) => {
      _vm.$emit('before-close', event);
      _vm.$refs.swipeExample && _vm.$refs.swipeExample.hide();
    },"closed":(event) => {
      _vm._emitInnerModalsClose();
      _vm.$emit('closed', event);
    }}},[_c('template',{slot:"raw"},[(_vm.useControls)?_c('easyscreen-circle-button',{staticClass:"materials-list--close-button materials-list--close-button_top-right",attrs:{"icon":"/images/es-menu/close_icon.png","icon-type":"image"},nativeOn:{"click":function($event){return _vm.hide.apply(null, arguments)}}}):_vm._e(),_c('easyscreen-carousel',{key:JSON.stringify(_vm.materials) + _vm.selected,ref:"carousel",staticClass:"materials-list",attrs:{"suspended":_vm.controls == false,"swipe-only":true,"layout-columns":1,"layout-rows":1,"defaultPosition":_vm._getMaterialIndex(_vm.selected) * -100,"additionalVisibleSlides":_vm.$easyscreenConfig.get('itemPopup.preload')},on:{"before-position-change":_vm._adjustNavigationButtons,"position-changed":(index) => _vm._sendMaterialOpenStatistic(_vm.materials[index])}},_vm._l((_vm.materials),function(material){return _c(_vm.materialDetailComponentName,{key:material,tag:"component",staticClass:"materials-list--element",attrs:{"id":material,"use-cache":_vm.useCache,"use-loan":_vm.useLoan,"use-suggested-lists":_vm.useSuggestedLists,"is-first-material":_vm._isFirstMaterial(material),"is-last-material":_vm._isLastMaterial(material)},on:{"next-material":() => _vm.nextMaterial(),"previous-material":() => _vm.previousMaterial(),"detail-loaded":(detail) => _vm._updateShortDetail(detail),"select-tag":(tag) => _vm.$emit('select-tag', tag),"open-loan":() => _vm.$emit('open-loan'),"suggested-material":(list, material) => {
            _vm.materials = list;
            _vm.selected = material;
          },"before-open-inner-modal":(closeModal, type) => {
            _vm.$emit('modal-opened', closeModal, type || 'materialsListInnerModal')
            _vm._addOpenedModal(type || 'materialsListInnerModal');
          },"closed-inner-modal":(type) => {
            _vm.$emit('closed-inner-modal', type || 'materialsListInnerModal');
            _vm._removeOpenedModal(type || 'materialsListInnerModal');
          }}})}),1),(!_vm.firstSelected && _vm.useControls && _vm.design !== 'light')?_c('easyscreen-button',{staticClass:"materials-list--navigation-button materials-list--navigation-button_previous",attrs:{"size":"medium"},nativeOn:{"click":function($event){return (() => _vm.previousMaterial()).apply(null, arguments)}}},[_c('i',{staticClass:"materials-list--navigation-button-icon fal fa-chevron-left"}),_vm._v(" "+_vm._s(_vm._l10n("Previous"))+" ")]):_vm._e(),(!_vm.lastSelected && _vm.useControls && _vm.design !== 'light')?_c('easyscreen-button',{staticClass:"materials-list--navigation-button materials-list--navigation-button_next",attrs:{"size":"medium"},nativeOn:{"click":function($event){return (() => _vm.nextMaterial()).apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm._l10n("Next"))+" "),_c('i',{staticClass:"materials-list--navigation-button-icon fal fa-chevron-right"})]):_vm._e(),(_vm.useControls)?_c('easyscreen-circle-button',{staticClass:"materials-list--close-button materials-list--close-button_bottom-right",attrs:{"icon":_vm.design === 'light' ? '/images/es-menu/close_icon_design-light.png' : '/images/es-menu/close_icon.png',"color":_vm.design === 'light' ? 'outline-black' : undefined,"icon-type":"image"},nativeOn:{"click":function($event){return _vm.hide.apply(null, arguments)}}}):_vm._e(),(_vm.design === 'light')?_c('swipe-example',{ref:"swipeExample",staticClass:"materials-list--swipe-example"}):_vm._e()],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }