<template>
  <div>
    <modal-fullscreen
      ref="modal"
      colorScheme="easyscreen-menu"
      class="easyscreen-materials-cart-modal"
      @before-open="(event) => $emit('before-open', event)"
      @opened="(event) => $emit('opened', event)"
      @before-close="(event) => $emit('before-close', event)"
      @closed="(event) => $emit('closed', event)"
      :hide-empty-container="true"
    >
      <template slot="header-center">
        <div class="easyscreen-materials-cart--header">
          {{ _l10n("Basket") }}
        </div>
      </template>
      <template slot="header-right">
        <easyscreen-circle-button
          icon="/images/es-menu/close_icon.png"
          icon-type="image"
          @click.native="hide"
        />
      </template>
      <template slot="content">
        <div class="easyscreen-materials-cart">
          <modal-layout>
            <template slot="content">
              <scrollable :max-height="_isLandscape() ? 666 : 1506" smooth-edge-color="#262626">
                <div v-for="(material, index) in materials" :key="material.id" :class="[
                  'easyscreen-materials-cart--item',
                  `easyscreen-materials-cart--item_${ _availabilityStatus(material) }`,
                  { 'easyscreen-materials-cart--item_reserved': material.isReserved }
                ]">
                  <div class="easyscreen-materials-cart--item-index">{{ index + 1 }}.</div>
                  <div class="easyscreen-materials-cart--item-title">
                    {{ material.detail.title }}
                    <span v-if="material.error" class="easyscreen-materials-cart--item-error">
                      ({{ material.errorMessage || material.error.message }})
                    </span>
                    <span v-if="material.isReserved" class="easyscreen-materials-cart--item-message">
                      ({{ _l10n("successfully reserved") }})
                    </span>
                  </div>
                  <div class="easyscreen-materials-cart--item-availability">
                    {{ _availabilityStatus(material) === "available" ? _l10n("AT HOME") : _l10n("LENT") }}
                  </div>
                  <div class="easyscreen-materials-cart--item-reservation-period">
                    <easyscreen-select
                      :label="_l10n('Interest period')"
                      :options="_getInterestPeriodOptions()"
                      :value="material.period"
                      :disabled="material.isReserved"
                      @selected="(value) => _setInterestPeriod(material, value)"
                    />
                  </div>
                  <div class="easyscreen-materials-cart--item-reservation-controls">
                    <easyscreen-button color="danger" @click.native="() => _removeMaterial(material)">
                      {{ _l10n("Remove") }}
                    </easyscreen-button>
                  </div>
                </div>
              </scrollable>
            </template>
            <template slot="footer">
              <easyscreen-button-group gap="big">
                <easyscreen-button
                  color="danger"
                  modificator="fixed-width"
                  :disabled="materials.length === 0"
                  @click.native="_removeAllMaterials"
                >
                  {{ _l10n("Remove any element") }}
                </easyscreen-button>
                <easyscreen-button
                  color="primary"
                  modificator="fixed-width"
                  :disabled="materials.length === 0"
                  @click.native="_openAuthenticationForm"
                >
                  {{ _l10n("Reserve all") }}
                </easyscreen-button>
              </easyscreen-button-group>
            </template>
          </modal-layout>
        </div>
      </template>
    </modal-fullscreen>
    <loader ref="loader" />
    <modal-alert ref="alert" />
    <modal-confirm ref="confirmModal" />
    <authentication-form
      ref="authenticationForm"
      type="auto-reservation"
      class="material-reservation"
      :title="_l10n('Reserve item')"
      :ok-title="_l10n('Reserve')"
      :message="_l10n('Please login with library account to make the reservation')"
      @authenticated="(_, formData) => {
        _reserveAllMaterials(formData);
      }"
    />
  </div>
</template>
<style src="./materials-cart.less" lang="less"></style>
<script>
  import Loader from "../core/loader/loader.vue";
  import ModalAlert from "../core/modal/alert.vue";
  import ModalConfirm from "../core/modal/confirm.vue";
  import ModalFullscreen from "../core/modal/fullscreen.vue";
  import ModalLayout from "../core/modal/layout.vue";
  import Scrollable from "../core/scrollable/scrollable.vue";
  import EasyscreenButton from "../core/button/button.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import EasyscreenButtonGroup from "../core/button/button-group.vue";
  import EasyscreenSelect from "../core/input/select.vue";
  import AuthenticationForm from "../patron/authentication/authentication.vue";

  import { fromPairs, omit } from "lodash";
  import moment from "moment";
  import l10n from "@/lib/localization/localization.js";
  import orientationMixin from "../core/mixins/orientation.js";
  import availabilityStatus from "../materials-list/availability-status.js";
  import reserveMaterialMixin from "../materials-list/reserve-material-mixin.js";

  export default {
    name: "easyscreen-materials-cart",
    mixins: [orientationMixin, reserveMaterialMixin],
    computed: {
      materials: {
        get() {
          return this.$store.getters["materialsCart/materials"];
        }
      }
    },
    methods: {
      /**
       * Show the current modal. The promise will be returned in case when callback is not provided.
       * @async
       *
       * @param {Function} [callback] - The show callback, will be called when modal is opened.
       */
      show(callback) {
        return this.$refs.modal.show(callback);
      },
      /**
       * Hide the current modal. The promise will be returned in case when callback is not provided.
       * @async
       *
       * @param {Function} [callback] - The hide callback, will be called when modal is closed.
       */
      hide(callback) {
        return this.$refs.modal.hide(callback);
      },
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Get the availability status of material by materal data.
       *
       * @param {Object} materialDetail - The detail of material (see full scheme at LMS route /[consumer-hash]/search);
       * @param {Boolean} materialDetail.availableOnline - The flag means online material.
       * @param {Object[]} materialDetail.holdings - The holdings of physical material;
       * @param {Number} materialDetail.holdings[].available - The amount of available materials.
       * @param {String} materialDetail.holdings[].branchId - The id of branch where material is available.
       * @param {String} materialDetail.holdings[].departmentId - The id of department where material is available.
       *
       * @returns {String} The type of availability. One of: unknown, available, not-available, available-online,
       * available-at-another-branch.
       */
      _availabilityStatus(material) {
        return availabilityStatus(Object.assign({ holdings: material.holdings }, material.detail));
      },
      /**
       * Get the interest period options.
       *
       * @returns {Object[]} options
       * @returns {String} options[].label
       * @returns {String} options[].value
       */
      _getInterestPeriodOptions() {
        return [7, 14, 21, 28, 56, 70].map(days => {
          let weeks = Math.ceil(days / 7);

          return {
            label: [
              weeks,
              weeks === 1 ? l10n("week") : l10n("weeks"),
              "-",
              days,
              days === 1 ? l10n("day") : l10n("days")
            ].join(" "),
            value: days
          };
        });
      },
      /**
       * Set interest period for material.
       *
       * @param {Object} material - Material data from `materialsCart` store.
       * @param {Object} period - interest period option.
       * @param {String} period[].value
       */
      _setInterestPeriod(material, period) {
        try {
          this.$store.dispatch("materialsCart/updateMaterial", Object.assign({}, material, { period: period.value }));
        } catch (error) {
          console.error(error);
        }
      },
      /**
       * Remove material from the cart by material data.
       *
       * @param {Object} material - Material data.
       * @param {String} material.id - Material id (faust number, assigned on lms side).
       */
      _removeMaterial(material) {
        this.$refs.confirmModal.show({
          title: l10n("Remove book"),
          message: l10n("Are you sure you want to remove this book?"),
          callback: (_, type) => {
            if (type === "ok") {
              try {
                this.$store.dispatch("materialsCart/removeMaterial", { reservableId: material.id });
              } catch (error) {
                console.error(error);
              }
            }
          }
        });
      },
      /**
       * Remove all materials from the materials cart.
       */
      _removeAllMaterials() {
        if (this.materials.length === 0) {
          return;
        }

        this.$refs.confirmModal.show({
          title: l10n("Reset cart"),
          message: l10n("Are you sure you want to reset cart?"),
          callback: (_, type) => {
            if (type === "ok") {
              try {
                this.$store.dispatch("materialsCart/removeAllMaterials");
              } catch (error) {
                console.error(error);
              }
            }
          }
        });
      },
      /**
       * Open the authentication form.
       * @async
       */
      _openAuthenticationForm() {
        if (this.materials.length === 0) {
          return;
        }

        if (this.$refs.authenticationForm) {
          return this.$refs.authenticationForm.show();
        }
      },
      /**
       * Reserve all materials from materials cart.
       *
       * @param {Object} options - reservation options.
       * @param {String} options.cpr - User cpr.
       * @param {String} options.pin - User pin.
       * @param {String} options.pickupPlacement
       * @param {String} options.pickupPlacement.value - the id of the pickup location.
       * @param {String} options.pickupPlacement.locationType - the type of pickup location: `branch` or `department`.
       */
      async _reserveAllMaterials(options) {
        if (this.$refs.authenticationForm) {
          this.$refs.authenticationForm.hide();
        }

        const reservations = this.materials.filter(material => !material.isReserved).map(material => {
          return {
            reservableId: material.id,
            from: moment().format("YYYY-MM-DD"),
            to: moment().add(material.period || 7, "days").format("YYYY-MM-DD")
          };
        });

        this.$refs.loader.show();

        var errors = {};
        try {
          await this._reserveMaterial({
            cpr: options.cpr,
            pin: options.pin,
            reservations: reservations,
            locationId: options.pickupPlacement.value,
            locationType: options.pickupPlacement.locationType
          });

          this.$refs.alert.show({
            title: l10n("Successful reservation"),
            message: l10n("All items reserved")
          });
        } catch (error) {
          errors = fromPairs(error.errors.map(error => [error.id, error]));

          this.$refs.alert.show({
            title: l10n("Reservation error"),
            message: l10n("Some items can't be reserved")
          });
        }

        this.materials.forEach(material => {
          if (errors[material.id]) {
            const errorMessage = errors[material.id].code === "ALREADY_RESERVED" ? "Item already reserved." : "Reservation error";
            this.$store.dispatch("materialsCart/updateMaterial", Object.assign({}, material, {
              error: errors[material.id],
              errorMessage
            }));
          } else {
            this.$store.dispatch("materialsCart/updateMaterial", Object.assign(
              {},
              omit(material, ["error", "errorMessage"]),
              {
                isReserved: true
              }
            ));
          }
        });

        return this.$refs.loader.hide();
      }
    },
    screenStandby() {
      this.$store.dispatch("materialsCart/removeAllMaterials");
    },
    components: {
      "loader": Loader,
      "modal-alert": ModalAlert,
      "modal-confirm": ModalConfirm,
      "modal-fullscreen": ModalFullscreen,
      "modal-layout": ModalLayout,
      "scrollable": Scrollable,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "easyscreen-button-group": EasyscreenButtonGroup,
      "easyscreen-select": EasyscreenSelect,
      "authentication-form": AuthenticationForm
    }
  };
</script>
