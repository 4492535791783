<template>
  <div :class="['keyboard-fullsize', `keyboard-fullsize_${ _caseModifer() }`, `keyboard-fullsize_design-${ design }`]">
    <div
      class="keyboard-fullsize--row"
      v-for="(keyboardRow, rowIndex) in _applyOverrides(dataLayout)" :key="`row-${ rowIndex }`"
    >
      <template v-for="keyOptions in keyboardRow">
        <span
          v-if="keyOptions.fn"
          v-html="keyOptions.fn(
            _getKeyPosition(rowIndex, keyOptions, keyboardRow),
            override[keyOptions.special] || {}
          )"
          :key="`key-${ _keyIndex(keyOptions) }`"
          @click="(event) => { _keyClick(keyOptions, event); }"
        ></span>
        <easyscreen-button
          v-else
          :class="['keyboard-fullsize--key', {
            'keyboard-fullsize--simple-key': typeof keyOptions === 'string',
            'keyboard-fullsize--disabled-key': keyOptions.disabled === true,
            [`keyboard-fullsize--special_${ keyOptions.special }`]: !!keyOptions.special
          }]"
          :color="design === 'light' ? 'white' : keyOptions.color || 'gray'"
          :active="(_isShift(keyOptions) && enabledShift) || (_isCapslock(keyOptions) && enabledCapslock)"
          :key="`key-${ _keyIndex(keyOptions) }`"
          :style="Object.assign(
            {},
            _getKeyPosition(rowIndex, keyOptions, keyboardRow),
            keyOptions.style
          )"
          @click.native="(event) => { _keyClick(keyOptions, event); }"
        >
          <span class="keyboard-fullsize--key-wrapper">
            <i
              v-if="keyOptions.icon && (keyOptions.icon.placement === 'left' || !keyOptions.icon.placement)"
              :class="['keyboard-fullsize--key-icon', 'keyboard-fullsize--key-icon_left', keyOptions.icon.className]"
              :style="keyOptions.icon.style"
            ></i>
            <span v-if="keyOptions.upperCase" class="keyboard-fullsize--upper-key">
              {{ keyOptions.upperCase }}
            </span>
            <span v-if="keyOptions.lowerCase" class="keyboard-fullsize--lower-key">
              {{ keyOptions.lowerCase }}
            </span>
            <span v-if="!keyOptions.lowerCase && !keyOptions.upperCase">
              {{ _keyLabel(keyOptions) }}
            </span>
            <i
              v-if="keyOptions.icon && (keyOptions.icon.placement === 'right')"
              :class="['keyboard-fullsize--key-icon', 'keyboard-fullsize--key-icon_right', keyOptions.icon.className]"
              :style="keyOptions.icon.style"
            ></i>
          </span>
        </easyscreen-button>
      </template>
    </div>
  </div>
</template>

<style lang="less" src="../mixins.less"></style>
<style lang="less" src="./fullsize.less"></style>
<script>
  import EasyscreenButton from "../button/button.vue";
  import danishLayout from "./fullsize-layout/danish.js";
  import romanianLayout from "./fullsize-layout/romanian.js";

  import { get, isFunction, merge, cloneDeep } from "lodash";
  import { joinAccentedSymbols, splitAccentedSymbols } from "./joinable-symbols.js";

  const layouts = {
    default: danishLayout,
    da: danishLayout,
    danish: danishLayout,
    ro: romanianLayout,
    romanian: romanianLayout
  };

  const KeyboardFullsize = {
    name: "keyboard-fullsize",
    props: {
      /*
       * The predefined keyboard layout:
       * - auto - Based on `this.$easyscreenConfig.fullsizeKeyboard` or `default` if that paramter are empty.
       * - default - The danish layout.
       * - da - Alias for "danish".
       * - danish - The danish layout.
       * - ro - Alias for "romanian".
       * - romanian - The romanian layout.
       */
      layout: {
        type: String,
        default: "auto",
        validator: _type => [
          "auto",
          "default",
          "da", "danish",
          "ro", "romanian"
        ].includes(_type)
      },
      /*
       * Keyboard override for following special keys: enter.
       * Defaults:
       *   enter:
       *   - label: l10n("Search")
       *   - fill: #43a09d
       *   - style: { border: "none", transform: "scale(1.05)", "z-index": 1, top: pos.top - 56, left: pos.left + 2, width: 109, height: 101 }
       *
       * @type {Object}
       * @property {Object} *
       * @property {Object} *.style - The css style.
       * @property {String} *.label -  The key label.
       * @property {String} *.fill - The color of filling the button in hex format.
       */
      override: {
        type: Object,
        default: () => ({})
      },
      /* The global reskin. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      },
      gap: {
        type: Number,
        default: 12
      }
    },
    data() {
      let activeLayout = this.layout;
      if (activeLayout === "auto") {
        activeLayout = this.$easyscreenConfig.fullsizeKeyboard || "default";
      }

      return {
        dataLayout: layouts[activeLayout](),
        enabledShift: false,
        enabledCapslock: false
      };
    },
    methods: {
      /**
       * The handler of native of click event for screen keyboard.
       * @fires keyboard-fullsize#key-click
       *
       * @param {(Object|String)} keyOptions - The options of special\combined key or key letter.
       * @param {String} keyOptions.lowerCase - The key letter for keyboard without case modificator (when keyboard in lower case).
       * @param {String} keyOptions.upperCase - The key letter for keyboard with the case modificator (when keyboard in upper case).
       * @param {String} keyOptions.label - The label of key.
       * @param {Event} event - The browser native click event.
       */
      _keyClick(keyOptions, event) {
        let key = keyOptions.special || this._keyLabel(keyOptions);
        if (key === "capslock") {
          this.enabledCapslock = !this.enabledCapslock;
          return;
        }

        if (key === "lshift" || key === "rshift") {
          this.enabledShift = !this.enabledShift;
          return;
        }

        if (key === "space") {
          key = " ";
        }

        this.enabledShift = false;
        /**
         * The key clicked event.
         *
         * @event keyboard-fullsize#key-click
         * @type {String + Event} - The key symbol or special name like "enter", tab and etc. + native click event.
         */
        this.$emit("key-click", key, event);
      },
      /**
       * Get the absolute key position from the keyboard container based on row and the symbol index in that row.
       *
       * @param {Number} rowIndex - The index of row.
       * @param {String} symbol - The symbol in row.
       * @param {String[]} row - The array with keys (see example in ./fullsize-layou/*.js).
       *
       * @returns {Object} postion - The position in px.
       * @returns {Number} postion.top - Offset from top in px.
       * @returns {Number} postion.left - Offset from left in px.
       */
      _getKeyPosition(rowIndex, symbol, row) {
        return {
          /* 58 - the default height (46) + gap between buttons (12) */
          top: (46 + this.gap) * rowIndex,
          left: row.slice(0, row.indexOf(symbol)).reduce((left, options) => {
            /* 46 - the default width; 12 - the gap between buttons. */
            return left + get(options, "style.width", 46) + this.gap;
          }, 0)
        };
      },
      /**
       * Returns the key string which can be used for "key" index of vue elements.
       *
       * @param {(Object|String)} keyOptions - The options of special\combined key or key letter.
       * @param {Object} keyOptions.lowerCase - The key letter for keyboard without case modificator (when keyboard in lower case).
       * @param {Object} keyOptions.special - The name of special key, like: enter, tab, space and etc.
       * @param {Object} keyOptions.label - The label of key.
       *
       * @returns {String} The key symbol, label or technical name.
       */
      _keyIndex(keyOptions) {
        return keyOptions.lowerCase || keyOptions.special || keyOptions.label || keyOptions;
      },
      /**
       * Get the keyboard case modificator based on state of shift and capslock keys.
       *
       * @returns {String} One of following modificator:
       *  - no-modificator - No modifications (lower case);
       *  - shift - The shift modification (total upper case, for any key);
       *  - capslock - The capslock modification (upper case for alphabet letters only);
       *  - capslock-shift - The capslock with shift modification (lower case for alphabet letters, and upper for other keys).
       */
      _caseModifer() {
        let keyModifer = "no-modificator";
        const onlyShift = this.enabledShift && !this.enabledCapslock;
        const onlyCapslock = !this.enabledShift && this.enabledCapslock;
        const bothModifers = this.enabledShift && this.enabledCapslock;

        if (onlyShift) {
          keyModifer = "shift";
        } else if (onlyCapslock) {
          /* Switch case only for string buttons - like on physical keyboard whey you pressing capslock. */
          keyModifer = "capslock";
        } else if (bothModifers) {
          keyModifer = "capslock-shift";
        }

        return keyModifer;
      },
      /**
       * Get the key label\value based on current case modifer.
       *
       * @param {(Object|String)} keyOptions - The options of special\combined key or key letter.
       * @param {String} keyOptions.lowerCase - The key letter for keyboard without case modificator (when keyboard in lower case).
       * @param {String} keyOptions.upperCase - The key letter for keyboard with the case modificator (when keyboard in upper case).
       * @param {String} keyOptions.label - The label of key.
       *
       * @returns {String} The key label\value.
       */
      _keyLabel(keyOptions) {
        let label;

        let hasCase = keyOptions.lowerCase && keyOptions.upperCase;
        let simpleKey = typeof keyOptions === "string";

        if (hasCase || simpleKey) {
          let keyModifer = this._caseModifer();
          if (hasCase) {
            label = ["shift", "capslock-shift"].includes(keyModifer) ? keyOptions.upperCase : keyOptions.lowerCase;
          } else {
            label = ["shift", "capslock"].includes(keyModifer) ? keyOptions.toUpperCase() : keyOptions;
          }
        } else {
          label = keyOptions.label;
        }

        return label;
      },
      /**
       * Check if the key is shift.
       *
       * @param {(Object|String)} keyOptions - The options of special\combined key or key letter.
       * @param {Object} keyOptions.special - The name of special key, like: enter, tab, space and etc.
       *
       * @returns {Boolean} `true` - The key is shift, `false` otherwise.
       */
      _isShift(keyOptions) {
        return keyOptions.special === "lshift" || keyOptions.special === "rshift";
      },
      /**
       * Check if the key is capslock.
       *
       * @param {(Object|String)} keyOptions - The options of special\combined key or key letter.
       * @param {Object} keyOptions.special - The name of special key, like: enter, tab, space and etc.
       *
       * @returns {Boolean} `true` - The key is capslock, `false` otherwise.
       */
      _isCapslock(keyOptions) {
        return keyOptions.special === "capslock";
      },
      _applyOverrides(layout) {
        return layout.map(row => {
          return row.map(keyOptions => {
            if (!keyOptions.special) {
              return keyOptions;
            }

            return merge(cloneDeep(keyOptions), this.override[keyOptions.special] || {});
          });
        });
      }
    },
    components: {
      "easyscreen-button": EasyscreenButton
    },
    /* Static methods. */
    /**
     * Remove the symbol from string. If the symbol which should be removed is joinable (accented)
     * then it will be splited and removed only one component of that.
     *
     * @param {String} string - The string where symbol should be removed.
     * @param {Number} index - Number of symbol for removing.
     *
     * @returns {String} The string with removed symbol.
     */
    removeAt(string, index) {
      if (index <= 0) {
        return string;
      }


      string = string.split("");
      let removed = string.splice(index - 1, 1)[0];
      string = string.join("");

      removed = splitAccentedSymbols(removed);
      if (removed.length === 2) {
        string = this.addAt(string, removed[0], index - 1);
      }

      return string;
    },
    /**
     * Add the symbol or substring to string at position.
     * If the updated string have valid combination of joinable (accented) symbol
     * then it will be merged in returned string.
     *
     * @param {String} string - String to update.
     * @param {String} substring - The symbol or substring to insertion.
     * @param {Number} index - Index in string where substring shuold be inserted.
     *
     * @returns {String} Updated string.
     */
    addAt(string, substring, index) {
      string = string || "";
      substring = substring || "";

      let before = { start: 0, end: index };
      let after = { start: index, end: string.length };

      let letterBefore = string.substring(index - 1, index);
      let joint = joinAccentedSymbols(letterBefore + substring);
      if (joint.length < substring.length + 1) {
        before.end -= 1;

        substring = joint;
      }

      return string.substring(before.start, before.end) + substring + string.substring(after.start, after.end);
    },
    /**
     * Remove the last symbol at string (helper for `removeAt`).
     *
     * @param {String} string - The string where symbol should be removed.
     *
     * @returns {String} The string with removed symbol.
     */
    removeLastSymbol(string) {
      return this.removeAt(string, (string || "").length);
    },
    /**
     * Add the symbol at the end of string (helper for `addAt`).
     *
     * @param {String} string - String to update.
     * @param {String} symbol - The symbol or substring to insertion.
     *
     * @returns {String} Updated string.
     */
    appendSymbol(string, symbol) {
      return this.addAt(string, symbol, (string || "").length);
    },
    /**
     * The default handler of keyboard events.
     * Can work with input or cahnge the passed value as string.
     *
     * @param {Object} options - The handler options.
     * @param {String} options.value - The name of value property for direct modification.
     * @param {Function} options.value.get - Method for get the input value.
     * @param {Function<String>} options.value.set - Method for set the input value.
     * @param {Object} options.cursor - The wrappers of cursor controls.
     * @param {Function} options.cursor.get - Method for get the cursor position in input.
     * @param {Function<Number>} options.cursor.set - Method for set the cursor position in input.
     * @param {Function} options.onUpdated - The callback which called after the recent value update (like $nextTick);
     *                                      Should working like a "once" event subscription.
     * @param {String} key - The key symbol or special name.
     *
     * @returns {(String|undefined)} The modified value or nothing.
     */
    defaultKeyboardHandler({ cursor, value, onUpdated }, key) {
      if (key === "enter") {
        return;
      }

      if (key === "◄" || key === "►") {
        if (cursor) {
          if (key === "◄") {
            cursor.set(cursor.get() - 1);
          } else if (key === "►") {
            cursor.set(cursor.get() + 1);
          }
        }

        return;
      }

      let currentValue = value.get() || "";
      let newValue = currentValue;
      if (cursor) {
        if (key === "backspace") {
          newValue = KeyboardFullsize.removeAt(currentValue, cursor.get());
        } else {
          newValue = KeyboardFullsize.addAt(currentValue, key, cursor.get());
        }
      } else {
        if (key === "backspace") {
          newValue = KeyboardFullsize.removeLastSymbol(currentValue);
        } else {
          newValue = KeyboardFullsize.appendSymbol(currentValue, key);
        }
      }

      value.set(newValue);
      if (isFunction(onUpdated) && cursor) {
        onUpdated(() => {
          let lengthDifference = newValue.length - currentValue.length;
          cursor.set(cursor.get() + lengthDifference);
        });
      }
    }
  };

  export default KeyboardFullsize;
</script>
