/**
 * @file
 * Shows the information about application stattus on initialization and list of supported query parameters.
 */
import * as querystring from "querystring";
const urlOptions = querystring.parse(window.location.href.split("#")[1]);

export default function(config) {
  if ("disableStartupInfo" in urlOptions) {
    return;
  }

  const features = [
    "availability",
    "holdings",
    "externalResources",
    "videosFromYT",
    "SSRating",
    "ebook",
    "search",
    "reservation",
    "norwayReservationKeyboard",
    "basket",
    "loan",
    "unloan",
    "suggestedLists",
    "popularSearches",
    "searchSuggestions",
    "wayfinder",
    "trailer",
    "qrCodes",
    "profile",
    "suggestedListsDefault",
    "cprScanner",
    "disableAlarmManipulation"
  ];

  let enabled = [];
  let disabled = [];
  if (config.enable) {
    features.forEach(function(feature) {
      if (config.enable[feature]) {
        enabled.push('"' + feature + '"');
      } else {
        disabled.push('"' + feature + '"');
      }
    });
  } else {
    console.warn("Object with features not exists. See: `config.enable`");
  }

  let skin = "";
  if (config.skin) {
    skin = '\nActive skin: "' + config.skin + '"';
  }

  let managerDomain = config.manager.domain;
  if (managerDomain[managerDomain.length - 1] === "/") {
    managerDomain = managerDomain.substring(0, managerDomain.length - 1);
  }

  console.debug(
    "Enabled features:\n",
    enabled.join("\n "),
    "\n\nDisabled features:\n",
    disabled.join("\n "),
    "\n",
    skin,
    "\nManager:",
    managerDomain + "/web",
    "\nLMS:",
    config.lmsConnector.domain + "/dashboard",
    "\nLMS covers:",
    config.lmsConnector.covers,
    '\nl10n: language "' + config.lmsConnector.l10n.language + '"',
    'project "' + config.lmsConnector.l10n.project + '"'
  );

  /*eslint-disable */
  console.debug(
    [
      "\nAvailable URL parameters:",
      "MAIN:",
      '  "id" or "screenId" - Unique string with screen id on manager side.',
      '  "skin" - Overrides for skin value in config file.',
      '  "pageId" - Unique number with screen index on manager side for preview.',
      '  "branchId" - String with branch id, used for showing book availability in selected branch.',
      '  "departmentId" - String or comma separated list with department id, used for showing book availability in selected deperment.',
      '  "fit" - Flag, fit content into viewport proportional.',
      '  "bodyBackground" - Any available css value for "background" property - should be serialized by "encodeURIComponent", spread this value to body.',
      '  "disableNavigation" - Flag, disable the bottom navigation for the digital shelf for the light design.',
      "DEV:",
      '  "run" - Custom js code for eval after page loaded.',
      '  "standBy" - Pending time in seconds for standby or "off" value for disabling. See format: https://www.npmjs.com/package/parse-duration or the number wihtout unit will be count as seconds.',
      '  "duration" - Duration in seconds for all presentation set slides.',
      '  "statistic" - Disabled manager statistic if value "off".',
      '  "activeSlide" - Slide index for start presentation set.',
      '  "fetchTime" - Time for send to manager for getting content. Format: hhmm.',
      '  "fetchTimeModifier" - The scale modificator of running time when "fetchTime" is used. Use 60 for count 1 min as 1h. 1 by default',
      '  "fetchDate" - Date for send to manager for getting content. Format: DD-MM-YYYY.',
      '  "withSwitcher" - Flag for enable slide switcher.',
      '  "withSearch" - Flag for enable search in PS slides.',
      '  "withScan" - Flag for enable old scanner.',
      '  "replaceMaterialId" - Flag for enable replacing material id from scanner application on available in sip2. Replaced "4140527802" on "5829213726" and "4936288591" on "5829213695".',
      '  "canvasSavePosition" - Flag for enable saving current canvas tranlation after page reload.',
      '  "canvasSaveScale" - Flag for enable saving current canvas scale after page reload.',
      '  "skipUpcoming" - Flag for skip upcoming in mobilesearch events.',
      '  "consumer" - Overrides for lms consumer in config file.',
      '  "lmsAPI" - Overrides for lms API url in config file.',
      '  "lmsCovers" - Overrides for lms covers url in config file.',
      '  "manager" - Overrides for manager url in config file.',
      '  "enableInfo" - Enable console.info.',
      '  "localization.language" - Overrige localization language from config file.',
      '  "localization.project" - Overrige localization project from config file.',
      '  "enableFeature" - Enable selected screen feature. One or few features in comma separated list.',
      '  "disableFeature" - Disable selected screen feature. One or few features in comma separated list.',
      '  "disableDigitalShelfPreload" - Disable preload of theme materials on digital shelf.',
      '  "disableQrShelfPreload" - Disable preload of theme materials on qr shelf.',
      '  "disableDSCache" - Disable cache of materials from digital shelf.',
      '  "withoutFullscreenButton" - Disable fullscreen button at top-right corver after page load.',
      '  "disableRequestsCache" - Disable cache for all ajax requests with a cache.',
      '  "disableStartupInfo" - Disable this info.',
      '  "disableLocalizationWarnings" - Disable warnings for not translated phrases.',
      "DEPRECATED:",
      '  "status" - Screen status "content" or "preview" (deprecated). Now preview shown if in url "pageId" instead "id" or "screenId".'
    ].join("\n")
  );

  console.debug(
    [
      "\nCommand line functions:",
      "l10n:",
      '  "window.l10n.exportNotTranslated({toJSON: true});" - View in browser console not translated phrases in JSON format.',
      "ESCache:",
      '  "window.ESCache.clear();" - Force clearing for all cached data.',
      '  "window.ESCache.storage" - Show cache storage in browser console.',
      "Other:",
      "  \"copy('config = ' + JSON.stringify(window.ESCONFIG, null, 2) + ';');\" - Copy client config."
    ].join("\n")
  );
  /*eslint-enable */
}
