<template>
  <div class="easyscreen-password-input" @click="focusTheHiddenInput">
    <label class="easyscreen-password-input--label" v-if="label" :for="id">{{ label }}</label>
    <easyscreen-input-group>
      <input
        :class="['easyscreen-input', 'easyscreen-password-input--input', { focus: focused }]"
        :type="dataMasked ? 'password' : 'text'"
        :style="{ fontSize: dataMasked ? 53 : 33 }"
        :value="value"
        @click="(e) => $emit('click', e)"
        autocomplete="off"
        readonly
      />
      <template slot="append-text">
        <span class="easyscreen-password-input--icon" @click="toggleMask">
          <i
            :class="['fa', { 'fa-eye-slash': dataMasked, 'fa-eye': !dataMasked }]"
            aria-hidden="true"
          ></i>
        </span>
      </template>
    </easyscreen-input-group>

    <input
      :id="id"
      class="easyscreen-password-input--hidden-input"
      type="text"
      :value="value"
      @input="(event) => $emit('input', event.target.value)"
      autocomplete="off"
      ref="hiddenInput"
      :readonly="readonly"
    />
  </div>
</template>

<style lang="less" src="./input.less"></style>
<style lang="less" src="./password.less"></style>
<script>
  import InputGroup from "./input-group.vue";
  import randomId from "../../../lib/utils/random-id.js";

  export default {
    name: "easyscreen-password-input",
    props: {
      /* Label of input field */
      label: {
        type: String,
        default: ""
      },
      /* Value of input */
      value: {
        type: String,
        default: ""
      },
      /* Sets the default masking of input. */
      masked: {
        type: Boolean,
        default: true
      },
      /* Sets the input focused when true. */
      focused: {
        type: Boolean,
        default: false
      },
      /* Sets the input readonly when true */
      readonly: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        dataMasked: this.masked,
        id: randomId()
      };
    },
    methods: {
      /**
       * Set the forcus for hidden input for track native keyboard events.
       */
      focusTheHiddenInput() {
        let hiddenInput = this.$refs.hiddenInput;
        if (hiddenInput) {
          hiddenInput.focus();
          hiddenInput.selectionStart = hiddenInput.selectionEnd = hiddenInput.value.length;
        }
      },
      /**
       * Toggle the masking state.
       */
      toggleMask() {
        this.dataMasked = !this.dataMasked;
      },
      /**
       * Set the masking state of field.
       *
       * @param {Boolean} state - Masking state.
       */
      setMasked(state) {
        this.dataMasked = !!state;
      }
    },
    components: {
      "easyscreen-input-group": InputGroup
    }
  };
</script>
