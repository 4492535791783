<template>
  <div class="popular-searches-list-with-covers">
    <div
      v-if="popularSearchesWithCovers.length !== 0"
      class="popular-searches-list-with-covers--title"
    >
      {{ $l10n('Popular searches') }}:
    </div>
    <div class="popular-searches-list-with-covers--list">
      <div
        v-for="tag in popularSearchesWithCovers.slice(0, previewAmount)"
        :key="tag.key"
        class="popular-searches-list-with-covers--list-element"
        @click="() => $emit('select', tag)"
        :style="{
          width: `${ 100 / previewAmount }%`
        }"
      >
        <digital-shelf-cover
          ref="covers"
          class="popular-searches-list-with-covers--list-element-cover"
          align="bottom"
          horizontal-align="center"
          width="80%"
          :cover="tag.cover"
          :with-cover-background="false"
        />
        <div class="popular-searches-list-with-covers--list-element-title">
          {{ tag.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<style src="./list-with-covers.less" lang="less" />

<script>
  import DigitalShelfCover from "../digital-shelf/cover.vue";
  import PopularSearchesMixin from "./popular-searches-mixin.js";

  export default {
    name: "popular-searches-list-with-covers",
    mixins: [PopularSearchesMixin],
    data() {
      return {
        coversLoaded: false
      };
    },
    props: {
      /* Amount of searches on preview. */
      previewAmount: {
        type: Number,
        default: 4
      },
      /* The maximim title length of single tag. Default uses default value of "tags-inline". */
      maxTitleLength: {
        type: Number,
        default: 60
      }
    },
    computed: {
      firstCoverDominantColor() {
        if (!this.$refs.covers || this.$refs.covers.length === 0) {
          return null;
        }

        return this.$refs.covers[0].dominantColor;
      },
      popularSearchesWithCovers() {
        return this.popularSearches.filter(tag => tag.cover != null);
      },
      amountOfLoadedCovers() {
        return this.popularSearchesWithCovers.length;
      }
    },
    methods: {
      async _fetchCovers() {
        if (this.coversLoaded) {
          return;
        }

        if (this.amountOfLoadedCovers >= this.previewAmount) {
          this.coversLoaded = true;
          return;
        }

        let forLoad = this.popularSearches.filter(tag => tag.cover == null && tag.coverFetched !== true);
        forLoad = forLoad.slice(0, this.previewAmount - this.amountOfLoadedCovers);

        if (forLoad.length === 0) {
          this.coversLoaded = true;
          return;
        }

        await Promise.all(forLoad.map(async (tag) => {
          tag.coverFetched = true;
          try {
            const searchResult = await this.$easyscreenRequest.lmsConnector.search({
              step: 1,
              query: '"' + tag.title + '"',
              withCoversOnly: true
            });

            if (searchResult.objects.length !== 0) {
              tag.cover = searchResult.objects[0].cover;
              /* Hack for reset the cache of computed properties after the creating of new property `popularSearches` element. */
              this.popularSearches = this.popularSearches.concat([]);
            }
          } catch (error) {
            console.error("Can't fetch cover for popular searches.", error);
          }
        }));

        if (this.amountOfLoadedCovers < this.previewAmount) {
          await this._fetchCovers();
        }
      }
    },
    mounted() {
      this.$once("fetched", async () => {
        await this._fetchCovers();
        this.$emit("ready");
      });
    },
    components: {
      "digital-shelf-cover": DigitalShelfCover
    }
  };
</script>
