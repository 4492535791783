var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
  'node-list-carousel-btj-slide',
  { 'node-list-carousel-btj-slide_without-image': !_vm.teaserImage },
  _vm.orientation
]},[_c('div',{staticClass:"node-list-carousel-btj-slide--content-wrapper"},[_c('div',{staticClass:"node-list-carousel-btj-slide--content"},[(_vm.teaserImage)?_c('div',{staticClass:"node-list-carousel-btj-slide--image",style:({
          backgroundImage: `url('${ _vm.teaserImage }')`
        })}):_vm._e(),_c('h1',{staticClass:"node-list-carousel-btj-slide--title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.isEvent)?_c('div',{staticClass:"node-list-carousel-btj-slide--meta"},[_c('div',{staticClass:"node-list-carousel-btj-slide--date"},[_vm._v(" "+_vm._s(_vm.formattedDate)+" ")]),_c('div',{staticClass:"node-list-carousel-btj-slide--location"},[_vm._v(" "+_vm._s(_vm.location)+" "),(_vm.price)?_c('span',{staticClass:"node-list-carousel-btj-slide--price"},[_vm._v(" - "+_vm._s(_vm.price)+" ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"node-list-carousel-btj-slide--teaser ck-editor",domProps:{"innerHTML":_vm._s(_vm.teaser)}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }