var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('modal-fullscreen',{ref:"modal",class:['easyscreen-search', `easyscreen-search_${ _vm.colorScheme }`, _vm.orientation],attrs:{"color-scheme":"easyscreen-menu","hide-empty-container":true,"header-height":"150px","footer-height":_vm.design === 'standalone-search' ? '150px' : null,"auto-close":_vm.autoClose,"position":_vm.position},on:{"before-open":(event) => _vm.$emit('before-open', event),"opened":(event) => _vm.$emit('opened', event),"before-close":(event) => _vm.$emit('before-close', event),"closed":(event) => {
      _vm.$emit('closed', event);
      _vm._resetSearchResults();
    }}},[_c('template',{slot:"header"},[_vm._t("header"),(_vm.withCloseControls)?_c('easyscreen-circle-button',{staticClass:"easyscreen-search--close-button",attrs:{"icon":"/images/es-menu/close_icon.png","icon-type":"image"},nativeOn:{"click":function($event){return _vm.hide.apply(null, arguments)}}}):_vm._e()],2),_c('template',{slot:"content"},[_c('search-form',{ref:"searchForm",attrs:{"default-sorting":_vm.defaultSorting,"with-sorting":false,"with-search-suggestions":!_vm.searchInProgress,"popular-searches":_vm.popularSearches},on:{"find":(query) => _vm.findBy(query)}})],1),_c('template',{slot:"footer"},[(_vm.design === 'standalone-search')?_c('span',[_vm._v("X")]):_vm._e()])],2),_c('search-results',{ref:"searchResultsModal",attrs:{"store":_vm.store,"color-scheme":_vm.colorScheme,"available-only-button":_vm.availableOnlyButton,"shelf-only-button":_vm.shelfOnlyButton,"popular-searches":_vm.popularSearches,"title":_vm.title,"position":_vm.position},on:{"opened":() => _vm.$emit('modal-opened', _vm.$refs.searchResultsModal.hide, 'searchResults'),"closed":() => _vm.$emit('closed-inner-modal', 'searchResults'),"modal-opened":(closeModal, type) => _vm.$emit('modal-opened', closeModal, type),"closed-inner-modal":(type) => _vm.$emit('closed-inner-modal', type),"before-close":() => {
      _vm.$emit('before-search-results-closed');

      if (_vm.$refs.searchForm) {
        _vm.$refs.searchForm.clearQuery();
      }
    },"go-home":_vm.hide}}),_c('loader',{ref:"searchLoader",attrs:{"position":_vm.position}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }