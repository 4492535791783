<template>
  <div
    class="node-list-carousel-vestbib-slide"
    :style="{
      backgroundImage: backgroundImage ? `url('${ backgroundImage }')` : null
    }"
  >
    <div class="node-list-carousel-vestbib-slide--logo"></div>
    <div class="node-list-carousel-vestbib-slide--content">
      <div
        v-if="isEvent"
        class="node-list-carousel-vestbib-slide--location"
      >{{ location }}</div>
      <div class="node-list-carousel-vestbib-slide--category">
        {{ color }}:
      </div>
      <h1 class="node-list-carousel-vestbib-slide--title">
        {{ title }}
      </h1>
      <div class="node-list-carousel-vestbib-slide--teaser ck-editor" v-html="teaser"></div>
      <div
        v-if="isEvent"
        class="node-list-carousel-vestbib-slide--date"
      >
        {{ formattedDate }}
      </div>
      <div
        v-if="isEvent && price"
        class="node-list-carousel-vestbib-slide--price"
      >
        {{ price }}
      </div>
    </div>
  </div>
</template>

<style src="../../core/ck-editor.less" lang="less"></style>
<style src="./node-list-carousel-vestbib-slide.less" lang="less"></style>

<script>
  import moment from "moment";

  const dateFormat = "D.MM.YY";

  export default {
    name: "node-list-carousel-vestbib-slide",
    props: {
      type: String,
      date: String,
      dateTo: String,
      location: String,
      price: [String, Number],
      bgImage: String,
      image: String,
      title: String,
      esTeaser: String,
      teaser: String,
      text: String,
      lead: String,
      color: String
    },
    computed: {
      isEvent() {
        return this.type === "event";
      },
      backgroundImage: {
        get() {
          return this.bgImage || this.image;
        }
      },
      formattedDate: {
        get() {
          const dateFrom = moment(this.date);
          const dateTo = moment(this.dateTo);
          let date = moment();

          if (date < dateFrom) {
            date = dateFrom;
          } else if (date > dateTo) {
            date = dateTo;
          }

          date.hour(dateFrom.hour());
          date.minute(dateFrom.minute());

          const formattedDateFrom = date.format(dateFormat);
          const formattedDateTo = dateTo.format(dateFormat);

          let formattedDate = formattedDateFrom;
          if (formattedDateFrom !== formattedDateTo) {
            formattedDate += ` - ${ formattedDateTo }`;
          }

          return formattedDate;
        }
      }
    }
  };
</script>
