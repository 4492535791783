<template>
  <div class="easyscreen-input-group">
    <div v-if="hasSlot('prepend')" class="easyscreen-input-group--prepend">
      <slot name="prepend"></slot>
    </div>
    <div v-if="hasSlot('prepend-text')" class="easyscreen-input-group--prepend easyscreen-input-group--text">
      <slot name="prepend-text"></slot>
    </div>

    <slot></slot>

    <div v-if="hasSlot('append')" class="easyscreen-input-group--append">
      <slot name="append"></slot>
    </div>
    <div v-if="hasSlot('append-text')" class="easyscreen-input-group--append easyscreen-input-group--text">
      <slot name="append-text"></slot>
    </div>
  </div>
</template>

<style lang="less" src="./input-group.less"></style>
<script>
  import hasSlotMixin from "../mixins/has-slot.js";

  export default {
    name: "easyscreen-input-group",
    mixins: [hasSlotMixin]
  };
</script>
