var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'easyscreen-foldable',
    `easyscreen-foldable_design-${ _vm.design }`,
    { 'easyscreen-foldable_height-limit-is-reached': _vm.heightLimitIsReached }
  ]},[(_vm.heightLimitIsReached && _vm.toggleButton)?_c('easyscreen-circle-button',{class:['easyscreen-foldable--toggle', _vm.toggleButtonClass],attrs:{"icon":[
      'fal',
      {
        'fa-chevron-left': !_vm.computedOpen && _vm.design === 'classic',
        'fa-chevron-down': _vm.computedOpen && _vm.design === 'classic',
        'fa-arrow-left': !_vm.computedOpen && _vm.design === 'light',
        'fa-arrow-down': _vm.computedOpen && _vm.design === 'light'
      }
    ],"size":"small","color":_vm.design === 'light' ? 'white' : undefined},nativeOn:{"click":function($event){return _vm.toggle.apply(null, arguments)}}}):_vm._e(),_c('div',{ref:"content",staticClass:"easyscreen-foldable--content",style:({ maxHeight: _vm.maxFoldedHeight })},[_vm._t("default")],2),(_vm.heightLimitIsReached)?_c('easyscreen-tooltip',{attrs:{"open":_vm.computedOpen,"placement":"bottom-start","tooltip-class":['easyscreen-foldable--tooltip', _vm.tooltipClass]}},[_c('div',{staticClass:"tooltip-target easyscreen-foldable--tooltip-target"}),_c('template',{slot:"tooltip"},[_c('div',{staticClass:"easyscreen-foldable--scrollable-wrapper",style:({ width: _vm.contentWidth })},[_c('easyscreen-scrollable',{attrs:{"max-height":_vm.maxUnflodedHeight,"smooth-edge-color":_vm.backgroundColor}},[_c('div',{staticClass:"easyscreen-foldable--tooltip-content",style:({ backgroundColor: _vm.backgroundColor })},[_vm._t("default")],2)])],1)])],2):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }