<template>
  <div>
    <modal-fullscreen
      ref="modal"

      colorScheme="easyscreen-menu"

      @before-open="(event) => $emit('before-open', event)"
      @opened="(event) => $emit('opened', event)"
      @before-close="(event) => $emit('before-close', event)"
      @closed="(event) => {
        _reset();
        $emit('closed', event);
      }"

      :class="[
        'easyscreen-search-results',
        `easyscreen-search-results_${ colorScheme }`,
        orientation,
        { 'easyscreen-search-results_with-title': title }
      ]"
      :hide-empty-container="true"
      :header-height="orientation === 'landscape-orientation' ? '150px' : '246px'"
      :position="position"
    >
      <template v-if="title" slot="header-center">
        <div class="easyscreen-search-results--title">
          {{ title }}
        </div>
      </template>
      <template slot="header-left">
        <div class="easyscreen-search-results--navigation">
          <easyscreen-circle-button
            class="easyscreen-search-results--home-button"
            icon="/images/es-menu/home_icon.png"
            icon-type="image"

            @click.native="(event) => {
              hide();
              $emit('go-home', event)
            }"
          />

          <search-input
            class="easyscreen-search-results--input es-ml-4"
            :value="query"
            :readonly-input="true"
            :sorting="sorting"
            @sorting-selected="(sorting) => _clarifySearch({ sorting: sorting.value })"
            @click.native="(event) => {
              /* Check if the clicked element has mounted. Otherwise the `_isSortingElement` might return the false-negative result. */
              if (_isElementOf(event.target, winproxy.document.body) && !_isSortingElement(event.target)) {
                formQuery = _isClearButtonElement(event.target) ? '' : query;
                $refs.searchFormModal.show();
              }
            }"
          />

          <div class="easyscreen-search-results--facets-group">
            <easyscreen-button
              v-if="availableOnlyButton"
              class="easyscreen-search-results--facet-button easyscreen-search-results--facet-button_fixed-text"
              color="primary"
              @click.native="() => _clarifySearch({ availableOnly: !availableOnly })"
            >

              <span class="easyscreen-search-results--facet-button-text">{{ _l10n('Available only') }}</span>
              <i :class="[
                'fa',
                'fa-check',
                'easyscreen-search-results--facet-icon_circle-border',
                { 'easyscreen-search-results--facet-icon_border-only': !availableOnly }
              ]"></i>
            </easyscreen-button>
            <easyscreen-button
              v-if="shelfOnlyButton"
              class="easyscreen-search-results--facet-button"
              color="primary"
              @click.native="() => _clarifySearch({ shelfOnly: !shelfOnly })"
            >
              {{ _l10n('This shelf only') }}
              <i :class="[
                'fa',
                'fa-check',
                'easyscreen-search-results--facet-icon_circle-border',
                { 'easyscreen-search-results--facet-icon_border-only': !shelfOnly }
              ]"></i>
            </easyscreen-button>

            <easyscreen-button
              class="easyscreen-search-results--facet-button easyscreen-search-results--facet-button_fixed-text"
              color="primary"
              v-for="previewFacet in _getPreviewFacets()"
              :key="previewFacet.id"
              @click.native="() => $refs.searchFacets.showSingleFacetModal(previewFacet.id, { standalone: true })"
            >
              <span
                :class="[
                  'easyscreen-search-results--facet-button-text',
                  { 'easyscreen-search-results--facet-button-text_with-counter': (selectedFacets[previewFacet.id] || []).length !== 0 }
                ]"
              >
                <span class="easyscreen-search-results--facet-button-text_label">
                  {{ previewFacet.name }}
                </span>
                <span
                  v-if="(selectedFacets[previewFacet.id] || []).length !== 0"
                  class="easyscreen-search-results--facet-button-text_counter"
                >
                  ({{ (selectedFacets[previewFacet.id] || []).length }})
                </span>
              </span>
              <i class="fa fa-caret-down easyscreen-search-results--facet-icon_right-center"></i>
            </easyscreen-button>

            <easyscreen-button
              v-if="(facets || []).length !== 0"
              class="easyscreen-search-results--facet-button"
              color="primary"
              @click.native="() => $refs.searchFacets.show()"
            >
              {{ _l10n("Show more facets") }}
            </easyscreen-button>
          </div>
        </div>
      </template>
      <template slot="header-right">
        <easyscreen-circle-button
          class="easyscreen-search-results--close-button"
          icon="/images/es-menu/close_icon.png"
          icon-type="image"

          @click.native="hide"
        />
      </template>
      <template slot="content">
        <div class="easyscreen-search-results--content">
          <div class="easyscreen-search-results--page-info">
            {{ _l10n('Showing') }}
            {{ _getShownRange() }}
            {{ _l10n('out of') }}
            {{ hits }}
            {{ _l10n('results') }}
            <i v-if="searchInProgress" class="fa fa-spinner fa-spin"></i>
          </div>
          <div class="easyscreen-search-results--carousel">
            <easyscreen-carousel
              ref="carousel"
              :layout-columns="layoutColumns"
              :layout-rows="layoutRows"
              @before-position-change="(_, followingSlideIndex) => {
                carouselSlideIndex = followingSlideIndex;
                _preload();
              }"
            >
              <search-cover
                v-for="item in results"
                :key="item.id"
                :title="item.title"
                :author="item.author"
                :year="item.year"
                :type="item.type"
                :cover="item.cover"
                :availability="_availabilityStatus(item)"
                @click.native="() => _showMaterialView(item)"
              />
            </easyscreen-carousel>
          </div>
        </div>
      </template>
    </modal-fullscreen>
    <modal-fullscreen ref="searchFormModal">
      <template slot="header-right">
        <easyscreen-circle-button
          icon="/images/es-menu/close_icon.png"
          icon-type="image"

          @click.native="$refs.searchFormModal.hide"
        />
      </template>
      <template slot="content">
        <search-form
          ref="searchForm"
          :with-sorting="true"
          :default-query="formQuery"
          :default-sorting="sorting"
          :popular-searches="popularSearches"
          @find="(query, sorting) => {
            _clarifySearch({ query: query, sorting: sorting });
            $refs.searchFormModal.hide();
          }"
        />
      </template>
      <template slot="footer-right">
        <easyscreen-circle-button
          icon="/images/es-menu/close_icon.png"
          icon-type="image"

          @click.native="$refs.searchFormModal.hide"
        />
      </template>
    </modal-fullscreen>
    <search-facets
      ref="searchFacets"
      :facets="facets || []"
      :selected-default="selectedFacets"
      :position="position"
      @opened="() => $emit('modal-opened', $refs.searchFacets.hide, 'searchResultsSearchFacets')"
      @closed="() => $emit('closed-inner-modal', 'searchResultsSearchFacets')"
      @modal-opened="(closeModal, type) => $emit('modal-opened', closeModal, 'searchResults' + type)"
      @closed-inner-modal="(type) => $emit('closed-inner-modal', 'searchResults' + type)"
      @selected="(_selectedFacets) => _clarifySearch({ facets: _selectedFacets })"
    >
      <template slot="title">
        <span>
          <span class="Search-facets--hits bold">{{ hits }}</span>&nbsp;
          <span class="bold">{{ _l10n('results') }}</span>&nbsp;
          {{ _l10n('for') }}&nbsp;
          “<span class="Search-facets--query">{{ query }}</span>”&nbsp;
          <span>{{ _l10n('with current facets').trim() }}</span>.
        </span>
      </template>
    </search-facets>

    <materials-list
      v-if="selectedMaterial"
      ref="materialsList"
      :use-cache="false"
      :default-materials="selectedMaterialsList"
      :default-selected="selectedMaterial"
      :position="position"
      @select-tag="_findByTag"
      @opened="() => $emit('modal-opened', $refs.materialsList.hide, 'searchResultsMaterialsList')"
      @closed="() => $emit('closed-inner-modal', 'searchResultsMaterialsList')"
      @modal-opened="(closeModal, type) => $emit('modal-opened', closeModal, 'searchResults' + type)"
      @closed-inner-modal="(type) => $emit('closed-inner-modal', 'searchResults' + type)"
    />
  </div>
</template>

<style src="./search-results.less" lang="less"></style>
<style src="../core/mixins.less" lang="less"></style>

<script>
  import ModalFullscreen from "../core/modal/fullscreen.vue";
  import EasyscreenButton from "../core/button/button.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import EasyscreenCarousel from "../core/carousel/carousel.vue";
  import MaterialsList from "../materials-list/materials-list.vue";
  import SearchCover from "./search-cover.vue";
  import SearchInput from "./search-input.vue";
  import SearchForm from "./search-form.vue";
  import SearchFacets from "./search-facets.vue";

  import { get } from "lodash";
  import { VuexLike } from "@/lib/vuex-like.js";
  import clampNumber from "@/lib/utils/clamp-number.js";
  import l10n from "@/lib/localization/localization.js";
  import isElementOf from "@/lib/utils/is-element-of.js";
  import orientationMixin from "../core/mixins/orientation.js";
  import availabilityStatus from "../materials-list/availability-status.js";
  import { isSortingElement, isClearButtonElement } from "./search-form.vue";

  export default {
    name: "search-results",
    mixins: [
      orientationMixin
    ],
    props: {
      /* The search store: state and actions for current search instance. */
      store: VuexLike,
      /*
       * The color scheme of search and search results:
       * - classic - the classic background with smoke (default).
       * - easyscreen-menu - the solid color or image background.
       */
      colorScheme: {
        type: String,
        default: "classic",
        validator: _colorScheme => ["classic", "easyscreen-menu"].includes(_colorScheme)
      },
      /* Show the available only button. Which allow the user to get the only available materials in resutls. */
      availableOnlyButton: {
        type: Boolean,
        default: false
      },
      /*
       * Show the this self only button. Which allow the user to get results from current
       * shelf (to query will be applied the custom sub-query).
       */
      shelfOnlyButton: {
        type: Boolean,
        default: false
      },
      /* The type of popular searches. */
      popularSearches: String,
      /* The title of search results screen, used for shelfOnly feature. */
      title: String,
      position: String
    },
    data() {
      return {
        carouselSlideIndex: 0,
        formQuery: "",
        selectedMaterialsList: [],
        selectedMaterial: ""
      };
    },
    computed: {
      /* Proxy for modal isShown variable. */
      isShown: {
        cache: false,
        get() {
          return this.$refs.modal && this.$refs.modal.isShown;
        }
      },
      /* Proxy valiables for store. */
      layoutColumns: {
        get() {
          return get(this.store, "state.layoutColumns");
        }
      },
      layoutRows: {
        get() {
          return get(this.store, "state.layoutRows");
        }
      },
      query: {
        get() {
          return get(this.store, "state.query");
        },
        set(value) {
          this.store.commit("setQuery", { query: value });
        }
      },
      sorting: {
        get() {
          return get(this.store, "state.sorting");
        }
      },
      hits: {
        get() {
          return get(this.store, "state.hits", 0);
        }
      },
      results: {
        get() {
          return get(this.store, "state.results", []);
        }
      },
      facets: {
        get() {
          return get(this.store, "state.facets", []);
        }
      },
      selectedFacets: {
        get() {
          return get(this.store, "state.selectedFacets", {});
        }
      },
      availableOnly: {
        get() {
          return get(this.store, "state.availableOnly", false);
        }
      },
      shelfOnly: {
        get() {
          return get(this.store, "state.shelfOnly", false);
        }
      },
      searchInProgress: {
        get() {
          return get(this.store, "state.searchInProgress");
        }
      },
      queryPrefix: {
        get() {
          return get(this.store, "state.queryPrefix");
        }
      }
    },
    /* The watch is used for apply the changes in search store. */
    watch: {
      "store.state": {
        handler() {},
        deep: true
      }
    },
    methods: {
      /**
       * Show the current modal.
       *
       * @param {Function} [callback] - The show callback, will be called when modal is opened.
       */
      show(callback) {
        return this.$refs.modal.show(callback);
      },
      /**
       * Hide the current modal.
       *
       * @param {Function} [callback] - The hide callback, will be called when modal is closed.
       */
      hide(callback) {
        return this.$refs.modal.hide(callback);
      },
      /* Proxy for localization function. */
      _l10n: l10n,
      /**
       * Proxy for availabilityStatus. See the ../materials-list/availability-status.js
       */
      _availabilityStatus: availabilityStatus,
      /**
       * Proxy for isSortingElement. See the ./search-form.vue
       */
      _isSortingElement: isSortingElement,
      /**
       * Proxy for isClearButtonElement. See the ./search-form.vue
       */
      _isClearButtonElement: isClearButtonElement,
      /**
       * Proxy for _isElementOf. See the @/lib/utils/is-element-of.js
       */
      _isElementOf: isElementOf,
      /**
       * Get amount of materials per slide.
       *
       * @returns {Number} The amount of materials per slide.
       */
      _getMaterialsPerSlide() {
        return this.layoutColumns * this.layoutRows;
      },
      /**
       * Get the range of materials shown on the screen (carousel).
       *
       * @returns {String} The range in format: `${first}-${last}`.
       */
      _getShownRange() {
        const materialsPerSlide = this._getMaterialsPerSlide();
        const firstActiveMaterialIndex = this.carouselSlideIndex * materialsPerSlide;
        const from = clampNumber(firstActiveMaterialIndex + 1, 0, this.hits);
        const to = clampNumber(firstActiveMaterialIndex + materialsPerSlide, 0, this.hits);
        return `${ from }-${ to }`;
      },
      /**
       * Load the two pages with resutls if the active slide is the one before last loaded.
       */
      _preload() {
        if (this.searchInProgress) {
          return;
        }

        const slidesLoaded = Math.ceil(this.results.length / this._getMaterialsPerSlide());
        /* Preload the data if selected slide is one before end. */
        if (this.carouselSlideIndex >= slidesLoaded - 2) {
          this.store.dispatch("loadPage", { amount: 2 });
        }
      },
      /**
       * Get the facets for facets buttons with short access.
       *
       * @returns {Object[]} facets - The facet data (from search).
       * @returns {String} facets[].id - The id of facet.
       * @returns {Object[]} facets[].values - The facet values.
       * @returns {String} facets[].values[].value - The value of facet.
       * @returns {Number} facets[].values[].frequence - The amout of elements with this facet value
       */
      _getPreviewFacets() {
        const previewAmount = 3 - [this.availableOnlyButton, this.shelfOnlyButton].filter(Boolean).length;
        const previewOptions = [{ id: "facet.subject", index: 0 }, { index: 1 }, { index: 2 }].slice(0, previewAmount);

        let preview = [];
        let facets =  this.facets;
        previewOptions.forEach(previewOption => {
          if (previewOption.id) {
            let facetIndex = facets.findIndex(facet => facet.id === previewOption.id);
            if (facetIndex !== -1) {
              preview = preview.concat(facets.splice(facetIndex, 1)[0]);
              previewOption.found = true;
            }
          }
        });

        preview = preview.concat(previewOptions.map(previewOption => {
          if (previewOption.found) {
            return null;
          }

          return facets[previewOption.index];
        }).filter(Boolean));

        return preview;
      },
      /**
       * Open the material view for selected material.
       *
       * @param {Object} item - The materail detail (see response of GET [lms]/[consumer-hash]/detail).
       * @param {String} item.faustNumber - The faust number of material.
       */
      async _showMaterialView(item) {
        this.selectedMaterialsList = this.results.map(material => material.id);
        this.selectedMaterial = item.id;

        await this.$nextTick();

        if (this.$refs.materialsList) {
          this.$refs.materialsList.show();
        }
      },
      /**
       * Do the search with changed one or more search parameters.
       * @async
       *
       * @param {Object} options - The search options.
       * @param {Boolean} [options.availableOnly=this.availableOnly] - Show the all or available only materials.
       * @param {Boolean} [options.shelfOnly=this.shelfOnly] - Show the all or materials from current shelf (used additional cql in query).
       * @param {String} [options.sorting=this.sorting] - The sorting of results.
       * @param {String} [options.query=this.query] - The text search query.
       * @param {Object} [options.facets=this.facets] - The selected search facets.
       * @param {String} [options.queryPrefix=this.queryPrefix] - The query prefix for shelf only feature.
       */
      async _clarifySearch(options) {
        let queryChanged = false;

        [{
          param: "availableOnly",
          _default: this.availableOnly
        }, {
          param: "shelfOnly",
          _default: this.shelfOnly
        }, {
          param: "sorting",
          _default: this.sorting
        }, {
          param: "query",
          _default: this.query
        }, {
          param: "facets",
          _default: this.selectedFacets
        }, {
          param: "queryPrefix",
          _default: this.queryPrefix
        }].forEach(({ param, _default }) => {
          if (options[param] === undefined) {
            options[param] = _default;
          } else if (!queryChanged && options[param] !== _default || param === "facets") {
            queryChanged = true;
          }
        });

        if (!queryChanged) {
          return;
        }

        /* Drop selected facets when the text query is changed. */
        if (options.query !== this.query) {
          options.facets = {};
        }

        if (this.$refs.searchForm) {
          this.$refs.searchForm.setQuery(options.query);
          this.$refs.searchForm.setSorting(options.sorting);
        }

        await this.store.dispatch("find", options);
        if (this.$refs.carousel)  {
          this.$refs.carousel.setOffset(0, { withoutAnimation: true });
        }

        return;
      },
      /**
       * Reset the search state.
       */
      _reset() {
        this.store && this.store.dispatch("reset");
        this.$refs.searchFacets && this.$refs.searchFacets.reset();
      },
      /**
       * Hanlder of find-by-tag event on material view.
       * Hides the opened material view and perform the search with new query.
       *
       * @param {String} query - The selected material tag for search.
       */
      async _findByTag(query) {
        if (this.$refs.materialsList) {
          this.$refs.materialsList.hide();
        }

        this.store.dispatch("find", { query });
      }
    },
    mounted() {
      this.$once("hook:beforeDestroy", this.store.on("search-end", (options) => {
        if (this.$refs.carousel && options.isClarification !== true) {
          this.$refs.carousel.selectByIndex(0);
        }
      }));
    },
    beforeDestroy() {
      this._reset();
    },
    components: {
      "modal-fullscreen": ModalFullscreen,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "easyscreen-carousel": EasyscreenCarousel,
      "materials-list": MaterialsList,
      "search-cover": SearchCover,
      "search-input": SearchInput,
      "search-form": SearchForm,
      "search-facets": SearchFacets
    }
  };
</script>
