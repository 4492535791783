<template>
  <div>
    <modal-confirm
      :class="['patron-authentication', `patron-authentication_design-${ design }`]"
      ref="modal"
      @before-open="(event) => $emit('before-open', event)"
      @opened="(event) => $emit('opened', event)"
      @before-close="(event) => $emit('before-close', event)"
      @ok="authenticate"
      @closed="(event) => {
        selectedType = '';
        $emit('closed', event);
      }"
      :ok-title="okTitle || $l10n('Login')"
      :cancel-title="cancelTitle || $l10n('Cancel')"
      :design="design"

      min-width="1040px"
    >
      <template slot="header">
        {{ title || $l10n('Login to profile') }}
      </template>
      <template slot="content">
        <div :style="{
          width: '1000px',
          height: ['fullsize', 'combined'].includes(_getAuthenticationType()) ? null : '550px'
        }">
          <authentication-form
            ref="form"
            :type="_getAuthenticationType()"
            :message="message"
            :with-pickup-select="type === 'auto-reservation'"
            :with-barcode-scanner="withBarcodeScanner"
            :require-password-for-barcode-scanner="requirePasswordForBarcodeScanner"
            :design="design"
            @login="authenticate"
            @fully-filled="authenticate(null, { type: $event })"
          />
        </div>
      </template>
    </modal-confirm>

    <modal-confirm
      ref="authenticationTypeModal"
      :ok-title="$l10n('Password')"
      :ok-options="{ color: 'secondary', style: { width: 477 }}"
      :cancel-title="$l10n('Pin')"
      :cancel-options="{ style: { width: 477 }}"

      min-width="1040px"
    >
      <template slot="header">
        {{ $l10n('Select login option') }}
      </template>
      <template slot="content">
        {{ $l10n('Please select how you want to login') }}
      </template>
    </modal-confirm>
    <modal-alert ref="alert" />
    <loader ref="loader" />
  </div>
</template>

<script>
  import ModalAlert from "../../core/modal/alert.vue";
  import ModalConfirm from "../../core/modal/confirm.vue";
  import Loader from "../../core/loader/loader.vue";
  import AuthenticationForm from "./authentication-form.vue";

  import { mapState } from "vuex";
  import { get, isFunction } from "lodash";
  import { AuthError } from "@/lib/errors.js";
  import asyncTimeout from "@/lib/utils/async-timeout.js";

  export default {
    name: "patron-authentication",
    props: {
      /*
       * The type of authentication:
       * - auto - The authentication based on defined rules for usable providers.
       * - auto-loan - The authentication based on defined rules for usable providers for loan flow.
       * - auto-profile - The authentication based on defined rules for usable providers for profile flow.
       * - auto-reservation - The authentication based on defined rules for usable providers for reservation flow
       *                      (form contains the branch\deparment select).
       * - user-choice - Ask the user which type of authentication he want to use (pin or password).
       * - numeric - Use the cpr\pin-based authentication (two numeric fields).
       * - combined - Use the cpr\password-based authentication (numeric + fullsize filed).
       * - fullsize - Use the login\password-based authentication (two fullsize fileds).
       */
      type: {
        type: String,
        default: "auto",
        validator: _type => [
          "auto",
          "auto-loan",
          "auto-reservation",
          "auto-profile",
          "user-choice",
          "numeric",
          "combined",
          "fullsize"
        ].includes(_type)
      },
      /* Custom modal title. Default: l10n("Login to profile") */
      title: String,
      /* Custom title of ok modal button. Default: l10n("Login") */
      okTitle: String,
      /* Custom title of cancel modal button. Default: l10n("Cancel") */
      cancelTitle: String,
      /* Custom modal message. Default: the profile overview. */
      message: String,
      /* Flag for using the barcode scanner features. */
      withBarcodeScanner: {
        type: Boolean,
        default: true
      },
      /* Flag login without password when cpr is scanner with barcode. (proxy for authentication-form) */
      requirePasswordForBarcodeScanner: {
        type: Boolean,
        default: true
      },
      /* The global reskin. */
      design: {
        type: String,
        default: "classic",
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    data() {
      return {
        selectedType: "",
        providerBasedType: ""
      };
    },
    computed: {
      ...mapState({
        safetyModeEnabled: state => state.safetyModeEnabled
      })
    },
    methods: {
      /**
       * Validate the user cpr\login and pin\password in auth form.
       * @async
       * @fires patron-authentication#authenticated
       *
       * @param {Object} event - The form submit\ok event.
       * @param {Object} [options] - The authentication options
       * @param {String} [options.type] - The type of the authentication: default (empty value) or "loan-without-pin";
       *
       * @returns {Promise} The promise-base callback.
       */
      async authenticate(event, options) {
        options = {
          type: "default",
          ...(options || {})
        };

        if (isFunction(get(event, "preventDefault"))) {
          event.preventDefault();
        }

        try {
          const formData = this.$refs.form.formData();
          if (this.safetyModeEnabled) {
            return this.$emit("authenticated", {}, formData);
          }

          this.$refs.loader.show();
          let profileData = {};

          let authenticationTypes = [this.providerBasedType, this.type];
          if (options.type !== "loan-without-pin" && !authenticationTypes.includes("auto-reservation")) {
            profileData = await this._getPatronProfileInfo({ user: formData.cpr, pin: formData.pin });
          }

          if (options.type === "loan-without-pin" && this.$easyscreenConfig.get("enable.selfcheckViaBarcode")) {
            /*
             * The use case hack: Show the loader for a user for a 2 seconds to give him a time for
             * move out the CPR card out of the barcode scanner.
             */
            await asyncTimeout(2000);
          }

          try {
            let providers = await this.$easyscreenRequest.lmsConnector.providers();
            if (providers["get /patron/email"] === "bibliofil") {
              profileData = await this.$easyscreenRequest.lmsConnector.getPatronEmail({ user: formData.cpr });
            }
          } catch (error) {
            console.error("Can't get patron email:", error);
          }

          /**
           * The user authenticated event.
           *
           * @event patron-authentication#authenticated
           * @type {Object + Object} Profile data (see response on GET [lms]/[consumer-hash]/patron/info) and form data `{ cpr: String, pin: String }`
           */
          this.$emit("authenticated", profileData, formData);
        } catch (err) {
          /* Cleanup the cpr and pin on authentication fail. */
          this.$refs.form.formData({ cpr: "", pin: "" });

          console.error("Authentication failed", err);
          this.$refs.alert.show({
            title: this.$l10n("Error"),
            message: this.$l10n("Incorrect CPR or PIN")
          });

          this.$emit("authentication-failed", err);
        }

        this.$refs.loader.hide();
      },
      /**
       * Shows the authentication form based on selected type.
       * @async
       *
       * @returns {Promise} The promise-base callback, called when the .show method called on modal.
       */
      async show() {
        if (this.type.startsWith("auto") && !this.providerBasedType) {
          try {
            this.providerBasedType = await this._getProviderBasedAuthenticationType();
          } catch (err) {
            console.error(err);
          }
        }

        if ([this.type, this.providerBasedType].includes("user-choice")) {
          try {
            await this._selectAuthenticationType();
          } catch (err) {
            console.error(err);
          }

          if (this.selectedType === "user-choice") {
            return;
          }
        }

        return this.$refs.modal.show();
      },
      /**
       * Hides the authentication form.
       * @async
       */
      hide() {
        return this.$refs.modal.hide();
      },
      /**
       * Shows the modal for select the type of authentication form (combined or numeric).
       * @async
       *
       * @returns {Promise<>} The promise-base callback, will be called on user choice.
       */
      _selectAuthenticationType() {
        return new Promise((resolve) => {
          const setType = function(type) {
            this.selectedType = type;
            this.$nextTick(resolve);

            if (this.$refs.authenticationTypeModal) {
              this.$refs.authenticationTypeModal.$off("ok");
              this.$refs.authenticationTypeModal.$off("cancel");
              this.$refs.authenticationTypeModal.$off("closed");
            }
          };

          this.$refs.authenticationTypeModal.$once("ok", setType.bind(this, "combined"));
          this.$refs.authenticationTypeModal.$once("cancel", setType.bind(this, "numeric"));
          this.$refs.authenticationTypeModal.$once("closed", (event) => {
            this.$emit("closed", event);
            setType.call(this, this.type);
          });

          this.$refs.authenticationTypeModal.show();
        });
      },
      /**
       * Get the authentication type.
       *
       * @returns {String} The authentication type.
       */
      _getAuthenticationType() {
        let type = this.type;
        if (type === "user-choice") {
          type = undefined;
        } else if (type.startsWith("auto")) {
          type = this.providerBasedType;
        }

        return this.selectedType || type;
      },
      /**
       * Calculate the provider based authentication type.
       * @async
       *
       * @returns {Promise<String>} The promise-base callback, called on calculation finish.
       */
      async _getProviderBasedAuthenticationType() {
        if (this.$easyscreenConfig.get("enable.loanWithoutPin") && this.type === "auto-loan") {
          return "numeric";
        }

        if (this.$easyscreenConfig.get("enable.fullsizePasswordKeyboard")) {
          return "combined";
        }

        if (this.$easyscreenConfig.get("enable.loanLoginWithOption") && ["auto-loan", "auto-profile"].includes(this.type)) {
          return "user-choice";
        }

        try {
          let providers = await this.$easyscreenRequest.lmsConnector.providers();
          let type = "numeric";
          if (["auto-loan", "auto-profile"].includes(this.type)) {
            let sip2Loan = providers["get /patron/loan"] === "sip2";
            let providerWithFlexLogin = ["bibliofil", "ex_alma"].includes(providers["post /patron/loan/renew"]);

            if (sip2Loan && providerWithFlexLogin) {
              type = "user-choice";
            }
          } else {
            let passwordBasedProvider = ["bibliofil", "ex_alma"].includes(providers["get /patron/info"]);
            if (passwordBasedProvider) {
              type = "combined";
            }
          }

          return type;
        } catch (error) {
          console.error("Can't get providers list: ", error);
          return "numeric";
        }
      },
      /**
       * Fetch the user profile info.
       * @async
       *
       * @param {Object} options - The authentication user form data.
       * @param {String} options.cpr - The user cpr.
       * @param {String} options.pin - The user pin.
       *
       * @returns {Promise<Object>} The promise-base callback, called on fetched profile data.
       */
      async _getPatronProfileInfo(options) {
        try {
          return this.$easyscreenRequest.lmsConnector.getPatronInfo({
            user: options.user,
            pin: options.pin
          });
        } catch (error) {
          throw new AuthError({
            message: this.$l10n("Please check your credentials and try again"),
            ajaxRequest: error
          });
        }
      }
    },
    components: {
      "modal-alert": ModalAlert,
      "modal-confirm": ModalConfirm,
      "loader": Loader,
      "authentication-form": AuthenticationForm
    }
  };
</script>
