var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.isWelcomeScreenLight && !_vm.$easyscreenIsMobile ? 'scrollable' : 'div',{tag:"component",class:[
    'text-and-image',
    `text-and-image_align-${ _vm.imageAlign }`,
    `text-and-image_design-${ _vm.design }`,
    _vm.subType,
    _vm.skin
  ],style:({ '--text-and-image-special-color': _vm.specialColor }),attrs:{"max-height":"100%"}},[(_vm.fixedImage)?_c('div',{staticClass:"text-and-image--image",style:({
      backgroundImage: !_vm.isWelcomeScreen ? `url('${ _vm.fixedImage }')` : null
    })},[(_vm.qrCode && _vm.isWelcomeScreenLight)?_c('qr-code',{staticClass:"text-and-image--qr-code",attrs:{"url":_vm.qrCode,"size":80}}):_vm._e()],1):_vm._e(),(_vm.title)?_c('h1',{staticClass:"text-and-image--title"},[(_vm.design === 'light')?_c('i',{staticClass:"fal fa-arrow-down text-and-image--title-icon"}):_vm._e(),_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),(_vm.subheadline)?_c('h2',{staticClass:"text-and-image--subtitle"},[_vm._v(" "+_vm._s(_vm.subheadline)+" ")]):_vm._e(),(_vm.fixedImage && _vm.isWelcomeScreen)?_c('img',{staticClass:"text-and-image--image",attrs:{"src":_vm.fixedImage}}):_vm._e(),(_vm.fixedImage && _vm.design === 'light')?_c('img',{ref:"image",staticClass:"text-and-image--image-reference",attrs:{"src":`/proxy?url=${ _vm.fixedImage }`,"crossOrigin":"annonimus"}}):_vm._e(),(_vm.text)?_c(_vm.isWelcomeScreenLight ? 'div' : 'scrollable',{tag:"component",staticClass:"text-and-image--text-wrapper"},[_c('div',{staticClass:"text-and-image--text ck-editor",domProps:{"innerHTML":_vm._s(_vm.text)}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }