<template>
  <div>
    <template v-if="slides.length !== 0">
      <welcome-screen-classic v-if="design === 'classic'" :slides="slides" />
      <welcome-screen-light v-if="design === 'light'" :slides="slides" />
    </template>
  </div>
</template>

<script>
  import WelcomeScreenClassic from "./welcome-screen-classic.vue";
  import WelcomeScreenLight from "../welcome-screen-light/welcome-screen-light.vue";

  export default {
    name: "welcome-screen",
    props: {
      slides: {
        type: Array,
        default: () => ([])
      },
      /* The global reskin for materials list. */
      design: {
        type: String,
        default: () => window.ESCONFIG.defaultDesign,
        validator: _design => ["classic", "light"].includes(_design)
      }
    },
    components: {
      WelcomeScreenClassic,
      WelcomeScreenLight
    }
  };
</script>
