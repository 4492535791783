var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:['keyboard-fullsize', `keyboard-fullsize_${ _vm._caseModifer() }`, `keyboard-fullsize_design-${ _vm.design }`]},_vm._l((_vm._applyOverrides(_vm.dataLayout)),function(keyboardRow,rowIndex){return _c('div',{key:`row-${ rowIndex }`,staticClass:"keyboard-fullsize--row"},[_vm._l((keyboardRow),function(keyOptions){return [(keyOptions.fn)?_c('span',{key:`key-${ _vm._keyIndex(keyOptions) }`,domProps:{"innerHTML":_vm._s(keyOptions.fn(
          _vm._getKeyPosition(rowIndex, keyOptions, keyboardRow),
          _vm.override[keyOptions.special] || {}
        ))},on:{"click":(event) => { _vm._keyClick(keyOptions, event); }}}):_c('easyscreen-button',{key:`key-${ _vm._keyIndex(keyOptions) }`,class:['keyboard-fullsize--key', {
          'keyboard-fullsize--simple-key': typeof keyOptions === 'string',
          'keyboard-fullsize--disabled-key': keyOptions.disabled === true,
          [`keyboard-fullsize--special_${ keyOptions.special }`]: !!keyOptions.special
        }],style:(Object.assign(
          {},
          _vm._getKeyPosition(rowIndex, keyOptions, keyboardRow),
          keyOptions.style
        )),attrs:{"color":_vm.design === 'light' ? 'white' : keyOptions.color || 'gray',"active":(_vm._isShift(keyOptions) && _vm.enabledShift) || (_vm._isCapslock(keyOptions) && _vm.enabledCapslock)},nativeOn:{"click":function($event){return ((event) => { _vm._keyClick(keyOptions, event); }).apply(null, arguments)}}},[_c('span',{staticClass:"keyboard-fullsize--key-wrapper"},[(keyOptions.icon && (keyOptions.icon.placement === 'left' || !keyOptions.icon.placement))?_c('i',{class:['keyboard-fullsize--key-icon', 'keyboard-fullsize--key-icon_left', keyOptions.icon.className],style:(keyOptions.icon.style)}):_vm._e(),(keyOptions.upperCase)?_c('span',{staticClass:"keyboard-fullsize--upper-key"},[_vm._v(" "+_vm._s(keyOptions.upperCase)+" ")]):_vm._e(),(keyOptions.lowerCase)?_c('span',{staticClass:"keyboard-fullsize--lower-key"},[_vm._v(" "+_vm._s(keyOptions.lowerCase)+" ")]):_vm._e(),(!keyOptions.lowerCase && !keyOptions.upperCase)?_c('span',[_vm._v(" "+_vm._s(_vm._keyLabel(keyOptions))+" ")]):_vm._e(),(keyOptions.icon && (keyOptions.icon.placement === 'right'))?_c('i',{class:['keyboard-fullsize--key-icon', 'keyboard-fullsize--key-icon_right', keyOptions.icon.className],style:(keyOptions.icon.style)}):_vm._e()])])]})],2)}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }