<template>
  <modal-layout-navigation
    ref="screenNavigation"
    :title="title"
    :withSearch="!!$refs.search"
    :searchDisabled="!$refs.search || $refs.search.isShown == true"
    @show-search="() => {
      $refs.screenNavigation.toHome();
      $refs.search.show();
    }"
  >
    <div :class="['easyscreen-events-promotion', orientation]" :style="{ '--easyscreen-events-promotion-special-color': specialColor }">
      <div class="easyscreen-events-promotion--wrapper">
        <div class="easyscreen-events-promotion--primary-content">
          <div class="easyscreen-events-promotion--primary-content-overlay"></div>
          <div class="easyscreen-events-promotion--cover-section">
            <div class="easyscreen-events-promotion--cover-section-overlay"></div>
            <div class="easyscreen-events-promotion--type">
              <i class="fal fa-arrow-down easyscreen-events-promotion--type-icon"></i>
              {{ /* TODO: Replace to event data */ }}
              {{ "Det sker" }}
            </div>
            <!-- image -->
            <div class="easyscreen-events-promotion--cover-wrapper">
              <transition
                name="easyscreen-events-promotion--horizontal-slide"
                mode="out-in"
                @after-leave="() => _updateSpecialColor(100)"
              >
                <img
                  :key="activeEvent.image"
                  ref="image"
                  class="easyscreen-events-promotion--cover"
                  draggable="false"
                  :src="'/proxy?url=' + activeEvent.image"
                  crossOrigin="annonimus"
                >
              </transition>
            </div>
          </div>
          <div class="easyscreen-events-promotion--text-section">
            <transition name="easyscreen-events-promotion--vertical-slide" mode="out-in">
              <div :key="activeEvent.nid">
                <div class="easyscreen-events-promotion--location">
                  {{ formattedDate }}
                  <span v-if="activeEvent.location">{{ $l10n("on") }} {{ activeEvent.location }}</span>
                </div>
                <h2 ref="title" class="easyscreen-events-promotion--title">
                  {{ activeEvent.title }}
                </h2>
                <div class="easyscreen-events-promotion--content-group">
                  <div class="easyscreen-events-promotion--content">
                    <div class="easyscreen-events-promotion--description">
                      {{ activeEvent.teaser }}
                    </div>
                    <div v-if="activeEvent.text">
                      <easyscreen-button
                        class="easyscreen-events-promotion--more-button"
                        color="white"
                        modificator="only-text"
                        @click.native="_openEventText"
                        size="medium"
                      >
                        <easyscreen-circle-button
                          class="easyscreen-events-promotion--more-button-icon"
                          icon="fal fa-arrow-right"
                          size="small"
                          color="white"
                        />
                        {{ $l10n("See more") }}
                      </easyscreen-button>
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <div class="easyscreen-events-promotion--primary-navigation">
              <div class="easyscreen-events-promotion--primary-navigation-buttons-group">
                <div
                  v-for="(event, eventIndex) in items"
                  :key="event.nid"
                  :class="[
                    'easyscreen-events-promotion--primary-navigation-button',
                    { 'easyscreen-events-promotion--primary-navigation-button_active': event === activeEvent }
                  ]"
                  @click="() => _selectEvent(eventIndex)"
                ></div>
              </div>
              <div class="easyscreen-events-promotion--primary-navigation-help">
                <i class="fal fa-hand-point-up easyscreen-events-promotion--primary-navigation-help-icon"></i>
                <span class="easyscreen-events-promotion--pFrimary-navigation-help-text">{{ $l10n("Browse") }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="easyscreen-events-promotion--secondary-content">
          <div class="easyscreen-events-promotion--secondary-content-navigation">
            <div
              v-for="(navigationElement, navigationElementIndex) in navigation"
              :key="navigationElement.type || `stub-${ navigationElementIndex }`"
              :class="[
                'easyscreen-events-promotion--navigation-button',
                {
                  'easyscreen-events-promotion--navigation-button_active': navigationElement.type === activeSecondaryContentType,
                  'easyscreen-events-promotion--navigation-button_stub': navigationElement.type === undefined
                }
              ]"
              @click="navigationElement.click ? navigationElement.click() : _selectSecondaryContent(navigationElement.type)"
            >
              <span class="easyscreen-events-promotion--navigation-button-absolute-icon">
                <i class="fal fa-arrow-down easyscreen-events-promotion--navigation-button-icon"></i>
                {{ $l10n(navigationElement.title) }}
              </span>
            </div>
          </div>
          <transition name="easyscreen-events-promotion--horizontal-slide" mode="out-in">
            <!-- suggested materials -->
            <div
              :key="secondaryContentKey"
              v-if="activeSecondaryContentType === 'recommendations' && activeEvent.recommendations"
              class="easyscreen-events-promotion--active-secondary-content"
            >
              <div class="easyscreen-events-promotion--recommendations">
                <div class="easyscreen-events-promotion--recommendations-themes-wrapper">
                  <div class="easyscreen-events-promotion--recommendations-themes">
                    <template
                      v-for="(theme, themeIndex) in activeEvent.recommendations.tags"
                    >
                      <div
                        :key="`${ theme }-${ themeIndex }`"
                        :class="[
                          'easyscreen-events-promotion--recommendations-theme',
                          { 'easyscreen-events-promotion--recommendations-theme_active': themeIndex === selectedRecommendationThemeIndex }
                        ]"
                        @click="() => _selectEventRecommendationTheme(themeIndex) "
                      >
                        <span class="easyscreen-events-promotion--recommendations-theme-text_default">{{ theme }}</span>
                        <span class="easyscreen-events-promotion--recommendations-theme-text_active">{{ theme }}</span>
                      </div>
                    </template>
                  </div>
                </div>
                <transition name="easyscreen-events-promotion--horizontal-slide" mode="out-in">
                  <easyscreen-carousel
                    ref="carousel"
                    class="easyscreen-events-promotion--recommendations-carousel"
                    :key="activeSecondaryContentType + selectedRecommendationThemeIndex"
                    :layout-columns="4"
                    :layout-rows="1"
                    @position-changed="_updateRecommendationCoverDominantColors"
                  >

                    <div
                      v-for="material in _getRecommendationMaterials(activeEvent, selectedRecommendationThemeIndex)"
                      :key="material.id"
                      class="easyscreen-events-promotion--recommendation-element"
                    >
                      <digital-shelf-cover
                        ref="recommendationCovers"
                        class="easyscreen-events-promotion--recommendation-cover"
                        :title="material.title"
                        :cover="material.cover"
                        :with-cover-background="false"
                        align="bottom"
                        horizontal-align="center"
                        @click.native="() => _showMaterialView(material, activeEvent, selectedRecommendationThemeIndex)"
                      />
                      <div
                        class="easyscreen-events-promotion--recommendation-cover-button"
                        :style="{ backgroundColor: _getRecommendationCoverDominantColor(material) }"
                      >
                        <span>
                          <i class="fal fa-arrow-right easyscreen-events-promotion--recommendation-cover-button-icon"></i>
                          {{ $l10n("Borrow & read") }}
                        </span>
                      </div>
                      <div class="easyscreen-events-promotion--recommendation-meta">
                        <div class="easyscreen-events-promotion--recommendation-type">{{ material.type }}</div>
                        <div>
                          <span v-if="material.author">{{ $l10n("Of") }} </span>
                          <span v-if="material.author" class="easyscreen-events-promotion--recommendation-autor">{{ material.author }}</span>
                          <span v-if="material.year" class="easyscreen-events-promotion--recommendation-year"> ({{ material.year }})</span>
                        </div>
                      </div>
                    </div>
                  </easyscreen-carousel>
                </transition>
              </div>
            </div>
            <div
              :key="secondaryContentKey"
              v-if="activeSecondaryContentType === 'relatedEvents'"
              class="easyscreen-events-promotion--active-secondary-content easyscreen-events-promotion--active-secondary-content_related-events"
            >
              <library-events-list
                layout="events"
                modal-position="absolute"
                design="light"
                :with-modal-controls="false"
                :title="activeEvent.relatedEvents.title"
                :items="activeEvent.relatedEvents.items"
                :auto-animation="activeEvent.relatedEvents.autoAnimation"
                :animation-duration="activeEvent.relatedEvents.animationDuration"
                @modal-opened="(closeModal, type) => $refs.screenNavigation.pushBackAction(closeModal, type)"
                @closed-inner-modal="(type) => $refs.screenNavigation.popBackAction(type)"
              />
            </div>
            <div
              :key="secondaryContentKey"
              v-if="activeSecondaryContentType === 'relatedNews'"
              class="easyscreen-events-promotion--active-secondary-content easyscreen-events-promotion--active-secondary-content_related-news"
            >
              <node-list-tab
                layout="news"
                modal-position="absolute"
                design="light"
                :full-standalone="false"
                :with-modal-controls="false"
                :title="activeEvent.relatedNews.title"
                :items="activeEvent.relatedNews.items"
                :auto-animation="activeEvent.relatedNews.autoAnimation"
                :animation-duration="activeEvent.relatedNews.animationDuration"
                @modal-opened="(closeModal, type) => $refs.screenNavigation.pushBackAction(closeModal, type)"
                @closed-inner-modal="(type) => $refs.screenNavigation.popBackAction(type)"
              />
            </div>
            <div
              :key="secondaryContentKey"
              v-if="activeSecondaryContentType === 'inspirationScanner'"
              class="easyscreen-events-promotion--active-secondary-content easyscreen-events-promotion--active-secondary-content_inspiration-scanner"
            >
              <inspiration-scanner
                design="light"
                position="absolute"
                :with-navigation-controls="false"
                :title="activeEvent.inspirationScanner.title"
                :with-covers-only="activeEvent.inspirationScanner.withCoversOnly"
                :available-only="activeEvent.inspirationScanner.availableOnly"
                :department-id="activeEvent.inspirationScanner.departmentId"
                :branch-id="activeEvent.inspirationScanner.branchId"
                @modal-opened="(closeModal, type) => $refs.screenNavigation.pushBackAction(closeModal, type)"
                @closed-inner-modal="(type) => $refs.screenNavigation.removeBackAction(type)"
                @select-tag="_findByTag"
              />
            </div>
          </transition>

          <div v-if="activeSecondaryContentType === 'review'">
            <scrollable
              class="easyscreen-events-promotion--reviews"
              smooth-edge-color="#ffffff"
              :max-height="730"
            >
              <ul class="easyscreen-events-promotion--reviews-list">
                <li
                  v-for="review in activeSecondaryContent.list"
                  :key="review.id || review.label"
                  class="easyscreen-events-promotion--reviews-list-element"
                  @click="() => _openReview(review)"
                >{{ review.label }}</li>
              </ul>
            </scrollable>
          </div>
        </div>

        <modal-blank
          v-if="activeEvent.text"
          ref="reviewModal"

          @before-open="() => $emit('before-open-inner-modal', $refs.reviewModal.hide, 'eventsPromotionText')"
          @closed="() => $emit('closed-inner-modal', 'eventsPromotionText')"

          class="easyscreen-events-promotion--modal material-detail--review prevent-carousel-actions"
          min-width="100%"
          min-height="100%"
        >
          <template slot="raw">
            <easyscreen-circle-button
              class="easyscreen-events-promotion--close-button easyscreen-events-promotion--close-button_top-right"
              icon="fal fa-times"
              color="white"
              size="medium"

              @click.native="() => $refs.reviewModal.hide()"
            />
            <easyscreen-circle-button
              class="easyscreen-events-promotion--close-button easyscreen-events-promotion--close-button_bottom-right"
              icon="fal fa-times"
              color="white"
              size="medium"

              @click.native="() => $refs.reviewModal.hide()"
            />

            <scrollable
              class="easyscreen-events-promotion--review-view"
              :max-height="_isLandscape() ? 900 : 850"
            >
              spotlight content
            </scrollable>
          </template>
        </modal-blank>

        <materials-list
          v-if="selectedMaterial"
          ref="materialsList"
          design="light"
          :use-suggested-lists="suggestedLists"
          :default-materials="selectedMaterialsList"
          :default-selected="selectedMaterial"
          @before-open="$refs.screenNavigation.pushBackAction(() => $refs.materialsList.hide(), 'materials-list')"
          @closed="() => $refs.screenNavigation.popBackAction('materials-list')"
          @select-tag="_findByTag"
          @modal-opened="(closeModal, type) => $refs.screenNavigation.pushBackAction(closeModal, type)"
          @closed-inner-modal="(type) => $refs.screenNavigation.popBackAction(type)"
        />

        <easyscreen-search-light
          ref="search"
          position="absolute"
          popular-searches="multiline"
          :available-only-button="true"
          :with-close-controls="false"
          :default-special-color="specialColor"
          @before-open="$refs.screenNavigation.pushBackAction(() => $refs.search.hide(), 'search')"
          @closed="() => $refs.screenNavigation.popBackAction('search')"
          @modal-opened="(closeModal, type) => $refs.screenNavigation.pushBackAction(closeModal, type)"
          @closed-inner-modal="(type) => $refs.screenNavigation.popBackAction(type)"
        />

        <modal-blank
          v-if="activeEvent"
          ref="activeElementInfoModal"
          position="absolute"
          :click-to-close="true"
          @before-open="$refs.screenNavigation.pushBackAction(() => {
            $refs.activeElementInfoModal && $refs.activeElementInfoModal.hide();
          }, 'activeElementInfo')"
          @closed="() => $refs.screenNavigation.popBackAction('activeElementInfo')"
        >
          <div>
            <node-list-info
              design="light"

              :type="activeEvent.type"
              :date="activeEvent.date"
              :date-to="activeEvent.dateTo"
              :location="activeEvent.location"
              :price="activeEvent.price"
              :image="activeEvent.image"
              :title="activeEvent.title"
              :es-teaser="activeEvent.esTeaser"
              :teaser="activeEvent.teaser"
              :text="activeEvent.text"
              :lead="activeEvent.lead"
            />
          </div>
        </modal-blank>

        <modal-blank
          ref="wayfinderModal"
          class="easyscreen-events-promotion--wayfinder"
          position="absolute"
          :click-to-close="true"
          @before-open="$refs.screenNavigation.pushBackAction($refs.wayfinderModal.hide, 'wayfinder')"
          @closed="() => $refs.screenNavigation.popBackAction('wayfinder')"
        >
          <easyscreen-iframe v-if="wayfinderUrl" :url="wayfinderUrl" :overlay="false"/>
        </modal-blank>
      </div>
    </div>
  </modal-layout-navigation>
</template>

<style src="./events-promotion.less" lang="less" />

<script>
  import ModalBlank from "../core/modal/blank.vue";
  import ModalLayoutNavigation from "../core/modal/layout-navigation.vue";
  import EasyscreenButton from "../core/button/button.vue";
  import EasyscreenCircleButton from "../core/button/circle-button.vue";
  import EasyscreenCarousel from "../core/carousel/carousel.vue";
  import Scrollable from "../core/scrollable/scrollable.vue";
  import EasyscreenIframe from "../core/iframe/iframe.vue";
  import DigitalShelfCover from "../digital-shelf/cover.vue";
  import MaterialsList from "../materials-list/materials-list.vue";
  import LibraryEventsList from "../library-events/library-events-list.vue";
  import EasyscreenSearchLight from "../search/search-light.vue";
  import NodeListInfo from "../node-list/node-list-info.vue";
  import NodeListTab from "../node-list/node-list-tab.vue";
  import InspirationScanner from "../inspiration-scanner/inspiration-scanner.vue";

  import moment from "moment";
  import lodash from "lodash";
  import getDominantImageColor from "@/lib/utils/get-dominant-image-color.js";
  import asyncTimeout from "@/lib/utils/async-timeout.js";
  import orientationMixin from "../core/mixins/orientation.js";
  import wayfinderMixin from "../core/mixins/wayfinder.js";

  export default {
    name: "easyscreen-events-promotion",
    mixins: [orientationMixin, wayfinderMixin],
    props: {
      /* The title of events promotion. */
      title: {
        type: String,
        default() {
          return this.$easyscreenConfig.name || "";
        }
      },
      /* The list of events. */
      items: Array,
      /* Flag of the suggested lists for material detail. */
      suggestedLists: {
        type: Boolean,
        default() {
          return this.$easyscreenConfig.get("enable.suggestedLists");
        }
      },
      defaultSpecialColor: {
        type: String,
        default: "#aaaaaa"
      }
    },
    computed: {
      activeEvent() {
        return this.items[this.activeEventIndex];
      },
      formattedDate: {
        get() {
          const dateFrom = moment(this.activeEvent.date, "DD/MM-YYYY");
          const dateTo = moment(this.activeEvent.dateTo, "DD/MM-YYYY");
          let date = moment();

          if (date < dateFrom) {
            date = dateFrom;
          } else if (date > dateTo) {
            date = dateTo;
          }

          date.hour(dateFrom.hour());
          date.minute(dateFrom.minute());

          let formattedDate = date.format(`dddd [${ this.$l10n("the") }] D/M YYYYY`);

          if (date.hour() !== 0 || date.minute() !== 0) {
            formattedDate += " " + this.$l10n("Kl.") + " " + date.format("HH.mm");
          }

          return formattedDate;
        }
      },
      navigation() {
        const defaultNavigation = [{
          title: this.$l10n("Recommendations"),
          type: "recommendations"
        }, {
          title: this.$l10n("Related events"),
          type: "relatedEvents"
        }, {
          title: this.$l10n("Related news"),
          type: "relatedNews"
        }, {
          title: this.$l10n("Inspiration scanner"),
          type: "inspirationScanner"
        }, {
          title: this.$l10n("Map"),
          type: "map",
          click: () => this._openMap(this.activeEvent.map)
        }].filter(navigationElement => {
          return this.activeEvent[navigationElement.type] !== undefined;
        });

        const stubs = new Array(lodash.clamp(4 - defaultNavigation.length, 0, Infinity)).fill(null).map(() => {
          return { title: "" };
        });

        return defaultNavigation.concat(stubs);
      },
      secondaryContentKey() {
        return [this.activeSecondaryContentType, (this.activeEvent || {}).nid || "empty"].join("-");
      }
    },
    data() {
      return {
        activeEventIndex: 0,
        activeRecommendations: 0,
        selectedRecommendationThemeIndex: 0,
        specialColor: this.defaultSpecialColor,
        activeSecondaryContentType: "recommendations",
        selectedMaterialsList: null,
        selectedMaterial: null,
        wayfinderUrl: null,
        recommendationCoverDominantColors: {}
      };
    },
    methods: {
      async _selectSecondaryContent(type) {
        if (type === undefined) {
          return;
        }

        if (type === "ereolen") {
          if (this.$refs.ereolenModal) {
            this.$refs.ereolenModal.show();
            return;
          }

          return;
        }

        this.activeSecondaryContentType = type || "";
        if (this.activeSecondaryContentType === "recommendations") {
          await asyncTimeout(600);
          this._updateRecommendationCoverDominantColors();
        }
      },
      _openEventText() {
        if (this.$refs.activeElementInfoModal) {
          this.$refs.activeElementInfoModal.show();
        }
      },
      async _selectEventRecommendationTheme(index) {
        this.selectedRecommendationThemeIndex = index;

        await asyncTimeout(600);
        this._updateRecommendationCoverDominantColors();
      },
      _getRecommendationMaterials(event, themeIndex) {
        return lodash.get(event, `recommendations.items[${ themeIndex }]`, []);
      },
      /**
       * Open the material view for selected material.
       * @async
       *
       * @param {Object} item - The materail detail (see response of GET [lms]/[consumer-hash]/detail).
       * @param {String} item.faustNumber - The faust number of material.
       * @param {Number} themeIndex - The index of selected theme.
       */
      async _showMaterialView({ faustNumber }, event, themeIndex) {
        this.selectedMaterialsList = this._getRecommendationMaterials(event, themeIndex).map(material => material.id || material.faustNumber);
        this.selectedMaterial = faustNumber;
        await this.$nextTick();

        if (this.$refs.materialsList) {
          this.$refs.materialsList.show();
        } else {
          console.warn("'this.$refs.materialsList' not found");
        }
      },
      async _updateSpecialColor(timeout) {
        if (timeout) {
          await asyncTimeout(timeout);
        }

        if (this.$refs.image) {
          const specialColor = await getDominantImageColor(this.$refs.image);
          if (specialColor) {
            this.specialColor = specialColor;
          }
        } else {
          this.specialColor = "#aaaaaa";
        }
      },
      async _selectEvent(eventIndex) {
        this.activeEventIndex = eventIndex;
        this.activeRecommendations = 0;
        this.selectedRecommendationThemeIndex = 0;
        this.activeSecondaryContentType = "";
        if (this.navigation.length !== 0) {
          this.activeSecondaryContentType = this.navigation[0].type;
        }

        if (this.$refs.selectedMaterial && this.$refs.selectedMaterial.isShown) {
          await this.$refs.selectedMaterial.hide();

          this.selectedMaterialsList = null;
          this.selectedMaterial = null;
        }

        this._updateSpecialColor();

        if (this.activeSecondaryContentType === "recommendations") {
          await asyncTimeout(600);
          this._updateRecommendationCoverDominantColors();
        }
      },
      /**
       * Hanlder of find-by-tag event on material view.
       *
       * @param {String} tag - The selected material tag for search.
       */
      async _findByTag(tag) {
        if (this.$refs.materialsList) {
          await this.$refs.materialsList.hide();
        }

        if (this.$refs.search) {
          await this.$refs.search.show();
          await this.$refs.search.findBy(tag);
        }
      },
      async _openMap(mapData) {
        this.wayfinderUrl = this._generateWayfinderUrl(mapData);
        await this.$nextTick();
        this.$refs.wayfinderModal.show();
      },
      async _hideMap() {
        await this.$refs.wayfinderModal.hide();
        this.wayfinderUrl = "";
      },
      _getRecommendationCoverDominantColor(materil) {
        return this.recommendationCoverDominantColors[materil.cover];
      },
      async _updateRecommendationCoverDominantColors() {
        this.recommendationCoverDominantColors = {};

        lodash.castArray(this.$refs.recommendationCovers).filter(Boolean).forEach(async (cover) => {
          this.recommendationCoverDominantColors[cover.cover] = await cover.dominantColor;
          this.$forceUpdate();
        });

      }
    },
    async mounted() {
      this._updateSpecialColor();
      this._updateRecommendationCoverDominantColors();
    },
    components: {
      "modal-blank": ModalBlank,
      "modal-layout-navigation": ModalLayoutNavigation,
      "easyscreen-button": EasyscreenButton,
      "easyscreen-circle-button": EasyscreenCircleButton,
      "easyscreen-carousel": EasyscreenCarousel,
      "scrollable": Scrollable,
      "easyscreen-iframe": EasyscreenIframe,
      "digital-shelf-cover": DigitalShelfCover,
      "materials-list": MaterialsList,
      "library-events-list": LibraryEventsList,
      "easyscreen-search-light": EasyscreenSearchLight,
      "node-list-info": NodeListInfo,
      "node-list-tab": NodeListTab,
      "inspiration-scanner": InspirationScanner
    }
  };
</script>
